import React from 'react';
import {
  CheckCircle,
  Error,
  ReportProblem,
  Schedule,
} from '@material-ui/icons';
import Pill, { PillVariantType } from '../../Pill';
import { MonitorEventResponseInvestigationStatusEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface MonitorStatusInvestigationStatusPillProps {
  type: MonitorEventResponseInvestigationStatusEnum;
}

type TypeMap<T> = {
  [key in MonitorEventResponseInvestigationStatusEnum]: T;
};

const typeToVariant: TypeMap<PillVariantType> = {
  [MonitorEventResponseInvestigationStatusEnum.Started]: 'primary',
  [MonitorEventResponseInvestigationStatusEnum.Done]: 'success',
  [MonitorEventResponseInvestigationStatusEnum.GaveUp]: 'danger',
  [MonitorEventResponseInvestigationStatusEnum.Unstarted]: 'warning',
};

const typeToIcon: TypeMap<React.ReactElement> = {
  [MonitorEventResponseInvestigationStatusEnum.Started]: (
    <Schedule fontSize='small' />
  ),
  [MonitorEventResponseInvestigationStatusEnum.Done]: (
    <CheckCircle fontSize='small' />
  ),
  [MonitorEventResponseInvestigationStatusEnum.GaveUp]: (
    <Error fontSize='small' />
  ),
  [MonitorEventResponseInvestigationStatusEnum.Unstarted]: (
    <ReportProblem fontSize='small' />
  ),
};

const MonitorStatusInvestigationStatusPill: React.FC<MonitorStatusInvestigationStatusPillProps> = ({
  type,
}) => {
  const variant: PillVariantType = typeToVariant[type];
  const icon: React.ReactElement = typeToIcon[type];

  return <Pill text={capitalizeEnum(type)} variant={variant!} icon={icon!} />;
};

export default MonitorStatusInvestigationStatusPill;
