import React, { useState } from 'react';
import { DateTime } from 'luxon';
import EditOutlined from '@material-ui/icons/EditOutlined';
import { numberToMoney } from '../../utils/CurrencyUtils';
import CardWithItems from '../CardWithItems';
import { TransactionResponse } from '../../openapi/arrakis';
import { capitalizeEnum } from '../../utils/StringUtils';
import EditTransactionInformationFormSidebarModal from '../../forms/EditTransactionInformationFormSidebarModal';
import { isTransactionReadOnly } from '../../utils/TransactionHelper';

interface TransactionInformationProps {
  transaction: TransactionResponse;
}

const TransactionInformation: React.FC<TransactionInformationProps> = ({
  transaction,
}) => {
  const [
    openEditTransactionInformationFormSidebarModal,
    setOpenEditTransactionInformationFormSidebarModal,
  ] = useState<boolean>(false);

  return (
    <div>
      <div className='flex flex-col'>
        <div className='py-2 flex justify-between'>
          <span className='text-xl text-gray-800 font-primary-medium'>
            Transaction Information
          </span>
          {!isTransactionReadOnly(transaction) && (
            <button
              onClick={() => {
                setOpenEditTransactionInformationFormSidebarModal(true);
              }}
              className='flex flex-row rounded items-center focus:outline-none ml-2 text-primary'
            >
              <span className='text-primary'>
                <EditOutlined fontSize='small' />
              </span>
              <span className='text-primary'>Edit</span>
            </button>
          )}
        </div>
        <CardWithItems
          items={[
            {
              name: 'Sales Price',
              value: numberToMoney(transaction.price?.amount!),
            },
            {
              name: 'Gross Commission',
              value: transaction.grossCommission
                ? numberToMoney(transaction.grossCommission.amount!)
                : 'N/A',
            },
            {
              name: 'Status',
              value: capitalizeEnum(transaction.lifecycleState!.state!),
            },
            {
              name: 'Closing Date',
              value: transaction.closingDateActual
                ? DateTime.fromISO(
                    transaction.closingDateActual,
                  ).toLocaleString()
                : 'N/A',
            },
            {
              name: 'Deal Type',
              value: capitalizeEnum(transaction.transactionType!),
            },
            {
              name: 'SkySlope ID',
              value: transaction.skySlopeSaleGuid,
            },
            {
              name: 'Commission Invoice Number',
              value: transaction.commissionInvoiceNumber || 'N/A',
            },
            {
              name: 'Transaction Owner',
              value: transaction.transactionOwner?.name || 'N/A',
            },
            {
              name: 'Representing',
              value: transaction.transactionOwner?.represents || 'N/A',
            },
          ]}
        />
      </div>
      <EditTransactionInformationFormSidebarModal
        isOpen={openEditTransactionInformationFormSidebarModal}
        onClose={() => setOpenEditTransactionInformationFormSidebarModal(false)}
        transaction={transaction}
      />
    </div>
  );
};

export default TransactionInformation;
