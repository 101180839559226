import React from 'react';
import { Schedule, CheckCircle, InfoRounded, Close } from '@material-ui/icons';
import Pill, { PillVariantType } from '../../Pill';
import { OutgoingPaymentResponseStatusEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface OutgoingPaymentStatusCellProps {
  type: OutgoingPaymentResponseStatusEnum;
}

type TypeMap<T> = {
  [key in OutgoingPaymentResponseStatusEnum]: T;
};

const typeToVariant: TypeMap<PillVariantType> = {
  [OutgoingPaymentResponseStatusEnum.Deleted]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Disputed]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Pending]: 'warning',
  [OutgoingPaymentResponseStatusEnum.ScheduledForPayment]: 'success',
  [OutgoingPaymentResponseStatusEnum.SentToPaymentSystem]: 'primary',
  [OutgoingPaymentResponseStatusEnum.GeneralError]: 'danger',
  [OutgoingPaymentResponseStatusEnum.SchedulingError]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Open]: 'primary',
  [OutgoingPaymentResponseStatusEnum.Paid]: 'success',
  [OutgoingPaymentResponseStatusEnum.Rejected]: 'danger',
  [OutgoingPaymentResponseStatusEnum.Cancelled]: 'danger',
};

const typeToIcon: TypeMap<React.ReactElement> = {
  [OutgoingPaymentResponseStatusEnum.Deleted]: <Close fontSize='small' />,
  [OutgoingPaymentResponseStatusEnum.Disputed]: <Close fontSize='small' />,
  [OutgoingPaymentResponseStatusEnum.Pending]: <InfoRounded fontSize='small' />,
  [OutgoingPaymentResponseStatusEnum.ScheduledForPayment]: (
    <Schedule fontSize='small' />
  ),
  [OutgoingPaymentResponseStatusEnum.SentToPaymentSystem]: (
    <Schedule fontSize='small' />
  ),
  [OutgoingPaymentResponseStatusEnum.GeneralError]: <Close fontSize='small' />,
  [OutgoingPaymentResponseStatusEnum.SchedulingError]: (
    <Close fontSize='small' />
  ),
  [OutgoingPaymentResponseStatusEnum.Open]: <InfoRounded fontSize='small' />,
  [OutgoingPaymentResponseStatusEnum.Paid]: <CheckCircle fontSize='small' />,
  [OutgoingPaymentResponseStatusEnum.Rejected]: <Close fontSize='small' />,
  [OutgoingPaymentResponseStatusEnum.Cancelled]: <Close fontSize='small' />,
};

const OutgoingPaymentStatusCell: React.FC<OutgoingPaymentStatusCellProps> = ({
  type,
}) => {
  const variant: PillVariantType = typeToVariant[type];
  const icon: React.ReactElement = typeToIcon[type];

  return <Pill text={capitalizeEnum(type)} variant={variant!} icon={icon!} />;
};

export default OutgoingPaymentStatusCell;
