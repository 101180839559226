declare global {
  interface Window {
    acquire: any;
  }
}

export const hideSupportWidget = () => {
  window.acquire = window.acquire || [];
  window.acquire.push(function (app: any) {
    app.applyAction('update-settings', { 'crm.hide_widget_in_web': 'yes' });
    app.applyAction('update-settings', { 'crm.hide_widget_in_mobiles': 'yes' });
  });
};

export const showSupportWidget = () => {
  window.acquire = window.acquire || [];
  window.acquire.push(function (app: any) {
    app.applyAction('update-settings', { 'crm.hide_widget_in_web': 'no' });
    app.applyAction('update-settings', { 'crm.hide_widget_in_mobiles': 'no' });
  });
};

export const closeSupportChat = () => {
  showSupportWidget();
  window.acquire.push(function (app: any) {
    app.ui.status('min');
  });
};

export const openSupportChat = () => {
  showSupportWidget();
  window.acquire.push(function (app: any) {
    app.ui.status('max');
  });
};
