import React from 'react';
import { useForm } from 'react-hook-form';
import { InfoOutlined, VpnKey } from '@material-ui/icons';
import ControlledTextInput from '../components/ControlledTextInput';
import Alert from '../components/Alert';
import SubmitButton from '../components/SubmitButton';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS } from '../utils/Validations';
import { ResetPasswordRequest, UserControllerApi } from '../openapi/yenta';

interface ResetPasswordRouteProps {}

interface FormValues extends ResetPasswordRequest {
  submit: any;
}

const ResetPasswordRoute: React.FC<ResetPasswordRouteProps> = () => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting, isSubmitSuccessful },
    clearErrors,
  } = useForm<FormValues>();

  const onSubmit = async (values: ResetPasswordRequest) => {
    const api = new UserControllerApi(getYentaConfiguration());
    try {
      await api.resetPasswordUsingPOST(values);
    } catch (e) {
      setError('submit', {
        message: e.response.data.message,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='bg-gray-100'>
        <div className='container mx-auto h-screen flex items-center justify-center'>
          <div className='bg-white lg:w-1/3 p-10 border-2 border-gray-100'>
            <div className='text-center -mt-20 mb-5'>
              <div className='inline-block rounded-full p-6 bg-dark text-white'>
                <VpnKey fontSize='large' />
              </div>
            </div>
            <p className='font-primary-medium text-3xl text-center'>
              Reset Password
            </p>
            {isSubmitSuccessful && (
              <Alert
                text='An email has been sent with a link to reset your password.'
                variant='success'
                icon={<InfoOutlined />}
              />
            )}
            {errors.submit && (
              <div className='mt-3'>
                <Alert
                  text={errors.submit.message}
                  variant='error'
                  icon={<InfoOutlined />}
                />
              </div>
            )}
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                label='Enter Email'
                name='usernameOrEmail'
                placeholder='Example@joinreal.com'
                errors={errors}
                rules={{
                  ...EMAIL_VALIDATIONS,
                  required: 'Email required',
                }}
              />
            </div>
            <SubmitButton
              label='Reset'
              isSubmitting={isSubmitting}
              onClick={() => {
                if (errors.submit) {
                  clearErrors('submit');
                }
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPasswordRoute;
