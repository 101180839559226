import React from 'react';
import AutorenewRounded from '@material-ui/icons/AutorenewRounded';
import classNames from 'classnames';

export interface DefaultLoaderProps {
  noPadding?: boolean;
  iconSize?: 'small' | 'large' | 'default';
}

const DefaultLoader: React.FC<DefaultLoaderProps> = ({
  noPadding,
  iconSize,
}) => {
  return (
    <div
      className={classNames('w-full h-full flex items-center justify-center', {
        'p-5': !noPadding,
      })}
      title='spinning-loader'
    >
      <AutorenewRounded
        fontSize={iconSize ? iconSize : 'large'}
        className='animate-spin text-primary'
      />
    </div>
  );
};

export default DefaultLoader;
