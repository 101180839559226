import React from 'react';
import BreadCrumbsWithUrls, { BreadCrumbsProps } from './BreadCrumbsWithUrls';

interface PageLayoutProps extends BreadCrumbsProps {
  RightComponent?: React.FC | React.ReactElement;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  path,
  RightComponent,
  children,
}) => {
  return (
    <div>
      <div className='px-4 py-1 h-10 lg:h-14 lg:border-b border-trueGray-200 flex flex-row flex-nowrap items-center'>
        <BreadCrumbsWithUrls path={path} />
        {RightComponent}
      </div>
      {children}
    </div>
  );
};

export default PageLayout;
