import React from 'react';
import { JobExecutionResponseJobExecutionStatusEnum } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import Pill, { PillVariantType } from '../../Pill';

interface CronJobsStatusCellProps {
  status: JobExecutionResponseJobExecutionStatusEnum;
}

const statusToVariant: EnumMap<
  JobExecutionResponseJobExecutionStatusEnum,
  PillVariantType
> = {
  SUCCESS: 'success',
  FAIL: 'danger',
  RUNNING: 'primary',
  CREATED: 'warning',
};

const CronJobsStatusCell: React.FC<CronJobsStatusCellProps> = ({ status }) => {
  return <Pill text={status.toUpperCase()} variant={statusToVariant[status]} />;
};

export default CronJobsStatusCell;
