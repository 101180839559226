import React, { useEffect } from 'react';
import '../assets/css/print.css';

interface PagedPrintProps {}

const PagedPrint: React.FC<PagedPrintProps> = ({ children }) => {
  useEffect(() => {
    // @ts-ignore
    import('pagedjs/dist/paged.polyfill');
  }, []);

  return <div>{children}</div>;
};

export default PagedPrint;
