import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import PageLayout from '../components/PageLayout';
import { mlsDetail } from '../slices/MLSSlice';
import ResourceContainer from '../components/ResourceContainer';
import { RootState } from '../types';
import MLSHeaderSection from '../components/mls/MLSHeaderSection';
import IdentificationCard from '../components/IdentificationCard';
import SectionHeader from '../components/SectionHeader';
import ListPaginateContainer from '../containers/ListPaginateContainer';
import DetailPageLoader from '../components/DetailPageLoader';
import { numberToMoney } from '../utils/CurrencyUtils';
import AgentRow from '../components/AgentRow';
import MlsStatusPill from '../components/table/Cells/MlsStatusPill';
import { AgentResponse, SearchRequestTargetObjectEnum } from '../openapi/yenta';
import { resourceListFetchData } from '../utils/TableUtils';

interface Match {
  id: string;
}

interface MLSDetailRouteProps extends RouteComponentProps<Match> {}

const MLSDetailRoute: React.FC<MLSDetailRouteProps> = ({ match }) => {
  const dispatch = useDispatch();
  const mlsId = match.params.id;
  // const [isCollapsed, setCollapsed] = useState(true);
  const {
    loadingDetail,
    detail,
    fetchDetailErrorCode,
    // calendarEvents,
  } = useSelector((state: RootState) => state.mls);

  useEffect(() => {
    dispatch(mlsDetail(mlsId));
    // dispatch(fetchMLSCalendarEvents(mlsId));
  }, [dispatch, mlsId]);
  const searchParameters = {
    filterJoinColumn: 'mls',
    filterValue: detail ? detail!.id! : '',
    joinOn: ['mls'],
    searchColumns: ['firstName', 'lastName'],
    responseColumns: [
      'id',
      'firstName',
      'lastName',
      'avatar',
      'emailAddress',
      'phoneNumber',
    ],
    targetObject: SearchRequestTargetObjectEnum.Agent,
  };

  return (
    <ResourceContainer
      loading={loadingDetail}
      LoaderComponent={<DetailPageLoader />}
      isEmpty={!detail}
      errorCode={fetchDetailErrorCode}
      resourceName='MLS'
    >
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'MLS', url: '/mls' },
          { title: `${detail?.name}`, url: `/mls/${detail?.id}` },
        ]}
      >
        {!!detail && <MLSHeaderSection mls={detail} />}
        {!!detail && (
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 m-4'>
            <IdentificationCard
              icon={<FingerprintIcon />}
              name='Code'
              value={detail.code!}
            />
            <IdentificationCard
              icon={<AttachMoneyIcon />}
              name='Registration Fee'
              value={numberToMoney(detail.registrationFee!.amount!, 0)}
            />
            <IdentificationCard
              icon={<CalendarTodayIcon />}
              name='Join Date'
              value={DateTime.fromISO(detail.joinDate!).toFormat('LL/dd/yy')}
            />
            <IdentificationCard
              icon={<RecentActorsIcon />}
              name='Status'
              value={<MlsStatusPill status={detail.status!} />}
            />
          </div>
        )}
        {!!detail && (
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-4 space-y-4 lg:space-y-0 md:space-y-0 m-4'>
            {/* <div> */}
            {/*<div className='mt-3'>*/}
            {/*  <SectionHeader*/}
            {/*    icon={<Info fontSize='inherit' />}*/}
            {/*    title='Calendar'*/}
            {/*  />*/}
            {/*  <div className='mt-3'>*/}
            {/*    <CalendarEventsCard*/}
            {/*      isCollapsed={isCollapsed}*/}
            {/*      setCollapsed={setCollapsed}*/}
            {/*      calendarGroup={calendarEvents}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/* </div> */}
            <div className='col-span-1 md:col-span-3'>
              <SectionHeader
                icon={<PeopleAlt fontSize='inherit' />}
                title='Agents'
              />
              <ListPaginateContainer<AgentResponse>
                fetchData={(req) =>
                  resourceListFetchData<AgentResponse>(
                    req,
                    req.search,
                    searchParameters,
                  )
                }
                resourceName='agent'
                searchPlaceholder='Search by name or zip code'
              >
                {(agents) =>
                  agents.map((agent) => <AgentRow key={agent.id} {...agent} />)
                }
              </ListPaginateContainer>
            </div>
          </div>
        )}
      </PageLayout>
    </ResourceContainer>
  );
};

export default MLSDetailRoute;
