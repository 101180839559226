import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import { Column } from 'react-table';
import { useDispatch } from 'react-redux';
import PageLayout from '../components/PageLayout';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import { resourceTableFetchData } from '../utils/TableUtils';
import {
  InvoiceControllerApi,
  InvoiceResponse,
  InvoiceResponseStatusEnum,
  SearchRequestTargetObjectEnum,
  TransactionControllerApi,
  TransactionLifecycleStateValueStateEnum,
} from '../openapi/arrakis';
import IconButton from '../components/IconButton';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import NumberColumnFilter from '../components/table/Filters/NumberColumnFilter';
import { numberToMoney } from '../utils/CurrencyUtils';
import InvoiceStatusSelectColumnFilter from '../components/table/Filters/InvoiceStatusSelectColumnFilter';
import InvoiceFormSidebarModal from '../forms/InvoiceFormSidebarModal';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showErrorToastForErrorCode } from '../slices/ToastNotificationSlice';
import ErrorService from '../services/ErrorService';
import InvoicesStatusCell from '../components/table/Cells/InvoicesStatusCell';
import NullableTextCell from '../components/table/Cells/NullableTextCell';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';
import ConfirmationModal from '../components/ConfirmationModal';
import Button from '../components/Button';
import { AppDispatch } from '../types';
import { transitionTransaction } from '../slices/TransactionSlice';
import { showApiErrorModal } from '../slices/ErrorSlice';

interface InvoicesIndexRouteProps {}

export const columns: Array<Column<InvoiceResponse>> = [
  {
    Header: 'Transaction Code',
    accessor: 'transactionCode',
    Cell: ({ value }) => (
      <Link to={`/transactions/code/${value}`}>
        <IconButton
          leftIcon={<ExitToAppIcon titleAccess='Link' fontSize='small' />}
          variant='outline'
          label={value}
        />
      </Link>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Invoice Number',
    accessor: 'invoiceNumber',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Amount',
    accessor: 'invoicedAmount',
    Cell: ({ value }) => (value ? numberToMoney(value.amount!) : 'N/A'),
    Filter: NumberColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: InvoiceStatusSelectColumnFilter,
    Cell: ({ value }) => <InvoicesStatusCell type={value!} />,
  },
  {
    Header: 'Company',
    accessor: 'company',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Payment System Id',
    accessor: 'paymentSystemId',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
    cardColSize: 6,
  },
];
export const columnsToFetch = [
  ...columns.map((col) => col.accessor as string),
  'firstName',
  'lastName',
];

const InvoicesIndexRoute: React.FC<InvoicesIndexRouteProps> = () => {
  const dispatch: AppDispatch = useDispatch();

  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceResponse | null>(
    null,
  );
  const [transactionCode, setTransactionCode] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getInvoiceDetailsById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new InvoiceControllerApi(
          getArrakisConfiguration(),
        ).getInvoiceUsingGET(id);

        setCurrentInvoice(data);
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the invoice details',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!invoiceId) {
      getInvoiceDetailsById(invoiceId);
    }
  }, [invoiceId, getInvoiceDetailsById]);

  const transitionTransactionToPaymentAccepted = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransactionByCodeUsingGET(transactionCode!);

      await dispatch(
        transitionTransaction(
          TransactionLifecycleStateValueStateEnum.PaymentAccepted,
          data.id!,
        ),
      );
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch transction with the code', e, {
        transaction: { code: transactionCode },
      });
      dispatch(
        showErrorToastForErrorCode(
          'Unable to fetch transction with the code',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setIsSubmitting(false);
      setTransactionCode(null);
    }
  };

  const columnsWithAction: Array<Column<InvoiceResponse>> = [
    {
      Header: 'Action',
      accessor: 'id',
      id: 'action',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <div className='flex space-x-2'>
          <IconButton
            leftIcon={<VisibilityIcon titleAccess='View' data-testid='View' />}
            onClick={() => setInvoiceId(original.id!)}
          />
          {original.status === InvoiceResponseStatusEnum.InvoiceCreated && (
            <IconButton
              leftIcon={<PaymentIcon titleAccess='Accept Payment' />}
              label='Accept Payment'
              onClick={() => setTransactionCode(original.transactionCode!)}
            />
          )}
        </div>
      ),
    },
    {
      Header: 'Name',
      accessor: 'firstName',
      Filter: TextColumnFilter,
      Cell: ({ value, row: { original } }) => (
        <p>{`${value} ${original.lastName}`}</p>
      ),
    },
    ...columns,
  ];

  return (
    <PageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Invoices', url: '/invoices' },
      ]}
    >
      <div className='px-4 lg:py-5'>
        <ResourceIndexContainer<InvoiceResponse>
          header='Invoices'
          columns={columnsWithAction}
          resourceName='Invoices'
          fetchData={async (req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              [],
              SearchRequestTargetObjectEnum.Invoice,
              'arrakis',
            )
          }
        />
      </div>

      <InvoiceFormSidebarModal
        isOpen={!!invoiceId && !!currentInvoice}
        onClose={() => {
          setInvoiceId(null);
          setCurrentInvoice(null);
        }}
        invoice={currentInvoice!}
      />
      <ConfirmationModal
        variant='warning'
        title='Are you sure you want to mark this as received?'
        subtitle='Once done, payments will be sent out to all suitable parties. This action cannot be undone.'
        isOpen={!!transactionCode?.length}
        onClose={() => setTransactionCode(null)}
      >
        <div className='mt-3 space-x-3'>
          <Button
            label='Confirm'
            type='primary'
            disabled={isSubmitting}
            onClick={transitionTransactionToPaymentAccepted}
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setTransactionCode(null)}
          />
        </div>
      </ConfirmationModal>
    </PageLayout>
  );
};

export default InvoicesIndexRoute;
