import React, { useCallback, useEffect, useState } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AgentDetailHeader from '../components/Agent/AgentDetailHeader';
import { AgentTransactionStatus, RootState } from '../types';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import {
  fetchAgentCommissionAdvances,
  fetchAgentDetail,
  fetchAgentPerformance,
  fetchAgentReferrals,
  fetchIncomeOverview,
} from '../slices/AgentSlice';
import { fetchFrontLineForAgent } from '../slices/NetworkSlice';
import { getCurrentTransactions } from '../slices/TransactionSlice';
import DetailPageLoader from '../components/DetailPageLoader';
import AgentDetailActionBar from '../components/Agent/AgentDetailActionBar';
import AgentDetailTransactionsRoute from './AgentDetailTransactionsRoute';
import AgentDetailInfoRoute from './AgentDetailInfoRoute';
import AgentDetailReferralsRoute from './AgentDetailReferralsRoute';
import AgentDetailCreditsRoute from './AgentDetailCreditsRoute';
import AgentDetailPaymentsRoute from './AgentDetailPaymentsRoute';
import AgentDetailEquityRoute from './AgentDetailEquityRoute';

interface Match {
  id: string;
}

interface AgentDetailRouteProps extends RouteComponentProps<Match> {}

const AgentDetailRoute: React.FC<AgentDetailRouteProps> = ({
  match,
  location,
}) => {
  const agentId = match.params.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    agentDetail,
    network: { networksById },
    userIds: { usersById },
    transaction: { currentTransactionOverview },
  } = useSelector((state: RootState) => state);

  const fetchData = useCallback(
    async () =>
      Promise.all([
        dispatch(fetchAgentDetail(agentId)),
        dispatch(fetchFrontLineForAgent(agentId)),
        dispatch(fetchAgentReferrals(agentId)),
        dispatch(fetchAgentCommissionAdvances(agentId)),
        dispatch(getCurrentTransactions(agentId)),
        dispatch(fetchIncomeOverview(agentId)),
        dispatch(fetchAgentPerformance(agentId)),
      ]),
    [agentId, dispatch],
  );

  useEffect(() => {
    fetchData().finally(() => {
      setLoading(false);
    });
  }, [fetchData]);

  const path = [
    { title: 'Home', url: '/' },
    { title: 'People', url: '/people' },
  ];

  if (agentDetail.detail) {
    path.push({
      title: agentDetail.detail.fullName!,
      url: `/people/${agentDetail.detail.id}`,
    });

    if (location.pathname.includes('transactions')) {
      path.push({
        title: 'Transactions',
        url: `/people/${agentDetail.detail.id}/transactions`,
      });
    }

    if (location.pathname.includes('referrals')) {
      path.push({
        title: 'Referrals',
        url: `/people/${agentDetail.detail.id}/referrals`,
      });
    }

    if (location.pathname.includes('credits')) {
      path.push({
        title: 'Credits',
        url: `/people/${agentDetail.detail.id}/credits`,
      });
    }

    if (location.pathname.includes('payments')) {
      path.push({
        title: 'Payments',
        url: `/people/${agentDetail.detail.id}/payments`,
      });
    }

    if (location.pathname.includes('equity')) {
      path.push({
        title: 'Equity',
        url: `/people/${agentDetail.detail.id}/equity`,
      });
    }
  }

  return (
    <ResourceContainer
      loading={loading}
      LoaderComponent={<DetailPageLoader />}
      errorCode={agentDetail.fetchDetailErrorCode}
      isEmpty={!agentDetail.detail}
      resourceName='agent'
    >
      {!!agentDetail.detail && (
        <PageLayout path={path}>
          <div className='flex flex-row space-x-4 px-4 py-2'>
            <AgentDetailActionBar agentInfo={agentDetail.detail} />
          </div>
          <div className='p-5 flex flex-row bg-mineshaft-500'>
            <AgentDetailHeader
              agentInfo={agentDetail}
              transactionOverview={[
                {
                  title: currentTransactionOverview?.openTransactions?.length!,
                  subtitle: AgentTransactionStatus.ACTIVE,
                },
                {
                  title: currentTransactionOverview?.closedTransactions
                    ?.length!,
                  subtitle: AgentTransactionStatus.CLOSED,
                },
              ]}
            />
          </div>
          <Route path={match.path} exact>
            <AgentDetailInfoRoute
              agentDetail={agentDetail}
              networks={networksById[agentId]}
              users={usersById}
              currentTransactionOverview={currentTransactionOverview!}
            />
          </Route>
          <Route path={`${match.path}/referrals`} exact>
            <AgentDetailReferralsRoute
              agent={agentDetail.detail}
              referrals={agentDetail.referrals!}
            />
          </Route>
          <Route path={`${match.path}/credits`} exact>
            <AgentDetailCreditsRoute agent={agentDetail.detail!} />
          </Route>
          <Route
            path={`${match.path}/transactions`}
            component={AgentDetailTransactionsRoute}
            exact
          />
          <Route
            path={`${match.path}/payments`}
            component={AgentDetailPaymentsRoute}
            exact
          />
          <Route
            path={`${match.path}/equity`}
            component={AgentDetailEquityRoute}
            exact
          />
        </PageLayout>
      )}
    </ResourceContainer>
  );
};

export default AgentDetailRoute;
