import * as d3 from 'd3';
import { FilterList } from '@material-ui/icons';
import { useState, useEffect } from 'react';
import DefaultLoader from '../../components/DefaultLoader';
import ResourceContainer from '../../components/ResourceContainer';
import IconButton from '../../components/IconButton';
import TransactionCommissionMap from '../components/TransactionCommissionMap';
import {
  getTransactions,
  Transaction,
} from '../utils/TransactionCommissionMapUtils';

interface AgentApplicationsMapRouteProps {}

const TransactionCommissionMapRoute: React.FC<AgentApplicationsMapRouteProps> = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [startDateRef, setStartDateRef] = useState<any>(null);
  const [endDateRef, setEndDateRef] = useState<any>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [data, setData] = useState<Transaction[] | null>(null);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    getTransactions()
      .then(setData)
      .finally(() => setLoading(false));
  }, [setData, setLoading]);

  return (
    <ResourceContainer
      loading={isLoading}
      LoaderComponent={<DefaultLoader />}
      isEmpty={!data}
      resourceName='agent application'
    >
      <div className='px-10 py-5 pb-10 flex flex-col gap-3'>
        <span className='text-2xl'>Transaction Commission Map</span>
        <hr />
        <div className='relative' title='table-options'>
          <div className='flex flex-row flex-nowrap space-x-3 justify-end'>
            <IconButton
              label='Filter'
              leftIcon={<FilterList fontSize='small' titleAccess='filter' />}
              variant='outline'
              onClick={() => setFilterOpen(!filterOpen)}
            />

            {filterOpen && (
              <div className='absolute z-10 rounded bg-white w-60 ring-2 ring-gray-200 mt-10 shadow-lg flex flex-col'>
                <div className='p-2 flex flex-row items-center gap-5'>
                  <p className='text-gray-600'>Filters</p>
                  <IconButton
                    buttonType='reset'
                    label='Clear'
                    variant='default'
                    onClick={() => {
                      d3.select(startDateRef).node().value = '';
                      d3.select(endDateRef).node().value = '';
                      setStartDate('');
                      setEndDate('');
                    }}
                  />
                </div>

                <div className='border-t border-gray-200 p-2'>
                  <label>
                    <span className='text'>Start Date:</span>
                    <input
                      className='rounded mx-2'
                      type='date'
                      ref={setStartDateRef}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </label>
                </div>
                <div className='border-t border-gray-200 p-2'>
                  <label>
                    <span>End Date:</span>
                    <input
                      className='rounded mx-2'
                      type='date'
                      ref={setEndDateRef}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
        <TransactionCommissionMap
          width={1000}
          height={700}
          startDate={startDate}
          endDate={endDate}
          transactions={data}
        />
      </div>
    </ResourceContainer>
  );
};

export default TransactionCommissionMapRoute;
