import React from 'react';

export interface IdentificationCardProps {
  icon: React.ReactElement;
  name: string;
  value: number | string | React.ReactElement;
}

const IdentificationCard: React.FC<IdentificationCardProps> = ({
  icon,
  name,
  value,
}) => {
  const ValueTag =
    typeof value === 'string' || typeof value === 'number' ? 'p' : 'div';

  return (
    <div className='w-full flex items-center border rounded border-gray-200 px-4 py-2'>
      <div className='rounded-full bg-dark w-10 h-10 flex items-center justify-center text-white'>
        {icon}
      </div>
      <div className='ml-3'>
        <p className='font-primary-medium'>{name}</p>
        <ValueTag className='font-primary-medium'>{value}</ValueTag>
      </div>
    </div>
  );
};

export default IdentificationCard;
