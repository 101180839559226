import classNames from 'classnames';
import React from 'react';

export interface SectionHeaderProps {
  icon?: React.ReactElement;
  title: string;
  actionText?: string;
  actionIcon?: React.ReactElement;
  onActionClick?(): void;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  icon,
  title,
  actionIcon,
  actionText,
  onActionClick,
}) => {
  return (
    <div className='flex flex-row items-center'>
      {!!icon && (
        <span className='flex items-center rounded bg-dark text-white p-1 text-base mr-2'>
          {icon}
        </span>
      )}
      <p className='text-base font-primary-medium text-dark'>{title}</p>
      {(!!actionIcon || !!actionText) && (
        <button
          onClick={onActionClick}
          className={classNames(
            'flex flex-row rounded items-center focus:outline-none ml-2 text-primary',
          )}
        >
          {!!actionIcon && (
            <span className={classNames('text-primary')}>{actionIcon}</span>
          )}
          {!!actionText && (
            <span className={classNames('text-primary')}>{actionText}</span>
          )}
        </button>
      )}
    </div>
  );
};

export default SectionHeader;
