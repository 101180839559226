import React from 'react';
import { useForm } from 'react-hook-form';
import SidebarModal from '../components/SidebarModal';
import ControlledTextInput from '../components/ControlledTextInput';
import Button from '../components/Button';
import { CreditResponse, CreditResponseTypeEnum } from '../openapi/arrakis';
import Logger from '../utils/Logger';

interface EditAgentCreditFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  agentCredit: CreditResponse;
}

const EditAgentCreditFormSidebarModal: React.FC<EditAgentCreditFormSidebarModalProps> = ({
  isOpen,
  onClose,
  agentCredit,
}) => {
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (values: any) => {
    Logger.log('[values]', values);
  };

  return (
    <SidebarModal
      title='Edit a Agent Credit'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Issuer Title'
              name='title'
              errors={errors}
              rules={{
                required: 'Issuer title is required',
              }}
            />
          </div>
          {agentCredit?.type ===
            CreditResponseTypeEnum.NumberOfPersonalTransactions ||
          agentCredit?.type ===
            CreditResponseTypeEnum.NumberOfExecutedTransactions ? (
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                label='Number'
                name='number'
                errors={errors}
                rules={{
                  required: 'number is required',
                }}
              />
            </div>
          ) : (
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                label='Credit Amount in USD'
                name='amount'
                errors={errors}
                rules={{
                  required: 'Credit amount is required',
                }}
              />
            </div>
          )}
          <div className='mt-5 pb-20'>
            <ControlledTextInput
              control={control}
              label='Issuer Note'
              name='issuerNote'
              errors={errors}
              rules={{
                required: 'Issuer note is required',
              }}
            />
          </div>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EditAgentCreditFormSidebarModal;
