import React from 'react';
import { EnumMap } from '../../../types';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { CommissionAdvanceResponseAdvanceStatusEnum } from '../../../openapi/arrakis';

interface AgentCommissionAdvancesStatusCellProps {
  status: CommissionAdvanceResponseAdvanceStatusEnum;
}

const statusToVariantClassnameMap: EnumMap<
  CommissionAdvanceResponseAdvanceStatusEnum,
  PillVariantType
> = {
  [CommissionAdvanceResponseAdvanceStatusEnum.Approved]: 'primary',
  [CommissionAdvanceResponseAdvanceStatusEnum.Repaid]: 'success',
  [CommissionAdvanceResponseAdvanceStatusEnum.AgreementReceived]: 'warning',
  [CommissionAdvanceResponseAdvanceStatusEnum.Rejected]: 'danger',
};

export const AgentCommissionAdvancesStatusCell: React.FC<AgentCommissionAdvancesStatusCellProps> = ({
  status,
}) => {
  return (
    <Pill
      text={capitalizeEnum(status)}
      variant={statusToVariantClassnameMap[status]}
    />
  );
};

export default AgentCommissionAdvancesStatusCell;
