import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { AppThunk, ErrorCode, OfficeState } from '../types';
import MockRealApi from '../utils/MockRealApi';
import ErrorService from '../services/ErrorService';
import { OfficeControllerApi, OfficeResponse } from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';
// import { DefaultOfficeDetailResponse } from '../fixtures/api/office/DefaultOfficeDetailResponse';

export const initialState: OfficeState = {
  loadingDetail: false,
  officeDetail: undefined,
  fetchDetailErrorCode: null,
  calendarEvents: [],
  officeDetailById: {},
};

const OfficeSlice = createSlice({
  name: 'office',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveOfficeDetail(state, action: PayloadAction<OfficeResponse>) {
      state.officeDetail = action.payload;
      state.fetchDetailErrorCode = null;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
    addCalendarEvents(state, action: PayloadAction<any>) {
      state.calendarEvents = _.chain(action.payload)
        .groupBy((day) => DateTime.fromISO(day.startDate).toFormat('ccc DD'))
        .map((value, key) => ({ day: key, events: value }))
        .value();
    },
    saveOfficeDetailById(state, action: PayloadAction<OfficeResponse>) {
      state.officeDetailById[action.payload.id!] = action.payload;
    },
  },
});

export const {
  changeLoadingDetail,
  errorFetchingDetail,
  addCalendarEvents,
  saveOfficeDetail,
  saveOfficeDetailById,
} = OfficeSlice.actions;

export const fetchOfficeCalendarEvents = (id: number): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingDetail(true));
  try {
    const api = new MockRealApi();
    const { events } = await api.getOfficeCalendarEvents(id);
    dispatch(addCalendarEvents(events));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch office calendar events', e, {
      office: { id },
    });
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const fetchOfficeDetailById = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new OfficeControllerApi(
      getYentaConfiguration(),
    ).getOfficeByIdUsingGET(id);
    dispatch(saveOfficeDetail(data));
    dispatch(saveOfficeDetailById(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch office detail', e, { office: { id } });
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export default OfficeSlice.reducer;
