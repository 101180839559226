import React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import SidebarModal from '../components/SidebarModal';
import ControlledTextInput from '../components/ControlledTextInput';
import { numberToMoney } from '../utils/CurrencyUtils';
import { OutgoingPaymentResponse } from '../openapi/arrakis';
import { capitalizeEnum } from '../utils/StringUtils';

interface OutgoingPaymentSidebarModalProps {
  isOpen: boolean;
  onClose(reload?: boolean): void;
  outgoingPayment: OutgoingPaymentResponse;
}

const OutgoingPaymentSidebarModal: React.FC<OutgoingPaymentSidebarModalProps> = ({
  isOpen,
  onClose,
  outgoingPayment,
}) => {
  const { control, errors } = useForm();

  return (
    <SidebarModal title='Outgoing Payment' isOpen={isOpen} onClose={onClose}>
      <form className='flex flex-col flex-auto justify-between h-full'>
        <div className='p-4'>
          <ControlledTextInput
            control={control}
            label='Status'
            name='status'
            defaultValue={
              outgoingPayment?.status && capitalizeEnum(outgoingPayment?.status)
            }
            errors={errors}
            readOnly
          />
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Transaction Code'
              name='transactionCode'
              defaultValue={outgoingPayment?.transactionCode}
              errors={errors}
              readOnly
            />
          </div>
          {outgoingPayment?.transactionCode && (
            <Link to={`/transactions/code/${outgoingPayment?.transactionCode}`}>
              <span className='text-primary flex items-center'>
                View Transaction <FiExternalLink className='ml-2' />
              </span>
            </Link>
          )}
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Full Name'
              name='fullName'
              defaultValue={`${outgoingPayment?.firstName} ${outgoingPayment?.lastName}`}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Amount'
              name='outgoingPaymentdAmount'
              defaultValue={
                outgoingPayment?.amount?.amount &&
                numberToMoney(outgoingPayment?.amount?.amount)
              }
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Paid At'
              name='paidAt'
              defaultValue={
                outgoingPayment?.paidAt &&
                DateTime.fromMillis(outgoingPayment?.paidAt).toFormat(
                  'LL/dd/yy',
                )
              }
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Paid By'
              name='paidBy'
              defaultValue={outgoingPayment?.paidBy}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Confirmation Code'
              name='confirmationCode'
              defaultValue={outgoingPayment?.confirmationCode}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Payer Note'
              name='payerNote'
              defaultValue={outgoingPayment?.payerNote}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Payment System'
              name='paymentSystem'
              defaultValue={
                outgoingPayment?.paymentSystem &&
                capitalizeEnum(outgoingPayment?.paymentSystem)
              }
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5 pb-20'>
            <ControlledTextInput
              control={control}
              label='Payment System ID'
              name='paymentSystemId'
              defaultValue={outgoingPayment?.paymentSystemId}
              errors={errors}
              readOnly
            />
          </div>
        </div>
      </form>
    </SidebarModal>
  );
};

export default OutgoingPaymentSidebarModal;
