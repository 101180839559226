import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CDAForm from './CDAForm';

interface Match {
  id: string;
}

interface CDAFormPdfRouteProps extends RouteComponentProps<Match> {}

const CDAFormPdfRoute: React.FC<CDAFormPdfRouteProps> = ({ match }) => {
  const { id } = match.params;

  return <CDAForm commissionDocumentId={id} isPdf />;
};

export default CDAFormPdfRoute;
