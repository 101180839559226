import { values } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AgentResponse, TeamResponseTypeEnum } from '../../openapi/yenta';
import {
  createDomesticTeam,
  createNormalTeam,
  createPlatinumTeam,
} from '../../slices/TeamSlice';
import { AppDispatch, AsyncSelectOption } from '../../types';
import { capitalizeEnum, NAME_REGEX } from '../../utils/StringUtils';
import { searchForRegisteredAgents } from '../../utils/TableUtils';
import Button from '../Button';
import ControlledAsyncSelectInput from '../ControlledAsyncSelectInput';
import ControlledSelectInput from '../ControlledSelectInput';
import ControlledTextInput from '../ControlledTextInput';
import SidebarModal from '../SidebarModal';

interface CreateTeamFormProps extends RouteComponentProps {
  isOpen: boolean;
  onClose(): void;
}

const CreateTeamForm: React.FC<CreateTeamFormProps> = ({
  isOpen,
  onClose,
  history,
}) => {
  const {
    control,
    errors,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const dispatch: AppDispatch = useDispatch();

  const onSubmit = async (data: any) => {
    let teamId: string | undefined;
    switch (data.teamType) {
      case TeamResponseTypeEnum.Domestic:
        teamId = await dispatch(
          createDomesticTeam({
            leaderAgentId: data.leaderAgentId.value,
            name: data.name,
          }),
        );
        break;
      case TeamResponseTypeEnum.Normal:
        teamId = await dispatch(
          createNormalTeam({
            leaderAgentId: data.leaderAgentId.value,
            memberCommissionSplit: +data.commissionSplit,
            members: [],
            name: data.name,
          }),
        );
        break;
      case TeamResponseTypeEnum.Platinum:
        teamId = await dispatch(
          createPlatinumTeam({
            leaderAgentId: data.leaderAgentId.value,
            memberCommissionSplit: +data.commissionSplit,
            name: data.name,
          }),
        );
        break;
    }

    if (teamId) {
      history.push(`/teams/${teamId}`);
    }
  };
  const teamType = watch('teamType');

  return (
    <SidebarModal
      title='Add New Team'
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='create-team-form'
      >
        <div className='p-4'>
          <div>
            <ControlledTextInput
              control={control}
              label='Team Name'
              name='name'
              placeholder='Enter Team Name'
              errors={errors}
              rules={{
                required: 'Please provide a team name',
                pattern: {
                  value: NAME_REGEX,
                  message: 'Please enter a valid team name',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledAsyncSelectInput
              control={control}
              errors={errors}
              name='leaderAgentId'
              placeholder='Search Member'
              label='Team Leader'
              fetchData={async (search, page) => {
                const searchResponse = await searchForRegisteredAgents<
                  Pick<AgentResponse, 'id' | 'firstName' | 'lastName'>
                >(
                  ['id', 'firstName', 'lastName'],
                  ['firstName', 'lastName'],
                  page,
                  search,
                );

                const options: AsyncSelectOption[] = searchResponse.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName}`,
                  }),
                );

                return options;
              }}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              name='teamType'
              control={control}
              errors={errors}
              label='Type'
              placeholder='Team Type'
              options={[
                {
                  label: `Select Type`,
                  value: '',
                  disabled: true,
                },
                ...values(TeamResponseTypeEnum)
                  .filter((val) => val !== TeamResponseTypeEnum.Group)
                  .map((type) => ({
                    value: type,
                    label: capitalizeEnum(type),
                  })),
              ]}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          {(teamType === TeamResponseTypeEnum.Normal ||
            teamType === TeamResponseTypeEnum.Platinum) && (
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                type='number'
                label='Commission Split'
                name='commissionSplit'
                errors={errors}
                placeholder='Commission Split'
                rules={{
                  required: 'Required',
                  min: { value: 0, message: 'Cannot be less than 0' },
                  max: {
                    value: teamType === TeamResponseTypeEnum.Normal ? 75 : 100,
                    message:
                      teamType === TeamResponseTypeEnum.Normal
                        ? 'Cannot be greater than 75'
                        : 'Cannot be greater than 100',
                  },
                }}
              />
            </div>
          )}
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200  bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2  w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Create'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default withRouter(CreateTeamForm);
