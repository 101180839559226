import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import { useEffect, useState } from 'react';
import { GeometryObject, Topology } from 'topojson-specification';
import { FeatureCollection } from 'geojson';
import {
  Application,
  getStateApplicationMap,
} from '../utils/AgentApplicationMapUtils';
import { legend } from '../utils/d3ColorLegend';
import us from '../assets/json/us-10m.v2.json';

interface AgentApplicationMapProps {
  width: number;
  height: number;
  startDate: string;
  endDate: string;
  applications: Application[] | null;
}

const AgentApplicationMap: React.FC<AgentApplicationMapProps> = ({
  width,
  height,
  startDate,
  endDate,
  applications,
}) => {
  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!svgRef || !tooltipRef || !applications) return;

    const normalise = (x: number) => 1 / (1 + Math.exp(-x));
    const color = (x: number) => d3.interpolateGreys(normalise(x));
    const data = getStateApplicationMap(applications, startDate, endDate);
    const path = d3.geoPath<any>();
    const svg = d3.select(svgRef).html('');
    const tooltip = d3
      .select(tooltipRef)
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('background-color', '#010101')
      .style('color', '#fff')
      .style('padding', '8px 16px')
      .style('border-radius', '5px')
      .on('mouseover', () => tooltip.style('opacity', 1));

    let max = 0;
    for (let element of data.values()) max = Math.max(max, element);

    legend({
      color: d3.scaleSequential([0, max], color),
      width: width / 3,
      title: 'Agent Applications',
      svg,
      translateX: (2 * width) / 3 - 100,
      translateY: height - 100,
    });

    // prettier-ignore
    svg.append("g")
			.selectAll("path")
			.data(((topojson.feature((us as unknown) as Topology, us.objects.states as GeometryObject)) as FeatureCollection).features)
			.join("path")
				.attr("fill", "#fff")
				.attr("d", path)
				.on("mousemove", (e, d: any) => {
					e.target.style.opacity = 0.7;
					tooltip.transition().duration(10).style("opacity", 0.9).style("display", "block");
					tooltip.html(`State: ${d.properties.name} <br /> Applications: ${ data.get(d.properties.name.toLowerCase()) ? data.get(d.properties.name.toLowerCase()) : 0 }`)
						.style("left", e.pageX + 10 + "px")
						.style("top", e.pageY - 40 - 16 + "px");
				})
				.on("mouseout", e => {
					e.target.style.opacity = 1;
					tooltip.transition().duration(100).style("opacity", 0).style("display", "none");
				})
				.transition()
				.duration(200)
				.attr("fill", (d: any) => {
					const value = data.get(d.properties.name.toLowerCase());

					if (value) return color(value);
					else return color(-1.386299);
				});

    // prettier-ignore
    svg
      .append('path')
      .datum(topojson.mesh((us as unknown) as Topology, us.objects.states as GeometryObject, (a, b) => a !== b))
        .attr('fill', 'none')
        .attr('stroke', 'white')
        .attr('stroke-linejoin', 'round')
        .attr('d', path);
  }, [svgRef, tooltipRef, startDate, endDate, applications, width, height]);

  return (
    <div className='application-map'>
      <svg width={width} height={height} ref={setSvgRef} />
      <div className='tooltip' ref={setTooltipRef} />
    </div>
  );
};

export default AgentApplicationMap;
