import { MonitorEventResponseEventStatusEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

const MonitorEventResponseStatusEnumSelectColumnFilter = <D extends object>(
  props: SelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(MonitorEventResponseEventStatusEnum)(
    props,
  );
};

export default MonitorEventResponseStatusEnumSelectColumnFilter;
