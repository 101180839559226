import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import {
  ApplicationFormFieldName,
  applicationFormQuestions,
} from '../../testUtils/OnboardingUtils';
import ApplicationLayout from '../ApplicationLayout';
import { RootState } from '../../types';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import { ApplicationResponseNextStatusEnum } from '../../openapi/yenta';
import Review from './application-form/Review';
import StepQuestionForm from './application-form/StepQuestionForm';

export interface ApplicationFormProps extends RouteComponentProps {}

export type FormDataType = {
  [X in ApplicationFormFieldName]: any;
};

const ApplicationForm: React.FC<ApplicationFormProps> = ({ history }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState<Partial<FormDataType>>({});
  const [isReviewing, setIsReviewing] = useState(false);
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const questions = applicationFormQuestions(
    userDetail?.fullName!,
    get(formData, 'selectedCountry'),
    get(formData, 'selectedProvinces'),
  );

  useRedirectAwayOnboardingEffect(
    ApplicationResponseNextStatusEnum.Started,
    history,
  );

  return (
    <ApplicationLayout>
      {isReviewing ? (
        <Review
          questions={questions}
          formData={formData as FormDataType}
          title='Please review your application before submitting'
          setIsReviewing={setIsReviewing}
          setCurrentIndex={setCurrentIndex}
        />
      ) : (
        <StepQuestionForm
          questions={questions}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          setIsReviewing={setIsReviewing}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </ApplicationLayout>
  );
};

export default ApplicationForm;
