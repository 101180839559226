import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CDAForm from './CDAForm';

interface Match {
  id: string;
}

interface CDAFormRouteProps extends RouteComponentProps<Match> {}

const CDAFormRoute: React.FC<CDAFormRouteProps> = ({ match }) => {
  const { id } = match.params;

  return <CDAForm commissionDocumentId={id} isPdf={false} />;
};

export default CDAFormRoute;
