import { Check, Clear } from '@material-ui/icons';
import React from 'react';
import classNames from 'classnames';

interface BooleanButtonProps {
  icon?: boolean;
  value: boolean;
  className?: string;
}
const BooleanButton: React.FC<BooleanButtonProps> = ({
  icon = true,
  value,
  className,
}) => {
  return value ? (
    <span
      className={classNames(
        'rounded px-2 py-1 text-sm space-x-2',
        className ?? 'bg-green-200 text-green-800',
      )}
    >
      {icon && <Check fontSize='small' />} YES
    </span>
  ) : (
    <span
      className={classNames(
        'rounded px-2 py-1 text-sm space-x-2',
        className ?? 'bg-red-200 text-red-800',
      )}
    >
      {icon && <Clear fontSize='small' />} NO
    </span>
  );
};

export default BooleanButton;
