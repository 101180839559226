import React, { useCallback, useEffect, useState } from 'react';
import { values } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SidebarModal from '../components/SidebarModal';
import ControlledTextInput from '../components/ControlledTextInput';
import ControlledSelectInput from '../components/ControlledSelectInput';
import {
  ParticipantValueRoleEnum,
  PaymentParticipantValue,
  TransactionResponse,
  ParticipantValue,
  UpdateParticipantRequest,
  TransactionControllerApi,
} from '../openapi/arrakis';
import {
  capitalizeEnum,
  getFileNameFromUrl,
  NAME_REGEX,
} from '../utils/StringUtils';
import Button from '../components/Button';
import { AppDispatch, AsyncSelectOption } from '../types';
import ControlledAsyncSelectInput from '../components/ControlledAsyncSelectInput';
import { searchForRegisteredAgents } from '../utils/TableUtils';
import { AgentResponse } from '../openapi/yenta';
import {
  EMAIL_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../utils/Validations';
import {
  updateTransactionParticipant,
  uploadW9Form,
} from '../slices/TransactionSlice';
import ControlledToggleInput from '../components/ControlledToggleInput';
import ControlledPhoneNumberInput from '../components/ControlledPhoneNumberInput';
import ControlledFileUploadInput from '../components/ControlledFileUploadInput';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { showErrorToastForErrorCode } from '../slices/ToastNotificationSlice';
import ErrorService from '../services/ErrorService';

interface EditTransactionParticipantFormProps {
  transaction: TransactionResponse;
  participant: PaymentParticipantValue | ParticipantValue | null;
  isOpen: boolean;
  onClose(): void;
}

const EditTransactionParticipantFormSidebarModal: React.FC<EditTransactionParticipantFormProps> = ({
  transaction,
  participant,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [w9FileUrl, setW9FileUrl] = useState<string>('');
  const {
    control,
    errors,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const participantRole: ParticipantValueRoleEnum = watch(
    'participantRole',
    participant?.role,
  );

  const onSubmit = async (values: any) => {
    if (values.w9form) {
      await dispatch(uploadW9Form(participant?.id!, values.w9form[0]));
    }

    const data: UpdateParticipantRequest = {
      emailAddress: values.emailAddress || participant?.emailAddress,
      firstName: values.firstName || participant?.firstName,
      lastName: values.lastName || participant?.lastName,
      phoneNumber: values.phoneNumber || participant?.phoneNumber,
      commissionDocumentRecipient:
        values.commissionDocumentRecipient ??
        !!participant?.commissionDocumentRecipient,
      payer: !!participant?.commissionDocumentPayer,
      passThrough: values.passThrough ?? !!participant?.passThrough,
      paidByReal: values.paidByReal ?? !!participant?.paidByReal,
      personalDeal:
        values.personalDeal ??
        ('personalDeal' in participant! ? !!participant.personalDeal : false),
      participantRole: values.participantRole,
      address: values.address,
      company: values.company || participant?.company || '',
    };

    const success = await dispatch(
      updateTransactionParticipant(participant?.id!, transaction.id!, data),
    );
    if (success) {
      onClose();
    }
  };

  const w9File = useCallback(async () => {
    setW9FileUrl('');

    // Force refresh on isOpen to handle bug where you upload a W9, close the modal, and
    // then reopen it for the same user.
    if (isOpen && participant?.externalParticipant) {
      try {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getW9UrlUsingGET(participant?.id!);

        setW9FileUrl(data);
      } catch (e) {
        dispatch(showApiErrorModal(e));
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the w9 form',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    }
  }, [dispatch, participant?.externalParticipant, participant?.id, isOpen]);

  useEffect(() => {
    w9File();
  }, [w9File]);

  const w9FileName =
    w9FileUrl && getFileNameFromUrl(w9FileUrl) !== 'null'
      ? getFileNameFromUrl(w9FileUrl)
      : '';

  return (
    <SidebarModal
      title={`Edit Participant: ${participant?.firstName} ${participant?.lastName}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-2 md:p-4'>
          <div>
            <ControlledSelectInput
              name='participantRole'
              control={control}
              errors={errors}
              label='Role'
              defaultValue={participant?.role}
              placeholder='Role'
              options={[
                {
                  label: `Select Role`,
                  value: '',
                  disabled: true,
                },
                ...values(ParticipantValueRoleEnum).map((role) => ({
                  value: role,
                  label: capitalizeEnum(role),
                })),
              ]}
            />
          </div>
          {!participant?.externalParticipant ? (
            <div className='mt-5'>
              <ControlledAsyncSelectInput
                control={control}
                errors={errors}
                name='agentName'
                defaultValue={{
                  label: `${participant?.firstName} ${participant?.lastName}`,
                  value: participant?.yentaId,
                }}
                placeholder='Search'
                label='Name of Participant'
                disabled
                fetchData={async (search, page) => {
                  const searchResponse = await searchForRegisteredAgents<
                    Pick<AgentResponse, 'id' | 'firstName' | 'lastName'>
                  >(
                    ['id', 'firstName', 'lastName'],
                    ['firstName', 'lastName'],
                    page,
                    search,
                  );

                  const options: AsyncSelectOption[] = searchResponse.map(
                    (resp) => ({
                      value: `${resp.id}`,
                      label: `${resp.firstName} ${resp.lastName}`,
                    }),
                  );

                  return options;
                }}
                rules={{
                  required: 'Required',
                }}
              />
            </div>
          ) : (
            <div>
              <div className='mt-5 flex flex-row space-x-2'>
                <ControlledTextInput
                  control={control}
                  label='First Name'
                  name='firstName'
                  placeholder='John'
                  errors={errors}
                  rules={{
                    minLength: {
                      value: 2,
                      message: 'Must be at least 2 characters',
                    },
                    pattern: {
                      value: NAME_REGEX,
                      message: 'Invalid Name',
                    },
                  }}
                  defaultValue={participant?.firstName}
                />
                <ControlledTextInput
                  control={control}
                  label='Last Name'
                  name='lastName'
                  placeholder='Doe'
                  errors={errors}
                  rules={{
                    minLength: {
                      value: 2,
                      message: 'Must be at least 2 characters',
                    },
                    pattern: {
                      value: NAME_REGEX,
                      message: 'Invalid Name',
                    },
                  }}
                  defaultValue={participant?.lastName}
                />
              </div>
              <div className='mt-5'>
                <ControlledTextInput
                  control={control}
                  label='Email'
                  name='emailAddress'
                  defaultValue={participant?.emailAddress}
                  placeholder='jane@example.com'
                  errors={errors}
                  rules={{
                    ...EMAIL_VALIDATIONS,
                  }}
                />
              </div>
              <div className='mt-5'>
                <ControlledPhoneNumberInput
                  control={control}
                  label='Mobile Number'
                  name='phoneNumber'
                  defaultValue={participant?.phoneNumber}
                  placeholder='+1 (702) 123-4567'
                  errors={errors}
                  rules={{
                    ...PHONE_NUMBER_VALIDATIONS,
                  }}
                />
              </div>
              <div className='mt-5'>
                <ControlledTextInput
                  control={control}
                  label='Address'
                  name='address'
                  defaultValue={participant?.address}
                  placeholder='P150, New Amsterdam, New York'
                  errors={errors}
                  rules={{
                    required: 'Please enter your address',
                  }}
                />
              </div>
              <div className='mt-5'>
                <ControlledTextInput
                  control={control}
                  label='Brokerage Name'
                  name='company'
                  defaultValue={participant?.company}
                  placeholder='Brokerage Name'
                  errors={errors}
                  rules={{
                    required: 'Please enter your brokerage name',
                  }}
                />
              </div>
              <div className='mt-5'>
                <ControlledFileUploadInput
                  errors={errors}
                  name='w9form'
                  control={control}
                  label='Upload W9 PDF'
                  placeholder={w9FileName || 'Choose file'}
                  accept='.pdf'
                  rightAction={
                    w9FileName
                      ? {
                          text: 'View W9',
                          onClick() {
                            window.open(w9FileUrl, '_blank');
                          },
                        }
                      : undefined
                  }
                />
              </div>
            </div>
          )}
          {!participant?.commissionDocumentPayer &&
            participantRole !== ParticipantValueRoleEnum.ExternalAgent && (
              <div className='mt-5 pb-20'>
                <div className='divide-y border rounded'>
                  {participant && 'personalDeal' in participant! && (
                    <ControlledToggleInput
                      name='personalDeal'
                      control={control}
                      errors={errors}
                      defaultValue={participant.personalDeal}
                      title='Personal Deal?'
                    />
                  )}
                  <ControlledToggleInput
                    name='commissionDocumentRecipient'
                    control={control}
                    errors={errors}
                    defaultValue={participant?.commissionDocumentRecipient}
                    title='Receives Invoice/CDA?'
                  />
                  <ControlledToggleInput
                    name='paidByReal'
                    control={control}
                    errors={errors}
                    defaultValue={participant?.paidByReal}
                    title='Is Single Check?'
                  />
                  <ControlledToggleInput
                    name='passThrough'
                    control={control}
                    errors={errors}
                    defaultValue={participant?.passThrough}
                    title='Pass Through Deal?'
                  />
                </div>
              </div>
            )}
        </div>

        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Update'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EditTransactionParticipantFormSidebarModal;
