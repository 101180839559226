import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import GridCard from '../components/GridCard';
import HighlightedText from '../components/HighlightedText';
// import ScanQRCode from '../components/ScanQRCode';
import ResourceContainer from '../components/ResourceContainer';
import { ErrorCode } from '../types';
import { numberToMoney } from '../utils/CurrencyUtils';
import FormLayout from '../components/FormLayout';
import ErrorService from '../services/ErrorService';
import PagedPrint from '../components/PagedPrint';
import {
  CommissionDocumentControllerApi,
  CommissionDocumentPayeeResponseTransactionParticipantRoleEnum,
  CommissionDocumentResponse,
} from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import DivContainer from '../components/DivContainer';
import { getDocumentIdentifier } from '../utils/CommissionDocumentHelper';
import { constructBillTo } from '../utils/InvoiceHelper';

interface InvoiceFormProps {
  commissionDocumentId: string;
  isPdf: boolean;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({
  commissionDocumentId,
  isPdf,
}) => {
  const [invoiceData, setInvoiceData] = useState<CommissionDocumentResponse>();
  const [loading, setIsLoading] = useState(true);
  const [errorCode, setErrorCode] = useState<null | ErrorCode>(null);
  const fetchInvoice = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await new CommissionDocumentControllerApi(
        getArrakisConfiguration(),
      ).getCommissionDocumentByIdUsingGET(commissionDocumentId);
      setInvoiceData(data);
    } catch (e) {
      ErrorService.notify('Unable to load invoice', e);
      setErrorCode(ErrorService.getErrorCode(e));
    } finally {
      setIsLoading(false);
    }
  }, [commissionDocumentId]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  const tableHeaderStyle =
    'py-1.5 px-3 border w-1/2 uppercase font-primary-regular text-sm print:text-xs';
  const tableDataStyle = 'border py-1.5 px-3 print:text-xs';

  const paymentRows =
    !!invoiceData &&
    invoiceData.payees?.map((item) => {
      return (
        <tr key={item.id} className='bi-avoid'>
          <td className={tableDataStyle}>{item.fullName}</td>
          <td className={tableDataStyle} align='center' />
          <td className={tableDataStyle} align='right'>
            {numberToMoney(item.amount?.amount!)}
          </td>
        </tr>
      );
    });

  const realPayee =
    invoiceData &&
    invoiceData.payees!.filter(
      (payee) =>
        payee.transactionParticipantRole ===
        CommissionDocumentPayeeResponseTransactionParticipantRoleEnum.Real,
    )[0];

  const Container = isPdf ? PagedPrint : DivContainer;

  return (
    <ResourceContainer
      loading={loading}
      errorCode={errorCode}
      isEmpty={!invoiceData}
      resourceName='Invoice'
    >
      <Container>
        {!!invoiceData && (
          <FormLayout
            title='Invoice'
            companyName={invoiceData.companyName!}
            companyAddress={invoiceData.officeAddress!}
            identifier={getDocumentIdentifier(invoiceData)}
          >
            <div className='pb-20'>
              <div className='flex flex-col md:flex-row print:flex-row justify-between'>
                <div>
                  <p className='text-2xl print:text-lg text-mineshaft font-primary-medium'>
                    Real Broker, LLC
                  </p>
                  <p className='text-mineshaft print:text-xs'>
                    27 W. 24th St., Suite 407 New York, NY 10010, United States
                  </p>
                  <div className='flex flex-col md:flex-row print:flex-row'>
                    <p className='pr-5 text-mineshaft print:text-xs'>
                      Tel: +1 413-248-7325
                    </p>
                    <p className='text-mineshaft print:text-xs'>
                      Email: support@joinreal.com
                    </p>
                  </div>
                </div>
                <div className='pt-5 md:pt-0 print:pt-0'>
                  <div className='flex flex-row justify-between space-x-5'>
                    <p className='text-gray-500 print:text-xs'>
                      Invoice Number
                    </p>
                    <p className='print:text-xs'>
                      {getDocumentIdentifier(invoiceData)}
                    </p>
                  </div>
                  <div className='flex flex-row justify-between space-x-5'>
                    <p className='text-gray-500 print:text-xs'>Date of Issue</p>
                    <p className='print:text-xs'>
                      {DateTime.fromMillis(
                        invoiceData.createdAt!,
                      ).toLocaleString(DateTime.DATE_FULL)}
                    </p>
                  </div>
                  <div className='flex flex-row justify-between space-x-5'>
                    <p className='text-gray-500 print:text-xs'>Date Due</p>
                    <p className='print:text-xs'>
                      {DateTime.fromISO(
                        invoiceData.anticipatedClosingDate!,
                      ).toLocaleString(DateTime.DATE_FULL)}
                    </p>
                  </div>
                </div>
              </div>
              <div className='py-5 grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 print:grid-cols-3 gap-5 print:text-xs'>
                <GridCard
                  items={[
                    {
                      name: 'Bill to',
                      value: constructBillTo(invoiceData.payer!),
                    },
                  ]}
                />
              </div>
              <HighlightedText
                text={`${numberToMoney(
                  invoiceData.grossCommission?.amount!,
                )} on ${DateTime.fromISO(
                  invoiceData.anticipatedClosingDate!,
                ).toLocaleString(DateTime.DATE_FULL)}`}
                textClassName='text-3xl print:text-lg font-primary-regular'
              />
              <div className='py-5'>
                <div className='flex flex-row space-x-2'>
                  <p className='text-gray-500 print:text-xs'>
                    Property address:
                  </p>
                  <p className='print:text-xs'>{invoiceData.propertyAddress}</p>
                </div>
                {/*<div className='flex flex-row space-x-2'>*/}
                {/*  <p className='text-gray-500 print:text-xs'>Tenant:</p>*/}
                {/*  <p className='print:text-xs'>{invoiceData.tenantName}</p>*/}
                {/*</div>*/}
                {/*<div className='flex flex-row space-x-2'>*/}
                {/*  <p className='text-gray-500 print:text-xs'>Monthly Rent:</p>*/}
                {/*  <p className='print:text-xs'>*/}
                {/*    {numberToMoney(invoiceData.monthlyRent, 0)}*/}
                {/*  </p>*/}
                {/*</div>*/}
                {/*<div className='flex flex-row space-x-2'>*/}
                {/*  <p className='text-gray-500 print:text-xs'>Move In Date:</p>*/}
                {/*  <p>*/}
                {/*    {DateTime.fromISO(invoiceData.moveInAt).toLocaleString()}*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
              <table className='border border-collapse w-full table-fixed'>
                <tbody className='border'>
                  <tr className='border'>
                    <th
                      className={classNames(
                        'w-1/4 md:w-1/2 print:w-1/2',
                        tableHeaderStyle,
                      )}
                      align='left'
                    >
                      Description
                    </th>
                    <th
                      className={classNames('w-1/6', tableHeaderStyle)}
                      align='center'
                    />
                    <th
                      className={classNames('w-1/6', tableHeaderStyle)}
                      align='right'
                    >
                      Amount
                    </th>
                  </tr>

                  {paymentRows}

                  <tr>
                    <td className={tableDataStyle} />
                    <td className={tableDataStyle}>Subtotal</td>
                    <td className={tableDataStyle} align='right'>
                      {numberToMoney(invoiceData.grossCommission?.amount!)}
                    </td>
                  </tr>
                  <tr>
                    <td className={tableDataStyle} />
                    <td className={tableDataStyle}>Amount Due</td>
                    <td className={tableDataStyle} align='right'>
                      {numberToMoney(invoiceData.grossCommission?.amount!)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='border-dashed border-b py-2'>
                <p className='italic text-gray-500 print:text-xs'>
                  Thank you for being our valued customer.
                </p>
              </div>
              <div className='bi-avoid'>
                <p className='mt-5 print:text-xs'>
                  Pay {numberToMoney(invoiceData.grossCommission?.amount!)} with
                  ACH or wire transfer
                </p>
                <div className='mt-3 mb-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 print:grid-cols-3 print:text-xs'>
                  <GridCard
                    key={realPayee!.id}
                    items={[
                      {
                        name: 'Bank',
                        value: realPayee!.bankName!,
                      },
                      {
                        name: 'Routing Number',
                        value: realPayee!.bankRoutingNumber!,
                      },
                      {
                        name: 'Account Number',
                        value: realPayee!.bankAccountNumber!,
                      },
                      {
                        name: 'Account Name',
                        value: realPayee!.bankAccountName!,
                      },
                    ]}
                  />
                  {/*<div className='justify-end flex col-span-1 md:col-span-1 lg:col-span-2 print:col-span-2'>*/}
                  {/*  <div className='w-24'>*/}
                  {/*    <ScanQRCode*/}
                  {/*      label='scan'*/}
                  {/*      imageUrl='https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fwww.joinreal.com%2Fabout&chs=180x180&choe=UTF-8&chld=L|2'*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </FormLayout>
        )}
      </Container>
    </ResourceContainer>
  );
};

export default InvoiceForm;
