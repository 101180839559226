import React from 'react';
import { EliteStatusValue } from '../../openapi/arrakis';
import { numberToMoney } from '../../utils/CurrencyUtils';

interface EliteStatusTrackerProps {
  eliteStatus: EliteStatusValue;
}

const EliteStatusTracker: React.FC<EliteStatusTrackerProps> = ({
  eliteStatus,
}) => {
  let gciProgressPercentage =
    (eliteStatus.gciEarned?.amount! / eliteStatus.minGCI?.amount!) * 100;

  let postCapFeeProgressPercentage =
    (eliteStatus.postCapFeesPaid?.amount! /
      eliteStatus.postCapTransactionFeesTotal?.amount!) *
    100;

  if (gciProgressPercentage > 100) {
    gciProgressPercentage = 100;
  }

  if (postCapFeeProgressPercentage > 100) {
    postCapFeeProgressPercentage = 100;
  }

  return (
    <div>
      <p>Elite Status:</p>
      <div>
        <p>{`${numberToMoney(eliteStatus.gciEarned?.amount!)} / ${numberToMoney(
          eliteStatus.minGCI?.amount!,
        )} GCI`}</p>
        <div className='my-2 bg-gray-100 rounded'>
          <div
            className='rounded bg-yellow-400'
            style={{
              width: `${gciProgressPercentage}%`,
              height: '10px',
            }}
          />
        </div>
        <p className='mt-4'>
          {`${numberToMoney(
            eliteStatus.postCapFeesPaid?.amount!,
          )} / ${numberToMoney(
            eliteStatus.postCapTransactionFeesTotal?.amount!,
          )} post-cap fees`}
        </p>
        <div className='my-2 bg-gray-100 rounded'>
          <div
            className='rounded bg-yellow-400'
            style={{
              width: `${postCapFeeProgressPercentage}%`,
              height: '10px',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EliteStatusTracker;
