import React from 'react';
import { ActiveInactiveType } from '../../../types';
import Pill from '../../Pill';

interface ProfileImageCellProps {
  status: ActiveInactiveType;
}

const ActiveInactive: React.FC<ProfileImageCellProps> = ({ status }) => {
  return (
    <Pill
      text={status === ActiveInactiveType.ACTIVE ? 'Active' : 'Inactive'}
      variant={status === ActiveInactiveType.ACTIVE ? 'success' : 'danger'}
    />
  );
};

export default ActiveInactive;
