import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PaymentCard from '../PaymentCard';
import config from '../../config';
import ApplicationLayout from '../ApplicationLayout';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import {
  ApplicationResponse,
  ApplicationResponseNextStatusEnum,
  MoneyValueCurrencyEnum,
} from '../../openapi/yenta';
import { RootState } from '../../types';
import { StateOrProvinceToDisplayName } from '../../utils/DisplayHelpers';

interface ApplicationFeeProps extends RouteComponentProps {}

const ApplicationFee: React.FC<ApplicationFeeProps> = ({ history }) => {
  const stripePromise = loadStripe(config.stripe.publishableKey);

  useRedirectAwayOnboardingEffect(
    ApplicationResponseNextStatusEnum.PayFees,
    history,
  );

  const { userDetail } = useSelector((state: RootState) => state.auth);

  const lastApplicationIndex = userDetail!.applications!.length - 1;
  const application: ApplicationResponse = userDetail!.applications![
    lastApplicationIndex
  ];

  const paymentBlocks = application.doesBusinessIn!.map((item) => {
    return (
      <div
        key={item!.administrativeArea!.stateOrProvince!}
        className='flex flex-row justify-between space-x-2'
      >
        <p>
          {StateOrProvinceToDisplayName(
            item!.administrativeArea!.stateOrProvince!,
          )}{' '}
          License Transfer Fees
        </p>

        <p>${item.administrativeArea!.licenseTransferFee!.amount!}</p>
      </div>
    );
  });

  const signUpFee: number = application.doesBusinessIn![0].administrativeArea!
    .signupFee!.amount!;
  const currency: MoneyValueCurrencyEnum = application.doesBusinessIn![0]
    .administrativeArea!.signupFee!.currency!;

  const oneTimeFee = application
    .doesBusinessIn!.map(
      (item) => item.administrativeArea!.licenseTransferFee!.amount!,
    )
    .reduce((prev, curr) => prev + curr, 0);

  const totalFee = signUpFee + oneTimeFee;

  return (
    <ApplicationLayout>
      <div className='flex items-center justify-center w-full'>
        <div className='w-full lg:max-w-7xl'>
          <div className='m-4 lg:m-8'>
            <p className='text-4xl font-primary-regular text-center text-gray-800'>
              Application Fee
            </p>
          </div>
          <div className='rounded-md grid place-items-center m-4 lg:m-8'>
            <div className='bg-dark py-8 px-4 lg:py-16 lg:px-8 rounded-b md:rounded-md text-white text-center space-y-4'>
              <p>One Time Fee</p>
              <div className='flex flex-row items-center space-x-3 justify-center pb-2'>
                <p className='text-4xl font-bold'>${totalFee}</p>
                <p className='text-3xl'>{currency}</p>
              </div>
              <div className='p-3 bg-trueGray-600 rounded flex flex-col'>
                <p className='mx-auto px-2 py-0.5 border border-gray-300 rounded-full bg-dark -mt-6 text-xs'>
                  Includes
                </p>
                <div className='space-y-2 pt-2'>
                  <div className='flex flex-row justify-between space-x-2'>
                    <p>Real Brokerage Application Fee</p>
                    <p>${signUpFee}</p>
                  </div>
                  {paymentBlocks}
                </div>
              </div>
              <p>Please enter your billing details</p>
              <Elements stripe={stripePromise}>
                <PaymentCard
                  totalFee={totalFee}
                  currency={currency}
                  applicationId={application.id!}
                />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationFee;
