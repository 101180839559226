import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainSidebar from '../containers/MainSidebar';
import { RootState } from '../types';
import { AgentResponseAgentStatusEnum } from '../openapi/yenta';
import Logger from '../utils/Logger';

export interface AuthRouteProps extends RouteProps {}

const AuthenticatedAdminRoute: React.FC<AuthRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { userDetail, userVerifyInfo } = useSelector(
    (state: RootState) => state.auth,
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userDetail) {
          Logger.debug(
            'AuthenticatedAdminRoute: no user detail, going to login',
          );
          return <Redirect to='/login' />;
        }

        if (
          userDetail?.agentStatus === AgentResponseAgentStatusEnum.Candidate
        ) {
          Logger.debug(
            'AuthenticatedAdminRoute: user is candidate, going to application form',
          );
          return <Redirect to='/onboarding/application-form' />;
        }

        // @ts-ignore
        if (!(userVerifyInfo?.roles || []).includes('ROLE_ADMIN')) {
          Logger.debug(
            'AuthenticatedAdminRoute: user is not admin, going to mobile app page',
          );

          return <Redirect to='/welcome-to-rezen/mobile-app' />;
        }

        return (
          <div className='flex flex-col lg:flex-row min-h-screen'>
            <MainSidebar />
            <div className='flex-grow lg:max-h-screen lg:overflow-y-auto overflow-x-hidden'>
              {/* @ts-ignore */}
              <Component {...props} />
            </div>
          </div>
        );
      }}
    />
  );
};

export default AuthenticatedAdminRoute;
