import { DateTime } from 'luxon';
import { capitalize, last } from 'lodash';
import {
  CheckCircleOutline,
  ContactMailOutlined,
  MailOutline,
  PermIdentity,
  PhoneOutlined,
  PinDropOutlined,
} from '@material-ui/icons';
import { HiOutlineIdentification } from 'react-icons/hi';
import React, { ChangeEvent, useState } from 'react';
import { Link, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import Pill from '../Pill';
import ProfileImage from '../ProfileImage';
import { AgentDetailState, AgentTransactionCard } from '../../types';
import StatisticsBlock from '../StatisticsBlock';
import { UserControllerApi } from '../../openapi/yenta';
import {
  capitalizeEnum,
  formatAddressObject,
  formatPhoneNumber,
} from '../../utils/StringUtils';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../services/ErrorService';
import { fetchAgentDetail } from '../../slices/AgentSlice';
import { getYentaImageUrl } from '../../utils/ImgUtils';
import Button from '../Button';
import ConnectToSkyslopeSidebarModal from '../../forms/ConnectToSkyslopeSidebarModal';
import config from '../../config';
import BooleanButton from './BooleanButton';

export interface AgentDetailHeaderProps extends RouteComponentProps {
  agentInfo: AgentDetailState;
  transactionOverview: AgentTransactionCard[];
}

const AgentDetailHeader: React.FC<AgentDetailHeaderProps> = ({
  agentInfo,
  transactionOverview,
  match,
}) => {
  const dispatch = useDispatch();

  const [
    isConnectToSkyslopeOpen,
    setIsConnectToSkyslopeOpen,
  ] = useState<boolean>(false);

  const onImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await new UserControllerApi(
        getYentaConfiguration(),
      ).uploadAvatarUsingPOST(agentInfo.detail?.id!, e.target!.files![0]);
      await dispatch(fetchAgentDetail(agentInfo.detail?.id!));
    } catch (e) {
      ErrorService.notify('Error uploading user avatar', e);
    }
  };

  const [isCopied, setIsCopied] = useState(false);
  const referralLink = `${config.reactAppHostUrl}/register?${qs.stringify({
    sponsorCode: agentInfo!.detail!.sponsorCode,
    sponsorName: `${agentInfo?.detail!.firstName} ${
      agentInfo?.detail!.lastName
    }`,
  })}`;

  const copyToClipboard = async (value: string) => {
    await navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className='w-full'>
      <header className='flex-grow flex md:flex-row flex-col justify-between bg-mineshaft-500'>
        <div className='flex md:flex-row flex-col'>
          <div className='pr-5 pb-3 md:pb-0 mx-auto md:mx-0'>
            <ProfileImage
              width={150}
              imageUrl={getYentaImageUrl(agentInfo!.detail?.avatar!)}
              editable
              onImageChange={onImageChange}
            />
          </div>
          <div className='col-span-12'>
            <div className='flex lg:flex-row flex-col space-x-4 pb-3 md:pb-1 items-center'>
              <p className='text-xl lg:text-2xl  text-white font-semibold'>
                {agentInfo.detail?.fullName}
              </p>
              <div className='flex flex-row space-x-4'>
                <div>
                  <Pill
                    text={capitalizeEnum(agentInfo.detail?.agentStatus ?? '')}
                    variant='success'
                    icon={<CheckCircleOutline fontSize='small' />}
                  />
                </div>
                <div>
                  <Pill
                    text={`${capitalizeEnum(
                      `${agentInfo.detail?.planMembership?.commissionPlan?.name}`,
                    )} Plan`}
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-row items-center space-x-2 mb-2 '>
              <p className='text-lg text-white font-semibold'>Real ID: </p>
              <p className='text-trueGray-300'> #{agentInfo.detail?.id}</p>
            </div>

            <a href={`tel:${agentInfo.detail?.phoneNumber}`}>
              <div className='flex flex-row items-center space-x-2 mb-2'>
                <PhoneOutlined fontSize='small' className='text-gray-200' />
                <p className='text-trueGray-300'>
                  {formatPhoneNumber(agentInfo.detail?.phoneNumber) || 'N/A'}
                </p>
              </div>
            </a>

            <div className='flex flex-row items-center space-x-2 mb-2'>
              <PinDropOutlined fontSize='small' className='text-gray-200' />
              <p className='text-trueGray-300'>
                {!!agentInfo.detail?.addresses?.length
                  ? formatAddressObject(last(agentInfo.detail?.addresses)!)
                  : 'N/A'}
              </p>
            </div>

            <div className='flex md:flex-row flex-col gap-2 items-baseline'>
              <a href={`mailto:${agentInfo.detail?.emailAddress}`}>
                <div className='flex flex-row  items-center space-x-2 mb-2'>
                  <ContactMailOutlined
                    fontSize='small'
                    className='text-gray-200'
                  />
                  <p className='text-trueGray-300'>
                    {agentInfo.detail?.emailAddress}
                  </p>
                </div>
              </a>
              {agentInfo.detail?.personalEmailAddress && (
                <a href={`mailto:${agentInfo.detail?.personalEmailAddress}`}>
                  <div className='flex flex-row  items-center gap-2'>
                    <MailOutline
                      fontSize='small'
                      className='text-trueGray-300'
                    />
                    <p className='text-trueGray-300'>
                      {agentInfo.detail?.personalEmailAddress}
                    </p>
                  </div>
                </a>
              )}
            </div>
            <div className='flex flex-row flex-wrap items-center space-x-2 mb-2'>
              <PermIdentity fontSize='small' className='text-gray-200' />
              <p className='text-trueGray-300'>
                License No:{' '}
                {!!agentInfo.detail?.licenses?.length
                  ? last(agentInfo.detail?.licenses)?.number
                  : 'N/A'}
              </p>

              <p className='text-trueGray-300'>•</p>
              <p className='text-trueGray-300'>
                Expires:{' '}
                {!!agentInfo.detail?.licenses?.length
                  ? DateTime.fromISO(
                      `${last(agentInfo.detail?.licenses)?.expirationDate}`,
                    ).toFormat('yyyy-MM-dd')
                  : 'N/A'}
              </p>
            </div>
            <div className='flex flex-row items-center space-x-2 mb-2'>
              {!!agentInfo.detail?.skySlopeId?.length ? (
                <>
                  <HiOutlineIdentification className='text-gray-200' />
                  <p className='text-trueGray-300'>
                    SkySlope ID: {agentInfo.detail?.skySlopeId}
                  </p>
                </>
              ) : (
                <Button
                  label='Connect to SkySlope'
                  type='primary'
                  className='text-base'
                  onClick={() => setIsConnectToSkyslopeOpen(true)}
                />
              )}
            </div>
            <div className='flex space-x-3 items-center'>
              <p className='text-trueGray-300'>Producing Status</p>
              <BooleanButton value={agentInfo.performance?.producing!} />
            </div>
            <div className='flex space-x-3 items-center mt-3'>
              <p className='text-trueGray-300'>Stock Opt-In Status</p>
              <BooleanButton
                value={
                  agentInfo.detail?.planMembership!.optInToEquityPlan!
                    ? true
                    : false
                }
              />
            </div>
            <div className='flex space-x-3 items-center mt-3'>
              <p className='text-trueGray-300'>Tipalti Integrated?</p>
              <BooleanButton value={agentInfo.detail?.tipaltiConfirmed!} />
              <Button
                type='outline'
                label={isCopied ? 'Copied Link' : 'Copy Sponsor Link'}
                onClick={() => copyToClipboard(referralLink)}
              />
            </div>
          </div>
        </div>
        <Route path={`${match.path}`} exact>
          <div className='flex lg:justify-end pt-3 lg:pt-0 justify-center col-span-12'>
            <div>
              <div>
                <span className='text-lg text-white font-primary-medium'>
                  Transactions
                </span>
              </div>
              <StatisticsBlock
                items={transactionOverview.map((transaction) => ({
                  ...transaction,
                  subtitle: capitalize(transaction.subtitle),
                }))}
              />
              <div className='bottom-0 relative mb-3 pt-2'>
                <Link to={`/people/${agentInfo.detail?.id}/transactions`}>
                  <button className='text-primary font-primary-medium focus:outline-none hover:opacity-70'>
                    See all
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Route>
      </header>
      <ConnectToSkyslopeSidebarModal
        agentId={agentInfo.detail?.id!}
        isOpen={isConnectToSkyslopeOpen}
        onClose={() => setIsConnectToSkyslopeOpen(false)}
      />
    </div>
  );
};

export default withRouter(AgentDetailHeader);
