import React from 'react';

export interface DataTableProps {}

const DataTable: React.FC<DataTableProps> = ({ children }) => {
  return (
    <table className='border border-gray-600 border-collapse p-1 rounded table-auto w-full'>
      <tbody>{children}</tbody>
    </table>
  );
};

export default DataTable;
