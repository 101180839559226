import React from 'react';
import { Link } from 'react-router-dom';
import { OfficePreviewResponse } from '../../openapi/yenta';
import ProfileImage from '../ProfileImage';
import { transactionDisbursementTypeToDisplayName } from '../../utils/DisplayHelpers';
import { formatPhoneNumber } from '../../utils/StringUtils';

interface Props {
  offices: OfficePreviewResponse[];
}
const OfficeBroker: React.FC<Props> = ({ offices }) => {
  return (
    <div className='divide-y'>
      {offices.map((office) => (
        <div key={office.id} className='px-4 py-2'>
          <p className='text-base font-primary-medium text-gray-800'>
            {office.name}
          </p>
          <p className='text-xs font-primary-medium text-gray-400'>
            DESIGNATED BROKER
          </p>
          <div className='flex flex-row mt-2'>
            <div className='mr-3'>
              <Link to={`/offices/${office.id}`}>
                <ProfileImage
                  imageUrl={office.designatedBroker?.avatar!}
                  width={50}
                />
              </Link>
            </div>
            <div>
              <Link to={`/offices/${office.id}`}>
                <p className='text-dark font-primary-medium leading-5'>
                  {office.designatedBroker?.fullName}
                </p>
              </Link>
              <p className='text-dark font-primary-medium'>
                {formatPhoneNumber(office.designatedBroker?.phoneNumber)}
              </p>
              <p className='text-gray-400 flex flex-row space-x-2'>
                Check Type:
                <span className='text-dark font-primary-medium ml-1'>
                  {transactionDisbursementTypeToDisplayName(
                    office.transactionType!,
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OfficeBroker;
