import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import ListPaginate from '../components/ListPaginate';
import { ErrorCode, IPaginateReq, IPaginateRes } from '../types';
import {
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
} from '../constants/ListPaginationConstants';
import ErrorService from '../services/ErrorService';

interface ListPaginateContainerProps<D> {
  fetchData(req: IPaginateReq<D>): Promise<IPaginateRes<D>>;
  children(data: Array<D>): React.ReactNode;
  searchPlaceholder?: string;
  resourceName: string;
}

const ListPaginateContainer = <D extends object>({
  fetchData,
  resourceName,
  searchPlaceholder,
  children,
}: ListPaginateContainerProps<D>) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState<null | ErrorCode>(null);
  const [search, setSearch] = useState<string | undefined>();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(DEFAULT_PAGE_NUM);
  const [listItemsPaginateRes, setListItemsPaginateRes] = useState<
    IPaginateRes<D>
  >({ data: [], total: 0 });

  const debounceApiCall = useAsyncDebounce(async () => {
    setLoading(true);

    try {
      const res = await fetchData({ page, pageSize, search });

      setListItemsPaginateRes(res);
      setIsError(null);
    } catch (e) {
      ErrorService.notify('Unable to fetch data', e);
      setIsError(ErrorService.getErrorCode(e));
    } finally {
      setLoading(false);
    }
  }, 400);

  useEffect(() => {
    debounceApiCall();
  }, [debounceApiCall, page, pageSize, search]);

  return (
    <ListPaginate
      listItems={listItemsPaginateRes.data}
      search={search}
      itemsToShow={pageSize}
      page={page}
      totalItems={listItemsPaginateRes.total}
      loading={loading}
      errorCode={isError}
      onSearch={setSearch}
      goToPage={setPage}
      updateTotalItemsToShow={setPageSize}
      searchPlaceholder={searchPlaceholder}
      resourceName={resourceName}
    >
      {children}
    </ListPaginate>
  );
};

export default ListPaginateContainer;
