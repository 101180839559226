import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SidebarModal from '../components/SidebarModal';
import Button from '../components/Button';
import ControlledTextInput from '../components/ControlledTextInput';
import { AgentControllerApi } from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import ErrorService from '../services/ErrorService';
import { fetchAgentDetail } from '../slices/AgentSlice';

interface FormValues {
  emailAddress: string;
}

interface ConnectToSkyslopeSidebarModalProps {
  agentId: string;
  isOpen: boolean;
  onClose(): void;
}

const ConnectToSkyslopeSidebarModal: React.FC<ConnectToSkyslopeSidebarModalProps> = ({
  agentId,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (values: FormValues) => {
    try {
      await new AgentControllerApi(
        getArrakisConfiguration(),
      ).connectToSkySlopeUsingPOST(agentId, values.emailAddress);
      dispatch(showSuccessToast('Connect to SkySlope successful'));
      dispatch(fetchAgentDetail(agentId));
      onClose();
    } catch (e) {
      ErrorService.notify('Unable to connect to skyslope', e, {
        agent: { agentId },
      });
      dispatch(
        showErrorToastForErrorCode(
          'We had a problem connecting to skyslope.',
          ErrorService.getErrorCode(e),
        ),
      );
    }
  };

  return (
    <SidebarModal title='Connect to SkySlope' isOpen={isOpen} onClose={onClose}>
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-basic-information-form'
      >
        <div className='p-4'>
          <ControlledTextInput
            control={control}
            label='Email Address'
            name='emailAddress'
            placeholder='Email Address to Connect'
            errors={errors}
            rules={{
              required: 'Required',
            }}
          />
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={onClose}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default ConnectToSkyslopeSidebarModal;
