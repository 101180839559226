import React, { useCallback, useEffect, useState } from 'react';
import { last } from 'lodash';
import PeopleIcon from '@material-ui/icons/People';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import BusinessIcon from '@material-ui/icons/Business';
import { FaCrown, FaCheckCircle, FaAddressCard, FaUsers } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { EditOutlined } from '@material-ui/icons';
import Card from '../components/Card';
import HalfPieProgress from '../components/HalfPieProgress';
import ProfileProgressRows from '../components/Agent/ProfileProgressRows';
import SectionHeader from '../components/SectionHeader';
import BasicInformationCard from '../components/Agent/BasicInformationCard';
import {
  AgentControllerApi,
  AgentProfileScoreResponse,
} from '../openapi/yenta';
import { numberToMoney } from '../utils/CurrencyUtils';
import BarChart from '../components/BarChart';
import CardWithItems from '../components/CardWithItems';
import TeamsCard from '../components/Agent/TeamsCard';
import OfficeTabs from '../components/Agent/OfficeTabs';
import AgentCommissionAdvances from '../components/Agent/AgentCommissionAdvances';
import Network from '../components/network/Network';
import NetworkRow from '../components/network/NetworkRow';
import {
  AgentDetailState,
  Mapping,
  NetworkResponse,
  SearchAgentResponse,
} from '../types';
import { START_TIER } from '../constants/NetworkConstants';
import ResourceContainer from '../components/ResourceContainer';
import DefaultEmpty from '../components/DefaultEmpty';
import AgentCredits from '../components/Agent/AgentCredits';
import AgentReferrals from '../components/Agent/AgentReferrals';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService';
import AgentPerformanceSection from '../components/Agent/AgentPerformanceSection';
import { calculateCapPercentage } from '../utils/CapHelper';
import BasicInformationForm from '../forms/BasicInformationForm';
import { TransactionsOverviewResponse } from '../openapi/arrakis';
import AgentLicenses from '../components/Agent/AgentLicenses';
import MLSAndBoardsCard from '../components/Agent/MLSAndBoardsCard';
import EditAgentLicensesSidebarModal from '../components/Agent/EditAgentLicensesSidebarModal';
import EliteStatusTracker from '../components/Agent/EliteStatusTracker';
import { getLabelAlternateNumber } from '../utils/MathUtils';

export interface AgentDetailInfoRouteProps {
  agentDetail: AgentDetailState;
  networks: NetworkResponse[];
  users: Mapping<SearchAgentResponse>;
  currentTransactionOverview: TransactionsOverviewResponse;
}

const AgentDetailInfoRoute: React.FC<AgentDetailInfoRouteProps> = ({
  agentDetail,
  networks,
  users,
  currentTransactionOverview,
}) => {
  const [selectAgents, setSelectAgents] = useState<NetworkResponse[] | null>(
    null,
  );
  const [profileScore, setProfileScore] = useState<AgentProfileScoreResponse>();
  const history = useHistory();
  const [
    openEditAgentDetailsSideModal,
    setOpenEditAgentDetailsSideModal,
  ] = useState<boolean>(false);
  const [editingLicenses, setEditingLicenses] = useState<boolean>(false);

  const fetchProfileScore = useCallback(async () => {
    try {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).getProfileScoreUsingGET(agentDetail.detail!.id!);
      setProfileScore(data);
    } catch (e) {
      ErrorService.notify('Error fetching profile score', e, {
        agent: { id: agentDetail.detail!.id },
      });
    }
  }, [agentDetail.detail]);

  useEffect(() => {
    fetchProfileScore();
  }, [fetchProfileScore]);

  const barChartData = agentDetail.incomeChart?.monthlyTotals?.map((item) => {
    return {
      month: `${item.month}/${item.year!.toString().slice(-2)}`,
      total: item.total?.amount!,
    };
  });
  const highlightedMonth = last(barChartData);

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 grid-flow-row p-4'>
        {/* BarChart  */}

        <Card>
          <div className='pt-2 flex flex-row justify-between'>
            <span className='text-xl text-gray-800 font-primary-medium px-4 '>
              {numberToMoney(
                agentDetail.incomeOverview?.grossCommission?.amount! || 0,
                0,
              )}
            </span>
            <span className='text-lg text-gray-600 font-primary-thin px-4 '>
              Total Income
            </span>
          </div>
          <div className='px-4'>
            <BarChart
              data={barChartData! || []}
              highlightedData={[highlightedMonth!]}
              xKey='month'
              yKey='total'
              xShowAlternate={getLabelAlternateNumber(
                (barChartData || []).length,
              )}
            />
          </div>
        </Card>

        {/* Commission  */}
        <CardWithItems
          title='Commission'
          bottomActionText='See all'
          onBottomActionClick={() =>
            history.push(`/people/${agentDetail!.detail!.id!}/payments`)
          }
          items={[
            {
              name: 'Total Commission',
              value: numberToMoney(
                agentDetail.incomeOverview?.grossCommission?.amount! || 0,
                0,
              ),
            },
            {
              name: 'Net Commission',
              value: numberToMoney(
                agentDetail.incomeOverview?.netCommission?.amount! || 0,
                0,
              ),
            },
            {
              name: 'Closed Transactions',
              value: `${agentDetail.incomeOverview?.numTransactions!}`,
            },
          ]}
          // bottomActionText='See all'
        />

        {/* Revenue Share  */}

        <CardWithItems
          title='Revenue Share'
          bottomActionText='See all'
          onBottomActionClick={() =>
            history.push(`/people/${agentDetail!.detail!.id!}/payments`)
          }
          items={[
            {
              name: 'Earnings',
              value: numberToMoney(
                agentDetail.incomeOverview?.revShareEarnings?.amount! || 0,
                0,
              ),
            },
            {
              name: 'Producing Tier 1 Agents',
              value: `${agentDetail.incomeOverview?.revShareStatus
                ?.numProducingFrontLineAgents!}`,
            },
            {
              name: 'Unlocked Tier ',
              value: `${agentDetail.incomeOverview?.revShareStatus
                ?.unlockedRevShareTier!}`,
            },
          ]}
          // bottomActionText='See all'
        />

        {/* Equity */}

        <CardWithItems
          title='Equity'
          bottomActionText='See all'
          onBottomActionClick={() =>
            history.push(`/people/${agentDetail!.detail!.id!}/equity`)
          }
          items={[
            {
              name: 'Shares Awarded',
              value: numberToMoney(
                agentDetail.incomeOverview?.equityPurchased?.amount! || 0,
                0,
              ),
            },
            // { name: 'Shares Vested', value: 1000 },
            // { name: 'Current Value', value: 1000 },
          ]}
          // bottomActionText='See all'
        />

        {/* Teams */}
        {agentDetail.detail!.teamMemberships?.length === 0 ? (
          <Card title='Teams'>
            <DefaultEmpty
              message='You do not belong to any teams.'
              icon={<PeopleIcon fontSize='large' />}
            />
          </Card>
        ) : (
          <TeamsCard teams={agentDetail.detail!.teamMemberships!} />
        )}

        {/* OfficeTabs  */}
        <Card title='Offices'>
          <ResourceContainer
            loading={!agentDetail.detail!.offices}
            isEmpty={agentDetail.detail!.offices?.length === 0}
            resourceName='office'
            emptyIcon={<BusinessIcon fontSize='large' />}
          >
            <OfficeTabs offices={agentDetail.detail!.offices!} />
          </ResourceContainer>
        </Card>

        {/* CapProgress  */}
        <Card>
          <div className='pt-4'>
            <div className='flex justify-between px-4'>
              <p className='text-xl text-gray-800 font-primary-medium'>
                Current Cap Cycle
              </p>
              <div className='flex items-center space-x-2'>
                {agentDetail.incomeOverview?.capped && (
                  <div className='flex flex-col items-center'>
                    <FaCheckCircle />
                    <p className='text-sm'>Capped</p>
                  </div>
                )}
                {agentDetail.incomeOverview?.elite && (
                  <div className='flex flex-col items-center text-yellow-600'>
                    <FaCrown />
                    <p className='text-sm'>Elite</p>
                  </div>
                )}
              </div>
            </div>
            <div className='px-4 pb-1 flex flex-row'>
              <p className='mr-1'>Cap Reset:</p>
              <p className='font-bold text-black '>
                {DateTime.fromISO(
                  agentDetail.incomeOverview?.anniversaryDate!,
                ).toFormat('yyyy-MM-dd')}
              </p>
            </div>
          </div>
          <div className='px-4'>
            {agentDetail.incomeOverview?.capped ? (
              <EliteStatusTracker
                eliteStatus={agentDetail.incomeOverview.eliteStatus!}
              />
            ) : (
              <HalfPieProgress
                progress={calculateCapPercentage(
                  agentDetail.incomeOverview?.capStatus,
                )}
                text={`${numberToMoney(
                  agentDetail.incomeOverview?.capStatus?.capAmountPaid
                    ?.amount! || 0,
                  0,
                )} / ${numberToMoney(
                  agentDetail.incomeOverview?.capStatus?.capAmount?.amount! ||
                    0,
                  0,
                )}`}
              />
            )}
          </div>
        </Card>
        <Card
          title={`Profile Score: ${profileScore?.percentage}`}
          onBottomActionClick={() => setOpenEditAgentDetailsSideModal(true)}
          bottomActionText='Update Information'
        >
          <div className='mx-3 my-2 bg-gray-100 rounded'>
            <div
              className='rounded bg-primary '
              style={{
                width: `${profileScore?.percentage}`,
                height: '10px',
              }}
            />
          </div>
          <ProfileProgressRows
            profileScoreItems={[
              {
                label: 'Photo Uploaded',
                completed: !!profileScore?.hasUploadedPhoto,
              },
              {
                label: 'Licenses, boards, and zip codes updated',
                completed: !!profileScore?.hasUpdatedZipcodes,
              },
              {
                label: 'Website added',
                completed: !!profileScore?.hasAddedWebsites,
              },
              {
                label: 'Bio/about me written',
                completed: !!profileScore?.hasWrittenBio,
              },
              {
                label: 'Social media accounts added',
                completed: !!profileScore?.hasAddedSocialMediaAccounts,
              },
            ]}
          />
        </Card>
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-4 grid-flow-row lg:grid-flow-col gap-4 px-4 mb-8'>
        <div>
          <AgentPerformanceSection agentDetail={agentDetail!} />
          <div className='mt-5'>
            <AgentReferrals
              agent={agentDetail.detail!}
              referrals={agentDetail.referrals!}
              users={users}
            />
          </div>
          <div className='mt-4'>
            <SectionHeader icon={<FaUsers />} title='My Network' />
            <div className='mt-2'>
              <Card
                bottomActionText={networks?.length === 0 ? '' : 'See all'}
                onBottomActionClick={() =>
                  setSelectAgents([
                    {
                      id: agentDetail.detail!.id!,
                      firstName: agentDetail.detail!.firstName!,
                      lastName: agentDetail.detail!.lastName!,
                      avatar: agentDetail.detail!.avatar!,
                      sizeOfNetwork: agentDetail.detail!.sizeOfNetwork!,
                    },
                  ])
                }
              >
                <ResourceContainer
                  loading={!networks}
                  isEmpty={networks?.length === 0}
                  resourceName='network'
                  emptyMessage='You have not referred any other agents.'
                  emptyIcon={<EmojiPeopleIcon fontSize='large' />}
                >
                  <div className='divide-y divide-gray-200 border-b border-gray-200'>
                    {networks?.slice(0, 3).map((agent) => (
                      <div key={agent.id} className='px-4'>
                        <NetworkRow
                          key={agent.id}
                          name={`${agent.firstName} ${agent.lastName}`}
                          tier={START_TIER}
                          imageUrl={agent.avatar!}
                          sizeOfNetwork={agent.sizeOfNetwork}
                          onClick={() =>
                            setSelectAgents([
                              {
                                id: agentDetail.detail!.id!,
                                firstName: agentDetail.detail!.firstName!,
                                lastName: agentDetail.detail!.lastName!,
                                avatar: agentDetail.detail!.avatar!,
                                sizeOfNetwork: agentDetail.detail!
                                  .sizeOfNetwork!,
                              },
                              agent,
                            ])
                          }
                        />
                      </div>
                    ))}
                  </div>
                </ResourceContainer>
              </Card>
            </div>
          </div>
          <div className='mt-4'>
            <MLSAndBoardsCard
              mls={agentDetail.detail?.mls!}
              boards={agentDetail.detail?.boards!}
            />
          </div>
          <div className='mt-4'>
            <SectionHeader
              icon={<FaAddressCard />}
              title='Licenses'
              actionText='Edit'
              actionIcon={<EditOutlined fontSize='small' />}
              onActionClick={() => setEditingLicenses(true)}
            />
            <div className='mt-2'>
              <AgentLicenses licenses={agentDetail.detail?.licenses!} />
            </div>
            <EditAgentLicensesSidebarModal
              agent={agentDetail.detail!}
              isOpen={editingLicenses}
              onClose={() => setEditingLicenses(false)}
            />
          </div>
        </div>

        {/* DataTable */}
        <div className='lg:col-span-3 col-span-1'>
          <BasicInformationCard
            agent={agentDetail.detail!}
            setOpenEditAgentDetailsSideModal={setOpenEditAgentDetailsSideModal}
          />
          <BasicInformationForm
            isOpen={openEditAgentDetailsSideModal}
            onClose={() => setOpenEditAgentDetailsSideModal(false)}
            agent={agentDetail.detail!}
          />
          <div className='mt-5'>
            <AgentCredits agent={agentDetail.detail!} />
          </div>
          <div className='mt-5'>
            <AgentCommissionAdvances
              agent={agentDetail.detail!}
              commissionAdvances={agentDetail.commissionAdvances!}
              currentTransactionOverview={currentTransactionOverview!}
            />
          </div>
          {/*<div className='mt-4'>*/}
          {/*  <SectionHeader*/}
          {/*    icon={<BugReport fontSize='small' />}*/}
          {/*    title='Tickets'*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className='border border-gray-200 rounded mt-2 pt-1'>*/}
          {/*  <TicketTabs tickets={agentDetail.detail!.tickets} />*/}
          {/*</div>*/}
          {/*<div className='bottom-0 relative'>*/}
          {/*  <button*/}
          {/*    onClick={() => {}}*/}
          {/*    className='text-primary font-primary-medium focus:outline-none hover:opacity-70 pt-1'*/}
          {/*  >*/}
          {/*    See all*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      </div>
      {selectAgents && (
        <Network
          isOpen={!!selectAgents.length}
          onClose={() => setSelectAgents(null)}
          selectAgents={selectAgents!}
        />
      )}
    </div>
  );
};

export default AgentDetailInfoRoute;
