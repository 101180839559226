import React from 'react';
import { last, values, capitalize } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  AdministrativeAreaResponseCountryEnum,
  AgentResponse,
  AgentUpdateRequestCompetenciesEnum,
} from '../openapi/yenta';
import { updateAgentDetail } from '../slices/AgentSlice';
import { capitalizeEnum, WEBSITE_REGEX } from '../utils/StringUtils';
import SidebarModal from '../components/SidebarModal';
import ControlledDatePickerInput from '../components/ControlledDatePickerInput';
import ControlledTextAreaInput from '../components/ControlledTextAreaInput';
import ControlledTextInput from '../components/ControlledTextInput';
import ControlledAsyncMultiSelectInput from '../components/ControlledAsyncMultiSelectInput';
import ControlledSelectInput from '../components/ControlledSelectInput';
import MockRealApi from '../utils/MockRealApi';
import Button from '../components/Button';
import { getUpdateAgentDetailObject } from '../utils/ApiObjectUtils';
import {
  EMAIL_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../utils/Validations';
import { CanadianStates, UnitedStates } from '../types';
import ControlledMultiSelectInput from '../components/ControlledMultiSelectInput';
import ControlledPhoneNumberInput from '../components/ControlledPhoneNumberInput';

interface BasicInformationFormProps {
  isOpen: boolean;
  onClose(): void;
  agent: AgentResponse;
}

const BasicInformationForm: React.FC<BasicInformationFormProps> = ({
  isOpen,
  onClose,
  agent,
}) => {
  const dispatch = useDispatch();

  const {
    control,
    errors,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<AgentResponse>();

  const onSubmit = async (data: any) => {
    await dispatch(
      updateAgentDetail(
        agent.id!,
        getUpdateAgentDetailObject(agent, {
          ...data,
          phoneNumber: data.phoneNumber || null,
          clubhouseURL: data.clubhouseURL || null,
          facebookURL: data.facebookURL || null,
          googleBusinessProfileURL: data.googleBusinessProfileURL || null,
          instagramURL: data.instagramURL || null,
          linkedInURL: data.linkedInURL || null,
          personalWebsiteURL: data.personalWebsiteURL || null,
          twitterURL: data.twitterURL || null,
          yelpURL: data.yelpURL || null,
          youtubeURL: data.youtubeURL || null,
          zillowURL: data.zillowURL || null,
          serviceAreaZipcodes: data.serviceAreaZipcodes || null,
          languages: values(data.languages).map((language) => language.value),
          personalEmailAddress: data.personalEmailAddress || null,
          personalPhoneNumber: data.personalPhoneNumber || null,
          competencies: values(data.competencies).map(
            (competencies) => competencies.value,
          ),
          slug: data.slug || null,
        }),
      ),
    );
    onClose();
  };
  const countrySelected = watch('country');
  return (
    <SidebarModal
      title='Edit Basic Information'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-basic-information-form'
      >
        <div className='p-4'>
          <div>
            <ControlledDatePickerInput
              name='birthDate'
              control={control}
              errors={errors}
              label='Birthday Date'
              placeholder='Birthday Date'
              defaultValue={agent.birthDate}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Title'
              name='title'
              placeholder='Your Title'
              defaultValue={agent.title}
              errors={errors}
            />
          </div>
          <div className='mt-5'>
            <ControlledPhoneNumberInput
              control={control}
              label='Phone Number'
              name='phoneNumber'
              placeholder='+1 (702) 123-4567'
              defaultValue={agent.phoneNumber}
              errors={errors}
              rules={{
                required: 'Required',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Personal Email Address'
              name='personalEmailAddress'
              placeholder='Personal Email Address'
              defaultValue={agent.personalEmailAddress}
              errors={errors}
              rules={EMAIL_VALIDATIONS}
            />
          </div>
          <div className='mt-5'>
            <ControlledPhoneNumberInput
              control={control}
              label='Personal Phone Number'
              name='personalPhoneNumber'
              placeholder='+1 (702) 123-4568'
              defaultValue={agent.personalPhoneNumber}
              errors={errors}
              rules={PHONE_NUMBER_VALIDATIONS}
            />
          </div>
          <div className='mt-5'>
            <ControlledMultiSelectInput
              name='competencies'
              control={control}
              errors={errors}
              label='Works with'
              placeholder='Works with'
              options={[
                {
                  label: 'Select competencies',
                  value: '',
                  disabled: false,
                },
                ...values(AgentUpdateRequestCompetenciesEnum).map(
                  (competencies) => ({
                    value: competencies,
                    label: capitalizeEnum(competencies),
                  }),
                ),
              ]}
              defaultValue={agent.competencies?.map((competencies) => ({
                value: competencies,
                label: capitalizeEnum(competencies),
              }))}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextAreaInput
              control={control}
              errors={errors}
              label='Service area zip codes'
              name='serviceAreaZipcodes'
              rows={3}
              defaultValue={agent.serviceAreaZipcodes}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              control={control}
              label='Address Type'
              name='type'
              errors={errors}
              options={[
                {
                  label: 'Choose address type',
                  value: '',
                  disabled: true,
                },
                ...['work', 'home'].map((type) => ({
                  value: type,
                  label: capitalize(type),
                })),
              ]}
              defaultValue={last(agent.addresses)?.type}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              name='country'
              control={control}
              errors={errors}
              label='Country'
              placeholder='Country'
              defaultValue={last(agent.addresses)?.country}
              options={[
                {
                  label: 'Choose Country',
                  value: '',
                  disabled: true,
                },
                ...values(AdministrativeAreaResponseCountryEnum).map(
                  (state) => ({
                    value: state,
                    label: capitalizeEnum(state),
                  }),
                ),
              ]}
              rules={{
                required: 'Please select country',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Street Address Line 1'
              name='streetAddress1'
              placeholder='Address'
              errors={errors}
              defaultValue={last(agent.addresses)?.streetAddress1}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Street Address Line 2'
              name='streetAddress2'
              placeholder='Address'
              errors={errors}
              defaultValue={last(agent.addresses)?.streetAddress2}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='City'
              name='city'
              placeholder='City'
              errors={errors}
              defaultValue={last(agent.addresses)?.city}
              rules={{
                required: 'Required',
              }}
            />
          </div>

          <div className='mt-5'>
            <ControlledSelectInput
              name='stateOrProvince'
              control={control}
              errors={errors}
              label='State'
              placeholder='State'
              defaultValue={last(agent.addresses)?.stateOrProvince}
              options={[
                {
                  label: 'Choose State or Province',
                  value: '',
                  disabled: true,
                },
                ...values(
                  countrySelected ===
                    AdministrativeAreaResponseCountryEnum.UnitedStates
                    ? UnitedStates
                    : CanadianStates,
                ).map((state) => ({
                  value: state,
                  label: capitalizeEnum(state),
                })),
              ]}
              rules={{
                required: 'Please select state or province.',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Zipcode'
              name='zipOrPostalCode'
              placeholder='Zipcode'
              errors={errors}
              defaultValue={last(agent.addresses)?.zipOrPostalCode}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledAsyncMultiSelectInput
              control={control}
              errors={errors}
              name='languages'
              label='Languages'
              placeholder='Select Languages'
              fetchData={(search, page) =>
                new MockRealApi().getAsyncLanguages(search, page)
              }
              defaultValue={agent.languages?.map((language) => ({
                label: capitalizeEnum(language),
                value: capitalizeEnum(language),
              }))}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextAreaInput
              control={control}
              label='Bio'
              name='about'
              placeholder='A short bio of the agent'
              errors={errors}
              defaultValue={agent.about}
              rows={2}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Slug'
              name='slug'
              placeholder='Slug'
              errors={errors}
              defaultValue={agent.slug}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Facebook Link'
              name='facebookURL'
              placeholder='Facebook Link'
              defaultValue={agent.facebookURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Clubhouse Link'
              name='clubhouseURL'
              placeholder='Clubhouse Link'
              defaultValue={agent.clubhouseURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Twitter Link'
              name='twitterURL'
              placeholder='Twitter Link'
              defaultValue={agent.twitterURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Instagram Link'
              name='instagramURL'
              placeholder='Instagram Link'
              defaultValue={agent.instagramURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Youtube Link'
              name='youtubeURL'
              placeholder='Youtube Link'
              defaultValue={agent.youtubeURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Website Link'
              name='personalWebsiteURL'
              placeholder='Website Link'
              defaultValue={agent.personalWebsiteURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Google Business Profile Link'
              name='googleBusinessProfileURL'
              placeholder='Google Business Profile Link'
              defaultValue={agent.googleBusinessProfileURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='LinkedIn Link'
              name='linkedInURL'
              placeholder='LinkedIn Link'
              defaultValue={agent.linkedInURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Yelp Link'
              name='yelpURL'
              placeholder='Yelp Link'
              defaultValue={agent.yelpURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Zillow Link'
              name='zillowURL'
              placeholder='Zillow Link'
              defaultValue={agent.zillowURL}
              errors={errors}
              rules={{
                pattern: {
                  value: WEBSITE_REGEX,
                  message: 'Please enter a valid url',
                },
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              name='receivesPaymentEmailNotifications'
              control={control}
              errors={errors}
              label='Receives Payment Email Notifications'
              placeholder='Receives Payment Email Notifications'
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              defaultValue={`${!!agent.receivesPaymentEmailNotifications}`}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              name='receivesPaymentPushNotifications'
              control={control}
              errors={errors}
              label='Receives Payment Push Notifications'
              placeholder='Receives Payment Push Notifications'
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              defaultValue={`${!!agent.receivesPaymentPushNotifications}`}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              name='receivesSupportNotifications'
              control={control}
              errors={errors}
              label='Receives Support Notifications'
              placeholder='Receives Support Notifications'
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              defaultValue={`${!!agent.receivesSupportNotifications}`}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              name='receivesTransactionEmailNotifications'
              control={control}
              errors={errors}
              label='Receives Transaction Email Notifications'
              placeholder='Receives Transaction Email Notifications'
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              defaultValue={`${!!agent.receivesTransactionEmailNotifications}`}
            />
          </div>
          <div className='mt-5 pb-20'>
            <ControlledSelectInput
              name='receivesTransactionPushNotifications'
              control={control}
              errors={errors}
              label='Receives Transaction Push Notifications'
              placeholder='Receives Transaction Push Notifications'
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              defaultValue={`${!!agent.receivesTransactionPushNotifications}`}
            />
          </div>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default BasicInformationForm;
