import React from 'react';
import { DateTime } from 'luxon';
import { FiTrash2 } from 'react-icons/fi';
import { CalendarToday } from '@material-ui/icons';
import {
  ReferralResponse,
  ReferralResponseReferralStatusEnum,
} from '../openapi/arrakis';
import { EnumMap, SearchAgentResponse } from '../types';
import { isReferralActionable } from '../utils/ReferralsUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import Pill, { PillVariantType } from './Pill';
import IconButton from './IconButton';
import Button from './Button';

export interface AgentReferralRowProps {
  referral: ReferralResponse;
  user: SearchAgentResponse;
  onEdit(): void;
  onDelete(): void;
  onDisburse(): void;
}

const AgentReferralRow: React.FC<AgentReferralRowProps> = ({
  referral,
  user,
  onEdit,
  onDelete,
  onDisburse,
}) => {
  const agentName =
    referral.externalAgentName || `${user?.firstName} ${user?.lastName}`;

  const pillTypeToString: EnumMap<
    ReferralResponseReferralStatusEnum,
    PillVariantType
  > = {
    SUBMITTED: 'warning',
    PAYMENT_RECEIVED: 'primary',
    DISBURSED: 'success',
  };

  return (
    <div className='p-3'>
      <div className='flex justify-between font-primary-medium'>
        <div>
          <p className='text-xs text-gray-500 uppercase'>Agent</p>
          <p>{agentName}</p>
        </div>
        <div>
          <p className='text-xs  text-gray-500 uppercase'>Client</p>
          <p>{referral.clientName}</p>
        </div>
      </div>
      <div className='flex justify-between mt-3'>
        <div className='flex items-center text-gray-500'>
          <CalendarToday fontSize='small' />
          <p className='ml-1'>
            Closing on{' '}
            {DateTime.fromISO(referral.expectedCloseDate!).toFormat(
              'LL/dd/yyyy',
            )}
          </p>
        </div>
        <div>
          <Pill
            text={capitalizeEnum(referral.referralStatus!)}
            variant={pillTypeToString[referral.referralStatus!]}
          />
        </div>
      </div>
      {isReferralActionable(referral.referralStatus!) && (
        <div className='mt-3 flex items-center justify-between'>
          <div>
            <Button
              label='Edit'
              type='outline'
              className='mr-2'
              onClick={onEdit}
            />
            {!referral.receivingAgentUserYentaId && (
              <Button
                label='Disburse'
                type='outline'
                onClick={onDisburse}
                disabled={!isReferralActionable(referral.referralStatus!)}
              />
            )}
          </div>
          <IconButton
            variant='outline'
            leftIcon={<FiTrash2 title='Delete' size={18} />}
            toolTipText='Delete'
            onClick={onDelete}
          />
        </div>
      )}
    </div>
  );
};

export default AgentReferralRow;
