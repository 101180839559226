import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppThunk,
  ErrorCode,
  SearchAgentResponse,
  UserIdsState,
} from '../types';
import {
  SearchControllerApi,
  SearchParamOperatorEnum,
  SearchRequest,
  SearchRequestTargetObjectEnum,
} from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { hydrateSearchResponse } from '../utils/TableUtils';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: UserIdsState = {
  loading: false,
  usersById: {},
  fetchUsersByIdErrorCode: null,
};

const UserIdsSlice = createSlice({
  name: 'userIds',
  initialState,
  reducers: {
    changeLoadingIds(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    errorFetchingUsersById(state, action: PayloadAction<ErrorCode>) {
      state.fetchUsersByIdErrorCode = action.payload;
    },
    saveUsersById(state, action: PayloadAction<SearchAgentResponse[]>) {
      action.payload.forEach((data) => {
        state.usersById[data.id] = data;
      });
      state.fetchUsersByIdErrorCode = null;
    },
  },
});

export const {
  changeLoadingIds,
  errorFetchingUsersById,
  saveUsersById,
} = UserIdsSlice.actions;

export const fetchUserByIds = (ids: string[]): AppThunk => async (dispatch) => {
  dispatch(changeLoadingIds(true));
  try {
    const searchRequest: SearchRequest = {
      filterAnd: [],
      filterOr: ids.map((id) => {
        return {
          column: 'id',
          stringStrictCase: true,
          value: id,
          operator: SearchParamOperatorEnum.Eq,
        };
      }),
      joinOn: [],
      order: [],
      pageNumber: 1,
      pageSize: ids.length,
      responseColumns: [
        'id',
        'firstName',
        'lastName',
        'avatar',
        'phoneNumber',
        'emailAddress',
      ],
      targetObject: SearchRequestTargetObjectEnum.User,
    };

    const { data } = await new SearchControllerApi(
      await getYentaConfiguration(),
    ).searchUsingPOST(searchRequest);

    const hydratedData = hydrateSearchResponse<SearchAgentResponse>(data);

    dispatch(saveUsersById(hydratedData));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agents by ids', e, {
      users: { ids },
    });
    dispatch(errorFetchingUsersById(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingIds(false));
  }
};

export default UserIdsSlice.reducer;
