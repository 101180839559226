import React, { useState } from 'react';
import { Column } from 'react-table';
import {
  CreditResponse,
  SearchRequestTargetObjectEnum,
} from '../openapi/arrakis';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import { resourceTableFetchData } from '../utils/TableUtils';
import EditAgentCreditFormSidebarModal from '../forms/EditAgentCreditFormSidebarModal';
import ConfirmDeleteRecordModal from '../components/ConfirmDeleteRecordModal';
import { capitalizeEnum } from '../utils/StringUtils';
import { numberToMoney } from '../utils/CurrencyUtils';
import AddAgentCreditFormSidebarModal from '../forms/AddAgentCreditFormSidebarModal';
import { AgentResponse } from '../openapi/yenta';
//import ActionCell from '../components/table/Cells/ActionCell';

interface AgentDetailCreditsRouteProps {
  agent: AgentResponse;
}

export const columns: Array<Column<CreditResponse>> = [
  {
    Header: 'Title',
    accessor: 'title',
    Cell: ({ value }) => value,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => capitalizeEnum(value!),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => numberToMoney(value?.amount!),
  },
  {
    Header: 'Issued On',
    accessor: 'issuedOn',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Issuer Note',
    accessor: 'issuerNote',
    disableFilters: true,
    disableSortBy: true,
  },
];

export const columnsToFetch = columns.map((col) => col.accessor as string);

const AgentDetailCreditsRoute: React.FC<AgentDetailCreditsRouteProps> = ({
  agent,
}) => {
  const [addAgentCredit, setAddAgentCredit] = useState<boolean>(false);
  const [
    deletingAgentCredit,
    setDeletingAgentCredit,
  ] = useState<CreditResponse | null>(null);
  const [
    editingAgentCredit,
    setEditingAgentCredit,
  ] = useState<CreditResponse | null>(null);

  const columnsWithAction: Array<Column<CreditResponse>> = [
    ...columns,
    // {
    //   Header: 'Actions',
    //   accessor: 'title',
    //   id: 'actions',
    //   disableSortBy: true,
    //   disableFilters: true,
    //   Cell: ({ row: { original } }) => (
    //     <ActionCell<CreditResponse>
    //       data={original}
    //       onDelete={() => setDeletingAgentCredit(original)}
    //       onEdit={() => setEditingAgentCredit(original)}
    //     />
    //   ),
    // },
  ];

  return (
    <div className='px-4 mt-4'>
      <div className='flex flex-row items-center mb-4'>
        <span className='font-bold  text-xl'>Credits</span>
        <button
          onClick={() => setAddAgentCredit(true)}
          className='focus:outline-none ml-2 text-primary'
        >
          Add
        </button>
      </div>
      <ResourceIndexContainer<CreditResponse>
        resourceName='credit'
        columns={columnsWithAction}
        fetchData={(req) =>
          resourceTableFetchData(
            req,
            columnsToFetch,
            [],
            SearchRequestTargetObjectEnum.AgentCredit,
            'arrakis',
          )
        }
        initialSort={{ issuedOn: 'desc' }}
        hideFilters
      />
      <AddAgentCreditFormSidebarModal
        isOpen={addAgentCredit}
        onClose={() => setAddAgentCredit(false)}
        agentId={agent.id!}
      />
      <EditAgentCreditFormSidebarModal
        isOpen={!!editingAgentCredit}
        agentCredit={editingAgentCredit!}
        onClose={() => setEditingAgentCredit(null)}
      />
      <ConfirmDeleteRecordModal
        onDelete={() => {}}
        title='Are you sure?'
        subtitle='This agent credit will be permanently deleted.'
        isOpen={!!deletingAgentCredit}
        onClose={() => setDeletingAgentCredit(null)}
      />
    </div>
  );
};
export default AgentDetailCreditsRoute;
