import React from 'react';
import { FilterProps } from 'react-table';
import ControlledTextInput from '../../ControlledTextInput';

export interface TextColumnFilterProps<D extends object>
  extends FilterProps<D> {}

const TextColumnFilter = <D extends object>({
  column,
  errors,
  control,
}: TextColumnFilterProps<D>): React.ReactElement => {
  return (
    <ControlledTextInput
      control={control}
      name={column.id}
      placeholder={`Enter ${column.render('Header')}...`}
      errors={errors}
      rules={{
        required: `${column.render('Header')} is required`,
      }}
    />
  );
};

export default TextColumnFilter;
