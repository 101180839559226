import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Column } from 'react-table';
import AvatarPillCell from '../components/table/Cells/AvatarPillCell';
import DateCell from '../components/table/Cells/DateCell';
import StateIconCell from '../components/table/Cells/StateIconCell';
import TransactionStateCell from '../components/table/Cells/TransactionStateCell';
import TransactionTypeCell from '../components/table/Cells/TransactionTypeCell';
import DateColumnFilter from '../components/table/Filters/DateColumnFilter';
import NumberColumnFilter from '../components/table/Filters/NumberColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import { Transaction } from '../types';
import { numberToMoney } from '../utils/CurrencyUtils';
import MockRealApi from '../utils/MockRealApi';
import TransactionStatusSelectColumnFilter from '../components/table/Filters/TransactionStatusSelectColumnFilter';
import TransactionTypeSelectColumnFilter from '../components/table/Filters/TransactionTypeSelectColumnFilter';
import StateSelectColumnFilter from '../components/table/Filters/StateSelectColumnFilter';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';

interface Match {
  id: string;
}

interface TeamTransactionsRouteProps extends RouteComponentProps<Match> {}

export const columns: Array<Column<Transaction>> = [
  {
    Header: '#',
    accessor: 'id',
    Filter: TextStrictCaseColumnFilter,
    disableSortBy: true,
    Cell: ({ value, row }) => (
      <Link to={`/transactions/${row.original.id}`}>{value}</Link>
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value, row }) => (
      <div className='w-56'>
        <Link to={`/transactions/${row.original.id}`}>{value}</Link>
      </div>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <TransactionStateCell status={value} />,
    Filter: TransactionStatusSelectColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <TransactionTypeCell type={value} />,
    Filter: TransactionTypeSelectColumnFilter,
  },
  {
    Header: 'Closing Date',
    accessor: 'closingDate',
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: DateColumnFilter,
  },
  {
    Header: 'Sales Price',
    accessor: 'salesPrice',
    Cell: ({ value }) => numberToMoney(value),
    Filter: NumberColumnFilter,
  },
  {
    Header: 'Total Commission',
    accessor: 'totalCommission',
    Cell: ({ value }) => numberToMoney(value),
    Filter: NumberColumnFilter,
  },
  {
    Header: 'Participants Split',
    accessor: 'participantsSplit',
    Cell: ({ value, row }) =>
      `${numberToMoney(value)} (${row.original.participantsSplitPercentage}%)`,
    Filter: NumberColumnFilter,
  },
  {
    Header: 'Total Real Split',
    accessor: 'totalRealSplit',
    Cell: ({ value, row }) =>
      `${numberToMoney(value)} (${row.original.totalRealSplitPercentage}%)`,
    Filter: NumberColumnFilter,
  },
  {
    Header: 'Agents',
    accessor: 'agents',
    disableFilters: true,
    Cell: ({ value }) => <AvatarPillCell items={value} emptyText='No Agents' />,
  },
  {
    Header: 'State',
    accessor: 'state',
    Cell: ({ value }) => <StateIconCell state={value} />,
    Filter: StateSelectColumnFilter,
  },
  {
    accessor: 'teamId',
    disableFilters: true,
    disableSortBy: true,
  },
];

const TeamTransactionsRoute: React.FC<TeamTransactionsRouteProps> = ({
  match,
}) => {
  const teamId = parseInt(match.params.id, 10);

  return (
    <div className='px-4 pt-5 pb-10'>
      <ResourceIndexContainer<Transaction>
        header='Transactions'
        columns={columns}
        filter={{
          teamId,
        }}
        hiddenColumns={['teamId']}
        resourceName='Team Transactions'
        fetchData={(req) => new MockRealApi().transactionPagination(req)}
      />
    </div>
  );
};

export default TeamTransactionsRoute;
