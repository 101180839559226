import React from 'react';

export interface AttachmentRowProps {
  title: string;
  icon: React.ReactElement;
  label: string;
  action?: {
    text: string;
    onClick(): void;
  };
}

const AttachmentRow: React.FC<AttachmentRowProps> = ({
  title,
  icon,
  label,
  action,
}) => {
  return (
    <div>
      <p className='py-1'>{title}</p>
      <div className='border rounded-lg border-gray-200 flex flex-row items-center justify-between px-3 py-2'>
        <div className='items-center flex flex-row space-x-1'>
          {icon}
          <p className='text-sm'>{label}</p>
        </div>
        {action && (
          <button
            onClick={action.onClick}
            className='text-primary font-primary-medium text-sm'
          >
            {action.text}
          </button>
        )}
      </div>
    </div>
  );
};

export default AttachmentRow;
