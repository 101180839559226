import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

export interface ReferralAgentNameCellProps {
  id: string | undefined;
  externalAgentName: string | undefined;
}

const ReferralAgentNameCell: React.FC<ReferralAgentNameCellProps> = ({
  id,
  externalAgentName,
}) => {
  const { usersById } = useSelector((state: RootState) => state.userIds);
  return (
    <p>
      {usersById[id!]?.firstName
        ? `${usersById[id!]?.firstName} ${usersById[id!]?.lastName}`
        : externalAgentName || 'NA'}
    </p>
  );
};

export default ReferralAgentNameCell;
