import { ExitToApp, Visibility } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import IconButton from '../components/IconButton';
import InvoicesStatusCell from '../components/table/Cells/InvoicesStatusCell';
import NullableTextCell from '../components/table/Cells/NullableTextCell';
import InvoiceStatusSelectColumnFilter from '../components/table/Filters/InvoiceStatusSelectColumnFilter';
import NumberColumnFilter from '../components/table/Filters/NumberColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import InvoiceFormSidebarModal from '../forms/InvoiceFormSidebarModal';
import {
  InvoiceControllerApi,
  InvoiceResponse,
  SearchParamOperatorEnum,
  SearchRequestTargetObjectEnum,
} from '../openapi/arrakis';
import ErrorService from '../services/ErrorService';
import { showErrorToastForErrorCode } from '../slices/ToastNotificationSlice';
import { RootState } from '../types';
import { numberToMoney } from '../utils/CurrencyUtils';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { resourceTableFetchData } from '../utils/TableUtils';

interface TransactionPaymentInvoicesRouteProps {}
export const columns: Array<Column<InvoiceResponse>> = [
  {
    Header: 'Transaction Code',
    accessor: 'transactionCode',
    Cell: ({ value }) => (
      <Link to={`/transactions/code/${value}`}>
        <IconButton
          leftIcon={<ExitToApp titleAccess='Link' fontSize='small' />}
          variant='outline'
          label={value}
        />
      </Link>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Invoice Number',
    accessor: 'invoiceNumber',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Amount',
    accessor: 'invoicedAmount',
    Cell: ({ value }) => (value ? numberToMoney(value.amount!) : 'N/A'),
    Filter: NumberColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: InvoiceStatusSelectColumnFilter,
    Cell: ({ value }) => <InvoicesStatusCell type={value!} />,
  },
  {
    Header: 'Company',
    accessor: 'company',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Payment System Id',
    accessor: 'paymentSystemId',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
    cardColSize: 6,
  },
];
export const columnsToFetch = [
  ...columns.map((col) => col.accessor as string),
  'firstName',
  'lastName',
];

const TransactionPaymentInvoicesRoute: React.FC<TransactionPaymentInvoicesRouteProps> = () => {
  const { transactionDetail } = useSelector(
    (state: RootState) => state.transaction,
  );
  const dispatch = useDispatch();

  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceResponse | null>(
    null,
  );

  const getInvoiceDetailsById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new InvoiceControllerApi(
          getArrakisConfiguration(),
        ).getInvoiceUsingGET(id);

        setCurrentInvoice(data);
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the invoice details',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!invoiceId) {
      getInvoiceDetailsById(invoiceId);
    }
  }, [invoiceId, getInvoiceDetailsById]);

  const columnsWithAction: Array<Column<InvoiceResponse>> = [
    {
      Header: 'Action',
      accessor: 'id',
      id: 'action',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <IconButton
          leftIcon={<Visibility titleAccess='Edit' data-testid='Edit' />}
          onClick={() => setInvoiceId(original.id!)}
        />
      ),
    },
    {
      Header: 'Name',
      accessor: 'firstName',
      Filter: TextColumnFilter,
      Cell: ({ value, row: { original } }) => (
        <p>{`${value} ${original.lastName}`}</p>
      ),
    },
    ...columns,
  ];

  return (
    <div className='px-4 lg:py-5'>
      <ResourceIndexContainer<InvoiceResponse>
        header='Payment Invoices'
        columns={columnsWithAction}
        resourceName='Payment Invoice'
        fetchData={async (req) =>
          resourceTableFetchData(
            req,
            columnsToFetch,
            [],
            SearchRequestTargetObjectEnum.Invoice,
            'arrakis',
            {
              filterAnd: [
                {
                  column: 'transactionCode',
                  operator: SearchParamOperatorEnum.Eq,
                  value: transactionDetail!.code,
                },
              ],
            },
          )
        }
      />
      <InvoiceFormSidebarModal
        isOpen={!!invoiceId && !!currentInvoice}
        onClose={() => {
          setInvoiceId(null);
          setCurrentInvoice(null);
        }}
        invoice={currentInvoice!}
      />
    </div>
  );
};

export default TransactionPaymentInvoicesRoute;
