import React from 'react';
import CardWithItems from '../CardWithItems';
import { AgentTeamMembershipResponse } from '../../openapi/yenta';

interface TeamsCardProps {
  teams: AgentTeamMembershipResponse[];
}

const TeamsCard: React.FC<TeamsCardProps> = ({ teams }) => {
  const data = teams.map((team) => {
    return {
      name: team.team?.name!,
      url: `/teams/${team.team?.id}`,
      subtitle: `Split (${team.memberCommissionSplit}/${
        100 - team.memberCommissionSplit!
      })`,
    };
  });
  return <CardWithItems title='Teams' items={data} />;
};

export default TeamsCard;
