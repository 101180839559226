import React from 'react';
import classNames from 'classnames';
import { CheckCircle } from '@material-ui/icons';
import { onBoardingStepType } from '../../types';

export interface ApplicationStepProgressHeaderProps {
  steps: onBoardingStepType[];
  inProgressIndex: number;
}

const ApplicationStepProgressHeader: React.FC<ApplicationStepProgressHeaderProps> = ({
  steps,
  inProgressIndex,
}) => (
  <ol className='flex lg:justify-center overflow-x-auto space-x-5 px-4'>
    {steps.map((step, index) => {
      const isStepComplete = inProgressIndex > index;
      const isStepInProgress = inProgressIndex === index;
      const isStepPending = inProgressIndex < index;

      return (
        <li key={step.name}>
          <span className='flex items-center'>
            <span className='flex-shrink-0 relative h-5 w-5 flex items-center justify-center'>
              {isStepComplete && (
                <CheckCircle fontSize='small' className='text-primary' />
              )}
              {isStepPending && (
                <div className='h-4 w-4 bg-gray-200 rounded-full' />
              )}
              {isStepInProgress && (
                <>
                  <span className='absolute h-5 w-5 rounded-full bg-indigo-100' />
                  <span className='relative block w-2.5 h-2.5 bg-primary rounded-full' />
                </>
              )}
            </span>
            <span
              className={classNames(
                'ml-3 text-sm font-primary-regular text-gray-500 whitespace-nowrap pt-1',
                {
                  'text-dark': isStepComplete,
                  'text-primary': isStepInProgress,
                },
              )}
            >
              {step.name}
            </span>
          </span>
        </li>
      );
    })}
  </ol>
);

export default ApplicationStepProgressHeader;
