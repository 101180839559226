import { ComponentType } from 'react';

export interface FormComponentProps {
  onSubmit(values: any): void;
}

export type FormTypeToComponentMap = {
  [key in FormType]: ComponentType<FormComponentProps>;
};

export enum FormType {
  ICA_STANDARD_V1 = 'ICA_STANDARD_V1',
  COMMERCIAL_ICA = 'COMMERCIAL_ICA',
  LETTER_OF_INTENT = 'LETTER_OF_INTENT',
}
