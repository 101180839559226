import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { values } from 'lodash';
import ConfirmationModal from '../ConfirmationModal';
import Button from '../Button';
import { capitalizeEnum, MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import ControlledTextInput from '../ControlledTextInput';
import {
  DisburseReferralRequest,
  DisburseReferralRequestPaymentMediumEnum,
  MoneyValueCurrencyEnum,
  ReferralResponse,
} from '../../openapi/arrakis';
import { AppDispatch } from '../../types';
import { disburseReferralDispatch } from '../../slices/AgentSlice';
import ControlledSelectInput from '../ControlledSelectInput';

interface DisburseReferralProps {
  disburseReferral: ReferralResponse | null;
  onClose(): void;
}

interface FormData {
  amount: number;
  paymentMedium: DisburseReferralRequestPaymentMediumEnum;
  referenceNumber: string;
}

const DisburseReferral: React.FC<DisburseReferralProps> = ({
  disburseReferral,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    const disburseRequest: DisburseReferralRequest = {
      amount: {
        amount: data.amount,
        currency: MoneyValueCurrencyEnum.Usd,
      },
      paymentMedium: data.paymentMedium,
      referenceNumber: data.referenceNumber,
    };

    const success = await dispatch(
      disburseReferralDispatch(disburseReferral?.id!, disburseRequest),
    );

    if (success) {
      onClose();
    }
  };

  return (
    <ConfirmationModal
      isOpen={!!disburseReferral}
      variant='info'
      title='Referral Disbursement'
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='disburse-referral'
      >
        <div className='mt-2'>
          <ControlledTextInput
            control={control}
            label='Amount'
            name='amount'
            errors={errors}
            rules={{
              pattern: {
                value: MONEY_AMOUNT_REGEX,
                message: 'Please enter a valid amount',
              },
              required: 'Please provide an amount',
            }}
          />
        </div>
        <div className='mt-2'>
          <ControlledSelectInput
            name='paymentMedium'
            control={control}
            errors={errors}
            label='Payment Medium'
            options={[
              { label: '', value: '', disabled: true },
              ...values(DisburseReferralRequestPaymentMediumEnum).map(
                (item) => ({
                  value: item,
                  label: capitalizeEnum(item),
                }),
              ),
            ]}
            rules={{
              required: 'Please provide a payment medium',
            }}
          />
        </div>
        <div className='mt-2'>
          <ControlledTextInput
            control={control}
            label='Reference Number'
            name='referenceNumber'
            errors={errors}
            rules={{
              required: 'Please provide a reference number',
            }}
          />
        </div>
        <div className='mt-3 space-x-3'>
          <Button
            label={isSubmitting ? 'Submitting...' : 'Disburse'}
            disabled={isSubmitting}
            type='primary'
            buttonType='submit'
          />
          <Button label='Cancel' type='secondary' onClick={onClose} />
        </div>
      </form>
    </ConfirmationModal>
  );
};

export default DisburseReferral;
