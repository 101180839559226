import { SearchRequestTargetObjectEnum } from '../../openapi/arrakis';
import { resourceTableFetchData } from '../../utils/TableUtils';

export interface Transaction {
  stateName: string;
  amount: number;
  createdAt: Date;
}

export const createTransaction = (
  stateName: string,
  amount: number,
  createdAt: Date,
): Transaction => ({
  stateName,
  amount,
  createdAt,
});

export interface FetchedTransaction {
  createdAt: number;
  address: {
    state: string | null;
  };
  price: {
    amount: number;
  };
}

const getStateName = (transaction: FetchedTransaction) =>
  (transaction.address.state || '').toLowerCase();

const getTransactionDate = (transaction: FetchedTransaction) =>
  new Date(transaction.createdAt);

const getTransactionAmount = (transaction: FetchedTransaction) =>
  transaction.price.amount;

export const getTransactions = async (): Promise<Transaction[]> => {
  const fetchData = async (pageNumber: number = 1) => {
    const response = await resourceTableFetchData<FetchedTransaction>(
      { page: pageNumber, pageSize: 500 },
      ['address', 'price', 'createdAt'],
      [],
      SearchRequestTargetObjectEnum.Transaction,
      'arrakis',
    );

    const json: FetchedTransaction[] = response.data;
    let data: Transaction[] = [];

    for (let i = 0; i < json.length; i++) {
      const transaction = json[i];

      data.push(
        createTransaction(
          getStateName(transaction),
          getTransactionAmount(transaction),
          getTransactionDate(transaction),
        ),
      );
    }

    if (json.length) {
      data.push(...(await fetchData(pageNumber + 1)));
    }

    return data;
  };

  return await fetchData();
};

export const approxNumber = (x: number, prefix: string): string => {
  const THOUSAND = 1000;
  const TEN_THOUSAND = 10000;
  const MILLION = 1000000;
  const BILLION = 1000000000;
  const TRILLION = 1000000000000;

  let res = prefix;
  if (x === 0) return '0';
  if (x < THOUSAND) {
    // 100
    res += x;
  } else if (x < TEN_THOUSAND) {
    // 1000
    res += Math.round((x / THOUSAND) * 100) / 100 + 'K';
  } else if (x < MILLION) {
    // 10000
    res += Math.round((x / THOUSAND) * 100) / 100 + 'K';
  } else if (x < BILLION) {
    res += Math.round((x / MILLION) * 100) / 100 + 'M';
  } else if (x < TRILLION) {
    res += Math.round((x / BILLION) * 100) / 100 + 'B';
  } else {
    res += Math.round((x / TRILLION) * 100) / 100 + 'T';
  }

  return res;
};

export const getStateTransactionMap = (
  transactions: Transaction[],
  startDate: string,
  endDate: string,
) => {
  let map = new Map();

  for (let i = 0; i < transactions.length; i++) {
    const transaction = transactions[i];

    const stateName = transaction.stateName;
    const createdDate = transaction.createdAt;
    const amount = transaction.amount;

    if (startDate.length !== 0 && new Date(startDate) > createdDate) continue;
    if (endDate.length !== 0 && new Date(endDate) < createdDate) continue;

    if (map.has(stateName)) map.set(stateName, map.get(stateName) + amount);
    else map.set(stateName, amount);
  }

  return map;
};
