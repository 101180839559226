import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PageLayoutWithSearch from '../components/PageLayoutWithSearch';
import ApplicationStatus from '../components/table/Cells/ApplicationStatus';
import StateOrProvinceIconCell from '../components/table/Cells/StateOrProvinceIconCell';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import {
  ApplicationResponse,
  SearchRequestTargetObjectEnum,
} from '../openapi/yenta';
import { resourceTableFetchData } from '../utils/TableUtils';
import DateColumnFilter from '../components/table/Filters/DateColumnFilter';
import ApplicationResponseStatusEnumSelectColumnFilter from '../components/table/Filters/ApplicationResponseStatusEnumSelectColumnFilter';
import MilliDateCell from '../components/table/Cells/MilliDateCell';
import IconButton from '../components/IconButton';
import PhoneNumberCell from '../components/table/Cells/PhoneNumberCell';

interface ApplicationFormIndexProps {}

export const columns: Array<Column<ApplicationResponse>> = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Email',
    accessor: 'emailAddress',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <ApplicationStatus status={value!} />,
    Filter: ApplicationResponseStatusEnumSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    Cell: ({ value }) => <PhoneNumberCell phoneNumber={value} />,
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'State/Province',
    id: 'state',
    accessor: 'doesBusinessIn',
    Cell: ({ value }) => {
      const stateOrProvince =
        value && value.length && value[0].administrativeArea!.stateOrProvince;

      return (
        stateOrProvince && <StateOrProvinceIconCell state={stateOrProvince} />
      );
    },
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'License Number',
    id: 'licenseNumber',
    accessor: 'doesBusinessIn',
    Cell: ({ value }) => {
      return value && value.length && value[0].number;
    },
    disableFilters: true,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => <MilliDateCell date={value!} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt',
    Cell: ({ value }) => <MilliDateCell date={value!} />,
    Filter: DateColumnFilter,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    disableFilters: true,
    disableSortBy: true,
  },
];

export const columnsWithAction: Array<Column<ApplicationResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<ApplicationResponse> }) => (
      <Link to={`/applications/${row.original.id}`}>
        <IconButton leftIcon={<VisibilityIcon titleAccess='View' />} />
      </Link>
    ),
  },
  ...columns,
];

export const columnsToFetch = columns.map((col) => col.accessor as string);
const columnsForSearch = ['firstName', 'lastName', 'emailAddress'];

const ApplicationFormIndex: React.FC<ApplicationFormIndexProps> = () => {
  const [search, setSearch] = useState<string>();

  return (
    <PageLayoutWithSearch
      path={[
        { title: 'Home', url: '/' },
        { title: 'Applications', url: '/applications' },
      ]}
      search={search}
      onSearchChange={setSearch}
    >
      <div className='px-4 lg:py-5'>
        <ResourceIndexContainer<ApplicationResponse>
          header='Applications'
          columns={columnsWithAction}
          search={search}
          resourceName='application'
          initialSort={{ updatedAt: 'desc' }}
          fetchData={(req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              columnsForSearch,
              SearchRequestTargetObjectEnum.Application,
              'yenta',
            )
          }
        />
      </div>
    </PageLayoutWithSearch>
  );
};

export default ApplicationFormIndex;
