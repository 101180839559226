/* tslint:disable */
/* eslint-disable */
/**
 * yenta-api API
 * yenta-api is a demo
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddDomesticTeamMemberRequest
 */
export interface AddDomesticTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddDomesticTeamMemberRequest
     */
    agentId?: string;
}
/**
 * 
 * @export
 * @interface AddNormalTeamMemberRequest
 */
export interface AddNormalTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddNormalTeamMemberRequest
     */
    agentId?: string;
    /**
     * 
     * @type {number}
     * @memberof AddNormalTeamMemberRequest
     */
    commissionSplit?: number;
}
/**
 * 
 * @export
 * @interface AddPlatinumTeamMemberRequest
 */
export interface AddPlatinumTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPlatinumTeamMemberRequest
     */
    agentId?: string;
    /**
     * 
     * @type {number}
     * @memberof AddPlatinumTeamMemberRequest
     */
    commissionSplit?: number;
}
/**
 * 
 * @export
 * @interface AddressRequest
 */
export interface AddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    country: AddressRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    stateOrProvince?: AddressRequestStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    streetAddress1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    streetAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    zipOrPostalCode: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddressRequestCountryEnum {
    Canada = 'CANADA',
    UnitedStates = 'UNITED_STATES'
}
/**
    * @export
    * @enum {string}
    */
export enum AddressRequestStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Alberta = 'ALBERTA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    BritishColumbia = 'BRITISH_COLUMBIA',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Manitoba = 'MANITOBA',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    NovaScotia = 'NOVA_SCOTIA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Ontario = 'ONTARIO',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    PuertoRico = 'PUERTO_RICO',
    Quebec = 'QUEBEC',
    RhodeIsland = 'RHODE_ISLAND',
    Saskatchewan = 'SASKATCHEWAN',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING'
}

/**
 * 
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    country?: AddressResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    oneLine?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    stateOrProvince?: AddressResponseStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    streetAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    streetAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    zipOrPostalCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddressResponseCountryEnum {
    Canada = 'CANADA',
    UnitedStates = 'UNITED_STATES'
}
/**
    * @export
    * @enum {string}
    */
export enum AddressResponseStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Alberta = 'ALBERTA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    BritishColumbia = 'BRITISH_COLUMBIA',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Manitoba = 'MANITOBA',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    NovaScotia = 'NOVA_SCOTIA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Ontario = 'ONTARIO',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    PuertoRico = 'PUERTO_RICO',
    Quebec = 'QUEBEC',
    RhodeIsland = 'RHODE_ISLAND',
    Saskatchewan = 'SASKATCHEWAN',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING'
}

/**
 * 
 * @export
 * @interface AdministrativeAreaRequest
 */
export interface AdministrativeAreaRequest {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaRequest
     */
    country: AdministrativeAreaRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaRequest
     */
    stateOrProvince: AdministrativeAreaRequestStateOrProvinceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdministrativeAreaRequestCountryEnum {
    Canada = 'CANADA',
    UnitedStates = 'UNITED_STATES'
}
/**
    * @export
    * @enum {string}
    */
export enum AdministrativeAreaRequestStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Alberta = 'ALBERTA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    BritishColumbia = 'BRITISH_COLUMBIA',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Manitoba = 'MANITOBA',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    NovaScotia = 'NOVA_SCOTIA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Ontario = 'ONTARIO',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    PuertoRico = 'PUERTO_RICO',
    Quebec = 'QUEBEC',
    RhodeIsland = 'RHODE_ISLAND',
    Saskatchewan = 'SASKATCHEWAN',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING'
}

/**
 * 
 * @export
 * @interface AdministrativeAreaResponse
 */
export interface AdministrativeAreaResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AdministrativeAreaResponse
     */
    agentCanTransferLicense?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    country?: AdministrativeAreaResponseCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeAreaResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    id?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AdministrativeAreaResponse
     */
    licenseTransferFee?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AdministrativeAreaResponse
     */
    signupFee?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    stateOrProvince?: AdministrativeAreaResponseStateOrProvinceEnum;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeAreaResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AdministrativeAreaResponseCountryEnum {
    Canada = 'CANADA',
    UnitedStates = 'UNITED_STATES'
}
/**
    * @export
    * @enum {string}
    */
export enum AdministrativeAreaResponseStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Alberta = 'ALBERTA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    BritishColumbia = 'BRITISH_COLUMBIA',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Manitoba = 'MANITOBA',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    NovaScotia = 'NOVA_SCOTIA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Ontario = 'ONTARIO',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    PuertoRico = 'PUERTO_RICO',
    Quebec = 'QUEBEC',
    RhodeIsland = 'RHODE_ISLAND',
    Saskatchewan = 'SASKATCHEWAN',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING'
}

/**
 * 
 * @export
 * @interface AgentPreviewResponse
 */
export interface AgentPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    about?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    clubhouseURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    facebookURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    googleBusinessProfileURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    instagramURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    joinDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    licenseVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    linkedInURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    middleName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    needOnBoarding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    personalWebsiteURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    serviceAreaZipcodes?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    skySlopeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    skySlopePublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    tipaltiId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    twitterURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    type?: AgentPreviewResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    yelpURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    youtubeURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    zillowURL?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AgentPreviewResponseTypeEnum {
    Agent = 'AGENT',
    Broker = 'BROKER',
    External = 'EXTERNAL'
}

/**
 * 
 * @export
 * @interface AgentProfileScoreResponse
 */
export interface AgentProfileScoreResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    hasAddedSocialMediaAccounts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    hasAddedWebsites: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    hasUpdatedZipcodes: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    hasUploadedPhoto: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    hasWrittenBio: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentProfileScoreResponse
     */
    percentage: string;
}
/**
 * 
 * @export
 * @interface AgentResponse
 */
export interface AgentResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    about?: string;
    /**
     * 
     * @type {Array<AddressResponse>}
     * @memberof AgentResponse
     */
    addresses?: Array<AddressResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    agentStatus?: AgentResponseAgentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    anniversaryDate?: string;
    /**
     * 
     * @type {Array<ApplicationResponse>}
     * @memberof AgentResponse
     */
    applications?: Array<ApplicationResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    birthDate?: string;
    /**
     * 
     * @type {Array<BoardPreviewResponse>}
     * @memberof AgentResponse
     */
    boards?: Array<BoardPreviewResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    clubhouseURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    company?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    competencies?: Array<AgentResponseCompetenciesEnum>;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    facebookURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    googleBusinessProfileURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    hasSignedICA?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    instagramURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    internalPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    joinDate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    languages?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    lastAnniversaryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    licenseVerified?: boolean;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof AgentResponse
     */
    licenses?: Array<LicenseResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    linkedInURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    middleName?: string;
    /**
     * 
     * @type {Array<MlsPreviewResponse>}
     * @memberof AgentResponse
     */
    mls?: Array<MlsPreviewResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    needOnBoarding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    nextAnniversaryDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    notifyLeadsInSMS?: boolean;
    /**
     * 
     * @type {Array<OfficePreviewResponse>}
     * @memberof AgentResponse
     */
    offices?: Array<OfficePreviewResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    personalEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    personalPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    personalWebsiteURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {PlanMembershipResponse}
     * @memberof AgentResponse
     */
    planMembership?: PlanMembershipResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    receivesLeadsOnlyFromZipcodes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    receivesPaymentEmailNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    receivesPaymentPushNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    receivesSupportNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    receivesTransactionEmailNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    receivesTransactionPushNotifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    serviceAreaZipcodes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    services?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    sizeOfNetwork?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    skySlopeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    skySlopePublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    sponsorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    sponsorId?: string;
    /**
     * 
     * @type {Array<AgentTeamMembershipResponse>}
     * @memberof AgentResponse
     */
    teamMemberships?: Array<AgentTeamMembershipResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    tipaltiConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    tipaltiId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    twitterURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    type?: AgentResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    yelpURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    youtubeURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    zillowURL?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AgentResponseAgentStatusEnum {
    Active = 'ACTIVE',
    Candidate = 'CANDIDATE',
    Inactive = 'INACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum AgentResponseCompetenciesEnum {
    Buyers = 'BUYERS',
    Commercial = 'COMMERCIAL',
    FarmAndRanch = 'FARM_AND_RANCH',
    Investors = 'INVESTORS',
    ProvidingReferrals = 'PROVIDING_REFERRALS',
    ReceivingReferrals = 'RECEIVING_REFERRALS',
    Residential = 'RESIDENTIAL',
    Sellers = 'SELLERS'
}
/**
    * @export
    * @enum {string}
    */
export enum AgentResponseTypeEnum {
    Agent = 'AGENT',
    Broker = 'BROKER',
    External = 'EXTERNAL'
}

/**
 * 
 * @export
 * @interface AgentTeamMembershipResponse
 */
export interface AgentTeamMembershipResponse {
    /**
     * 
     * @type {number}
     * @memberof AgentTeamMembershipResponse
     */
    memberCommissionSplit?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentTeamMembershipResponse
     */
    roles?: Array<AgentTeamMembershipResponseRolesEnum>;
    /**
     * 
     * @type {TeamPreviewResponse}
     * @memberof AgentTeamMembershipResponse
     */
    team?: TeamPreviewResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum AgentTeamMembershipResponseRolesEnum {
    Admin = 'ADMIN',
    Leader = 'LEADER',
    Member = 'MEMBER'
}

/**
 * 
 * @export
 * @interface AgentUpdateLicenseBoardMlsRequest
 */
export interface AgentUpdateLicenseBoardMlsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateLicenseBoardMlsRequest
     */
    boardIds: Array<string>;
    /**
     * 
     * @type {Array<LicenseRequest>}
     * @memberof AgentUpdateLicenseBoardMlsRequest
     */
    licenses: Array<LicenseRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateLicenseBoardMlsRequest
     */
    mlsIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface AgentUpdateLicenseVerifiedRequest
 */
export interface AgentUpdateLicenseVerifiedRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateLicenseVerifiedRequest
     */
    licenseVerified: boolean;
}
/**
 * 
 * @export
 * @interface AgentUpdateNeedOnBoardingRequest
 */
export interface AgentUpdateNeedOnBoardingRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateNeedOnBoardingRequest
     */
    needOnBoarding: boolean;
}
/**
 * 
 * @export
 * @interface AgentUpdateRequest
 */
export interface AgentUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    about?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof AgentUpdateRequest
     */
    addressRequest?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    clubhouseURL?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateRequest
     */
    competencies?: Array<AgentUpdateRequestCompetenciesEnum>;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    facebookURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    googleBusinessProfileURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    instagramURL?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateRequest
     */
    languages?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    linkedInURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    notifyLeadsInSMS?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    optInToEquityPlan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    personalEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    personalPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    personalWebsiteURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {RealV1AgentWebsiteUpdateRequest}
     * @memberof AgentUpdateRequest
     */
    realV1AgentWebsiteUpdateRequest?: RealV1AgentWebsiteUpdateRequest;
    /**
     * 
     * @type {RealV1AgentWebsiteUpdateSearchCriteriaRequest}
     * @memberof AgentUpdateRequest
     */
    realV1AgentWebsiteUpdateSearchCriteriaRequest?: RealV1AgentWebsiteUpdateSearchCriteriaRequest;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    receivesLeadsOnlyFromZipcodes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    receivesPaymentEmailNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    receivesPaymentPushNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    receivesSupportNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    receivesTransactionEmailNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    receivesTransactionPushNotifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    serviceAreaZipcodes?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    slug?: string;
    /**
     * 
     * @type {Array<RealV1AgentWebsiteUpdateTestimonyRequest>}
     * @memberof AgentUpdateRequest
     */
    testimoniesUpdate?: Array<RealV1AgentWebsiteUpdateTestimonyRequest>;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    twitterURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    yelpURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    youtubeURL?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    zillowURL?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AgentUpdateRequestCompetenciesEnum {
    Buyers = 'BUYERS',
    Commercial = 'COMMERCIAL',
    FarmAndRanch = 'FARM_AND_RANCH',
    Investors = 'INVESTORS',
    ProvidingReferrals = 'PROVIDING_REFERRALS',
    ReceivingReferrals = 'RECEIVING_REFERRALS',
    Residential = 'RESIDENTIAL',
    Sellers = 'SELLERS'
}

/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponse
     */
    status?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationRequest
 */
export interface ApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    applicationType?: ApplicationRequestApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    boardOrMls: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationRequest
     */
    commercialAgent: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    currentBrokerage: string;
    /**
     * 
     * @type {Array<LicenseRequest>}
     * @memberof ApplicationRequest
     */
    doesBusinessIn: Array<LicenseRequest>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    estimatedSales: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    sponsoredByCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationRequestApplicationTypeEnum {
    Ica = 'ICA',
    LetterOfIntent = 'LETTER_OF_INTENT',
    Regular = 'REGULAR'
}

/**
 * 
 * @export
 * @interface ApplicationResponse
 */
export interface ApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    agentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    applicationType?: ApplicationResponseApplicationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    approvedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    approvedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    boardOrMls?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    commercialAgent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    currentBrokerage?: string;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof ApplicationResponse
     */
    doesBusinessIn?: Array<LicenseResponse>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    estimatedSales?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    feesPaidAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    feesStripeChargeId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaAnniversaryDateRequested?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaEmergencyContactEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaEmergencyContactName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaEmergencyContactPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaEmergencyContactRelationship?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaInitials?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaReferringAgentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaSignature?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    icaSignedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    icaType?: ApplicationResponseIcaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    licenseTransferredAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    loiName?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    loiNumDaysUntilJoining?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    loiResidentIn?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    loiSignature?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    loiSignedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    nextStatus?: ApplicationResponseNextStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    optInToStockPlan?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof ApplicationResponse
     */
    paidFeesAmount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    rejectedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    rejectedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    sponsorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    status?: ApplicationResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationResponseApplicationTypeEnum {
    Ica = 'ICA',
    LetterOfIntent = 'LETTER_OF_INTENT',
    Regular = 'REGULAR'
}
/**
    * @export
    * @enum {string}
    */
export enum ApplicationResponseIcaTypeEnum {
    CommercialStandardV1 = 'COMMERCIAL_STANDARD_V1',
    ResidentialStandardV1 = 'RESIDENTIAL_STANDARD_V1'
}
/**
    * @export
    * @enum {string}
    */
export enum ApplicationResponseNextStatusEnum {
    Approved = 'APPROVED',
    BoardJoined = 'BOARD_JOINED',
    FeesPaid = 'FEES_PAID',
    IcaSigned = 'ICA_SIGNED',
    JoinBoard = 'JOIN_BOARD',
    JoinMls = 'JOIN_MLS',
    LetterOfIntentSigned = 'LETTER_OF_INTENT_SIGNED',
    LicenseTransferred = 'LICENSE_TRANSFERRED',
    MlsJoined = 'MLS_JOINED',
    PayFees = 'PAY_FEES',
    PendingApproval = 'PENDING_APPROVAL',
    Rejected = 'REJECTED',
    SignIca = 'SIGN_ICA',
    SignLetterOfIntent = 'SIGN_LETTER_OF_INTENT',
    Started = 'STARTED',
    TransferLicense = 'TRANSFER_LICENSE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApplicationResponseStatusEnum {
    Approved = 'APPROVED',
    BoardJoined = 'BOARD_JOINED',
    FeesPaid = 'FEES_PAID',
    IcaSigned = 'ICA_SIGNED',
    JoinBoard = 'JOIN_BOARD',
    JoinMls = 'JOIN_MLS',
    LetterOfIntentSigned = 'LETTER_OF_INTENT_SIGNED',
    LicenseTransferred = 'LICENSE_TRANSFERRED',
    MlsJoined = 'MLS_JOINED',
    PayFees = 'PAY_FEES',
    PendingApproval = 'PENDING_APPROVAL',
    Rejected = 'REJECTED',
    SignIca = 'SIGN_ICA',
    SignLetterOfIntent = 'SIGN_LETTER_OF_INTENT',
    Started = 'STARTED',
    TransferLicense = 'TRANSFER_LICENSE'
}

/**
 * 
 * @export
 * @interface ApplicationUpdateRequest
 */
export interface ApplicationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    boardOrMls: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUpdateRequest
     */
    commercialAgent: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    currentBrokerage: string;
    /**
     * 
     * @type {Array<LicenseRequest>}
     * @memberof ApplicationUpdateRequest
     */
    doesBusinessIn: Array<LicenseRequest>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    estimatedSales: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUpdateRequest
     */
    phoneNumber: string;
}
/**
 * 
 * @export
 * @interface ApproveApplicationRequest
 */
export interface ApproveApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof ApproveApplicationRequest
     */
    agentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApproveApplicationRequest
     */
    boardIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApproveApplicationRequest
     */
    mlsIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApproveApplicationRequest
     */
    officeIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface BankAccountResponse
 */
export interface BankAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    accountNumber?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof BankAccountResponse
     */
    bankAddress?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    bankRoutingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    wireRoutingNumber?: string;
}
/**
 * 
 * @export
 * @interface BoardJoinedRequest
 */
export interface BoardJoinedRequest {
    /**
     * 
     * @type {string}
     * @memberof BoardJoinedRequest
     */
    applicationId: string;
}
/**
 * 
 * @export
 * @interface BoardPreviewResponse
 */
export interface BoardPreviewResponse {
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof BoardPreviewResponse
     */
    administrativeArea?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof BoardPreviewResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof BoardPreviewResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface BoardResponse
 */
export interface BoardResponse {
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof BoardResponse
     */
    administrativeArea?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof BoardResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    joinDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof BoardResponse
     */
    registrationFee?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    status?: BoardResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof BoardResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum BoardResponseStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    NotMembers = 'NOT_MEMBERS',
    Pending = 'PENDING',
    WillingToJoin = 'WILLING_TO_JOIN'
}

/**
 * 
 * @export
 * @interface CommissionPlanResponse
 */
export interface CommissionPlanResponse {
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    allowedPersonalDeals?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    annualBrokerageFee?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    annualCap?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    country?: CommissionPlanResponseCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    description?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    domesticTeamCap?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    groupTeamCap?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    id?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    minCommissionForBrokerageFee?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    name?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    normalTeamLeadAnnualCap?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    normalTeamMemberAnnualCap?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    perTransactionBrokerageFee?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    personalDealFee?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    platinumTeamLeadAnnualCap?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    platinumTeamMemberAnnualCap?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    postCapLeaseTransactionFee?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    postCapSalesTransactionFee?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    preCapRealCommissionSplit?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    signUpFee?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CommissionPlanResponseCountryEnum {
    Canada = 'CANADA',
    UnitedStates = 'UNITED_STATES'
}

/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * 
     * @type {AddressResponse}
     * @memberof CompanyResponse
     */
    address?: AddressResponse;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof CompanyResponse
     */
    administrativeArea?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CreateCommissionPlanRequest
 */
export interface CreateCommissionPlanRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateCommissionPlanRequest
     */
    allowedPersonalDeals: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    annualBrokerageFee: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    annualCap: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionPlanRequest
     */
    country: CreateCommissionPlanRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionPlanRequest
     */
    description?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    domesticTeamCap: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    groupTeamCap: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    minCommissionForBrokerageFee: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionPlanRequest
     */
    name: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    normalTeamLeadAnnualCap: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    normalTeamMemberAnnualCap: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    perTransactionBrokerageFee: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    personalDealFee: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    platinumTeamLeadAnnualCap: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    platinumTeamMemberAnnualCap: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    postCapLeaseTransactionFee: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    postCapSalesTransactionFee: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CreateCommissionPlanRequest
     */
    preCapRealCommissionSplit: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    signUpFee: MoneyValue;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateCommissionPlanRequestCountryEnum {
    Canada = 'CANADA',
    UnitedStates = 'UNITED_STATES'
}

/**
 * 
 * @export
 * @interface CreateDomesticTeamRequest
 */
export interface CreateDomesticTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDomesticTeamRequest
     */
    leaderAgentId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDomesticTeamRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateEliteEquityAwardPlanRequest
 */
export interface CreateEliteEquityAwardPlanRequest {
    /**
     * 
     * @type {Money}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    awardedAmount: Money;
    /**
     * 
     * @type {string}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    description?: string;
    /**
     * 
     * @type {Money}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    minGCI: Money;
    /**
     * 
     * @type {number}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    minTransactionsClosed: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    name: string;
    /**
     * 
     * @type {Money}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    postCapTransactionFeesTotal: Money;
    /**
     * 
     * @type {Money}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    postEliteLeaseTransactionFee: Money;
    /**
     * 
     * @type {Money}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    postEliteSalesTransactionFee: Money;
}
/**
 * 
 * @export
 * @interface CreateEquityAwardPlanRequest
 */
export interface CreateEquityAwardPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEquityAwardPlanRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEquityAwardPlanRequest
     */
    name: string;
    /**
     * 
     * @type {Array<CreateEquityAwardTierRequest>}
     * @memberof CreateEquityAwardPlanRequest
     */
    tiers: Array<CreateEquityAwardTierRequest>;
}
/**
 * 
 * @export
 * @interface CreateEquityAwardTierRequest
 */
export interface CreateEquityAwardTierRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateEquityAwardTierRequest
     */
    maxNumberOfAgents: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityAwardTierRequest
     */
    minNumberOfAgents: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityAwardTierRequest
     */
    numberOfSharesForAttracting: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityAwardTierRequest
     */
    numberOfSharesForCapping: number;
}
/**
 * 
 * @export
 * @interface CreateEquityPurchasePlanRequest
 */
export interface CreateEquityPurchasePlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEquityPurchasePlanRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEquityPurchasePlanRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityPurchasePlanRequest
     */
    postCapBonusSharePercent: number;
    /**
     * 
     * @type {Money}
     * @memberof CreateEquityPurchasePlanRequest
     */
    postCapMax: Money;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityPurchasePlanRequest
     */
    postCapNetCommissionPercent: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityPurchasePlanRequest
     */
    preCapBonusSharePercent: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityPurchasePlanRequest
     */
    preCapNetCommissionPercent: number;
}
/**
 * 
 * @export
 * @interface CreateExternalUserRequest
 */
export interface CreateExternalUserRequest {
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateExternalUserRequest
     */
    addressRequest?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalUserRequest
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalUserRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalUserRequest
     */
    phoneNumber?: string;
}
/**
 * 
 * @export
 * @interface CreateNormalTeamMemberRequest
 */
export interface CreateNormalTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNormalTeamMemberRequest
     */
    memberAgentId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateNormalTeamMemberRequest
     */
    memberCommissionSplit?: number;
}
/**
 * 
 * @export
 * @interface CreateNormalTeamRequest
 */
export interface CreateNormalTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNormalTeamRequest
     */
    leaderAgentId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateNormalTeamRequest
     */
    memberCommissionSplit: number;
    /**
     * 
     * @type {Array<CreateNormalTeamMemberRequest>}
     * @memberof CreateNormalTeamRequest
     */
    members: Array<CreateNormalTeamMemberRequest>;
    /**
     * 
     * @type {string}
     * @memberof CreateNormalTeamRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateNoteRequest
 */
export interface CreateNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    allowableRole: CreateNoteRequestAllowableRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    entityType: CreateNoteRequestEntityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateNoteRequest
     */
    followupDate?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateNoteRequestAllowableRoleEnum {
    Admin = 'ADMIN',
    Leader = 'LEADER',
    Member = 'MEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateNoteRequestEntityTypeEnum {
    Application = 'APPLICATION',
    Transaction = 'TRANSACTION'
}

/**
 * 
 * @export
 * @interface CreatePlatinumTeamRequest
 */
export interface CreatePlatinumTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePlatinumTeamRequest
     */
    leaderAgentId: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePlatinumTeamRequest
     */
    memberCommissionSplit: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePlatinumTeamRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateRevenueSharePlanRequest
 */
export interface CreateRevenueSharePlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRevenueSharePlanRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRevenueSharePlanRequest
     */
    name?: string;
    /**
     * 
     * @type {Array<CreateRevenueShareTierRequest>}
     * @memberof CreateRevenueSharePlanRequest
     */
    tiers: Array<CreateRevenueShareTierRequest>;
}
/**
 * 
 * @export
 * @interface CreateRevenueShareTierRequest
 */
export interface CreateRevenueShareTierRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateRevenueShareTierRequest
     */
    commissionPercent: number;
    /**
     * 
     * @type {Money}
     * @memberof CreateRevenueShareTierRequest
     */
    maxAnnualAmountPerAgent: Money;
    /**
     * 
     * @type {number}
     * @memberof CreateRevenueShareTierRequest
     */
    numberOfTierOneAgentsToUnlockThisTier: number;
    /**
     * 
     * @type {number}
     * @memberof CreateRevenueShareTierRequest
     */
    tier: number;
}
/**
 * 
 * @export
 * @interface EliteEquityAwardPlanResponse
 */
export interface EliteEquityAwardPlanResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    award?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    id?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    minGCI?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    minTransactionsClosed?: number;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    name?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    postCapTransactionFeesTotal?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    postEliteLeaseTransactionFee?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    postEliteSalesTransactionFee?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface EquityAwardPlanResponse
 */
export interface EquityAwardPlanResponse {
    /**
     * 
     * @type {number}
     * @memberof EquityAwardPlanResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<EquityAwardTierResponse>}
     * @memberof EquityAwardPlanResponse
     */
    tiers?: Array<EquityAwardTierResponse>;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardPlanResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface EquityAwardTierResponse
 */
export interface EquityAwardTierResponse {
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    maxNumberOfAgents?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    minNumberOfAgents?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    numberOfSharesForAttracting?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    numberOfSharesForCapping?: number;
}
/**
 * 
 * @export
 * @interface EquityPurchasePlanResponse
 */
export interface EquityPurchasePlanResponse {
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    postCapBonusSharePercent?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    postCapMax?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    postCapNetCommissionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    preCapBonusSharePercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    preCapNetCommissionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface FeePaymentRequest
 */
export interface FeePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof FeePaymentRequest
     */
    applicationId: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof FeePaymentRequest
     */
    fees: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof FeePaymentRequest
     */
    paymentMethod: string;
}
/**
 * 
 * @export
 * @interface ICASignRequest
 */
export interface ICASignRequest {
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    customAnniversaryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    emergencyContactEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    emergencyContactName?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    emergencyContactPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    emergencyContactRelationship?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    entityName?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    initials?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICASignRequest
     */
    optInStockPlan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    referringAgentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    signature?: string;
}
/**
 * 
 * @export
 * @interface JobExecutionResponse
 */
export interface JobExecutionResponse {
    /**
     * 
     * @type {number}
     * @memberof JobExecutionResponse
     */
    completedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof JobExecutionResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    errorLog?: string;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    jobExecutionName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    jobExecutionStatus?: JobExecutionResponseJobExecutionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof JobExecutionResponse
     */
    startedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof JobExecutionResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum JobExecutionResponseJobExecutionStatusEnum {
    Created = 'CREATED',
    Fail = 'FAIL',
    Running = 'RUNNING',
    Success = 'SUCCESS'
}

/**
 * 
 * @export
 * @interface JobExecutionsResponse
 */
export interface JobExecutionsResponse {
    /**
     * 
     * @type {Array<JobExecutionResponse>}
     * @memberof JobExecutionsResponse
     */
    jobExecutionResponses?: Array<JobExecutionResponse>;
}
/**
 * 
 * @export
 * @interface LetterOfIntentSignRequest
 */
export interface LetterOfIntentSignRequest {
    /**
     * 
     * @type {string}
     * @memberof LetterOfIntentSignRequest
     */
    applicationId: string;
    /**
     * 
     * @type {string}
     * @memberof LetterOfIntentSignRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LetterOfIntentSignRequest
     */
    numDays: number;
    /**
     * 
     * @type {string}
     * @memberof LetterOfIntentSignRequest
     */
    residentIn: string;
    /**
     * 
     * @type {string}
     * @memberof LetterOfIntentSignRequest
     */
    signature: string;
}
/**
 * 
 * @export
 * @interface LicenseRequest
 */
export interface LicenseRequest {
    /**
     * 
     * @type {boolean}
     * @memberof LicenseRequest
     */
    active: boolean;
    /**
     * 
     * @type {AdministrativeAreaRequest}
     * @memberof LicenseRequest
     */
    administrativeAreaRequest: AdministrativeAreaRequest;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequest
     */
    expirationDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseRequest
     */
    knownComplaints: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequest
     */
    licenseType?: LicenseRequestLicenseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequest
     */
    number: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LicenseRequestLicenseTypeEnum {
    Agent = 'AGENT',
    Broker = 'BROKER'
}

/**
 * 
 * @export
 * @interface LicenseResponse
 */
export interface LicenseResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LicenseResponse
     */
    active?: boolean;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof LicenseResponse
     */
    administrativeArea?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    expirationDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseResponse
     */
    knownComplaints?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    number?: string;
}
/**
 * 
 * @export
 * @interface LicenseTransferRequest
 */
export interface LicenseTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseTransferRequest
     */
    applicationId: string;
}
/**
 * 
 * @export
 * @interface MlsPreviewResponse
 */
export interface MlsPreviewResponse {
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof MlsPreviewResponse
     */
    administrativeAreas?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof MlsPreviewResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof MlsPreviewResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface MlsResponse
 */
export interface MlsResponse {
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof MlsResponse
     */
    administrativeAreas?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof MlsResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    joinDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof MlsResponse
     */
    registrationFee?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    status?: MlsResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof MlsResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MlsResponseStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    NotMembers = 'NOT_MEMBERS',
    Pending = 'PENDING',
    WillingToJoin = 'WILLING_TO_JOIN'
}

/**
 * 
 * @export
 * @interface Money
 */
export interface Money {
    /**
     * 
     * @type {any}
     * @memberof Money
     */
    currency?: any;
    /**
     * 
     * @type {number}
     * @memberof Money
     */
    number?: number;
}
/**
 * 
 * @export
 * @interface MoneyValue
 */
export interface MoneyValue {
    /**
     * 
     * @type {number}
     * @memberof MoneyValue
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyValue
     */
    currency?: MoneyValueCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MoneyValueCurrencyEnum {
    Cad = 'CAD',
    Usd = 'USD'
}

/**
 * 
 * @export
 * @interface NoteResponse
 */
export interface NoteResponse {
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    allowableRole?: NoteResponseAllowableRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    entityType?: NoteResponseEntityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    followupDate?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    noteStatus?: NoteResponseNoteStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {UserPreviewResponse}
     * @memberof NoteResponse
     */
    user?: UserPreviewResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum NoteResponseAllowableRoleEnum {
    Admin = 'ADMIN',
    Leader = 'LEADER',
    Member = 'MEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum NoteResponseEntityTypeEnum {
    Application = 'APPLICATION',
    Transaction = 'TRANSACTION'
}
/**
    * @export
    * @enum {string}
    */
export enum NoteResponseNoteStatusEnum {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED'
}

/**
 * 
 * @export
 * @interface NotesResponse
 */
export interface NotesResponse {
    /**
     * 
     * @type {Array<NoteResponse>}
     * @memberof NotesResponse
     */
    notes?: Array<NoteResponse>;
}
/**
 * 
 * @export
 * @interface OfficePreviewResponse
 */
export interface OfficePreviewResponse {
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    acceptsEscrow?: boolean;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficePreviewResponse
     */
    address?: AddressResponse;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof OfficePreviewResponse
     */
    administrativeArea?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    brokerageLicenseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    cdaDocumentTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficePreviewResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof OfficePreviewResponse
     */
    designatedBroker?: AgentPreviewResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    faxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    invoiceDocumentTitle?: string;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof OfficePreviewResponse
     */
    managingBroker?: AgentPreviewResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    mlsOfficeId?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    paysOtherAgent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    skySlopeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    transactionType?: OfficePreviewResponseTransactionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OfficePreviewResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    usesTipalti?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum OfficePreviewResponseTransactionTypeEnum {
    SingleCheck = 'SINGLE_CHECK',
    SplitCheck = 'SPLIT_CHECK'
}

/**
 * 
 * @export
 * @interface OfficeResponse
 */
export interface OfficeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    acceptsEscrow?: boolean;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficeResponse
     */
    address?: AddressResponse;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof OfficeResponse
     */
    administrativeArea?: AdministrativeAreaResponse;
    /**
     * 
     * @type {BankAccountResponse}
     * @memberof OfficeResponse
     */
    bankAccount?: BankAccountResponse;
    /**
     * 
     * @type {Array<BoardPreviewResponse>}
     * @memberof OfficeResponse
     */
    boards?: Array<BoardPreviewResponse>;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    brokerageLicenseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    cdaDocumentTitle?: string;
    /**
     * 
     * @type {CompanyResponse}
     * @memberof OfficeResponse
     */
    company?: CompanyResponse;
    /**
     * 
     * @type {number}
     * @memberof OfficeResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof OfficeResponse
     */
    designatedBroker?: AgentPreviewResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    faxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    invoiceDocumentTitle?: string;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof OfficeResponse
     */
    managingBroker?: AgentPreviewResponse;
    /**
     * 
     * @type {Array<MlsPreviewResponse>}
     * @memberof OfficeResponse
     */
    mls?: Array<MlsPreviewResponse>;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    mlsOfficeId?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    paysOtherAgent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    skySlopeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    transactionType?: OfficeResponseTransactionTypeEnum;
    /**
     * 
     * @type {BankAccountResponse}
     * @memberof OfficeResponse
     */
    trustAccount?: BankAccountResponse;
    /**
     * 
     * @type {number}
     * @memberof OfficeResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    usesTipalti?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum OfficeResponseTransactionTypeEnum {
    SingleCheck = 'SINGLE_CHECK',
    SplitCheck = 'SPLIT_CHECK'
}

/**
 * 
 * @export
 * @interface PayeeDetailsResponse
 */
export interface PayeeDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    paymentMethod?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayeeDetailsResponse
     */
    registered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    signUpLink?: string;
}
/**
 * 
 * @export
 * @interface PlanMembershipResponse
 */
export interface PlanMembershipResponse {
    /**
     * 
     * @type {CommissionPlanResponse}
     * @memberof PlanMembershipResponse
     */
    commissionPlan?: CommissionPlanResponse;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {EliteEquityAwardPlanResponse}
     * @memberof PlanMembershipResponse
     */
    eliteEquityAwardPlan?: EliteEquityAwardPlanResponse;
    /**
     * 
     * @type {EquityAwardPlanResponse}
     * @memberof PlanMembershipResponse
     */
    equityAwardPlan?: EquityAwardPlanResponse;
    /**
     * 
     * @type {EquityPurchasePlanResponse}
     * @memberof PlanMembershipResponse
     */
    equityPurchasePlan?: EquityPurchasePlanResponse;
    /**
     * 
     * @type {string}
     * @memberof PlanMembershipResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    optInToEquityPlan?: number;
    /**
     * 
     * @type {RevenueSharePlanResponse}
     * @memberof PlanMembershipResponse
     */
    revenueSharePlan?: RevenueSharePlanResponse;
    /**
     * 
     * @type {string}
     * @memberof PlanMembershipResponse
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface PushNotificationRegistrationResponse
 */
export interface PushNotificationRegistrationResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof PushNotificationRegistrationResponse
     */
    pushNotificationRegistrationIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface RealV1AgentWebsiteUpdateRequest
 */
export interface RealV1AgentWebsiteUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    bottom_sub_title?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    bottom_title?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    color_theme?: RealV1AgentWebsiteUpdateRequestColorThemeEnum;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    main_title?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    photo_sets?: RealV1AgentWebsiteUpdateRequestPhotoSetsEnum;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    search_criteria_label?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    show_agent_referral_page?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    site_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    site_title?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    sub_title?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    subtitle_feature_1?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    subtitle_feature_2?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    subtitle_feature_3?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    title_feature_1?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    title_feature_2?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    title_feature_3?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateRequest
     */
    username?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RealV1AgentWebsiteUpdateRequestColorThemeEnum {
    Black = 'black',
    Blue = 'blue',
    Green = 'green',
    Magenta = 'magenta',
    Red = 'red',
    Yellow = 'yellow'
}
/**
    * @export
    * @enum {string}
    */
export enum RealV1AgentWebsiteUpdateRequestPhotoSetsEnum {
    Cabin = 'cabin',
    Country = 'country',
    Luxury = 'luxury',
    Matsimmons = 'matsimmons',
    Photoset10 = 'photoset_10',
    Photoset5 = 'photoset_5',
    Photoset6 = 'photoset_6',
    Photoset7 = 'photoset_7',
    Photoset8 = 'photoset_8',
    Photoset9 = 'photoset_9',
    Urban = 'urban'
}

/**
 * 
 * @export
 * @interface RealV1AgentWebsiteUpdateSearchCriteriaRequest
 */
export interface RealV1AgentWebsiteUpdateSearchCriteriaRequest {
    /**
     * 
     * @type {number}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    baths_min?: number;
    /**
     * 
     * @type {number}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    beds_min?: number;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    google_place_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    has_photos?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    is_active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    is_condo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    is_house?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    is_land?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    is_multifamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    is_rental?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    is_townhouse?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    price_max?: number;
    /**
     * 
     * @type {number}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    price_min?: number;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    search_label?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateSearchCriteriaRequest
     */
    sort_by?: string;
}
/**
 * 
 * @export
 * @interface RealV1AgentWebsiteUpdateTestimonyRequest
 */
export interface RealV1AgentWebsiteUpdateTestimonyRequest {
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateTestimonyRequest
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof RealV1AgentWebsiteUpdateTestimonyRequest
     */
    customer_name?: string;
}
/**
 * 
 * @export
 * @interface RejectApplicationRequest
 */
export interface RejectApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof RejectApplicationRequest
     */
    agentId: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    usernameOrEmail: string;
}
/**
 * 
 * @export
 * @interface RevenueSharePlanResponse
 */
export interface RevenueSharePlanResponse {
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<RevenueShareTierResponse>}
     * @memberof RevenueSharePlanResponse
     */
    tiers?: Array<RevenueShareTierResponse>;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface RevenueShareTierResponse
 */
export interface RevenueShareTierResponse {
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    commissionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueShareTierResponse
     */
    id?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueShareTierResponse
     */
    maxAnnualAmountPerAgent?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    numberOfTierOneAgentsToUnlockThisTier?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    tier?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface SearchOrder
 */
export interface SearchOrder {
    /**
     * 
     * @type {boolean}
     * @memberof SearchOrder
     */
    asc?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchOrder
     */
    column?: string;
}
/**
 * 
 * @export
 * @interface SearchParam
 */
export interface SearchParam {
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    column?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    joinColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    operator?: SearchParamOperatorEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SearchParam
     */
    stringStrictCase?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    value?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchParamOperatorEnum {
    Eq = 'EQ',
    Gt = 'GT',
    Gte = 'GTE',
    In = 'IN',
    Like = 'LIKE',
    Lt = 'LT',
    Lte = 'LTE'
}

/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchRequest
     */
    filterAnd?: Array<SearchParam>;
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchRequest
     */
    filterOr?: Array<SearchParam>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    joinOn?: Array<string>;
    /**
     * 
     * @type {Array<SearchOrder>}
     * @memberof SearchRequest
     */
    order?: Array<SearchOrder>;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    responseColumns?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    targetObject?: SearchRequestTargetObjectEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchRequestTargetObjectEnum {
    Agent = 'AGENT',
    Application = 'APPLICATION',
    Board = 'BOARD',
    Mls = 'MLS',
    Note = 'NOTE',
    Office = 'OFFICE',
    Team = 'TEAM',
    Transaction = 'TRANSACTION',
    User = 'USER'
}

/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SearchResponse
     */
    hasNext?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchResponse
     */
    params?: Array<SearchParam>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchResponse
     */
    responseColumns?: Array<string>;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof SearchResponse
     */
    responseRows?: Array<Array<any>>;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    targetObject?: SearchResponseTargetObjectEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchResponseTargetObjectEnum {
    Agent = 'AGENT',
    Application = 'APPLICATION',
    Board = 'BOARD',
    Mls = 'MLS',
    Note = 'NOTE',
    Office = 'OFFICE',
    Team = 'TEAM',
    Transaction = 'TRANSACTION',
    User = 'USER'
}

/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    username: string;
}
/**
 * 
 * @export
 * @interface SponsorTreeResponse
 */
export interface SponsorTreeResponse {
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof SponsorTreeResponse
     */
    agent?: AgentPreviewResponse;
    /**
     * 
     * @type {Array<AgentPreviewResponse>}
     * @memberof SponsorTreeResponse
     */
    sponsorLine?: Array<AgentPreviewResponse>;
}
/**
 * 
 * @export
 * @interface StartJobExecutionRequest
 */
export interface StartJobExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof StartJobExecutionRequest
     */
    jobExecutionName: string;
    /**
     * 
     * @type {string}
     * @memberof StartJobExecutionRequest
     */
    notes?: string;
}
/**
 * 
 * @export
 * @interface TeamMemberResponse
 */
export interface TeamMemberResponse {
    /**
     * 
     * @type {UserPreviewResponse}
     * @memberof TeamMemberResponse
     */
    agent?: UserPreviewResponse;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberResponse
     */
    memberCommissionSplit?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamMemberResponse
     */
    roles?: Array<TeamMemberResponseRolesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum TeamMemberResponseRolesEnum {
    Admin = 'ADMIN',
    Leader = 'LEADER',
    Member = 'MEMBER'
}

/**
 * 
 * @export
 * @interface TeamPreviewResponse
 */
export interface TeamPreviewResponse {
    /**
     * 
     * @type {number}
     * @memberof TeamPreviewResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamPreviewResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface TeamResponse
 */
export interface TeamResponse {
    /**
     * 
     * @type {Array<TeamMemberResponse>}
     * @memberof TeamResponse
     */
    agents?: Array<TeamMemberResponse>;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    type?: TeamResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TeamResponseTypeEnum {
    Domestic = 'DOMESTIC',
    Group = 'GROUP',
    Normal = 'NORMAL',
    Platinum = 'PLATINUM'
}

/**
 * 
 * @export
 * @interface UpdateAgentRequestAdmin
 */
export interface UpdateAgentRequestAdmin {
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequestAdmin
     */
    skySlopeId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequestAdmin
     */
    skySlopePublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequestAdmin
     */
    tipaltiId?: string;
}
/**
 * 
 * @export
 * @interface UpdateBoardRequest
 */
export interface UpdateBoardRequest {
    /**
     * 
     * @type {AdministrativeAreaRequest}
     * @memberof UpdateBoardRequest
     */
    administrativeArea?: AdministrativeAreaRequest;
    /**
     * 
     * @type {string}
     * @memberof UpdateBoardRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBoardRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBoardRequest
     */
    joinDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBoardRequest
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBoardRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBoardRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Money}
     * @memberof UpdateBoardRequest
     */
    registrationFee?: Money;
    /**
     * 
     * @type {string}
     * @memberof UpdateBoardRequest
     */
    status?: UpdateBoardRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateBoardRequestStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    NotMembers = 'NOT_MEMBERS',
    Pending = 'PENDING',
    WillingToJoin = 'WILLING_TO_JOIN'
}

/**
 * 
 * @export
 * @interface UpdateExternalUserRequest
 */
export interface UpdateExternalUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateExternalUserRequest
     */
    emailAddress?: string;
}
/**
 * 
 * @export
 * @interface UpdateJobExecutionRequest
 */
export interface UpdateJobExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateJobExecutionRequest
     */
    errorLog?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateJobExecutionRequest
     */
    notes?: string;
}
/**
 * 
 * @export
 * @interface UpdateMlsRequest
 */
export interface UpdateMlsRequest {
    /**
     * 
     * @type {Array<AdministrativeAreaRequest>}
     * @memberof UpdateMlsRequest
     */
    administrativeAreas?: Array<AdministrativeAreaRequest>;
    /**
     * 
     * @type {string}
     * @memberof UpdateMlsRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMlsRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMlsRequest
     */
    joinDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMlsRequest
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMlsRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMlsRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Money}
     * @memberof UpdateMlsRequest
     */
    registrationFee?: Money;
    /**
     * 
     * @type {string}
     * @memberof UpdateMlsRequest
     */
    status?: UpdateMlsRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateMlsRequestStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    NotMembers = 'NOT_MEMBERS',
    Pending = 'PENDING',
    WillingToJoin = 'WILLING_TO_JOIN'
}

/**
 * 
 * @export
 * @interface UpdateNoteRequest
 */
export interface UpdateNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteRequest
     */
    allowableRole: UpdateNoteRequestAllowableRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteRequest
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNoteRequest
     */
    followupDate?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateNoteRequestAllowableRoleEnum {
    Admin = 'ADMIN',
    Leader = 'LEADER',
    Member = 'MEMBER'
}

/**
 * 
 * @export
 * @interface UpdateTeamMemberCommissionSplitRequest
 */
export interface UpdateTeamMemberCommissionSplitRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateTeamMemberCommissionSplitRequest
     */
    commissionSplit?: number;
}
/**
 * 
 * @export
 * @interface UpdateTeamRequest
 */
export interface UpdateTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface UserPreviewResponse
 */
export interface UserPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    about?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    agentStatus?: UserPreviewResponseAgentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    anniversaryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    clubhouseURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreviewResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    facebookURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    googleBusinessProfileURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    instagramURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    joinDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    keyMakerId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    lastAnniversaryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    linkedInURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    nextAnniversaryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    personalWebsiteURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    skySlopeId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    skySlopePublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    tipaltiId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    twitterURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    type?: UserPreviewResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UserPreviewResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    yelpURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    youtubeURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    zillowURL?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserPreviewResponseAgentStatusEnum {
    Active = 'ACTIVE',
    Candidate = 'CANDIDATE',
    Inactive = 'INACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum UserPreviewResponseTypeEnum {
    Agent = 'AGENT',
    Broker = 'BROKER',
    External = 'EXTERNAL'
}

/**
 * 
 * @export
 * @interface UserPrincipal
 */
export interface UserPrincipal {
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPrincipal
     */
    roles?: Array<UserPrincipalRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    yentaId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserPrincipalRolesEnum {
    Admin = 'ROLE_ADMIN',
    User = 'ROLE_USER'
}

/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    about?: string;
    /**
     * 
     * @type {Array<AddressResponse>}
     * @memberof UserResponse
     */
    addresses?: Array<AddressResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    agentStatus?: UserResponseAgentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    anniversaryDate?: string;
    /**
     * 
     * @type {Array<ApplicationResponse>}
     * @memberof UserResponse
     */
    applications?: Array<ApplicationResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    birthDate?: string;
    /**
     * 
     * @type {Array<BoardPreviewResponse>}
     * @memberof UserResponse
     */
    boards?: Array<BoardPreviewResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    clubhouseURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    company?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponse
     */
    competencies?: Array<UserResponseCompetenciesEnum>;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    facebookURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    googleBusinessProfileURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    instagramURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    joinDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    keyMakerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponse
     */
    languages?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastAnniversaryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof UserResponse
     */
    licenses?: Array<LicenseResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    linkedInURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    middleName?: string;
    /**
     * 
     * @type {Array<MlsPreviewResponse>}
     * @memberof UserResponse
     */
    mls?: Array<MlsPreviewResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    nextAnniversaryDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    notifyLeadsInSMS?: boolean;
    /**
     * 
     * @type {Array<OfficePreviewResponse>}
     * @memberof UserResponse
     */
    offices?: Array<OfficePreviewResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    personalWebsiteURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {PlanMembershipResponse}
     * @memberof UserResponse
     */
    planMembership?: PlanMembershipResponse;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    receivesLeadsOnlyFromZipcodes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    receivesPaymentEmailNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    receivesPaymentPushNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    receivesSupportNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    receivesTransactionEmailNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    receivesTransactionPushNotifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    skySlopeId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    skySlopePublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    sponsorId?: string;
    /**
     * 
     * @type {Array<AgentTeamMembershipResponse>}
     * @memberof UserResponse
     */
    teamMemberships?: Array<AgentTeamMembershipResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    tipaltiId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    twitterURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    type?: UserResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    yelpURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    youtubeURL?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    zillowURL?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserResponseAgentStatusEnum {
    Active = 'ACTIVE',
    Candidate = 'CANDIDATE',
    Inactive = 'INACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum UserResponseCompetenciesEnum {
    Buyers = 'BUYERS',
    Commercial = 'COMMERCIAL',
    FarmAndRanch = 'FARM_AND_RANCH',
    Investors = 'INVESTORS',
    ProvidingReferrals = 'PROVIDING_REFERRALS',
    ReceivingReferrals = 'RECEIVING_REFERRALS',
    Residential = 'RESIDENTIAL',
    Sellers = 'SELLERS'
}
/**
    * @export
    * @enum {string}
    */
export enum UserResponseTypeEnum {
    Agent = 'AGENT',
    Broker = 'BROKER',
    External = 'EXTERNAL'
}


/**
 * AgentControllerApi - axios parameter creator
 * @export
 */
export const AgentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get agent by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentByIdUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Opcity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpcityUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/opcity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User\'s Profile Score
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileScoreUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProfileScoreUsingGET', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/profile-score`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s sponsor tree
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSponsorTreeForAgentUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSponsorTreeForAgentUsingGET', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/sponsor-tree`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipaltiSignUpUrlUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/tipaltiUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id id
         * @param {UpdateAgentRequestAdmin} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentByIdAdminUsingPUT: async (id: string, req: UpdateAgentRequestAdmin, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentByIdAdminUsingPUT', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateAgentByIdAdminUsingPUT', 'req', req)
            const localVarPath = `/api/v1/agents/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id id
         * @param {AgentUpdateRequest} agentUpdateRequest agentUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentByIdUsingPUT: async (id: string, agentUpdateRequest: AgentUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentByIdUsingPUT', 'id', id)
            // verify required parameter 'agentUpdateRequest' is not null or undefined
            assertParamExists('updateAgentByIdUsingPUT', 'agentUpdateRequest', agentUpdateRequest)
            const localVarPath = `/api/v1/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Licenses/Board/MLS by id
         * @param {string} id id
         * @param {AgentUpdateLicenseBoardMlsRequest} agentUpdateLicenseBoardMlsRequest agentUpdateLicenseBoardMlsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseBoardMlsByIdUsingPUT: async (id: string, agentUpdateLicenseBoardMlsRequest: AgentUpdateLicenseBoardMlsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentLicenseBoardMlsByIdUsingPUT', 'id', id)
            // verify required parameter 'agentUpdateLicenseBoardMlsRequest' is not null or undefined
            assertParamExists('updateAgentLicenseBoardMlsByIdUsingPUT', 'agentUpdateLicenseBoardMlsRequest', agentUpdateLicenseBoardMlsRequest)
            const localVarPath = `/api/v1/agents/licensesBoardMls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateLicenseBoardMlsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id id
         * @param {AgentUpdateLicenseVerifiedRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicenseVerifiedUsingPUT: async (id: string, req: AgentUpdateLicenseVerifiedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLicenseVerifiedUsingPUT', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateLicenseVerifiedUsingPUT', 'req', req)
            const localVarPath = `/api/v1/agents/licenseVerified/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id id
         * @param {AgentUpdateNeedOnBoardingRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedOnBoardingUsingPUT: async (id: string, req: AgentUpdateNeedOnBoardingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNeedOnBoardingUsingPUT', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateNeedOnBoardingUsingPUT', 'req', req)
            const localVarPath = `/api/v1/agents/needOnBoarding/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentControllerApi - functional programming interface
 * @export
 */
export const AgentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get agent by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentByIdUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Opcity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpcityUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpcityUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User\'s Profile Score
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileScoreUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentProfileScoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileScoreUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s sponsor tree
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSponsorTreeForAgentUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SponsorTreeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSponsorTreeForAgentUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTipaltiSignUpUrlUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayeeDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTipaltiSignUpUrlUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id id
         * @param {UpdateAgentRequestAdmin} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentByIdAdminUsingPUT(id: string, req: UpdateAgentRequestAdmin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentByIdAdminUsingPUT(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id id
         * @param {AgentUpdateRequest} agentUpdateRequest agentUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentByIdUsingPUT(id: string, agentUpdateRequest: AgentUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentByIdUsingPUT(id, agentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Licenses/Board/MLS by id
         * @param {string} id id
         * @param {AgentUpdateLicenseBoardMlsRequest} agentUpdateLicenseBoardMlsRequest agentUpdateLicenseBoardMlsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentLicenseBoardMlsByIdUsingPUT(id: string, agentUpdateLicenseBoardMlsRequest: AgentUpdateLicenseBoardMlsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentLicenseBoardMlsByIdUsingPUT(id, agentUpdateLicenseBoardMlsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id id
         * @param {AgentUpdateLicenseVerifiedRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicenseVerifiedUsingPUT(id: string, req: AgentUpdateLicenseVerifiedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicenseVerifiedUsingPUT(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id id
         * @param {AgentUpdateNeedOnBoardingRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNeedOnBoardingUsingPUT(id: string, req: AgentUpdateNeedOnBoardingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNeedOnBoardingUsingPUT(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentControllerApi - factory interface
 * @export
 */
export const AgentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get agent by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentByIdUsingGET(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getAgentByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeUsingGET(options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getMeUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Opcity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpcityUsingGET(options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getOpcityUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User\'s Profile Score
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileScoreUsingGET(id: string, options?: any): AxiosPromise<AgentProfileScoreResponse> {
            return localVarFp.getProfileScoreUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s sponsor tree
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSponsorTreeForAgentUsingGET(id: string, options?: any): AxiosPromise<SponsorTreeResponse> {
            return localVarFp.getSponsorTreeForAgentUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipaltiSignUpUrlUsingGET(options?: any): AxiosPromise<PayeeDetailsResponse> {
            return localVarFp.getTipaltiSignUpUrlUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id id
         * @param {UpdateAgentRequestAdmin} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentByIdAdminUsingPUT(id: string, req: UpdateAgentRequestAdmin, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentByIdAdminUsingPUT(id, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id id
         * @param {AgentUpdateRequest} agentUpdateRequest agentUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentByIdUsingPUT(id: string, agentUpdateRequest: AgentUpdateRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentByIdUsingPUT(id, agentUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Licenses/Board/MLS by id
         * @param {string} id id
         * @param {AgentUpdateLicenseBoardMlsRequest} agentUpdateLicenseBoardMlsRequest agentUpdateLicenseBoardMlsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseBoardMlsByIdUsingPUT(id: string, agentUpdateLicenseBoardMlsRequest: AgentUpdateLicenseBoardMlsRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentLicenseBoardMlsByIdUsingPUT(id, agentUpdateLicenseBoardMlsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id id
         * @param {AgentUpdateLicenseVerifiedRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicenseVerifiedUsingPUT(id: string, req: AgentUpdateLicenseVerifiedRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateLicenseVerifiedUsingPUT(id, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id id
         * @param {AgentUpdateNeedOnBoardingRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedOnBoardingUsingPUT(id: string, req: AgentUpdateNeedOnBoardingRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateNeedOnBoardingUsingPUT(id, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentControllerApi - object-oriented interface
 * @export
 * @class AgentControllerApi
 * @extends {BaseAPI}
 */
export class AgentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get agent by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentByIdUsingGET(id: string, options?: any) {
        return AgentControllerApiFp(this.configuration).getAgentByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current agent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getMeUsingGET(options?: any) {
        return AgentControllerApiFp(this.configuration).getMeUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Opcity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getOpcityUsingGET(options?: any) {
        return AgentControllerApiFp(this.configuration).getOpcityUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User\'s Profile Score
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getProfileScoreUsingGET(id: string, options?: any) {
        return AgentControllerApiFp(this.configuration).getProfileScoreUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s sponsor tree
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getSponsorTreeForAgentUsingGET(id: string, options?: any) {
        return AgentControllerApiFp(this.configuration).getSponsorTreeForAgentUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getTipaltiSignUpUrlUsingGET(options?: any) {
        return AgentControllerApiFp(this.configuration).getTipaltiSignUpUrlUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent by id
     * @param {string} id id
     * @param {UpdateAgentRequestAdmin} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentByIdAdminUsingPUT(id: string, req: UpdateAgentRequestAdmin, options?: any) {
        return AgentControllerApiFp(this.configuration).updateAgentByIdAdminUsingPUT(id, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent by id
     * @param {string} id id
     * @param {AgentUpdateRequest} agentUpdateRequest agentUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentByIdUsingPUT(id: string, agentUpdateRequest: AgentUpdateRequest, options?: any) {
        return AgentControllerApiFp(this.configuration).updateAgentByIdUsingPUT(id, agentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Licenses/Board/MLS by id
     * @param {string} id id
     * @param {AgentUpdateLicenseBoardMlsRequest} agentUpdateLicenseBoardMlsRequest agentUpdateLicenseBoardMlsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentLicenseBoardMlsByIdUsingPUT(id: string, agentUpdateLicenseBoardMlsRequest: AgentUpdateLicenseBoardMlsRequest, options?: any) {
        return AgentControllerApiFp(this.configuration).updateAgentLicenseBoardMlsByIdUsingPUT(id, agentUpdateLicenseBoardMlsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent licenseVerified by id
     * @param {string} id id
     * @param {AgentUpdateLicenseVerifiedRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateLicenseVerifiedUsingPUT(id: string, req: AgentUpdateLicenseVerifiedRequest, options?: any) {
        return AgentControllerApiFp(this.configuration).updateLicenseVerifiedUsingPUT(id, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent licenseVerified by id
     * @param {string} id id
     * @param {AgentUpdateNeedOnBoardingRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateNeedOnBoardingUsingPUT(id: string, req: AgentUpdateNeedOnBoardingRequest, options?: any) {
        return AgentControllerApiFp(this.configuration).updateNeedOnBoardingUsingPUT(id, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentPlanControllerApi - axios parameter creator
 * @export
 */
export const AgentPlanControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a commission plan
         * @param {CreateCommissionPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommissionPlanUsingPOST: async (req: CreateCommissionPlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createCommissionPlanUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agents/plans/commission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an elite equity award plan
         * @param {CreateEliteEquityAwardPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEliteEquityAwardPlanUsingPOST: async (req: CreateEliteEquityAwardPlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createEliteEquityAwardPlanUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agents/plans/elite_equity_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an equity award plan
         * @param {CreateEquityAwardPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquityAwardPlanUsingPOST: async (req: CreateEquityAwardPlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createEquityAwardPlanUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agents/plans/equity_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an equity purchase plan
         * @param {CreateEquityPurchasePlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquityPurchasePlanUsingPOST: async (req: CreateEquityPurchasePlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createEquityPurchasePlanUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agents/plans/equity_purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a revenue sharing plan
         * @param {CreateRevenueSharePlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevenueSharePlanUsingPOST: async (req: CreateRevenueSharePlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createRevenueSharePlanUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agents/plans/revenue_share`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get commission plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionPlanUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCommissionPlanUsingGET', 'id', id)
            const localVarPath = `/api/v1/agents/plans/commission/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get elite equity award plan id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEliteEquityAwardPlanUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEliteEquityAwardPlanUsingGET', 'id', id)
            const localVarPath = `/api/v1/agents/plans/elite_equity_award/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equity award plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquityAwardPlanUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEquityAwardPlanUsingGET', 'id', id)
            const localVarPath = `/api/v1/agents/plans/equity_award/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equity purchase plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquityPurchasePlanUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEquityPurchasePlanUsingGET', 'id', id)
            const localVarPath = `/api/v1/agents/plans/equity_purchase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get revenue sharing plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenueSharePlanUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRevenueSharePlanUsingGET', 'id', id)
            const localVarPath = `/api/v1/agents/plans/revenue_share/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentPlanControllerApi - functional programming interface
 * @export
 */
export const AgentPlanControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentPlanControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a commission plan
         * @param {CreateCommissionPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommissionPlanUsingPOST(req: CreateCommissionPlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommissionPlanUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an elite equity award plan
         * @param {CreateEliteEquityAwardPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEliteEquityAwardPlanUsingPOST(req: CreateEliteEquityAwardPlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EliteEquityAwardPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEliteEquityAwardPlanUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an equity award plan
         * @param {CreateEquityAwardPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEquityAwardPlanUsingPOST(req: CreateEquityAwardPlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityAwardPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEquityAwardPlanUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an equity purchase plan
         * @param {CreateEquityPurchasePlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEquityPurchasePlanUsingPOST(req: CreateEquityPurchasePlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityPurchasePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEquityPurchasePlanUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a revenue sharing plan
         * @param {CreateRevenueSharePlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRevenueSharePlanUsingPOST(req: CreateRevenueSharePlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueSharePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRevenueSharePlanUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get commission plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommissionPlanUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommissionPlanUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get elite equity award plan id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEliteEquityAwardPlanUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EliteEquityAwardPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEliteEquityAwardPlanUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equity award plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquityAwardPlanUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityAwardPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquityAwardPlanUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equity purchase plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquityPurchasePlanUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityPurchasePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquityPurchasePlanUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get revenue sharing plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevenueSharePlanUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueSharePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevenueSharePlanUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentPlanControllerApi - factory interface
 * @export
 */
export const AgentPlanControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentPlanControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a commission plan
         * @param {CreateCommissionPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommissionPlanUsingPOST(req: CreateCommissionPlanRequest, options?: any): AxiosPromise<CommissionPlanResponse> {
            return localVarFp.createCommissionPlanUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an elite equity award plan
         * @param {CreateEliteEquityAwardPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEliteEquityAwardPlanUsingPOST(req: CreateEliteEquityAwardPlanRequest, options?: any): AxiosPromise<EliteEquityAwardPlanResponse> {
            return localVarFp.createEliteEquityAwardPlanUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an equity award plan
         * @param {CreateEquityAwardPlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquityAwardPlanUsingPOST(req: CreateEquityAwardPlanRequest, options?: any): AxiosPromise<EquityAwardPlanResponse> {
            return localVarFp.createEquityAwardPlanUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an equity purchase plan
         * @param {CreateEquityPurchasePlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquityPurchasePlanUsingPOST(req: CreateEquityPurchasePlanRequest, options?: any): AxiosPromise<EquityPurchasePlanResponse> {
            return localVarFp.createEquityPurchasePlanUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a revenue sharing plan
         * @param {CreateRevenueSharePlanRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevenueSharePlanUsingPOST(req: CreateRevenueSharePlanRequest, options?: any): AxiosPromise<RevenueSharePlanResponse> {
            return localVarFp.createRevenueSharePlanUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get commission plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionPlanUsingGET(id: string, options?: any): AxiosPromise<CommissionPlanResponse> {
            return localVarFp.getCommissionPlanUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get elite equity award plan id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEliteEquityAwardPlanUsingGET(id: string, options?: any): AxiosPromise<EliteEquityAwardPlanResponse> {
            return localVarFp.getEliteEquityAwardPlanUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equity award plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquityAwardPlanUsingGET(id: string, options?: any): AxiosPromise<EquityAwardPlanResponse> {
            return localVarFp.getEquityAwardPlanUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equity purchase plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquityPurchasePlanUsingGET(id: string, options?: any): AxiosPromise<EquityPurchasePlanResponse> {
            return localVarFp.getEquityPurchasePlanUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get revenue sharing plan by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenueSharePlanUsingGET(id: string, options?: any): AxiosPromise<RevenueSharePlanResponse> {
            return localVarFp.getRevenueSharePlanUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentPlanControllerApi - object-oriented interface
 * @export
 * @class AgentPlanControllerApi
 * @extends {BaseAPI}
 */
export class AgentPlanControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a commission plan
     * @param {CreateCommissionPlanRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createCommissionPlanUsingPOST(req: CreateCommissionPlanRequest, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).createCommissionPlanUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an elite equity award plan
     * @param {CreateEliteEquityAwardPlanRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createEliteEquityAwardPlanUsingPOST(req: CreateEliteEquityAwardPlanRequest, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).createEliteEquityAwardPlanUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an equity award plan
     * @param {CreateEquityAwardPlanRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createEquityAwardPlanUsingPOST(req: CreateEquityAwardPlanRequest, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).createEquityAwardPlanUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an equity purchase plan
     * @param {CreateEquityPurchasePlanRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createEquityPurchasePlanUsingPOST(req: CreateEquityPurchasePlanRequest, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).createEquityPurchasePlanUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a revenue sharing plan
     * @param {CreateRevenueSharePlanRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createRevenueSharePlanUsingPOST(req: CreateRevenueSharePlanRequest, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).createRevenueSharePlanUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get commission plan by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getCommissionPlanUsingGET(id: string, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).getCommissionPlanUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get elite equity award plan id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getEliteEquityAwardPlanUsingGET(id: string, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).getEliteEquityAwardPlanUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equity award plan by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getEquityAwardPlanUsingGET(id: string, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).getEquityAwardPlanUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equity purchase plan by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getEquityPurchasePlanUsingGET(id: string, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).getEquityPurchasePlanUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get revenue sharing plan by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getRevenueSharePlanUsingGET(id: string, options?: any) {
        return AgentPlanControllerApiFp(this.configuration).getRevenueSharePlanUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationControllerApi - axios parameter creator
 * @export
 */
export const ApplicationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Apply to be Real Agent
         * @param {ApplicationRequest} applicationRequest applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyToRealUsingPOST: async (applicationRequest: ApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationRequest' is not null or undefined
            assertParamExists('applyToRealUsingPOST', 'applicationRequest', applicationRequest)
            const localVarPath = `/api/v1/applications/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve a pending application
         * @param {string} id id
         * @param {ApproveApplicationRequest} approveApplicationRequest approveApplicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveApplicationUsingPOST: async (id: string, approveApplicationRequest: ApproveApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveApplicationUsingPOST', 'id', id)
            // verify required parameter 'approveApplicationRequest' is not null or undefined
            assertParamExists('approveApplicationUsingPOST', 'approveApplicationRequest', approveApplicationRequest)
            const localVarPath = `/api/v1/applications/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Agent joined the board
         * @param {BoardJoinedRequest} boardJoinedRequest boardJoinedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardJoinedUsingPOST: async (boardJoinedRequest: BoardJoinedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardJoinedRequest' is not null or undefined
            assertParamExists('boardJoinedUsingPOST', 'boardJoinedRequest', boardJoinedRequest)
            const localVarPath = `/api/v1/applications/board_joined`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardJoinedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch an application by a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicationUsingGET', 'id', id)
            const localVarPath = `/api/v1/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Agent license is transferred
         * @param {LicenseTransferRequest} licenseTransferRequest licenseTransferRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseTransferredUsingPOST: async (licenseTransferRequest: LicenseTransferRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseTransferRequest' is not null or undefined
            assertParamExists('licenseTransferredUsingPOST', 'licenseTransferRequest', licenseTransferRequest)
            const localVarPath = `/api/v1/applications/license_transferred`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pay fees for application
         * @param {FeePaymentRequest} paymentRequest paymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payApplicationFeesUsingPOST: async (paymentRequest: FeePaymentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentRequest' is not null or undefined
            assertParamExists('payApplicationFeesUsingPOST', 'paymentRequest', paymentRequest)
            const localVarPath = `/api/v1/applications/pay_fees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject a pending application
         * @param {string} id id
         * @param {RejectApplicationRequest} rejectApplicationRequest rejectApplicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectApplicationUsingPOST: async (id: string, rejectApplicationRequest: RejectApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rejectApplicationUsingPOST', 'id', id)
            // verify required parameter 'rejectApplicationRequest' is not null or undefined
            assertParamExists('rejectApplicationUsingPOST', 'rejectApplicationRequest', rejectApplicationRequest)
            const localVarPath = `/api/v1/applications/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign ICA
         * @param {ICASignRequest} icaSignRequest icaSignRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signICAUsingPOST: async (icaSignRequest: ICASignRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'icaSignRequest' is not null or undefined
            assertParamExists('signICAUsingPOST', 'icaSignRequest', icaSignRequest)
            const localVarPath = `/api/v1/applications/sign_ica`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(icaSignRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign Letter of Intent
         * @param {LetterOfIntentSignRequest} letterOfIntentRequest letterOfIntentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signLetterOfIntentUsingPOST: async (letterOfIntentRequest: LetterOfIntentSignRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'letterOfIntentRequest' is not null or undefined
            assertParamExists('signLetterOfIntentUsingPOST', 'letterOfIntentRequest', letterOfIntentRequest)
            const localVarPath = `/api/v1/applications/sign-loi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(letterOfIntentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing application
         * @param {string} id id
         * @param {ApplicationUpdateRequest} applicationUpdateRequest applicationUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationUsingPUT: async (id: string, applicationUpdateRequest: ApplicationUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationUsingPUT', 'id', id)
            // verify required parameter 'applicationUpdateRequest' is not null or undefined
            assertParamExists('updateApplicationUsingPUT', 'applicationUpdateRequest', applicationUpdateRequest)
            const localVarPath = `/api/v1/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationControllerApi - functional programming interface
 * @export
 */
export const ApplicationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Apply to be Real Agent
         * @param {ApplicationRequest} applicationRequest applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyToRealUsingPOST(applicationRequest: ApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyToRealUsingPOST(applicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approve a pending application
         * @param {string} id id
         * @param {ApproveApplicationRequest} approveApplicationRequest approveApplicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveApplicationUsingPOST(id: string, approveApplicationRequest: ApproveApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveApplicationUsingPOST(id, approveApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Agent joined the board
         * @param {BoardJoinedRequest} boardJoinedRequest boardJoinedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardJoinedUsingPOST(boardJoinedRequest: BoardJoinedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardJoinedUsingPOST(boardJoinedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch an application by a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Agent license is transferred
         * @param {LicenseTransferRequest} licenseTransferRequest licenseTransferRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licenseTransferredUsingPOST(licenseTransferRequest: LicenseTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.licenseTransferredUsingPOST(licenseTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pay fees for application
         * @param {FeePaymentRequest} paymentRequest paymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payApplicationFeesUsingPOST(paymentRequest: FeePaymentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payApplicationFeesUsingPOST(paymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject a pending application
         * @param {string} id id
         * @param {RejectApplicationRequest} rejectApplicationRequest rejectApplicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectApplicationUsingPOST(id: string, rejectApplicationRequest: RejectApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectApplicationUsingPOST(id, rejectApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign ICA
         * @param {ICASignRequest} icaSignRequest icaSignRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signICAUsingPOST(icaSignRequest: ICASignRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signICAUsingPOST(icaSignRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign Letter of Intent
         * @param {LetterOfIntentSignRequest} letterOfIntentRequest letterOfIntentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signLetterOfIntentUsingPOST(letterOfIntentRequest: LetterOfIntentSignRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signLetterOfIntentUsingPOST(letterOfIntentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing application
         * @param {string} id id
         * @param {ApplicationUpdateRequest} applicationUpdateRequest applicationUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationUsingPUT(id: string, applicationUpdateRequest: ApplicationUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationUsingPUT(id, applicationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationControllerApi - factory interface
 * @export
 */
export const ApplicationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Apply to be Real Agent
         * @param {ApplicationRequest} applicationRequest applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyToRealUsingPOST(applicationRequest: ApplicationRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.applyToRealUsingPOST(applicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve a pending application
         * @param {string} id id
         * @param {ApproveApplicationRequest} approveApplicationRequest approveApplicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveApplicationUsingPOST(id: string, approveApplicationRequest: ApproveApplicationRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.approveApplicationUsingPOST(id, approveApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Agent joined the board
         * @param {BoardJoinedRequest} boardJoinedRequest boardJoinedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardJoinedUsingPOST(boardJoinedRequest: BoardJoinedRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.boardJoinedUsingPOST(boardJoinedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch an application by a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationUsingGET(id: string, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.getApplicationUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Agent license is transferred
         * @param {LicenseTransferRequest} licenseTransferRequest licenseTransferRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseTransferredUsingPOST(licenseTransferRequest: LicenseTransferRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.licenseTransferredUsingPOST(licenseTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pay fees for application
         * @param {FeePaymentRequest} paymentRequest paymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payApplicationFeesUsingPOST(paymentRequest: FeePaymentRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.payApplicationFeesUsingPOST(paymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject a pending application
         * @param {string} id id
         * @param {RejectApplicationRequest} rejectApplicationRequest rejectApplicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectApplicationUsingPOST(id: string, rejectApplicationRequest: RejectApplicationRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.rejectApplicationUsingPOST(id, rejectApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign ICA
         * @param {ICASignRequest} icaSignRequest icaSignRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signICAUsingPOST(icaSignRequest: ICASignRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.signICAUsingPOST(icaSignRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign Letter of Intent
         * @param {LetterOfIntentSignRequest} letterOfIntentRequest letterOfIntentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signLetterOfIntentUsingPOST(letterOfIntentRequest: LetterOfIntentSignRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.signLetterOfIntentUsingPOST(letterOfIntentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing application
         * @param {string} id id
         * @param {ApplicationUpdateRequest} applicationUpdateRequest applicationUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationUsingPUT(id: string, applicationUpdateRequest: ApplicationUpdateRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.updateApplicationUsingPUT(id, applicationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationControllerApi - object-oriented interface
 * @export
 * @class ApplicationControllerApi
 * @extends {BaseAPI}
 */
export class ApplicationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Apply to be Real Agent
     * @param {ApplicationRequest} applicationRequest applicationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public applyToRealUsingPOST(applicationRequest: ApplicationRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).applyToRealUsingPOST(applicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve a pending application
     * @param {string} id id
     * @param {ApproveApplicationRequest} approveApplicationRequest approveApplicationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public approveApplicationUsingPOST(id: string, approveApplicationRequest: ApproveApplicationRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).approveApplicationUsingPOST(id, approveApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Agent joined the board
     * @param {BoardJoinedRequest} boardJoinedRequest boardJoinedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public boardJoinedUsingPOST(boardJoinedRequest: BoardJoinedRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).boardJoinedUsingPOST(boardJoinedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch an application by a given id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getApplicationUsingGET(id: string, options?: any) {
        return ApplicationControllerApiFp(this.configuration).getApplicationUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Agent license is transferred
     * @param {LicenseTransferRequest} licenseTransferRequest licenseTransferRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public licenseTransferredUsingPOST(licenseTransferRequest: LicenseTransferRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).licenseTransferredUsingPOST(licenseTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pay fees for application
     * @param {FeePaymentRequest} paymentRequest paymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public payApplicationFeesUsingPOST(paymentRequest: FeePaymentRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).payApplicationFeesUsingPOST(paymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject a pending application
     * @param {string} id id
     * @param {RejectApplicationRequest} rejectApplicationRequest rejectApplicationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public rejectApplicationUsingPOST(id: string, rejectApplicationRequest: RejectApplicationRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).rejectApplicationUsingPOST(id, rejectApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign ICA
     * @param {ICASignRequest} icaSignRequest icaSignRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public signICAUsingPOST(icaSignRequest: ICASignRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).signICAUsingPOST(icaSignRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign Letter of Intent
     * @param {LetterOfIntentSignRequest} letterOfIntentRequest letterOfIntentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public signLetterOfIntentUsingPOST(letterOfIntentRequest: LetterOfIntentSignRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).signLetterOfIntentUsingPOST(letterOfIntentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing application
     * @param {string} id id
     * @param {ApplicationUpdateRequest} applicationUpdateRequest applicationUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public updateApplicationUsingPUT(id: string, applicationUpdateRequest: ApplicationUpdateRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).updateApplicationUsingPUT(id, applicationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BoardControllerApi - axios parameter creator
 * @export
 */
export const BoardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get board by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardByIdUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBoardByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id id
         * @param {UpdateBoardRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoardByIdUsingPUT: async (id: string, req: UpdateBoardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBoardByIdUsingPUT', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateBoardByIdUsingPUT', 'req', req)
            const localVarPath = `/api/v1/boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoardControllerApi - functional programming interface
 * @export
 */
export const BoardControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoardControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get board by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardByIdUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id id
         * @param {UpdateBoardRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBoardByIdUsingPUT(id: string, req: UpdateBoardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBoardByIdUsingPUT(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoardControllerApi - factory interface
 * @export
 */
export const BoardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoardControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get board by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardByIdUsingGET(id: string, options?: any): AxiosPromise<BoardResponse> {
            return localVarFp.getBoardByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id id
         * @param {UpdateBoardRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoardByIdUsingPUT(id: string, req: UpdateBoardRequest, options?: any): AxiosPromise<BoardResponse> {
            return localVarFp.updateBoardByIdUsingPUT(id, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoardControllerApi - object-oriented interface
 * @export
 * @class BoardControllerApi
 * @extends {BaseAPI}
 */
export class BoardControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get board by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public getBoardByIdUsingGET(id: string, options?: any) {
        return BoardControllerApiFp(this.configuration).getBoardByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update board by id
     * @param {string} id id
     * @param {UpdateBoardRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public updateBoardByIdUsingPUT(id: string, req: UpdateBoardRequest, options?: any) {
        return BoardControllerApiFp(this.configuration).updateBoardByIdUsingPUT(id, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobExecutionControllerApi - axios parameter creator
 * @export
 */
export const JobExecutionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mark a job failed.
         * @param {string} jobExecutionId jobExecutionId
         * @param {UpdateJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failJobExecutionUsingPUT: async (jobExecutionId: string, req: UpdateJobExecutionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobExecutionId' is not null or undefined
            assertParamExists('failJobExecutionUsingPUT', 'jobExecutionId', jobExecutionId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('failJobExecutionUsingPUT', 'req', req)
            const localVarPath = `/api/v1/jobExecutions/failJobExecution/{jobExecutionId}`
                .replace(`{${"jobExecutionId"}}`, encodeURIComponent(String(jobExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get JobExecution by id
         * @param {string} jobExecutionId jobExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobExecutionByIdUsingGET: async (jobExecutionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobExecutionId' is not null or undefined
            assertParamExists('getJobExecutionByIdUsingGET', 'jobExecutionId', jobExecutionId)
            const localVarPath = `/api/v1/jobExecutions/{jobExecutionId}`
                .replace(`{${"jobExecutionId"}}`, encodeURIComponent(String(jobExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get JobExecutions for a given jobExecutionType
         * @param {string} jobExecutionName jobExecutionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobExecutionsUsingGET: async (jobExecutionName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobExecutionName' is not null or undefined
            assertParamExists('getJobExecutionsUsingGET', 'jobExecutionName', jobExecutionName)
            const localVarPath = `/api/v1/jobExecutions/getJobExecutions/{jobExecutionName}`
                .replace(`{${"jobExecutionName"}}`, encodeURIComponent(String(jobExecutionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Latest JobExecution grouped by jobExecutionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestJobExecutionsUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobExecutions/getLatestJobExecutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start tracking a job
         * @param {StartJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJobExecutionUsingPUT: async (req: StartJobExecutionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('startJobExecutionUsingPUT', 'req', req)
            const localVarPath = `/api/v1/jobExecutions/startJobExecution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a job completed successfully.
         * @param {string} jobExecutionId jobExecutionId
         * @param {UpdateJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        succeedJobExecutionUsingPUT: async (jobExecutionId: string, req: UpdateJobExecutionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobExecutionId' is not null or undefined
            assertParamExists('succeedJobExecutionUsingPUT', 'jobExecutionId', jobExecutionId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('succeedJobExecutionUsingPUT', 'req', req)
            const localVarPath = `/api/v1/jobExecutions/succeedJobExecution/{jobExecutionId}`
                .replace(`{${"jobExecutionId"}}`, encodeURIComponent(String(jobExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobExecutionControllerApi - functional programming interface
 * @export
 */
export const JobExecutionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobExecutionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Mark a job failed.
         * @param {string} jobExecutionId jobExecutionId
         * @param {UpdateJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failJobExecutionUsingPUT(jobExecutionId: string, req: UpdateJobExecutionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failJobExecutionUsingPUT(jobExecutionId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get JobExecution by id
         * @param {string} jobExecutionId jobExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobExecutionByIdUsingGET(jobExecutionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobExecutionByIdUsingGET(jobExecutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get JobExecutions for a given jobExecutionType
         * @param {string} jobExecutionName jobExecutionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobExecutionsUsingGET(jobExecutionName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobExecutionsUsingGET(jobExecutionName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Latest JobExecution grouped by jobExecutionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestJobExecutionsUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestJobExecutionsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start tracking a job
         * @param {StartJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startJobExecutionUsingPUT(req: StartJobExecutionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startJobExecutionUsingPUT(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a job completed successfully.
         * @param {string} jobExecutionId jobExecutionId
         * @param {UpdateJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async succeedJobExecutionUsingPUT(jobExecutionId: string, req: UpdateJobExecutionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.succeedJobExecutionUsingPUT(jobExecutionId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobExecutionControllerApi - factory interface
 * @export
 */
export const JobExecutionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobExecutionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Mark a job failed.
         * @param {string} jobExecutionId jobExecutionId
         * @param {UpdateJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failJobExecutionUsingPUT(jobExecutionId: string, req: UpdateJobExecutionRequest, options?: any): AxiosPromise<JobExecutionResponse> {
            return localVarFp.failJobExecutionUsingPUT(jobExecutionId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get JobExecution by id
         * @param {string} jobExecutionId jobExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobExecutionByIdUsingGET(jobExecutionId: string, options?: any): AxiosPromise<JobExecutionResponse> {
            return localVarFp.getJobExecutionByIdUsingGET(jobExecutionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get JobExecutions for a given jobExecutionType
         * @param {string} jobExecutionName jobExecutionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobExecutionsUsingGET(jobExecutionName: string, options?: any): AxiosPromise<JobExecutionsResponse> {
            return localVarFp.getJobExecutionsUsingGET(jobExecutionName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Latest JobExecution grouped by jobExecutionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestJobExecutionsUsingGET(options?: any): AxiosPromise<JobExecutionsResponse> {
            return localVarFp.getLatestJobExecutionsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start tracking a job
         * @param {StartJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJobExecutionUsingPUT(req: StartJobExecutionRequest, options?: any): AxiosPromise<JobExecutionResponse> {
            return localVarFp.startJobExecutionUsingPUT(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a job completed successfully.
         * @param {string} jobExecutionId jobExecutionId
         * @param {UpdateJobExecutionRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        succeedJobExecutionUsingPUT(jobExecutionId: string, req: UpdateJobExecutionRequest, options?: any): AxiosPromise<JobExecutionResponse> {
            return localVarFp.succeedJobExecutionUsingPUT(jobExecutionId, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobExecutionControllerApi - object-oriented interface
 * @export
 * @class JobExecutionControllerApi
 * @extends {BaseAPI}
 */
export class JobExecutionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Mark a job failed.
     * @param {string} jobExecutionId jobExecutionId
     * @param {UpdateJobExecutionRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public failJobExecutionUsingPUT(jobExecutionId: string, req: UpdateJobExecutionRequest, options?: any) {
        return JobExecutionControllerApiFp(this.configuration).failJobExecutionUsingPUT(jobExecutionId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get JobExecution by id
     * @param {string} jobExecutionId jobExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public getJobExecutionByIdUsingGET(jobExecutionId: string, options?: any) {
        return JobExecutionControllerApiFp(this.configuration).getJobExecutionByIdUsingGET(jobExecutionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get JobExecutions for a given jobExecutionType
     * @param {string} jobExecutionName jobExecutionName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public getJobExecutionsUsingGET(jobExecutionName: string, options?: any) {
        return JobExecutionControllerApiFp(this.configuration).getJobExecutionsUsingGET(jobExecutionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Latest JobExecution grouped by jobExecutionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public getLatestJobExecutionsUsingGET(options?: any) {
        return JobExecutionControllerApiFp(this.configuration).getLatestJobExecutionsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start tracking a job
     * @param {StartJobExecutionRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public startJobExecutionUsingPUT(req: StartJobExecutionRequest, options?: any) {
        return JobExecutionControllerApiFp(this.configuration).startJobExecutionUsingPUT(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a job completed successfully.
     * @param {string} jobExecutionId jobExecutionId
     * @param {UpdateJobExecutionRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public succeedJobExecutionUsingPUT(jobExecutionId: string, req: UpdateJobExecutionRequest, options?: any) {
        return JobExecutionControllerApiFp(this.configuration).succeedJobExecutionUsingPUT(jobExecutionId, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MlsControllerApi - axios parameter creator
 * @export
 */
export const MlsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get MLS by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMlsByIdUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMlsByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/mls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id id
         * @param {UpdateMlsRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMlsByIdUsingPUT: async (id: string, req: UpdateMlsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMlsByIdUsingPUT', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateMlsByIdUsingPUT', 'req', req)
            const localVarPath = `/api/v1/mls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MlsControllerApi - functional programming interface
 * @export
 */
export const MlsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MlsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get MLS by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMlsByIdUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MlsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMlsByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id id
         * @param {UpdateMlsRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMlsByIdUsingPUT(id: string, req: UpdateMlsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MlsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMlsByIdUsingPUT(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MlsControllerApi - factory interface
 * @export
 */
export const MlsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MlsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get MLS by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMlsByIdUsingGET(id: string, options?: any): AxiosPromise<MlsResponse> {
            return localVarFp.getMlsByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id id
         * @param {UpdateMlsRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMlsByIdUsingPUT(id: string, req: UpdateMlsRequest, options?: any): AxiosPromise<MlsResponse> {
            return localVarFp.updateMlsByIdUsingPUT(id, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MlsControllerApi - object-oriented interface
 * @export
 * @class MlsControllerApi
 * @extends {BaseAPI}
 */
export class MlsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get MLS by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public getMlsByIdUsingGET(id: string, options?: any) {
        return MlsControllerApiFp(this.configuration).getMlsByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update board by id
     * @param {string} id id
     * @param {UpdateMlsRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public updateMlsByIdUsingPUT(id: string, req: UpdateMlsRequest, options?: any) {
        return MlsControllerApiFp(this.configuration).updateMlsByIdUsingPUT(id, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoteControllerApi - axios parameter creator
 * @export
 */
export const NoteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive Note
         * @param {string} noteId noteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNoteUsingPUT: async (noteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('archiveNoteUsingPUT', 'noteId', noteId)
            const localVarPath = `/api/v1/notes/archiveNote/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Note
         * @param {CreateNoteRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteUsingPUT: async (req: CreateNoteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createNoteUsingPUT', 'req', req)
            const localVarPath = `/api/v1/notes/createNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Note by id
         * @param {string} noteId noteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteByIdUsingGET: async (noteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('getNoteByIdUsingGET', 'noteId', noteId)
            const localVarPath = `/api/v1/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Notes for a given entityType and entityId
         * @param {string} entityId entityId
         * @param {'APPLICATION' | 'TRANSACTION'} entityType entityType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteByIdUsingGET1: async (entityId: string, entityType: 'APPLICATION' | 'TRANSACTION', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getNoteByIdUsingGET1', 'entityId', entityId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getNoteByIdUsingGET1', 'entityType', entityType)
            const localVarPath = `/api/v1/notes/{entityType}/{entityId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"entityType"}}`, encodeURIComponent(String(entityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Note
         * @param {string} noteId noteId
         * @param {UpdateNoteRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteUsingPUT: async (noteId: string, req: UpdateNoteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateNoteUsingPUT', 'noteId', noteId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateNoteUsingPUT', 'req', req)
            const localVarPath = `/api/v1/notes/updateNote/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteControllerApi - functional programming interface
 * @export
 */
export const NoteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive Note
         * @param {string} noteId noteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveNoteUsingPUT(noteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveNoteUsingPUT(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Note
         * @param {CreateNoteRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNoteUsingPUT(req: CreateNoteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNoteUsingPUT(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Note by id
         * @param {string} noteId noteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteByIdUsingGET(noteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteByIdUsingGET(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Notes for a given entityType and entityId
         * @param {string} entityId entityId
         * @param {'APPLICATION' | 'TRANSACTION'} entityType entityType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteByIdUsingGET1(entityId: string, entityType: 'APPLICATION' | 'TRANSACTION', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteByIdUsingGET1(entityId, entityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Note
         * @param {string} noteId noteId
         * @param {UpdateNoteRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoteUsingPUT(noteId: string, req: UpdateNoteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteUsingPUT(noteId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoteControllerApi - factory interface
 * @export
 */
export const NoteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive Note
         * @param {string} noteId noteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNoteUsingPUT(noteId: string, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.archiveNoteUsingPUT(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Note
         * @param {CreateNoteRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteUsingPUT(req: CreateNoteRequest, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.createNoteUsingPUT(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Note by id
         * @param {string} noteId noteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteByIdUsingGET(noteId: string, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.getNoteByIdUsingGET(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Notes for a given entityType and entityId
         * @param {string} entityId entityId
         * @param {'APPLICATION' | 'TRANSACTION'} entityType entityType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteByIdUsingGET1(entityId: string, entityType: 'APPLICATION' | 'TRANSACTION', options?: any): AxiosPromise<NotesResponse> {
            return localVarFp.getNoteByIdUsingGET1(entityId, entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Note
         * @param {string} noteId noteId
         * @param {UpdateNoteRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteUsingPUT(noteId: string, req: UpdateNoteRequest, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.updateNoteUsingPUT(noteId, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteControllerApi - object-oriented interface
 * @export
 * @class NoteControllerApi
 * @extends {BaseAPI}
 */
export class NoteControllerApi extends BaseAPI {
    /**
     * 
     * @summary Archive Note
     * @param {string} noteId noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public archiveNoteUsingPUT(noteId: string, options?: any) {
        return NoteControllerApiFp(this.configuration).archiveNoteUsingPUT(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Note
     * @param {CreateNoteRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public createNoteUsingPUT(req: CreateNoteRequest, options?: any) {
        return NoteControllerApiFp(this.configuration).createNoteUsingPUT(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Note by id
     * @param {string} noteId noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public getNoteByIdUsingGET(noteId: string, options?: any) {
        return NoteControllerApiFp(this.configuration).getNoteByIdUsingGET(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Notes for a given entityType and entityId
     * @param {string} entityId entityId
     * @param {'APPLICATION' | 'TRANSACTION'} entityType entityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public getNoteByIdUsingGET1(entityId: string, entityType: 'APPLICATION' | 'TRANSACTION', options?: any) {
        return NoteControllerApiFp(this.configuration).getNoteByIdUsingGET1(entityId, entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Note
     * @param {string} noteId noteId
     * @param {UpdateNoteRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public updateNoteUsingPUT(noteId: string, req: UpdateNoteRequest, options?: any) {
        return NoteControllerApiFp(this.configuration).updateNoteUsingPUT(noteId, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfficeControllerApi - axios parameter creator
 * @export
 */
export const OfficeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get global office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalOfficeUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offices/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Office by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficeByIdUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOfficeByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/offices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfficeControllerApi - functional programming interface
 * @export
 */
export const OfficeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfficeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get global office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalOfficeUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalOfficeUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Office by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfficeByIdUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficeByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfficeControllerApi - factory interface
 * @export
 */
export const OfficeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfficeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get global office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalOfficeUsingGET(options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.getGlobalOfficeUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Office by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficeByIdUsingGET(id: string, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.getOfficeByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfficeControllerApi - object-oriented interface
 * @export
 * @class OfficeControllerApi
 * @extends {BaseAPI}
 */
export class OfficeControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get global office
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getGlobalOfficeUsingGET(options?: any) {
        return OfficeControllerApiFp(this.configuration).getGlobalOfficeUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Office by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getOfficeByIdUsingGET(id: string, options?: any) {
        return OfficeControllerApiFp(this.configuration).getOfficeByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicControllerApi - axios parameter creator
 * @export
 */
export const PublicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get public message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicControllerApi - functional programming interface
 * @export
 */
export const PublicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get public message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicControllerApi - factory interface
 * @export
 */
export const PublicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get public message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET(options?: any): AxiosPromise<string> {
            return localVarFp.getMessageUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicControllerApi - object-oriented interface
 * @export
 * @class PublicControllerApi
 * @extends {BaseAPI}
 */
export class PublicControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get public message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getMessageUsingGET(options?: any) {
        return PublicControllerApiFp(this.configuration).getMessageUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PushNotificationControllerApi - axios parameter creator
 * @export
 */
export const PushNotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pushNotifications/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a push notification token for a particular user
         * @param {string} pushRegistrationId pushRegistrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUsingPUT: async (pushRegistrationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pushRegistrationId' is not null or undefined
            assertParamExists('registerUsingPUT', 'pushRegistrationId', pushRegistrationId)
            const localVarPath = `/api/v1/pushNotifications/{pushRegistrationId}`
                .replace(`{${"pushRegistrationId"}}`, encodeURIComponent(String(pushRegistrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushNotificationControllerApi - functional programming interface
 * @export
 */
export const PushNotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushNotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageUsingGET1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageUsingGET1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register a push notification token for a particular user
         * @param {string} pushRegistrationId pushRegistrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUsingPUT(pushRegistrationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushNotificationRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUsingPUT(pushRegistrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushNotificationControllerApi - factory interface
 * @export
 */
export const PushNotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushNotificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET1(options?: any): AxiosPromise<string> {
            return localVarFp.getMessageUsingGET1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a push notification token for a particular user
         * @param {string} pushRegistrationId pushRegistrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUsingPUT(pushRegistrationId: string, options?: any): AxiosPromise<PushNotificationRegistrationResponse> {
            return localVarFp.registerUsingPUT(pushRegistrationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PushNotificationControllerApi - object-oriented interface
 * @export
 * @class PushNotificationControllerApi
 * @extends {BaseAPI}
 */
export class PushNotificationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get secured message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationControllerApi
     */
    public getMessageUsingGET1(options?: any) {
        return PushNotificationControllerApiFp(this.configuration).getMessageUsingGET1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a push notification token for a particular user
     * @param {string} pushRegistrationId pushRegistrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationControllerApi
     */
    public registerUsingPUT(pushRegistrationId: string, options?: any) {
        return PushNotificationControllerApiFp(this.configuration).registerUsingPUT(pushRegistrationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchControllerApi - axios parameter creator
 * @export
 */
export const SearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingPOST: async (request: SearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('searchUsingPOST', 'request', request)
            const localVarPath = `/api/v1/search/universal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchControllerApi - functional programming interface
 * @export
 */
export const SearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsingPOST(request: SearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsingPOST(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchControllerApi - factory interface
 * @export
 */
export const SearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingPOST(request: SearchRequest, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.searchUsingPOST(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchControllerApi - object-oriented interface
 * @export
 * @class SearchControllerApi
 * @extends {BaseAPI}
 */
export class SearchControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search API
     * @param {SearchRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchControllerApi
     */
    public searchUsingPOST(request: SearchRequest, options?: any) {
        return SearchControllerApiFp(this.configuration).searchUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecuredControllerApi - axios parameter creator
 * @export
 */
export const SecuredControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/secured/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecuredControllerApi - functional programming interface
 * @export
 */
export const SecuredControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecuredControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageUsingGET2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageUsingGET2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecuredControllerApi - factory interface
 * @export
 */
export const SecuredControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecuredControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET2(options?: any): AxiosPromise<string> {
            return localVarFp.getMessageUsingGET2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecuredControllerApi - object-oriented interface
 * @export
 * @class SecuredControllerApi
 * @extends {BaseAPI}
 */
export class SecuredControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get secured message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuredControllerApi
     */
    public getMessageUsingGET2(options?: any) {
        return SecuredControllerApiFp(this.configuration).getMessageUsingGET2(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamControllerApi - axios parameter creator
 * @export
 */
export const TeamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a member to an existing Domestic team
         * @param {string} id id
         * @param {AddDomesticTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToDomesticTeamUsingPOST: async (id: string, req: AddDomesticTeamMemberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberToDomesticTeamUsingPOST', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('addMemberToDomesticTeamUsingPOST', 'req', req)
            const localVarPath = `/api/v1/teams/domestic/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a member to an existing Normal team
         * @param {string} id id
         * @param {AddNormalTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToNormalTeamUsingPOST: async (id: string, req: AddNormalTeamMemberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberToNormalTeamUsingPOST', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('addMemberToNormalTeamUsingPOST', 'req', req)
            const localVarPath = `/api/v1/teams/normal/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a member to an existing Platinum team
         * @param {string} id id
         * @param {AddPlatinumTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToPlatinumTeamUsingPOST: async (id: string, req: AddPlatinumTeamMemberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberToPlatinumTeamUsingPOST', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('addMemberToPlatinumTeamUsingPOST', 'req', req)
            const localVarPath = `/api/v1/teams/platinum/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Domestic team
         * @param {CreateDomesticTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDomesticTeamUsingPOST: async (req: CreateDomesticTeamRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createDomesticTeamUsingPOST', 'req', req)
            const localVarPath = `/api/v1/teams/domestic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Normal Team
         * @param {CreateNormalTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalTeamUsingPOST: async (req: CreateNormalTeamRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createNormalTeamUsingPOST', 'req', req)
            const localVarPath = `/api/v1/teams/normal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Platinum team
         * @param {CreatePlatinumTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlatinumTeamUsingPOST: async (req: CreatePlatinumTeamRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createPlatinumTeamUsingPOST', 'req', req)
            const localVarPath = `/api/v1/teams/platinum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamByIdUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a member from an existing team
         * @param {string} agentId agentId
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberUsingDELETE: async (agentId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('removeMemberUsingDELETE', 'agentId', agentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeMemberUsingDELETE', 'id', id)
            const localVarPath = `/api/v1/teams/{id}/members/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing member in a Normal team
         * @param {string} agentId agentId
         * @param {string} teamId teamId
         * @param {UpdateTeamMemberCommissionSplitRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberInNormalTeamUsingPUT: async (agentId: string, teamId: string, req: UpdateTeamMemberCommissionSplitRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateMemberInNormalTeamUsingPUT', 'agentId', agentId)
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateMemberInNormalTeamUsingPUT', 'teamId', teamId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateMemberInNormalTeamUsingPUT', 'req', req)
            const localVarPath = `/api/v1/teams/normal/{teamId}/members/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing member in a Platinum team
         * @param {string} agentId agentId
         * @param {string} teamId teamId
         * @param {UpdateTeamMemberCommissionSplitRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberInPlatinumTeamUsingPUT: async (agentId: string, teamId: string, req: UpdateTeamMemberCommissionSplitRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateMemberInPlatinumTeamUsingPUT', 'agentId', agentId)
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateMemberInPlatinumTeamUsingPUT', 'teamId', teamId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateMemberInPlatinumTeamUsingPUT', 'req', req)
            const localVarPath = `/api/v1/teams/platinum/{teamId}/members/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update team by id
         * @param {string} id id
         * @param {UpdateTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamUsingPUT: async (id: string, req: UpdateTeamRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeamUsingPUT', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateTeamUsingPUT', 'req', req)
            const localVarPath = `/api/v1/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamControllerApi - functional programming interface
 * @export
 */
export const TeamControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a member to an existing Domestic team
         * @param {string} id id
         * @param {AddDomesticTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToDomesticTeamUsingPOST(id: string, req: AddDomesticTeamMemberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToDomesticTeamUsingPOST(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a member to an existing Normal team
         * @param {string} id id
         * @param {AddNormalTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToNormalTeamUsingPOST(id: string, req: AddNormalTeamMemberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToNormalTeamUsingPOST(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a member to an existing Platinum team
         * @param {string} id id
         * @param {AddPlatinumTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToPlatinumTeamUsingPOST(id: string, req: AddPlatinumTeamMemberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToPlatinumTeamUsingPOST(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Domestic team
         * @param {CreateDomesticTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDomesticTeamUsingPOST(req: CreateDomesticTeamRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDomesticTeamUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Normal Team
         * @param {CreateNormalTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalTeamUsingPOST(req: CreateNormalTeamRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNormalTeamUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Platinum team
         * @param {CreatePlatinumTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlatinumTeamUsingPOST(req: CreatePlatinumTeamRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlatinumTeamUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamByIdUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a member from an existing team
         * @param {string} agentId agentId
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMemberUsingDELETE(agentId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMemberUsingDELETE(agentId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing member in a Normal team
         * @param {string} agentId agentId
         * @param {string} teamId teamId
         * @param {UpdateTeamMemberCommissionSplitRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberInNormalTeamUsingPUT(agentId: string, teamId: string, req: UpdateTeamMemberCommissionSplitRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberInNormalTeamUsingPUT(agentId, teamId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing member in a Platinum team
         * @param {string} agentId agentId
         * @param {string} teamId teamId
         * @param {UpdateTeamMemberCommissionSplitRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberInPlatinumTeamUsingPUT(agentId: string, teamId: string, req: UpdateTeamMemberCommissionSplitRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberInPlatinumTeamUsingPUT(agentId, teamId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update team by id
         * @param {string} id id
         * @param {UpdateTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamUsingPUT(id: string, req: UpdateTeamRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamUsingPUT(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamControllerApi - factory interface
 * @export
 */
export const TeamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a member to an existing Domestic team
         * @param {string} id id
         * @param {AddDomesticTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToDomesticTeamUsingPOST(id: string, req: AddDomesticTeamMemberRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.addMemberToDomesticTeamUsingPOST(id, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a member to an existing Normal team
         * @param {string} id id
         * @param {AddNormalTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToNormalTeamUsingPOST(id: string, req: AddNormalTeamMemberRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.addMemberToNormalTeamUsingPOST(id, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a member to an existing Platinum team
         * @param {string} id id
         * @param {AddPlatinumTeamMemberRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToPlatinumTeamUsingPOST(id: string, req: AddPlatinumTeamMemberRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.addMemberToPlatinumTeamUsingPOST(id, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Domestic team
         * @param {CreateDomesticTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDomesticTeamUsingPOST(req: CreateDomesticTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.createDomesticTeamUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Normal Team
         * @param {CreateNormalTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalTeamUsingPOST(req: CreateNormalTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.createNormalTeamUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Platinum team
         * @param {CreatePlatinumTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlatinumTeamUsingPOST(req: CreatePlatinumTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.createPlatinumTeamUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamByIdUsingGET(id: string, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.getTeamByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a member from an existing team
         * @param {string} agentId agentId
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberUsingDELETE(agentId: string, id: string, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.removeMemberUsingDELETE(agentId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing member in a Normal team
         * @param {string} agentId agentId
         * @param {string} teamId teamId
         * @param {UpdateTeamMemberCommissionSplitRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberInNormalTeamUsingPUT(agentId: string, teamId: string, req: UpdateTeamMemberCommissionSplitRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateMemberInNormalTeamUsingPUT(agentId, teamId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing member in a Platinum team
         * @param {string} agentId agentId
         * @param {string} teamId teamId
         * @param {UpdateTeamMemberCommissionSplitRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberInPlatinumTeamUsingPUT(agentId: string, teamId: string, req: UpdateTeamMemberCommissionSplitRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateMemberInPlatinumTeamUsingPUT(agentId, teamId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update team by id
         * @param {string} id id
         * @param {UpdateTeamRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamUsingPUT(id: string, req: UpdateTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateTeamUsingPUT(id, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamControllerApi - object-oriented interface
 * @export
 * @class TeamControllerApi
 * @extends {BaseAPI}
 */
export class TeamControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add a member to an existing Domestic team
     * @param {string} id id
     * @param {AddDomesticTeamMemberRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addMemberToDomesticTeamUsingPOST(id: string, req: AddDomesticTeamMemberRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).addMemberToDomesticTeamUsingPOST(id, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a member to an existing Normal team
     * @param {string} id id
     * @param {AddNormalTeamMemberRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addMemberToNormalTeamUsingPOST(id: string, req: AddNormalTeamMemberRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).addMemberToNormalTeamUsingPOST(id, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a member to an existing Platinum team
     * @param {string} id id
     * @param {AddPlatinumTeamMemberRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addMemberToPlatinumTeamUsingPOST(id: string, req: AddPlatinumTeamMemberRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).addMemberToPlatinumTeamUsingPOST(id, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Domestic team
     * @param {CreateDomesticTeamRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createDomesticTeamUsingPOST(req: CreateDomesticTeamRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).createDomesticTeamUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Normal Team
     * @param {CreateNormalTeamRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createNormalTeamUsingPOST(req: CreateNormalTeamRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).createNormalTeamUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Platinum team
     * @param {CreatePlatinumTeamRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createPlatinumTeamUsingPOST(req: CreatePlatinumTeamRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).createPlatinumTeamUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamByIdUsingGET(id: string, options?: any) {
        return TeamControllerApiFp(this.configuration).getTeamByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a member from an existing team
     * @param {string} agentId agentId
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public removeMemberUsingDELETE(agentId: string, id: string, options?: any) {
        return TeamControllerApiFp(this.configuration).removeMemberUsingDELETE(agentId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing member in a Normal team
     * @param {string} agentId agentId
     * @param {string} teamId teamId
     * @param {UpdateTeamMemberCommissionSplitRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateMemberInNormalTeamUsingPUT(agentId: string, teamId: string, req: UpdateTeamMemberCommissionSplitRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).updateMemberInNormalTeamUsingPUT(agentId, teamId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing member in a Platinum team
     * @param {string} agentId agentId
     * @param {string} teamId teamId
     * @param {UpdateTeamMemberCommissionSplitRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateMemberInPlatinumTeamUsingPUT(agentId: string, teamId: string, req: UpdateTeamMemberCommissionSplitRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).updateMemberInPlatinumTeamUsingPUT(agentId, teamId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update team by id
     * @param {string} id id
     * @param {UpdateTeamRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateTeamUsingPUT(id: string, req: UpdateTeamRequest, options?: any) {
        return TeamControllerApiFp(this.configuration).updateTeamUsingPUT(id, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestControllerApi - axios parameter creator
 * @export
 */
export const TestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDateUsingPUT: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/reset-clock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clock Management API
         * @param {string} localDate localDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDateUsingPUT: async (localDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'localDate' is not null or undefined
            assertParamExists('setDateUsingPUT', 'localDate', localDate)
            const localVarPath = `/api/v1/test/set-clock/{localDate}`
                .replace(`{${"localDate"}}`, encodeURIComponent(String(localDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestControllerApi - functional programming interface
 * @export
 */
export const TestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetDateUsingPUT(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetDateUsingPUT(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clock Management API
         * @param {string} localDate localDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDateUsingPUT(localDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDateUsingPUT(localDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestControllerApi - factory interface
 * @export
 */
export const TestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDateUsingPUT(options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.resetDateUsingPUT(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clock Management API
         * @param {string} localDate localDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDateUsingPUT(localDate: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.setDateUsingPUT(localDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestControllerApi - object-oriented interface
 * @export
 * @class TestControllerApi
 * @extends {BaseAPI}
 */
export class TestControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public resetDateUsingPUT(options?: any) {
        return TestControllerApiFp(this.configuration).resetDateUsingPUT(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clock Management API
     * @param {string} localDate localDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public setDateUsingPUT(localDate: string, options?: any) {
        return TestControllerApiFp(this.configuration).setDateUsingPUT(localDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create external user (seller, buyer, non-REAL agent) from SkySlope transaction data
         * @param {CreateExternalUserRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalUserUsingPOST: async (req: CreateExternalUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createExternalUserUsingPOST', 'req', req)
            const localVarPath = `/api/v1/users/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user UserPrincipal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserPrincipalUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/myprincipal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByIdUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by SkySlope id
         * @param {string} skySlopeId skySlopeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBySkySlopeIdUsingGET: async (skySlopeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'skySlopeId' is not null or undefined
            assertParamExists('getUserBySkySlopeIdUsingGET', 'skySlopeId', skySlopeId)
            const localVarPath = `/api/v1/users/skySlope/{skySlopeId}`
                .replace(`{${"skySlopeId"}}`, encodeURIComponent(String(skySlopeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST: async (resetPasswordRequest: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetPasswordUsingPOST', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/v1/users/forgotpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply to be Real agent
         * @param {SignUpRequest} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupUsingPOST: async (signUpRequest: SignUpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpRequest' is not null or undefined
            assertParamExists('signupUsingPOST', 'signUpRequest', signUpRequest)
            const localVarPath = `/api/v1/users/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update external user by id
         * @param {string} id id
         * @param {UpdateExternalUserRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalUserUsingPUT: async (id: string, req: UpdateExternalUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExternalUserUsingPUT', 'id', id)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateExternalUserUsingPUT', 'req', req)
            const localVarPath = `/api/v1/users/external/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload User Avatar
         * @param {string} id id
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatarUsingPOST: async (id: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadAvatarUsingPOST', 'id', id)
            const localVarPath = `/api/v1/users/{id}/avatar/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create external user (seller, buyer, non-REAL agent) from SkySlope transaction data
         * @param {CreateExternalUserRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExternalUserUsingPOST(req: CreateExternalUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExternalUserUsingPOST(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user UserPrincipal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserPrincipalUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrincipal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserPrincipalUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByIdUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by SkySlope id
         * @param {string} skySlopeId skySlopeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserBySkySlopeIdUsingGET(skySlopeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserBySkySlopeIdUsingGET(skySlopeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordUsingPOST(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apply to be Real agent
         * @param {SignUpRequest} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signupUsingPOST(signUpRequest: SignUpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signupUsingPOST(signUpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update external user by id
         * @param {string} id id
         * @param {UpdateExternalUserRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalUserUsingPUT(id: string, req: UpdateExternalUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalUserUsingPUT(id, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload User Avatar
         * @param {string} id id
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAvatarUsingPOST(id: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAvatarUsingPOST(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create external user (seller, buyer, non-REAL agent) from SkySlope transaction data
         * @param {CreateExternalUserRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalUserUsingPOST(req: CreateExternalUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createExternalUserUsingPOST(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user UserPrincipal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserPrincipalUsingGET(options?: any): AxiosPromise<UserPrincipal> {
            return localVarFp.getCurrentUserPrincipalUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserUsingGET(options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getCurrentUserUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByIdUsingGET(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getUserByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by SkySlope id
         * @param {string} skySlopeId skySlopeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBySkySlopeIdUsingGET(skySlopeId: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getUserBySkySlopeIdUsingGET(skySlopeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<any> {
            return localVarFp.resetPasswordUsingPOST(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply to be Real agent
         * @param {SignUpRequest} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupUsingPOST(signUpRequest: SignUpRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.signupUsingPOST(signUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update external user by id
         * @param {string} id id
         * @param {UpdateExternalUserRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalUserUsingPUT(id: string, req: UpdateExternalUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateExternalUserUsingPUT(id, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload User Avatar
         * @param {string} id id
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatarUsingPOST(id: string, file?: any, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.uploadAvatarUsingPOST(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create external user (seller, buyer, non-REAL agent) from SkySlope transaction data
     * @param {CreateExternalUserRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createExternalUserUsingPOST(req: CreateExternalUserRequest, options?: any) {
        return UserControllerApiFp(this.configuration).createExternalUserUsingPOST(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user UserPrincipal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUserPrincipalUsingGET(options?: any) {
        return UserControllerApiFp(this.configuration).getCurrentUserPrincipalUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUserUsingGET(options?: any) {
        return UserControllerApiFp(this.configuration).getCurrentUserUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserByIdUsingGET(id: string, options?: any) {
        return UserControllerApiFp(this.configuration).getUserByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by SkySlope id
     * @param {string} skySlopeId skySlopeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserBySkySlopeIdUsingGET(skySlopeId: string, options?: any) {
        return UserControllerApiFp(this.configuration).getUserBySkySlopeIdUsingGET(skySlopeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ForgotPassword
     * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetPasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any) {
        return UserControllerApiFp(this.configuration).resetPasswordUsingPOST(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apply to be Real agent
     * @param {SignUpRequest} signUpRequest signUpRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public signupUsingPOST(signUpRequest: SignUpRequest, options?: any) {
        return UserControllerApiFp(this.configuration).signupUsingPOST(signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update external user by id
     * @param {string} id id
     * @param {UpdateExternalUserRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateExternalUserUsingPUT(id: string, req: UpdateExternalUserRequest, options?: any) {
        return UserControllerApiFp(this.configuration).updateExternalUserUsingPUT(id, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload User Avatar
     * @param {string} id id
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public uploadAvatarUsingPOST(id: string, file?: any, options?: any) {
        return UserControllerApiFp(this.configuration).uploadAvatarUsingPOST(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}


