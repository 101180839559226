import { AgentResponse } from '../openapi/yenta';

export const hasSocialMediaLinks = (agent: AgentResponse): boolean => {
  return (
    !!agent.facebookURL ||
    !!agent.twitterURL ||
    !!agent.clubhouseURL ||
    !!agent.instagramURL ||
    !!agent.youtubeURL ||
    !!agent.personalWebsiteURL ||
    !!agent.clubhouseURL ||
    !!agent.googleBusinessProfileURL ||
    !!agent.linkedInURL ||
    !!agent.yelpURL ||
    !!agent.zillowURL
  );
};
