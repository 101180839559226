import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InvoiceForm from './InvoiceForm';

interface Match {
  id: string;
}

interface InvoiceFormRouteProps extends RouteComponentProps<Match> {}

const InvoiceFormRoute: React.FC<InvoiceFormRouteProps> = ({ match }) => {
  const { id } = match.params;

  return <InvoiceForm commissionDocumentId={id} isPdf={false} />;
};

export default InvoiceFormRoute;
