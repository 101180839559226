import { ReferralResponseReferralStatusEnum } from '../openapi/arrakis';
import { EnumMap } from '../types';

export const isReferralActionable = (
  status: ReferralResponseReferralStatusEnum,
): boolean => {
  const mapReferralStatus: EnumMap<
    ReferralResponseReferralStatusEnum,
    boolean
  > = {
    [ReferralResponseReferralStatusEnum.Disbursed]: false,
    [ReferralResponseReferralStatusEnum.PaymentReceived]: false,
    [ReferralResponseReferralStatusEnum.Submitted]: true,
  };

  return mapReferralStatus[status];
};
