import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState, ErrorCode } from '../types';
import ErrorService from '../services/ErrorService';
import {
  AgentResponse,
  ApplicationControllerApi,
  ApplicationUpdateRequest,
  ApproveApplicationRequest,
  RejectApplicationRequest,
} from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: ApplicationState = {
  loadingDetail: false,
  applicationDetail: undefined,
  fetchDetailErrorCode: null,
};

const ApplicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveApplicationDetail(state, action: PayloadAction<AgentResponse>) {
      state.applicationDetail = action.payload;
      state.fetchDetailErrorCode = null;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
  },
});

export const {
  changeLoadingDetail,
  errorFetchingDetail,
  saveApplicationDetail,
} = ApplicationSlice.actions;

export const fetchApplicationById = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).getApplicationUsingGET(id);
    await dispatch(saveApplicationDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent', e);
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const approveApplicationById = (
  id: string,
  approveRequest: ApproveApplicationRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).approveApplicationUsingPOST(id, approveRequest);
    await dispatch(saveApplicationDetail(data));
    await dispatch(showSuccessToast('Application Approved.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to approve agent', e);
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const rejectApplicationById = (
  id: string,
  rejectRequest: RejectApplicationRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).rejectApplicationUsingPOST(id, rejectRequest);
    await dispatch(saveApplicationDetail(data));
    await dispatch(showSuccessToast('Application Rejected.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to reject agent', e);
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const editApplicationForm = (
  id: string,
  values: ApplicationUpdateRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new ApplicationControllerApi(
      getYentaConfiguration(),
    ).updateApplicationUsingPUT(id, values);
    await dispatch(saveApplicationDetail(data));
    await dispatch(showSuccessToast('Application Updated.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to edit application form', e);
    dispatch(
      showErrorToast(
        'We had a problem updating application',
        'Please try again in a few moments.',
      ),
    );
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const agentLicenseTransferred = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    const api = new ApplicationControllerApi(getYentaConfiguration());
    const { data } = await api.licenseTransferredUsingPOST({
      applicationId: id,
    });
    await dispatch(saveApplicationDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to transfer license', e);
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const agentJoinedBoard = (id: string): AppThunk => async (dispatch) => {
  try {
    const api = new ApplicationControllerApi(getYentaConfiguration());
    const { data } = await api.boardJoinedUsingPOST({
      applicationId: id,
    });
    await dispatch(saveApplicationDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to join board in application', e);
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export default ApplicationSlice.reducer;
