import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import {
  AddressResponseStateOrProvinceEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
} from '../openapi/yenta';
import { StateAbbreviation } from '../types';

export type StateIconVariant = 'rounded' | 'circle';

// todo: remove StateAbbreviation once the api is ready
type StateType =
  | AddressResponseStateOrProvinceEnum
  | AdministrativeAreaResponseStateOrProvinceEnum
  | StateAbbreviation;

export interface StateIconProps {
  state: StateType;
  width: number;
  variant?: StateIconVariant;
}

const StateIcon: React.FC<StateIconProps> = ({
  state,
  width,
  variant = 'rounded',
}) => {
  const variantMapClass: { [x in StateIconVariant]: string } = {
    rounded: 'rounded',
    circle: 'rounded-full',
  };

  const onImageLoadError = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = '/img/states/default-state.png';
  };

  return (
    <img
      className={classNames('border border-gray-100', variantMapClass[variant])}
      onError={onImageLoadError}
      src={`/img/states/${state}.png`}
      width={width}
      alt={state}
    />
  );
};

export default StateIcon;
