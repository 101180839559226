import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import OutgoingPaymentsComponent from '../components/outgoingPayment/OugoingPaymentsComponent';

interface OutgoingPaymentsIndexRouteProps extends RouteComponentProps {}

const OutgoingPaymentsIndexRoute: React.FC<OutgoingPaymentsIndexRouteProps> = () => {
  return (
    <PageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Outgoing Payments', url: '/outgoing-payments' },
      ]}
    >
      <OutgoingPaymentsComponent />
    </PageLayout>
  );
};

export default OutgoingPaymentsIndexRoute;
