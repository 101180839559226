import { DateTime } from 'luxon';
import React from 'react';
import { ApplicationResponse } from '../../openapi/yenta';
import GridCard from '../GridCard';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import BooleanButton from '../Agent/BooleanButton';

interface ApplicationDetailCardProps {
  application: ApplicationResponse;
}

const ApplicationDetailCard: React.FC<ApplicationDetailCardProps> = ({
  application,
}) => {
  const doesBusinessIn = application
    .doesBusinessIn!.map((item) => {
      let data;
      let state = capitalizeEnum(item.administrativeArea!.stateOrProvince!);
      data = [
        {
          name: 'State / Province',
          value: state,
        },
        {
          name: `License Number in ${state}`,
          value: item!.number!,
        },
        {
          name: `License Active in ${state}?`,
          value: <BooleanButton value={item!.active!} />,
        },
        {
          name: `License Expiration Date in ${state}`,
          value: DateTime.fromISO(item!.expirationDate!).toLocaleString(),
        },
        {
          name: `Registered Complaint filed in ${state}?`,
          value: (
            <BooleanButton
              value={item!.knownComplaints!}
              className={
                item!.knownComplaints
                  ? 'text-red-800 bg-red-200'
                  : 'text-green-800 bg-green-200'
              }
            />
          ),
        },
      ];
      return data;
    })
    .reduce((a, b) => [...a, ...b], []);

  return (
    <GridCard
      items={[
        {
          name: 'Phone Number',
          value: formatPhoneNumber(application.phoneNumber!)!,
        },
        {
          name: 'Date of Birth',
          value: application.birthDate!,
        },
        {
          name: 'Member of MLS / Board',
          value: application.boardOrMls!,
        },
        ...doesBusinessIn,
        {
          name: 'Current Brokerage Name',
          value: application.currentBrokerage!,
        },
        {
          name: 'Estimated Sales Volume in last 12 months',
          value: application.estimatedSales!,
        },
        {
          name: 'Commercial-only Agent?',
          value: application.commercialAgent! ? 'Yes' : 'No',
        },
      ]}
      colCount={1}
    />
  );
};

export default ApplicationDetailCard;
