import React from 'react';
import { Apartment, Home } from '@material-ui/icons';
import Pill, { PillVariantType } from '../../Pill';
import { TransactionResponseTransactionTypeEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface TransactionTypeCellProps {
  type: TransactionResponseTransactionTypeEnum;
}

type TypeMap<T> = {
  [key in TransactionResponseTransactionTypeEnum]: T;
};

const typeToVariant: TypeMap<PillVariantType> = {
  SALE: 'success',
  LEASE: 'primary',
  COMMERCIAL_LEASE: 'primary',
  OTHER: 'warning',
  PLOT: 'dark',
  COMPENSATING: 'dark',
  REFERRAL: 'primary',
};

const typeToIcon: TypeMap<React.ReactElement> = {
  SALE: <Home fontSize='small' />,
  LEASE: <Apartment fontSize='small' />,
  COMMERCIAL_LEASE: <Apartment fontSize='small' />,
  PLOT: <Apartment fontSize='small' />,
  OTHER: <Apartment fontSize='small' />,
  COMPENSATING: <Apartment fontSize='small' />,
  REFERRAL: <Apartment fontSize='small' />,
};

const TransactionTypeCell: React.FC<TransactionTypeCellProps> = ({ type }) => {
  const variant: PillVariantType = typeToVariant[type];
  const icon: React.ReactElement = typeToIcon[type];

  return <Pill text={capitalizeEnum(type)} variant={variant!} icon={icon!} />;
};

export default TransactionTypeCell;
