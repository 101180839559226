import { times } from 'lodash';
import {
  IPaginateRes,
  OfficeIndexResponse,
  StateAbbreviation,
} from '../../../types';
import { OfficePreviewResponseTransactionTypeEnum } from '../../../openapi/yenta';
import {
  fakeAddress,
  fakeCompany,
  fakeNumber,
  fakePersonFullName,
  fakeProfileImage,
  randomEnum,
} from '../../../testUtils/FakerUtils';

export const DefaultOfficeIndexPaginationResponse: IPaginateRes<OfficeIndexResponse> = {
  data: times<OfficeIndexResponse>(20, (id) => ({
    id: id + 1,
    principalBroker: {
      id: id + 1,
      name: fakePersonFullName(),
      imageUrl: fakeProfileImage(),
    },
    companyName: fakeCompany(),
    ein: fakeNumber({ min: 10000000, max: 99999999 }),
    transactionDisbursementType: randomEnum<OfficePreviewResponseTransactionTypeEnum>(
      OfficePreviewResponseTransactionTypeEnum,
    ),
    address: fakeAddress(),
    state: randomEnum<StateAbbreviation>(StateAbbreviation),
    totalAgents: fakeNumber({ min: 100, max: 1000 }),
  })),
  total: fakeNumber({ min: 50, max: 100 }),
};
