import React from 'react';
import { CheckCircleOutline, Close, WarningRounded } from '@material-ui/icons';
import Pill, { PillVariantType } from '../../Pill';
import { EnumMap } from '../../../types';
import { TransactionLifecycleStateValueStateEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface TransactionStatusCellProps {
  status: TransactionLifecycleStateValueStateEnum;
}

const statusToVariant: EnumMap<
  TransactionLifecycleStateValueStateEnum,
  PillVariantType
> = {
  [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: 'primary',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]:
    'success',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]:
    'success',
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: 'success',
  [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]:
    'warning',
  [TransactionLifecycleStateValueStateEnum.Settled]: 'success',
  [TransactionLifecycleStateValueStateEnum.CalculateLedger]: 'warning',
  [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: 'warning',
  [TransactionLifecycleStateValueStateEnum.Closed]: 'success',
  [TransactionLifecycleStateValueStateEnum.CommissionValidated]: 'success',
  [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]:
    'warning',
  [TransactionLifecycleStateValueStateEnum.New]: 'primary',
  [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: 'dark',
  [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: 'primary',
  [TransactionLifecycleStateValueStateEnum.Terminated]: 'danger',
};

const statusToIcon: EnumMap<
  TransactionLifecycleStateValueStateEnum,
  React.ReactElement
> = {
  [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: (
    <Close fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.Settled]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.CalculateLedger]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.Closed]: <Close fontSize='small' />,
  [TransactionLifecycleStateValueStateEnum.CommissionValidated]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.New]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: (
    <WarningRounded fontSize='small' />
  ),
  [TransactionLifecycleStateValueStateEnum.Terminated]: (
    <Close fontSize='small' />
  ),
};

const TransactionStateCell: React.FC<TransactionStatusCellProps> = ({
  status,
}) => {
  const variant: PillVariantType = statusToVariant[status];
  const icon: React.ReactElement = statusToIcon[status];

  return <Pill text={capitalizeEnum(status)} variant={variant!} icon={icon!} />;
};

export default TransactionStateCell;
