interface IConfig {
  debug: boolean;
  reactAppHostUrl: string;
  assetsBaseUrl: string;
  stripe: {
    publishableKey: string;
  };
  bugsnag: {
    apiKey: string;
    releaseStage: string;
    deployVersion: string;
  };
  yentaImageBaseUrl: string;
  apiBasePath: {
    yenta: string;
    arrakis: string;
    keymaker: string;
  };
}

const config: IConfig = {
  debug: process.env.REACT_APP_DEBUG! === 'true',
  reactAppHostUrl: process.env.REACT_APP_HOST_URL!,
  assetsBaseUrl: process.env.REACT_APP_ASSETS_BASE_URL!,
  stripe: {
    publishableKey: process.env.REACT_APP_STRIPE_KEY!,
  },
  bugsnag: {
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY!,
    releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE!,
    deployVersion: process.env.REACT_APP_DEPLOY_VERSION!,
  },
  yentaImageBaseUrl: process.env.REACT_APP_YENTA_IMAGE_BASE_URL!,
  apiBasePath: {
    yenta: process.env.REACT_APP_YENTA_API_BASE!,
    arrakis: process.env.REACT_APP_ARRAKIS_API_BASE!,
    keymaker: process.env.REACT_APP_KEYMAKER_API_BASE!,
  },
};

export default config;
