import React, { useState } from 'react';
import IconButton from '../IconButton';
import CreateTransaction from '../transactions/CreateTransaction';
import { AgentResponse } from '../../openapi/yenta';

interface AgentDetailActionBarProps {
  agentInfo: AgentResponse;
}

const AgentDetailActionBar: React.FC<AgentDetailActionBarProps> = ({
  agentInfo,
}) => {
  const [
    showCreateTestTransactionModal,
    setShowCreateTestTransactionModal,
  ] = useState(false);

  return (
    <>
      <div className='ml-auto'>
        <IconButton
          label='Create Test Transaction'
          onClick={() => setShowCreateTestTransactionModal(true)}
        />
      </div>

      <CreateTransaction
        createTransaction={showCreateTestTransactionModal}
        setCreateTransaction={setShowCreateTestTransactionModal}
        agentId={agentInfo.id!}
        licenses={agentInfo.licenses!}
      />
    </>
  );
};

export default AgentDetailActionBar;
