import React from 'react';
import { DateTime } from 'luxon';

export interface MilliDateTimeCellProps {
  date: number | undefined;
}

const MilliDateTimeCell: React.FC<MilliDateTimeCellProps> = ({ date }) => {
  return <span>{date ? DateTime.fromMillis(date).toFormat('f') : 'N/A'}</span>;
};

export default MilliDateTimeCell;
