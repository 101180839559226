import { TransactionLifecycleStateValueStateEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

export interface TransactionStatusSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const TransactionStatusSelectColumnFilter = <D extends object>(
  props: TransactionStatusSelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(TransactionLifecycleStateValueStateEnum)(
    props,
  );
};

export default TransactionStatusSelectColumnFilter;
