import { Action } from 'redux';
import { ThunkAction } from '@reduxjs/toolkit';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { ReactDatePickerProps } from 'react-datepicker';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponseTransactionTypeEnum,
  EscrowResponse,
  TransactionResponse,
  ReferralResponse,
  CommissionDocumentResponse,
  CommissionAdvanceResponse,
  PaymentParticipantValue,
  TransactionsOverviewResponse,
  IncomeOverviewResponse,
  IncomeTotalsResponse,
  AgentResponse as ArrakisAgentResponse,
  EquityOverviewResponse,
} from './openapi/arrakis';
import store from './slices/store';
import {
  InitialSort,
  TableResourceFilter,
} from './containers/ResourceIndexContainer';
import {
  AdministrativeAreaRequestCountryEnum,
  AgentResponse,
  BoardResponse,
  MlsResponse,
  MoneyValue,
  OfficeResponse,
  OfficePreviewResponseTransactionTypeEnum,
  TeamResponse,
  JobExecutionResponse,
} from './openapi/yenta';
import { PageAnnouncementVariant } from './components/PageAnnouncement';
import { UserDetails } from './openapi/keymaker';

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type EnumMap<E extends string, T> = {
  [key in E]: T;
};

export interface Mapping<T> {
  [key: string]: T;
}

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type ButtonType = 'primary' | 'secondary' | 'danger' | 'outline';

export type ButtonSizeType = 'xs' | 'sm' | 'lg' | 'xl';

export type AvatarSizeType = 'xxs' | 'xs' | 'sm' | 'lg' | 'xl';

export interface CardItem {
  name: string;
  subtitle?: string | number;
  value?: string | number;
  url?: string;
}

export interface RootState {
  office: OfficeState;
  mls: MLSState;
  board: BoardState;
  transaction: TransactionState;
  team: TeamState;
  agentDetail: AgentDetailState;
  cdaForm: CDAFormState;
  auth: AuthState;
  toastNotification: ToastNotificationState;
  applicationDetail: ApplicationState;
  network: NetworkState;
  userIds: UserIdsState;
  errorDetails: ErrorState;
  cronJobs: CronJobsState;
}

export type ToastNotificationType = 'info' | 'warning' | 'success' | 'error';

export interface ToastNotificationData {
  type: ToastNotificationType;
  title: string;
  subtitle?: string;
}

export interface ToastNotificationState {
  visible: boolean;
  toastData: ToastNotificationData | undefined;
}

export interface ApiError {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
  trace: string;
}
export interface ErrorState {
  visible: boolean;
  errorData: ApiError | undefined;
}

export interface AuthState {
  loadingUserDetail: boolean;
  userDetail: AgentResponse | null;
  userVerifyInfo: UserDetails | null;
}

export interface CDAFormState {
  cdaFormDetail: CommissionDocumentResponse | null;
  loadingCDAFormDetail: boolean;
  fetchingCDAFormDetailErrorCode: null | ErrorCode;
}

export interface AgentDetailState {
  loadingDetail: boolean;
  detail: AgentResponse | undefined;
  fetchDetailErrorCode: null | ErrorCode;
  loadingReferrals: boolean;
  referrals: ReferralResponse[] | undefined;
  fetchReferralsErrorCode: null | ErrorCode;
  loadingCommissionAdvances: boolean;
  commissionAdvances: CommissionAdvanceResponse[] | undefined;
  fetchCommissionAdvancesErrorCode: null | ErrorCode;
  loadingPerformance: boolean;
  performance: ArrakisAgentResponse | undefined;
  fetchPerformanceErrorCode: null | ErrorCode;
  transactions: TransactionResponse[];
  incomeOverview: IncomeOverviewResponse | undefined;
  incomeChart: IncomeTotalsResponse | undefined;
  equity: EquityOverviewResponse | undefined;
}

export interface ApplicationState {
  loadingDetail: boolean;
  fetchDetailErrorCode: null | ErrorCode;
  applicationDetail: AgentResponse | undefined;
}

export interface MLSState {
  loadingDetail: boolean;
  detail: MlsResponse | undefined;
  fetchDetailErrorCode: null | ErrorCode;
  calendarEvents: CalendarGroup[];
}

export interface BoardState {
  loadingDetail: boolean;
  detail: BoardResponse | undefined;
  fetchDetailErrorCode: null | ErrorCode;
  calendarEvents: CalendarGroup[];
}

export interface OfficeState {
  loadingDetail: boolean;
  officeDetail: OfficeResponse | undefined;
  fetchDetailErrorCode: null | ErrorCode;
  calendarEvents: CalendarGroup[];
  officeDetailById: Mapping<OfficeResponse>;
}

export interface TransactionState {
  loadingTransactionOverview: boolean;
  transactionOverview: ITransactionsOverviewResponse | undefined;
  fetchTransactionOverviewErrorCode: null | ErrorCode;
  loadingTransactionDetail: boolean;
  transactionDetail: TransactionResponse | undefined;
  fetchTransactionDetailErrorCode: null | ErrorCode;
  loadingCommissionParticipants: boolean;
  fetchCommissionParticipantsErrorCode: null | ErrorCode;
  escrowDetails: EscrowResponse | undefined;
  currentTransactionOverview: TransactionsOverviewResponse | undefined;
}

export interface TeamState {
  loading: boolean;
  teamDetailOverview: TeamResponse | null;
  fetchErrorCode: null | ErrorCode;
}

export interface NetworkState {
  loading: boolean;
  networksById: Mapping<NetworkResponse[]>;
  fetchErrorCode: null | ErrorCode;
}

export interface UserIdsState {
  loading: boolean;
  usersById: Mapping<SearchAgentResponse>;
  fetchUsersByIdErrorCode: null | ErrorCode;
}

export interface CronJobsState {
  loading: boolean;
  cronJobs: JobExecutionResponse[] | undefined;
  cronJobHistory: JobExecutionResponse[] | undefined;
  fetchErrorCode: null | ErrorCode;
}

export interface BreadCrumbsPath {
  title: string;
  url: string;
}

export interface ReduxStateFactory<T> {
  getState(state: Partial<T>): T;
}

export type TabsVariant = 'default' | 'spread';

export interface Agent {
  id: number;
  name: string;
  title: string;
  cap: number;
  isActive: boolean;
  imageUrl: string;
}

export interface AgentTransactionCard {
  title: number;
  subtitle: AgentTransactionStatus;
}

export enum AgentTransactionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface IAgent {
  id: number;
  name: string;
  realID: number;
  phone: string;
  address: string;
  primaryEmail: string;
  secondaryEmail: string;
  status: string;
  accountPlan: string;
  licenseNumber: string;
  expiryDate: string;
  imageUrl: string;
  roles?: UserRole[];
}

export enum ActiveInactiveType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TeamType {
  DOMESTIC = 'DOMESTIC',
  NORMAL = 'NORMAL',
  MEGA = 'MEGA',
}

export interface People {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  imageUrl: string;
  capResetOnDate: string;
  status: ActiveInactiveType;
  licenseState: string;
  licenseCity: string;
  createdAt: number;
  updatedAt: number;
}

export interface OfficeIndexResponse {
  id: number;
  principalBroker: PrincipleBroker;
  companyName: string;
  ein: number;
  transactionDisbursementType: OfficePreviewResponseTransactionTypeEnum;
  state: StateAbbreviation;
  address: string;
  totalAgents: number;
}

export interface PrincipleBroker {
  id: number;
  name: string;
  imageUrl: string;
}

export enum TransactionDisbursementType {
  SPLIT_CHECK = 'SPLIT_CHECK',
  SINGLE_CHECK = 'SINGLE_CHECK',
}

export interface TransactionAgent {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Transaction {
  id: number;
  name: string;
  status: TransactionLifecycleStateValueStateEnum;
  type: TransactionResponseTransactionTypeEnum;
  closingDate: string;
  salesPrice: number;
  totalCommission: number;
  participantsSplit: number;
  participantsSplitPercentage: number;
  totalRealSplit: number;
  totalRealSplitPercentage: number;
  agents: TransactionAgent[];
  state: StateAbbreviation;
  teamId: number;
  transactionCode: string;
}

export interface TransactionCDA {
  id: number;
  revisionNumber: number;
  status: TransactionLifecycleStateValueStateEnum;
  closingDate: string;
  createdDate: string;
}

export interface Team {
  id: number;
  name: string;
  type: TeamType;
  totalMembers: number;
  leader: Leader;
  members: Member[];
  status: ActiveInactiveType;
}

export interface Leader {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Member {
  id: number;
  name: string;
  imageUrl: string;
}

export interface IPaginateReq<D> {
  filter?: TableResourceFilter<D>;
  sortBy?: InitialSort<D>;
  page: number;
  pageSize: number;
  search?: string;
}

export interface IPaginateRes<D> {
  data: Array<D>;
  total: number;
}

export interface Avatar {
  imageUrl?: string;
  text?: string;
}

export interface ColoredProgressBarItem {
  value: number;
  className: string;
}

export interface TransactionOverview {
  id: number;
  title: string;
  closingDate: string;
  commission: number;
}

export interface AvatarPillCellItem {
  id: number;
  name: string;
  imageUrl: string;
}

export enum StateAbbreviation {
  AK = 'AK',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  IL = 'IL',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  MO = 'MO',
  NC = 'NC',
  NJ = 'NJ',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  WA = 'WA',
}

export interface MLS {
  id: number;
  name: string;
  logo: string;
  code: string;
  state: StateAbbreviation;
  brokerImage: string;
  brokerName: string;
}

export interface Board {
  id: number;
  name: string;
  logo: string;
  code: string;
  state: StateAbbreviation;
  brokerImage: string;
  brokerName: string;
}

export interface PrimaryBrokerDetail {
  id: number;
  name: string;
  imageUrl: string;
  license: string;
  phone: string;
  email: string;
  bio: string;
  dob: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: StateAbbreviation;
  zip: string;
}

export interface OfficeDetail {
  id: number;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: StateAbbreviation;
  zip: string;
  phone: string;
  fax: string;
  agentsCount: number;
  activeListingsCount: number;
  hudNumber: string;
  mlsOfficeId: string;
  brokerageLicenseId: string;
  primaryBroker: PrimaryBrokerDetail;
  announcementText?: string;
  announcementType?: PageAnnouncementVariant;
}

export interface OfficeDetailResponse {
  office: OfficeDetail;
}
export interface IAgentRow {
  id: string;
  name: string;
  email: string;
  phone: string;
  imageUrl: string;
  zip: string;
}

export interface OfficeAgentsResponse {
  agents: IAgentRow[];
}

export interface ITransactionsOverviewResponse {
  activeListings: number;
  pending: number;
  closed: number;
  openTransactions: TransactionOverview[];
  waitingTransactions: TransactionOverview[];
}

export interface TeamDetailOverviewResponse {
  id: number;
  name: string;
  type: TeamType;
  totalMember: number;
  recentTransactions: TransactionOverview[];
  createdAt: string;
}

export enum RoleType {
  LEADER = 'LEADER',
  MEMBER = 'MEMBER',
}

export interface TeamAgents {
  id: number;
  name: string;
  imageUrl: string;
  role: RoleType;
  status: ActiveInactiveType;
  transactionDisbursementType: TransactionDisbursementType;
  commissionSplit: number;
  joinedAt: string;
}

export enum StateName {
  AK = 'Alaska',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DC = 'District of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  IL = 'Illinois',
  LA = 'Louisiana',
  MA = 'Massachusetts',
  MD = 'Maryland',
  MO = 'Missouri',
  NC = 'North Carolina',
  NJ = 'New Jersey',
  NY = 'New York',
  OH = 'Ohio',
  OK = 'Oklahoma',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VA = 'Virginia',
  WA = 'Washington',
}

export interface Income {
  month: string;
  revenue: number;
}

export interface Commission {
  name: string;
  value: number;
}

export interface RevenueShare {
  name: string;
  value: number;
}

export interface Equity {
  name: string;
  value: number;
}

export interface TeamCardStats {
  id: number;
  name: string;
  split: number;
}

export interface BasicPrimaryBroker {
  id: number;
  name: string;
  imageUrl: string;
  phone: string;
  transactionDisbursementType: TransactionDisbursementType;
}

export interface OfficeCard {
  id: number;
  state: StateName;
  primaryBroker: BasicPrimaryBroker;
}

export interface CommissionCap {
  resetDate: string;
  amount: number;
}

export interface ProfileScore {
  percentage: number;
  incomplete: string[];
}

export interface Language {
  name: string;
}

export interface AgentProfileCard {
  doj: string;
  dob: string;
  desc: string;
  address: string;
  language: Language[];
  bio: string;
}

export interface RealEstateInfo {
  title: string;
  about: string;
  worksWith: WorksWithType;
  licensedStates: StateAbbreviation[];
  zipCodes: string[];
  zipCodeLeads: boolean;
  smsLeads: boolean;
  mls: string;
  priorJoinDateGCI: number;
}

export enum TicketStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export interface Ticket {
  id: number;
  title: string;
  description: string;
  createdBy: { id: number; name: string };
  assignedTo: { id: number; name: string };
  status: TicketStatus;
  createdAt: string;
}

export interface Referral {
  id: number;
  type: ReferralType;
  agentName: string;
  agentEmail: string;
  clientName: string;
  clientEmail: string;
  closingDate: string;
  referralAgreement: string[];
  status: AgentReferralStatus;
}

export interface BasicInfo {
  dob: string;
  description: string;
  joinedDate: string;
  address: string;
  languages: string[];
  bio: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  website: string;
}

export interface AgentDetail {
  agent: IAgent;
  transactionsOverview: AgentTransactionCard[];
  income: Income[];
  commission: Commission[];
  revenueShare: RevenueShare[];
  equity: Equity[];
  teams: TeamCardStats[];
  office: OfficeCard[];
  commissionCap: CommissionCap;
  profileScore: ProfileScore;
  basicInfo: BasicInfo;
  realEstateInfo: RealEstateInfo;
  tickets: Ticket[];
}

export enum MLSAndBoardType {
  MLS = 'MLS',
  BOARD = 'BOARD',
}

export interface MLSDetail {
  id: number;
  name: string;
  logo: string;
  state: StateAbbreviation;
  phone: string;
  email: string;
  agentsCount: number;
  activeListingsCount: number;
  code: string;
  registrationFee: number;
  joinDate: string;
  status: ActiveInactiveType;
  primaryBroker: PrimaryBrokerDetail;
}

export interface BoardDetail {
  id: number;
  name: string;
  logo: string;
  state: StateAbbreviation;
  phone: string;
  email: string;
  agentsCount: number;
  activeListingsCount: number;
  code: string;
  registrationFee: number;
  joinDate: string;
  status: ActiveInactiveType;
  primaryBroker: PrimaryBrokerDetail;
}

export interface MLSDetailResponse {
  mls: MLSDetail;
}

export interface BoardDetailResponse {
  boards: BoardDetail;
}

export interface MlsAndBoardAgentsResponse {
  agents: IAgentRow[];
}

export enum ParticipantType {
  AGENT = 'AGENT',
  EXTERNAL_ENTITY = 'EXTERNAL_ENTITY',
}

export enum ParticipantRole {
  SELLERS_AGENT = 'SELLERS_AGENT',
  BUYERS_AGENT = 'BUYERS_AGENT',
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  CLOSER = 'CLOSER',
}

export interface Participant {
  id: string;
  name: string;
  imageUrl: string;
  status: TransactionLifecycleStateValueStateEnum;
  type: ParticipantType;
  role: ParticipantRole;
  payment: Payment | null;
}

export interface Payment {
  commissionSplit: number;
  totalCommission: number;
  realSplit: number;
  realStocksValue: number;
  realPersonalDealFee: number;
  transactionFee: number;
  brokerageFee: number;
  mlsFee: number;
  clientRebate: number;
  netPayout: number;
}

export interface CommissionSplit {
  name: string;
  commission: number;
  subtitle: number;
}

export interface CoordinatorInformation {
  id: number;
  name: string;
  email: string;
  phone: string;
}
export interface TransactionsDetail {
  id: number;
  participants: Participant[];
}

export interface EscrowAccountDetails {
  escrowAccountDetail: string;
  escrowAccountNumber: number;
  escrowRoutingNumber: number;
}

export enum AccountPlanType {
  STANDARD = 'STANDARD',
  POWER = 'POWER',
  ENTERPRICE = 'ENTERPRICE',
}

export interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export enum DateFilterType {
  inTheLast = 'inTheLast',
  equals = 'equals',
  between = 'between',
  isAfter = 'isAfter',
  isAfterOrOn = 'isAfterOrOn',
  isBefore = 'isBefore',
  isBeforeOrOn = 'isBeforeOrOn',
}

export enum DateFilterTypeLabel {
  inTheLast = 'is in the last',
  equals = 'is equal to',
  between = 'is between',
  isAfter = 'is after',
  isAfterOrOn = 'is on or after',
  isBefore = 'is before',
  isBeforeOrOn = 'is before or on',
}

export enum NumberFilterType {
  equals = 'equals',
  between = 'between',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
}

export enum NumberFilterTypeLabel {
  equals = 'is equal to',
  between = 'is between',
  greaterThan = 'is greater than',
  greaterThanOrEqual = 'is greater than or equal to',
  lessThan = 'is less than',
  lessThanOrEqual = 'is less than  or equal to',
}

export enum FilterColumnsToProcess {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  ENUM = 'ENUM',
  STRICT_CASE = 'STRICT_CASE',
  BOOLEAN = 'BOOLEAN',
}

export interface CalendarItem {
  title: string;
  startDate: string;
  actionText: string;
}

export interface CalendarGroup {
  day: string;
  events: CalendarItem[];
}

export interface GetCalendarEventsResponse {
  events: CalendarItem[];
}

export interface AsyncSelectOption {
  label: string;
  value: string;
}

export interface AsyncSelectAdditional {
  page: number;
}

export enum FilterOperation {
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  eq = 'eq',
}

export interface BillingInformation {
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
}

export interface ShippingInformation {
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
}

export interface PaymentDetails {
  description: string;
  quantity: number;
  unitPrice: number;
  amount: number;
}

export interface BankInformation {
  bankName: string;
  accountName: string;
  routingNumber: string;
  accountNumber: string;
  swiftCode?: string;
}

export interface InvoiceResponse {
  id: number;
  invoiceNumber: string;
  issuedAt: string;
  dueAt: string;
  propertyAddress: string;
  billingInformation: BillingInformation;
  shippingInformation: ShippingInformation;
  tenantName: string;
  monthlyRent: number;
  moveInAt: string;
  paymentDetails: PaymentDetails[];
  subtotal: number;
  amountDue: number;
  bankInformation: BankInformation;
}

export enum PayeeRole {
  AGENT = 'AGENT',
  BROKER = 'BROKER',
}

export interface Payees {
  name: string;
  role: PayeeRole;
  license: string;
  email: string;
  phoneNumber: string;
  paymentInstructions: string;
  bankInformation: BankInformation | null;
  payout: number;
  officeAddress: string;
}

export enum RepresentingRole {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export interface CDAForm {
  transactionId: number;
  propertyAddress: string;
  estimatedClosingDate: string;
  salesPrice: number;
  representingRole: RepresentingRole;
  buyerName: string;
  sellerName: string;
  buyerCommissionAmount: number;
  sellerCommissionAmount: number;
  totalCommission: number;
  payees: Array<Payees>;
  createdAt: string;
  revisionNumber: number;
  approvedAt: string;
  comment: string;
  qrCodeUrl: string;
}

export interface CDAFormResponse {
  cdaForm: CDAForm;
}

export interface FormFieldConfig {
  label?: string;
  secondaryLabel?: string;
  helperText?: string;
  placeholder?: string;
  type: InputFieldType;
  name: string;
  reviewLabel?: string;
  options?: Array<ISelectOption>;
  defaultValue?: string;
  naMessage?: string;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  datePickerConfig?: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur'
  >;

  resolveFieldDisplayValue?: (value: any) => any;
  convertValueBeforeSend?: (value: any, formData: any) => any;
}

export enum InputFieldType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  SELECT = 'SELECT',
  MULTISELECT = 'MULTISELECT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  PHONE = 'PHONE',
}

export enum YesNoType {
  YES = 'YES',
  NO = 'NO',
}

export interface onBoardingStepType {
  name: string;
  subtitle?: string;
  action?: {
    buttonText: string;
    onClick(): void;
  };
}

export interface DownloadStateLicenseResponse {
  id: number;
  url: string;
}

export interface FAQType {
  question: string;
  answer: string;
}

export enum WorksWithType {
  BUYERS = 'BUYERS',
  SELLERS = 'SELLERS',
  BUYERS_SELLERS = 'BUYERS_SELLERS',
}

export enum LanguageType {
  ARABIC = 'ARABIC',
  ARMENIAN = 'ARMENIAN',
  BENGALI = 'BENGALI',
  BOSNIAN = 'BOSNIAN',
  CANTONESE = 'CANTONESE',
  CROATIAN = 'CROATIAN',
  ENGLISH = 'ENGLISH',
  FARSI = 'FARSI',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  GREEK = 'GREEK',
  GUJARATI = 'GUJARATI',
  HAITIAN_CREOLE = 'HAITIAN_CREOLE',
  HEBREW = 'HEBREW',
  HINDI = 'HINDI',
  HMONG = 'HMONG',
  ITALIAN = 'ITALIAN',
  JAPANESE = 'JAPANESE',
  KHMER = 'KHMER',
  KOREAN = 'KOREAN',
  MANDARIN = 'MANDARIN',
  MONTENEGRIN = 'MONTENEGRIN',
  NAVAJO = 'NAVAJO',
  POLISH = 'POLISH',
  PORTUGUESE = 'PORTUGUESE',
  PUNJABI = 'PUNJABI',
  RUSSIAN = 'RUSSIAN',
  SERBIAN = 'SERBIAN',
  SPANISH = 'SPANISH',
  SWAHILI = 'SWAHILI',
  TAGALOG = 'TAGALOG',
  TAI_KADAI = 'TAI_KADAI',
  TAMIL = 'TAMIL',
  TELUGU = 'TELUGU',
  URDU = 'URDU',
  VIETNAMESE = 'VIETNAMESE',
  YIDDISH = 'YIDDISH',
}

export type CountryMapStateOptions = {
  [c in AdministrativeAreaRequestCountryEnum]: ISelectOption[];
};

export type ParticipantUpdateCommissionRequest = PaymentParticipantValue &
  SearchAgentResponse;

export type Transition = TransactionLifecycleStateValueStateEnum;

export interface Escrow {
  id: number;
  amount: number;
  date: string;
  status: TransactionLifecycleStateValueStateEnum;
  updatedBy: {
    name: string;
    photo: string;
  };
  info: string;
}

export enum ErrorCode {
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
}

export enum AgentReferralStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}

export enum ReferralType {
  REAL_AGENT = 'REAL_AGENT',
  EXTERNAL_AGENT = 'EXTERNAL_AGENT',
}

export enum AgentCommissionAdvanceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REPAID = 'REPAID',
}

export interface AgentCommissionAdvance {
  id: number;
  amount: MoneyValue;
  nameOfCompany: string;
  email: string;
  agreementUrl: string;
  status: AgentCommissionAdvanceStatus;
}

export interface AgentCommissionAdvancesResponse {
  commissionAdvances: AgentCommissionAdvance[];
}

export interface NetworkResponse {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string | null;
  sizeOfNetwork: number;
}

export interface BreadCrumbsNoUrlType {
  title: string;
  onClick?(): void;
}

export interface SearchAgentResponse {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string | null;
  emailAddress: string;
  phoneNumber: string;
}

export type SearchAPIType = 'yenta' | 'arrakis';

export enum FeatureFlagTypeEnum {
  BASIC_FEATURE = 'BASIC_FEATURE',
  ADMIN_FEATURE = 'ADMIN_FEATURE',
}

export interface FeatureFlagRow {
  name: string;
  subtitle: string;
}

export enum EventStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum InvestigationStatus {
  UNSTARTED = 'UNSTARTED',
  STARTED = 'STARTED',
  GAVE_UP = 'GAVE_UP',
  DONE = 'DONE',
}
export interface MonitorEventsResponse {
  status: EventStatus;
  investigationStatus: InvestigationStatus;
  eventType: string;
  objectType: string;
  objectId: string;
  retryCount: number;
  triggeredBy: string;
  message: string;
  notes: string;
}

export enum CanadianStates {
  Alberta = 'ALBERTA',
  BritishColumbia = 'BRITISH_COLUMBIA',
  Manitoba = 'MANITOBA',
  NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
  NewBrunswick = 'NEW_BRUNSWICK',
  NovaScotia = 'NOVA_SCOTIA',
  Ontario = 'ONTARIO',
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  Quebec = 'QUEBEC',
  Saskatchewan = 'SASKATCHEWAN',
}

export enum UnitedStates {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  NovaScotia = 'NOVA_SCOTIA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  PuertoRico = 'PUERTO_RICO',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
}
