import React from 'react';
import Button from './Button';

export interface ApplicationStepCompleteMessageProps {
  icon: React.ReactElement;
  header: string;
  subtitle?: string;
  nextStepName?: string;
  onNextStepClicked?: () => void;
}

const ApplicationStepCompleteMessage: React.FC<ApplicationStepCompleteMessageProps> = ({
  icon,
  header,
  subtitle,
  nextStepName,
  onNextStepClicked,
}) => {
  return (
    <div>
      <div className='h-14 w-14 flex items-center justify-center rounded-full bg-success text-white'>
        {icon}
      </div>
      <p className='mt-4 text-3xl lg:text-4xl font-primary-medium'>{header}</p>
      {subtitle && <p className='mt-2.5 text-gray-400 leading-6'>{subtitle}</p>}
      {nextStepName && (
        <>
          <p className='mt-4 mb-1 text-gray-500 text-2xs font-primary-medium tracking-wider uppercase'>
            Next Step
          </p>
          <Button
            size='lg'
            label={nextStepName}
            onClick={onNextStepClicked}
            className='w-full lg:w-auto'
          />
        </>
      )}
    </div>
  );
};

export default ApplicationStepCompleteMessage;
