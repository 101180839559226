import React from 'react';
import qs from 'qs';
import { Check } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ApplicationStepCompleteMessage from '../../ApplicationStepCompleteMessage';
import ApplicationLayout from '../../ApplicationLayout';
import { RootState } from '../../../types';
import ApplicationVerticalStepProgress from '../ApplicationVerticalStepProgress';

export interface ApplicationFormSuccessProps extends RouteComponentProps {}

const ApplicationFormSuccess: React.FC<ApplicationFormSuccessProps> = ({
  history,
  location,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const { sponsorCode, sponsorName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  return (
    <ApplicationLayout>
      <div className='w-full mx-auto px-4 my-10 lg:max-w-6xl gap-10 grid grid-cols-1 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          <ApplicationStepCompleteMessage
            icon={<Check fontSize='large' />}
            header={`Thank you, ${userDetail?.firstName} for filling out the application form.`}
            subtitle='Up next, we will ask you to sign an independent contractor agreement.'
            nextStepName='Sign Agreement'
            onNextStepClicked={() => {
              history.push(
                `/onboarding/application-ica-signing?${qs.stringify({
                  sponsorCode,
                  sponsorName,
                })}`,
              );
            }}
          />
        </div>
        <div className='lg:col-span-2'>
          <ApplicationVerticalStepProgress />
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationFormSuccess;
