import React from 'react';
import classNames from 'classnames';

export interface IconTextProps {
  icon: React.ReactElement;
  text: string;
  lineClamp?: number;
}

const IconText: React.FC<IconTextProps> = ({ icon, text, lineClamp }) => {
  return (
    <div className='flex flex-row items-start flex-nowrap'>
      {icon}
      <p
        className={classNames(
          'pl-1 font-primary-light',
          lineClamp ? `line-clamp-${lineClamp}` : 'line-clamp-none',
        )}
      >
        {text}
      </p>
    </div>
  );
};

export default IconText;
