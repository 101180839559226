import React, { useState } from 'react';
import { Column, Row } from 'react-table';
import qs from 'qs';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import PageLayoutWithSearch from '../components/PageLayoutWithSearch';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import { SearchRequestTargetObjectEnum, TeamResponse } from '../openapi/yenta';
import { resourceTableFetchData } from '../utils/TableUtils';
import MilliDateCell from '../components/table/Cells/MilliDateCell';
import DateColumnFilter from '../components/table/Filters/DateColumnFilter';
import IconButton from '../components/IconButton';
import CreateTeamForm from '../components/team/CreateTeamForm';
import TeamTypeCell from '../components/table/Cells/TeamTypeCell';
import TeamTypeSelectColumnFilter from '../components/table/Filters/TeamTypeSelectColumnFilter';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';

interface TeamTableContainerProps extends RouteComponentProps {}

export const columns: Array<Column<TeamResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <TeamTypeCell type={value!} />,
    Filter: TeamTypeSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => <MilliDateCell date={value!} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
  },
];

export const columnsWithAction: Array<Column<TeamResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<TeamResponse> }) => (
      <Link to={`/teams/${row.original.id}`}>
        <IconButton leftIcon={<VisibilityIcon titleAccess='View' />} />
      </Link>
    ),
  },
  ...columns,
];

export const columnsToFetch = columns.map((col) => col.accessor as string);
const columnsForSearch = ['name'];

const TeamSearchRoute: React.FC<TeamTableContainerProps> = ({ location }) => {
  const { term, type } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [search, setSearch] = useState<string | undefined>(
    typeof term === 'string' ? term : undefined,
  );
  const [openCreateTeamForm, setOpenCreateTeamForm] = useState<boolean>(false);

  return (
    <PageLayoutWithSearch
      path={[
        { title: 'Home', url: '/' },
        { title: 'Team', url: '/teams' },
      ]}
      search={search}
      onSearchChange={setSearch}
    >
      <div className='px-4 lg:pt-5'>
        <ResourceIndexContainer<TeamResponse>
          header='Teams'
          defaultSelectedTabName={typeof type === 'string' ? type : undefined}
          // tabs={[
          //   {
          //     name: 'All',
          //   },
          // {
          //   name: 'Normal',
          //   filter: {
          //     type: {
          //       column: 'type',
          //       value: TeamType.NORMAL,
          //       operator: SearchParamOperatorEnum.Eq,
          //     },
          //   },
          // },
          // {
          //   name: 'Domestic',
          //   filter: {
          //     type: {
          //       column: 'type',
          //       value: TeamType.DOMESTIC,
          //       operator: SearchParamOperatorEnum.Eq,
          //     },
          //   },
          // },
          // {
          //   name: 'Mega',
          //   filter: {
          //     type: {
          //       column: 'type',
          //       value: TeamType.MEGA,
          //       operator: SearchParamOperatorEnum.Eq,
          //     },
          //   },
          // },
          // ]}
          columns={columnsWithAction}
          search={search}
          initialSort={{ name: 'asc' }}
          fetchData={(req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              columnsForSearch,
              SearchRequestTargetObjectEnum.Team,
              'yenta',
            )
          }
          resourceName='team'
          RightComponent={
            <IconButton
              leftIcon={<Add fontSize='small' />}
              label='Create a Team'
              variant='default'
              onClick={() => setOpenCreateTeamForm(true)}
            />
          }
        />
      </div>
      <CreateTeamForm
        isOpen={openCreateTeamForm}
        onClose={() => setOpenCreateTeamForm(false)}
      />
    </PageLayoutWithSearch>
  );
};

export default TeamSearchRoute;
