import React from 'react';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BarChartSharpIcon from '@material-ui/icons/BarChartSharp';
import { AgentDetailState } from '../../types';
import ResourceContainer from '../ResourceContainer';
import SectionHeader from '../SectionHeader';
import DataTable from '../DataTable';
import DataRow from '../DataRow';
import { numberToMoney } from '../../utils/CurrencyUtils';

interface AgentPerformanceSectionProps {
  agentDetail: AgentDetailState;
}

const AgentPerformanceSection: React.FC<AgentPerformanceSectionProps> = ({
  agentDetail,
}) => {
  return (
    <div>
      <SectionHeader
        icon={<BarChartSharpIcon fontSize='small' />}
        title='Agent Performance'
      />
      <ResourceContainer
        loading={agentDetail.loadingPerformance}
        isEmpty={!agentDetail.performance}
        errorCode={agentDetail.fetchPerformanceErrorCode}
        resourceName='performance'
        emptyIcon={<TrendingUpIcon fontSize='large' />}
      >
        <div className='mt-2'>
          <DataTable>
            <DataRow
              name='Pre Cap Equity Contribution'
              value={numberToMoney(
                agentDetail.performance?.preCapEquityContribution?.amount!,
              )}
            />
            <DataRow
              name='Post Cap Equity Contribution'
              value={numberToMoney(
                agentDetail.performance?.postCapEquityContribution?.amount!,
              )}
            />
            <DataRow
              name='Post Cap Transaction Fees Paid'
              value={numberToMoney(
                agentDetail.performance?.postCapTransactionFeesPaid?.amount!,
              )}
            />
            <DataRow
              name='Personal Deals Count'
              value={agentDetail.performance?.personalDealsCount}
            />
          </DataTable>
        </div>
      </ResourceContainer>
    </div>
  );
};

export default AgentPerformanceSection;
