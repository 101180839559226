import React from 'react';
import { TeamMemberResponseRolesEnum } from '../../../openapi/yenta';
import Crown from '../../Icons/Crown';

export interface TeamAgentNameCellProps {
  name: string;
  role: TeamMemberResponseRolesEnum;
}

const TeamAgentNameCell: React.FC<TeamAgentNameCellProps> = ({
  name,
  role,
}) => {
  return (
    <div className='flex item-center'>
      <span className='mr-2'>{name}</span>{' '}
      {role === TeamMemberResponseRolesEnum.Leader && <Crown length={22} />}
    </div>
  );
};

export default TeamAgentNameCell;
