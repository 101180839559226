import { FeatureFlagTypeEnum } from '../types';

export default class FeatureFlagService {
  static readonly featureFlagKey: string = 'featureFlagsEnabled';

  static getAllFeatureFlags(): FeatureFlagTypeEnum[] {
    const flags = window.localStorage.getItem(
      FeatureFlagService.featureFlagKey,
    );
    return flags ? JSON.parse(flags) : [];
  }

  static setAllFeatureFlags(flag: FeatureFlagTypeEnum[]) {
    return window.localStorage.setItem(
      FeatureFlagService.featureFlagKey,
      JSON.stringify(flag),
    );
  }

  static clear() {
    return window.localStorage.removeItem(FeatureFlagService.featureFlagKey);
  }

  static isAvailable(flag: FeatureFlagTypeEnum) {
    const flagsEnabled = FeatureFlagService.getAllFeatureFlags();
    return flagsEnabled.includes(flag);
  }

  static enable(flag: FeatureFlagTypeEnum) {
    const flagsEnabled = FeatureFlagService.getAllFeatureFlags();
    if (!flagsEnabled.includes(flag)) {
      FeatureFlagService.setAllFeatureFlags([...flagsEnabled, flag]);
    }
  }

  static disable(flag: FeatureFlagTypeEnum) {
    const flagsEnabled = FeatureFlagService.getAllFeatureFlags();
    if (flagsEnabled.includes(flag)) {
      FeatureFlagService.setAllFeatureFlags(
        flagsEnabled.filter((f) => f !== flag),
      );
    }
  }
}
