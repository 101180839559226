import { Route, RouteComponentProps } from 'react-router-dom';
import PageLayout from '../../components/PageLayout';
import Card from '../components/Card';
import {
  ExperimentalProject,
  Projects,
} from '../utils/ExperimentalProjectUtils';

interface Match {}

interface ExperimentalRouteProps extends RouteComponentProps<Match> {}

const ExperimentalRoute: React.FC<ExperimentalRouteProps> = ({ location }) => {
  const path = [
    { title: 'Home', url: '/' },
    { title: 'Experimental', url: '/experimental' },
  ];

  Projects.forEach((project: ExperimentalProject) => {
    if (location.pathname === project.link) {
      path.push({ title: project.title, url: project.link });
    }
  });

  return (
    <PageLayout path={path}>
      <Route path='/experimental' exact>
        <div className='px-10 lg:py-10'>
          <div>
            <span className='text-4xl'>Experimental Features</span>
            <hr className='my-3' />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 grid-flow-row p-4'>
            {Projects.map((project: ExperimentalProject) => (
              <Card
                title={project.title}
                description={project.desc}
                imgSrc={project.imgSrc}
                link={project.link}
                key={project.title}
              />
            ))}
          </div>
        </div>
      </Route>

      {Projects.map((project: ExperimentalProject) => (
        <Route
          path={project.link}
          component={project.component}
          key={project.id}
          exact
        />
      ))}
    </PageLayout>
  );
};

export default ExperimentalRoute;
