import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ExitToApp, InfoOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import ControlledTextInput from '../components/ControlledTextInput';
import { AuthControllerApi, LoginRequest } from '../openapi/keymaker';
import Alert from '../components/Alert';
import SubmitButton from '../components/SubmitButton';
import { setAuthCookie } from '../utils/AuthUtils';
import { fetchAuthUserDetail } from '../slices/AuthSlice';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';

interface LoginRouteProps {}

const LoginRoute: React.FC<LoginRouteProps> = () => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();

  const onSubmit = async (values: LoginRequest) => {
    try {
      const api = new AuthControllerApi(getKeymakerConfiguration());
      const { data } = await api.authenticateUserUsingPOST(values);
      setAuthCookie(data.accessToken!);
      dispatch(fetchAuthUserDetail());
    } catch (e) {
      setError('submit', {
        message: e.response.data.errorMessage,
      });
    }
  };

  if (isSubmitSuccessful) {
    return <Redirect to='/' />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='bg-gray-100'>
        <div className='container mx-auto h-screen flex items-center justify-center'>
          <div className='bg-white lg:w-1/3 p-10 border-2 border-gray-100'>
            <div className='text-center -mt-20 mb-5'>
              <div className='inline-block rounded-full p-6 bg-dark text-white'>
                <ExitToApp fontSize='large' />
              </div>
            </div>
            <p className='font-primary-medium text-3xl text-center'>Login</p>
            {errors.submit && (
              <div className='mt-3'>
                <Alert
                  text={errors.submit.message}
                  variant='error'
                  icon={<InfoOutlined />}
                />
              </div>
            )}
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                label='Email / Username'
                name='usernameOrEmail'
                placeholder='Enter username or email'
                errors={errors}
                rules={{
                  required: 'Email or username required',
                }}
              />
            </div>
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                type='password'
                label='Password'
                name='password'
                placeholder='Password'
                errors={errors}
                rules={{
                  required: 'Password required',
                }}
              />
            </div>
            <SubmitButton
              label='Login'
              isSubmitting={isSubmitting}
              onClick={() => {
                if (errors.submit) {
                  clearErrors('submit');
                }
              }}
            />
            <div className='flex justify-between'>
              <Link to='/register' className='hover:underline'>
                <p className='text-sm text-right mt-3'>Join Real</p>
              </Link>
              <Link to='/reset-password' className='hover:underline'>
                <p className='text-sm text-right mt-3'>Forgot Password?</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginRoute;
