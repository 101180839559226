import React from 'react';
import { DateTime } from 'luxon';
import {
  CheckBoxOutlined,
  Info,
  Mic,
  People,
  LocationOn,
  PermContactCalendar,
  Phone,
  EditOutlined,
  Work,
  LocationCity,
  LocationOnRounded,
} from '@material-ui/icons';
import classNames from 'classnames';
import { flatten, last } from 'lodash';
import { SvgIcon } from '@material-ui/core';
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillYoutube,
  AiFillLinkedin,
  AiFillGoogleCircle,
  AiOutlineGlobal,
} from 'react-icons/ai';
import { IoMdMicrophone } from 'react-icons/io';
import { FaYelp } from 'react-icons/fa';
import { SiZillow } from 'react-icons/si';
import Card from '../Card';
import IconText from '../IconText';
import {
  capitalizeEnum,
  formatAddressObject,
  formatPhoneNumber,
} from '../../utils/StringUtils';
import SectionHeader from '../SectionHeader';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg';
import { AgentResponse } from '../../openapi/yenta';
import { hasSocialMediaLinks } from '../../utils/AgentHelper';

interface BasicInformationCardProps {
  agent: AgentResponse;
  setOpenEditAgentDetailsSideModal(val: boolean): void;
}

const BasicInformationCard: React.FC<BasicInformationCardProps> = ({
  agent,
  setOpenEditAgentDetailsSideModal,
}) => {
  return (
    <div>
      <SectionHeader
        icon={<SvgIcon component={InfoIcon} fontSize='small' />}
        title='Basic Information'
        actionIcon={<EditOutlined fontSize='small' />}
        actionText='Edit'
        onActionClick={() => setOpenEditAgentDetailsSideModal(true)}
      />
      <div className='mt-2'>
        <Card>
          <div className='flex flex-col divide-y'>
            <span className='px-4 py-1'>
              <IconText
                icon={<CheckBoxOutlined fontSize='small' />}
                text={
                  agent.birthDate
                    ? `Birthday is ${DateTime.fromISO(agent.birthDate).toFormat(
                        'MMMM d',
                      )}`
                    : 'N/A'
                }
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<PermContactCalendar fontSize='small' />}
                text={
                  agent.createdAt
                    ? `Joined Real in ${DateTime.fromMillis(
                        agent.createdAt,
                      ).toFormat('MMMM yyyy')}`
                    : 'N/A'
                }
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<People fontSize='small' />}
                text={agent.title || 'N/A'}
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<Phone fontSize='small' />}
                text={formatPhoneNumber(agent.phoneNumber) || 'N/A'}
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<LocationOn fontSize='small' />}
                text={
                  !!agent.addresses?.length
                    ? formatAddressObject(last(agent.addresses)!)
                    : 'N/A'
                }
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<Mic fontSize='small' />}
                text={
                  !!agent.languages?.length
                    ? `Speaks ${agent.languages
                        ?.map((language) => capitalizeEnum(language))
                        .join(', ')}`
                    : 'N/A'
                }
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<Info fontSize='small' />}
                text={agent.about || 'N/A'}
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<Work fontSize='small' />}
                text={
                  agent.competencies
                    ?.map((c) => capitalizeEnum(c))
                    .join(', ') || 'N/A'
                }
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<LocationCity fontSize='small' />}
                text={
                  flatten(
                    agent!.applications!.map((a) =>
                      a.doesBusinessIn!.map((b) =>
                        capitalizeEnum(b.administrativeArea!.stateOrProvince!),
                      ),
                    ),
                  ).join(', ') || 'N/A'
                }
              />
            </span>
            <span className='px-4 py-1'>
              <IconText
                icon={<LocationOnRounded fontSize='small' />}
                text={agent.serviceAreaZipcodes! || 'N/A'}
              />
            </span>

            <div
              className={classNames(
                'grid justify-items-start px-4 grid-flow-col visible',
                !hasSocialMediaLinks(agent) && 'hidden',
              )}
            >
              {agent.facebookURL && (
                <a
                  href={agent.facebookURL}
                  className='py-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiFillFacebook size={20} />
                </a>
              )}
              {agent.twitterURL && (
                <a
                  href={agent.twitterURL}
                  className='py-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiOutlineTwitter size={20} />
                </a>
              )}
              {agent.instagramURL && (
                <a
                  href={agent.instagramURL}
                  className='py-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiFillInstagram size={20} />
                </a>
              )}
              {agent.youtubeURL && (
                <a
                  href={agent.youtubeURL}
                  className='py-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiFillYoutube size={20} />
                </a>
              )}
              {agent.personalWebsiteURL && (
                <a
                  href={agent.personalWebsiteURL}
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiOutlineGlobal size={20} />
                </a>
              )}
              {agent.clubhouseURL && (
                <a
                  href={agent.clubhouseURL}
                  className='py-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  <IoMdMicrophone size={20} />
                </a>
              )}
              {agent.googleBusinessProfileURL && (
                <a
                  href={agent.googleBusinessProfileURL}
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiFillGoogleCircle size={20} />
                </a>
              )}
              {agent.linkedInURL && (
                <a
                  href={agent.linkedInURL}
                  className='py-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiFillLinkedin size={20} />
                </a>
              )}
              {agent.yelpURL && (
                <a
                  href={agent.yelpURL}
                  className='py-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaYelp size={20} />
                </a>
              )}
              {agent.zillowURL && (
                <a
                  href={agent.zillowURL}
                  className='py-1'
                  target='_blank'
                  rel='noreferrer'
                >
                  <SiZillow size={20} />
                </a>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default BasicInformationCard;
