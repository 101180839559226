import { DateTime } from 'luxon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeApiErrorModal } from '../slices/ErrorSlice';
import { RootState } from '../types';
import Button from './Button';
import ConfirmationModal from './ConfirmationModal';
import CopyToClipboard from './CopyToClipboard';

interface ApiErrorModalProps {}

const ApiErrorModal: React.FC<ApiErrorModalProps> = () => {
  const dispatch = useDispatch();
  const { visible, errorData } = useSelector(
    (state: RootState) => state.errorDetails,
  );

  const onClose = () => dispatch(closeApiErrorModal());

  if (!visible || !errorData) {
    return null;
  }
  console.log(errorData);

  return (
    <ConfirmationModal
      title='Api Error'
      subtitle={`Error Code: ${errorData.status}`}
      isOpen={visible}
      onClose={onClose}
      variant='error'
    >
      <div className='flex justify-between'>
        <p className='text-gray-500 font-primary-light'>Error</p>
        <CopyToClipboard
          value={JSON.stringify(errorData)}
          label='Copy Error Data'
        />
      </div>
      <div className='flex flex-col space-y-2'>
        <p> {errorData.error}</p>
        <div>
          <p className='text-gray-500 font-primary-light'>Message</p>
          <p> {errorData.message}</p>
        </div>
        <div>
          <p className='text-gray-500 font-primary-light'>Path</p>
          <p>{errorData.path}</p>
        </div>
        <div>
          <p className='text-gray-500 font-primary-light'>TimeStamp</p>
          <p>
            {DateTime.fromISO(errorData.timestamp).toLocaleString(
              DateTime.DATETIME_FULL_WITH_SECONDS,
            )}
          </p>
        </div>
        <div>
          <p className='text-gray-500 font-primary-light'>Trace</p>
          <p className='overflow-y-scroll h-52 break-all'>{errorData.trace}</p>
        </div>
      </div>
      <div className='mt-4 flex justify-end'>
        <Button label='Close' type='secondary' onClick={onClose} />
      </div>
    </ConfirmationModal>
  );
};

export default ApiErrorModal;
