import React from 'react';
import { useSelector } from 'react-redux';
import { SearchParamOperatorEnum } from '../openapi/arrakis';
import { RootState } from '../types';
import OutgoingPaymentsComponent from '../components/outgoingPayment/OugoingPaymentsComponent';

interface TransactionOutgoingPaymentsRouteProps {}

const TransactionOutgoingPaymentRoute: React.FC<TransactionOutgoingPaymentsRouteProps> = () => {
  const { transactionDetail } = useSelector(
    (state: RootState) => state.transaction,
  );

  return (
    <div className='px-4 lg:py-5'>
      <OutgoingPaymentsComponent
        searchDefaults={{
          filterAnd: [
            {
              column: 'transactionCode',
              operator: SearchParamOperatorEnum.Eq,
              value: transactionDetail!.code,
            },
          ],
        }}
      />
    </div>
  );
};

export default TransactionOutgoingPaymentRoute;
