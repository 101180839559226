import React from 'react';

interface SideBarMenuItemProps {
  icon: React.ReactElement;
  label: string;
}

const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({ icon, label }) => {
  return (
    <div className='flex flex-row py-2 my-1 hover:bg-trueGray-700 px-4 rounded-lg'>
      <span className='text-trueGray-300 pr-2'>{icon}</span>
      <span className='text-white text-base'>{label}</span>
    </div>
  );
};

export default SideBarMenuItem;
