import React from 'react';

interface ControlDisplayItemsProps {
  itemsToShow: number;
  itemsOptions?: number[];
  setPageSize(size: number): void;
}

const ControlDisplayItems: React.FC<ControlDisplayItemsProps> = ({
  itemsToShow,
  itemsOptions = [10, 20, 50, 75, 100],
  setPageSize,
}) => {
  return (
    <div className='flex items-center'>
      <label htmlFor='pageSize'>Showing</label>
      <select
        id='pageSize'
        value={itemsToShow}
        onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
        className='block focus:ring-0 border-none bg-trueGray-100 mx-2'
      >
        {itemsOptions.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ControlDisplayItems;
