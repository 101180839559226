import React from 'react';
import { FilterProps } from 'react-table';
import ControlledSwitchInput from '../../../ControlledSwitchInput';
import { PROCESS_FILTER_COLUMN } from '../../../../utils/TableUtils';
import { FilterColumnsToProcess } from '../../../../types';

export interface SwitchColumnFilterProps<D extends object>
  extends FilterProps<D> {
  label: string;
}

const SwitchColumnFilter = <D extends object>({
  column,
  control,
  errors,
  label,
}: SwitchColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}[${FilterColumnsToProcess.BOOLEAN}][${column.id}]`;

  return (
    <ControlledSwitchInput
      control={control}
      name={prefixFieldName}
      errors={errors}
      label={label}
    />
  );
};

export default SwitchColumnFilter;
