import React from 'react';

interface NullableTextCellProps {
  text: string | undefined | null;
}

const NullableTextCell: React.FC<NullableTextCellProps> = ({ text }) => (
  <span>{text || 'N/A'}</span>
);

export default NullableTextCell;
