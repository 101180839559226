import React, { useCallback } from 'react';
import { Column, Row } from 'react-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch } from 'react-redux';
import DateCell from '../components/table/Cells/DateCell';
import DateColumnFilter from '../components/table/Filters/DateColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import { resourceTableFetchData } from '../utils/TableUtils';
import {
  CommissionDocumentResponse,
  SearchRequestTargetObjectEnum,
} from '../openapi/arrakis';
import { SearchParamOperatorEnum } from '../openapi/yenta';
import CommissionDocumentStatusCell from '../components/table/Cells/CommissionDocumentResponseStatusCell';
import CommissionDocumentResponseStatusSelectColumnFilter from '../components/table/Filters/CommissionDocumentResponseStatusSelectColumnFilter';
import MilliDateCell from '../components/table/Cells/MilliDateCell';
import IconButton from '../components/IconButton';
import Button from '../components/Button';
import { AppDispatch } from '../types';
import { downloadCDAForm } from '../slices/TransactionSlice';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';

interface TransactionCDARouteProps {
  transactionId: string;
  headerName: string;
  getUrl(id: string): string;
}

export const columns: Array<Column<CommissionDocumentResponse>> = [
  {
    Header: '#',
    accessor: 'id',
    Filter: TextStrictCaseColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Revision Number',
    accessor: 'revision',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <CommissionDocumentStatusCell status={value!} />,
    Filter: CommissionDocumentResponseStatusSelectColumnFilter,
  },
  {
    Header: 'Closing Date',
    accessor: 'anticipatedClosingDate',
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: DateColumnFilter,
  },
  {
    Header: 'Created Date',
    accessor: 'createdAt',
    Cell: ({ value }) => <MilliDateCell date={value} />,
    Filter: DateColumnFilter,
  },
];

export const columnsToFetch = columns.map((col) => col.accessor as string);
const columnsForSearch = ['number'];

const TransactionCDARoute: React.FC<TransactionCDARouteProps> = ({
  transactionId,
  headerName,
  getUrl,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const getDownloadUrl = useCallback(
    async (id: string) => {
      let url = await dispatch(downloadCDAForm(id!));
      if (url) window.open(url, '_blank');
    },
    [dispatch],
  );
  const joinOn = ['transaction'];
  const filterAnd = [
    {
      column: 'id',
      joinColumn: 'transaction',
      operator: SearchParamOperatorEnum.Eq,
      stringStrictCase: true,
      value: transactionId,
    },
  ];

  const columnsWithAction: Array<Column<CommissionDocumentResponse>> = [
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }: { row: Row<CommissionDocumentResponse> }) => (
        <a
          href={getUrl(row.original.id!)}
          target='_blank'
          rel='noopener noreferrer'
        >
          <IconButton leftIcon={<VisibilityIcon />} />
        </a>
      ),
    },
    ...columns,
    {
      Header: 'Download CDA',
      accessor: 'id',
      id: 'downloadId',
      Cell: ({ value }) => (
        <Button
          className='flex flex-nowrap items-center space-x-2'
          type='outline'
          label='Download'
          onClick={() => getDownloadUrl(value!)}
        />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  return (
    <div className='p-4'>
      <ResourceIndexContainer<CommissionDocumentResponse>
        header={headerName}
        columns={columnsWithAction}
        resourceName='document'
        initialSort={{ revision: 'desc' }}
        fetchData={(req) =>
          resourceTableFetchData(
            req,
            columnsToFetch,
            columnsForSearch,
            SearchRequestTargetObjectEnum.CommissionDocument,
            'arrakis',
            {
              joinOn,
              filterAnd,
            },
          )
        }
      />
    </div>
  );
};

export default TransactionCDARoute;
