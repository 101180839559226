import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { ExitToApp, Refresh } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ResourceIndexContainer from '../../containers/ResourceIndexContainer';
import TextColumnFilter from '../table/Filters/TextColumnFilter';
import { resourceTableFetchData } from '../../utils/TableUtils';
import NumberColumnFilter from '../table/Filters/NumberColumnFilter';
import { numberToMoney } from '../../utils/CurrencyUtils';
import OutgoingPaymentStatusCell from '../table/Cells/OutgoingPaymentStatusCell';
import MilliDateCell from '../table/Cells/MilliDateCell';
import DateColumnFilter from '../table/Filters/DateColumnFilter';
import OutgoingPaymentStatusSelectColumnFilter from '../table/Filters/OutgoingPaymentStatusSelectColumnFilter';
import EditOutgoingPaymentFormSidebarModal from '../../forms/EditOutgoingPaymentFormSidebarModal';
import {
  OutgoingPaymentResponse,
  OutgoingPaymentResponseStatusEnum,
  OutgoingPaymentsControllerApi,
  SearchRequestTargetObjectEnum,
  // TransactionResponse,
} from '../../openapi/arrakis';
import IconButton from '../IconButton';
import OutgoingPaymentPaymentSystemSelectColumnFilter from '../table/Filters/OutgoingPaymentPaymentSystemSelectColumnFilter';
import OutgoingPaymentPaymentSystemCell from '../table/Cells/OutgoingPaymentPaymentSystemCell';
import NullableTextCell from '../table/Cells/NullableTextCell';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import ErrorService from '../../services/ErrorService';
import OutgoingPaymentSidebarModal from '../../forms/OutgoingPaymentSidebarModal';
import ConfirmationModal from '../ConfirmationModal';
import Button from '../Button';
import { SearchRequest } from '../../openapi/yenta';

export interface OutgoingPaymentsResponseModified
  extends OutgoingPaymentResponse {
  transactionCode: string;
  firstName: string;
  lastName: string;
  company: string;
  // transaction: TransactionResponse;
  yentaUserId: string;
}

interface OutgoingPaymentsComponentProps {
  searchDefaults?: Partial<SearchRequest>;
}

export const columns: Array<Column<OutgoingPaymentsResponseModified>> = [
  {
    Header: 'Transaction Code',
    accessor: 'transactionCode',
    Cell: ({ value }) => (
      <Link to={`/transactions/code/${value}`}>
        <IconButton
          leftIcon={<ExitToApp titleAccess='Link' fontSize='small' />}
          variant='outline'
          label={value}
        />
      </Link>
    ),
    Filter: TextColumnFilter,
  },
  {
    Header: 'Payment System',
    accessor: 'paymentSystem',
    Filter: OutgoingPaymentPaymentSystemSelectColumnFilter,
    Cell: ({ value }) => <OutgoingPaymentPaymentSystemCell type={value!} />,
  },
  {
    Header: 'Payment System Id',
    accessor: 'paymentSystemId',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Amount Paid',
    accessor: 'amount',
    Cell: ({ value }) => (value ? numberToMoney(value.amount!) : 'N/A'),
    Filter: NumberColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Confirmation Code',
    accessor: 'confirmationCode',
    Cell: ({ value }) => <NullableTextCell text={value} />,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <OutgoingPaymentStatusCell type={value!} />,
    Filter: OutgoingPaymentStatusSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Paid At',
    accessor: 'paidAt',
    Cell: ({ value }) => <MilliDateCell date={value} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    accessor: 'payerNote',
    disableSortBy: true,
    disableFilters: true,
  },
];
export const columnsToFetch = [
  ...columns.map((col) => col.accessor as string),
  'id',
  'firstName',
  'lastName',
  'company',
  'yentaUserId',
  // 'transaction',
];

const OutgoingPaymentsComponent: React.FC<OutgoingPaymentsComponentProps> = ({
  searchDefaults = {},
}) => {
  const dispatch = useDispatch();
  const [
    editingOutgoingPayment,
    setEditingOutgoingPayment,
  ] = useState<OutgoingPaymentsResponseModified | null>(null);
  const [
    retryingOutgoingPayment,
    setRetryingOutgoingPayment,
  ] = useState<OutgoingPaymentsResponseModified | null>(null);
  const [
    currentOutgoingPayment,
    setCurrentOutgoingPayment,
  ] = useState<OutgoingPaymentResponse | null>(null);
  const [retrying, setRetrying] = useState(false);

  const getOutgoingPaymentById = useCallback(
    async (id: string) => {
      try {
        const { data } = await new OutgoingPaymentsControllerApi(
          getArrakisConfiguration(),
        ).getOutgoingPaymentUsingGET(id);
        setCurrentOutgoingPayment(data);
      } catch (e) {
        dispatch(
          showErrorToastForErrorCode(
            'We were unable to fetch the outgoing payment details',
            ErrorService.getErrorCode(e),
          ),
        );
      }
    },
    [dispatch],
  );

  const retryOutgoingPayment = async () => {
    setRetrying(true);
    try {
      const { data } = await new OutgoingPaymentsControllerApi(
        getArrakisConfiguration(),
      ).reScheduleOutGoingPaymentUsingGET(retryingOutgoingPayment?.id!);
      dispatch(
        showSuccessToast(
          `Successfully requested for payment to ${data.firstName} ${data.lastName}`,
        ),
      );
      setEditingOutgoingPayment(null);
      setRetryingOutgoingPayment(null);
    } catch (e) {
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to retry the outgoing payment',
          ErrorService.getErrorCode(e),
        ),
      );
    } finally {
      setRetrying(false);
    }
  };

  const columnsWithAction: Array<Column<OutgoingPaymentsResponseModified>> = [
    {
      Header: 'Actions',
      accessor: 'id',
      id: 'action',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <div className='flex flex-nowrap space-x-3'>
          <IconButton
            leftIcon={<EditIcon titleAccess='Edit' data-testid='Edit' />}
            onClick={() => setEditingOutgoingPayment(original)}
          />
          <IconButton
            leftIcon={<VisibilityIcon titleAccess='View' />}
            onClick={() => getOutgoingPaymentById(original.id!)}
          />
          {original.status ===
            OutgoingPaymentResponseStatusEnum.SchedulingError && (
            <IconButton
              leftIcon={<Refresh titleAccess='Retry' />}
              onClick={() => setRetryingOutgoingPayment(original)}
            />
          )}
        </div>
      ),
    },
    {
      Header: 'Name',
      accessor: 'firstName',
      Filter: TextColumnFilter,
      Cell: ({ value, row: { original } }) =>
        original.yentaUserId ? (
          <Link to={`/people/${original.yentaUserId}`}>
            <IconButton
              leftIcon={<ExitToApp titleAccess='Link' fontSize='small' />}
              variant='outline'
              label={`${value} ${original.lastName}`}
            />
          </Link>
        ) : (
          <span>{`${value} ${original.lastName}`}</span>
        ),
    },
    {
      Header: 'Company',
      accessor: 'company',
      Cell: ({ value }) => <NullableTextCell text={value} />,
      Filter: TextColumnFilter,
    },
    ...columns,
  ];

  return (
    <>
      <div className='px-4 lg:py-5'>
        <ResourceIndexContainer<OutgoingPaymentsResponseModified>
          header='Outgoing Payments'
          columns={columnsWithAction}
          resourceName='Outgoing Payment'
          hiddenColumns={['payerNote']}
          fetchData={async (req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              [],
              SearchRequestTargetObjectEnum.OutgoingPayment,
              'arrakis',
              searchDefaults,
            )
          }
        />
      </div>

      <EditOutgoingPaymentFormSidebarModal
        isOpen={!!editingOutgoingPayment}
        outgoingPayment={editingOutgoingPayment!}
        onClose={() => setEditingOutgoingPayment(null)}
      />

      <OutgoingPaymentSidebarModal
        isOpen={!!currentOutgoingPayment}
        onClose={() => setCurrentOutgoingPayment(null)}
        outgoingPayment={currentOutgoingPayment!}
      />
      <ConfirmationModal
        isOpen={!!retryingOutgoingPayment}
        variant='error'
        title='Retry Payment'
        onClose={() => setRetryingOutgoingPayment(null)}
      >
        <div>
          <p className='text-lg font-primary-medium'>
            Are you sure you want to retry payment of{' '}
            {numberToMoney(retryingOutgoingPayment?.amount?.amount! || 0)} to{' '}
            {retryingOutgoingPayment?.firstName}{' '}
            {retryingOutgoingPayment?.lastName}
          </p>
        </div>

        <div className='mt-3 space-x-3'>
          <Button
            label='Retry Payment'
            type='primary'
            disabled={retrying}
            onClick={() => retryOutgoingPayment()}
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setRetryingOutgoingPayment(null)}
          />
        </div>
      </ConfirmationModal>
    </>
  );
};

export default OutgoingPaymentsComponent;
