import React from 'react';
import GavelIcon from '@material-ui/icons/Gavel';
import SectionHeader from '../SectionHeader';
import DataRow from '../DataRow';
import DataTable from '../DataTable';
import { BoardPreviewResponse, MlsPreviewResponse } from '../../openapi/yenta';

interface MLSAndBoardsCardProps {
  mls: MlsPreviewResponse[];
  boards: BoardPreviewResponse[];
}

const MLSAndBoards: React.FC<MLSAndBoardsCardProps> = ({ mls, boards }) => {
  return (
    <div>
      <SectionHeader
        icon={<GavelIcon fontSize='small' />}
        title='MLSs & Boards'
      />
      <div className='mt-2'>
        <DataTable>
          <DataRow
            name='MLSs'
            value={mls.map((m) => m.name).join(', ') || 'N/A'}
          />
          <DataRow
            name='Boards'
            value={boards.map((board) => board.name).join(', ') || 'N/A'}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default MLSAndBoards;
