import { SvgIcon } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { ReactComponent as ChatIcon } from '../assets/icons/chat.svg';

export interface NoteItemProps {
  name: string;
  comment: string;
  followUpDate?: {
    date: number;
    onClick(): void;
  };
  createdAt: number;
  avatar?: string;
  icon?: React.ReactElement;
  isLastItem?: boolean;
}

const NoteItem: React.FC<NoteItemProps> = ({
  comment,
  createdAt,
  name,
  icon,
  avatar,
  isLastItem = false,
  followUpDate,
}) => {
  return (
    <div className='relative pb-8'>
      {!isLastItem && (
        <span
          className='absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200'
          aria-hidden='true'
        />
      )}
      <div className='relative flex items-start space-x-3'>
        <div className='relative'>
          {avatar ? (
            <img
              className='h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white'
              src={avatar}
              alt=''
            />
          ) : (
            <span className='h-10 w-10 rounded-full bg-gray-800 flex items-center justify-center ring-8 ring-white text-white'>
              {name[0]}
            </span>
          )}

          <span className='absolute -bottom-2 -right-1 bg-white rounded-tl p-0.5 py-px'>
            {icon ? (
              icon
            ) : (
              <SvgIcon component={ChatIcon} className='text-gray-400' />
            )}
          </span>
        </div>
        <div className='min-w-0 flex-1'>
          <div className='flex md:flex-row flex-col justify-between'>
            <div>
              <div className='text-sm'>
                <p className='font-medium text-gray-900'>{name}</p>
              </div>
              <p className='mt-0.5 text-sm text-gray-500'>
                {DateTime.fromMillis(createdAt).toRelative()}
              </p>
            </div>
            <div>
              {followUpDate?.date && (
                <div className='flex md:flex-row flex-col space-x-1 items-center'>
                  <p className='font-primary-regular text-gray-600'>
                    Follow Up:
                  </p>
                  <div className='inline-block py-1 rounded-full'>
                    <p className='bg-white text-gray-600 border rounded-full px-2 font-primary-regular'>
                      {DateTime.fromMillis(followUpDate.date).toLocaleString()}{' '}
                      <button
                        onClick={followUpDate.onClick}
                        className='focus:outline-none'
                      >
                        <Close style={{ fontSize: 14 }} />
                      </button>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='mt-2 text-sm text-gray-700'>
            <p>{comment}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteItem;
