import React from 'react';
import { VictoryPie } from 'victory';

export interface HalfPieProps {
  progress: number;
  text: string;
  activeTintColor?: string;
  inactiveTintColor?: string;
}

const HalfPieProgress: React.FC<HalfPieProps> = ({
  progress,
  text,
  activeTintColor = '#3E3F3C',
  inactiveTintColor = 'rgba(62, 63, 60, 0.25)',
}) => {
  const done = (progress * 180) / 100;
  const remaining = 180 - done;

  return (
    <svg viewBox='0 0 300 150'>
      <text
        x='50%'
        y='62%'
        dominantBaseline='middle'
        textAnchor='middle'
        className='font-primary-medium text-4xl'
        fill={activeTintColor}
      >
        {progress}%
      </text>
      {!!text && (
        <text
          x='50%'
          y='83%'
          dominantBaseline='middle'
          textAnchor='middle'
          className='font-primary-regular text-xl'
          fill={activeTintColor}
        >
          {text}
        </text>
      )}
      <VictoryPie
        standalone={false}
        width={300}
        height={300}
        startAngle={90}
        endAngle={-90}
        labels={() => null}
        innerRadius={150}
        radius={130}
        data={[
          { y: remaining, fill: inactiveTintColor },
          { y: done, fill: activeTintColor },
        ]}
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
          },
        }}
      />
    </svg>
  );
};

export default HalfPieProgress;
