import React from 'react';
import { formatPhoneNumber } from '../../../utils/StringUtils';

interface PhoneNumberCellProps {
  phoneNumber: string | undefined;
}

const PhoneNumberCell: React.FC<PhoneNumberCellProps> = ({ phoneNumber }) => {
  return <p>{formatPhoneNumber(phoneNumber) || 'N/A'}</p>;
};

export default PhoneNumberCell;
