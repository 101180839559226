import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { get } from 'lodash';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import Select from 'react-select';
import { ISelectOption } from '../types';
import FormErrorMessage from './FormErrorMessage';

interface ControlledMultiSelectInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues> {
  errors: FieldErrors<TFieldValues>;
  label?: string;
  placeholder?: string;
  options: Array<ISelectOption>;
}

const ControlledMultiSelectInput: React.FC<ControlledMultiSelectInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  options,
  placeholder = 'Select',
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && <label htmlFor={name}>{label}</label>}
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={rules}
        render={({ name, value, onChange, onBlur, ref }) => (
          <Select
            options={options}
            placeholder={placeholder}
            name={name}
            isMulti
            defaultValue={value}
            value={value}
            closeMenuOnSelect={false}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            className='react-select-container'
            classNamePrefix='react-select'
            inputId={name}
          />
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledMultiSelectInput;
