import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { last } from 'lodash';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  getIndexFromStatus,
  getIndexFromStatusWithLOI,
  onboardingSteps,
  onboardingStepsWithLOI,
} from '../testUtils/OnboardingUtils';
import { showSupportWidget } from '../utils/Acquire';
import { AppDispatch, RootState } from '../types';
import { ApplicationResponseApplicationTypeEnum } from '../openapi/yenta';
import { deleteAuthCookie } from '../utils/AuthUtils';
import { resetApp } from '../slices/actions/authActions';
import ApplicationStepProgressHeader from './onboarding/ApplicationStepProgressHeader';
import RealHeader from './RealHeader';
import IconButton from './IconButton';

export interface ApplicationLayoutProps {}

const ApplicationLayout: React.FC<ApplicationLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const application = last(userDetail?.applications);
  const { loi } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const showLOIStep: boolean =
    loi === 'true' ||
    application?.applicationType ===
      ApplicationResponseApplicationTypeEnum.LetterOfIntent;

  useEffect(() => {
    showSupportWidget();
  }, []);

  const dispatch: AppDispatch = useDispatch();

  const logout = () => {
    deleteAuthCookie();
    dispatch(resetApp());
  };

  return (
    <div className='h-full-window overflow-y-auto w-full flex flex-col'>
      <RealHeader />
      <div className='py-4'>
        <ApplicationStepProgressHeader
          steps={showLOIStep ? onboardingStepsWithLOI : onboardingSteps}
          inProgressIndex={
            showLOIStep
              ? getIndexFromStatusWithLOI(application?.nextStatus!)
              : getIndexFromStatus(application?.nextStatus!)
          }
        />
      </div>
      <div className='flex-auto'>{children}</div>
      <div className='flex flex-row p-4'>
        <IconButton
          label='Logout'
          variant='outline'
          leftIcon={<ExitToAppIcon />}
          buttonType='button'
          onClick={logout}
        />
      </div>
    </div>
  );
};

export default ApplicationLayout;
