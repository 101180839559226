import React from 'react';
import classNames from 'classnames';

export interface HighlightedDivProps {
  divClassName?: string;
}

const HighlightedDiv: React.FC<HighlightedDivProps> = ({
  divClassName,
  children,
}) => {
  return (
    <div className={classNames('p-1 bg-warning bg-opacity-30', divClassName)}>
      {children}
    </div>
  );
};

export default HighlightedDiv;
