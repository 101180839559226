import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Business from '@material-ui/icons/Business';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import Info from '@material-ui/icons/Info';
import { AccountBalance } from '@material-ui/icons';
import PageLayout from '../components/PageLayout';
import { fetchOfficeDetailById } from '../slices/OfficeSlice';
import ResourceContainer from '../components/ResourceContainer';
import { RootState } from '../types';
import OfficeDetailHeaderSection from '../components/office/OfficeDetailHeaderSection';
import IdentificationCard from '../components/IdentificationCard';
import SectionHeader from '../components/SectionHeader';
import ListPaginateContainer from '../containers/ListPaginateContainer';
import DetailPageLoader from '../components/DetailPageLoader';
import AgentRow from '../components/AgentRow';
import OfficeTransactionTypeCell from '../components/table/Cells/OfficeTransactionTypeCell';
import { AgentResponse, SearchRequestTargetObjectEnum } from '../openapi/yenta';
import { resourceListFetchData } from '../utils/TableUtils';
import GridCard from '../components/GridCard';
import { capitalizeEnum, formatAddressObject } from '../utils/StringUtils';
import StateOrProvinceIconCell from '../components/table/Cells/StateOrProvinceIconCell';
import BrokerCard from '../components/BrokerCard';
import Pill from '../components/Pill';
// import CalendarEventsCard from '../components/CalendarEventsCard';
// import {AssignmentInd,NotificationImportant,HomeWork} from '@material-ui/icons';
// import PageAnnouncement from '../components/PageAnnouncement';

interface Match {
  id: string;
}

interface OfficeDetailProps extends RouteComponentProps<Match> {}

const OfficeDetailRoute: React.FC<OfficeDetailProps> = ({ match }) => {
  const dispatch = useDispatch();
  const officeId = match.params.id;
  // const [showAnnouncement, setShowAnnouncement] = useState(false);
  // const [isCollapsed, setCollapsed] = useState(true);
  const {
    loadingDetail,
    officeDetail,
    fetchDetailErrorCode,
    // calendarEvents,
  } = useSelector((state: RootState) => state.office);

  useEffect(() => {
    dispatch(fetchOfficeDetailById(officeId));
    // dispatch(fetchOfficeCalendarEvents(officeId));
  }, [dispatch, officeId]);

  // useEffect(() => {
  //   setShowAnnouncement(!!detail?.announcementText);
  // }, [detail]);

  const searchParameters = {
    filterJoinColumn: 'offices',
    filterValue: !!officeDetail ? officeDetail!.id! : '',
    joinOn: ['offices'],
    searchColumns: ['firstName', 'lastName'],
    responseColumns: [
      'id',
      'firstName',
      'lastName',
      'avatar',
      'emailAddress',
      'phoneNumber',
    ],
    targetObject: SearchRequestTargetObjectEnum.Agent,
  };

  return (
    <ResourceContainer
      loading={loadingDetail}
      isEmpty={!officeDetail}
      errorCode={fetchDetailErrorCode}
      LoaderComponent={<DetailPageLoader />}
      resourceName='office'
    >
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Offices', url: '/offices' },
          {
            title: `${officeDetail?.name}`,
            url: `/offices/${officeDetail?.id}`,
          },
        ]}
      >
        {/* {!!detail && detail.announcementText && (
          <PageAnnouncement
            titleIcon={<NotificationImportant />}
            title='Announcement:'
            content={detail.announcementText}
            show={showAnnouncement}
            variant={detail.announcementType!}
            onClose={() => setShowAnnouncement(false)}
          />
        )} */}
        {!!officeDetail && (
          <div>
            <OfficeDetailHeaderSection detail={officeDetail} />
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 m-4'>
              {/* <IdentificationCard
              icon={<HomeWork />}
              name='HUD Number'
              value={officeDetail.hudNumber!}
            /> */}
              <IdentificationCard
                icon={<AttachMoneyIcon />}
                name='Transaction Type'
                value={
                  <OfficeTransactionTypeCell
                    transactionType={officeDetail.transactionType!}
                  />
                }
              />

              <IdentificationCard
                icon={<Business />}
                name='Brokerage License ID'
                value={officeDetail.brokerageLicenseNumber!}
              />

              <IdentificationCard
                icon={<AccountBalance />}
                name='Payment System'
                value={
                  <Pill
                    text={capitalizeEnum(
                      officeDetail.usesTipalti! ? 'Tipalti' : 'Netsuite',
                    )}
                    variant={officeDetail.usesTipalti ? 'primary' : 'success'}
                  />
                }
              />
              {/* <IdentificationCard
              icon={<AssignmentInd />}
              name='Broker License ID'
              value={officeDetail.brokerLicenseId!}
            /> */}
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 m-4'>
              <div>
                <div>
                  <SectionHeader
                    icon={<Info fontSize='inherit' />}
                    title='Designated Broker'
                  />
                  <div className='mt-3'>
                    <BrokerCard broker={officeDetail.designatedBroker!} />
                  </div>
                </div>

                {officeDetail.managingBroker && (
                  <div className='mt-3'>
                    <SectionHeader
                      icon={<Info fontSize='inherit' />}
                      title='Manager'
                    />
                    <div className='mt-3'>
                      <BrokerCard broker={officeDetail.managingBroker!} />
                    </div>
                  </div>
                )}
                {/* <div className='mt-3'>
                  <SectionHeader
                    icon={<Info fontSize='inherit' />}
                    title='Calendar'
                  />
                  <div className='mt-3'>
                    <CalendarEventsCard
                      isCollapsed={isCollapsed}
                      setCollapsed={setCollapsed}
                      calendarGroup={calendarEvents}
                    />
                  </div>
                </div> */}
              </div>
              <div className='lg:col-span-2'>
                <div>
                  <SectionHeader
                    icon={<Info fontSize='inherit' />}
                    title='Banking Information'
                  />
                  <div className='mt-3'>
                    <GridCard
                      items={[
                        {
                          name: 'Bank',
                          value: officeDetail!.bankAccount!.bankName!,
                        },
                        {
                          name: 'Routing Number',
                          value: officeDetail!.bankAccount!.bankRoutingNumber!,
                        },
                        {
                          name: 'Account Number',
                          value: officeDetail!.bankAccount!.accountNumber!,
                        },
                        {
                          name: 'Account Name',
                          value: officeDetail!.bankAccount!.accountName!,
                        },
                      ]}
                    />
                  </div>
                </div>
                {officeDetail.trustAccount && (
                  <div className='mt-3'>
                    <SectionHeader
                      icon={<Info fontSize='inherit' />}
                      title='Trust Account'
                    />
                    <div className='mt-3'>
                      <GridCard
                        items={[
                          {
                            name: 'Bank',
                            value: officeDetail!.trustAccount!.bankName!,
                          },
                          {
                            name: 'Routing Number',
                            value: officeDetail!.trustAccount!
                              .bankRoutingNumber!,
                          },
                          {
                            name: 'Account Number',
                            value: officeDetail!.trustAccount!.accountNumber!,
                          },
                          {
                            name: 'Account Name',
                            value: officeDetail!.trustAccount!.accountName!,
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
                {officeDetail.company && (
                  <div className='mt-3'>
                    <SectionHeader
                      icon={<Info fontSize='inherit' />}
                      title='Company Details'
                    />
                    <div className='mt-3'>
                      <GridCard
                        items={[
                          {
                            name: 'Name',
                            value: officeDetail!.company!.name!,
                          },
                          {
                            name: 'EIN',
                            value: officeDetail!.company!.ein!,
                          },
                          {
                            name: 'Address',
                            value: formatAddressObject(
                              officeDetail!.company!.address!,
                            ),
                          },
                          {
                            name: 'State',
                            value: (
                              <StateOrProvinceIconCell
                                state={
                                  officeDetail!.company!.administrativeArea
                                    ?.stateOrProvince!
                                }
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
                <div className='mt-3'>
                  <SectionHeader
                    icon={<PeopleAlt fontSize='inherit' />}
                    title='Agents'
                  />
                  <ListPaginateContainer<AgentResponse>
                    fetchData={(req) =>
                      resourceListFetchData<AgentResponse>(
                        req,
                        req.search,
                        searchParameters,
                      )
                    }
                    resourceName='agent'
                    searchPlaceholder='Search by name or zip code'
                  >
                    {(agents) =>
                      agents.map((agent) => (
                        <AgentRow key={agent.id} {...agent} />
                      ))
                    }
                  </ListPaginateContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageLayout>
    </ResourceContainer>
  );
};

export default OfficeDetailRoute;
