import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Column } from 'react-table';
import ResourceIndexContainer from '../../containers/ResourceIndexContainer';
import {
  AttachedFeeValue,
  ParticipantResponse,
  TransactionResponse,
} from '../../openapi/arrakis';
import { deleteAttachedFee } from '../../slices/TransactionSlice';
import { numberToMoney } from '../../utils/CurrencyUtils';
import AvatarPill from '../AvatarPill';
import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';
import FeeTypeCell from '../table/Cells/FeeTypeCell';
import DateColumnFilter from '../table/Filters/DateColumnFilter';
import { isTransactionReadOnly } from '../../utils/TransactionHelper';
import AddAttachedFee from './AddAttachedFee';

interface AttachedFeeProps {
  attachedFees: AttachedFeeValue[];
  transaction: TransactionResponse;
  participants: ParticipantResponse[];
}

const columns: Array<Column<AttachedFeeValue>> = [
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => numberToMoney(value!.amount!),
  },
  {
    Header: 'Fee Type',
    accessor: 'feeType',
    Cell: ({ value }) => <FeeTypeCell status={value!} />,
  },
  {
    Header: 'Description',
    accessor: 'description',
    disableFilters: true,
    Cell: ({ value }) => <p>{value}</p>,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) =>
      value ? DateTime.fromMillis(value).toFormat('LL/dd/yy') : 'N/A',
    Filter: DateColumnFilter,
  },
];

const AttachedFee: React.FC<AttachedFeeProps> = ({
  attachedFees,
  transaction,
  participants,
}) => {
  const dispatch = useDispatch();
  const [
    openAttachedFeeFormSidebarModal,
    setOpenAttachedFeeFormSidebarModal,
  ] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [
    attachedFeeRowDetails,
    setAttachedFeeRowDetails,
  ] = useState<AttachedFeeValue>();
  const [
    attachedParticipantDetails,
    setAttachedParticipantDetails,
  ] = useState<ParticipantResponse>();
  const columnsWithAction: Array<Column<AttachedFeeValue>> = [
    {
      Header: 'Participant',
      accessor: 'counterpartyId',
      disableFilters: true,
      Cell: ({ value }) => (
        <AvatarPill
          imageUrl=''
          text={`${participants.find((x) => x.id === value)?.firstName} ${
            participants.find((x) => x.id === value)?.lastName
          }`}
        />
      ),
    },
    ...columns,
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ row }) => (
        <div className='md:space-x-3 flex md:flex-row flex-col'>
          {!isTransactionReadOnly(transaction) && (
            <>
              <Button
                label='Edit'
                type='outline'
                onClick={() => {
                  setAttachedFeeRowDetails(row.original);
                  setOpenAttachedFeeFormSidebarModal(true);
                }}
              />
              <Button
                label='Delete'
                type='outline'
                onClick={() => {
                  setAttachedFeeRowDetails(row.original);
                  setAttachedParticipantDetails(
                    participants.filter(
                      (item) => item.id === row.original.counterpartyId,
                    )[0],
                  );
                  setOpenDeleteModal(true);
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className='col-span-12 p-4'>
      <div className='col-span-12'>
        <div className='py-2 flex flex-row justify-between'>
          <span className='text-xl text-gray-800 font-primary-medium'>
            Additional Fees
          </span>
          {!isTransactionReadOnly(transaction) && (
            <div className='space-x-3'>
              <Button
                type='outline'
                label='+ Add'
                onClick={() => {
                  setAttachedFeeRowDetails(undefined);
                  setOpenAttachedFeeFormSidebarModal(true);
                }}
              />
            </div>
          )}
        </div>
        <div className='py-0'>
          <ResourceIndexContainer<AttachedFeeValue>
            columns={columnsWithAction}
            resourceName='Additional Fee'
            fetchData={() =>
              new Promise((resolve) =>
                resolve({
                  data: attachedFees,
                  total: attachedFees!.length,
                }),
              )
            }
            hidePagination
          />
        </div>
        <AddAttachedFee
          isOpen={openAttachedFeeFormSidebarModal}
          onClose={() => setOpenAttachedFeeFormSidebarModal(false)}
          transactionId={transaction?.id!}
          attachedFee={attachedFeeRowDetails!}
        />
        <ConfirmationModal
          isOpen={openDeleteModal}
          variant='error'
          title='Delete'
          onClose={() => setOpenDeleteModal(false)}
        >
          <div>
            <p className='text-lg font-primary-medium'>
              Are you sure you want to delete an additional fee of&nbsp;
              {numberToMoney(
                attachedFeeRowDetails?.amount?.amount! || 0,
              )} from {attachedParticipantDetails?.firstName}&nbsp;
              {attachedParticipantDetails?.lastName}
            </p>
          </div>

          <div className='mt-3 space-x-3'>
            <Button
              label='Delete'
              type='primary'
              onClick={() => {
                dispatch(
                  deleteAttachedFee(
                    attachedFeeRowDetails?.id!,
                    transaction?.id!,
                  ),
                );
                setOpenDeleteModal(false);
              }}
            />
            <Button
              label='Cancel'
              type='secondary'
              onClick={() => setOpenDeleteModal(false)}
            />
          </div>
        </ConfirmationModal>
      </div>
    </div>
  );
};

export default AttachedFee;
