import { EditOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ParticipantValue,
  PaymentParticipantValue,
  TransactionResponse,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../../openapi/arrakis';
import { AppDispatch } from '../../types';
import {
  isParticipantTransactionOwner,
  isTransactionReadOnly,
} from '../../utils/TransactionHelper';
import ParticipantCard from '../ParticipantCard';
import PaymentParticipantCard from '../PaymentParticipantCard';
import EditTransactionParticipantFormSidebarModal from '../../forms/EditTransactionParticipantFormSidebarModal';
import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';
import {
  removeTransactionParticipant,
  updateTransactionParticipant,
} from '../../slices/TransactionSlice';
import { getParticipantName } from '../../utils/ParticipantHelper';
import CommissionSplitForm from './CommissionSplitForm';

interface PaymentParticipantSectionProps {
  transactionDetail: TransactionResponse;
}

const TransactionParticipantsSection: React.FC<PaymentParticipantSectionProps> = ({
  transactionDetail,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [
    openCommissionSplitFormSidebarModal,
    setOpenCommissionSplitFormSidebarModal,
  ] = useState<boolean>(false);
  const [
    openEditParticipantFormSidebarModal,
    setOpenEditParticipantFormSidebarModal,
  ] = useState<boolean>(false);
  const [payoutParticipant, setPayoutParticipant] = useState<
    PaymentParticipantValue | ParticipantValue | null
  >(null);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);

  const handleEditParticipant = (
    participant: PaymentParticipantValue | ParticipantValue,
  ) => {
    setPayoutParticipant(participant);
    setOpenEditParticipantFormSidebarModal(true);
  };

  const handleDeleteParticipant = (
    participant: PaymentParticipantValue | ParticipantValue,
  ) => {
    setPayoutParticipant(participant);
    setIsConfirmDelete(true);
  };

  const deleteParticipant = async () => {
    const success = await dispatch(
      removeTransactionParticipant(
        transactionDetail.id!,
        payoutParticipant?.id!,
      ),
    );
    if (success) {
      setIsConfirmDelete(false);
    }
  };

  const toggleParticipant = async (
    participant: PaymentParticipantValue | ParticipantValue,
    flag: keyof UpdateParticipantRequest,
    value: boolean,
  ): Promise<boolean> => {
    return await dispatch(
      updateTransactionParticipant(participant.id!, transactionDetail.id!, {
        paidByReal: !!participant.paidByReal,
        passThrough: !!participant.passThrough,
        participantRole: (participant.role as unknown) as UpdateParticipantRequestParticipantRoleEnum,
        company: participant.company,
        paymentInstructions: participant.paymentInstructions,
        address: participant.address,
        payer: !!participant.commissionDocumentPayer,
        commissionDocumentRecipient: !!participant.commissionDocumentRecipient,
        firstName: participant.firstName,
        lastName: participant.lastName,
        phoneNumber: participant.phoneNumber,
        emailAddress: participant.emailAddress,
        personalDeal:
          'personalDeal' in participant ? !!participant.personalDeal : false,
        [flag]: value,
      }),
    );
  };

  return (
    <div>
      <div className='col-span-12 p-4'>
        <div className='py-2 flex flex-row space-x-2'>
          <span className='text-xl text-gray-800 font-primary-medium'>
            Payment Participants
          </span>
          {!isTransactionReadOnly(transactionDetail) && (
            <button
              onClick={() => {
                setOpenCommissionSplitFormSidebarModal(true);
              }}
              className='flex flex-row rounded items-center focus:outline-none ml-2 text-primary'
            >
              <span className='text-primary'>
                <EditOutlined fontSize='small' />
              </span>
              <span className='text-primary'>Add / Edit Participants</span>
            </button>
          )}
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {transactionDetail?.paymentParticipants?.map((participant) => {
            return (
              <div key={participant.yentaId}>
                <PaymentParticipantCard
                  participant={participant}
                  onEdit={() => handleEditParticipant(participant)}
                  onDelete={() => handleDeleteParticipant(participant)}
                  isReadonly={isTransactionReadOnly(transactionDetail)}
                  toggleFlag={(flag, value) =>
                    toggleParticipant(participant, flag, value)
                  }
                  transactionOwner={isParticipantTransactionOwner(
                    transactionDetail.transactionOwner!,
                    participant,
                  )}
                />
              </div>
            );
          })}
        </div>
      </div>
      {transactionDetail?.otherParticipants?.length === 0 || (
        <div className='col-span-12 p-4'>
          <div className='py-2 flex flex-col'>
            <span className='text-xl text-gray-800 font-primary-medium'>
              Other Participants
            </span>
          </div>
          <div className='grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            {transactionDetail.otherParticipants?.map((participant) => {
              return (
                <div key={participant.yentaId}>
                  <ParticipantCard
                    participant={participant}
                    onEdit={() => handleEditParticipant(participant)}
                    onDelete={() => handleDeleteParticipant(participant)}
                    isReadonly={isTransactionReadOnly(transactionDetail)}
                    toggleFlag={(flag, value) =>
                      toggleParticipant(participant, flag, value)
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <CommissionSplitForm
        isOpen={openCommissionSplitFormSidebarModal}
        onClose={() => setOpenCommissionSplitFormSidebarModal(false)}
        totalCommission={transactionDetail.grossCommission?.amount!}
        propertyName={transactionDetail.address?.oneLine!}
        participants={transactionDetail?.possiblePayableParticipants!}
        transactionId={transactionDetail.id!}
      />
      {payoutParticipant && (
        <>
          <EditTransactionParticipantFormSidebarModal
            transaction={transactionDetail}
            participant={payoutParticipant}
            isOpen={openEditParticipantFormSidebarModal}
            onClose={() => setOpenEditParticipantFormSidebarModal(false)}
          />
          <ConfirmationModal
            variant='error'
            title={`Remove ${getParticipantName(payoutParticipant)}?`}
            isOpen={isConfirmDelete}
            onClose={() => setIsConfirmDelete(false)}
          >
            <div>
              <p className='text-lg font-primary-medium'>
                You can always add them back in the future.
              </p>
            </div>
            <div className='mt-3 space-x-3'>
              <Button
                label='Confirm'
                type='primary'
                onClick={deleteParticipant}
              />
              <Button
                label='Cancel'
                type='secondary'
                onClick={() => setIsConfirmDelete(false)}
              />
            </div>
          </ConfirmationModal>
        </>
      )}
    </div>
  );
};

export default TransactionParticipantsSection;
