import { groupBy, values } from 'lodash';
import React, { useState } from 'react';
import { OfficePreviewResponse } from '../../openapi/yenta';
import { capitalizeEnum } from '../../utils/StringUtils';
import Tabs from '../Tabs';
import OfficeBroker from './OfficeBroker';

interface Props {
  offices: OfficePreviewResponse[];
}

const OfficeTabs: React.FC<Props> = ({ offices }) => {
  const stateOffices = groupBy(offices, 'administrativeArea.stateOrProvince');
  const tabs = values(stateOffices)
    .slice(0, 3)
    .map((office: OfficePreviewResponse[]) => {
      return {
        name: capitalizeEnum(office[0].administrativeArea?.stateOrProvince!),
        TabComponent: <OfficeBroker offices={office!} />,
      };
    });

  const [officeTab, setOfficeTab] = useState(tabs[0].name.toString());

  return (
    <Tabs
      tabs={tabs}
      selected={officeTab}
      variant='spread'
      showBorderBottom
      onChange={setOfficeTab}
    />
  );
};

export default OfficeTabs;
