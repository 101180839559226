import { SvgIcon } from '@material-ui/core';
import {
  AttachFile,
  Check,
  Clear,
  EditOutlined,
  Info,
} from '@material-ui/icons';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ApplicationHeader from '../components/ApplicationHeader';
import SectionHeader from '../components/SectionHeader';
import {
  ApplicationResponse,
  ApplicationResponseApplicationTypeEnum,
  ApplicationResponseNextStatusEnum,
  ApplicationResponseStatusEnum,
  BoardResponse,
  CreateNoteRequestEntityTypeEnum,
  MlsResponse,
  SearchRequestTargetObjectEnum,
} from '../openapi/yenta';
import { ReactComponent as InfoIcon } from '../assets/icons/info-icon.svg';
import Card from '../components/Card';
import AttachmentRow from '../components/AttachmentRow';
import VerticalStepProgress from '../components/onboarding/VerticalStepProgress';
import {
  getIndexFromStatus,
  JOIN_THE_BOARD_INDEX,
  onboardingSteps,
  TRANSFER_LICENSE_INDEX,
} from '../testUtils/OnboardingUtils';
import PageAnnouncement from '../components/PageAnnouncement';
import Button from '../components/Button';
import ApplicationDetailCard from '../components/applicationForm/ApplicationDetailCard';
import ApplicationEditForm from '../components/applicationForm/ApplicationEditForm';
import {
  agentJoinedBoard,
  agentLicenseTransferred,
  approveApplicationById,
  rejectApplicationById,
} from '../slices/ApplicationSlice';
import ConfirmationModal from '../components/ConfirmationModal';
import ControlledAsyncMultiSelectInput from '../components/ControlledAsyncMultiSelectInput';
import { performSearchRequest } from '../utils/TableUtils';
import { capitalizeEnum, formatPhoneNumber } from '../utils/StringUtils';
import { getStripePaymentUrl } from '../utils/StripeUtils';
import { AsyncSelectOption } from '../types';
import { OfficeResponse } from '../openapi/arrakis';
import DataTable from '../components/DataTable';
import DataRow from '../components/DataRow';
import NotesContainer from '../components/NotesContainer';
import BooleanButton from '../components/Agent/BooleanButton';

interface ApplicationFormContentProps {
  id: string;
  name: string;
  application: ApplicationResponse;
}

const ApplicationFormContent: React.FC<ApplicationFormContentProps> = ({
  id,
  name,
  application,
}) => {
  const [
    openApplicationEditFormSidebarModal,
    setOpenApplicationEditFormSidebarModal,
  ] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [
    openTransferLicenseModal,
    setOpenTransferLicenseModal,
  ] = useState<boolean>(false);
  const [openJoinBoardModal, setOpenJoinBoardModal] = useState<boolean>(false);
  // const [stepsWithAction, setActionState] = useState(onboardingSteps);
  const dispatch = useDispatch();

  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onApprove = (data: any) => {
    const mlsId = data.mls.map((mls: { value: any }) => mls.value);
    const boardId = data.board.map((board: { value: any }) => board.value);
    const officeId = data.office.map((office: { value: any }) => office.value);

    dispatch(
      approveApplicationById(id, {
        agentId: application.agentId!,
        mlsIds: mlsId,
        boardIds: boardId,
        officeIds: officeId,
      }),
    );
  };

  const licenseInfo =
    application.doesBusinessIn && application.doesBusinessIn[0];

  // We need to clone this array to make sure we get a new copy every time
  const stepsWithAction = onboardingSteps.map((obj) => ({ ...obj }));

  if (
    application.nextStatus === ApplicationResponseNextStatusEnum.TransferLicense
  ) {
    stepsWithAction[TRANSFER_LICENSE_INDEX].action = {
      buttonText: 'Mark as Complete',
      onClick: () => setOpenTransferLicenseModal(true),
    };
  } else if (
    application.nextStatus === ApplicationResponseNextStatusEnum.JoinBoard
  ) {
    stepsWithAction[JOIN_THE_BOARD_INDEX].action = {
      buttonText: 'Mark as Complete',
      onClick: () => setOpenJoinBoardModal(true),
    };
  }

  const hasMadePayment: boolean = !!application.feesStripeChargeId;

  return (
    <div>
      {application.nextStatus ===
        ApplicationResponseNextStatusEnum.PendingApproval && (
        <PageAnnouncement
          content='Application is pending for approval'
          title='Pending for Review'
          variant='warning'
          titleIcon={<Info />}
          RightComponent={
            <div className='space-x-2 mt-3 lg:mt-0'>
              <Button
                label='Approve'
                type='primary'
                onClick={() => setOpenApproveModal(true)}
              />
              <Button
                label='Reject'
                type='danger'
                onClick={() => setOpenRejectModal(true)}
              />
            </div>
          }
          show
        />
      )}
      {application.status === ApplicationResponseStatusEnum.Approved && (
        <PageAnnouncement
          content={`Application was approved on ${DateTime.fromMillis(
            application.approvedAt!,
          ).toLocaleString()}`}
          title='Approved'
          variant='success'
          titleIcon={<Check />}
          show
        />
      )}
      {application.status === ApplicationResponseStatusEnum.Rejected && (
        <PageAnnouncement
          content={`Application was rejected on ${DateTime.fromMillis(
            application.rejectedAt!,
          ).toLocaleString()}`}
          title='Rejected'
          variant='danger'
          titleIcon={<Clear />}
          show
        />
      )}
      <ApplicationHeader
        id={application.agentId!}
        name={name}
        state={
          licenseInfo
            ? capitalizeEnum(licenseInfo?.administrativeArea!.stateOrProvince!)
            : 'N/A'
        }
        expiryDate={licenseInfo?.expirationDate!}
        licenseNumber={licenseInfo?.number!}
        phoneNumber={formatPhoneNumber(application.phoneNumber)!}
        primaryEmail={application.emailAddress!}
      />
      <div className='p-4'>
        <div className='grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-5'>
          <div>
            <SectionHeader
              title='Application Details'
              actionText='Edit'
              actionIcon={<EditOutlined fontSize='small' />}
              icon={<SvgIcon component={InfoIcon} fontSize='small' />}
              onActionClick={() => {
                setOpenApplicationEditFormSidebarModal(true);
              }}
            />
            <div className='mt-2'>
              <ApplicationDetailCard application={application} />
            </div>
          </div>
          <div className='space-y-5'>
            <div>
              <SectionHeader
                title='Additional Information'
                icon={<SvgIcon component={InfoIcon} fontSize='small' />}
              />
              <div className='mt-2'>
                <Card>
                  <div className='p-4'>
                    <AttachmentRow
                      action={
                        hasMadePayment
                          ? {
                              text: 'View on Stripe',
                              onClick: () => {
                                window.open(
                                  getStripePaymentUrl(
                                    application.feesStripeChargeId!,
                                  ),
                                  '_blank',
                                );
                              },
                            }
                          : undefined
                      }
                      label={hasMadePayment ? 'Invoice' : 'Waiting on Payment'}
                      icon={
                        hasMadePayment ? <AttachFile /> : <HourglassEmptyIcon />
                      }
                      title='Payment'
                    />
                    {application.applicationType ===
                      ApplicationResponseApplicationTypeEnum.LetterOfIntent && (
                      <div className='mt-2'>
                        <p className='py-2'>Letter Of Intent</p>
                        {application.loiSignedAt ? (
                          <DataTable>
                            <DataRow name='Name' value={application.loiName} />
                            <DataRow
                              name='Resident In'
                              value={application.loiResidentIn}
                            />
                            <DataRow
                              name='Number of Days Until Joining'
                              value={application.loiNumDaysUntilJoining}
                            />
                            <DataRow
                              name='Signed Date'
                              value={DateTime.fromMillis(
                                application.loiSignedAt,
                              ).toLocaleString()}
                            />
                            <DataRow name='Status'>
                              <label className='bg-green-100 text-green-600 rounded p-1 px-2 my-2'>
                                Agreement Signed
                              </label>
                            </DataRow>
                          </DataTable>
                        ) : (
                          <DataTable>
                            <DataRow name='Status'>
                              <label className='bg-yellow-100 text-yellow-600 rounded p-1 px-2 my-2'>
                                Signature Pending
                              </label>
                            </DataRow>
                          </DataTable>
                        )}
                      </div>
                    )}
                    <div className='mt-2'>
                      <p className='py-2'>Independent Contractor Agreement</p>
                      {application.icaSignedAt ? (
                        <DataTable>
                          <DataRow name='Name' value={application.icaName} />
                          <DataRow
                            name='Entity Name'
                            value={application.icaEntityName}
                          />
                          <DataRow
                            name='Emergency Contact Name'
                            value={application.icaEmergencyContactName}
                          />
                          <DataRow
                            name='Emergency Contact Phone Number'
                            value={formatPhoneNumber(
                              application.icaEmergencyContactPhoneNumber,
                            )}
                          />
                          <DataRow
                            name='Emergency Contact Email Address'
                            value={application.icaEmergencyContactEmailAddress}
                          />
                          <DataRow
                            name='Emergency Contact Relationship'
                            value={application.icaEmergencyContactRelationship}
                          />
                          <DataRow
                            name='Referral Agent'
                            value={application.icaReferringAgentName || 'N/A'}
                          />
                          <DataRow name='Opt-in To Stock Plan'>
                            <BooleanButton
                              value={application.optInToStockPlan!}
                            />
                          </DataRow>
                          <DataRow
                            name='Anniversary Date'
                            value={DateTime.fromISO(
                              application.icaAnniversaryDateRequested!,
                            ).toLocaleString()}
                          />
                          <DataRow
                            name='Initials'
                            value={application.icaInitials}
                          />
                          <DataRow
                            name='Signed Date'
                            value={DateTime.fromMillis(
                              application.icaSignedAt,
                            ).toLocaleString()}
                          />
                          <DataRow name='Status'>
                            <label className='bg-green-100 text-green-600 rounded p-1 px-2 my-2'>
                              Agreement Signed
                            </label>
                          </DataRow>
                        </DataTable>
                      ) : (
                        <DataTable>
                          <DataRow name='Status'>
                            <label className='bg-yellow-100 text-yellow-600 rounded p-1 px-2 my-2'>
                              Signature Pending
                            </label>
                          </DataRow>
                        </DataTable>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div>
              <div>
                <SectionHeader
                  title='Onboarding Progress'
                  icon={<SvgIcon component={InfoIcon} fontSize='small' />}
                />
                <div className='mt-2'>
                  <Card>
                    <div className='p-4'>
                      <VerticalStepProgress
                        steps={stepsWithAction}
                        inProgressIndex={getIndexFromStatus(
                          application.nextStatus!,
                        )}
                      />
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <div>
            <SectionHeader
              title='Notes'
              icon={<SvgIcon component={InfoIcon} fontSize='small' />}
            />
            <div className='mt-2'>
              <NotesContainer
                entityId={id}
                entityType={CreateNoteRequestEntityTypeEnum.Application}
              />
            </div>
          </div>
        </div>
      </div>
      <ApplicationEditForm
        isOpen={openApplicationEditFormSidebarModal}
        onClose={() => setOpenApplicationEditFormSidebarModal(false)}
        application={application}
      />
      <ConfirmationModal
        isOpen={openApproveModal}
        variant='info'
        title='Approve Application'
        subtitle={`Are you sure you want to approve application of ${name}?`}
        onClose={() => setOpenApproveModal(false)}
      >
        <form onSubmit={handleSubmit(onApprove)}>
          <div className='my-2'>
            <ControlledAsyncMultiSelectInput
              control={control}
              errors={errors}
              name='mls'
              label='MLSs to Join'
              fetchData={async (search, page) => {
                const searchResponse = await performSearchRequest<
                  Pick<MlsResponse, 'id' | 'name'>
                >(
                  ['id', 'name'],
                  ['name'],
                  page,
                  SearchRequestTargetObjectEnum.Mls,
                  search,
                  {
                    order: [
                      {
                        asc: true,
                        column: 'name',
                      },
                    ],
                  },
                );

                const options: AsyncSelectOption[] = searchResponse.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.name}`,
                  }),
                );

                return options;
              }}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='my-2'>
            <ControlledAsyncMultiSelectInput
              control={control}
              errors={errors}
              name='board'
              label='Boards to Join'
              fetchData={async (search, page) => {
                const searchResponse = await performSearchRequest<
                  Pick<BoardResponse, 'id' | 'name'>
                >(
                  ['id', 'name'],
                  ['name'],
                  page,
                  SearchRequestTargetObjectEnum.Board,
                  search,
                  {
                    order: [
                      {
                        asc: true,
                        column: 'name',
                      },
                    ],
                  },
                );

                const options: AsyncSelectOption[] = searchResponse.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.name}`,
                  }),
                );

                return options;
              }}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='my-2'>
            <ControlledAsyncMultiSelectInput
              control={control}
              errors={errors}
              name='office'
              label='Offices to Join'
              fetchData={async (search, page) => {
                const searchResponse = await performSearchRequest<
                  Pick<OfficeResponse, 'id' | 'name'>
                >(
                  ['id', 'name'],
                  ['name'],
                  page,
                  SearchRequestTargetObjectEnum.Office,
                  search,
                  {
                    order: [
                      {
                        asc: true,
                        column: 'name',
                      },
                    ],
                  },
                );

                const options: AsyncSelectOption[] = searchResponse.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.name}`,
                  }),
                );

                return options;
              }}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-3 space-x-3'>
            <Button label='Approve' type='primary' buttonType='submit' />
            <Button
              label='Cancel'
              type='secondary'
              onClick={() => setOpenApproveModal(false)}
            />
          </div>
        </form>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={openRejectModal}
        variant='error'
        title='Reject Application'
        subtitle={`Are you sure you want to reject application of ${name}?`}
        onClose={() => {
          setOpenRejectModal(false);
        }}
      >
        <div className='mt-3 space-x-3'>
          <Button
            label='Reject'
            type='danger'
            onClick={() =>
              dispatch(
                rejectApplicationById(id, { agentId: application.agentId! }),
              )
            }
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setOpenRejectModal(false)}
          />
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={openTransferLicenseModal}
        variant='info'
        title='License Transfer'
        subtitle='Are you sure you want to mark license transfer as completed?'
        onClose={() => setOpenTransferLicenseModal(false)}
      >
        <div className='mt-3 space-x-3'>
          <Button
            label='Mark as Complete'
            type='primary'
            onClick={() => {
              dispatch(agentLicenseTransferred(application.id!));
              setOpenTransferLicenseModal(false);
            }}
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setOpenTransferLicenseModal(false)}
          />
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={openJoinBoardModal}
        variant='info'
        title='Join Board'
        subtitle='Are you sure you want to mark join the boards as completed?'
        onClose={() => {
          setOpenJoinBoardModal(false);
        }}
      >
        <div className='mt-3 space-x-3'>
          <Button
            label='Mark as Complete'
            type='primary'
            onClick={() => {
              dispatch(agentJoinedBoard(application.id!));
              setOpenJoinBoardModal(false);
            }}
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setOpenJoinBoardModal(false)}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default ApplicationFormContent;
