import React from 'react';
import ReactDOM from 'react-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/index.css';
import './assets/css/tooltip.css';
import { Provider } from 'react-redux';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './slices/store';
import config from './config';
import Route500 from './routes/Route500';
import Logger from './utils/Logger';

Bugsnag.start({
  apiKey: config.bugsnag.apiKey,
  plugins: [new BugsnagPluginReact()],
  releaseStage: config.bugsnag.releaseStage,
  enabledReleaseStages: ['production', 'stage', 'play'],
  appVersion: config.bugsnag.deployVersion,
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

Logger.debug(
  'Running version ',
  config.bugsnag.deployVersion,
  ' in ',
  config.bugsnag.releaseStage,
  ' environment.',
);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Route500}>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
