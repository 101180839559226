import React from 'react';

export interface DataRowProps {
  name: string;
  value?: any;
}

const DataRowCapitalized: React.FC<DataRowProps> = ({
  name,
  value,
  children,
}) => {
  return (
    <tr>
      <th className='border text-xs uppercase text-gray-700 text-left font-normal font-primary-medium tracking-wider p-2'>
        {name}
      </th>
      <td className='border p-2'>
        {value} {children}
      </td>
    </tr>
  );
};

export default DataRowCapitalized;
