import React from 'react';
import { Link } from 'react-router-dom';
import { CardItem } from '../types';
import Card from './Card';

export interface CardWithItemsProps {
  items: CardItem[];
  title?: string;
  bottomActionText?: string;
  onBottomActionClick?(): void;
}

const CardWithItems: React.FC<CardWithItemsProps> = ({
  items,
  bottomActionText,
  onBottomActionClick,
  title,
}) => {
  return (
    <Card
      bottomActionText={bottomActionText}
      title={title}
      onBottomActionClick={onBottomActionClick}
    >
      {items.map((item, index) => {
        return (
          <div key={item.name}>
            {items.length + 1 !== index && index > 0 && (
              <div className='border-b' />
            )}

            <div className='px-4 py-2 justify-between flex-row flex'>
              <div className='flex flex-col'>
                {item.url ? (
                  <Link to={item.url}>
                    <span className='text-primary font-semibold hover:underline'>
                      {item.name}
                    </span>
                  </Link>
                ) : (
                  <span className='font-primary-light text-gray-600'>
                    {item.name}
                  </span>
                )}
                <span className='text-gray-500 font-medium'>
                  {item.subtitle}
                </span>
              </div>
              <span className='font-primary-medium'>{item.value}</span>
            </div>
          </div>
        );
      })}
    </Card>
  );
};

export default CardWithItems;
