import classNames from 'classnames';
import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { ButtonTypeType } from './Button';

export type IconButtonVariant =
  | 'default'
  | 'slim'
  | 'pill'
  | 'outline'
  | 'danger'
  | 'no-outline';

export interface IconButtonProps {
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
  label?: string;
  onClick?(): void;
  variant?: IconButtonVariant;
  toolTipText?: string;
  buttonType?: ButtonTypeType;
  fullWidth?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  leftIcon,
  rightIcon,
  onClick,
  label,
  variant = 'default',
  toolTipText = '',
  buttonType = 'button',
  fullWidth,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  let textStyle: string;
  let buttonStyle: string;

  if (variant === 'outline') {
    textStyle = 'text-trueGray-700';
    buttonStyle =
      'bg-white border border-gray-200 ring-gray-200 ring-1 border-1';
  } else if (variant === 'no-outline') {
    textStyle = 'text-white';
    buttonStyle = 'bg-transparent hover:bg-trueGray-700';
  } else if (variant === 'pill') {
    textStyle = 'text-white';
    buttonStyle = 'bg-primary rounded-full px-2';
  } else if (variant === 'slim') {
    textStyle = 'text-white';
    buttonStyle =
      'bg-black border-black border border-black ring-black ring-1 border-1';
  } else if (variant === 'danger') {
    textStyle = 'text-white';
    buttonStyle =
      'bg-error border-error border border-error ring-error ring-1 border-1';
  } else {
    textStyle = 'text-white';
    buttonStyle =
      'bg-primary border-primary border border-primary ring-primary ring-1 border-1';
  }

  return (
    <button
      type={buttonType}
      onClick={onClick}
      ref={setTriggerRef}
      className={classNames(
        'flex flex-row rounded py-1 px-2 items-center focus:outline-none hover:opacity-90',
        buttonStyle,
        { 'w-full justify-center': fullWidth },
      )}
    >
      {leftIcon && (
        <span className={classNames('flex items-center', textStyle)}>
          {leftIcon}
        </span>
      )}
      {label && (
        <span
          className={classNames(
            'px-1 items-center whitespace-nowrap',
            textStyle,
          )}
        >
          {label}
        </span>
      )}
      {rightIcon && (
        <span className={classNames('flex items-center', textStyle)}>
          {rightIcon}
        </span>
      )}
      {visible && toolTipText && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip' })}>
          <div {...getArrowProps({ className: 't-arrow' })} />
          {toolTipText}
        </div>
      )}
    </button>
  );
};

export default IconButton;
