import React from 'react';
import { VictoryPie } from 'victory';

interface Data {
  fill?: string;
}

export interface CircleSplitChartProps<T> {
  data: T[];
  xKey: keyof T;
  yKey: keyof T;
  width: number;
}

const CircleSplitChart = <T extends Data>({
  data,
  width,
  xKey,
  yKey,
}: CircleSplitChartProps<T>): React.ReactElement => (
  <VictoryPie
    width={width}
    height={width * 0.9}
    innerRadius={70}
    labels={() => null}
    style={{
      data: {
        fill: ({ datum }) => datum.fill,
      },
    }}
    padding={40}
    data={data}
    x={`${xKey}`}
    y={`${yKey}`}
  />
);

export default CircleSplitChart;
