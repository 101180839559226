import React from 'react';
import { FormType, FormTypeToComponentMap } from '../utils/FormUtils';
import RealBrokerICAForm from './forms/RealBrokerICAForm';
import LetterOfIntentForm from './LetterOfIntentForm';
import CommercialICAForm from './CommercialICAForm';

interface FormBasedOnTypeProps {
  formType: FormType;
  onSubmit(values: any): void;
}

export const FORM_TYPE_TO_COMPONENT: FormTypeToComponentMap = {
  [FormType.ICA_STANDARD_V1]: RealBrokerICAForm,
  [FormType.COMMERCIAL_ICA]: CommercialICAForm,
  [FormType.LETTER_OF_INTENT]: LetterOfIntentForm,
};

const FormBasedOnType: React.FC<FormBasedOnTypeProps> = ({
  formType,
  onSubmit,
}) => {
  const FormComponent = FORM_TYPE_TO_COMPONENT[formType];

  if (!FormComponent) {
    return null;
  }

  return <FormComponent onSubmit={onSubmit} />;
};

export default FormBasedOnType;
