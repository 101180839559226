import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppThunk,
  ErrorCode,
  TransactionState,
  ITransactionsOverviewResponse,
} from '../types';
import {
  CreateTestTransactionRequest,
  EscrowRequest,
  EscrowResponse,
  SetPaymentsRequest,
  TransactionControllerApi,
  TransactionResponse,
  TransactionLifecycleStateValueStateEnum,
  TransactionsOverviewResponse,
  CreateParticipantRequest,
  UpdateParticipantRequest,
  UpdateTransactionRequest,
  AttachedFeeRequest,
  CommissionDocumentControllerApi,
  ParticipantResponse,
  UpdateTransactionVerifiedRequest,
} from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import MockRealApi from '../utils/MockRealApi';
import ErrorService from '../services/ErrorService';
import { readableTransitionName } from '../utils/TransactionHelper';
import { isEqualAndNotEmpty } from '../utils/StringUtils';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: TransactionState = {
  loadingTransactionOverview: false,
  transactionOverview: undefined,
  fetchTransactionOverviewErrorCode: null,
  loadingTransactionDetail: false,
  transactionDetail: undefined,
  fetchTransactionDetailErrorCode: null,
  loadingCommissionParticipants: false,
  fetchCommissionParticipantsErrorCode: null,
  escrowDetails: undefined,
  currentTransactionOverview: undefined,
};

const TransactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    changeLoadingTransactionOverview(state, action: PayloadAction<boolean>) {
      state.loadingTransactionOverview = action.payload;
    },
    errorFetchingTransactionOverview(state, action: PayloadAction<ErrorCode>) {
      state.fetchTransactionOverviewErrorCode = action.payload;
    },
    saveTransactionOverview(
      state,
      action: PayloadAction<ITransactionsOverviewResponse>,
    ) {
      state.transactionOverview = action.payload;
      state.fetchTransactionOverviewErrorCode = null;
    },
    changeLoadingTransactionDetail(state, action: PayloadAction<boolean>) {
      state.loadingTransactionDetail = action.payload;
    },
    errorFetchingTransactionDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchTransactionDetailErrorCode = action.payload;
    },
    saveTransactionDetail(state, action: PayloadAction<TransactionResponse>) {
      state.transactionDetail = action.payload;
      state.fetchTransactionDetailErrorCode = null;
    },
    changeLoadingCommissionParticipants(state, action: PayloadAction<boolean>) {
      state.loadingCommissionParticipants = action.payload;
    },
    errorFetchingCommissionParticipants(
      state,
      action: PayloadAction<ErrorCode>,
    ) {
      state.fetchCommissionParticipantsErrorCode = action.payload;
    },
    saveEscrowDetails(state, action: PayloadAction<EscrowResponse>) {
      const escrowIndex = state.transactionDetail?.escrows!.findIndex(
        (a) => a.id === action.payload.id,
      );

      if (escrowIndex !== -1) {
        state.transactionDetail!.escrows![escrowIndex!] = action.payload;
      } else {
        state.transactionDetail?.escrows!.push(action.payload);
      }
      state.fetchTransactionOverviewErrorCode = null;
    },

    saveCurrentTransactionDetails(
      state,
      action: PayloadAction<TransactionsOverviewResponse>,
    ) {
      state.currentTransactionOverview = action.payload;
    },
  },
});

export const {
  changeLoadingTransactionOverview,
  errorFetchingTransactionOverview,
  saveTransactionOverview,
  changeLoadingTransactionDetail,
  errorFetchingTransactionDetail,
  saveTransactionDetail,
  changeLoadingCommissionParticipants,
  errorFetchingCommissionParticipants,
  saveEscrowDetails,
  saveCurrentTransactionDetails,
} = TransactionSlice.actions;

export const createTestTransaction = (
  transactionData: CreateTestTransactionRequest,
): AppThunk<Promise<TransactionResponse | undefined>> => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).createTestTransactionUsingPOST(transactionData);
    dispatch(showSuccessToast(`Transaction was created successfully.`));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create test transaction', e, {
      transactionData,
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't create the transaction",
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
  return undefined;
};
export const getTransactionOverview = (id: number): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingTransactionOverview(true));
  try {
    const response = await new MockRealApi().transactionOverview(id);
    dispatch(saveTransactionOverview(response));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch transaction overview', e, {
      transaction: { id },
    });
    dispatch(errorFetchingTransactionOverview(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingTransactionOverview(false));
  }
};

export const fetchTransactionDetails = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingTransactionDetail(true));

  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getTransactionUsingGET(id);

    dispatch(saveTransactionDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch transaction details', e, {
      transaction: { id },
    });
    dispatch(errorFetchingTransactionDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const addAttachedFee = (
  transactionId: string,
  attachedFeeData: AttachedFeeRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).createAttachedFeeUsingPOST(transactionId, attachedFeeData);
    dispatch(saveTransactionDetail(data));
    dispatch(showSuccessToast('Successfully created the additional fee.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create additional fee', e, {
      escrow: { transactionId, attachedFeeData },
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't create that additional fee",
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const updateAttachedFee = (
  attachedFeeId: string,
  transactionId: string,
  attachedFeeData: AttachedFeeRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateAttachedFeeUsingPUT(attachedFeeId, transactionId, attachedFeeData);
    dispatch(saveTransactionDetail(data));
    dispatch(
      showSuccessToast('Successfully updated the additional fee details.'),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update the additional fee details', e, {
      escrow: { attachedFeeId, attachedFeeData },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the additional fee details.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const deleteAttachedFee = (
  attachedFeeId: string,
  transactionId: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).deleteAttachedFeeUsingDELETE(attachedFeeId, transactionId);
    dispatch(saveTransactionDetail(data));
    dispatch(showSuccessToast('Successfully deleted the additional fee.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to delete the additional fee details', e, {
      escrow: { attachedFeeId, transactionId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to delete the additional fee details.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const createEscrow = (
  transactionId: string,
  escrowDetails: EscrowRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).createEscrowUsingPOST(transactionId, escrowDetails);
    dispatch(saveEscrowDetails(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create escrow', e, {
      escrow: { transactionId, escrowDetails },
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't create that escrow payment",
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const updateEscrowDetails = (
  escrowDetails: EscrowRequest,
  escrowId: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateEscrowUsingPUT(escrowId, escrowDetails);
    dispatch(saveEscrowDetails(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update escrow details', e, {
      escrow: { escrowId, escrowDetails },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the escrow details.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const markEscrowAsReceived = (escrowId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      await getArrakisConfiguration(),
    ).receivedEscrowUsingPUT(escrowId);
    dispatch(saveEscrowDetails(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to mark escrow as completed', e, {
      escrow: { escrowId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to mark the escrow as received.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const disburseEscrow = (id: string): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).disburseEscrowUsingPUT(id);
    dispatch(saveTransactionDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch disburse escrow', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to disburse the escrow payments',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const addCommissionParticipant = (
  id: string,
  participant: CreateParticipantRequest,
): AppThunk<Promise<ParticipantResponse | undefined | void>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).addParticipantUsingPOST(id, participant);
    dispatch(saveTransactionDetail(data));

    return data.participants?.find(
      (p) =>
        isEqualAndNotEmpty(p.emailAddress!, participant.emailAddress!) ||
        isEqualAndNotEmpty(p.company!, participant.company!),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add participant', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update add the participant',
        ErrorService.getErrorCode(e),
      ),
    );
    return undefined;
  }
};

export const addOPCityCommissionParticipant = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).addOpcityParticipantUsingPOST(id);
    dispatch(saveTransactionDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add opcity participant', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to add the participant',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const removeCommissionParticipant = (
  transactionId: string,
  participantId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).removeParticipantUsingDELETE(participantId, transactionId);
    dispatch(saveTransactionDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to remove participant', e, {
      transaction: { id: transactionId },
      participant: { id: participantId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to remove the participant',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateCommissionPayouts = (
  id: string,
  payoutsData: SetPaymentsRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).setPayoutsUsingPUT(id, payoutsData);
    dispatch(saveTransactionDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update commission payouts', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update commission payouts',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};
export const transitionTransaction = (
  nextState: TransactionLifecycleStateValueStateEnum,
  id: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    switch (nextState) {
      // case TransactionResponseNextAdminTransitionsEnum.CancelApproved:
      // case TransactionResponseNextUserTransitionsEnum.CancelApproved:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      // case TransactionResponseNextAdminTransitionsEnum.CancelPending:
      // case TransactionResponseNextUserTransitionsEnum.CancelPending:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelPendingUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      case TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToCommissionDocumentApprovedUsingPUT(id);
          dispatch(saveTransactionDetail(data));
        }
        break;
      case TransactionLifecycleStateValueStateEnum.ApprovedForClosing:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToApprovedForClosingUsingPUT(id);
          dispatch(saveTransactionDetail(data));
        }
        break;
      // case TransactionResponseNextAdminTransitionsEnum.CommissionDocumentGenerated:
      // case TransactionResponseNextUserTransitionsEnum.CommissionDocumentGenerated:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      // case TransactionResponseNextAdminTransitionsEnum.CommissionDocumentSent:
      // case TransactionResponseNextUserTransitionsEnum.CommissionDocumentSent:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      case TransactionLifecycleStateValueStateEnum.Closed:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToClosedUsingPUT(id);
          dispatch(saveTransactionDetail(data));
        }
        break;
      case TransactionLifecycleStateValueStateEnum.CommissionValidated:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToCommissionValidatedUsingPUT(id);
          dispatch(saveTransactionDetail(data));
        }
        break;
      case TransactionLifecycleStateValueStateEnum.New:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToNewUsingPUT(id);
          dispatch(saveTransactionDetail(data));
        }
        break;
      case TransactionLifecycleStateValueStateEnum.PaymentAccepted:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToPaymentAcceptedUsingPUT(id);
          dispatch(saveTransactionDetail(data));
        }
        break;
      // case TransactionResponseNextAdminTransitionsEnum.PaymentScheduled:
      // case TransactionResponseNextUserTransitionsEnum.PaymentScheduled:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      case TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToReadyForCommissionDocumentGenerationUsingPUT(id);
          dispatch(saveTransactionDetail(data));
        }
        break;
      case TransactionLifecycleStateValueStateEnum.Terminated:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToTerminatedUsingPUT(id);
          dispatch(saveTransactionDetail(data));
        }
        break;
      // case TransactionResponseNextAdminTransitionsEnum.Settled:
      // case TransactionResponseNextUserTransitionsEnum.Settled:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      // case TransactionResponseNextAdminTransitionsEnum.WaitingOnPayment:
      // case TransactionResponseNextUserTransitionsEnum.WaitingOnPayment:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
    }
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      `Unable to transition the transaction to ${readableTransitionName(
        nextState,
      )}`,
      e,
      {
        transaction: { id, nextState },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        `We were unable to transition the transaction to ${readableTransitionName(
          nextState,
        )}`,
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const removeTransactionParticipant = (
  transactionId: string,
  participantId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).removeParticipantUsingDELETE(participantId, transactionId);
    dispatch(saveTransactionDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to remove participant', e, {
      transaction: { id: transactionId },
      participant: { id: participantId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to remove the participant',
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
  return true;
};

export const updateTransactionParticipant = (
  participantId: string,
  transactionId: string,
  values: UpdateParticipantRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateParticipantUsingPUT(participantId, transactionId, values);
    dispatch(saveTransactionDetail(data));
    dispatch(showSuccessToast('Participant updated!'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error updating the participant', e, {
      transaction: { id: transactionId },
      participant: { yentaId: participantId, values },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the participant',
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
  return true;
};

export const getCurrentTransactions = (yentaId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingTransactionOverview(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getCurrentTransactionsUsingGET(yentaId);
    dispatch(saveCurrentTransactionDetails(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch current transaction overview', e, {
      transaction: { yentaId },
    });
    dispatch(errorFetchingTransactionOverview(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingTransactionOverview(false));
  }
};

export const getProcessTransaction = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).processTransactionUsingGET(transactionId);
    dispatch(saveTransactionDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to process transaction', e, {
      transaction: { transactionId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to process transition the transaction.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const updateTransactionInformation = (
  id: string,
  values: UpdateTransactionRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateTransactionUsingPUT(id, values);
    dispatch(saveTransactionDetail(data));
    dispatch(showSuccessToast('The transaction was successfully updated.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error updating the transaction', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the transaction',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const resetFromSkySlope = (
  id: string,
  reset?: string,
): AppThunk => async (dispatch) => {
  const isReset = !reset ? 'Reset' : 'Sync';
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).resetSkyslopeUsingPUT(id, reset);
    dispatch(saveTransactionDetail(data));
    dispatch(showSuccessToast(`${isReset} successful`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error fetching from skyslope', e);
    dispatch(
      showErrorToastForErrorCode(
        `We were unable to ${isReset} the transaction from skyslope`,
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const downloadCDAForm = (
  referralId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new CommissionDocumentControllerApi(
      getArrakisConfiguration(),
    ).getPreSignedUrlUsingGET2(referralId);
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch CDA document', e);
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem fetching the CDA document',
        ErrorService.getErrorCode(e),
      ),
    );
    return undefined;
  }
};

export const uploadW9Form = (
  participantId: string,
  w9form: any,
): AppThunk => async (dispatch) => {
  try {
    await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).uploadW9UsingPUT(participantId, w9form);
  } catch (e) {
    ErrorService.notify('Unable to upload W9 form', e, {
      participant: { id: participantId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to upload W9 form',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateTransactionVerified = (
  transactionId: string,
  values: UpdateTransactionVerifiedRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateTransactionVerifiedUsingPUT(transactionId, values);
    dispatch(saveTransactionDetail(data));
    dispatch(showSuccessToast('Transaction verified updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error updating the transaction verified', e, {
      transaction: { transactionId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the transaction verified',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export default TransactionSlice.reducer;
