import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { get, set, merge, isObject, isArray, mergeWith, size } from 'lodash';
import { FormFieldConfig, InputFieldType } from '../../../types';
import ControlledSelectInput from '../../ControlledSelectInput';
import ControlledCheckboxInput from '../../ControlledCheckboxInput';
import ControlledRadioInput from '../../ControlledRadioInput';
import ControlledDatePickerInput from '../../ControlledDatePickerInput';
import ControlledTextInput from '../../ControlledTextInput';
import { FormDataType } from '../ApplicationForm';
import StepPagination from '../StepPagination';
import ApplicationProgressBarCard from '../../ApplicationProgressBarCard';
import {
  ApplicationFormFieldName,
  getFlattenedObjectWithDotNotation,
} from '../../../testUtils/OnboardingUtils';
import ControlledMultiSelectInput from '../../ControlledMultiSelectInput';
import ControlledPhoneNumberInput from '../../ControlledPhoneNumberInput';

export interface StepQuestionFormProps {
  questions: FormFieldConfig[];
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  setIsReviewing: (isReviewing: boolean) => void;
  formData: Partial<FormDataType>;
  setFormData(formData: Partial<FormDataType>): void;
}

const StepQuestionForm: React.FC<StepQuestionFormProps> = ({
  currentIndex,
  questions,
  setCurrentIndex,
  setIsReviewing,
  formData,
  setFormData,
}) => {
  const {
    errors,
    control,
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormDataType>();
  const stepsCompleted = size(getFlattenedObjectWithDotNotation(formData));
  const isFormFilled = stepsCompleted === questions.length;
  const activeField = questions[currentIndex];

  const onSubmit = (values: Partial<FormDataType>) => {
    setFormData(
      mergeWith(formData, values, (obj, src, key) => {
        if (
          (isObject(obj) && !isArray(obj)) ||
          key === ApplicationFormFieldName.DOES_BUSINESS_IN
        ) {
          return merge(obj, src);
        }
        return src;
      }),
    );
    if (currentIndex + 1 === questions.length || isFormFilled) {
      setIsReviewing(true);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const renderField = (currentField: FormFieldConfig) => {
    const readOnly: boolean =
      !!currentField.naMessage &&
      watch(currentField.name) === currentField.naMessage;

    if (currentField.type === InputFieldType.SELECT) {
      return (
        <ControlledSelectInput
          errors={errors}
          options={currentField.options || []}
          name={currentField.name}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
        />
      );
    }

    if (currentField.type === InputFieldType.CHECKBOX) {
      return (
        <ControlledCheckboxInput
          errors={errors}
          options={currentField.options || []}
          name={currentField.name}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
          inline
        />
      );
    }

    if (currentField.type === InputFieldType.RADIO) {
      return (
        <ControlledRadioInput
          errors={errors}
          options={currentField.options || []}
          name={currentField.name}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
          inline
        />
      );
    }

    if (currentField.type === InputFieldType.DATE) {
      return (
        <ControlledDatePickerInput
          errors={errors}
          datePickerConfig={currentField.datePickerConfig}
          name={currentField.name}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
        />
      );
    }

    if (currentField.type === InputFieldType.MULTISELECT) {
      return (
        <ControlledMultiSelectInput
          control={control}
          errors={errors}
          options={currentField.options!}
          placeholder={currentField.placeholder}
          name={currentField.name}
          rules={currentField.rules}
        />
      );
    }

    if (currentField.type === InputFieldType.PHONE) {
      return (
        <ControlledPhoneNumberInput
          errors={errors}
          name={currentField.name}
          defaultValue={currentField.defaultValue}
          control={control}
          rules={currentField.rules}
          placeholder={currentField.placeholder}
        />
      );
    }

    return (
      <ControlledTextInput
        errors={errors}
        name={currentField.name}
        defaultValue={currentField.defaultValue}
        control={control}
        rules={currentField.rules}
        placeholder={currentField.placeholder}
        readOnly={readOnly}
      />
    );
  };

  useEffect(() => {
    setValue(
      activeField.name,
      get(formData, activeField.name) || activeField.defaultValue || '',
    );
  }, [activeField.defaultValue, activeField.name, formData, setValue]);

  // if country is changed, the state is reset
  const country = watch(ApplicationFormFieldName.JURISDICTION_COUNTRY);
  useEffect(() => {
    const oldCountry = get(
      formData,
      ApplicationFormFieldName.JURISDICTION_COUNTRY,
    );
    if (country && oldCountry && country !== oldCountry) {
      setFormData(set(formData, ApplicationFormFieldName.DOES_BUSINESS_IN, []));
      setFormData(
        set(formData, ApplicationFormFieldName.SELECTED_PROVINCES, []),
      );
    }
  }, [country, formData, setFormData]);

  return (
    <form
      className='w-full px-4 lg:max-w-xl mx-auto mt-10 lg:mt-32'
      onSubmit={handleSubmit(onSubmit)}
    >
      {activeField.label && (
        <p className='text-xl font-primary-medium mb-2'>{activeField.label}</p>
      )}
      {activeField.secondaryLabel && (
        <p className='mb-3'>{activeField.secondaryLabel}</p>
      )}
      {activeField.helperText && (
        <p className='text-gray-500 text-sm mb-1'>
          {activeField.helperText}
          {!!activeField.rules?.required ? '*' : ' (optional)'}:
        </p>
      )}
      {renderField(activeField)}
      {activeField.naMessage && (
        <label className='flex items-center justify-start space-x-2 mt-3'>
          <input
            type='checkbox'
            className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
            value={activeField.naMessage}
            onChange={({ target: { checked, value } }) => {
              setValue(activeField.name, checked ? value : '');
            }}
            checked={watch(activeField.name) === activeField.naMessage}
          />
          <p>{activeField.naMessage}</p>
        </label>
      )}
      <div className='mt-5'>
        <StepPagination
          previousText={!isFormFilled && currentIndex !== 0 ? 'Previous' : ''}
          onPrevious={() => setCurrentIndex(currentIndex - 1)}
          nextText={
            currentIndex >= questions.length - 1 || isFormFilled
              ? 'Review'
              : 'Next'
          }
          onSkip={() => onSubmit({ [activeField.name]: undefined })}
          showSkip={!isFormFilled && !activeField?.rules?.required}
        />
      </div>
      <div className='fixed p-5 w-full lg:max-w-xs bottom-20 right-0'>
        <ApplicationProgressBarCard
          onStep={currentIndex + 1}
          completed={stepsCompleted}
          total={questions.length}
        />
      </div>
    </form>
  );
};

export default StepQuestionForm;
