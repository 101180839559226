import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { InfoOutlined } from '@material-ui/icons';
import { FormType } from '../../utils/FormUtils';
import { YesNoType } from '../../types';
import { ApplicationControllerApi, ICASignRequest } from '../../openapi/yenta';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { saveApplication } from '../../slices/AuthSlice';
import ErrorService from '../../services/ErrorService';
import RealHeader from '../../components/RealHeader';
import Alert from '../../components/Alert';
import FormBasedOnType from '../../components/FormBasedOnType';
import { deleteAuthCookie } from '../../utils/AuthUtils';
import { resetApp } from '../../slices/actions/authActions';

interface RezenICASigningRouteProps extends RouteComponentProps {}

const RezenICASigningRoute: React.FC<RezenICASigningRouteProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSubmit = async (values: any) => {
    try {
      const api = new ApplicationControllerApi(getYentaConfiguration());
      const icaSignRequest: Required<ICASignRequest> = {
        applicationId: 'ICA',
        customAnniversaryDate: values.customAnniversaryDate,
        emergencyContactEmailAddress: values.emergencyContactEmailAddress,
        emergencyContactName: values.emergencyContactName,
        emergencyContactPhoneNumber: values.emergencyContactPhoneNumber,
        emergencyContactRelationship: values.emergencyContactRelationship,
        initials: values.initials,
        entityName: values.entityName,
        name: values.name,
        referringAgentName: values.referringAgentName,
        signature: values.signature,
        optInStockPlan: values.optInStockPlan === YesNoType.YES,
      };

      const { data } = await api.signICAUsingPOST(icaSignRequest);
      dispatch(saveApplication(data));

      // This is important! We don't want them poking their heads around the admin panel in any way.
      deleteAuthCookie();
      dispatch(resetApp());

      history.push('/welcome-to-rezen/mobile-app');
    } catch (e) {
      ErrorService.notify('Unable to sign ICA', e);
      setErrorMessage(e.response.data.message);
    }
  };

  return (
    <div className='h-full-window overflow-y-auto w-full flex flex-col'>
      <RealHeader />
      <div className='flex justify-center items-center my-10 lg:mx-4 mx-0'>
        <div className='container'>
          <div className='text-center mx-2 lg:mx-0'>
            <p className='text-xl font-primary-medium'>
              INDEPENDENT CONTRACTOR AGREEMENT
            </p>
            <p className='text-gray-800'>
              Please Review and Sign the document below.
            </p>
          </div>
          {errorMessage && (
            <div className='my-5'>
              <Alert
                text={errorMessage}
                variant='error'
                icon={<InfoOutlined />}
              />
            </div>
          )}
          <div className='bg-gray-100 flex justify-center lg:p-16 lg:p-5 p-0 mt-10 mb-20 lg:mb-40'>
            <FormBasedOnType
              formType={FormType.ICA_STANDARD_V1}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RezenICASigningRoute;
