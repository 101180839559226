import { DateTime } from 'luxon';
import {
  SearchResponse,
  SearchResponseTargetObjectEnum,
} from '../../../openapi/arrakis';

export const DefaultTransactionsCDAPaginationResponse: SearchResponse = {
  pageNumber: 1,
  pageSize: 20,
  targetObject: SearchResponseTargetObjectEnum.CommissionDocument,
  responseColumns: [
    'id',
    'revision',
    'status',
    'anticipatedClosingDate',
    'createdAt',
  ],
  params: [],
  responseRows: [
    [
      '748376e8-0be8-48f4-93b6-b672185d8523',
      '1',
      'APPROVED',
      DateTime.fromISO('2020-08-23T23:22:10.082+02:00').toMillis(),
      DateTime.fromISO('2020-08-21T23:22:10.082+02:00').toMillis(),
    ],
    [
      '44ac5f37-3157-4197-9f5f-17def07dea75',
      '2',
      'INVALIDATED',
      DateTime.fromISO('2020-08-23T23:22:10.082+02:00').toMillis(),
      DateTime.fromISO('2020-08-21T23:22:10.082+02:00').toMillis(),
    ],
    [
      '7078b523-158d-4329-ab05-05a777758be9',
      '3',
      'PENDING',
      DateTime.fromISO('2020-08-23T23:22:10.082+02:00').toMillis(),
      DateTime.fromISO('2020-08-21T23:22:10.082+02:00').toMillis(),
    ],
  ],
  hasNext: true,
};
