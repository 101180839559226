import React, { useState } from 'react';
import EditOutlined from '@material-ui/icons/EditOutlined';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import CardWithItems from '../CardWithItems';
import { TransactionResponse } from '../../openapi/arrakis';
import { isTransactionReadOnly } from '../../utils/TransactionHelper';
import EditTransactionParticipantFormSidebarModal from '../../forms/EditTransactionParticipantFormSidebarModal';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import ChangeCdPayerSidebarModal from './ChangeCdPayerSidebarModal';

interface CdPayerInformationCardProps {
  transaction: TransactionResponse;
}

const CdPayerInformationCard: React.FC<CdPayerInformationCardProps> = ({
  transaction,
}) => {
  const cdPayer = transaction.cdPayer!;
  const [
    openEditParticipantFormSidebarModal,
    setOpenEditParticipantFormSidebarModal,
  ] = useState<boolean>(false);
  const [openChangeCdPayer, setOpenChangeCdPayer] = useState<boolean>(false);

  return (
    <div>
      <div className='flex flex-col'>
        <div className='py-2 flex justify-between'>
          <span className='text-xl text-gray-800 font-primary-medium'>
            Commission Payer
          </span>
          {!isTransactionReadOnly(transaction) && (
            <div className='flex flex-row flex-nowrap ml-2 space-x-2'>
              <button
                onClick={() => setOpenChangeCdPayer(true)}
                className='flex flex-row rounded items-center focus:outline-none text-primary'
              >
                <span className='text-primary'>
                  <SupervisorAccount fontSize='small' />
                </span>
                <span className='text-primary'>Change</span>
              </button>
              <button
                onClick={() => {
                  setOpenEditParticipantFormSidebarModal(true);
                }}
                className='flex flex-row rounded items-center focus:outline-none text-primary'
              >
                <span className='text-primary'>
                  <EditOutlined fontSize='small' />
                </span>
                <span className='text-primary'>Edit</span>
              </button>
            </div>
          )}
        </div>
        <CardWithItems
          items={[
            {
              name: 'Name',
              value: cdPayer?.firstName
                ? `${cdPayer?.firstName} ${cdPayer?.lastName}`
                : 'N/A',
            },
            { name: 'Company', value: cdPayer?.company || 'N/A' },
            { name: 'Email', value: cdPayer?.emailAddress || 'N/A' },
            {
              name: 'Phone',
              value: formatPhoneNumber(cdPayer?.phoneNumber) || 'N/A',
            },
            {
              name: 'Role',
              value: cdPayer?.role ? capitalizeEnum(cdPayer?.role) : 'N/A',
            },
          ]}
        />
      </div>
      <EditTransactionParticipantFormSidebarModal
        transaction={transaction}
        participant={cdPayer}
        isOpen={openEditParticipantFormSidebarModal}
        onClose={() => setOpenEditParticipantFormSidebarModal(false)}
      />
      <ChangeCdPayerSidebarModal
        isOpen={openChangeCdPayer}
        onClose={() => setOpenChangeCdPayer(false)}
        transaction={transaction}
      />
    </div>
  );
};

export default CdPayerInformationCard;
