import faker from 'faker';
import { times } from 'lodash';
import { DateTime } from 'luxon';
import { Mapping } from '../types';
import {
  AddressResponse,
  AddressResponseCountryEnum,
  AddressResponseStateOrProvinceEnum,
  AdministrativeAreaResponse,
  AdministrativeAreaResponseCountryEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  AgentPreviewResponse,
  AgentPreviewResponseTypeEnum,
  AgentTeamMembershipResponse,
  AgentTeamMembershipResponseRolesEnum,
  BoardPreviewResponse,
  MlsPreviewResponse,
  MoneyValue,
  MoneyValueCurrencyEnum,
  OfficePreviewResponse,
  OfficePreviewResponseTransactionTypeEnum,
} from '../openapi/yenta';
import {
  TransactionLifecycleStateValue,
  TransactionLifecycleStateValueStateEnum,
} from '../openapi/arrakis';

const memoizedResponses: Mapping<boolean> = {};
const ensureUnique = <T extends Pick<Object, 'toString'>>(func: () => T): T => {
  let response: T = func();
  while (memoizedResponses[response.toString()]) {
    response = func();
  }

  memoizedResponses[response.toString()] = true;

  return response;
};

export const fakeDate = (): DateTime => {
  return ensureUnique<DateTime>(() => DateTime.fromJSDate(fakeJsDate()));
};

export const fakeJsDate = (): Date => {
  return ensureUnique<Date>(() => faker.date.past());
};

export const fakeFutureDate = (): DateTime => {
  return ensureUnique<DateTime>(() => DateTime.fromJSDate(fakeFutureJsDate()));
};

export const fakeFutureJsDate = (): Date => {
  return ensureUnique<Date>(() => faker.date.future());
};

export const fakePastDate = (): DateTime => {
  return ensureUnique<DateTime>(() => DateTime.fromJSDate(fakePastJsDate()));
};

export const fakePastJsDate = (): Date => {
  return ensureUnique<Date>(() => faker.date.past());
};

export const fakeAddress = (): string => {
  return ensureUnique<string>(() => faker.address.streetAddress(true));
};

export const fakeAptNo = (): string => {
  return ensureUnique<string>(() => faker.address.secondaryAddress());
};

export const fakeStreetName = (): string => {
  return ensureUnique<string>(() => faker.address.streetName());
};

export const fakeEmail = (): string => {
  return ensureUnique<string>(() => faker.internet.email());
};

export const fakePhoneNumber = (): string => {
  return ensureUnique<string>(() => faker.phone.phoneNumberFormat(1));
};

export const fakePhoneNumberWithoutFormat = (): string => {
  return ensureUnique<string>(
    () => `${fakeNumber({ min: 1000000000, max: 9999999999 })}`,
  );
};

export const fakeState = (): string => {
  return ensureUnique<string>(() => faker.address.state());
};

export const fakeCity = (): string => {
  return ensureUnique<string>(() => faker.address.city());
};

export const fakeCompany = (): string => {
  return ensureUnique<string>(() => faker.company.companyName(0));
};

export const fakeLicenseNumber = (): string => {
  return ensureUnique<string>(
    () =>
      `${faker.random.alpha({
        count: 2,
        upcase: true,
      })}${faker.random.number({ min: 10000, max: 999999 })}`,
  );
};

export const fakeIdentificationNumber = (length = 10): string => {
  return ensureUnique<string>(() =>
    times(length)
      .map(() => fakeNumber({ min: 0, max: 9 }))
      .join(''),
  );
};

export const fakeZipCode = (): string => {
  return ensureUnique<string>(() => faker.address.zipCode('#####'));
};

export const fakeUrl = (): string => {
  return ensureUnique<string>(() => faker.internet.url());
};

export const fakeCommissionAmount = (): number => {
  return ensureUnique<number>(() =>
    faker.random.number({ min: 1000, max: 8000 }),
  );
};

export const fakeTeamName = (): string => {
  return ensureUnique<string>(() => faker.company.companyName(0));
};

export const fakePersonFullName = (): string => {
  return ensureUnique<string>(
    () => `${fakePersonFirstName()} ${fakePersonLastName()}`,
  );
};

export const fakePersonFirstName = (): string => {
  return ensureUnique<string>(() => faker.name.firstName());
};

export const fakeUsername = () => {
  return faker.random.alphaNumeric(12);
};

export const fakePersonLastName = (): string => {
  return ensureUnique<string>(() => faker.name.lastName());
};

export const randomEnum = <T>(myEnum: any): T => {
  return (faker.random.objectElement(myEnum) as unknown) as T;
};

export const fakeCommissionSplit = (): number => {
  return faker.random.number({ min: 5, max: 50, precision: 5 });
};

export const fakeProfileImage = (): string => {
  let manOrWoman = 'men';
  if (faker.random.number() % 2 === 0) {
    manOrWoman = 'women';
  }
  return `https://randomuser.me/api/portraits/${manOrWoman}/${faker.random.number(
    { min: 0, max: 99 },
  )}.jpg`;
};

export const fakeCompanyImage = (): string => {
  return `http://dummyimage.com/250x250.jpg/${faker.random.number({
    min: 100000,
    max: 999999,
  })}/${faker.random.number({ min: 100000, max: 999999 })}`;
};

export const randomBoolean = (): boolean => {
  return faker.random.boolean();
};

export const randomArrayElement = <T>(arr: T[]): T => {
  return faker.random.arrayElement<T>(arr);
};

export const fakeNumber = (options?: {
  min?: number;
  max?: number;
  precision?: number;
}): number => {
  return faker.random.number(options);
};

export const fakeWords = (count: number): string => {
  return ensureUnique<string>(() => faker.lorem.words(count));
};

export const fakeQRCodeImageUrl = (code: string) => {
  return `https://chart.googleapis.com/chart?cht=qr&chl=${code}&chs=180x180&choe=UTF-8&chld=L|2`;
};

export const fakeId = (): string => {
  return faker.random.uuid();
};

export const fakeFilePath = (): string => {
  return ensureUnique<string>(() => faker.system.filePath());
};

export const createFakeMoneyResponse = (
  money: Partial<MoneyValue> = {},
): MoneyValue => {
  const wrapper: Required<MoneyValue> = {
    amount: fakeNumber({ min: 1 }),
    currency: randomEnum<MoneyValueCurrencyEnum>(MoneyValueCurrencyEnum),
  };

  return { ...wrapper, ...money };
};

export const createAddressResponse = (
  address: Partial<AddressResponse> = {},
): AddressResponse => {
  const response: Required<AddressResponse> = {
    oneLine: fakeAddress(),
    city: fakeWords(2),
    country: randomEnum<AddressResponseCountryEnum>(AddressResponseCountryEnum),
    stateOrProvince: randomEnum<AddressResponseStateOrProvinceEnum>(
      AddressResponseStateOrProvinceEnum,
    ),
    streetAddress1: fakeStreetName(),
    streetAddress2: fakeAptNo(),
    type: 'home',
    zipOrPostalCode: fakeNumber({ min: 10000, max: 99999 }).toString(),
  };

  return { ...response, ...address };
};

export const createAdministrativeAreaResponse = (
  area: Partial<AdministrativeAreaResponse> = {},
): AdministrativeAreaResponse => {
  const administrativeArea: Required<AdministrativeAreaResponse> = {
    agentCanTransferLicense: randomBoolean(),
    country: randomEnum<AdministrativeAreaResponseCountryEnum>(
      AdministrativeAreaResponseCountryEnum,
    ),
    createdAt: fakePastDate().toMillis(),
    id: fakeId(),
    licenseTransferFee: createFakeMoneyResponse(),
    signupFee: createFakeMoneyResponse(),
    stateOrProvince: randomEnum<AdministrativeAreaResponseStateOrProvinceEnum>(
      AdministrativeAreaResponseStateOrProvinceEnum,
    ),
    updatedAt: fakePastDate().toMillis(),
  };

  return { ...administrativeArea, ...area };
};

export const createPreviewBoardResponse = (
  board: Partial<BoardPreviewResponse> = {},
): BoardPreviewResponse => {
  const response: Required<BoardPreviewResponse> = {
    administrativeArea: createAdministrativeAreaResponse(),
    code: fakeWords(1),
    createdAt: fakePastDate().toMillis(),
    email: fakeEmail(),
    id: fakeId(),
    logo: fakeProfileImage(),
    name: fakeTeamName(),
    phoneNumber: fakePhoneNumberWithoutFormat(),
    updatedAt: fakePastDate().toMillis(),
  };

  return { ...response, ...board };
};

export const createPreviewMlsResponse = (
  mls: Partial<MlsPreviewResponse> = {},
): MlsPreviewResponse => {
  const response: Required<MlsPreviewResponse> = {
    administrativeAreas: [createAdministrativeAreaResponse()],
    code: fakeWords(1),
    createdAt: fakePastDate().toMillis(),
    email: fakeEmail(),
    id: fakeId(),
    logo: fakeProfileImage(),
    name: fakeTeamName(),
    phoneNumber: fakePhoneNumberWithoutFormat(),
    updatedAt: fakePastDate().toMillis(),
  };

  return { ...response, ...mls };
};

export const createBrokerResponse = (
  override: Partial<AgentPreviewResponse> = {},
): AgentPreviewResponse => {
  const response: Required<AgentPreviewResponse> = {
    slug: '',
    needOnBoarding: false,
    licenseVerified: true,
    company: 'company',
    type: AgentPreviewResponseTypeEnum.Agent,
    skySlopePublicId: 'dsuio',
    tipaltiId: '8fj9',
    skySlopeId: '9',
    paymentInstructions: fakeWords(5),
    joinDate: fakePastDate().toISODate(),
    googleBusinessProfileURL: fakeUrl(),
    linkedInURL: fakeUrl(),
    yelpURL: fakeUrl(),
    zillowURL: fakeUrl(),
    clubhouseURL: fakeUrl(),
    about: fakeWords(10),
    createdAt: fakeDate().toMillis(),
    birthDate: fakeDate().toISODate(),
    facebookURL: fakeUrl(),
    firstName: fakePersonFirstName(),
    id: '1',
    instagramURL: fakeUrl(),
    lastName: fakePersonLastName(),
    middleName: fakePersonFirstName(),
    fullName: fakePersonFullName(),
    personalWebsiteURL: fakeUrl(),
    avatar:
      'avatar/f84c7644-511d-47be-b02c-d6c9ca471fc0_df9ff079-de72-4637-ae84-6497cae51b34.png',
    phoneNumber: fakePhoneNumber(),
    serviceAreaZipcodes: '10011',
    title: fakeWords(5),
    twitterURL: fakeUrl(),
    updatedAt: fakeDate().toMillis(),
    youtubeURL: fakeUrl(),
    emailAddress: 'emailaddressvalue@email.com',
  };

  return { ...response, ...override };
};

export const createPreviewOfficeResponse = (
  office: Partial<OfficePreviewResponse> = {},
): OfficePreviewResponse => {
  const response: Required<OfficePreviewResponse> = {
    usesTipalti: false,
    acceptsEscrow: true,
    cdaDocumentTitle: fakeWords(2),
    invoiceDocumentTitle: fakeWords(2),
    paysOtherAgent: randomBoolean(),
    emailAddress: fakeEmail(),
    managingBroker: createBrokerResponse(),
    address: createAddressResponse(),
    administrativeArea: createAdministrativeAreaResponse(),
    brokerageLicenseNumber: fakeLicenseNumber(),
    createdAt: fakePastDate().toMillis(),
    designatedBroker: createBrokerResponse(),
    faxNumber: fakePhoneNumberWithoutFormat(),
    id: fakeId(),
    mlsOfficeId: fakeIdentificationNumber(),
    name: fakeTeamName(),
    phoneNumber: fakePhoneNumberWithoutFormat(),
    transactionType: randomEnum<OfficePreviewResponseTransactionTypeEnum>(
      OfficePreviewResponseTransactionTypeEnum,
    ),
    updatedAt: fakePastDate().toMillis(),
    skySlopeGuid: fakeIdentificationNumber(),
  };

  return { ...response, ...office };
};

export const createAgentTeamMembershipResponse = (
  override: Partial<AgentTeamMembershipResponse> = {},
): AgentTeamMembershipResponse => {
  const response: Required<AgentTeamMembershipResponse> = {
    memberCommissionSplit: fakeNumber({ min: 10, max: 90 }),
    roles: [
      randomEnum<AgentTeamMembershipResponseRolesEnum>(
        AgentTeamMembershipResponseRolesEnum,
      ),
    ],
    team: {
      id: fakeId(),
      name: fakeTeamName(),
      type: fakeWords(1),
      updatedAt: fakeDate().toMillis(),
      createdAt: fakeDate().toMillis(),
    },
  };

  return { ...response, ...override };
};

export const createLifecycleState = (
  state: TransactionLifecycleStateValueStateEnum,
  override: Partial<TransactionLifecycleStateValue> = {},
): TransactionLifecycleStateValue => {
  const response: Required<TransactionLifecycleStateValue> = {
    state,
    description: fakeWords(5),
    descriptionToTransition: fakeWords(5),
  };

  return { ...response, ...override };
};
