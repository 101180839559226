import { times } from 'lodash';
import { IPaginateRes } from '../../../types';
import {
  fakeCommissionAmount,
  fakeDate,
  fakeId,
  fakeNumber,
  fakePersonFirstName,
  fakePersonFullName,
  fakePersonLastName,
  fakePhoneNumber,
  fakeProfileImage,
  fakeUrl,
  fakeWords,
  randomBoolean,
  randomEnum,
} from '../../../testUtils/FakerUtils';
import {
  TeamMemberResponse,
  TeamMemberResponseRolesEnum,
  UserPreviewResponseAgentStatusEnum,
  UserPreviewResponseTypeEnum,
} from '../../../openapi/yenta';

export const DefaultTeamAgentsPaginationResponse: IPaginateRes<TeamMemberResponse> = {
  data: times<Required<TeamMemberResponse>>(2, (id) => ({
    agent: {
      about: fakeWords(10),
      agentStatus: randomEnum<UserPreviewResponseAgentStatusEnum>(
        UserPreviewResponseAgentStatusEnum,
      ),
      anniversaryDate: fakeDate().toISODate(),
      birthDate: fakeDate().toISODate(),
      clubhouseURL: fakeUrl(),
      createdAt: fakeDate().toMillis(),
      emailAddress: 'emailaddressvalue@email.com',
      facebookURL: fakeUrl(),
      firstName: fakePersonFirstName(),
      googleBusinessProfileURL: fakeUrl(),
      id: `${id + 1}`,
      instagramURL: fakeUrl(),
      joinDate: fakeDate().toISODate(),
      keyMakerId: fakeId(),
      lastName: fakePersonLastName(),
      linkedInURL: fakeUrl(),
      middleName: fakePersonFirstName(),
      fullName: fakePersonFullName(),
      notifyLeadsInSMS: randomBoolean(),
      personalWebsiteURL: fakeUrl(),
      phoneNumber: fakePhoneNumber(),
      photo: fakeProfileImage(),
      receivesLeadsOnlyFromZipcodes: randomBoolean(),
      receivesPaymentEmailNotifications: randomBoolean(),
      receivesPaymentPushNotifications: randomBoolean(),
      receivesSupportNotifications: randomBoolean(),
      receivesTransactionEmailNotifications: randomBoolean(),
      receivesTransactionPushNotifications: randomBoolean(),
      skySlopeId: `${fakeId()}`,
      title: fakeWords(5),
      twitterURL: fakeUrl(),
      type: randomEnum<UserPreviewResponseTypeEnum>(
        UserPreviewResponseTypeEnum,
      ),
      updatedAt: fakeDate().toMillis(),
      yelpURL: fakeUrl(),
      youtubeURL: fakeUrl(),
      zillowURL: fakeUrl(),
    },
    memberCommissionSplit: fakeCommissionAmount(),
    roles: [
      randomEnum<TeamMemberResponseRolesEnum>(TeamMemberResponseRolesEnum),
    ],
  })),
  total: fakeNumber({ min: 50, max: 100 }),
};
