import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ErrorCode } from '../types';
import DetailPageLoader from '../components/DetailPageLoader';
import ResourceContainer from '../components/ResourceContainer';
import { TransactionControllerApi } from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';

interface Match {
  code: string;
}

interface TransactionDetailsByCodeRouteProps
  extends RouteComponentProps<Match> {}

const TransactionDetailsByCodeRoute: React.FC<TransactionDetailsByCodeRouteProps> = ({
  match,
  history,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const transactionCode = match.params.code;

  useEffect(() => {
    const fetchTransaction = async () => {
      setIsLoading(true);
      try {
        const { data } = await new TransactionControllerApi(
          getArrakisConfiguration(),
        ).getTransactionByCodeUsingGET(transactionCode);

        history.push(`/transactions/${data.id}`);
      } catch (e) {
        dispatch(showApiErrorModal(e));
        setErrorCode(ErrorService.getErrorCode(e));
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransaction();
  }, [transactionCode, history]);

  return (
    <ResourceContainer
      loading={isLoading}
      LoaderComponent={<DetailPageLoader />}
      isEmpty={false}
      errorCode={errorCode}
      resourceName='transaction'
    />
  );
};

export default TransactionDetailsByCodeRoute;
