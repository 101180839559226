import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormComponentProps } from '../utils/FormUtils';
import { RootState, YesNoType } from '../types';
import TamirsSignatureImg from '../assets/img/tamirs-signature.png';
import HighlightedDiv from './HighlightedDiv';
import ControlledDatePickerInput from './ControlledDatePickerInput';
import SubmitButton from './SubmitButton';
import ControlledTextInput from './ControlledTextInput';
import ControlledSignatureInput from './ControlledSignatureInput';
import ControlledRadioInput from './ControlledRadioInput';

export interface CommercialICAFormProps
  extends RouteComponentProps,
    FormComponentProps {}

const CommercialICAForm: React.FC<CommercialICAFormProps> = ({
  onSubmit,
  location,
}) => {
  const { sponsorName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const {
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  const { name, agentName } = watch(['name', 'agentName']);

  useEffect(() => {
    setValue('agentName', name);
  }, [name, setValue]);

  useEffect(() => {
    setValue('name', agentName);
  }, [agentName, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='container pl-16 pr-16'>
        <div className='bg-white lg:p-16 p-5 text-justify font-arial text-center'>
          <p className='font-primary-medium mb-10 text-center text-xl'>
            REAL BROKER COMMERCIAL, LLC
            <br />
            INDEPENDENT CONTRACTOR AGREEMENT
          </p>
          <div>
            This Independent Contractor Agreement (Agreement) is made between
            REAL BROKER COMMERCIAL, LLC, a Texas limited liability company
            (Company) operating under the assumed name “Real” and
            <div className='block lg:inline-block w-full max-w-xs lg:mx-2'>
              <HighlightedDiv>
                <ControlledTextInput
                  control={control}
                  label='Licensee Name'
                  errors={errors}
                  name='name'
                  defaultValue={userDetail?.fullName}
                  readOnly
                />
              </HighlightedDiv>
            </div>
            (Licensee). In consideration of the covenants and representations
            contained in this Agreement, Company and Licensee agree as follows:
          </div>
          <div className='mt-5'>
            <p className='font-bold'>1. TERM</p>
            <p className='mt-2'>
              This Agreement shall commence upon mutual execution of this
              Agreement and shall remain in effect for one (1) year and shall
              automatically be renewed for successive one (1) year terms, unless
              sooner terminated as provided in Paragraph 34 below. The first
              year shall be referred to as the Initial Term and each succeeding
              shall be referred to as a Renewal Term.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>2. COMPANY</p>
            <p className='mt-2'>
              Company is a duly licensed real estate broker under the laws of
              the States listed on the company website
              (realbrokercommercial.com). Company shall keep Company’s license
              current and in good standing during the term of this Agreement.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>3. LICENSEE</p>
            <p className='mt-2'>
              Licensee represents that he/she is duly licensed in at least one
              of the states listed on the joinreal.com website as a real estate
              broker or salesperson, and has not used any other names within the
              past five years. Licensee shall keep his/her real estate
              license(s) current and active during the terms of this Agreement,
              including satisfying all applicable continuing education and
              provisional license requirements of the state in which Licensee is
              licensed. Licensee hereby confirms he/she has provided Company
              true and accurate information on the Licensee contact Info section
              of Company’s website.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>4. COMPANY AND LICENSEE RELATIONSHIP</p>
            <div className='lg:ml-10 ml-5'>
              <p className='mt-2'>
                4.1 Company and Licensee are independent contracting parties and
                this agreement does not constitute an employment agreement by
                either party and shall not be construed as a partnership and
                Company shall not be liable for any obligation, injury,
                disability or liability incurred by Licensee. It is expressly
                understood by Company and Licensee that no employment
                relationships exist between Company and Licensee.
              </p>
              <p className='mt-2'>
                4.2. Licensee assumes and agrees to perform no other activities
                in association with Company, except to solicit and obtain
                listings and rents and sales of property for the parties mutual
                benefit, and to do so in accordance with the guidelines and
                requirements and with the ethical and professional standards as
                required by Licensee’s state of licensure with Company.
              </p>
              <p className='mt-2'>
                4.3. Company shall not limit Licensee’s activities to
                geographical areas, manner in which services are to be
                performed, with regard to hours, schedule, inventory, vacation
                or similar activities, except to the extent required by all
                applicable laws, policies, and procedures. Licensee agrees to be
                competent in any and all geographical areas that they serve as
                required by state licensing laws.
              </p>
              <p className='mt-2'>
                4.4. All listings of property, and all agreements, acts or
                actions for performance of licensed acts by Licensee, which are
                taken or performed in connection with this Agreement, shall be
                taken and performed in the name of Brokerage. Licensee agrees
                and does hereby contribute all right and title to such listings
                to Company for the benefit and use of Company, Licensee and
                other Licensees of Company. Licensee agrees to provide Company
                copies of all documents used in the closing of a transaction and
                all related documentation, including, but not limited to,
                Purchase and Sale Agreements, Listing agreements, Closing
                statements and Client information data within 72 hours of
                execution date.
              </p>
              <p className='mt-2'>
                4.5.Representation or promise made by Licensee on behalf of
                Company without a written authorization from Company shall not
                bind Company and shall be regarded as an unauthorized act by
                Licensee.
              </p>
              <p className='mt-2'>
                4.6. Licensee is considered to be an Independent Contractor for
                tax purposes and will be responsible for all tax issues at the
                end of each calendar year. Company will issue an IRS Form 1099
                to Licensee regardless of whether Company was issued one on
                behalf of a transaction Licensee was involved in. Company does
                not withhold taxes or Social Security contributions from
                Licensee’s compensation. Payment of taxes and Social Security
                contributions are Licensee’s sole responsibility. Licensee is
                considered an independent contractor and Company does not
                provide unemployment insurance or other employment benefits.
              </p>
            </div>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>5. BUSINESS EXPENSES: </p>
            <p className='mt-2'>
              Company shall not be liable to Licensee for any direct or indirect
              expenses incurred by Licensee or for any of its acts. Licensee
              agrees to provide and pay for all necessary professional licenses
              and dues. Licensee understands and agrees that Company shall not
              pay for any travel expenses, office space, place of business,
              communication charges, supplies, advertisements, marketing
              materials and that Licensee is responsible for conducting business
              at its own costs, if any. Company shall not be liable to reimburse
              Licensee for any expenses.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>6. LICENSED ACTIVITY: </p>
            <p className='mt-2'>
              Licensee shall be familiar with, and comply with all applicable
              federal, state and local laws, policies, and procedures,
              including, but not limited to anti-discrimination laws and
              restrictions against the giving or accepting a fee, or other
              things of value, for the referral of business to title companies,
              escrow companies, inspection companies, lenders and other
              settlement service providers pursuant to the applicable laws of
              Licensee’s state of licensure with Company and the Real Estate
              Settlement Procedures Act (RESPA).
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>7. ANNIVERSARY DATE AND GOOD STANDING</p>
            <div className='mt-2'>
              Licensee’s “join date” shall be the date on which the contractor
              completes the onboarding process and transfers his or her license
              to Company. The “anniversary date” for Licensee will be the first
              day of the calendar month following Licensee’s join date with the
              Company unless specified otherwise here: Join date is hereby set
              to{' '}
              <div className='block lg:inline-block w-full max-w-xs lg:mx-2'>
                <HighlightedDiv>
                  <ControlledDatePickerInput
                    name='customAnniversaryDate'
                    control={control}
                    errors={errors}
                    label='Join Date'
                    defaultValue={DateTime.local().toISODate()}
                    rules={{
                      required: 'Date required',
                    }}
                  />
                </HighlightedDiv>
              </div>
              ).
            </div>
            <p className='mt-2'>
              To be considered a Licensee in “good standing”, Licensee must be
              current on all financial obligations, including all fees and/or
              amounts owed to Company. In addition, all required licenses,
              local, state and national dues and subscriptions which are
              required to conduct real estate business in Licensee’s state(s)
              must be current and in effect.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>8. COMPENSATION / COMMISSION:</p>
            <div className='lg:ml-10 ml-5'>
              <div>
                <p className='mt-2'>
                  8.1 Unless otherwise agreed in a separate written agreement
                  between Company and Licensee, when Licensee performs any
                  services under this Agreement for which a commission or any
                  payment governed by applicable real estate license law is
                  earned and collected by Company (collectively, “Commission”),
                  Company shall pay Licensee as follows:
                </p>
                <p className='mt-2'>
                  Licensee will be at a commission split of 90/10, with 90% of
                  all commercial commissions going to the Licensee and 10% being
                  paid to Company until the commissions paid to Company total
                  $50,000, which is defined as the Agent Cap Amount. Referral
                  agreements will be paid from the gross amount unless stated
                  otherwise in the agreement.
                </p>
                <p className='mt-2'>
                  Once Company has retained the Agent Cap Amount during the
                  Initial Term or any Renewal Term from Commissions generated by
                  Licensee, Company will pay Licensee one hundred percent (100%)
                  of any additional Commission generated in that same term, less
                  the fees detailed in Paragraph 9 below.
                </p>
              </div>
              <p className='mt-2'>
                8.2. Licensee may use his/her discretion regarding the amount of
                Commission fee charged to clients for real estate brokerage
                services provided by Licensee. Licensee will obtain written
                permission from Company for any contemplated commission to
                client which is decidedly lower than the regular and customary
                commission charged by a full service broker in the territory
                Licensee does business.
              </p>
              <p className='mt-2'>
                In no event shall Company or Company’s directors and employees
                be personally liable to Licensee for Licensee’s share of
                commissions not collected, nor shall Licensee be entitled to any
                advance or payment from Company upon future commissions,
                Licensee’s only compensation being Licensee’s share of the
                Commission as described above. Nor shall Licensee be personally
                liable to Company
              </p>
              <p className='mt-2'>
                for any commission not collected. Licensee shall be responsible
                for any previously earned sales commission reimbursement,
                rebate, or refund that may be ordered by a Court of Law or by a
                professional Arbitration or Mediation Panel, for any reason.
              </p>
              <p className='mt-2'>
                8.3 In the event licensees with Company represent both the
                purchaser and seller of the same property address, each “side”
                will represent a separate transaction and will be subject to
                company splits and transaction fees per side.
              </p>
            </div>
          </div>{' '}
          <div className='mt-5'>
            <p className='font-bold'>9. FEES AND PAYMENT SCHEDULE:</p>
            <div className='lg:ml-10 ml-5'>
              <p className='mt-2'>
                9.1 Sign-up fee: $149 will be charged to Licensee upon signing
                this document
              </p>
              <p className='mt-2'>
                9.2 Brokerage fee. Company will charge a $500 brokerage fee paid
                in two equal installments out of the first two transactions
                following the last anniversary date. Each installment will be
                $250.
              </p>
              <p className='mt-2'>
                9.3 Payment/funding source: Both a primary and secondary/backup
                electronic funds transfer funding source, one of which must be
                an Automatic Clearing House (ACH) source, for payment of any and
                all recurring and non-recurring fees and amounts due from
                Licensee under this ICA must be on file and kept current. Unused
                portions of any fees previously paid will not be
                credited/prorated. By signing this document Licensee is giving
                Company permission to directly deduct the funding source on file
                for unpaid fees, charges, repayments, billbacks and any other
                amounts Licensee owes to Company and will be deducted directly
                from any and all of Licensee’s pending and future earnings that
                would otherwise be payable to Licensee by Company.
              </p>
              <p className='mt-2'>
                9.4 All Commissions collected by Company and due to Licensee,
                shall be paid to Licensee after deduction of expenses (if any,
                including wire transfer fees), unless otherwise expressed in
                this Agreement. Company may withhold payment to Licensee until
                such time the transaction and its corresponding file is closed
                and complete which determination shall be in Company’s absolute
                discretion. In case of a known or pending claim against Company
                or Licensee on transactions for which Licensee has yet to be
                paid, Company may withhold such payment of amounts on which
                Licensee could be responsible for under the terms of this
                agreement, until such claim is resolved. Licensee is not
                entitled to any advance payment by Company on behalf of future
                compensation. Company may withhold a portion of Licensee’s
                commission (or a commission in full) to offset sums owed to
                Company by Licensee. At no time is Licensee authorized to
                request, receive, ask or authorize any closing company or
                attorney to release a commission check directly to Licensee
                unless otherwise agreed in writing by Company. Unauthorized
                receipt of a commission check to Licensee is grounds for
                immediate termination.
              </p>
              <div className='mt-2'>
                <p className='mt-2'>
                  9.5 A “personal transaction” is any transaction for which
                  Licensee has an ownership interest in the subject property,
                  whether through Licensee’s own name and/ or a spouse’s name,
                  or a business entity, or revocable trust that is owned and/or
                  controlled by Licensee and/or Licensee’s spouse.
                </p>
                <ul className='list-disc lg:ml-10 ml-5 mt-2'>
                  <li>
                    Personal transactions will carry a $250 transaction fee.
                  </li>
                  <li>
                    Personal transaction commissions are not included in Revenue
                    Share Plan calculations.
                  </li>
                  <li>
                    Licensee may exempt up to three personal transactions per
                    anniversary year. ● Licenseemust be in good standing with
                    the Company.
                  </li>
                  <li>
                    (iv) Licensee’s name, or Licensee’s spouse’s name, or entity
                    controlled by Licensee or Licensee’s spouse, must be on the
                    title.
                  </li>
                </ul>
              </div>
              <p className='mt-2'>
                9.6 If the Licensee reaches the Agent Cap Amount, Company will
                not retain 10% of the commission from Licensee’s Transactions.
                Instead, the company will assess a Transaction Fee of $225 per
                Transaction. If the total commission exceeds $30,000 Company
                will retain 1% of commission. The Transaction Fee is “per
                Transaction” and not “per Agent”, and is always split between
                Agents on a Transaction equal to the percentage of Commission
                each Agent earns.
              </p>
            </div>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>
              10. COMMISSIONS ON LEASES, REFERRALS & BPOS:
            </p>
            <p className='mt-2'>
              Licensee may list a rental property on a commercial database site,
              such as CoSTAR, market it, and show it to potential tenants.
              Licensee must never accept a rental / lease payment or deposit.
              Any payments must go directly to the owner, property management or
              closing company. Licensee must never accept any type of
              compensation in regards to a commercial rental or lease
              transaction. The compensation must be paid to the Licensee through
              the Company unless otherwise agreed in writing by Company. The
              transaction must be reported to the Company within 48 hours.
              Referral fees shall only be paid to the Company in accordance with
              a written agreement between salespersons / brokers, approved by
              Company prior to close of any deals. All referral fees are to be
              paid out of the Licensee&apos;s gross share of commissions. BPOS
              are to be taken in the name of the Company and paid at the 90/10
              commission split. A social security number of the referring broker
              or EIN number is required, along with referring broker contact
              information and referral agreement.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>11. COMMISSION PAYMENT DELAYS: </p>
            <p className='mt-2'>
              Delays in commission payments to Company by closing/Title
              companies may result in delays in payment to Licensee.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>
              12. COMPENSATION UPON HIRE OR AFTER TERMINATION:{' '}
            </p>
            <p className='mt-2'>
              Upon termination of this agreement, payments under this section
              shall cease; provided, however, that so long as Licensee is not in
              default of any provision of this Agreement, Licensee shall be
              entitled to payments for periods or partial periods that occurred
              prior to the date of termination and for which Licensee has not
              yet been paid. Licensee authorizes Company to deduct from any
              commissions due at termination of this Agreement all financial
              obligations owed to Company that are imposed by terms of this
              Agreement. Furthermore, in the event Licensee leaves and has
              transactions pending that require further work normally rendered
              by the Licensee, Company shall make arrangements for State Broker
              or another licensee in the company to perform the required work,
              and the broker or licensee assigned shall be compensated for
              completing the details of pending transactions and such
              compensation shall be deducted from the terminated share of the
              commission. Agent cap ceases to exist once this agreement is
              terminated. Company shall retain 10% of commission checks for
              transactions that closed after the termination of this agreement.
            </p>
            <p className='mt-2'>
              In the event of a termination of this Agreement, all pending
              listings and pending purchase and sale agreements shall remain
              with Company unless Company sends a written document stating
              otherwise to Licensee. Company may, at their discretion, release
              any active listings to another broker upon a written request from
              the acquiring broker.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>13. PROPERTY MANAGEMENT</p>
            <p className='mt-2'>
              Property management, as described in this Agreement, is the
              professional management of another person’s real property.
              Property management, as described, is strictly prohibited.
              Salespersons who practice property management are subject to
              immediate termination.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>14. DOCUMENTS AND FILES: </p>
            <p className='mt-2'>
              All files and documents pertaining to listings, leads and
              transactions produced, received or retained by Licensee are the
              property of Company and shall be delivered to Company by Licensee
              according to the manner and term indicated in Company’s Policy and
              Procedure Manual. Failure to comply with this requirement may
              result in delay of commission payments.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>15. AUTOMOBILE INSURANCE: </p>
            <p className='mt-2'>
              Licensee shall, at all times, maintain automobile insurance
              coverage for liability and property damage with minimum coverage
              amounts of $100,000 / $300,000. Company shall be indemnified and
              held harmless against any claims or demands resulting from any
              automobile accident of Licensee or as a result of Licensee’s
              default in this paragraph.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>16. EARNEST MONEY DEPOSITS: </p>
            <p className='mt-2'>
              In certain states, Company does maintain a trust fund account.
              Earnest money deposits shall never be handled or deposited by
              Licensee. Licensee should notify the closing agent immediately to
              arrange for any earnest money deposits needing to be picked up or
              delivered and submitted on behalf of the client and inform the
              Company of such earnest money deposits. Licensee shall never
              accept ANY funds from clients, nor receive ANY cash payments from
              clients. All trust funds shall be handled in compliance with
              applicable local, state and federal laws. Company utilizes title
              company escrow accounts when available.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>
              17. FICTITIOUS BUSINESS NAMES AND LOGOS:
            </p>
            <p className='mt-2'>
              While affiliated with Company, Licensee shall use Company’s name
              “Real” and distinctive logo on Licensee’s business cards,
              advertising, signage, stationary, websites, and/or any other
              marketing materials, unless otherwise agreed to in writing.
              Licensee agrees that the Company retains exclusive rights to the
              “Real” and “Joinreal.com” trademark logo and graphics. Licensee
              agrees to discontinue the use of the Real trademark logo and
              graphics immediately upon the termination of this Agreement.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>18. ADVERTISING AND SOLICITATIONS: </p>
            <p className='mt-2'>
              Unless produced directly using Company’s online system, all
              advertising done by Licensee must receive prior written approval
              of Company. NO TELEPHONE SOLICITATION IS ALLOWED by Licensee to
              individuals who have registered their telephone numbers on a
              national do-not-call registry. Company is not liable or
              responsible for any advertising done by Licensee on its behalf and
              Licensee agrees to hold Company harmless of any costs, damages,
              legal or otherwise, specifically arising as a result of Licensee’s
              failure to comply with this requirement.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>19. LIABILITY AND HOLD HARMLESS: </p>
            <p className='mt-2'>
              In addition to all other legal or equitable remedies of Company,
              Licensee shall indemnify and hold Company and its owner(s),
              managers, affiliates, shareholders, directors, officers, agents,
              employees, successors, and assigns harmless from and against and
              shall reimburse the same with respect to any and all losses,
              damages, demands, claims, liabilities, costs, and expenses,
              including reasonable attorney fees (collective “Losses”), incurred
              by reason of or arising out of or in connection with any fraud or
              misrepresentation of Licensee, including, but not limited to,
              Licensee’s misrepresentation of its relationship with Company to
              any third party or any action by Licensee taken or omitted
              pursuant to this Agreement. Any such claims or costs payable
              pursuant to this Agreement are due to be paid in full by Licensee,
              who hereby agrees to indemnify and hold harmless Company or
              manager for all such sums.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>20. INJURIES TO LICENSEE: </p>
            <p className='mt-2'>
              Licensee acknowledges and agrees that Company does not provide
              workers compensation insurance for Licensee as the Licensee is an
              independent contractor. It is the Licensee&apos;s obligation to
              obtain appropriate insurance coverage for the benefit of Licensee
              and its employees, if any, for any injuries. Licensee and its
              employees waive any rights to recovery from Company for any
              injuries that Licensee and/or its employees may sustain while
              performing services under this Agreement.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>21. LICENSEE’S EMPLOYEES: </p>
            <p className='mt-2'>
              Licensee’s employees, if any, who perform services for Company
              under this Agreement shall also be bound by the provision of this
              Agreement. Licensee’s responsibilities include advising its
              employees of the relationship between Licensee and Company and the
              terms of this Agreement. Licensee is responsible for supervising
              Licensee employee’s activities to ensure their compliance with all
              terms of this Agreement. At the request of Company, Licensee shall
              provide evidence that such persons are Licensee’s employees and
              are bound by the provisions of this Agreement.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>22. WORKING PLACE: </p>
            <p className='mt-2'>
              Company does not provide an office space or other form of working
              space for the Licensee. Licensee may work from home, personal
              office or any other places of Licensee’s choice in accordance with
              applicable laws. However, Licensee is responsible to store all
              transaction documents in a safe place with a lock and be able to
              present them at the Company’s request within 24 hours. Licensee
              must be accessible by phone, fax, e-mail and postal mail at the
              numbers and addresses provided to Company by Licensee, and respond
              to voicemails within a maximum time frame of 24 hours.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>23. ACTIVITY REPORTING: </p>
            <p className='mt-2'>
              Licensee is required to report all his/her real estate related
              activities to the Company within two (2) business days of their
              occurrence. Urgent matters requiring Company’s involvement should
              be reported to Company immediately. Real estate activities include
              listing agreements, newly opened escrows (accepted purchase
              agreements), earnest money deposits escrow has handled, closings,
              cancelled and expired agreements, renewed agreements, referral fee
              agreements and/or any other business contract or arrangement
              involving a licensee and his/her client.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>24. E & O INSURANCE </p>
            <p className='mt-2'>
              Company’s E&O insurance covers all transactions made by the
              Company. Licensee is responsible for the deductible on any
              transaction brokered through Company that results in a lawsuit or
              claim. Such deductible may be as high as $10,000. Licensee hereby
              permits Company to collect any unpaid portion of such deductible
              from Licensee’s commissions. Licensee is subject to the first
              $5,000 unless the Company determines the agent was grossly
              negligent/fraudulent in their actions.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>25. USE OF A DBA AND FICTITIOUS NAMES: </p>
            <p className='mt-2'>
              Although agents and associates may use a DBA or Fictitious Name
              for their business to promote name awareness and/or identify team
              affiliation, approval for such name must be approved by the
              compliance officer of the Company. Additionally, after Compliance
              approves the name, the agent or associate must receive approval
              from their state licensing commission, if applicable. At a minimum
              Licensee is to receive approval from the state broker. The agent
              or associate is responsible for all paperwork, forms, and cost (if
              any) for registering such name with their licensing Commission.
              Please note that use of nicknames also falls under this rule.
              Without specific Commission approval, the name on your state
              license is the name you must use in your real estate business.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>26. AGENCY: </p>
            <p className='mt-2'>
              Although Real Broker Commercial LLC, permits agents and associates
              to engage in Seller Agency, Buyer Agency, Transaction Broker, and
              Dual Agency (where permitted), undisclosed dual agency, whether
              unintentional or accidental, is expressly prohibited. If agents or
              associates believe there is an agency conflict in a transaction or
              with a client representation, they must contact the Compliance
              Officer immediately (by emailing support@joinreal.com).
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>27. POLICIES AND PROCEDURES: </p>
            <p className='mt-2'>
              The Policies and Procedures Manual of Company is made part of this
              Independent Contractor Agreement. Licensee acknowledges that
              he/she has read, is knowledgeable about the contents of this
              document and will, where applicable, comply with its provisions.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>28. REWARD PROGRAMS DISCLOSURE: </p>
            <p className='mt-2'>
              From time to time, the Company will implement reward programs,
              such as production awards, and every Licensee in good standing
              will be eligible to participate under the terms specified for each
              program.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>29. REFERRAL AGENT </p>
            <div className='mt-2'>
              The referral agent, if any, is defined as the agent who most
              influenced the contractor to become an agent with the Company.
              Licensee acknowledges and verifies that the referral agent has not
              promised, enticed or provided the contractor with anything of
              value, including, but not limited to, lead generation platforms,
              CRM, coaching, trips, rebates or currency in return for naming
              referral agent. Licensee names
              <div className='block lg:inline-block w-full max-w-xs lg:mx-2'>
                <HighlightedDiv>
                  <ControlledTextInput
                    label='Referring Agent Name'
                    control={control}
                    name='referringAgentName'
                    errors={errors}
                    defaultValue={sponsorName}
                  />
                </HighlightedDiv>
              </div>
              as referral agent.
            </div>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>
              30. PROHIBITION AGAINST INSIDER TRADING:{' '}
            </p>
            <p className='mt-2'>
              The Licensee hereby acknowledges that the Licensee is aware, that
              United States securities laws and Canada securities laws prohibit
              any person who has material, non-public information about a
              company from purchasing or selling securities of such a company or
              from communicating such information to any other person under
              circumstances in which it is reasonably foreseeable that such
              person is likely to purchase or sell such securities. Licensee
              further acknowledges that information is material if it would
              reasonably be expected to result in a significant change in the
              market price or value of any of the Company’s securities.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>
              31. REVISIONS/MODIFICATIONS TO THIS AGREEMENT{' '}
            </p>
            <p className='mt-2'>
              The Company may, in its sole discretion, periodically change
              portions of this Agreement. For any material change of this
              agreement Company will provide Licensee a review period of 10
              days. At the end of the review period if Licensee has not notified
              Company of any objections to the changes in writing, Licensee is
              deemed to have accepted all revisions. The notice of any material
              changes will be sent to the email Company has on file for
              Licensee.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>32. ENTIRE AGREEMENT: </p>
            <p className='mt-2'>
              This Agreement constitutes the entire agreement between the
              Parties and fully supersedes any and all prior understandings,
              representations, warranties or agreements pertaining to the
              subject matter of this Agreement. No oral agreements,
              understandings or representations shall change, modify or amend
              any part of this Agreement. Any changes, modifications, or
              amendments of this Agreement will be shared publicly and become
              effective seven (7) days after posting.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>33. APPLICABLE LAW: </p>
            <p className='mt-2'>
              This Agreement shall be subject to and governed by the laws of the
              State of Texas, without regard or reference to its conflict of
              laws principles.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>34. TERMINATION OF AGREEMENT: </p>
            <p className='mt-2'>
              This Agreement may be terminated by either party, at any time,
              with or without cause upon written notice given to the other
              party. Even after termination, this Agreement shall govern all
              disputes and claims between Company and Licensee connected with
              their relationship under this Agreement, including obligations and
              liabilities arising from existing and completed listings,
              transactions, and services. This agreement shall be automatically
              terminated in the event Licensee’s real estate license is
              expired/revoked/canceled/becomes inactive for any reason or
              transferred to be associated with a different broker or is not
              affiliated with Company’s license.
            </p>
            <p className='mt-2'>
              In case of termination of this agreement, Licensee is required to
              provide Company all information, files and documents for closed,
              pending and current contracts with clients.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>35. DISPUTE RESOLUTION: </p>
            <p className='mt-2'>
              All disputes or claims between Licensee and other licensee(s)
              associated with Company or manager, or between Licensee and
              Company or manager, arising from or connected in any way with this
              Agreement, which cannot be adjusted directly between the parties
              involved, or by mediation, shall be submitted to the Association
              of REALTORS® in the Licensee’s state of licensure for arbitration
              pursuant to the provisions of its Bylaws, as may be amended from
              time to time, which are incorporated as a part of this Agreement
              by reference. If the Bylaws of the Association do not cover
              arbitration of the dispute, or if the Association declines
              jurisdiction over the dispute, then the arbitration shall be
              pursuant to the rules of law in the state in which the Licensee is
              licensed in. The Federal Arbitration Act, Title 9, U.S. Code,
              Section 1, et seq., shall govern this Agreement.
            </p>
          </div>
          <div className='mt-5'>
            <p className='font-bold'>36. CLASS ACTION WAIVER: </p>
            <p className='mt-2'>
              Company and Licensee agree that any and all claims pursued against
              each other will be on an individual basis, and not on behalf of or
              as a part of any purported class, collective, representative, or
              consolidated action. Both Company and Licensee hereby waive their
              right to commence, become a party to or remain a participant in
              any group, representative, class collective or hybrid
              class/collective or group action in any court, arbitration
              proceeding, or any other forum, against the other. The parties
              agree that any claim by or against Company or Licensee shall be
              heard in arbitration without joinder of parties or consolidation
              of such claim with any other person or entity&apos;s claim, except
              as otherwise agreed to in writing by Company and Licensee. This
              Class Action waiver shall supersede any contrary agreements,
              statements or rules in the JAMS Rules.
            </p>
          </div>
          <div className='mt-10'>
            Licensee’s hereby acknowledges reading and understanding this
            Agreement and Company’s Policies and Procedures manual in its
            entirety and agrees to abide, comply and respect the provisions set
            forth by this Agreement.
          </div>
          <div className='mt-10'>
            Licensee Name Tamir Poleg (CEO), Real
            <img
              src={TamirsSignatureImg}
              alt="Tamir's Signature"
              className='inline-block h-10'
            />
          </div>
        </div>
        <div className='bg-white lg:p-16 p-5 text-justify font-arial'>
          <p className='font-primary-medium mb-5 text-center text-xl'>
            ADDENDUM A - 2020 Agent Stock Purchase Plan Opt-In Form
          </p>
          <p className='mt-6'>
            Real Brokerage Inc (“REAX”) adopted the Agent Stock Initiatives
            (“the Initiatives”) on [date]. Pursuant to the Initiatives, REAX
            created the Agent Stock Purchase Plan (“the Plan”) to be
            administered by the board’s discretion, may issue shares of
            Real&apos;s restricted stock to the Company&apos;s agents who elect
            to participate (“Participants”).
          </p>
          <p className='mt-6'>
            <strong>Eligibility:</strong> All agents in good standing with the
            Company are eligible to participate in the Plan.
          </p>
          <p className='mt-6'>
            <strong>Issuance of Shares as Payment of Commission:</strong> By
            submitting this Opt-in Form, Participant authorizes the Company to
            set aside five percent (5%) of Participant&apos;s Net Commission
            (after splits, fees and any other withholdings) on Transactions
            which close in Participant&apos;s name, commencing with Transactions
            on or after Participant&apos;s Join Date, and before Participant has
            paid the full annual cap.
          </p>
          <p className='mt-4'>
            Participant authorizes the Company to set aside ten percent (10%) of
            Participant&apos;s Net Commission (after splits, fees and any other
            withholdings), upto a total cumulative amount of $19,000, on
            Transactions which close in Participant&apos;s name, commencing with
            Transactions after Participant&apos;s capping Transaction.
          </p>
          <p className='mt-6'>
            <strong>Price of Issued Shares:</strong>
            The price for shares issued under the Program shall be the fair
            market value of REAX&apos;s restricted stock, as determined by the
            closing market price of REAX&apos;s common stock on the last trading
            day of the month.
          </p>
          <p className='mt-6'>
            <strong>Issue Date: </strong>
            Shares under the Program shall be issued on the last trading day of
            the month during which the closing on the sales of any properties
            from which a Shares for Payment has been authorized results in an
            accumulated Shares for Payment of not less than $250 USD (each a
            “Issue Date”).
          </p>
          <p className='mt-6'>
            <strong>Custody of Shares:</strong>
            All shares issued under the Program shall initially be placed and
            held in an account created in the Participant&apos;s name with
            Computer share.
          </p>
          <p className='mt-6'>
            <strong>Associated Costs:</strong> Ownership of shares issued under
            the Program may come with associated costs imposed by third parties,
            including but not limited to, fees that may be imposed by a
            stockbroker, financial services broker of Participant’s choosing, or
            others.
          </p>
          <p className='mt-6'>
            <strong>Cancellation of Participation:</strong> Any Participant may
            cancel his or her participation in the Program by providing email
            notification of cancellation (“Cancellation Notice”) not less than
            thirty (30) calendar days prior to the next scheduled Issue Date. In
            order to be effective, signed cancellation Notices must be sent to{' '}
            <a
              href='mailto: support@joinreal.com'
              className='underline text-primary'
            >
              support@joinreal.com.
            </a>{' '}
            Once participation is cancelled by a Participant he/she may not
            elect to opt-in to the Agent Stock Purchase Plan until the following
            anniversary year.
          </p>
          <p className='mt-6'>
            <strong>Modification or Termination:</strong> The Program is subject
            to modification or termination at the discretion of the
            Company&apos;s Board of Directors.
          </p>
          <p className='mt-6'>
            <strong>Acknowledgments:</strong> Participant understands that
            participation in the Plan is subject to the terms and conditions
            contained in the Independent Contractor Agreement, in this Agent
            Stock Purchase Plan Opt in Form, in the Plan itself, and in the
            Initiatives. Participant has read and fully understands both the
            Plan and the Initiatives. By participating in the Plan, the
            Participant agrees to be bound by the terms and conditions of the
            ICA, the Plan and the Initiatives. By acceptance of this opportunity
            to receive shares, Participant consents to the electronic delivery
            of all related documents, including the Plan, the Initiatives, any
            account statements and Plan prospectuses, as applicable, and all
            other documents that REAX is required to deliver to its security
            holders (including, without limitation, annual reports and proxy
            statements) or other communications or information related to an
            investment in REAX&apos;s stock.
          </p>
          <p className='mt-4'>
            By signing this opt-in form, the Participant certifies that he or
            she is of legal age in the state or country of his or her residence.
          </p>
          <p className='mt-4'>
            Participant, by signing this opt-in form, certifies that:
            Participant is not subject to backup withholding because (a)
            Participant is exempt from backup withholding, or (b) Participant
            has been notified by the Internal Revenue Service (IRS) that
            Participant is not subject to backup withholding, or (c) the IRS has
            notified Participant that Participant is no longer subject to backup
            withholding.
          </p>
          <p className='mt-4'>
            Participant, by signing this opt-in form, certifies that:
            Participant is receiving the shares solely for the
            Participant&apos;s own account, and not for the benefit of any other
            person. Participant is being issued the shares solely for investment
            purposes and not with a view to distribution or resale, nor with the
            intention of selling, transferring or otherwise disposing of all or
            any part thereof for any particular price, or at any particular
            time, or upon the happening of any particular event or circumstance,
            except selling, transferring, or disposing of the shares, in full
            compliance with all applicable provisions of the Securities Act, the
            rules and regulations promulgated by the Securities and Exchange
            Commission thereunder, and applicable state securities laws.
          </p>
          <p className='mt-4'>
            Participant confirms that she or he has had the opportunity to ask
            questions of, and receive answers from, REAX or any authorized
            person acting on its behalf concerning REAX and its business, and to
            obtain any additional information, to the extent possessed by REAX
            (or to the extent it could have been acquired by REAX without
            unreasonable effort or expense) necessary to verify the accuracy of
            the information received by Participant.
          </p>
          <p className='mt-4'>
            Participant has carefully considered and has discussed (or accepts
            the responsibility to discuss) with its own legal, tax, accounting
            and financial advisors, to the extent the Participant has deemed
            necessary, the suitability of this investment and the transactions
            contemplated by this Agreement for the Participant’s particular
            federal, state, provincial, local and foreign tax and financial
            situation and has independently determined that this investment and
            the transactions contemplated by this Agreement are a suitable
            investment for the Participant. Participant understands that it (and
            not REAX ) shall be responsible for Participant’s own tax liability
            that may arise as a result of the receipt of the shares or the
            transactions contemplated by this Agreement.
          </p>
          <p className='mt-4'>
            Participant understands that participation in this Program does not
            change the at will nature of Participant’s independent contractor
            consulting relationship with the Company.
          </p>
          <p className='mt-6'>
            <strong>
              NO AGENT, BROKER OR ELIGIBLE INDIVIDUAL SHALL BE DEEMED A
              PARTICIPANT UNLESS AND UNTIL SUBMITTING THIS COMPLETED OPT-IN
              FORM.
            </strong>
          </p>

          <div className='mt-10'>
            <p>Please check the appropriate choice below and sign:</p>
            <div className='mt-5'>
              <HighlightedDiv>
                <ControlledRadioInput
                  control={control}
                  errors={errors}
                  name='optInStockPlan'
                  options={[
                    {
                      label: 'YES, I would like to participate in the Plan',
                      value: YesNoType.YES,
                    },
                    {
                      label:
                        'NO, I do not wish to participate in the Plan at this time.',
                      value: YesNoType.NO,
                    },
                  ]}
                  rules={{
                    required: 'Required',
                  }}
                />
              </HighlightedDiv>
            </div>
          </div>
        </div>
        <div className='bg-white lg:p-16 p-5 text-justify font-arial'>
          <p className='text-center text-xl'>
            <strong>Policy and Procedure Manual for Licensee</strong>
          </p>
          <p className='mt-4'>
            <strong>Introduction: </strong> Real Broker LLC or Real Brokerage
            Technologies Inc. or Real Broker NY LLC or Real Broker MA LLC or
            Real Broker CT LLC (“Company”) operating under the assumed name
            “Real” is a real estate broker licensed in the States of California,
            Colorado, Connecticut, Florida, Georgia, Illinois, Louisiana,
            Maryland, Massachusetts, Missouri, New Jersey, New York, North
            Carolina, Pennsylvania, Rhode Island, South Carolina, Tennessee,
            Texas, Virginia and District of Columbia.
          </p>
          <p className='mt-4'>
            As an innovative real estate brokerage, Real is committed to the
            highest standards of professionalism and service in order to achieve
            the highest level of customers&apos; satisfaction. Our policy and
            procedure manual outlines key issues important for our Licensees
            operation, however, it is important that you use your professional
            judgment and education you have obtained.
          </p>
          <p className='mt-4'>
            Licensees of Company are not employees of the company and are
            considered independent contractors, bound by an independent
            contractor agreement signed with Company which clearly outlines the
            relationship between Licensees and Company. As a Licensee, you are
            under the direction of a Company as results of the work, but not by
            the methods by which results are accomplished. Please use this
            document as a reference on how to conduct your daily business as the
            Company’s Licensee.
          </p>
          <p className='mt-6'>
            <strong>Real&apos;s Philosophy: </strong>Real strives to provide
            excellent service while constantly improving the professional skills
            and ethics of our employees and Licensees. We are dedicated to
            offering our clients the highest level of customer service, in order
            to create a brand associated with pure excellence.
          </p>
          <p className='mt-6'>
            <strong>License and education: </strong>Licensees are required to
            hold and maintain a valid Real Estate salesperson license or broker
            license, in good standing, for as long as Licensee is under
            independent contractor agreement with Company. If there is any
            change in your license status, such as expiration, revocation or
            suspension, you may not engage in any activity on behalf of the
            Company. It is Licensee&apos;s sole responsibility to comply with
            all applicable laws and education requirements of Licensee’s state
            of licensure real estate commission.
          </p>
          <p className='mt-6'>
            <strong>Working Place: </strong>
            Company does not maintain office spaces for the use of Licensees.
            You may work from home, personal office or any other place of your
            choice. However, you are responsible for storing all transaction
            documents in a safe place with a lock and to be able to present them
            at the Company&apos;s request within 24 hours. You must be
            accessible at the contact number and email address you provided to
            Company and respond no later than 24 hours after receipt of an email
            or voice message from Company.
          </p>
          <div className='mt-6'>
            <strong>Relationship of Licensee and Term: </strong>
            Licensee is not an employee, but rather an independent contractor as
            outlined in the Independent Contractor Agreement. You do not have an
            employee employer relationship with the Company. You are considered
            an independent contractor for all purposes. Except where it is
            otherwise expressly agreed in writing with the company, either party
            may terminate their association at any time with or without cause or
            reason. Company maintains the absolute rights to transfer, demote,
            suspend and administer independent contractor discipline at any
            time, for any reason. You are advised, however, that any violation
            of the following could be cause for immediate termination of your
            association:
            <ul className='list-disc ml-10 mt-4'>
              <li>The policies in this manual</li>
              <li>
                The terms of any Company-Licensee independent contractor
                agreement
              </li>
              <li>
                {' '}
                Any request or announcement communicated to you by Company
              </li>
              <li>The Real Estate License Law</li>
              <li>
                The laws of the State in which your real estate license was
                issued
              </li>
              <li>The REALTOR® Code of Ethics</li>
              <li>
                Conviction of any illegal act or any dishonest or unethical act
              </li>
              <li>
                Company is committed to Equal Opportunity in the workplace and
                maintains a policy to employ, retain, promote, terminate, and
                otherwise treat all Licensees, employees and job applicants on
                the basis of merit, qualifications, and competence.
              </li>
            </ul>
          </div>
          <p className='mt-6'>
            <strong>Licensee’s Employees: </strong>
            Licensee is obligated to inform Licensee&apos;s employees, if any,
            who perform, directly or indirectly services for Company, of their
            obligation to be bound by the provisions of this Policy and any
            other agreement between Company and Licensee. Licensee is
            responsible for notifying Licensee&apos;s employees about this
            obligation and supervise their activities to ensure they comply.
          </p>
          <p className='mt-6'>
            <strong>Rebates or Credits: </strong>
            Licensees may negotiate commissions with clients in accordance with
            the real estate laws in their state of licensure. Fees and
            commissions owed to Company by Licensee are calculated on the total
            “Gross” commissions paid on a transaction and not the rebated,
            discounted or credited commission amount paid. Buying or Selling
            your own property: Any transaction involving Licensee as buyer or
            seller must be reported, ahead of time, to Company as Licensee will
            have to address issues such as Company’s E&O insurance, property
            advertising and disclosure on Licensee’s license status in the
            contract. Is such case, Company should advise whether the Licensee
            may represent him/herself or should be represented by another
            Licensee of the Company.
          </p>
          <p className='mt-6'>
            <strong>Do-Not-Call, Spam and Fax: </strong>
            Licensees who make “cold calls” must comply with the requirements of
            the National Do-Not Call registry and Do-Not-Call Rules.
            Specifically, before making a cold call, a licensee must verify
            whether the number is on the national do-not-call registry.
            Licensees may not make “cold calls” to numbers in the registry.
            Licensee needs to update any download of the registry at least every
            31 days. Licensees are prohibited from engaging in any form of spam,
            email spam, mass faxing or emailing. If you are uncertain about the
            legitimacy or legality of an act you are planning to perform, please
            consult an attorney.
          </p>
          <p className='mt-6'>
            <strong>Discrimination: </strong>
            Company does not discriminate, does not allow any associated parties
            to discriminate and will not tolerate discrimination. Licensees must
            respect the law and not discriminate based on sex, color, religion,
            race, origin, disability, sexual orientation and family status.
            Licensees must assist all customers in meeting their real estate
            needs.
          </p>
          <p className='mt-6'>
            <strong>Compensation Disputes: </strong>
            If a dispute of any kind involving the compensation, including,
            among other things, payment of the compensation, the amount of the
            compensation, or the parties entitled to the compensation, exists
            between or among any of the following: Company, you and another
            broker, a principal, or any other party involved in a transaction,
            the compensation will be held by Company or until the dispute is
            resolved. You agree Company will incur no liability for withholding
            payment until all disputes are fully resolved. In the event of a
            termination of this Agreement, all listings and pending purchase and
            sale agreement shall remain with Company unless Company send a
            written document stating otherwise to Licensee.
          </p>
          <p className='mt-6'>
            <strong>Commission checks: </strong>
            At no time is Licensee to request, receive, ask or authorize any
            escrow company to release a commission check on a transaction they
            may be involved in unless authorized to do so by Company, in
            writing. A complete package of the transaction files must be sent to
            the Company prior to such request. Failure to comply with this
            regulation may result in immediate termination.
          </p>
          <p className='mt-6'>
            <strong>MLS Services: </strong>
            Multiple Listing Service (MLS) provides real estate professional
            listings and valuable information. You are obliged to join your
            local MLS and board of REALTORS. By joining MLS, you will be
            requested to comply with MLS rules which you may find in the
            respective Association of Realtors&apos; website. Failure to comply
            with MLS rules, may result in fines, suspension or other sanctions,
            to you and to the Company. You are responsible for any fine or
            punishment imposed on you or on Company as a result of your
            noncompliance.
          </p>
          <p className='mt-6'>
            <strong>Tax Reporting: </strong>
            Licensees are urged to maintain accurate records of all their
            earnings and expenses and provide them to the Internal Revenue
            Service at the end of each year as required by law. Licensees are
            advised to obtain professional tax advice regarding these matters.
          </p>
          <p className='mt-6'>
            <strong>Confidentiality: </strong>
            Company may provide you innovative proprietary tools and information
            that are only available to Company&apos;s Licensees. You agree to
            keep all information concerning Company&apos;s tools, online system,
            forms, website, marketing strategies, programs, legal information,
            brochures, clients, training materials and plans confidential at all
            times.
          </p>
          <p className='mt-6'>
            <strong>Error and Omissions Insurance: </strong>
            As expressed in our Independent Contractor Agreement, All real
            estate transactions are covered under our E&O insurance policy,
            which has a limit liability of $1.0M. Licensee is responsible for
            the deductible on any transaction that was brokered through Company
            and resulted in claim or lawsuit. Such deductible may be as high as
            $5000.
          </p>
          <p className='mt-6'>
            <strong>Lockboxes and Signs: </strong>
            Licensee is responsible for ordering, purchasing and handling his
            signs and lockboxes. Licensee should use only known and reliable
            vendors. Company may provide licensee with easy ordering tools
            directly from Licensee&apos;s dashboard on Company&apos;s website.
          </p>
          <div className='mt-6'>
            <strong>Litigation and Claims Handling: </strong>
            You are required to:
            <ul className='list-decimal ml-10 mt-4'>
              <li>
                Promptly notify your broker/ manager of any claim or potential
                claim made against you and or the company, including any demand
                received by you for money or services alleging a negligent act
                or omission; any notification of the commencement of a Lawsuit,
                arbitration or mediation process; or any written or verbal
                notice or threat that anyone intends to hold you and/or the
                company responsible for any alleged wrongdoing.
              </li>
              <li>Cooperate with the company in the defense of the claim.</li>
              <li>
                Promptly pay to the company any amounts due hereunder upon
                notice to you from the company in regards of such claim or
                lawsuit.
              </li>
              <li>
                The Company has the right to make all decisions concerning the
                defense of the claim, including choice of counsel. In the event
                you object to any decision made by the company, you may obtain
                your own attorney at your own expense; however, you shall not be
                relieved from the obligation to pay your portion of the cost of
                the claim as set forth herein.
              </li>
              <li>
                Except as provided below, the cost of the defense of the claim,
                or to defend or protect against any potential or possible claim
                where the company or you are not involved as a party, including
                attorney&apos;s fees, and the cost of any settlement or a
                judgment (collectively the &quot;costs of defense &quot;), shall
                be allocated between the company and you in the same percentages
                as per your Independent Contractor Agreement or prospective
                transaction that led to the claim, whether or not the
                transaction actually closed.
              </li>
              <li>
                You will be responsible for all costs of a claim if you fail to
                follow any law, regulation or company policy as set forth in
                this policy manual, and that failure results in a judgment or
                other final adjudication based on that failure.
              </li>
              <li>
                You will be solely responsible, and shall reimburse the company,
                for all the company&apos;s costs of defense if a judgment or
                other final adjudication on any claim adverse to the company
                and/or you establishes that dishonest, fraudulent, criminal, or
                malicious acts, errors or omissions were committed or results in
                a finding of intentional tort, slander, defamation or any
                conduct which leads to the imposition of punitive, exemplary or
                multiple damages, or fines or penalties, or establishes
                discrimination on the basis of race, creed, religion, ethnic
                background, national origin, age, sex, handicap, familial
                status, physical disability, sexual preference, or any other
                unlawful classification.
              </li>
            </ul>
          </div>
          <p className='mt-6'>
            <strong>Sexual Harassment Policy: </strong> Company disapproves of
            and does not tolerate sexual harassment of any kind. All licensees
            must avoid offensive or inappropriate sexual behavior and are
            responsible for ensuring that their workplace is free from sexual
            harassment at all times. Company prohibits: unwelcome sexual
            advances, requests for sexual acts or favors, with or without
            accompanying promises, threats or reciprocal favors or actions, or
            other verbal or physical conduct of a sexual nature which creates a
            hostile or offensive working environment. Complaints of sexual
            harassment will be promptly and carefully investigated, and all
            licensees are assured that they will be free from any reprisal or
            retaliation from filing such complaints.
          </p>
          <p className='mt-4'>
            Any licensee who has a complaint of sexual harassment by anyone
            should immediately bring the complaint to the attention of the
            Company. Company investigations will include interviews with all
            relevant persons, including the complainant, the accused, and other
            potential witnesses. Licensees are assured that the privacy of the
            complainant and the person accused of sexual harassment will be kept
            strictly confidential. The Company will review findings with the
            complainant at the conclusion of the investigation. If the
            investigation reveals that the complaint appears to be valid,
            immediate and appropriate corrective action, up to and including
            termination will be taken to stop the harassment and prevent its
            recurrence. If the validity of the complaint cannot be determined,
            immediate and appropriate action will be taken to assure that all
            parties are acquainted with this sexual harassment policy and to
            avoid sexual harassment in the future. The above policy also applies
            to harassment of any kind.
          </p>
          <div className='mt-6'>
            <p>
              <strong>
                Recommendations on Working with Clients and the Public:
              </strong>
            </p>
            <p className='mt-2'>
              Listing Presentations - All of your marketing efforts will be a
              waste of time if you don&apos;t have an effective listing or buyer
              presentation with your customer. New Licensees are welcome to seek
              assistance and we may provide such.
            </p>
            <p className='mt-4'>
              Please browse all available material on our website and your
              dashboard section of our website as you may find many valuable
              answers in it. If you need closer guidance, the Company may
              provide it to you, or may refer you to the relevant licensed
              individual who may assist you. Here are a few suggestions that may
              increase your effectiveness:
            </p>
            <ul className='list-disc ml-10 mt-4'>
              <li>
                Always be on time for appointments. Leave early and plan your
                schedule well. - Be positive, optimistic, confident and
                truthful. Be precise and do not exaggerate. - The law requires
                you to always leave a signed copy of any agreement with Seller.
                - It is recommended that you visit a property before showing it
                to customers. Being familiar with a property will assist you
                when showing it to clients.
              </li>
              <li>
                Make sure Sellers are doing their best to make their property
                show well. - Notify Sellers or other party&apos;s agents of any
                change in showing schedule. - When arriving at a showing,
                approach the door or lockbox first without your client and
                advise Seller of the situation.
              </li>
              <li>Be respectful to your client and to other parties.</li>
              <li>
                When using a lockbox, first approach the door and knock or use
                the doorbell. If there is no answer, enter and make sure there
                is no one there.
              </li>
              <li>
                Open the lights, curtains and make the property lightened and
                all details visible to you and your client.
              </li>
              <li>
                Spend enough time with your client at the property and advise
                your clients of any defects or issues that require your
                client&apos;s attention and appropriate disclosure.
              </li>
              <li>
                Do not leave your client unattended on the property as you may
                be blamed for missing items later on.
              </li>
              <li>
                When leaving, be sure to keep your card in a visible place and
                leave the property (including electric switches, alarms and
                curtains) in the same condition it was before.
              </li>
            </ul>
          </div>
          <div className='mt-6'>
            <strong>Offers To Purchase: </strong>
            <p className='mt-2'>
              Licensees are obligated to present all offers to the listing
              agent, even if the property is in escrow, unless the listing agent
              or seller has given you written instructions to the contrary. Upon
              receiving offers from buyers, review it thoroughly for
              completeness, accuracy and clarity. It&apos;s important to pay
              close attention to time limits set out in the offer, ESPECIALLY
              the time within which the seller must respond.
            </p>
            <p className='mt-4'>
              Next, you may want to call the listing agent, obtain any relevant
              background information about the buyer and clarify any ambiguities
              in the offer. Notify the listing agent promptly that you have
              received the offer. Then make an appointment as soon as possible
              to present the offer to the listing agent. Refer to the MLS Rules
              and Regulations for Selling Agent&apos;s right to present in
              person. As with all contracts, you must obtain all parties&apos;
              signatures in order for the contract to become effective. If a
              party signs on behalf of another, you must have evidence of that
              person&apos;s authority to do so in writing. If you must present
              an offer missing a signature, you must disclose this fact to the
              seller/listing agent. Be sure to condition the offer on the
              obtaining of any missing signature(s). If your clients receive a
              counter offer, be sure the terms are clear and complete. Be sure
              to review it against the offer to purchase.
            </p>
            <p className='mt-4'>
              NOTE: You must adhere to all time requirements stated in the
              purchase agreement and closing instructions unless all parties
              agree in writing to the contrary. Never use outdated forms. Loan
              Modifications: Company does not allow Licensees to engage in loan
              modifications and such activity is not covered by Company&apos;s
              E&O insurance policy.
            </p>
          </div>
          <p className='mt-6'>
            <strong>Communication with Company: </strong>
            Company may be reached in the manners specified at the “Contact Us”
            section of Company&apos;s website, at www.joinreal.com, at the
            Company&apos;s mobile application support chat, at Company&apos;s
            urgent phone line number or as otherwise informed by Company. All
            important announcements and notifications will be communicated by
            the Company via email, phone or text. It is imperative that you pay
            close attention to all emails arriving from Company and notify
            Company of any change in your contact information. In case of
            termination of your agreement with Company, you are required to
            provide Company all information, files and documents for closed,
            pending and current contracts with clients.
          </p>
          <div className='mt-5'>
            <p>
              Acknowledgement: I, the undersigned Licensee of Real Broker LLC or
              Real Brokerage Technologies Inc. or Real Broker NY LLC or Real
              Broker MA LLC or Real Broker CT LLC hereby acknowledge I have
              read, fully understood and agree to abide by this policy.
            </p>
            <div className='mt-20 grid lg:grid-cols-2 grid-cols-1 gap-10'>
              <ControlledTextInput
                label='Licensee Name'
                name='name'
                control={control}
                errors={errors}
                defaultValue={userDetail?.fullName}
                readOnly
              />
              <HighlightedDiv>
                <ControlledSignatureInput
                  label='Licensee Signature'
                  control={control}
                  name='signature'
                  errors={errors}
                  rules={{
                    required: 'Signature is required',
                  }}
                />
              </HighlightedDiv>
              <ControlledTextInput
                label='Email'
                control={control}
                name='email'
                defaultValue={userDetail?.emailAddress}
                errors={errors}
                readOnly
              />
              <ControlledDatePickerInput
                label='Date'
                name='date'
                control={control}
                errors={errors}
                defaultValue={DateTime.local().toISODate()}
                readOnly
              />
            </div>
            <div className='flex justify-end mt-10'>
              <SubmitButton
                label='Sign and Submit'
                isSubmitting={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                inline
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withRouter(CommercialICAForm);
