import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SidebarModal from '../components/SidebarModal';
import Button from '../components/Button';
import { updateTransactionVerified } from '../slices/TransactionSlice';
import { AppDispatch } from '../types';
import ControlledSelectInput from '../components/ControlledSelectInput';
import { TransactionResponse } from '../openapi/arrakis';

interface EditTransactionVerifiedSidebarModalProps {
  transaction: TransactionResponse;
  isOpen: boolean;
  onClose(): void;
}

interface FormData {
  verified: string;
}

const EditTransactionVerifiedSidebarModal: React.FC<EditTransactionVerifiedSidebarModalProps> = ({
  transaction,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    await dispatch(
      updateTransactionVerified(transaction.id!, {
        verified: values.verified === 'true',
      }),
    );
    onClose();
  };

  return (
    <SidebarModal
      title='Edit Transaction Verified'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-2 md:p-4'>
          <div>
            <ControlledSelectInput
              label='Transaction Verified'
              name='verified'
              control={control}
              errors={errors}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              defaultValue={`${transaction.verified}`}
              rules={{
                required: 'Transaction verified is required.',
              }}
            />
          </div>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Update'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EditTransactionVerifiedSidebarModal;
