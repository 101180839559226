import React, { useState } from 'react';
import { Column, Row } from 'react-table';
import { EditOutlined } from '@material-ui/icons';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import PageLayoutWithSearch from '../components/PageLayoutWithSearch';
import {
  MonitorEventResponse,
  MonitorEventResponseEventStatusEnum,
  SearchRequestTargetObjectEnum,
} from '../openapi/arrakis';
import { capitalizeEnum } from '../utils/StringUtils';
import { resourceTableFetchData } from '../utils/TableUtils';
import IconButton from '../components/IconButton';
import EditMonitoringEvent from '../components/monitoringEvents/EditMonitoringEvent';
import MonitorStatusStatusPill from '../components/table/Cells/MonitorStatusStatusPill';
import MonitorStatusInvestigationStatusPill from '../components/table/Cells/MonitorStatusInvestigationStatusPill';
import MonitorEventResponseStatusEnumSelectColumnFilter from '../components/table/Filters/MonitorEventResponseStatusEnumSelectColumnFilter';
import MonitorEventResponseInvestigationStatusEnumSelectColumnFilter from '../components/table/Filters/MonitorEventResponseInvestigationStatusEnumSelectColumnFilter';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';

export interface MonitorEventResponseModified extends MonitorEventResponse {
  status: MonitorEventResponseEventStatusEnum;
}

interface MonitorEventsRouteProps {}

export const columns: Array<Column<MonitorEventResponseModified>> = [
  {
    Header: 'ID',
    accessor: 'id',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Object Id',
    accessor: 'objectId',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Event Type',
    accessor: 'eventType',
    Cell: ({ value }) => capitalizeEnum(value!),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Object Type',
    accessor: 'objectType',
    Cell: ({ value }) => capitalizeEnum(value!),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <MonitorStatusStatusPill type={value!} />,
    Filter: MonitorEventResponseStatusEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Investigation Status',
    accessor: 'investigationStatus',
    Cell: ({ value }) => <MonitorStatusInvestigationStatusPill type={value!} />,
    Filter: MonitorEventResponseInvestigationStatusEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Triggered By',
    accessor: 'triggeredBy',
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Message',
    accessor: 'message',
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Retry Count',
    accessor: 'retryCount',
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
];

export const columnsToFetch = [
  ...columns.map((col) => col.accessor as string),
  'createdAt',
];
const columnsForSearch = ['triggeredBy'];

const MonitorEventsRoute: React.FC<MonitorEventsRouteProps> = () => {
  const [search, setSearch] = useState<string>();
  const [openEditMonitoringForm, setOpenEditMonitoringForm] = useState<boolean>(
    false,
  );
  const [eventData, setEventData] = useState<MonitorEventResponse | undefined>(
    undefined,
  );

  const columnsWithAction = [
    {
      Header: 'Edit',
      Cell: ({ row }: { row: Row<MonitorEventResponse> }) => (
        <IconButton
          leftIcon={<EditOutlined fontSize='small' titleAccess='Edit' />}
          onClick={() => {
            setEventData(row.original);
            setOpenEditMonitoringForm(true);
          }}
        />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    ...columns,
  ];

  return (
    <PageLayoutWithSearch
      path={[
        { title: 'Home', url: '/' },
        { title: 'Events', url: '/events' },
      ]}
      search={search!}
      onSearchChange={setSearch}
    >
      <div className='px-4 lg:py-5'>
        <ResourceIndexContainer<MonitorEventResponseModified>
          header='Events'
          columns={columnsWithAction}
          hiddenColumns={['id']}
          search={search!}
          resourceName='events'
          initialSort={{ createdAt: 'desc' }}
          fetchData={(req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              columnsForSearch,
              SearchRequestTargetObjectEnum.MonitoringEvent,
              'arrakis',
            )
          }
        />
      </div>
      <EditMonitoringEvent
        isOpen={openEditMonitoringForm}
        onClose={() => setOpenEditMonitoringForm(false)}
        eventData={eventData}
      />
    </PageLayoutWithSearch>
  );
};

export default MonitorEventsRoute;
