import qs from 'qs';
import {
  Board,
  Escrow,
  IPaginateReq,
  IPaginateRes,
  MLS,
  OfficeIndexResponse,
  People,
  Team,
  Transaction,
  TransactionCDA,
} from '../types';
import { DefaultAgentsPaginationResponse } from '../fixtures/api/DefaultAgentPaginationResponse';
import { DefaultPeoplePaginationResponse } from '../fixtures/api/people/DefaultPeoplePaginationResponse';
import { DefaultOfficeIndexPaginationResponse } from '../fixtures/api/office/DefaultOfficePaginationResponse';
import { DefaultMLSResponse } from '../fixtures/api/mls/DefaultMLSResponse';
import { DefaultTeamPaginationResponse } from '../fixtures/api/team/DefaultTeamPaginationResponse';
import { DefaultTransactionsPaginationResponse } from '../fixtures/api/transactions/DefaultTransactionsPaginationResponse';
import { DefaultTeamAgentsPaginationResponse } from '../fixtures/api/team/DefaultTeamAgentsPaginationResponse';
import { DefaultTransactionsCDAPaginationResponse } from '../fixtures/api/transactions/DefaultTransactionsCDAPaginationResponse';
import {
  ApplicationResponse,
  TeamMemberResponse,
  SearchResponse as SearchResponseYenta,
} from '../openapi/yenta';
import { DefaultApplicationPaginationResponse } from '../fixtures/api/applications/DefaultApplicationPaginationResponse';
import { DefaultBoardsResponse } from '../fixtures/api/boards/DefaultBoardsResponse';
import { DefaultEscrowPaginationResponse } from '../fixtures/api/transactions/DefaultEscrowPaginationResponse';

import { SearchResponse } from '../openapi/arrakis';
import Logger from './Logger';
import BaseMockRealAPI from './BaseMockRealAPI';

export class PaginateMockRealAPI extends BaseMockRealAPI {
  public agentPagination(
    req: Required<SearchResponseYenta>,
  ): Promise<Required<SearchResponseYenta>> {
    Logger.debug('calling api /agents?' + qs.stringify(req));
    return (this.returnMock<Required<SearchResponseYenta>>(
      DefaultAgentsPaginationResponse,
    ) as unknown) as Promise<Required<SearchResponseYenta>>;
  }

  public peoplePagination(
    req: IPaginateReq<People>,
  ): Promise<IPaginateRes<People>> {
    Logger.debug('calling api /people?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<People>>(
      DefaultPeoplePaginationResponse,
    ) as unknown) as Promise<IPaginateRes<People>>;
  }

  public officePagination(
    req: IPaginateReq<OfficeIndexResponse>,
  ): Promise<IPaginateRes<OfficeIndexResponse>> {
    Logger.debug('calling api /offices?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<OfficeIndexResponse>>(
      DefaultOfficeIndexPaginationResponse,
    ) as unknown) as Promise<IPaginateRes<OfficeIndexResponse>>;
  }

  public mlsPagination(req: IPaginateReq<MLS>): Promise<IPaginateRes<MLS>> {
    Logger.debug('calling api /mls?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<MLS>>(
      DefaultMLSResponse,
    ) as unknown) as Promise<IPaginateRes<MLS>>;
  }

  public boardsPagination(
    req: IPaginateReq<Board>,
  ): Promise<IPaginateRes<Board>> {
    Logger.debug('calling api /boards?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<Board>>(
      DefaultBoardsResponse,
    ) as unknown) as Promise<IPaginateRes<Board>>;
  }

  public teamPagination(req: IPaginateReq<Team>): Promise<IPaginateRes<Team>> {
    Logger.debug('calling api /teams?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<Team>>(
      DefaultTeamPaginationResponse,
    ) as unknown) as Promise<IPaginateRes<Team>>;
  }

  public transactionPagination(
    req: IPaginateReq<Transaction>,
  ): Promise<IPaginateRes<Transaction>> {
    Logger.debug('calling api /transactions?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<Transaction>>(
      DefaultTransactionsPaginationResponse,
    ) as unknown) as Promise<IPaginateRes<Transaction>>;
  }

  public transactionCDAPagination(
    req: IPaginateReq<TransactionCDA>,
  ): Promise<IPaginateRes<TransactionCDA>> {
    Logger.debug('calling api /transactions-cda?' + qs.stringify(req));
    return (this.returnMock<SearchResponse>(
      DefaultTransactionsCDAPaginationResponse,
    ) as unknown) as Promise<IPaginateRes<TransactionCDA>>;
  }

  public teamAgentsPagination(
    req: IPaginateReq<TeamMemberResponse>,
  ): Promise<IPaginateRes<TeamMemberResponse>> {
    Logger.debug('calling api /team/:id?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<TeamMemberResponse>>(
      DefaultTeamAgentsPaginationResponse,
    ) as unknown) as Promise<IPaginateRes<TeamMemberResponse>>;
  }

  public agentApplicationPagination(
    req: IPaginateReq<ApplicationResponse>,
  ): Promise<IPaginateRes<ApplicationResponse>> {
    Logger.debug('calling api /application-form?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<ApplicationResponse>>(
      DefaultApplicationPaginationResponse,
    ) as unknown) as Promise<IPaginateRes<ApplicationResponse>>;
  }

  public escrowPagination(
    req: IPaginateReq<Escrow>,
  ): Promise<IPaginateRes<Escrow>> {
    Logger.debug('calling api /escrow?' + qs.stringify(req));
    return (this.returnMock<IPaginateRes<Escrow>>(
      DefaultEscrowPaginationResponse,
    ) as unknown) as Promise<IPaginateRes<Escrow>>;
  }
}
