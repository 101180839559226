import { UseControllerOptions } from 'react-hook-form';
import { EMAIL_REGEX, PHONE_REGEX } from './StringUtils';

type Validation = UseControllerOptions['rules'];

export const PASSWORD_VALIDATIONS: Validation = {
  minLength: {
    value: 6,
    message: 'Password must have a minimum of 6 characters',
  },
  required: 'Password is required',
};

export const PHONE_NUMBER_VALIDATIONS: Validation = {
  pattern: {
    value: PHONE_REGEX,
    message: 'Please enter a valid phone number',
  },
};

export const EMAIL_VALIDATIONS: Validation = {
  pattern: {
    value: EMAIL_REGEX,
    message: 'Invalid email address',
  },
};
