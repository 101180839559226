import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { NoteAdd } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { get, sum } from 'lodash';
import { numberToMoney } from '../../utils/CurrencyUtils';
import Button from '../Button';
import IconButton from '../IconButton';
import SidebarModal from '../SidebarModal';
import { capitalizeEnum, isStringEmpty } from '../../utils/StringUtils';
import Avatar from '../Avatar';
import ControlledTextInput from '../ControlledTextInput';
import CustomSwitch from '../CustomSwitch';
import Crown from '../Icons/Crown';
import {
  MoneyValue,
  MoneyValueCurrencyEnum,
  ParticipantPaymentValue,
  ParticipantResponse,
  ParticipantResponseParticipantRoleEnum,
  PercentageValue,
} from '../../openapi/arrakis';
import { updateCommissionPayouts } from '../../slices/TransactionSlice';
import Logger from '../../utils/Logger';
import { getParticipantName } from '../../utils/ParticipantHelper';
import AddParticipantsForm from './AddParticipantsForm';

interface CommissionSplitFormProps {
  isOpen: boolean;
  onClose(): void;
  totalCommission: number;
  propertyName: string;
  transactionId: string;
  participants: ParticipantResponse[];
}

interface IFormValues {
  commissionParticipant: {
    percent?: PercentageValue;
    money?: MoneyValue;
    isPercentage: boolean;
    id: string;
  }[];
}

const CommissionSplitForm: React.FC<CommissionSplitFormProps> = ({
  isOpen,
  onClose,
  propertyName,
  totalCommission,
  transactionId,
  participants,
}) => {
  const dispatch = useDispatch();
  const defaultValues: any = useMemo<IFormValues>(
    () => ({
      commissionParticipant:
        participants.map((participant) => {
          return {
            ...(!!participant.payment?.percent
              ? {
                  percent: {
                    value: parseFloat(
                      (participant.payment?.percent * 100).toFixed(2),
                    ),
                    string: `${(participant.payment?.percent * 100).toFixed(
                      2,
                    )}%`,
                  },
                }
              : {
                  money: participant.payment?.amount,
                }),
            isPercentage: !participant.payment?.amount,
            id: participant.id!,
          };
        }) || [],
    }),
    [participants],
  );
  const {
    control,
    errors,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting, isValid },
  } = useForm<any>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { fields } = useFieldArray({
    control,
    name: 'commissionParticipant',
  });
  const watchParticipants = watch('commissionParticipant', []);
  const totalPercentage = sum(
    watchParticipants
      .filter((p: any) => p.isPercentage)
      .map((item: any) => +item.percent?.value || 0) || 0,
  );
  const totalAmount = sum(
    watchParticipants
      .filter((p: any) => !p.isPercentage)
      .map((item: any) => +item.money?.amount || 0),
  );
  const [
    openParticipantsSidebarModal,
    setOpenParticipantsSidebarModal,
  ] = useState<boolean>(false);
  const invalidPercent =
    totalAmount < totalCommission && totalPercentage !== 100;
  const isInvalidAmount =
    totalAmount > totalCommission || invalidPercent || !isValid;
  const isConsiderDollar =
    watchParticipants.every((p: any) => !p.isPercentage) ||
    totalAmount === totalCommission;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (data: any) => {
    let finalData: Array<ParticipantPaymentValue> = data.commissionParticipant?.map(
      (item: any) => {
        let moneyValue: MoneyValue | undefined = undefined;
        let percentageValue: PercentageValue | undefined = undefined;

        if (item.isPercentage && !isStringEmpty(item.percent.value)) {
          percentageValue = {
            value: item.percent.value,
            string: `${item.percent.value}%`,
          };
        } else if (!isStringEmpty(item.money.amount)) {
          moneyValue = {
            amount: item.money.amount,
            currency: item.money.currency,
          };
        } else {
          Logger.debug(
            'Both percentage and amount are null. Skipping over.',
            item,
          );
          return null;
        }

        const participantPaymentValue: ParticipantPaymentValue = {
          paymentValue: {
            money: moneyValue,
            percentage: percentageValue,
          },
          participantId: item.id,
        };

        return participantPaymentValue;
      },
    );

    finalData = finalData.filter((d) => d !== null);
    await dispatch(
      updateCommissionPayouts(transactionId, { payments: finalData }),
    );

    onClose();
  };

  const toggleSwitches = (index: number) => {
    if (!watchParticipants[index].isPercentage) {
      setValue(
        `commissionParticipant[${index}].percent.value`,
        watchParticipants[index]?.percent?.value || 0,
      );
      setValue(
        `commissionParticipant[${index}].percent.string`,
        watchParticipants[index]?.percent?.string || '0%',
      );
    } else {
      setValue(
        `commissionParticipant[${index}].money.amount`,
        watchParticipants[index]?.money?.amount || 0,
      );
      setValue(
        `commissionParticipant[${index}].money.currency`,
        MoneyValueCurrencyEnum.Usd,
      );
    }
    setValue(
      `commissionParticipant[${index}].isPercentage`,
      !watchParticipants[index].isPercentage,
    );
  };

  const checkAmountError = (index: number) =>
    get(errors, `commissionParticipant[${index}].money.amount`);

  const checkPercentageError = (index: number) =>
    get(errors, `commissionParticipant[${index}].percent.value`);

  return (
    <SidebarModal
      title={`Total Commission: ${
        totalCommission ? numberToMoney(totalCommission, 0) : 'N/A'
      }`}
      subtitle={`Property: ${propertyName}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='py-4'>
          <div className='space-y-2 divide-y'>
            <div className='flex flex-row justify-between items-center px-2 md:px-4'>
              <p className='text-lg font-primary-regular'>
                Participants ({watchParticipants.length})
              </p>
              <IconButton
                label='Add Participant'
                variant='outline'
                onClick={() => setOpenParticipantsSidebarModal(true)}
              />
            </div>
            <div className='py-2'>
              <div className='flex flex-row justify-between items-center px-2 md:px-4'>
                <p className='text-lg font-primary-regular'>
                  Commission Splits
                </p>
              </div>
              <div className='divide-y space-y-2'>
                <ul>
                  {fields.map((item: any, index: number) => {
                    const participant = participants.find(
                      (p) => p.id === item.id,
                    )!;

                    return (
                      <section
                        className='flex flex-row justify-between py-1 px-2 md:px-4'
                        key={item.id}
                      >
                        <div className='flex flex-row space-x-1'>
                          <div className='flex flex-row space-x-2 items-center'>
                            <Avatar
                              name={getParticipantName(participant) || 'N/A'}
                              imageUrl={item.avatar!}
                              size='xs'
                            />
                            <div className='flex md:flex-row flex-col'>
                              <div className='flex flex-col'>
                                <ControlledTextInput
                                  type='hidden'
                                  control={control}
                                  errors={errors}
                                  name={`commissionParticipant[${index}].id`}
                                  defaultValue={item.id}
                                />
                                <p className='md:text-base text-sm'>
                                  {getParticipantName(participant)}
                                </p>
                                <p className='text-xs'>
                                  {participant
                                    ? capitalizeEnum(
                                        participant?.participantRole!,
                                      )
                                    : 'N/A'}
                                </p>
                              </div>
                              <div className='flex flex-row space-x-1 items-center'>
                                {participant?.participantRole! ===
                                  ParticipantResponseParticipantRoleEnum.TeamLeader && (
                                  <Crown length={22} />
                                )}
                                {participant?.commissionDocumentPayer && (
                                  <NoteAdd
                                    data-testid='documentIcon'
                                    fontSize='small'
                                    className='text-gray-500'
                                  />
                                )}
                                {/* {item.isPayer && (
                                    <AttachMoney
                                      data-testid='moneyIcon'
                                      fontSize='small'
                                      className='text-green-600'
                                    />
                                  )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex flex-row items-center space-x-3'>
                          <div>
                            <CustomSwitch
                              onClick={() => toggleSwitches(index)}
                              toggle={!watchParticipants[index].isPercentage}
                              leftIcon='%'
                              rightIcon='$'
                            />
                            <Controller
                              name={`commissionParticipant[${index}].isPercentage`}
                              control={control}
                              defaultValue={item.isPercentage}
                              render={({ name, value, onChange }) => (
                                <input
                                  className='hidden'
                                  name={name}
                                  type='checkbox'
                                  value={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  checked={value}
                                />
                              )}
                            />
                          </div>
                          <div
                            className={classNames(
                              'mt-1 flex rounded shadow-sm',
                              !watchParticipants[index].isPercentage
                                ? 'hidden'
                                : 'block',
                            )}
                          >
                            <span
                              className={classNames(
                                'text-sm inline-flex items-center px-1.5 md:px-2 rounded-l border border-r-0 bg-gray-200 text-gray-500 sm:text-sm',
                                checkPercentageError(index)
                                  ? 'border-red-500'
                                  : 'border-gray-300',
                              )}
                            >
                              %
                            </span>
                            <span
                              className={classNames(
                                'w-20 rounded-none rounded-r sm:text-sm border border-l-0',
                                checkPercentageError(index)
                                  ? 'border-red-500'
                                  : 'border-gray-300',
                              )}
                            >
                              <ControlledTextInput
                                control={control}
                                type='number'
                                name={`commissionParticipant[${index}].percent.value`}
                                errors={errors}
                                defaultValue={item.percent?.value || '0'}
                                rules={{
                                  required: 'Required',
                                }}
                                noBorder
                                placeholder='Percentage'
                                hideErrorMessage
                              />
                              <ControlledTextInput
                                control={control}
                                type='hidden'
                                name={`commissionParticipant[${index}].percent.string`}
                                errors={errors}
                                defaultValue={item.percent?.string}
                              />
                            </span>
                          </div>
                          <div
                            className={classNames(
                              'mt-1 flex rounded shadow-sm',
                              !watchParticipants[index].isPercentage
                                ? 'block'
                                : 'hidden',
                            )}
                          >
                            <span
                              className={classNames(
                                'text-sm inline-flex items-center px-1.5 md:px-2 rounded-l border border-r-0 bg-gray-200 text-gray-500 sm:text-sm',
                                checkAmountError(index)
                                  ? 'border-red-500'
                                  : 'border-gray-300',
                              )}
                            >
                              $
                            </span>
                            <span
                              className={classNames(
                                'w-20 rounded-none rounded-r text-sm border border-l-0',
                                checkAmountError(index)
                                  ? 'border-red-500'
                                  : 'border-gray-300',
                              )}
                            >
                              <ControlledTextInput
                                control={control}
                                type='number'
                                name={`commissionParticipant[${index}].money.amount`}
                                errors={errors}
                                noBorder
                                defaultValue={item.money?.amount || '0'}
                                rules={{
                                  required: 'Required',
                                }}
                                placeholder='Amount'
                                hideErrorMessage
                              />
                              <ControlledTextInput
                                control={control}
                                type='hidden'
                                name={`commissionParticipant[${index}].money.currency`}
                                errors={errors}
                                noBorder
                                defaultValue={
                                  item.money?.currency! ||
                                  MoneyValueCurrencyEnum.Usd
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </section>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className='flex flex-col space-y-2 py-2 px-2 md:px-4'>
              <div className='flex flex-row justify-between items-start'>
                <p className='text-lg font-primary-regular text-left'>Total</p>
                <div className='flex justify-end'>
                  <p
                    className={classNames(
                      isInvalidAmount
                        ? 'text-red-600 border border-red-300 rounded p-1'
                        : 'text-black p-1 border-0',
                    )}
                  >
                    {isConsiderDollar
                      ? numberToMoney(totalAmount)
                      : `${totalPercentage}%`}
                  </p>
                </div>
              </div>
              {isInvalidAmount && (
                <div className='flex justify-end'>
                  <p className='text-red-600 border border-red-300 rounded p-1 flex text-sm md:text-base'>
                    {isConsiderDollar
                      ? `The total commission amounts must add up to ${numberToMoney(
                          totalCommission,
                        )}`
                      : 'The total commission percentages must add up to 100%'}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='p-2 md:p-4 bg-white border-t border-gray-200  bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2  w-full'>
          <Button
            disabled={isInvalidAmount || isSubmitting}
            buttonType='submit'
            label='Update'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
      <AddParticipantsForm
        isOpen={openParticipantsSidebarModal}
        onClose={() => setOpenParticipantsSidebarModal(false)}
        transactionId={transactionId}
      />
    </SidebarModal>
  );
};

export default CommissionSplitForm;
