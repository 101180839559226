import { times } from 'lodash';
import { DateTime } from 'luxon';
import {
  AdministrativeAreaResponseCountryEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  ApplicationResponse,
  ApplicationResponseApplicationTypeEnum,
  ApplicationResponseIcaTypeEnum,
  ApplicationResponseNextStatusEnum,
  ApplicationResponseStatusEnum,
  MoneyValueCurrencyEnum,
} from '../../../openapi/yenta';
import {
  fakeDate,
  fakeEmail,
  fakeId,
  fakeIdentificationNumber,
  fakeNumber,
  fakePersonFirstName,
  fakePersonLastName,
  fakePhoneNumber,
  fakeTeamName,
  fakeWords,
  randomBoolean,
  randomEnum,
} from '../../../testUtils/FakerUtils';
import { IPaginateRes, MLSAndBoardType } from '../../../types';

export const DefaultApplicationPaginationResponse: IPaginateRes<ApplicationResponse> = {
  data: times<Required<ApplicationResponse>>(20, (id) => ({
    optInToStockPlan: false,
    slug: '',
    licenseTransferredAt: fakeDate().toMillis(),
    icaInitials: 'Ica Initials',
    applicationType: ApplicationResponseApplicationTypeEnum.Regular,
    icaEmergencyContactEmailAddress: '123 Main St.',
    icaEmergencyContactName: '7777777777',
    icaEmergencyContactPhoneNumber: '7777777777',
    icaEmergencyContactRelationship: 'REL',
    icaEntityName: 'John',
    loiName: 'Letter of intent to join Real',
    loiNumDaysUntilJoining: 20,
    loiResidentIn: 'NY',
    loiSignature: 'John',
    loiSignedAt: DateTime.local(2021, 1, 1).toMillis(),
    sponsorCode: fakeWords(1),
    agentId: fakeId(),
    approvedAt: fakeDate().toMillis(),
    approvedBy: fakeId(),
    rejectedAt: fakeDate().toMillis(),
    rejectedBy: fakeId(),
    birthDate: fakeDate().toISODate(),
    boardOrMls: randomEnum<MLSAndBoardType>(MLSAndBoardType),
    businessEntity: fakeTeamName(),
    commercialAgent: randomBoolean(),
    createdAt: fakeDate().toMillis(),
    currentBrokerage: fakeTeamName(),
    emailAddress: fakeEmail(),
    estimatedSales: fakeNumber({ min: 100000, max: 300000 }).toString(),
    feesPaidAt: fakeDate().toMillis(),
    feesStripeChargeId: fakeIdentificationNumber(),
    firstName: fakePersonFirstName(),
    icaSignedAt: fakeDate().toMillis(),
    icaType: randomEnum<ApplicationResponseIcaTypeEnum>(
      ApplicationResponseIcaTypeEnum,
    ),
    id: `${id + 1}`,
    lastName: fakePersonLastName(),
    license: {
      active: true,
      expirationDate: fakeDate().toMillis(),
      knownComplaints: false,
      number: 'NY12345678',
    },
    nextStatus: ApplicationResponseNextStatusEnum.PendingApproval,
    phoneNumber: fakePhoneNumber(),
    status: randomEnum<ApplicationResponseStatusEnum>(
      ApplicationResponseStatusEnum,
    ),
    teamName: fakeTeamName(),
    updatedAt: fakeDate().toMillis(),
    icaAnniversaryDateRequested: fakeDate().toISODate(),
    icaName: 'John Smith',
    icaReferringAgentName: 'Referring Agent',
    icaSignature: 'John Smith',
    doesBusinessIn: [
      {
        active: true,
        expirationDate: fakeDate().toISODate(),
        knownComplaints: false,
        number: 'NY12345678',
        administrativeArea: {
          agentCanTransferLicense: true,
          country: AdministrativeAreaResponseCountryEnum.UnitedStates,
          licenseTransferFee: {
            currency: MoneyValueCurrencyEnum.Usd,
            amount: 149.0,
          },
          signupFee: {
            currency: MoneyValueCurrencyEnum.Usd,
            amount: 49.0,
          },
          stateOrProvince: randomEnum<AdministrativeAreaResponseStateOrProvinceEnum>(
            AdministrativeAreaResponseStateOrProvinceEnum,
          ),
        },
      },
    ],
    paidFeesAmount: {
      currency: MoneyValueCurrencyEnum.Usd,
      amount: 198.0,
    },
  })),

  total: fakeNumber({ min: 50, max: 100 }),
};
