import React from 'react';

export interface CardProps {
  title?: string;
  bottomActionText?: string;
  onBottomActionClick?(): void;
}

const Card: React.FC<CardProps> = ({
  bottomActionText,
  onBottomActionClick,
  title,
  children,
}) => {
  return (
    <div className='border border-gray-200 rounded'>
      {title && (
        <div className='pt-4'>
          <span className='text-xl text-gray-800 font-primary-medium px-4 '>
            {title}
          </span>
        </div>
      )}
      <div>{children}</div>
      {bottomActionText && (
        <button
          onClick={onBottomActionClick}
          className='text-primary font-primary-medium focus:outline-none hover:opacity-70 px-4 py-2'
        >
          {bottomActionText}
        </button>
      )}
    </div>
  );
};

export default Card;
