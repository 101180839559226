import React from 'react';
import RealLogoImg from '../assets/img/realLogo.png';

export interface DocumentMastHeadProps {
  companyName: string;
  companyAddress: string;
}

const DocumentMastHead: React.FC<DocumentMastHeadProps> = ({
  companyName,
  companyAddress,
}) => {
  return (
    <div className='flex flex-col items-center px-4 pb-3 pt-8 print:pt-4'>
      <img src={RealLogoImg} className='h-7 print:h-5' alt='Real Logo' />
      <p className='text-xl print:text-lg font-primary-medium text-center mt-3'>
        {companyName}
      </p>
      <p className='font-primary-medium text-center print:text-xs'>
        {companyAddress}
      </p>
    </div>
  );
};

export default DocumentMastHead;
