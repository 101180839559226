/* tslint:disable */
/* eslint-disable */
/**
 * arrakis-api API
 * arrakis-api is a demo
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    oneLine?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state?: AddressStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zip?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddressStateEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Alberta = 'ALBERTA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    BritishColumbia = 'BRITISH_COLUMBIA',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Manitoba = 'MANITOBA',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    NovaScotia = 'NOVA_SCOTIA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Ontario = 'ONTARIO',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    PuertoRico = 'PUERTO_RICO',
    Quebec = 'QUEBEC',
    RhodeIsland = 'RHODE_ISLAND',
    Saskatchewan = 'SASKATCHEWAN',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING'
}

/**
 * 
 * @export
 * @interface AgentCreditResponse
 */
export interface AgentCreditResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentCreditResponse
     */
    brokerageFeeAmountPaid?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentCreditResponse
     */
    capAmountPaid?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof AgentCreditResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {Array<CreditResponse>}
     * @memberof AgentCreditResponse
     */
    credits?: Array<CreditResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentCreditResponse
     */
    id?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentCreditResponse
     */
    netCommission?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AgentCreditResponse
     */
    permanentlyProducing?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentCreditResponse
     */
    personalDealsCount?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentCreditResponse
     */
    postCapEquityContribution?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentCreditResponse
     */
    postCapTransactionFeesPaid?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentCreditResponse
     */
    preCapEquityContribution?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AgentCreditResponse
     */
    producing?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentCreditResponse
     */
    stockPurchasePlanAmountPaid?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof AgentCreditResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface AgentPreviewResponse
 */
export interface AgentPreviewResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentPreviewResponse
     */
    brokerageFeeAmountPaid?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentPreviewResponse
     */
    capAmountPaid?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    permanentlyProducing?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    personalDealsCount?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentPreviewResponse
     */
    postCapEquityContribution?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentPreviewResponse
     */
    postCapTransactionFeesPaid?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentPreviewResponse
     */
    preCapEquityContribution?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    producing?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentPreviewResponse
     */
    stockPurchasePlanAmountPaid?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface AgentResponse
 */
export interface AgentResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentResponse
     */
    brokerageFeeAmountPaid?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    brokerageFeePaid?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentResponse
     */
    capAmountPaid?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    capped?: boolean;
    /**
     * 
     * @type {CommissionPlan}
     * @memberof AgentResponse
     */
    commissionPlan?: CommissionPlan;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    elite?: boolean;
    /**
     * 
     * @type {EliteEquityAwardPlan}
     * @memberof AgentResponse
     */
    eliteEquityAwardPlan?: EliteEquityAwardPlan;
    /**
     * 
     * @type {EliteStatusValue}
     * @memberof AgentResponse
     */
    eliteStatus?: EliteStatusValue;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    id?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentResponse
     */
    netCommission?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    nextAnniversary?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    numClosedTransactions?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    permanentlyProducing?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    personalDealsCount?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentResponse
     */
    postCapEquityContribution?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentResponse
     */
    postCapTransactionFeesPaid?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentResponse
     */
    preCapEquityContribution?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    producing?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    revShareTierUnlocked?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AgentResponse
     */
    stockPurchasePlanAmountPaid?: MoneyValue;
    /**
     * 
     * @type {TeamPlan}
     * @memberof AgentResponse
     */
    teamPlan?: TeamPlan;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface AllTransactionsResponse
 */
export interface AllTransactionsResponse {
    /**
     * 
     * @type {Array<TransactionResponse>}
     * @memberof AllTransactionsResponse
     */
    transactions?: Array<TransactionResponse>;
}
/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponse
     */
    status?: boolean;
}
/**
 * 
 * @export
 * @interface AttachedFeeRequest
 */
export interface AttachedFeeRequest {
    /**
     * 
     * @type {MoneyValue}
     * @memberof AttachedFeeRequest
     */
    amount: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof AttachedFeeRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AttachedFeeRequest
     */
    feeType: AttachedFeeRequestFeeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AttachedFeeRequest
     */
    participantId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AttachedFeeRequestFeeTypeEnum {
    AdditionalCommission = 'ADDITIONAL_COMMISSION',
    Mls = 'MLS',
    Reimbursement = 'REIMBURSEMENT',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR'
}

/**
 * 
 * @export
 * @interface AttachedFeeValue
 */
export interface AttachedFeeValue {
    /**
     * 
     * @type {MoneyValue}
     * @memberof AttachedFeeValue
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof AttachedFeeValue
     */
    counterpartyId?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachedFeeValue
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachedFeeValue
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachedFeeValue
     */
    feeType?: AttachedFeeValueFeeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AttachedFeeValue
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachedFeeValue
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AttachedFeeValueFeeTypeEnum {
    AdditionalCommission = 'ADDITIONAL_COMMISSION',
    Mls = 'MLS',
    Reimbursement = 'REIMBURSEMENT',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR'
}

/**
 * 
 * @export
 * @interface CapStatusValue
 */
export interface CapStatusValue {
    /**
     * 
     * @type {MoneyValue}
     * @memberof CapStatusValue
     */
    capAmount?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CapStatusValue
     */
    capAmountPaid?: MoneyValue;
}
/**
 * 
 * @export
 * @interface CommissionAdvanceListResponse
 */
export interface CommissionAdvanceListResponse {
    /**
     * 
     * @type {Array<CommissionAdvanceResponse>}
     * @memberof CommissionAdvanceListResponse
     */
    commissionAdvances: Array<CommissionAdvanceResponse>;
}
/**
 * 
 * @export
 * @interface CommissionAdvanceResponse
 */
export interface CommissionAdvanceResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionAdvanceResponse
     */
    advanceStatus?: CommissionAdvanceResponseAdvanceStatusEnum;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof CommissionAdvanceResponse
     */
    agent?: AgentPreviewResponse;
    /**
     * 
     * @type {string}
     * @memberof CommissionAdvanceResponse
     */
    agreementPath?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionAdvanceResponse
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionAdvanceResponse
     */
    companyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionAdvanceResponse
     */
    companyName?: string;
    /**
     * 
     * @type {number}
     * @memberof CommissionAdvanceResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionAdvanceResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionAdvanceResponse
     */
    notes?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionAdvanceResponse
     */
    repaidAmount?: MoneyValue;
    /**
     * 
     * @type {TransactionPreviewResponse}
     * @memberof CommissionAdvanceResponse
     */
    transaction?: TransactionPreviewResponse;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionAdvanceResponse
     */
    unpaidAmount?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionAdvanceResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CommissionAdvanceResponseAdvanceStatusEnum {
    AgreementReceived = 'AGREEMENT_RECEIVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Repaid = 'REPAID'
}

/**
 * 
 * @export
 * @interface CommissionDocumentPayeeResponse
 */
export interface CommissionDocumentPayeeResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    additionalInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    address?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionDocumentPayeeResponse
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    bankAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    bankAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    bankAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    bankRoutingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    commissionDocumentRole?: CommissionDocumentPayeeResponseCommissionDocumentRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentPayeeResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    licenseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    transactionParticipantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    transactionParticipantRole?: CommissionDocumentPayeeResponseTransactionParticipantRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentPayeeResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    wiringInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayeeResponse
     */
    yentaUserId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CommissionDocumentPayeeResponseCommissionDocumentRoleEnum {
    Payee = 'PAYEE',
    Payer = 'PAYER',
    Recipient = 'RECIPIENT'
}
/**
    * @export
    * @enum {string}
    */
export enum CommissionDocumentPayeeResponseTransactionParticipantRoleEnum {
    Appraiser = 'APPRAISER',
    Attorney = 'ATTORNEY',
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    DomesticLead = 'DOMESTIC_LEAD',
    Escrow = 'ESCROW',
    ExternalAgent = 'EXTERNAL_AGENT',
    HomeWarranty = 'HOME_WARRANTY',
    Inspection = 'INSPECTION',
    Landlord = 'LANDLORD',
    Lender = 'LENDER',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Other = 'OTHER',
    OtherAgent = 'OTHER_AGENT',
    Real = 'REAL',
    ReferralPayer = 'REFERRAL_PAYER',
    ReferringAgent = 'REFERRING_AGENT',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    Tenant = 'TENANT',
    TenantAgent = 'TENANT_AGENT',
    Title = 'TITLE',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface CommissionDocumentPayerResponse
 */
export interface CommissionDocumentPayerResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    commissionDocumentRole?: CommissionDocumentPayerResponseCommissionDocumentRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentPayerResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    transactionParticipantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    transactionParticipantRole?: CommissionDocumentPayerResponseTransactionParticipantRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentPayerResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentPayerResponse
     */
    yentaUserId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CommissionDocumentPayerResponseCommissionDocumentRoleEnum {
    Payee = 'PAYEE',
    Payer = 'PAYER',
    Recipient = 'RECIPIENT'
}
/**
    * @export
    * @enum {string}
    */
export enum CommissionDocumentPayerResponseTransactionParticipantRoleEnum {
    Appraiser = 'APPRAISER',
    Attorney = 'ATTORNEY',
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    DomesticLead = 'DOMESTIC_LEAD',
    Escrow = 'ESCROW',
    ExternalAgent = 'EXTERNAL_AGENT',
    HomeWarranty = 'HOME_WARRANTY',
    Inspection = 'INSPECTION',
    Landlord = 'LANDLORD',
    Lender = 'LENDER',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Other = 'OTHER',
    OtherAgent = 'OTHER_AGENT',
    Real = 'REAL',
    ReferralPayer = 'REFERRAL_PAYER',
    ReferringAgent = 'REFERRING_AGENT',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    Tenant = 'TENANT',
    TenantAgent = 'TENANT_AGENT',
    Title = 'TITLE',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface CommissionDocumentRecipientResponse
 */
export interface CommissionDocumentRecipientResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    commissionDocumentRole?: CommissionDocumentRecipientResponseCommissionDocumentRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentRecipientResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    transactionParticipantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    transactionParticipantRole?: CommissionDocumentRecipientResponseTransactionParticipantRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentRecipientResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentRecipientResponse
     */
    yentaUserId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CommissionDocumentRecipientResponseCommissionDocumentRoleEnum {
    Payee = 'PAYEE',
    Payer = 'PAYER',
    Recipient = 'RECIPIENT'
}
/**
    * @export
    * @enum {string}
    */
export enum CommissionDocumentRecipientResponseTransactionParticipantRoleEnum {
    Appraiser = 'APPRAISER',
    Attorney = 'ATTORNEY',
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    DomesticLead = 'DOMESTIC_LEAD',
    Escrow = 'ESCROW',
    ExternalAgent = 'EXTERNAL_AGENT',
    HomeWarranty = 'HOME_WARRANTY',
    Inspection = 'INSPECTION',
    Landlord = 'LANDLORD',
    Lender = 'LENDER',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Other = 'OTHER',
    OtherAgent = 'OTHER_AGENT',
    Real = 'REAL',
    ReferralPayer = 'REFERRAL_PAYER',
    ReferringAgent = 'REFERRING_AGENT',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    Tenant = 'TENANT',
    TenantAgent = 'TENANT_AGENT',
    Title = 'TITLE',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface CommissionDocumentResponse
 */
export interface CommissionDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    anticipatedClosingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    approvedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    approvedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    buyerName?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionDocumentResponse
     */
    buyersAgentCommission?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    companyName?: string;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    documentTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    dueDate?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionDocumentResponse
     */
    grossCommission?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    invalidatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    invalidatedOn?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionDocumentResponse
     */
    invoiceableRealBrokerageFee?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionDocumentResponse
     */
    invoiceableRealCommission?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    officeAddress?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionDocumentResponse
     */
    otherAgentCommission?: MoneyValue;
    /**
     * 
     * @type {Array<CommissionDocumentPayeeResponse>}
     * @memberof CommissionDocumentResponse
     */
    payees?: Array<CommissionDocumentPayeeResponse>;
    /**
     * 
     * @type {CommissionDocumentPayerResponse}
     * @memberof CommissionDocumentResponse
     */
    payer?: CommissionDocumentPayerResponse;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    pdfPath?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionDocumentResponse
     */
    price?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    propertyAddress?: string;
    /**
     * 
     * @type {Array<CommissionDocumentRecipientResponse>}
     * @memberof CommissionDocumentResponse
     */
    recipients?: Array<CommissionDocumentRecipientResponse>;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    representing?: string;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentResponse
     */
    revision?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    sellerName?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionDocumentResponse
     */
    sellersAgentCommission?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    status?: CommissionDocumentResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    transactionCode?: string;
    /**
     * 
     * @type {TransactionOwnerValue}
     * @memberof CommissionDocumentResponse
     */
    transactionOwner?: TransactionOwnerValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionDocumentResponse
     */
    type?: CommissionDocumentResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CommissionDocumentResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CommissionDocumentResponseStatusEnum {
    Approved = 'APPROVED',
    Invalidated = 'INVALIDATED',
    Pending = 'PENDING',
    Uninitialized = 'UNINITIALIZED'
}
/**
    * @export
    * @enum {string}
    */
export enum CommissionDocumentResponseTypeEnum {
    Cda = 'CDA',
    Invoice = 'INVOICE'
}

/**
 * 
 * @export
 * @interface CommissionPlan
 */
export interface CommissionPlan {
    /**
     * 
     * @type {number}
     * @memberof CommissionPlan
     */
    allowedPersonalDeals?: number;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    annualBrokerageFee?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    annualCap?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    capAmount?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    cappedLeaseFee?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    cappedTransactionFee?: Money;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlan
     */
    country?: CommissionPlanCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlan
     */
    createdAt?: number;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    domesticTeamCap?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    groupTeamCap?: Money;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlan
     */
    id?: string;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    minCommissionForBrokerageFee?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    normalTeamLeadAnnualCap?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    normalTeamMemberAnnualCap?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    perTransactionBrokerageFee?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    personalDealFee?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    platinumTeamLeadAnnualCap?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    platinumTeamMemberAnnualCap?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    postCapLeaseTransactionFee?: Money;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    postCapSalesTransactionFee?: Money;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlan
     */
    preCapRealCommissionSplit?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlan
     */
    realSplit?: number;
    /**
     * 
     * @type {Money}
     * @memberof CommissionPlan
     */
    signUpFee?: Money;
}

/**
    * @export
    * @enum {string}
    */
export enum CommissionPlanCountryEnum {
    Canada = 'CANADA',
    UnitedStates = 'UNITED_STATES'
}

/**
 * 
 * @export
 * @interface CommissionSplitParticipantValue
 */
export interface CommissionSplitParticipantValue {
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionSplitParticipantValue
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {PercentageValue}
     * @memberof CommissionSplitParticipantValue
     */
    percentage?: PercentageValue;
    /**
     * 
     * @type {string}
     * @memberof CommissionSplitParticipantValue
     */
    yentaId?: string;
}
/**
 * 
 * @export
 * @interface CreateCommissionAdvanceRequest
 */
export interface CreateCommissionAdvanceRequest {
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionAdvanceRequest
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionAdvanceRequest
     */
    companyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionAdvanceRequest
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionAdvanceRequest
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionAdvanceRequest
     */
    transactionId?: string;
}
/**
 * 
 * @export
 * @interface CreateMonetaryCreditRequest
 */
export interface CreateMonetaryCreditRequest {
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateMonetaryCreditRequest
     */
    amount: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CreateMonetaryCreditRequest
     */
    issuedOn: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMonetaryCreditRequest
     */
    issuerNote: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMonetaryCreditRequest
     */
    title: string;
}
/**
 * 
 * @export
 * @interface CreateNumericalCreditRequest
 */
export interface CreateNumericalCreditRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNumericalCreditRequest
     */
    issuedOn: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNumericalCreditRequest
     */
    issuerNote: string;
    /**
     * 
     * @type {number}
     * @memberof CreateNumericalCreditRequest
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof CreateNumericalCreditRequest
     */
    title: string;
}
/**
 * 
 * @export
 * @interface CreateParticipantRequest
 */
export interface CreateParticipantRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateParticipantRequest
     */
    commissionDocumentRecipient: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateParticipantRequest
     */
    paidByReal: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    participantRole: CreateParticipantRequestParticipantRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateParticipantRequest
     */
    passThrough: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateParticipantRequest
     */
    payer: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateParticipantRequest
     */
    personalDeal: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    yentaId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateParticipantRequestParticipantRoleEnum {
    Appraiser = 'APPRAISER',
    Attorney = 'ATTORNEY',
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    DomesticLead = 'DOMESTIC_LEAD',
    Escrow = 'ESCROW',
    ExternalAgent = 'EXTERNAL_AGENT',
    HomeWarranty = 'HOME_WARRANTY',
    Inspection = 'INSPECTION',
    Landlord = 'LANDLORD',
    Lender = 'LENDER',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Other = 'OTHER',
    OtherAgent = 'OTHER_AGENT',
    Real = 'REAL',
    ReferralPayer = 'REFERRAL_PAYER',
    ReferringAgent = 'REFERRING_AGENT',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    Tenant = 'TENANT',
    TenantAgent = 'TENANT_AGENT',
    Title = 'TITLE',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface CreateTestTransactionRequest
 */
export interface CreateTestTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTestTransactionRequest
     */
    agentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTestTransactionRequest
     */
    listingCommissionAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTestTransactionRequest
     */
    price?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTestTransactionRequest
     */
    rental?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTestTransactionRequest
     */
    representing?: CreateTestTransactionRequestRepresentingEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTestTransactionRequest
     */
    salesCommissionAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTestTransactionRequest
     */
    stateOrProvince?: CreateTestTransactionRequestStateOrProvinceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateTestTransactionRequestRepresentingEnum {
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    None = 'NONE',
    Other = 'OTHER',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    Unknown = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateTestTransactionRequestStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Alberta = 'ALBERTA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    BritishColumbia = 'BRITISH_COLUMBIA',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Manitoba = 'MANITOBA',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    NovaScotia = 'NOVA_SCOTIA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Ontario = 'ONTARIO',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    PuertoRico = 'PUERTO_RICO',
    Quebec = 'QUEBEC',
    RhodeIsland = 'RHODE_ISLAND',
    Saskatchewan = 'SASKATCHEWAN',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING'
}

/**
 * 
 * @export
 * @interface CreditResponse
 */
export interface CreditResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreditResponse
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CreditResponse
     */
    issuedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditResponse
     */
    issuerNote?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditResponse
     */
    issuerUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditResponse
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditResponse
     */
    type?: CreditResponseTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreditResponseTypeEnum {
    BrokerageFee = 'BROKERAGE_FEE',
    Cap = 'CAP',
    CumulativeGci = 'CUMULATIVE_GCI',
    NumberOfExecutedTransactions = 'NUMBER_OF_EXECUTED_TRANSACTIONS',
    NumberOfPersonalTransactions = 'NUMBER_OF_PERSONAL_TRANSACTIONS',
    PostCapEquityPurchase = 'POST_CAP_EQUITY_PURCHASE',
    PostCapFee = 'POST_CAP_FEE',
    PreCapEquityPurchase = 'PRE_CAP_EQUITY_PURCHASE',
    Revshare = 'REVSHARE'
}

/**
 * 
 * @export
 * @interface CurrencyContext
 */
export interface CurrencyContext {
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyContext
     */
    empty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrencyContext
     */
    providerName?: string;
}
/**
 * 
 * @export
 * @interface CurrencyUnit
 */
export interface CurrencyUnit {
    /**
     * 
     * @type {CurrencyContext}
     * @memberof CurrencyUnit
     */
    context?: CurrencyContext;
    /**
     * 
     * @type {string}
     * @memberof CurrencyUnit
     */
    currencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyUnit
     */
    defaultFractionDigits?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyUnit
     */
    numericCode?: number;
}
/**
 * 
 * @export
 * @interface DisburseReferralRequest
 */
export interface DisburseReferralRequest {
    /**
     * 
     * @type {MoneyValue}
     * @memberof DisburseReferralRequest
     */
    amount: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof DisburseReferralRequest
     */
    paymentMedium: DisburseReferralRequestPaymentMediumEnum;
    /**
     * 
     * @type {string}
     * @memberof DisburseReferralRequest
     */
    referenceNumber?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DisburseReferralRequestPaymentMediumEnum {
    Check = 'CHECK',
    Wire = 'WIRE'
}

/**
 * 
 * @export
 * @interface EliteEquityAwardPlan
 */
export interface EliteEquityAwardPlan {
    /**
     * 
     * @type {Money}
     * @memberof EliteEquityAwardPlan
     */
    award?: Money;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlan
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlan
     */
    id?: string;
    /**
     * 
     * @type {Money}
     * @memberof EliteEquityAwardPlan
     */
    minGCI?: Money;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlan
     */
    minTransactionsClosed?: number;
    /**
     * 
     * @type {Money}
     * @memberof EliteEquityAwardPlan
     */
    postCapTransactionFeesTotal?: Money;
    /**
     * 
     * @type {Money}
     * @memberof EliteEquityAwardPlan
     */
    postEliteLeaseTransactionFee?: Money;
    /**
     * 
     * @type {Money}
     * @memberof EliteEquityAwardPlan
     */
    postEliteSalesTransactionFee?: Money;
}
/**
 * 
 * @export
 * @interface EliteStatusValue
 */
export interface EliteStatusValue {
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteStatusValue
     */
    gciEarned?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteStatusValue
     */
    minGCI?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteStatusValue
     */
    postCapFeesPaid?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteStatusValue
     */
    postCapTransactionFeesTotal?: MoneyValue;
}
/**
 * 
 * @export
 * @interface EntityTag
 */
export interface EntityTag {
    /**
     * 
     * @type {string}
     * @memberof EntityTag
     */
    value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityTag
     */
    weak?: boolean;
}
/**
 * 
 * @export
 * @interface EquityContributionValue
 */
export interface EquityContributionValue {
    /**
     * 
     * @type {Address}
     * @memberof EquityContributionValue
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof EquityContributionValue
     */
    closingDateActual?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityContributionValue
     */
    contributionAmount?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityContributionValue
     */
    matchAmount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof EquityContributionValue
     */
    transactionId?: string;
}
/**
 * 
 * @export
 * @interface EquityMonthlyResponse
 */
export interface EquityMonthlyResponse {
    /**
     * 
     * @type {Array<EquityContributionValue>}
     * @memberof EquityMonthlyResponse
     */
    contributions?: Array<EquityContributionValue>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityMonthlyResponse
     */
    matchedAmount?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EquityMonthlyResponse
     */
    month?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityMonthlyResponse
     */
    pendingAmount?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityMonthlyResponse
     */
    purchasedAmount?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EquityMonthlyResponse
     */
    year?: number;
}
/**
 * 
 * @export
 * @interface EquityOverviewResponse
 */
export interface EquityOverviewResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityOverviewResponse
     */
    currentMonthPendingAmount?: MoneyValue;
    /**
     * 
     * @type {Array<EquityMonthlyResponse>}
     * @memberof EquityOverviewResponse
     */
    monthlyAmounts?: Array<EquityMonthlyResponse>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityOverviewResponse
     */
    yearlyMatchedAmount?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityOverviewResponse
     */
    yearlyPurchasedAmount?: MoneyValue;
}
/**
 * 
 * @export
 * @interface EscrowRequest
 */
export interface EscrowRequest {
    /**
     * 
     * @type {MoneyValue}
     * @memberof EscrowRequest
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof EscrowRequest
     */
    info?: string;
}
/**
 * 
 * @export
 * @interface EscrowResponse
 */
export interface EscrowResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof EscrowResponse
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EscrowResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof EscrowResponse
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof EscrowResponse
     */
    escrowStatus?: EscrowResponseEscrowStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EscrowResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EscrowResponse
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof EscrowResponse
     */
    transactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof EscrowResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EscrowResponseEscrowStatusEnum {
    Disbursed = 'DISBURSED',
    Received = 'RECEIVED',
    Submitted = 'SUBMITTED'
}

/**
 * 
 * @export
 * @interface IncomeMonthlyResponse
 */
export interface IncomeMonthlyResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeMonthlyResponse
     */
    equityPurchased?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeMonthlyResponse
     */
    grossCommission?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof IncomeMonthlyResponse
     */
    month?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeMonthlyResponse
     */
    netCommission?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof IncomeMonthlyResponse
     */
    numTransactions?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeMonthlyResponse
     */
    revShareEarnings?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof IncomeMonthlyResponse
     */
    year?: number;
}
/**
 * 
 * @export
 * @interface IncomeOverviewResponse
 */
export interface IncomeOverviewResponse {
    /**
     * 
     * @type {string}
     * @memberof IncomeOverviewResponse
     */
    anniversaryDate?: string;
    /**
     * 
     * @type {CapStatusValue}
     * @memberof IncomeOverviewResponse
     */
    capStatus?: CapStatusValue;
    /**
     * 
     * @type {boolean}
     * @memberof IncomeOverviewResponse
     */
    capped?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IncomeOverviewResponse
     */
    elite?: boolean;
    /**
     * 
     * @type {EliteStatusValue}
     * @memberof IncomeOverviewResponse
     */
    eliteStatus?: EliteStatusValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeOverviewResponse
     */
    equityPurchased?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeOverviewResponse
     */
    grossCommission?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeOverviewResponse
     */
    netCommission?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof IncomeOverviewResponse
     */
    numTransactions?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeOverviewResponse
     */
    revShareEarnings?: MoneyValue;
    /**
     * 
     * @type {RevShareStatusValue}
     * @memberof IncomeOverviewResponse
     */
    revShareStatus?: RevShareStatusValue;
}
/**
 * 
 * @export
 * @interface IncomeTotalMonthlyResponse
 */
export interface IncomeTotalMonthlyResponse {
    /**
     * 
     * @type {number}
     * @memberof IncomeTotalMonthlyResponse
     */
    month?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof IncomeTotalMonthlyResponse
     */
    total?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof IncomeTotalMonthlyResponse
     */
    year?: number;
}
/**
 * 
 * @export
 * @interface IncomeTotalsResponse
 */
export interface IncomeTotalsResponse {
    /**
     * 
     * @type {Array<IncomeTotalMonthlyResponse>}
     * @memberof IncomeTotalsResponse
     */
    monthlyTotals?: Array<IncomeTotalMonthlyResponse>;
}
/**
 * 
 * @export
 * @interface InvoiceResponse
 */
export interface InvoiceResponse {
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof InvoiceResponse
     */
    invoicedAmount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    paidAt?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    payerNote?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    paymentAcceptable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    paymentSystem?: InvoiceResponsePaymentSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    paymentSystemId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    retryable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    status?: InvoiceResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    transactionCode?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum InvoiceResponsePaymentSystemEnum {
    Netsuite = 'NETSUITE',
    Tipalti = 'TIPALTI'
}
/**
    * @export
    * @enum {string}
    */
export enum InvoiceResponseStatusEnum {
    Cancel = 'CANCEL',
    CancelConfirmed = 'CANCEL_CONFIRMED',
    GeneralError = 'GENERAL_ERROR',
    InvoiceCreated = 'INVOICE_CREATED',
    NetsuiteError = 'NETSUITE_ERROR',
    Open = 'OPEN',
    Paid = 'PAID',
    PaidConfirmed = 'PAID_CONFIRMED',
    TransactionMissing = 'TRANSACTION_MISSING'
}

/**
 * 
 * @export
 * @interface LedgerItemValue
 */
export interface LedgerItemValue {
    /**
     * 
     * @type {MoneyValue}
     * @memberof LedgerItemValue
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof LedgerItemValue
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemValue
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemValue
     */
    ledgerItemType?: LedgerItemValueLedgerItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemValue
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof LedgerItemValue
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LedgerItemValueLedgerItemTypeEnum {
    BrokerageFee = 'BROKERAGE_FEE',
    Commission = 'COMMISSION',
    CommissionAdvanceRepayment = 'COMMISSION_ADVANCE_REPAYMENT',
    MlsFee = 'MLS_FEE',
    Other = 'OTHER',
    PersonalDealFee = 'PERSONAL_DEAL_FEE',
    PostCapEquityContribution = 'POST_CAP_EQUITY_CONTRIBUTION',
    PreCapEquityContribution = 'PRE_CAP_EQUITY_CONTRIBUTION',
    RealSplit = 'REAL_SPLIT',
    Reimbursement = 'REIMBURSEMENT',
    StockAward = 'STOCK_AWARD',
    TransactionCoordinatorFee = 'TRANSACTION_COORDINATOR_FEE',
    TransactionFee = 'TRANSACTION_FEE'
}

/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Link
     */
    params?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    rel?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Link
     */
    rels?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    uri?: string;
    /**
     * 
     * @type {any}
     * @memberof Link
     */
    uriBuilder?: any;
}
/**
 * 
 * @export
 * @interface Locale
 */
export interface Locale {
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    displayCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    displayLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    displayScript?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    displayVariant?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Locale
     */
    extensionKeys?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    iso3Country?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    iso3Language?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    script?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Locale
     */
    unicodeLocaleAttributes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Locale
     */
    unicodeLocaleKeys?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    variant?: string;
}
/**
 * 
 * @export
 * @interface MediaType
 */
export interface MediaType {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MediaType
     */
    parameters?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof MediaType
     */
    subtype?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaType
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaType
     */
    wildcardSubtype?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MediaType
     */
    wildcardType?: boolean;
}
/**
 * 
 * @export
 * @interface MonetaryAmountFactoryMoney
 */
export interface MonetaryAmountFactoryMoney {
    /**
     * 
     * @type {MonetaryContext}
     * @memberof MonetaryAmountFactoryMoney
     */
    defaultMonetaryContext?: MonetaryContext;
    /**
     * 
     * @type {NumberValue}
     * @memberof MonetaryAmountFactoryMoney
     */
    maxNumber?: NumberValue;
    /**
     * 
     * @type {MonetaryContext}
     * @memberof MonetaryAmountFactoryMoney
     */
    maximalMonetaryContext?: MonetaryContext;
    /**
     * 
     * @type {NumberValue}
     * @memberof MonetaryAmountFactoryMoney
     */
    minNumber?: NumberValue;
}
/**
 * 
 * @export
 * @interface MonetaryContext
 */
export interface MonetaryContext {
    /**
     * 
     * @type {boolean}
     * @memberof MonetaryContext
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonetaryContext
     */
    fixedScale?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonetaryContext
     */
    maxScale?: number;
    /**
     * 
     * @type {number}
     * @memberof MonetaryContext
     */
    precision?: number;
    /**
     * 
     * @type {string}
     * @memberof MonetaryContext
     */
    providerName?: string;
}
/**
 * 
 * @export
 * @interface Money
 */
export interface Money {
    /**
     * 
     * @type {MonetaryContext}
     * @memberof Money
     */
    context?: MonetaryContext;
    /**
     * 
     * @type {CurrencyUnit}
     * @memberof Money
     */
    currency?: CurrencyUnit;
    /**
     * 
     * @type {MonetaryAmountFactoryMoney}
     * @memberof Money
     */
    factory?: MonetaryAmountFactoryMoney;
    /**
     * 
     * @type {boolean}
     * @memberof Money
     */
    negative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Money
     */
    negativeOrZero?: boolean;
    /**
     * 
     * @type {NumberValue}
     * @memberof Money
     */
    number?: NumberValue;
    /**
     * 
     * @type {number}
     * @memberof Money
     */
    numberStripped?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Money
     */
    positive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Money
     */
    positiveOrZero?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Money
     */
    zero?: boolean;
}
/**
 * 
 * @export
 * @interface MoneyValue
 */
export interface MoneyValue {
    /**
     * 
     * @type {number}
     * @memberof MoneyValue
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyValue
     */
    currency?: MoneyValueCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MoneyValueCurrencyEnum {
    Cad = 'CAD',
    Usd = 'USD'
}

/**
 * 
 * @export
 * @interface MonitorEventResponse
 */
export interface MonitorEventResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitorEventResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    eventStatus?: MonitorEventResponseEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    eventType?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    investigationStatus?: MonitorEventResponseInvestigationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    objectType?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitorEventResponse
     */
    retryCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitorEventResponse
     */
    triggeredBy?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitorEventResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MonitorEventResponseEventStatusEnum {
    Failed = 'FAILED',
    Succeeded = 'SUCCEEDED'
}
/**
    * @export
    * @enum {string}
    */
export enum MonitorEventResponseInvestigationStatusEnum {
    Done = 'DONE',
    GaveUp = 'GAVE_UP',
    Started = 'STARTED',
    Unstarted = 'UNSTARTED'
}

/**
 * 
 * @export
 * @interface NewCookie
 */
export interface NewCookie {
    /**
     * 
     * @type {string}
     * @memberof NewCookie
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCookie
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCookie
     */
    expiry?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewCookie
     */
    httpOnly?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NewCookie
     */
    maxAge?: number;
    /**
     * 
     * @type {string}
     * @memberof NewCookie
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCookie
     */
    path?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewCookie
     */
    secure?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewCookie
     */
    value?: string;
    /**
     * 
     * @type {number}
     * @memberof NewCookie
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface NumberValue
 */
export interface NumberValue {
    /**
     * 
     * @type {number}
     * @memberof NumberValue
     */
    amountFractionDenominator?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberValue
     */
    amountFractionNumerator?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberValue
     */
    precision?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberValue
     */
    scale?: number;
}
/**
 * 
 * @export
 * @interface OfficeResponse
 */
export interface OfficeResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    bankAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    bankAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    bankAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    bankRoutingNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficeResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    license?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    splitCheck?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OfficeResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface OutgoingPaymentResponse
 */
export interface OutgoingPaymentResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof OutgoingPaymentResponse
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    confirmationCode?: string;
    /**
     * 
     * @type {number}
     * @memberof OutgoingPaymentResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    displayLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    displayLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    externalSystemConfirmationId?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof OutgoingPaymentResponse
     */
    invoicedAmount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    lastName?: string;
    /**
     * 
     * @type {OutgoingPaymentSourceResponse}
     * @memberof OutgoingPaymentResponse
     */
    outgoingPaymentSource?: OutgoingPaymentSourceResponse;
    /**
     * 
     * @type {number}
     * @memberof OutgoingPaymentResponse
     */
    paidAt?: number;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    paidBy?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    payerNote?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    paymentSystem?: OutgoingPaymentResponsePaymentSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    paymentSystemId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OutgoingPaymentResponse
     */
    retryable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    status?: OutgoingPaymentResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    transactionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    transactionParticipantId?: string;
    /**
     * 
     * @type {number}
     * @memberof OutgoingPaymentResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentResponse
     */
    yentaUserId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OutgoingPaymentResponsePaymentSystemEnum {
    Netsuite = 'NETSUITE',
    Tipalti = 'TIPALTI'
}
/**
    * @export
    * @enum {string}
    */
export enum OutgoingPaymentResponseStatusEnum {
    Cancelled = 'CANCELLED',
    Deleted = 'DELETED',
    Disputed = 'DISPUTED',
    GeneralError = 'GENERAL_ERROR',
    Open = 'OPEN',
    Paid = 'PAID',
    Pending = 'PENDING',
    Rejected = 'REJECTED',
    ScheduledForPayment = 'SCHEDULED_FOR_PAYMENT',
    SchedulingError = 'SCHEDULING_ERROR',
    SentToPaymentSystem = 'SENT_TO_PAYMENT_SYSTEM'
}

/**
 * 
 * @export
 * @interface OutgoingPaymentSourceResponse
 */
export interface OutgoingPaymentSourceResponse {
    /**
     * 
     * @type {number}
     * @memberof OutgoingPaymentSourceResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof OutgoingPaymentSourceResponse
     */
    id?: string;
    /**
     * 
     * @type {Array<RevShareResponse>}
     * @memberof OutgoingPaymentSourceResponse
     */
    revShares?: Array<RevShareResponse>;
    /**
     * 
     * @type {TransactionResponse}
     * @memberof OutgoingPaymentSourceResponse
     */
    transaction?: TransactionResponse;
    /**
     * 
     * @type {number}
     * @memberof OutgoingPaymentSourceResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface ParticipantPaymentValue
 */
export interface ParticipantPaymentValue {
    /**
     * 
     * @type {string}
     * @memberof ParticipantPaymentValue
     */
    participantId: string;
    /**
     * 
     * @type {PaymentValue}
     * @memberof ParticipantPaymentValue
     */
    paymentValue: PaymentValue;
}
/**
 * 
 * @export
 * @interface ParticipantResponse
 */
export interface ParticipantResponse {
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantResponse
     */
    commissionDocumentPayer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantResponse
     */
    commissionDocumentRecipient?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipantResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantResponse
     */
    owner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantResponse
     */
    paidByReal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    participantRole?: ParticipantResponseParticipantRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    participantStatus?: ParticipantResponseParticipantStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantResponse
     */
    passThrough?: boolean;
    /**
     * 
     * @type {PaymentResponse}
     * @memberof ParticipantResponse
     */
    payment?: PaymentResponse;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantResponse
     */
    personalDeal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipantResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ParticipantResponseParticipantRoleEnum {
    Appraiser = 'APPRAISER',
    Attorney = 'ATTORNEY',
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    DomesticLead = 'DOMESTIC_LEAD',
    Escrow = 'ESCROW',
    ExternalAgent = 'EXTERNAL_AGENT',
    HomeWarranty = 'HOME_WARRANTY',
    Inspection = 'INSPECTION',
    Landlord = 'LANDLORD',
    Lender = 'LENDER',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Other = 'OTHER',
    OtherAgent = 'OTHER_AGENT',
    Real = 'REAL',
    ReferralPayer = 'REFERRAL_PAYER',
    ReferringAgent = 'REFERRING_AGENT',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    Tenant = 'TENANT',
    TenantAgent = 'TENANT_AGENT',
    Title = 'TITLE',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Unknown = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum ParticipantResponseParticipantStatusEnum {
    Active = 'ACTIVE',
    Paid = 'PAID'
}

/**
 * 
 * @export
 * @interface ParticipantValue
 */
export interface ParticipantValue {
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantValue
     */
    commissionDocumentPayer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantValue
     */
    commissionDocumentRecipient?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipantValue
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    emailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantValue
     */
    externalParticipant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    firstName?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof ParticipantValue
     */
    grossPayment?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantValue
     */
    paidByReal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantValue
     */
    passThrough?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {PaymentValue}
     * @memberof ParticipantValue
     */
    payoutSetting?: PaymentValue;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    role?: ParticipantValueRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    status?: ParticipantValueStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    type?: ParticipantValueTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ParticipantValue
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantValue
     */
    yentaId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ParticipantValueRoleEnum {
    Appraiser = 'APPRAISER',
    Attorney = 'ATTORNEY',
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    DomesticLead = 'DOMESTIC_LEAD',
    Escrow = 'ESCROW',
    ExternalAgent = 'EXTERNAL_AGENT',
    HomeWarranty = 'HOME_WARRANTY',
    Inspection = 'INSPECTION',
    Landlord = 'LANDLORD',
    Lender = 'LENDER',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Other = 'OTHER',
    OtherAgent = 'OTHER_AGENT',
    Real = 'REAL',
    ReferralPayer = 'REFERRAL_PAYER',
    ReferringAgent = 'REFERRING_AGENT',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    Tenant = 'TENANT',
    TenantAgent = 'TENANT_AGENT',
    Title = 'TITLE',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Unknown = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum ParticipantValueStatusEnum {
    Active = 'ACTIVE',
    Paid = 'PAID'
}
/**
    * @export
    * @enum {string}
    */
export enum ParticipantValueTypeEnum {
    Agent = 'AGENT',
    ExternalEntity = 'EXTERNAL_ENTITY'
}

/**
 * 
 * @export
 * @interface PaymentParticipantValue
 */
export interface PaymentParticipantValue {
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentParticipantValue
     */
    commissionDocumentPayer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentParticipantValue
     */
    commissionDocumentRecipient?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PaymentParticipantValue
     */
    commissionTotal?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentParticipantValue
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    emailAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentParticipantValue
     */
    externalParticipant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    firstName?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PaymentParticipantValue
     */
    grossPayment?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    id?: string;
    /**
     * 
     * @type {Array<LedgerItemValue>}
     * @memberof PaymentParticipantValue
     */
    items?: Array<LedgerItemValue>;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    lastName?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof PaymentParticipantValue
     */
    netPayout?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentParticipantValue
     */
    owner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentParticipantValue
     */
    paidByReal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentParticipantValue
     */
    passThrough?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {PaymentValue}
     * @memberof PaymentParticipantValue
     */
    payoutSetting?: PaymentValue;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentParticipantValue
     */
    personalDeal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    role?: PaymentParticipantValueRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    status?: PaymentParticipantValueStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    type?: PaymentParticipantValueTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PaymentParticipantValue
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    w9Url?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentParticipantValue
     */
    yentaId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentParticipantValueRoleEnum {
    Appraiser = 'APPRAISER',
    Attorney = 'ATTORNEY',
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    DomesticLead = 'DOMESTIC_LEAD',
    Escrow = 'ESCROW',
    ExternalAgent = 'EXTERNAL_AGENT',
    HomeWarranty = 'HOME_WARRANTY',
    Inspection = 'INSPECTION',
    Landlord = 'LANDLORD',
    Lender = 'LENDER',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Other = 'OTHER',
    OtherAgent = 'OTHER_AGENT',
    Real = 'REAL',
    ReferralPayer = 'REFERRAL_PAYER',
    ReferringAgent = 'REFERRING_AGENT',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    Tenant = 'TENANT',
    TenantAgent = 'TENANT_AGENT',
    Title = 'TITLE',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Unknown = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentParticipantValueStatusEnum {
    Active = 'ACTIVE',
    Paid = 'PAID'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentParticipantValueTypeEnum {
    Agent = 'AGENT',
    ExternalEntity = 'EXTERNAL_ENTITY'
}

/**
 * 
 * @export
 * @interface PaymentResponse
 */
export interface PaymentResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof PaymentResponse
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof PaymentResponse
     */
    percent?: number;
}
/**
 * 
 * @export
 * @interface PaymentValue
 */
export interface PaymentValue {
    /**
     * 
     * @type {MoneyValue}
     * @memberof PaymentValue
     */
    money?: MoneyValue;
    /**
     * 
     * @type {PercentageValue}
     * @memberof PaymentValue
     */
    percentage?: PercentageValue;
}
/**
 * 
 * @export
 * @interface PercentageValue
 */
export interface PercentageValue {
    /**
     * 
     * @type {string}
     * @memberof PercentageValue
     */
    string?: string;
    /**
     * 
     * @type {number}
     * @memberof PercentageValue
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface ReferralRequest
 */
export interface ReferralRequest {
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    clientEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    expectedCloseDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    externalAgentBrokerage?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    externalAgentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    externalAgentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    propertyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    receivingAgentYentaId?: string;
}
/**
 * 
 * @export
 * @interface ReferralResponse
 */
export interface ReferralResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof ReferralResponse
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    clientEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    clientName?: string;
    /**
     * 
     * @type {number}
     * @memberof ReferralResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    docUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    expectedCloseDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    externalAgentBrokerageName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    externalAgentEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    externalAgentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    paymentMedium?: ReferralResponsePaymentMediumEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    propertyAddress?: string;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof ReferralResponse
     */
    receivingAgent?: AgentPreviewResponse;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    receivingAgentUserYentaId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    referenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    referralStatus?: ReferralResponseReferralStatusEnum;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof ReferralResponse
     */
    sendingAgentUserYentaId?: AgentPreviewResponse;
    /**
     * 
     * @type {number}
     * @memberof ReferralResponse
     */
    updatedAt?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferralResponsePaymentMediumEnum {
    Check = 'CHECK',
    Wire = 'WIRE'
}
/**
    * @export
    * @enum {string}
    */
export enum ReferralResponseReferralStatusEnum {
    Disbursed = 'DISBURSED',
    PaymentReceived = 'PAYMENT_RECEIVED',
    Submitted = 'SUBMITTED'
}

/**
 * 
 * @export
 * @interface ReferralsResponse
 */
export interface ReferralsResponse {
    /**
     * 
     * @type {Array<ReferralResponse>}
     * @memberof ReferralsResponse
     */
    referrals?: Array<ReferralResponse>;
}
/**
 * 
 * @export
 * @interface Response
 */
export interface Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof Response
     */
    allowedMethods?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: NewCookie; }}
     * @memberof Response
     */
    cookies?: { [key: string]: NewCookie; };
    /**
     * 
     * @type {string}
     * @memberof Response
     */
    date?: string;
    /**
     * 
     * @type {any}
     * @memberof Response
     */
    entity?: any;
    /**
     * 
     * @type {EntityTag}
     * @memberof Response
     */
    entityTag?: EntityTag;
    /**
     * 
     * @type {{ [key: string]: Array<any>; }}
     * @memberof Response
     */
    headers?: { [key: string]: Array<any>; };
    /**
     * 
     * @type {Locale}
     * @memberof Response
     */
    language?: Locale;
    /**
     * 
     * @type {string}
     * @memberof Response
     */
    lastModified?: string;
    /**
     * 
     * @type {number}
     * @memberof Response
     */
    length?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Response
     */
    links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof Response
     */
    location?: string;
    /**
     * 
     * @type {MediaType}
     * @memberof Response
     */
    mediaType?: MediaType;
    /**
     * 
     * @type {{ [key: string]: Array<any>; }}
     * @memberof Response
     */
    metadata?: { [key: string]: Array<any>; };
    /**
     * 
     * @type {number}
     * @memberof Response
     */
    status?: number;
    /**
     * 
     * @type {StatusType}
     * @memberof Response
     */
    statusInfo?: StatusType;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof Response
     */
    stringHeaders?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface RevShareMonthlyResponse
 */
export interface RevShareMonthlyResponse {
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevShareMonthlyResponse
     */
    earnings?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RevShareMonthlyResponse
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof RevShareMonthlyResponse
     */
    year?: number;
}
/**
 * 
 * @export
 * @interface RevShareOverviewResponse
 */
export interface RevShareOverviewResponse {
    /**
     * 
     * @type {Array<RevShareMonthlyResponse>}
     * @memberof RevShareOverviewResponse
     */
    monthlyEarnings?: Array<RevShareMonthlyResponse>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevShareOverviewResponse
     */
    paidEarnings?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevShareOverviewResponse
     */
    pendingEarnings?: MoneyValue;
}
/**
 * 
 * @export
 * @interface RevShareResponse
 */
export interface RevShareResponse {
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof RevShareResponse
     */
    agent?: AgentPreviewResponse;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevShareResponse
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RevShareResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof RevShareResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RevShareResponse
     */
    processedAt?: string;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof RevShareResponse
     */
    sponsor?: AgentPreviewResponse;
    /**
     * 
     * @type {number}
     * @memberof RevShareResponse
     */
    tier?: number;
    /**
     * 
     * @type {TransactionResponse}
     * @memberof RevShareResponse
     */
    transaction?: TransactionResponse;
    /**
     * 
     * @type {number}
     * @memberof RevShareResponse
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface RevShareStatusValue
 */
export interface RevShareStatusValue {
    /**
     * 
     * @type {number}
     * @memberof RevShareStatusValue
     */
    numProducingFrontLineAgents?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RevShareStatusValue
     */
    producing?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RevShareStatusValue
     */
    unlockedRevShareTier?: number;
}
/**
 * 
 * @export
 * @interface SearchOrder
 */
export interface SearchOrder {
    /**
     * 
     * @type {boolean}
     * @memberof SearchOrder
     */
    asc?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchOrder
     */
    column?: string;
}
/**
 * 
 * @export
 * @interface SearchParam
 */
export interface SearchParam {
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    column?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    joinColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    operator?: SearchParamOperatorEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SearchParam
     */
    stringStrictCase?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchParam
     */
    value?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchParamOperatorEnum {
    Eq = 'EQ',
    Gt = 'GT',
    Gte = 'GTE',
    In = 'IN',
    Like = 'LIKE',
    Lt = 'LT',
    Lte = 'LTE'
}

/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchRequest
     */
    filterAnd?: Array<SearchParam>;
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchRequest
     */
    filterOr?: Array<SearchParam>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    joinOn?: Array<string>;
    /**
     * 
     * @type {Array<SearchOrder>}
     * @memberof SearchRequest
     */
    order?: Array<SearchOrder>;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    responseColumns?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    targetObject?: SearchRequestTargetObjectEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchRequestTargetObjectEnum {
    AgentCredit = 'AGENT_CREDIT',
    CommissionDocument = 'COMMISSION_DOCUMENT',
    Invoice = 'INVOICE',
    MonitoringEvent = 'MONITORING_EVENT',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Participant = 'PARTICIPANT',
    Transaction = 'TRANSACTION'
}

/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SearchResponse
     */
    hasNext?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<SearchParam>}
     * @memberof SearchResponse
     */
    params?: Array<SearchParam>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchResponse
     */
    responseColumns?: Array<string>;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof SearchResponse
     */
    responseRows?: Array<Array<any>>;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    targetObject?: SearchResponseTargetObjectEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchResponseTargetObjectEnum {
    AgentCredit = 'AGENT_CREDIT',
    CommissionDocument = 'COMMISSION_DOCUMENT',
    Invoice = 'INVOICE',
    MonitoringEvent = 'MONITORING_EVENT',
    OutgoingPayment = 'OUTGOING_PAYMENT',
    Participant = 'PARTICIPANT',
    Transaction = 'TRANSACTION'
}

/**
 * 
 * @export
 * @interface SetPaymentsRequest
 */
export interface SetPaymentsRequest {
    /**
     * 
     * @type {Array<ParticipantPaymentValue>}
     * @memberof SetPaymentsRequest
     */
    payments?: Array<ParticipantPaymentValue>;
}
/**
 * 
 * @export
 * @interface StatusType
 */
export interface StatusType {
    /**
     * 
     * @type {string}
     * @memberof StatusType
     */
    family?: StatusTypeFamilyEnum;
    /**
     * 
     * @type {string}
     * @memberof StatusType
     */
    reasonPhrase?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusType
     */
    statusCode?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum StatusTypeFamilyEnum {
    ClientError = 'CLIENT_ERROR',
    Informational = 'INFORMATIONAL',
    Other = 'OTHER',
    Redirection = 'REDIRECTION',
    ServerError = 'SERVER_ERROR',
    Successful = 'SUCCESSFUL'
}

/**
 * 
 * @export
 * @interface TeamPlan
 */
export interface TeamPlan {
    /**
     * 
     * @type {number}
     * @memberof TeamPlan
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamPlan
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamPlan
     */
    memberSplit?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamPlan
     */
    role?: TeamPlanRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamPlan
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPlan
     */
    teamType?: TeamPlanTeamTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TeamPlanRoleEnum {
    Admin = 'ADMIN',
    Lead = 'LEAD',
    Member = 'MEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum TeamPlanTeamTypeEnum {
    Domestic = 'DOMESTIC',
    Group = 'GROUP',
    Normal = 'NORMAL',
    Platinum = 'PLATINUM'
}

/**
 * 
 * @export
 * @interface TransactionError
 */
export interface TransactionError {
    /**
     * 
     * @type {string}
     * @memberof TransactionError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionError
     */
    severity?: TransactionErrorSeverityEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionErrorSeverityEnum {
    Blocker = 'BLOCKER',
    Critical = 'CRITICAL',
    Warning = 'WARNING'
}

/**
 * 
 * @export
 * @interface TransactionLifecycleStateValue
 */
export interface TransactionLifecycleStateValue {
    /**
     * 
     * @type {string}
     * @memberof TransactionLifecycleStateValue
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionLifecycleStateValue
     */
    descriptionToTransition?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionLifecycleStateValue
     */
    state?: TransactionLifecycleStateValueStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionLifecycleStateValueStateEnum {
    ApprovedForClosing = 'APPROVED_FOR_CLOSING',
    CalculateLedger = 'CALCULATE_LEDGER',
    Closed = 'CLOSED',
    CommissionDocumentApproved = 'COMMISSION_DOCUMENT_APPROVED',
    CommissionDocumentGenerated = 'COMMISSION_DOCUMENT_GENERATED',
    CommissionDocumentSent = 'COMMISSION_DOCUMENT_SENT',
    CommissionValidated = 'COMMISSION_VALIDATED',
    NeedsCommissionValidation = 'NEEDS_COMMISSION_VALIDATION',
    New = 'NEW',
    PaymentAccepted = 'PAYMENT_ACCEPTED',
    PaymentScheduled = 'PAYMENT_SCHEDULED',
    ReadyForCommissionDocumentGeneration = 'READY_FOR_COMMISSION_DOCUMENT_GENERATION',
    Settled = 'SETTLED',
    Terminated = 'TERMINATED',
    WaitingOnPayment = 'WAITING_ON_PAYMENT'
}

/**
 * 
 * @export
 * @interface TransactionOwnerValue
 */
export interface TransactionOwnerValue {
    /**
     * 
     * @type {string}
     * @memberof TransactionOwnerValue
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOwnerValue
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOwnerValue
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOwnerValue
     */
    represents?: string;
}
/**
 * 
 * @export
 * @interface TransactionPreviewResponse
 */
export interface TransactionPreviewResponse {
    /**
     * 
     * @type {Address}
     * @memberof TransactionPreviewResponse
     */
    address?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPreviewResponse
     */
    closed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionPreviewResponse
     */
    closedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionPreviewResponse
     */
    closingDateActual?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionPreviewResponse
     */
    closingDateEstimated?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionPreviewResponse
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionPreviewResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {Array<TransactionError>}
     * @memberof TransactionPreviewResponse
     */
    errors?: Array<TransactionError>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof TransactionPreviewResponse
     */
    grossCommission?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof TransactionPreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {TransactionLifecycleStateValue}
     * @memberof TransactionPreviewResponse
     */
    lifecycleState?: TransactionLifecycleStateValue;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPreviewResponse
     */
    showOnboardingScreen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPreviewResponse
     */
    terminated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionPreviewResponse
     */
    terminatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionPreviewResponse
     */
    transactionType?: TransactionPreviewResponseTransactionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TransactionPreviewResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPreviewResponse
     */
    verified?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionPreviewResponseTransactionTypeEnum {
    CommercialLease = 'COMMERCIAL_LEASE',
    Compensating = 'COMPENSATING',
    Lease = 'LEASE',
    Other = 'OTHER',
    Plot = 'PLOT',
    Referral = 'REFERRAL',
    Sale = 'SALE'
}

/**
 * 
 * @export
 * @interface TransactionResponse
 */
export interface TransactionResponse {
    /**
     * 
     * @type {Address}
     * @memberof TransactionResponse
     */
    address?: Address;
    /**
     * 
     * @type {Array<AttachedFeeValue>}
     * @memberof TransactionResponse
     */
    attachedFees?: Array<AttachedFeeValue>;
    /**
     * 
     * @type {ParticipantValue}
     * @memberof TransactionResponse
     */
    cdPayer?: ParticipantValue;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    closed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    closedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    closingDateActual?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    closingDateEstimated?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    commissionInvoiceNumber?: string;
    /**
     * 
     * @type {Array<CommissionSplitParticipantValue>}
     * @memberof TransactionResponse
     */
    commissionSplits?: Array<CommissionSplitParticipantValue>;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    currentStateFailed?: boolean;
    /**
     * 
     * @type {Array<TransactionError>}
     * @memberof TransactionResponse
     */
    errors?: Array<TransactionError>;
    /**
     * 
     * @type {Array<EscrowResponse>}
     * @memberof TransactionResponse
     */
    escrows?: Array<EscrowResponse>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof TransactionResponse
     */
    grossCommission?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    id?: string;
    /**
     * 
     * @type {TransactionLifecycleStateValue}
     * @memberof TransactionResponse
     */
    lifecycleState?: TransactionLifecycleStateValue;
    /**
     * 
     * @type {TransactionLifecycleStateValue}
     * @memberof TransactionResponse
     */
    nextPrimaryAdminTransition?: TransactionLifecycleStateValue;
    /**
     * 
     * @type {TransactionLifecycleStateValue}
     * @memberof TransactionResponse
     */
    nextPrimaryUserTransition?: TransactionLifecycleStateValue;
    /**
     * 
     * @type {Array<TransactionLifecycleStateValue>}
     * @memberof TransactionResponse
     */
    nextSecondaryAdminTransitions?: Array<TransactionLifecycleStateValue>;
    /**
     * 
     * @type {Array<TransactionLifecycleStateValue>}
     * @memberof TransactionResponse
     */
    nextSecondaryUserTransitions?: Array<TransactionLifecycleStateValue>;
    /**
     * 
     * @type {OfficeResponse}
     * @memberof TransactionResponse
     */
    office?: OfficeResponse;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    officeAcceptsEscrow?: boolean;
    /**
     * 
     * @type {Array<ParticipantValue>}
     * @memberof TransactionResponse
     */
    otherParticipants?: Array<ParticipantValue>;
    /**
     * 
     * @type {Array<ParticipantResponse>}
     * @memberof TransactionResponse
     */
    participants?: Array<ParticipantResponse>;
    /**
     * 
     * @type {Array<PaymentParticipantValue>}
     * @memberof TransactionResponse
     */
    paymentParticipants?: Array<PaymentParticipantValue>;
    /**
     * 
     * @type {Array<ParticipantResponse>}
     * @memberof TransactionResponse
     */
    possiblePayableParticipants?: Array<ParticipantResponse>;
    /**
     * 
     * @type {MoneyValue}
     * @memberof TransactionResponse
     */
    price?: MoneyValue;
    /**
     * 
     * @type {ParticipantResponse}
     * @memberof TransactionResponse
     */
    realParticipant?: ParticipantResponse;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    showOnboardingScreen?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    skySlopeSaleGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    skySlopeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    skyslopeEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    terminated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    terminatedAt?: number;
    /**
     * 
     * @type {TransactionOwnerValue}
     * @memberof TransactionResponse
     */
    transactionOwner?: TransactionOwnerValue;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    transactionType?: TransactionResponseTransactionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    updatedAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    verified?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionResponseTransactionTypeEnum {
    CommercialLease = 'COMMERCIAL_LEASE',
    Compensating = 'COMPENSATING',
    Lease = 'LEASE',
    Other = 'OTHER',
    Plot = 'PLOT',
    Referral = 'REFERRAL',
    Sale = 'SALE'
}

/**
 * 
 * @export
 * @interface TransactionsMonthlyResponse
 */
export interface TransactionsMonthlyResponse {
    /**
     * 
     * @type {number}
     * @memberof TransactionsMonthlyResponse
     */
    month?: number;
    /**
     * 
     * @type {Array<TransactionResponse>}
     * @memberof TransactionsMonthlyResponse
     */
    transactions?: Array<TransactionResponse>;
    /**
     * 
     * @type {number}
     * @memberof TransactionsMonthlyResponse
     */
    year?: number;
}
/**
 * 
 * @export
 * @interface TransactionsOverviewResponse
 */
export interface TransactionsOverviewResponse {
    /**
     * 
     * @type {Array<TransactionPreviewResponse>}
     * @memberof TransactionsOverviewResponse
     */
    closedTransactions?: Array<TransactionPreviewResponse>;
    /**
     * 
     * @type {Array<TransactionPreviewResponse>}
     * @memberof TransactionsOverviewResponse
     */
    openTransactions?: Array<TransactionPreviewResponse>;
}
/**
 * 
 * @export
 * @interface UpdateCommissionAdvanceRequest
 */
export interface UpdateCommissionAdvanceRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommissionAdvanceRequest
     */
    advanceStatus?: UpdateCommissionAdvanceRequestAdvanceStatusEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof UpdateCommissionAdvanceRequest
     */
    amount?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommissionAdvanceRequest
     */
    companyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommissionAdvanceRequest
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommissionAdvanceRequest
     */
    notes?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateCommissionAdvanceRequestAdvanceStatusEnum {
    AgreementReceived = 'AGREEMENT_RECEIVED',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Repaid = 'REPAID'
}

/**
 * 
 * @export
 * @interface UpdateMonitorEventRequest
 */
export interface UpdateMonitorEventRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMonitorEventRequest
     */
    investigationStatus?: UpdateMonitorEventRequestInvestigationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateMonitorEventRequest
     */
    notes?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateMonitorEventRequestInvestigationStatusEnum {
    Done = 'DONE',
    GaveUp = 'GAVE_UP',
    Started = 'STARTED',
    Unstarted = 'UNSTARTED'
}

/**
 * 
 * @export
 * @interface UpdateOutgoingPaymentRequest
 */
export interface UpdateOutgoingPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOutgoingPaymentRequest
     */
    confirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOutgoingPaymentRequest
     */
    payerNote?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOutgoingPaymentRequest
     */
    paymentStatus: UpdateOutgoingPaymentRequestPaymentStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateOutgoingPaymentRequestPaymentStatusEnum {
    Cancelled = 'CANCELLED',
    Deleted = 'DELETED',
    Disputed = 'DISPUTED',
    GeneralError = 'GENERAL_ERROR',
    Open = 'OPEN',
    Paid = 'PAID',
    Pending = 'PENDING',
    Rejected = 'REJECTED',
    ScheduledForPayment = 'SCHEDULED_FOR_PAYMENT',
    SchedulingError = 'SCHEDULING_ERROR',
    SentToPaymentSystem = 'SENT_TO_PAYMENT_SYSTEM'
}

/**
 * 
 * @export
 * @interface UpdateParticipantRequest
 */
export interface UpdateParticipantRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateParticipantRequest
     */
    commissionDocumentRecipient: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateParticipantRequest
     */
    paidByReal: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    participantId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    participantRole: UpdateParticipantRequestParticipantRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateParticipantRequest
     */
    passThrough: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateParticipantRequest
     */
    payer: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    paymentInstructions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateParticipantRequest
     */
    personalDeal: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateParticipantRequest
     */
    phoneNumber?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateParticipantRequestParticipantRoleEnum {
    Appraiser = 'APPRAISER',
    Attorney = 'ATTORNEY',
    Buyer = 'BUYER',
    BuyersAgent = 'BUYERS_AGENT',
    DomesticLead = 'DOMESTIC_LEAD',
    Escrow = 'ESCROW',
    ExternalAgent = 'EXTERNAL_AGENT',
    HomeWarranty = 'HOME_WARRANTY',
    Inspection = 'INSPECTION',
    Landlord = 'LANDLORD',
    Lender = 'LENDER',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Other = 'OTHER',
    OtherAgent = 'OTHER_AGENT',
    Real = 'REAL',
    ReferralPayer = 'REFERRAL_PAYER',
    ReferringAgent = 'REFERRING_AGENT',
    Seller = 'SELLER',
    SellersAgent = 'SELLERS_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    Tenant = 'TENANT',
    TenantAgent = 'TENANT_AGENT',
    Title = 'TITLE',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface UpdateTransactionOnboardedRequest
 */
export interface UpdateTransactionOnboardedRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTransactionOnboardedRequest
     */
    onboarded: boolean;
}
/**
 * 
 * @export
 * @interface UpdateTransactionRequest
 */
export interface UpdateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    closingDateActual?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    closingDateEstimated?: string;
}
/**
 * 
 * @export
 * @interface UpdateTransactionVerifiedRequest
 */
export interface UpdateTransactionVerifiedRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTransactionVerifiedRequest
     */
    verified: boolean;
}

/**
 * AgentControllerApi - axios parameter creator
 * @export
 */
export const AgentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Connect user to skyslope
         * @param {string} agentYentaId agentYentaId
         * @param {string} emailAddress emailAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectToSkySlopeUsingPOST: async (agentYentaId: string, emailAddress: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('connectToSkySlopeUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('connectToSkySlopeUsingPOST', 'emailAddress', emailAddress)
            const localVarPath = `/api/v1/agent/{agentYentaId}/connectToSkyslope`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Commission Advance
         * @param {string} agentId agentId
         * @param {CreateCommissionAdvanceRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommissionAdvanceUsingPOST: async (agentId: string, req: CreateCommissionAdvanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('createCommissionAdvanceUsingPOST', 'agentId', agentId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('createCommissionAdvanceUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentId}/commission-advance`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Referral
         * @param {string} agentYentaId agentYentaId
         * @param {ReferralRequest} referralRequest referralRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferralUsingPOST: async (agentYentaId: string, referralRequest: ReferralRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('createReferralUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'referralRequest' is not null or undefined
            assertParamExists('createReferralUsingPOST', 'referralRequest', referralRequest)
            const localVarPath = `/api/v1/agent/{agentYentaId}/referral`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referralRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Referral
         * @param {string} referralId referralId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReferralUsingDELETE: async (referralId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('deleteReferralUsingDELETE', 'referralId', referralId)
            const localVarPath = `/api/v1/agent/referral/{referralId}`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disburse Referral
         * @param {string} referralId referralId
         * @param {DisburseReferralRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disburseReferralUsingPUT: async (referralId: string, req: DisburseReferralRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('disburseReferralUsingPUT', 'referralId', referralId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('disburseReferralUsingPUT', 'req', req)
            const localVarPath = `/api/v1/agent/referral/{referralId}/disburse`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Agent
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentByYentaIdUsingGET: async (agentYentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('getAgentByYentaIdUsingGET', 'agentYentaId', agentYentaId)
            const localVarPath = `/api/v1/agent/{agentYentaId}`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Agent\'s Commission Advances
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionAdvancesUsingGET: async (agentYentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('getCommissionAdvancesUsingGET', 'agentYentaId', agentYentaId)
            const localVarPath = `/api/v1/agent/{agentYentaId}/commission-advances`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get URL for download
         * @param {string} referralId referralId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreSignedUrlUsingGET: async (referralId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getPreSignedUrlUsingGET', 'referralId', referralId)
            const localVarPath = `/api/v1/agent/referral/{referralId}/download-url`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Referrals
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralsUsingGET: async (agentYentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('getReferralsUsingGET', 'agentYentaId', agentYentaId)
            const localVarPath = `/api/v1/agent/{agentYentaId}/referrals`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Referral Attachment
         * @param {string} referralId referralId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferralAttachmentUsingPUT: async (referralId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('updateReferralAttachmentUsingPUT', 'referralId', referralId)
            const localVarPath = `/api/v1/agent/referral/{referralId}/upload-attachment`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Referral
         * @param {string} referralId referralId
         * @param {ReferralRequest} referralRequest referralRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferralUsingPUT: async (referralId: string, referralRequest: ReferralRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('updateReferralUsingPUT', 'referralId', referralId)
            // verify required parameter 'referralRequest' is not null or undefined
            assertParamExists('updateReferralUsingPUT', 'referralRequest', referralRequest)
            const localVarPath = `/api/v1/agent/referral/{referralId}`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referralRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentControllerApi - functional programming interface
 * @export
 */
export const AgentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Connect user to skyslope
         * @param {string} agentYentaId agentYentaId
         * @param {string} emailAddress emailAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectToSkySlopeUsingPOST(agentYentaId: string, emailAddress: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectToSkySlopeUsingPOST(agentYentaId, emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Commission Advance
         * @param {string} agentId agentId
         * @param {CreateCommissionAdvanceRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommissionAdvanceUsingPOST(agentId: string, req: CreateCommissionAdvanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionAdvanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommissionAdvanceUsingPOST(agentId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Referral
         * @param {string} agentYentaId agentYentaId
         * @param {ReferralRequest} referralRequest referralRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReferralUsingPOST(agentYentaId: string, referralRequest: ReferralRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReferralUsingPOST(agentYentaId, referralRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Referral
         * @param {string} referralId referralId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReferralUsingDELETE(referralId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReferralUsingDELETE(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disburse Referral
         * @param {string} referralId referralId
         * @param {DisburseReferralRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disburseReferralUsingPUT(referralId: string, req: DisburseReferralRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disburseReferralUsingPUT(referralId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Agent
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentByYentaIdUsingGET(agentYentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentByYentaIdUsingGET(agentYentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Agent\'s Commission Advances
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommissionAdvancesUsingGET(agentYentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionAdvanceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommissionAdvancesUsingGET(agentYentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get URL for download
         * @param {string} referralId referralId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreSignedUrlUsingGET(referralId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreSignedUrlUsingGET(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Referrals
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralsUsingGET(agentYentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralsUsingGET(agentYentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Referral Attachment
         * @param {string} referralId referralId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReferralAttachmentUsingPUT(referralId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReferralAttachmentUsingPUT(referralId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Referral
         * @param {string} referralId referralId
         * @param {ReferralRequest} referralRequest referralRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReferralUsingPUT(referralId: string, referralRequest: ReferralRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReferralUsingPUT(referralId, referralRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentControllerApi - factory interface
 * @export
 */
export const AgentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Connect user to skyslope
         * @param {string} agentYentaId agentYentaId
         * @param {string} emailAddress emailAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectToSkySlopeUsingPOST(agentYentaId: string, emailAddress: string, options?: any): AxiosPromise<void> {
            return localVarFp.connectToSkySlopeUsingPOST(agentYentaId, emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Commission Advance
         * @param {string} agentId agentId
         * @param {CreateCommissionAdvanceRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommissionAdvanceUsingPOST(agentId: string, req: CreateCommissionAdvanceRequest, options?: any): AxiosPromise<CommissionAdvanceResponse> {
            return localVarFp.createCommissionAdvanceUsingPOST(agentId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Referral
         * @param {string} agentYentaId agentYentaId
         * @param {ReferralRequest} referralRequest referralRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferralUsingPOST(agentYentaId: string, referralRequest: ReferralRequest, options?: any): AxiosPromise<ReferralResponse> {
            return localVarFp.createReferralUsingPOST(agentYentaId, referralRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Referral
         * @param {string} referralId referralId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReferralUsingDELETE(referralId: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.deleteReferralUsingDELETE(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disburse Referral
         * @param {string} referralId referralId
         * @param {DisburseReferralRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disburseReferralUsingPUT(referralId: string, req: DisburseReferralRequest, options?: any): AxiosPromise<ReferralResponse> {
            return localVarFp.disburseReferralUsingPUT(referralId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Agent
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentByYentaIdUsingGET(agentYentaId: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getAgentByYentaIdUsingGET(agentYentaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Agent\'s Commission Advances
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionAdvancesUsingGET(agentYentaId: string, options?: any): AxiosPromise<CommissionAdvanceListResponse> {
            return localVarFp.getCommissionAdvancesUsingGET(agentYentaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get URL for download
         * @param {string} referralId referralId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreSignedUrlUsingGET(referralId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPreSignedUrlUsingGET(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Referrals
         * @param {string} agentYentaId agentYentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralsUsingGET(agentYentaId: string, options?: any): AxiosPromise<ReferralsResponse> {
            return localVarFp.getReferralsUsingGET(agentYentaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Referral Attachment
         * @param {string} referralId referralId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferralAttachmentUsingPUT(referralId: string, file?: any, options?: any): AxiosPromise<ReferralResponse> {
            return localVarFp.updateReferralAttachmentUsingPUT(referralId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Referral
         * @param {string} referralId referralId
         * @param {ReferralRequest} referralRequest referralRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferralUsingPUT(referralId: string, referralRequest: ReferralRequest, options?: any): AxiosPromise<ReferralResponse> {
            return localVarFp.updateReferralUsingPUT(referralId, referralRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentControllerApi - object-oriented interface
 * @export
 * @class AgentControllerApi
 * @extends {BaseAPI}
 */
export class AgentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Connect user to skyslope
     * @param {string} agentYentaId agentYentaId
     * @param {string} emailAddress emailAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public connectToSkySlopeUsingPOST(agentYentaId: string, emailAddress: string, options?: any) {
        return AgentControllerApiFp(this.configuration).connectToSkySlopeUsingPOST(agentYentaId, emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Commission Advance
     * @param {string} agentId agentId
     * @param {CreateCommissionAdvanceRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public createCommissionAdvanceUsingPOST(agentId: string, req: CreateCommissionAdvanceRequest, options?: any) {
        return AgentControllerApiFp(this.configuration).createCommissionAdvanceUsingPOST(agentId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Referral
     * @param {string} agentYentaId agentYentaId
     * @param {ReferralRequest} referralRequest referralRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public createReferralUsingPOST(agentYentaId: string, referralRequest: ReferralRequest, options?: any) {
        return AgentControllerApiFp(this.configuration).createReferralUsingPOST(agentYentaId, referralRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Referral
     * @param {string} referralId referralId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public deleteReferralUsingDELETE(referralId: string, options?: any) {
        return AgentControllerApiFp(this.configuration).deleteReferralUsingDELETE(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disburse Referral
     * @param {string} referralId referralId
     * @param {DisburseReferralRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public disburseReferralUsingPUT(referralId: string, req: DisburseReferralRequest, options?: any) {
        return AgentControllerApiFp(this.configuration).disburseReferralUsingPUT(referralId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Agent
     * @param {string} agentYentaId agentYentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentByYentaIdUsingGET(agentYentaId: string, options?: any) {
        return AgentControllerApiFp(this.configuration).getAgentByYentaIdUsingGET(agentYentaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Agent\'s Commission Advances
     * @param {string} agentYentaId agentYentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getCommissionAdvancesUsingGET(agentYentaId: string, options?: any) {
        return AgentControllerApiFp(this.configuration).getCommissionAdvancesUsingGET(agentYentaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get URL for download
     * @param {string} referralId referralId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getPreSignedUrlUsingGET(referralId: string, options?: any) {
        return AgentControllerApiFp(this.configuration).getPreSignedUrlUsingGET(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Referrals
     * @param {string} agentYentaId agentYentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getReferralsUsingGET(agentYentaId: string, options?: any) {
        return AgentControllerApiFp(this.configuration).getReferralsUsingGET(agentYentaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Referral Attachment
     * @param {string} referralId referralId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateReferralAttachmentUsingPUT(referralId: string, file?: any, options?: any) {
        return AgentControllerApiFp(this.configuration).updateReferralAttachmentUsingPUT(referralId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Referral
     * @param {string} referralId referralId
     * @param {ReferralRequest} referralRequest referralRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateReferralUsingPUT(referralId: string, referralRequest: ReferralRequest, options?: any) {
        return AgentControllerApiFp(this.configuration).updateReferralUsingPUT(referralId, referralRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentCreditControllerApi - axios parameter creator
 * @export
 */
export const AgentCreditControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary i a credit toward agent\'s brokerage fee payments
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBrokerageFeeCreditUsingPOST: async (agentYentaId: string, req: CreateMonetaryCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issueBrokerageFeeCreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issueBrokerageFeeCreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/brokerage-fee-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s cap
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueCapCreditUsingPOST: async (agentYentaId: string, req: CreateMonetaryCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issueCapCreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issueCapCreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/cap-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s gross commission income (GCI)
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueCumulativeGCICreditUsingPOST: async (agentYentaId: string, req: CreateMonetaryCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issueCumulativeGCICreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issueCumulativeGCICreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/cumulative-gci-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s number of executed transactions
         * @param {string} agentYentaId agentYentaId
         * @param {CreateNumericalCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueNumberOfExecutedTransactionsCreditUsingPOST: async (agentYentaId: string, req: CreateNumericalCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issueNumberOfExecutedTransactionsCreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issueNumberOfExecutedTransactionsCreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/number-of-executed-transactions-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s number of personal transactions
         * @param {string} agentYentaId agentYentaId
         * @param {CreateNumericalCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueNumberOfPersonalTransactionsCreditUsingPOST: async (agentYentaId: string, req: CreateNumericalCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issueNumberOfPersonalTransactionsCreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issueNumberOfPersonalTransactionsCreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/number-of-personal-transactions-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap stock purchase
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePostCapEquityPurchaseCreditUsingPOST: async (agentYentaId: string, req: CreateMonetaryCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issuePostCapEquityPurchaseCreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issuePostCapEquityPurchaseCreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/post-cap-stock-purchase-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap fees
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePostCapFeeCreditUsingPOST: async (agentYentaId: string, req: CreateMonetaryCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issuePostCapFeeCreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issuePostCapFeeCreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/post-cap-fee-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap stock purchase
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePreCapEquityPurchaseCreditUsingPOST: async (agentYentaId: string, req: CreateMonetaryCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issuePreCapEquityPurchaseCreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issuePreCapEquityPurchaseCreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/pre-cap-stock-purchase-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s rev share
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueRevShareCreditUsingPOST: async (agentYentaId: string, req: CreateMonetaryCreditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentYentaId' is not null or undefined
            assertParamExists('issueRevShareCreditUsingPOST', 'agentYentaId', agentYentaId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('issueRevShareCreditUsingPOST', 'req', req)
            const localVarPath = `/api/v1/agent/{agentYentaId}/revshare-credit`
                .replace(`{${"agentYentaId"}}`, encodeURIComponent(String(agentYentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentCreditControllerApi - functional programming interface
 * @export
 */
export const AgentCreditControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentCreditControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary i a credit toward agent\'s brokerage fee payments
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueBrokerageFeeCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueBrokerageFeeCreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s cap
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueCapCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueCapCreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s gross commission income (GCI)
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueCumulativeGCICreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueCumulativeGCICreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s number of executed transactions
         * @param {string} agentYentaId agentYentaId
         * @param {CreateNumericalCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueNumberOfExecutedTransactionsCreditUsingPOST(agentYentaId: string, req: CreateNumericalCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueNumberOfExecutedTransactionsCreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s number of personal transactions
         * @param {string} agentYentaId agentYentaId
         * @param {CreateNumericalCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueNumberOfPersonalTransactionsCreditUsingPOST(agentYentaId: string, req: CreateNumericalCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueNumberOfPersonalTransactionsCreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap stock purchase
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuePostCapEquityPurchaseCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuePostCapEquityPurchaseCreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap fees
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuePostCapFeeCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuePostCapFeeCreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap stock purchase
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuePreCapEquityPurchaseCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuePreCapEquityPurchaseCreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s rev share
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueRevShareCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueRevShareCreditUsingPOST(agentYentaId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentCreditControllerApi - factory interface
 * @export
 */
export const AgentCreditControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentCreditControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary i a credit toward agent\'s brokerage fee payments
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueBrokerageFeeCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issueBrokerageFeeCreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s cap
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueCapCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issueCapCreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s gross commission income (GCI)
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueCumulativeGCICreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issueCumulativeGCICreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s number of executed transactions
         * @param {string} agentYentaId agentYentaId
         * @param {CreateNumericalCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueNumberOfExecutedTransactionsCreditUsingPOST(agentYentaId: string, req: CreateNumericalCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issueNumberOfExecutedTransactionsCreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s number of personal transactions
         * @param {string} agentYentaId agentYentaId
         * @param {CreateNumericalCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueNumberOfPersonalTransactionsCreditUsingPOST(agentYentaId: string, req: CreateNumericalCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issueNumberOfPersonalTransactionsCreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap stock purchase
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePostCapEquityPurchaseCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issuePostCapEquityPurchaseCreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap fees
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePostCapFeeCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issuePostCapFeeCreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s post-cap stock purchase
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePreCapEquityPurchaseCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issuePreCapEquityPurchaseCreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issue a credit toward agent\'s rev share
         * @param {string} agentYentaId agentYentaId
         * @param {CreateMonetaryCreditRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueRevShareCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any): AxiosPromise<AgentCreditResponse> {
            return localVarFp.issueRevShareCreditUsingPOST(agentYentaId, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentCreditControllerApi - object-oriented interface
 * @export
 * @class AgentCreditControllerApi
 * @extends {BaseAPI}
 */
export class AgentCreditControllerApi extends BaseAPI {
    /**
     * 
     * @summary i a credit toward agent\'s brokerage fee payments
     * @param {string} agentYentaId agentYentaId
     * @param {CreateMonetaryCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issueBrokerageFeeCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issueBrokerageFeeCreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issue a credit toward agent\'s cap
     * @param {string} agentYentaId agentYentaId
     * @param {CreateMonetaryCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issueCapCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issueCapCreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issue a credit toward agent\'s gross commission income (GCI)
     * @param {string} agentYentaId agentYentaId
     * @param {CreateMonetaryCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issueCumulativeGCICreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issueCumulativeGCICreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issue a credit toward agent\'s number of executed transactions
     * @param {string} agentYentaId agentYentaId
     * @param {CreateNumericalCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issueNumberOfExecutedTransactionsCreditUsingPOST(agentYentaId: string, req: CreateNumericalCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issueNumberOfExecutedTransactionsCreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issue a credit toward agent\'s number of personal transactions
     * @param {string} agentYentaId agentYentaId
     * @param {CreateNumericalCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issueNumberOfPersonalTransactionsCreditUsingPOST(agentYentaId: string, req: CreateNumericalCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issueNumberOfPersonalTransactionsCreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issue a credit toward agent\'s post-cap stock purchase
     * @param {string} agentYentaId agentYentaId
     * @param {CreateMonetaryCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issuePostCapEquityPurchaseCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issuePostCapEquityPurchaseCreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issue a credit toward agent\'s post-cap fees
     * @param {string} agentYentaId agentYentaId
     * @param {CreateMonetaryCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issuePostCapFeeCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issuePostCapFeeCreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issue a credit toward agent\'s post-cap stock purchase
     * @param {string} agentYentaId agentYentaId
     * @param {CreateMonetaryCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issuePreCapEquityPurchaseCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issuePreCapEquityPurchaseCreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issue a credit toward agent\'s rev share
     * @param {string} agentYentaId agentYentaId
     * @param {CreateMonetaryCreditRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCreditControllerApi
     */
    public issueRevShareCreditUsingPOST(agentYentaId: string, req: CreateMonetaryCreditRequest, options?: any) {
        return AgentCreditControllerApiFp(this.configuration).issueRevShareCreditUsingPOST(agentYentaId, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommissionAdvancesControllerApi - axios parameter creator
 * @export
 */
export const CommissionAdvancesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get URL for download
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreSignedUrlUsingGET1: async (commissionAdvanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commissionAdvanceId' is not null or undefined
            assertParamExists('getPreSignedUrlUsingGET1', 'commissionAdvanceId', commissionAdvanceId)
            const localVarPath = `/api/v1/commission-advances/{commissionAdvanceId}/download-url`
                .replace(`{${"commissionAdvanceId"}}`, encodeURIComponent(String(commissionAdvanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Commission Advance Attachment
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommissionAdvanceAttachmentUsingPUT: async (commissionAdvanceId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commissionAdvanceId' is not null or undefined
            assertParamExists('updateCommissionAdvanceAttachmentUsingPUT', 'commissionAdvanceId', commissionAdvanceId)
            const localVarPath = `/api/v1/commission-advances/{commissionAdvanceId}/upload-attachment`
                .replace(`{${"commissionAdvanceId"}}`, encodeURIComponent(String(commissionAdvanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Commission Advance
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {UpdateCommissionAdvanceRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommissionAdvanceUsingPUT: async (commissionAdvanceId: string, req: UpdateCommissionAdvanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commissionAdvanceId' is not null or undefined
            assertParamExists('updateCommissionAdvanceUsingPUT', 'commissionAdvanceId', commissionAdvanceId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateCommissionAdvanceUsingPUT', 'req', req)
            const localVarPath = `/api/v1/commission-advances/{commissionAdvanceId}`
                .replace(`{${"commissionAdvanceId"}}`, encodeURIComponent(String(commissionAdvanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommissionAdvancesControllerApi - functional programming interface
 * @export
 */
export const CommissionAdvancesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommissionAdvancesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get URL for download
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreSignedUrlUsingGET1(commissionAdvanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreSignedUrlUsingGET1(commissionAdvanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Commission Advance Attachment
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommissionAdvanceAttachmentUsingPUT(commissionAdvanceId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionAdvanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommissionAdvanceAttachmentUsingPUT(commissionAdvanceId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Commission Advance
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {UpdateCommissionAdvanceRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommissionAdvanceUsingPUT(commissionAdvanceId: string, req: UpdateCommissionAdvanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionAdvanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommissionAdvanceUsingPUT(commissionAdvanceId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommissionAdvancesControllerApi - factory interface
 * @export
 */
export const CommissionAdvancesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommissionAdvancesControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get URL for download
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreSignedUrlUsingGET1(commissionAdvanceId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPreSignedUrlUsingGET1(commissionAdvanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Commission Advance Attachment
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommissionAdvanceAttachmentUsingPUT(commissionAdvanceId: string, file?: any, options?: any): AxiosPromise<CommissionAdvanceResponse> {
            return localVarFp.updateCommissionAdvanceAttachmentUsingPUT(commissionAdvanceId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Commission Advance
         * @param {string} commissionAdvanceId commissionAdvanceId
         * @param {UpdateCommissionAdvanceRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommissionAdvanceUsingPUT(commissionAdvanceId: string, req: UpdateCommissionAdvanceRequest, options?: any): AxiosPromise<CommissionAdvanceResponse> {
            return localVarFp.updateCommissionAdvanceUsingPUT(commissionAdvanceId, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommissionAdvancesControllerApi - object-oriented interface
 * @export
 * @class CommissionAdvancesControllerApi
 * @extends {BaseAPI}
 */
export class CommissionAdvancesControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get URL for download
     * @param {string} commissionAdvanceId commissionAdvanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionAdvancesControllerApi
     */
    public getPreSignedUrlUsingGET1(commissionAdvanceId: string, options?: any) {
        return CommissionAdvancesControllerApiFp(this.configuration).getPreSignedUrlUsingGET1(commissionAdvanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Commission Advance Attachment
     * @param {string} commissionAdvanceId commissionAdvanceId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionAdvancesControllerApi
     */
    public updateCommissionAdvanceAttachmentUsingPUT(commissionAdvanceId: string, file?: any, options?: any) {
        return CommissionAdvancesControllerApiFp(this.configuration).updateCommissionAdvanceAttachmentUsingPUT(commissionAdvanceId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Commission Advance
     * @param {string} commissionAdvanceId commissionAdvanceId
     * @param {UpdateCommissionAdvanceRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionAdvancesControllerApi
     */
    public updateCommissionAdvanceUsingPUT(commissionAdvanceId: string, req: UpdateCommissionAdvanceRequest, options?: any) {
        return CommissionAdvancesControllerApiFp(this.configuration).updateCommissionAdvanceUsingPUT(commissionAdvanceId, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommissionDocumentControllerApi - axios parameter creator
 * @export
 */
export const CommissionDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get commission document by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionDocumentByIdUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCommissionDocumentByIdUsingGET', 'id', id)
            const localVarPath = `/api/v1/cdas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get URL for download
         * @param {string} commissionDocumentId commissionDocumentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreSignedUrlUsingGET2: async (commissionDocumentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commissionDocumentId' is not null or undefined
            assertParamExists('getPreSignedUrlUsingGET2', 'commissionDocumentId', commissionDocumentId)
            const localVarPath = `/api/v1/cdas/{commissionDocumentId}/download-url`
                .replace(`{${"commissionDocumentId"}}`, encodeURIComponent(String(commissionDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommissionDocumentControllerApi - functional programming interface
 * @export
 */
export const CommissionDocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommissionDocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get commission document by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommissionDocumentByIdUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommissionDocumentByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get URL for download
         * @param {string} commissionDocumentId commissionDocumentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreSignedUrlUsingGET2(commissionDocumentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreSignedUrlUsingGET2(commissionDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommissionDocumentControllerApi - factory interface
 * @export
 */
export const CommissionDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommissionDocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get commission document by id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionDocumentByIdUsingGET(id: string, options?: any): AxiosPromise<CommissionDocumentResponse> {
            return localVarFp.getCommissionDocumentByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get URL for download
         * @param {string} commissionDocumentId commissionDocumentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreSignedUrlUsingGET2(commissionDocumentId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPreSignedUrlUsingGET2(commissionDocumentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommissionDocumentControllerApi - object-oriented interface
 * @export
 * @class CommissionDocumentControllerApi
 * @extends {BaseAPI}
 */
export class CommissionDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get commission document by id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionDocumentControllerApi
     */
    public getCommissionDocumentByIdUsingGET(id: string, options?: any) {
        return CommissionDocumentControllerApiFp(this.configuration).getCommissionDocumentByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get URL for download
     * @param {string} commissionDocumentId commissionDocumentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionDocumentControllerApi
     */
    public getPreSignedUrlUsingGET2(commissionDocumentId: string, options?: any) {
        return CommissionDocumentControllerApiFp(this.configuration).getPreSignedUrlUsingGET2(commissionDocumentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EquityControllerApi - axios parameter creator
 * @export
 */
export const EquityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gets the current equity contributions overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRevSharePerformanceOverviewUsingGET: async (yentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getCurrentRevSharePerformanceOverviewUsingGET', 'yentaId', yentaId)
            const localVarPath = `/api/v1/equity/performance/{yentaId}/current`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets the equity contributions over transactions for a particular month for an agent
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyPerformanceOverviewUsingGET: async (yentaId: string, month?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getMonthlyPerformanceOverviewUsingGET', 'yentaId', yentaId)
            const localVarPath = `/api/v1/equity/performance/{yentaId}`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquityControllerApi - functional programming interface
 * @export
 */
export const EquityControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquityControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gets the current equity contributions overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentRevSharePerformanceOverviewUsingGET(yentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentRevSharePerformanceOverviewUsingGET(yentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets the equity contributions over transactions for a particular month for an agent
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyPerformanceOverviewUsingGET(yentaId: string, month?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityMonthlyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyPerformanceOverviewUsingGET(yentaId, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquityControllerApi - factory interface
 * @export
 */
export const EquityControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquityControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary gets the current equity contributions overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRevSharePerformanceOverviewUsingGET(yentaId: string, options?: any): AxiosPromise<EquityOverviewResponse> {
            return localVarFp.getCurrentRevSharePerformanceOverviewUsingGET(yentaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets the equity contributions over transactions for a particular month for an agent
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyPerformanceOverviewUsingGET(yentaId: string, month?: string, options?: any): AxiosPromise<EquityMonthlyResponse> {
            return localVarFp.getMonthlyPerformanceOverviewUsingGET(yentaId, month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquityControllerApi - object-oriented interface
 * @export
 * @class EquityControllerApi
 * @extends {BaseAPI}
 */
export class EquityControllerApi extends BaseAPI {
    /**
     * 
     * @summary gets the current equity contributions overview for an agent
     * @param {string} yentaId yentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquityControllerApi
     */
    public getCurrentRevSharePerformanceOverviewUsingGET(yentaId: string, options?: any) {
        return EquityControllerApiFp(this.configuration).getCurrentRevSharePerformanceOverviewUsingGET(yentaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets the equity contributions over transactions for a particular month for an agent
     * @param {string} yentaId yentaId
     * @param {string} [month] month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquityControllerApi
     */
    public getMonthlyPerformanceOverviewUsingGET(yentaId: string, month?: string, options?: any) {
        return EquityControllerApiFp(this.configuration).getMonthlyPerformanceOverviewUsingGET(yentaId, month, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IncomeControllerApi - axios parameter creator
 * @export
 */
export const IncomeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get income totals for the anniversary year
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeTotalsUsingGET: async (yentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getIncomeTotalsUsingGET', 'yentaId', yentaId)
            const localVarPath = `/api/v1/income/overview/{yentaId}/total`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the monthly income for a given month
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyIncomePerformanceUsingGET: async (yentaId: string, month?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getMonthlyIncomePerformanceUsingGET', 'yentaId', yentaId)
            const localVarPath = `/api/v1/income/overview/{yentaId}`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets the current yearly income performance overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearlyIncomePerformanceOverviewUsingGET: async (yentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getYearlyIncomePerformanceOverviewUsingGET', 'yentaId', yentaId)
            const localVarPath = `/api/v1/income/overview/{yentaId}/current`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IncomeControllerApi - functional programming interface
 * @export
 */
export const IncomeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IncomeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get income totals for the anniversary year
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIncomeTotalsUsingGET(yentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeTotalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIncomeTotalsUsingGET(yentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the monthly income for a given month
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyIncomePerformanceUsingGET(yentaId: string, month?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeMonthlyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyIncomePerformanceUsingGET(yentaId, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets the current yearly income performance overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYearlyIncomePerformanceOverviewUsingGET(yentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYearlyIncomePerformanceOverviewUsingGET(yentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IncomeControllerApi - factory interface
 * @export
 */
export const IncomeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IncomeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary get income totals for the anniversary year
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeTotalsUsingGET(yentaId: string, options?: any): AxiosPromise<IncomeTotalsResponse> {
            return localVarFp.getIncomeTotalsUsingGET(yentaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the monthly income for a given month
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyIncomePerformanceUsingGET(yentaId: string, month?: string, options?: any): AxiosPromise<IncomeMonthlyResponse> {
            return localVarFp.getMonthlyIncomePerformanceUsingGET(yentaId, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets the current yearly income performance overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearlyIncomePerformanceOverviewUsingGET(yentaId: string, options?: any): AxiosPromise<IncomeOverviewResponse> {
            return localVarFp.getYearlyIncomePerformanceOverviewUsingGET(yentaId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IncomeControllerApi - object-oriented interface
 * @export
 * @class IncomeControllerApi
 * @extends {BaseAPI}
 */
export class IncomeControllerApi extends BaseAPI {
    /**
     * 
     * @summary get income totals for the anniversary year
     * @param {string} yentaId yentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomeControllerApi
     */
    public getIncomeTotalsUsingGET(yentaId: string, options?: any) {
        return IncomeControllerApiFp(this.configuration).getIncomeTotalsUsingGET(yentaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the monthly income for a given month
     * @param {string} yentaId yentaId
     * @param {string} [month] month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomeControllerApi
     */
    public getMonthlyIncomePerformanceUsingGET(yentaId: string, month?: string, options?: any) {
        return IncomeControllerApiFp(this.configuration).getMonthlyIncomePerformanceUsingGET(yentaId, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets the current yearly income performance overview for an agent
     * @param {string} yentaId yentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomeControllerApi
     */
    public getYearlyIncomePerformanceOverviewUsingGET(yentaId: string, options?: any) {
        return IncomeControllerApiFp(this.configuration).getYearlyIncomePerformanceOverviewUsingGET(yentaId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceControllerApi - axios parameter creator
 * @export
 */
export const InvoiceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel an Invoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoiceUsingPUT: async (invoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('cancelInvoiceUsingPUT', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/invoices/cancel/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Outgoing Payment
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceUsingGET: async (invoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getInvoiceUsingGET', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Re-Schedule Invoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryInvoiceUsingGET: async (invoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('retryInvoiceUsingGET', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/invoices/retryInvoice/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceControllerApi - functional programming interface
 * @export
 */
export const InvoiceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel an Invoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvoiceUsingPUT(invoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoiceUsingPUT(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Outgoing Payment
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceUsingGET(invoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceUsingGET(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Re-Schedule Invoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryInvoiceUsingGET(invoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryInvoiceUsingGET(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceControllerApi - factory interface
 * @export
 */
export const InvoiceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel an Invoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoiceUsingPUT(invoiceId: string, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.cancelInvoiceUsingPUT(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Outgoing Payment
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceUsingGET(invoiceId: string, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.getInvoiceUsingGET(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Re-Schedule Invoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryInvoiceUsingGET(invoiceId: string, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.retryInvoiceUsingGET(invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceControllerApi - object-oriented interface
 * @export
 * @class InvoiceControllerApi
 * @extends {BaseAPI}
 */
export class InvoiceControllerApi extends BaseAPI {
    /**
     * 
     * @summary Cancel an Invoice
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public cancelInvoiceUsingPUT(invoiceId: string, options?: any) {
        return InvoiceControllerApiFp(this.configuration).cancelInvoiceUsingPUT(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Outgoing Payment
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getInvoiceUsingGET(invoiceId: string, options?: any) {
        return InvoiceControllerApiFp(this.configuration).getInvoiceUsingGET(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Re-Schedule Invoice
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public retryInvoiceUsingGET(invoiceId: string, options?: any) {
        return InvoiceControllerApiFp(this.configuration).retryInvoiceUsingGET(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MonitorEventsControllerApi - axios parameter creator
 * @export
 */
export const MonitorEventsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Monitor Event
         * @param {string} monitorEventId monitorEventId
         * @param {UpdateMonitorEventRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMonitorEventUsingPUT: async (monitorEventId: string, req: UpdateMonitorEventRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorEventId' is not null or undefined
            assertParamExists('updateMonitorEventUsingPUT', 'monitorEventId', monitorEventId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateMonitorEventUsingPUT', 'req', req)
            const localVarPath = `/api/v1/monitor-events/{monitorEventId}`
                .replace(`{${"monitorEventId"}}`, encodeURIComponent(String(monitorEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonitorEventsControllerApi - functional programming interface
 * @export
 */
export const MonitorEventsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonitorEventsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Monitor Event
         * @param {string} monitorEventId monitorEventId
         * @param {UpdateMonitorEventRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMonitorEventUsingPUT(monitorEventId: string, req: UpdateMonitorEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitorEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMonitorEventUsingPUT(monitorEventId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MonitorEventsControllerApi - factory interface
 * @export
 */
export const MonitorEventsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonitorEventsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Monitor Event
         * @param {string} monitorEventId monitorEventId
         * @param {UpdateMonitorEventRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMonitorEventUsingPUT(monitorEventId: string, req: UpdateMonitorEventRequest, options?: any): AxiosPromise<MonitorEventResponse> {
            return localVarFp.updateMonitorEventUsingPUT(monitorEventId, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonitorEventsControllerApi - object-oriented interface
 * @export
 * @class MonitorEventsControllerApi
 * @extends {BaseAPI}
 */
export class MonitorEventsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update Monitor Event
     * @param {string} monitorEventId monitorEventId
     * @param {UpdateMonitorEventRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorEventsControllerApi
     */
    public updateMonitorEventUsingPUT(monitorEventId: string, req: UpdateMonitorEventRequest, options?: any) {
        return MonitorEventsControllerApiFp(this.configuration).updateMonitorEventUsingPUT(monitorEventId, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OutgoingPaymentsControllerApi - axios parameter creator
 * @export
 */
export const OutgoingPaymentsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutgoingPaymentUsingGET: async (outgoingPaymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'outgoingPaymentId' is not null or undefined
            assertParamExists('getOutgoingPaymentUsingGET', 'outgoingPaymentId', outgoingPaymentId)
            const localVarPath = `/api/v1/outgoing-payments/{outgoingPaymentId}`
                .replace(`{${"outgoingPaymentId"}}`, encodeURIComponent(String(outgoingPaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Re-Schedule Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reScheduleOutGoingPaymentUsingGET: async (outgoingPaymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'outgoingPaymentId' is not null or undefined
            assertParamExists('reScheduleOutGoingPaymentUsingGET', 'outgoingPaymentId', outgoingPaymentId)
            const localVarPath = `/api/v1/outgoing-payments/reScheduleOutGoingPayment/{outgoingPaymentId}`
                .replace(`{${"outgoingPaymentId"}}`, encodeURIComponent(String(outgoingPaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {UpdateOutgoingPaymentRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOutgoingPaymentUsingPUT: async (outgoingPaymentId: string, req: UpdateOutgoingPaymentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'outgoingPaymentId' is not null or undefined
            assertParamExists('updateOutgoingPaymentUsingPUT', 'outgoingPaymentId', outgoingPaymentId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateOutgoingPaymentUsingPUT', 'req', req)
            const localVarPath = `/api/v1/outgoing-payments/{outgoingPaymentId}`
                .replace(`{${"outgoingPaymentId"}}`, encodeURIComponent(String(outgoingPaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutgoingPaymentsControllerApi - functional programming interface
 * @export
 */
export const OutgoingPaymentsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutgoingPaymentsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutgoingPaymentUsingGET(outgoingPaymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOutgoingPaymentUsingGET(outgoingPaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Re-Schedule Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reScheduleOutGoingPaymentUsingGET(outgoingPaymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reScheduleOutGoingPaymentUsingGET(outgoingPaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {UpdateOutgoingPaymentRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOutgoingPaymentUsingPUT(outgoingPaymentId: string, req: UpdateOutgoingPaymentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOutgoingPaymentUsingPUT(outgoingPaymentId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OutgoingPaymentsControllerApi - factory interface
 * @export
 */
export const OutgoingPaymentsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutgoingPaymentsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutgoingPaymentUsingGET(outgoingPaymentId: string, options?: any): AxiosPromise<OutgoingPaymentResponse> {
            return localVarFp.getOutgoingPaymentUsingGET(outgoingPaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Re-Schedule Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reScheduleOutGoingPaymentUsingGET(outgoingPaymentId: string, options?: any): AxiosPromise<OutgoingPaymentResponse> {
            return localVarFp.reScheduleOutGoingPaymentUsingGET(outgoingPaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Outgoing Payment
         * @param {string} outgoingPaymentId outgoingPaymentId
         * @param {UpdateOutgoingPaymentRequest} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOutgoingPaymentUsingPUT(outgoingPaymentId: string, req: UpdateOutgoingPaymentRequest, options?: any): AxiosPromise<OutgoingPaymentResponse> {
            return localVarFp.updateOutgoingPaymentUsingPUT(outgoingPaymentId, req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutgoingPaymentsControllerApi - object-oriented interface
 * @export
 * @class OutgoingPaymentsControllerApi
 * @extends {BaseAPI}
 */
export class OutgoingPaymentsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get Outgoing Payment
     * @param {string} outgoingPaymentId outgoingPaymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingPaymentsControllerApi
     */
    public getOutgoingPaymentUsingGET(outgoingPaymentId: string, options?: any) {
        return OutgoingPaymentsControllerApiFp(this.configuration).getOutgoingPaymentUsingGET(outgoingPaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Re-Schedule Outgoing Payment
     * @param {string} outgoingPaymentId outgoingPaymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingPaymentsControllerApi
     */
    public reScheduleOutGoingPaymentUsingGET(outgoingPaymentId: string, options?: any) {
        return OutgoingPaymentsControllerApiFp(this.configuration).reScheduleOutGoingPaymentUsingGET(outgoingPaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Outgoing Payment
     * @param {string} outgoingPaymentId outgoingPaymentId
     * @param {UpdateOutgoingPaymentRequest} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutgoingPaymentsControllerApi
     */
    public updateOutgoingPaymentUsingPUT(outgoingPaymentId: string, req: UpdateOutgoingPaymentRequest, options?: any) {
        return OutgoingPaymentsControllerApiFp(this.configuration).updateOutgoingPaymentUsingPUT(outgoingPaymentId, req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RevShareControllerApi - axios parameter creator
 * @export
 */
export const RevShareControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gets the current rev share performance overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRevSharePerformanceOverviewUsingGET1: async (yentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getCurrentRevSharePerformanceOverviewUsingGET1', 'yentaId', yentaId)
            const localVarPath = `/api/v1/revshares/performance/{yentaId}/revenue-share/current`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets the rev share performance for a particular month for an agent
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyPerformanceOverviewUsingGET1: async (yentaId: string, month?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getMonthlyPerformanceOverviewUsingGET1', 'yentaId', yentaId)
            const localVarPath = `/api/v1/revshares/performance/{yentaId}/revenue-share`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RevShareControllerApi - functional programming interface
 * @export
 */
export const RevShareControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RevShareControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gets the current rev share performance overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentRevSharePerformanceOverviewUsingGET1(yentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevShareOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentRevSharePerformanceOverviewUsingGET1(yentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets the rev share performance for a particular month for an agent
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyPerformanceOverviewUsingGET1(yentaId: string, month?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevShareMonthlyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyPerformanceOverviewUsingGET1(yentaId, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RevShareControllerApi - factory interface
 * @export
 */
export const RevShareControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RevShareControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary gets the current rev share performance overview for an agent
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRevSharePerformanceOverviewUsingGET1(yentaId: string, options?: any): AxiosPromise<RevShareOverviewResponse> {
            return localVarFp.getCurrentRevSharePerformanceOverviewUsingGET1(yentaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets the rev share performance for a particular month for an agent
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyPerformanceOverviewUsingGET1(yentaId: string, month?: string, options?: any): AxiosPromise<RevShareMonthlyResponse> {
            return localVarFp.getMonthlyPerformanceOverviewUsingGET1(yentaId, month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RevShareControllerApi - object-oriented interface
 * @export
 * @class RevShareControllerApi
 * @extends {BaseAPI}
 */
export class RevShareControllerApi extends BaseAPI {
    /**
     * 
     * @summary gets the current rev share performance overview for an agent
     * @param {string} yentaId yentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevShareControllerApi
     */
    public getCurrentRevSharePerformanceOverviewUsingGET1(yentaId: string, options?: any) {
        return RevShareControllerApiFp(this.configuration).getCurrentRevSharePerformanceOverviewUsingGET1(yentaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets the rev share performance for a particular month for an agent
     * @param {string} yentaId yentaId
     * @param {string} [month] month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevShareControllerApi
     */
    public getMonthlyPerformanceOverviewUsingGET1(yentaId: string, month?: string, options?: any) {
        return RevShareControllerApiFp(this.configuration).getMonthlyPerformanceOverviewUsingGET1(yentaId, month, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchControllerApi - axios parameter creator
 * @export
 */
export const SearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingPOST: async (request: SearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('searchUsingPOST', 'request', request)
            const localVarPath = `/api/v1/search/universal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchControllerApi - functional programming interface
 * @export
 */
export const SearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsingPOST(request: SearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsingPOST(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchControllerApi - factory interface
 * @export
 */
export const SearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {SearchRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingPOST(request: SearchRequest, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.searchUsingPOST(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchControllerApi - object-oriented interface
 * @export
 * @class SearchControllerApi
 * @extends {BaseAPI}
 */
export class SearchControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search API
     * @param {SearchRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchControllerApi
     */
    public searchUsingPOST(request: SearchRequest, options?: any) {
        return SearchControllerApiFp(this.configuration).searchUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TipaltiIpnControllerApi - axios parameter creator
 * @export
 */
export const TipaltiIpnControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tipalti bill_updated IPN handler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billUpdatedUsingPOST: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tipalti_ipn/bill_updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tipalti error endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPOST: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tipalti_ipn/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TipaltiIpnControllerApi - functional programming interface
 * @export
 */
export const TipaltiIpnControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TipaltiIpnControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tipalti bill_updated IPN handler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billUpdatedUsingPOST(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billUpdatedUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tipalti error endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorUsingPOST(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TipaltiIpnControllerApi - factory interface
 * @export
 */
export const TipaltiIpnControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TipaltiIpnControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Tipalti bill_updated IPN handler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billUpdatedUsingPOST(options?: any): AxiosPromise<Response> {
            return localVarFp.billUpdatedUsingPOST(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tipalti error endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorUsingPOST(options?: any): AxiosPromise<Response> {
            return localVarFp.errorUsingPOST(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TipaltiIpnControllerApi - object-oriented interface
 * @export
 * @class TipaltiIpnControllerApi
 * @extends {BaseAPI}
 */
export class TipaltiIpnControllerApi extends BaseAPI {
    /**
     * 
     * @summary Tipalti bill_updated IPN handler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TipaltiIpnControllerApi
     */
    public billUpdatedUsingPOST(options?: any) {
        return TipaltiIpnControllerApiFp(this.configuration).billUpdatedUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tipalti error endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TipaltiIpnControllerApi
     */
    public errorUsingPOST(options?: any) {
        return TipaltiIpnControllerApiFp(this.configuration).errorUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionControllerApi - axios parameter creator
 * @export
 */
export const TransactionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add participant
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpcityParticipantUsingPOST: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('addOpcityParticipantUsingPOST', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/participant/opcity`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add participant
         * @param {string} transactionId transactionId
         * @param {CreateParticipantRequest} createParticipantRequest createParticipantRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantUsingPOST: async (transactionId: string, createParticipantRequest: CreateParticipantRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('addParticipantUsingPOST', 'transactionId', transactionId)
            // verify required parameter 'createParticipantRequest' is not null or undefined
            assertParamExists('addParticipantUsingPOST', 'createParticipantRequest', createParticipantRequest)
            const localVarPath = `/api/v1/transactions/{transactionId}/participant`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createParticipantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an attached fee on the transaction
         * @param {string} transactionId transactionId
         * @param {AttachedFeeRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttachedFeeUsingPOST: async (transactionId: string, request: AttachedFeeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('createAttachedFeeUsingPOST', 'transactionId', transactionId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createAttachedFeeUsingPOST', 'request', request)
            const localVarPath = `/api/v1/transactions/{transactionId}/attached-fee`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Escrow
         * @param {string} transactionId transactionId
         * @param {EscrowRequest} escrowRequest escrowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEscrowUsingPOST: async (transactionId: string, escrowRequest: EscrowRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('createEscrowUsingPOST', 'transactionId', transactionId)
            // verify required parameter 'escrowRequest' is not null or undefined
            assertParamExists('createEscrowUsingPOST', 'escrowRequest', escrowRequest)
            const localVarPath = `/api/v1/transactions/{transactionId}/escrow`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(escrowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Test Transaction
         * @param {CreateTestTransactionRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestTransactionUsingPOST: async (request: CreateTestTransactionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createTestTransactionUsingPOST', 'request', request)
            const localVarPath = `/api/v1/transactions/create/test-transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an attached fee on the transaction
         * @param {string} attachedFeeId attachedFeeId
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachedFeeUsingDELETE: async (attachedFeeId: string, transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachedFeeId' is not null or undefined
            assertParamExists('deleteAttachedFeeUsingDELETE', 'attachedFeeId', attachedFeeId)
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('deleteAttachedFeeUsingDELETE', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/attached-fee/{attachedFeeId}`
                .replace(`{${"attachedFeeId"}}`, encodeURIComponent(String(attachedFeeId)))
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disburse Escrow
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disburseEscrowOldUsingPOST: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('disburseEscrowOldUsingPOST', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/disburse-escrow`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disburse Escrow
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disburseEscrowUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('disburseEscrowUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/disburse-escrow`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all transactions by month (createdAt), no month = current month
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactionsByMonthUsingGET: async (yentaId: string, month?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getAllTransactionsByMonthUsingGET', 'yentaId', yentaId)
            const localVarPath = `/api/v1/transactions/participant/{yentaId}`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all transactions for a given user
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactionsUsingGET: async (yentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getAllTransactionsUsingGET', 'yentaId', yentaId)
            const localVarPath = `/api/v1/transactions/participant/{yentaId}/all`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get current transactions
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTransactionsUsingGET: async (yentaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaId' is not null or undefined
            assertParamExists('getCurrentTransactionsUsingGET', 'yentaId', yentaId)
            const localVarPath = `/api/v1/transactions/participant/{yentaId}/current`
                .replace(`{${"yentaId"}}`, encodeURIComponent(String(yentaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Transaction by code
         * @param {string} transactionCode transactionCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByCodeUsingGET: async (transactionCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionCode' is not null or undefined
            assertParamExists('getTransactionByCodeUsingGET', 'transactionCode', transactionCode)
            const localVarPath = `/api/v1/transactions/code/{transactionCode}`
                .replace(`{${"transactionCode"}}`, encodeURIComponent(String(transactionCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Transaction by id
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionUsingGET: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getTransactionUsingGET', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get participant\'s W9 url
         * @param {string} participantId participantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getW9UrlUsingGET: async (participantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getW9UrlUsingGET', 'participantId', participantId)
            const localVarPath = `/api/v1/transactions/participant/{participantId}/w9`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process transaction
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTransactionUsingGET: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('processTransactionUsingGET', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/process`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Received Escrow
         * @param {string} escrowId escrowId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receivedEscrowUsingPUT: async (escrowId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'escrowId' is not null or undefined
            assertParamExists('receivedEscrowUsingPUT', 'escrowId', escrowId)
            const localVarPath = `/api/v1/transactions/escrows/{escrowId}/received`
                .replace(`{${"escrowId"}}`, encodeURIComponent(String(escrowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove participant
         * @param {string} participantId participantId
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeParticipantUsingDELETE: async (participantId: string, transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('removeParticipantUsingDELETE', 'participantId', participantId)
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('removeParticipantUsingDELETE', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/participant/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)))
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Drop local changes and reset transaction from skyslope
         * @param {string} transactionId transactionId
         * @param {string} [reset] reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetSkyslopeUsingPUT: async (transactionId: string, reset?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('resetSkyslopeUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/skyslope-reset`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (reset !== undefined) {
                localVarQueryParameter['reset'] = reset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set payouts
         * @param {string} transactionId transactionId
         * @param {SetPaymentsRequest} setpayoutsrequest setpayoutsrequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPayoutsUsingPUT: async (transactionId: string, setpayoutsrequest: SetPaymentsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('setPayoutsUsingPUT', 'transactionId', transactionId)
            // verify required parameter 'setpayoutsrequest' is not null or undefined
            assertParamExists('setPayoutsUsingPUT', 'setpayoutsrequest', setpayoutsrequest)
            const localVarPath = `/api/v1/transactions/{transactionId}/payouts`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setpayoutsrequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition transaction to \'Approved for Closing\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToApprovedForClosingUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transitionToApprovedForClosingUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/approved-for-closing`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition transaction to \'Closed\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToClosedUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transitionToClosedUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/closed`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition transaction to \'Commission Document Approved\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToCommissionDocumentApprovedUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transitionToCommissionDocumentApprovedUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/cd-approved`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition transaction to \'Commission Validated\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToCommissionValidatedUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transitionToCommissionValidatedUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/commission-validated`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition transaction to \'New\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToNewUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transitionToNewUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/new`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition transaction to \'Payment Accepted\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToPaymentAcceptedUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transitionToPaymentAcceptedUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/payment-accepted`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition transaction to \'Ready for Commission Document Generation\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToReadyForCommissionDocumentGenerationUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transitionToReadyForCommissionDocumentGenerationUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/ready-for-cd-generation`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition transaction to \'Terminated\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToTerminatedUsingPUT: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transitionToTerminatedUsingPUT', 'transactionId', transactionId)
            const localVarPath = `/api/v1/transactions/{transactionId}/terminated`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a transaction\'s attached fee
         * @param {string} attachedFeeId attachedFeeId
         * @param {string} transactionId transactionId
         * @param {AttachedFeeRequest} feeRequest feeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachedFeeUsingPUT: async (attachedFeeId: string, transactionId: string, feeRequest: AttachedFeeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachedFeeId' is not null or undefined
            assertParamExists('updateAttachedFeeUsingPUT', 'attachedFeeId', attachedFeeId)
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('updateAttachedFeeUsingPUT', 'transactionId', transactionId)
            // verify required parameter 'feeRequest' is not null or undefined
            assertParamExists('updateAttachedFeeUsingPUT', 'feeRequest', feeRequest)
            const localVarPath = `/api/v1/transactions/{transactionId}/attached-fee/{attachedFeeId}`
                .replace(`{${"attachedFeeId"}}`, encodeURIComponent(String(attachedFeeId)))
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Escrow
         * @param {string} escrowId escrowId
         * @param {EscrowRequest} escrowRequest escrowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEscrowUsingPUT: async (escrowId: string, escrowRequest: EscrowRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'escrowId' is not null or undefined
            assertParamExists('updateEscrowUsingPUT', 'escrowId', escrowId)
            // verify required parameter 'escrowRequest' is not null or undefined
            assertParamExists('updateEscrowUsingPUT', 'escrowRequest', escrowRequest)
            const localVarPath = `/api/v1/transactions/escrows/{escrowId}`
                .replace(`{${"escrowId"}}`, encodeURIComponent(String(escrowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(escrowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update transaction participant
         * @param {string} participantId participantId
         * @param {string} transactionId transactionId
         * @param {UpdateParticipantRequest} updateParticipantRequest updateParticipantRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParticipantUsingPUT: async (participantId: string, transactionId: string, updateParticipantRequest: UpdateParticipantRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('updateParticipantUsingPUT', 'participantId', participantId)
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('updateParticipantUsingPUT', 'transactionId', transactionId)
            // verify required parameter 'updateParticipantRequest' is not null or undefined
            assertParamExists('updateParticipantUsingPUT', 'updateParticipantRequest', updateParticipantRequest)
            const localVarPath = `/api/v1/transactions/{transactionId}/participant/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)))
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateParticipantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update onboarded a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionOnboardedRequest} updateTransactionOnboardedRequest updateTransactionOnboardedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionOnboardedUsingPUT: async (transactionId: string, updateTransactionOnboardedRequest: UpdateTransactionOnboardedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('updateTransactionOnboardedUsingPUT', 'transactionId', transactionId)
            // verify required parameter 'updateTransactionOnboardedRequest' is not null or undefined
            assertParamExists('updateTransactionOnboardedUsingPUT', 'updateTransactionOnboardedRequest', updateTransactionOnboardedRequest)
            const localVarPath = `/api/v1/transactions/{transactionId}/onboarded`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransactionOnboardedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionRequest} updateTransactionRequest updateTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionUsingPUT: async (transactionId: string, updateTransactionRequest: UpdateTransactionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('updateTransactionUsingPUT', 'transactionId', transactionId)
            // verify required parameter 'updateTransactionRequest' is not null or undefined
            assertParamExists('updateTransactionUsingPUT', 'updateTransactionRequest', updateTransactionRequest)
            const localVarPath = `/api/v1/transactions/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update verified a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionVerifiedRequest} updateTransactionVerifiedRequest updateTransactionVerifiedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionVerifiedUsingPUT: async (transactionId: string, updateTransactionVerifiedRequest: UpdateTransactionVerifiedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('updateTransactionVerifiedUsingPUT', 'transactionId', transactionId)
            // verify required parameter 'updateTransactionVerifiedRequest' is not null or undefined
            assertParamExists('updateTransactionVerifiedUsingPUT', 'updateTransactionVerifiedRequest', updateTransactionVerifiedRequest)
            const localVarPath = `/api/v1/transactions/{transactionId}/updateVerified`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransactionVerifiedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload W9 of external transaction participant
         * @param {string} participantId participantId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadW9UsingPUT: async (participantId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('uploadW9UsingPUT', 'participantId', participantId)
            const localVarPath = `/api/v1/transactions/participant/{participantId}/w9`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionControllerApi - functional programming interface
 * @export
 */
export const TransactionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add participant
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOpcityParticipantUsingPOST(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOpcityParticipantUsingPOST(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary add participant
         * @param {string} transactionId transactionId
         * @param {CreateParticipantRequest} createParticipantRequest createParticipantRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addParticipantUsingPOST(transactionId: string, createParticipantRequest: CreateParticipantRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addParticipantUsingPOST(transactionId, createParticipantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an attached fee on the transaction
         * @param {string} transactionId transactionId
         * @param {AttachedFeeRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAttachedFeeUsingPOST(transactionId: string, request: AttachedFeeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAttachedFeeUsingPOST(transactionId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Escrow
         * @param {string} transactionId transactionId
         * @param {EscrowRequest} escrowRequest escrowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEscrowUsingPOST(transactionId: string, escrowRequest: EscrowRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EscrowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEscrowUsingPOST(transactionId, escrowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Test Transaction
         * @param {CreateTestTransactionRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestTransactionUsingPOST(request: CreateTestTransactionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestTransactionUsingPOST(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an attached fee on the transaction
         * @param {string} attachedFeeId attachedFeeId
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAttachedFeeUsingDELETE(attachedFeeId: string, transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAttachedFeeUsingDELETE(attachedFeeId, transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disburse Escrow
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disburseEscrowOldUsingPOST(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disburseEscrowOldUsingPOST(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disburse Escrow
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disburseEscrowUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disburseEscrowUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all transactions by month (createdAt), no month = current month
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTransactionsByMonthUsingGET(yentaId: string, month?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsMonthlyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTransactionsByMonthUsingGET(yentaId, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all transactions for a given user
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTransactionsUsingGET(yentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllTransactionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTransactionsUsingGET(yentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get current transactions
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTransactionsUsingGET(yentaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTransactionsUsingGET(yentaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Transaction by code
         * @param {string} transactionCode transactionCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionByCodeUsingGET(transactionCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionByCodeUsingGET(transactionCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Transaction by id
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionUsingGET(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionUsingGET(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get participant\'s W9 url
         * @param {string} participantId participantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getW9UrlUsingGET(participantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getW9UrlUsingGET(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Process transaction
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processTransactionUsingGET(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processTransactionUsingGET(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Received Escrow
         * @param {string} escrowId escrowId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receivedEscrowUsingPUT(escrowId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EscrowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receivedEscrowUsingPUT(escrowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove participant
         * @param {string} participantId participantId
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeParticipantUsingDELETE(participantId: string, transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeParticipantUsingDELETE(participantId, transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Drop local changes and reset transaction from skyslope
         * @param {string} transactionId transactionId
         * @param {string} [reset] reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetSkyslopeUsingPUT(transactionId: string, reset?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetSkyslopeUsingPUT(transactionId, reset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set payouts
         * @param {string} transactionId transactionId
         * @param {SetPaymentsRequest} setpayoutsrequest setpayoutsrequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPayoutsUsingPUT(transactionId: string, setpayoutsrequest: SetPaymentsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPayoutsUsingPUT(transactionId, setpayoutsrequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition transaction to \'Approved for Closing\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionToApprovedForClosingUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionToApprovedForClosingUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition transaction to \'Closed\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionToClosedUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionToClosedUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition transaction to \'Commission Document Approved\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionToCommissionDocumentApprovedUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionToCommissionDocumentApprovedUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition transaction to \'Commission Validated\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionToCommissionValidatedUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionToCommissionValidatedUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition transaction to \'New\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionToNewUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionToNewUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition transaction to \'Payment Accepted\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionToPaymentAcceptedUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionToPaymentAcceptedUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition transaction to \'Ready for Commission Document Generation\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionToReadyForCommissionDocumentGenerationUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionToReadyForCommissionDocumentGenerationUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition transaction to \'Terminated\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionToTerminatedUsingPUT(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionToTerminatedUsingPUT(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a transaction\'s attached fee
         * @param {string} attachedFeeId attachedFeeId
         * @param {string} transactionId transactionId
         * @param {AttachedFeeRequest} feeRequest feeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAttachedFeeUsingPUT(attachedFeeId: string, transactionId: string, feeRequest: AttachedFeeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAttachedFeeUsingPUT(attachedFeeId, transactionId, feeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Escrow
         * @param {string} escrowId escrowId
         * @param {EscrowRequest} escrowRequest escrowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEscrowUsingPUT(escrowId: string, escrowRequest: EscrowRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EscrowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEscrowUsingPUT(escrowId, escrowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update transaction participant
         * @param {string} participantId participantId
         * @param {string} transactionId transactionId
         * @param {UpdateParticipantRequest} updateParticipantRequest updateParticipantRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParticipantUsingPUT(participantId: string, transactionId: string, updateParticipantRequest: UpdateParticipantRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParticipantUsingPUT(participantId, transactionId, updateParticipantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update onboarded a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionOnboardedRequest} updateTransactionOnboardedRequest updateTransactionOnboardedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionOnboardedUsingPUT(transactionId: string, updateTransactionOnboardedRequest: UpdateTransactionOnboardedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionOnboardedUsingPUT(transactionId, updateTransactionOnboardedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionRequest} updateTransactionRequest updateTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionUsingPUT(transactionId: string, updateTransactionRequest: UpdateTransactionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionUsingPUT(transactionId, updateTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update verified a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionVerifiedRequest} updateTransactionVerifiedRequest updateTransactionVerifiedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionVerifiedUsingPUT(transactionId: string, updateTransactionVerifiedRequest: UpdateTransactionVerifiedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionVerifiedUsingPUT(transactionId, updateTransactionVerifiedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload W9 of external transaction participant
         * @param {string} participantId participantId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadW9UsingPUT(participantId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadW9UsingPUT(participantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionControllerApi - factory interface
 * @export
 */
export const TransactionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary add participant
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpcityParticipantUsingPOST(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.addOpcityParticipantUsingPOST(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add participant
         * @param {string} transactionId transactionId
         * @param {CreateParticipantRequest} createParticipantRequest createParticipantRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantUsingPOST(transactionId: string, createParticipantRequest: CreateParticipantRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.addParticipantUsingPOST(transactionId, createParticipantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an attached fee on the transaction
         * @param {string} transactionId transactionId
         * @param {AttachedFeeRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttachedFeeUsingPOST(transactionId: string, request: AttachedFeeRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.createAttachedFeeUsingPOST(transactionId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Escrow
         * @param {string} transactionId transactionId
         * @param {EscrowRequest} escrowRequest escrowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEscrowUsingPOST(transactionId: string, escrowRequest: EscrowRequest, options?: any): AxiosPromise<EscrowResponse> {
            return localVarFp.createEscrowUsingPOST(transactionId, escrowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Test Transaction
         * @param {CreateTestTransactionRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestTransactionUsingPOST(request: CreateTestTransactionRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.createTestTransactionUsingPOST(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an attached fee on the transaction
         * @param {string} attachedFeeId attachedFeeId
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachedFeeUsingDELETE(attachedFeeId: string, transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.deleteAttachedFeeUsingDELETE(attachedFeeId, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disburse Escrow
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disburseEscrowOldUsingPOST(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.disburseEscrowOldUsingPOST(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disburse Escrow
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disburseEscrowUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.disburseEscrowUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all transactions by month (createdAt), no month = current month
         * @param {string} yentaId yentaId
         * @param {string} [month] month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactionsByMonthUsingGET(yentaId: string, month?: string, options?: any): AxiosPromise<TransactionsMonthlyResponse> {
            return localVarFp.getAllTransactionsByMonthUsingGET(yentaId, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all transactions for a given user
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactionsUsingGET(yentaId: string, options?: any): AxiosPromise<AllTransactionsResponse> {
            return localVarFp.getAllTransactionsUsingGET(yentaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get current transactions
         * @param {string} yentaId yentaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTransactionsUsingGET(yentaId: string, options?: any): AxiosPromise<TransactionsOverviewResponse> {
            return localVarFp.getCurrentTransactionsUsingGET(yentaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Transaction by code
         * @param {string} transactionCode transactionCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByCodeUsingGET(transactionCode: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.getTransactionByCodeUsingGET(transactionCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Transaction by id
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionUsingGET(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.getTransactionUsingGET(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get participant\'s W9 url
         * @param {string} participantId participantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getW9UrlUsingGET(participantId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getW9UrlUsingGET(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process transaction
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processTransactionUsingGET(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.processTransactionUsingGET(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Received Escrow
         * @param {string} escrowId escrowId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receivedEscrowUsingPUT(escrowId: string, options?: any): AxiosPromise<EscrowResponse> {
            return localVarFp.receivedEscrowUsingPUT(escrowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove participant
         * @param {string} participantId participantId
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeParticipantUsingDELETE(participantId: string, transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.removeParticipantUsingDELETE(participantId, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Drop local changes and reset transaction from skyslope
         * @param {string} transactionId transactionId
         * @param {string} [reset] reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetSkyslopeUsingPUT(transactionId: string, reset?: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.resetSkyslopeUsingPUT(transactionId, reset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set payouts
         * @param {string} transactionId transactionId
         * @param {SetPaymentsRequest} setpayoutsrequest setpayoutsrequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPayoutsUsingPUT(transactionId: string, setpayoutsrequest: SetPaymentsRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.setPayoutsUsingPUT(transactionId, setpayoutsrequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition transaction to \'Approved for Closing\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToApprovedForClosingUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.transitionToApprovedForClosingUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition transaction to \'Closed\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToClosedUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.transitionToClosedUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition transaction to \'Commission Document Approved\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToCommissionDocumentApprovedUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.transitionToCommissionDocumentApprovedUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition transaction to \'Commission Validated\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToCommissionValidatedUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.transitionToCommissionValidatedUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition transaction to \'New\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToNewUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.transitionToNewUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition transaction to \'Payment Accepted\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToPaymentAcceptedUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.transitionToPaymentAcceptedUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition transaction to \'Ready for Commission Document Generation\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToReadyForCommissionDocumentGenerationUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.transitionToReadyForCommissionDocumentGenerationUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition transaction to \'Terminated\'
         * @param {string} transactionId transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionToTerminatedUsingPUT(transactionId: string, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.transitionToTerminatedUsingPUT(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a transaction\'s attached fee
         * @param {string} attachedFeeId attachedFeeId
         * @param {string} transactionId transactionId
         * @param {AttachedFeeRequest} feeRequest feeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachedFeeUsingPUT(attachedFeeId: string, transactionId: string, feeRequest: AttachedFeeRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.updateAttachedFeeUsingPUT(attachedFeeId, transactionId, feeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Escrow
         * @param {string} escrowId escrowId
         * @param {EscrowRequest} escrowRequest escrowRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEscrowUsingPUT(escrowId: string, escrowRequest: EscrowRequest, options?: any): AxiosPromise<EscrowResponse> {
            return localVarFp.updateEscrowUsingPUT(escrowId, escrowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update transaction participant
         * @param {string} participantId participantId
         * @param {string} transactionId transactionId
         * @param {UpdateParticipantRequest} updateParticipantRequest updateParticipantRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParticipantUsingPUT(participantId: string, transactionId: string, updateParticipantRequest: UpdateParticipantRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.updateParticipantUsingPUT(participantId, transactionId, updateParticipantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update onboarded a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionOnboardedRequest} updateTransactionOnboardedRequest updateTransactionOnboardedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionOnboardedUsingPUT(transactionId: string, updateTransactionOnboardedRequest: UpdateTransactionOnboardedRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.updateTransactionOnboardedUsingPUT(transactionId, updateTransactionOnboardedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionRequest} updateTransactionRequest updateTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionUsingPUT(transactionId: string, updateTransactionRequest: UpdateTransactionRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.updateTransactionUsingPUT(transactionId, updateTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update verified a Transaction by id
         * @param {string} transactionId transactionId
         * @param {UpdateTransactionVerifiedRequest} updateTransactionVerifiedRequest updateTransactionVerifiedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionVerifiedUsingPUT(transactionId: string, updateTransactionVerifiedRequest: UpdateTransactionVerifiedRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.updateTransactionVerifiedUsingPUT(transactionId, updateTransactionVerifiedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload W9 of external transaction participant
         * @param {string} participantId participantId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadW9UsingPUT(participantId: string, file?: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadW9UsingPUT(participantId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionControllerApi - object-oriented interface
 * @export
 * @class TransactionControllerApi
 * @extends {BaseAPI}
 */
export class TransactionControllerApi extends BaseAPI {
    /**
     * 
     * @summary add participant
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public addOpcityParticipantUsingPOST(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).addOpcityParticipantUsingPOST(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add participant
     * @param {string} transactionId transactionId
     * @param {CreateParticipantRequest} createParticipantRequest createParticipantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public addParticipantUsingPOST(transactionId: string, createParticipantRequest: CreateParticipantRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).addParticipantUsingPOST(transactionId, createParticipantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an attached fee on the transaction
     * @param {string} transactionId transactionId
     * @param {AttachedFeeRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public createAttachedFeeUsingPOST(transactionId: string, request: AttachedFeeRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).createAttachedFeeUsingPOST(transactionId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Escrow
     * @param {string} transactionId transactionId
     * @param {EscrowRequest} escrowRequest escrowRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public createEscrowUsingPOST(transactionId: string, escrowRequest: EscrowRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).createEscrowUsingPOST(transactionId, escrowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Test Transaction
     * @param {CreateTestTransactionRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public createTestTransactionUsingPOST(request: CreateTestTransactionRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).createTestTransactionUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an attached fee on the transaction
     * @param {string} attachedFeeId attachedFeeId
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public deleteAttachedFeeUsingDELETE(attachedFeeId: string, transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).deleteAttachedFeeUsingDELETE(attachedFeeId, transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disburse Escrow
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public disburseEscrowOldUsingPOST(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).disburseEscrowOldUsingPOST(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disburse Escrow
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public disburseEscrowUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).disburseEscrowUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all transactions by month (createdAt), no month = current month
     * @param {string} yentaId yentaId
     * @param {string} [month] month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getAllTransactionsByMonthUsingGET(yentaId: string, month?: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).getAllTransactionsByMonthUsingGET(yentaId, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all transactions for a given user
     * @param {string} yentaId yentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getAllTransactionsUsingGET(yentaId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).getAllTransactionsUsingGET(yentaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get current transactions
     * @param {string} yentaId yentaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getCurrentTransactionsUsingGET(yentaId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).getCurrentTransactionsUsingGET(yentaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Transaction by code
     * @param {string} transactionCode transactionCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getTransactionByCodeUsingGET(transactionCode: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).getTransactionByCodeUsingGET(transactionCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Transaction by id
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getTransactionUsingGET(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).getTransactionUsingGET(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get participant\'s W9 url
     * @param {string} participantId participantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getW9UrlUsingGET(participantId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).getW9UrlUsingGET(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process transaction
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public processTransactionUsingGET(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).processTransactionUsingGET(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Received Escrow
     * @param {string} escrowId escrowId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public receivedEscrowUsingPUT(escrowId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).receivedEscrowUsingPUT(escrowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove participant
     * @param {string} participantId participantId
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public removeParticipantUsingDELETE(participantId: string, transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).removeParticipantUsingDELETE(participantId, transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Drop local changes and reset transaction from skyslope
     * @param {string} transactionId transactionId
     * @param {string} [reset] reset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public resetSkyslopeUsingPUT(transactionId: string, reset?: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).resetSkyslopeUsingPUT(transactionId, reset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set payouts
     * @param {string} transactionId transactionId
     * @param {SetPaymentsRequest} setpayoutsrequest setpayoutsrequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public setPayoutsUsingPUT(transactionId: string, setpayoutsrequest: SetPaymentsRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).setPayoutsUsingPUT(transactionId, setpayoutsrequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition transaction to \'Approved for Closing\'
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public transitionToApprovedForClosingUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).transitionToApprovedForClosingUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition transaction to \'Closed\'
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public transitionToClosedUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).transitionToClosedUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition transaction to \'Commission Document Approved\'
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public transitionToCommissionDocumentApprovedUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).transitionToCommissionDocumentApprovedUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition transaction to \'Commission Validated\'
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public transitionToCommissionValidatedUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).transitionToCommissionValidatedUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition transaction to \'New\'
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public transitionToNewUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).transitionToNewUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition transaction to \'Payment Accepted\'
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public transitionToPaymentAcceptedUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).transitionToPaymentAcceptedUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition transaction to \'Ready for Commission Document Generation\'
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public transitionToReadyForCommissionDocumentGenerationUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).transitionToReadyForCommissionDocumentGenerationUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition transaction to \'Terminated\'
     * @param {string} transactionId transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public transitionToTerminatedUsingPUT(transactionId: string, options?: any) {
        return TransactionControllerApiFp(this.configuration).transitionToTerminatedUsingPUT(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a transaction\'s attached fee
     * @param {string} attachedFeeId attachedFeeId
     * @param {string} transactionId transactionId
     * @param {AttachedFeeRequest} feeRequest feeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public updateAttachedFeeUsingPUT(attachedFeeId: string, transactionId: string, feeRequest: AttachedFeeRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).updateAttachedFeeUsingPUT(attachedFeeId, transactionId, feeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Escrow
     * @param {string} escrowId escrowId
     * @param {EscrowRequest} escrowRequest escrowRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public updateEscrowUsingPUT(escrowId: string, escrowRequest: EscrowRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).updateEscrowUsingPUT(escrowId, escrowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update transaction participant
     * @param {string} participantId participantId
     * @param {string} transactionId transactionId
     * @param {UpdateParticipantRequest} updateParticipantRequest updateParticipantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public updateParticipantUsingPUT(participantId: string, transactionId: string, updateParticipantRequest: UpdateParticipantRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).updateParticipantUsingPUT(participantId, transactionId, updateParticipantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update onboarded a Transaction by id
     * @param {string} transactionId transactionId
     * @param {UpdateTransactionOnboardedRequest} updateTransactionOnboardedRequest updateTransactionOnboardedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public updateTransactionOnboardedUsingPUT(transactionId: string, updateTransactionOnboardedRequest: UpdateTransactionOnboardedRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).updateTransactionOnboardedUsingPUT(transactionId, updateTransactionOnboardedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Transaction by id
     * @param {string} transactionId transactionId
     * @param {UpdateTransactionRequest} updateTransactionRequest updateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public updateTransactionUsingPUT(transactionId: string, updateTransactionRequest: UpdateTransactionRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).updateTransactionUsingPUT(transactionId, updateTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update verified a Transaction by id
     * @param {string} transactionId transactionId
     * @param {UpdateTransactionVerifiedRequest} updateTransactionVerifiedRequest updateTransactionVerifiedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public updateTransactionVerifiedUsingPUT(transactionId: string, updateTransactionVerifiedRequest: UpdateTransactionVerifiedRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).updateTransactionVerifiedUsingPUT(transactionId, updateTransactionVerifiedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload W9 of external transaction participant
     * @param {string} participantId participantId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public uploadW9UsingPUT(participantId: string, file?: any, options?: any) {
        return TransactionControllerApiFp(this.configuration).uploadW9UsingPUT(participantId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


