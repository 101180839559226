import React from 'react';
import { LocationOn, Phone, Print } from '@material-ui/icons';
import StateIcon from '../StateIcon';
import IconText from '../IconText';
import {
  formatAddressObject,
  formatPhoneNumber,
} from '../../utils/StringUtils';
import { OfficeResponse } from '../../openapi/yenta';
import CopyToClipboard from '../CopyToClipboard';

interface OfficeDetailHeaderSectionProps {
  detail: OfficeResponse;
}

const OfficeDetailHeaderSection: React.FC<OfficeDetailHeaderSectionProps> = ({
  detail,
}) => {
  return (
    <header className='flex flex-col lg:flex-row justify-between items-center px-4 py-10 bg-gray-800 text-gray-100'>
      <div className='flex flex-col md:flex-row items-center mb-4 lg:mb-0'>
        <StateIcon
          state={detail.administrativeArea?.stateOrProvince!}
          width={120}
          variant='circle'
        />
        <div className='md:ml-4'>
          <h1 className='text-xl text-center md:text-left font-primary-medium my-3 md:mt-0'>
            {detail.name}
          </h1>
          <div className='flex flex-col md:flex-row space-x-2 items-center justify-center'>
            <p className='text-center md:text-left font-primary-medium'>
              ID: {detail.id}
            </p>
            <CopyToClipboard value={detail.id!} />
          </div>
          <div className='flex flex-col items-center justify-center md:items-start'>
            <IconText
              icon={<LocationOn fontSize='small' />}
              text={formatAddressObject(detail.address!)}
            />
          </div>
          <div className='flex flex-col md:flex-row items-center justify-center md:justify-start md:space-x-4 lg:mt-1'>
            <a href={`tel:${detail.phoneNumber}`}>
              <IconText
                icon={<Phone fontSize='small' />}
                text={formatPhoneNumber(detail.phoneNumber)!}
              />
            </a>
            {detail.faxNumber && (
              <IconText
                icon={<Print fontSize='small' />}
                text={`FAX: ${formatPhoneNumber(detail.faxNumber)}`}
              />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default OfficeDetailHeaderSection;
