import React from 'react';
import { FilterProps } from 'react-table';
import { FilterColumnsToProcess, ISelectOption } from '../../../../types';
import ControlledSelectInput from '../../../ControlledSelectInput';
import { PROCESS_FILTER_COLUMN } from '../../../../utils/TableUtils';

export interface SelectColumnFilterProps<D extends object>
  extends FilterProps<D> {
  options: Array<ISelectOption>;
}

const SelectColumnFilter = <D extends object>({
  column,
  control,
  errors,
  options,
}: SelectColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}[${FilterColumnsToProcess.ENUM}][${column.id}]`;

  return (
    <ControlledSelectInput
      control={control}
      errors={errors}
      options={[
        {
          label: `Select ${column.render('Header')}`,
          value: '',
          disabled: true,
        },
        ...options,
      ]}
      name={prefixFieldName}
      rules={{
        required: `${column.render('Header')} is required`,
      }}
    />
  );
};

export default SelectColumnFilter;
