import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import { useEffect, useState } from 'react';
import { GeometryObject, Topology } from 'topojson-specification';
import { FeatureCollection } from 'geojson';
import { legend } from '../utils/d3ColorLegend';
import us from '../assets/json/us-10m.v2.json';
import {
  getStateTransactionMap,
  approxNumber,
  Transaction,
} from '../utils/TransactionCommissionMapUtils';

interface TransactionCommissionMapProps {
  width: number;
  height: number;
  startDate: string;
  endDate: string;
  transactions: Transaction[] | null;
}

const TransactionCommissionMap: React.FC<TransactionCommissionMapProps> = ({
  width,
  height,
  startDate,
  endDate,
  transactions,
}) => {
  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!svgRef || !tooltipRef || !transactions) return;

    const data = getStateTransactionMap(transactions, startDate, endDate);
    let max = 0;

    for (let element of data.values()) {
      max = Math.max(max, element);
    }

    const color = (x: number) => {
      if (x <= 1) x *= max;
      if (x === 0) return d3.interpolateGreys(0.2);

      return d3.interpolateGreys(Math.max(0.4, x / max));
    };

    const path = d3.geoPath();
    const svg = d3.select(svgRef).html('');
    const tooltip = d3
      .select(tooltipRef)
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('background-color', '#010101')
      .style('color', '#fff')
      .style('padding', '8px 16px')
      .style('border-radius', '5px')
      .on('mouseover', () => tooltip.style('opacity', 1));

    legend({
      color: d3.scaleSequential([0, max], color),
      width: width / 3,
      title: 'Agent Transactions',
      svg,
      translateX: (2 * width) / 3 - 100,
      translateY: height - 100,
      tickFormat: (tick: number) => approxNumber(tick, '$'),
    });

    // prettier-ignore
    svg.append("g")
			.selectAll("path")
			.data(((topojson.feature((us as unknown) as Topology, us.objects.states as GeometryObject)) as FeatureCollection).features)
			.join("path")
				.attr("fill", "#fff")
				.attr("d", path)
				.on("mousemove", (e, d: any) => {
					e.target.style.opacity = 0.7;
					tooltip.transition().duration(10).style("opacity", 0.9).style("display", "block");
					tooltip.html(`State: ${d.properties.name} <br /> Commission: ${ data.get(d.properties.name.toLowerCase()) ? approxNumber(data.get(d.properties.name.toLowerCase()), "$") : 0 }`)
						.style("left", (e.pageX + 10) + "px")
						.style("top", (e.pageY - 40 - 16) + "px");
				})
				.on("mouseout", e => {
					e.target.style.opacity = 1;
					tooltip.transition().duration(100).style("opacity", 0).style("display", "none");
				})
				.transition()
				.duration(200)
				.attr("fill", (d: any) => {
					const value = data.get(d.properties.name.toLowerCase());

					if (value) return color(value);
					else return color(0);
				});

    // prettier-ignore
    svg
      .append('path')
      .datum(
        topojson.mesh(
          (us as unknown) as Topology,
          us.objects.states as GeometryObject,
          (a, b) => a !== b,
        ),
      )
        .attr('fill', 'none')
        .attr('stroke', 'white')
        .attr('stroke-linejoin', 'round')
        .attr('d', path);
  }, [svgRef, tooltipRef, startDate, endDate, transactions, width, height]);

  return (
    <div className='application-map'>
      <svg width={width} height={height} ref={setSvgRef} />
      <div className='tooltip' ref={setTooltipRef} />
    </div>
  );
};

export default TransactionCommissionMap;
