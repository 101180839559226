import React from 'react';
import LocationOn from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import IconText from '../IconText';
import ProfileImage from '../ProfileImage';
import { MlsResponse } from '../../openapi/yenta';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';

interface MlsAndBoardHeaderSectionProps {
  mls: MlsResponse;
}

const MLSHeaderSection: React.FC<MlsAndBoardHeaderSectionProps> = ({ mls }) => {
  return (
    <section className='flex flex-col lg:flex-row justify-between items-center px-4 py-10 bg-gray-800 text-gray-100'>
      <div className='flex flex-col md:flex-row items-center md:pb-4 lg:pb-0'>
        <ProfileImage imageUrl={mls.logo!} width={120} variant='rounded' />
        <div className='md:ml-4 py-3 lg:py-0'>
          <h1 className='text-xl font-primary-medium mb-2'>{mls.name}</h1>
          <IconText
            icon={<LocationOn fontSize='small' />}
            text={
              mls.administrativeAreas
                ?.map((area) => capitalizeEnum(area.stateOrProvince!))
                .join(', ') ?? 'N/A'
            }
          />
          <div className='flex flex-col md:flex-row mt-1'>
            {mls.phoneNumber ? (
              <a href={`tel:${mls.phoneNumber}`}>
                <IconText
                  icon={<PhoneIcon fontSize='small' />}
                  text={formatPhoneNumber(mls.phoneNumber) ?? 'N/A'}
                />
              </a>
            ) : (
              <IconText icon={<PhoneIcon fontSize='small' />} text='N/A' />
            )}
            <span className='mr-3' />
            {mls.email ? (
              <a href={`mailto:${mls.email}`}>
                <IconText
                  icon={<MailIcon fontSize='small' />}
                  text={mls.email!}
                />
              </a>
            ) : (
              <IconText icon={<MailIcon fontSize='small' />} text='N/A' />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MLSHeaderSection;
