import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import DetailPageLoader from '../components/DetailPageLoader';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import CommissionSplitCard from '../components/transactions/CommissionSplitCard';
import TransactionParticipantsSection from '../components/transactions/TransactionParticipantsSection';
import TransactionDetailActionBar from '../components/transactions/TransactionDetailActionBar';
import TransactionHeader from '../components/transactions/TransactionHeader';
import TransactionInformation from '../components/transactions/TransactionInformation';
import { fetchTransactionDetails } from '../slices/TransactionSlice';
import { RootState } from '../types';
import EscrowSection from '../components/transactions/EscrowSection';
import { isSaleTransaction } from '../utils/TransactionHelper';
import {
  CreateNoteRequestEntityTypeEnum,
  UserPrincipalRolesEnum,
} from '../openapi/yenta';
import NotesContainer from '../components/NotesContainer';
import TransactionDetailErrors from '../components/transactions/TransactionDetailErrors';
import { fetchOfficeDetailById } from '../slices/OfficeSlice';
import CdPayerInformationCard from '../components/transactions/CdPayerInformationCard';
import AttachedFees from '../components/transactions/AttachedFee';
import TransactionCDARoute from './TransactionCDARoute';
import TransactionInvoiceRoute from './TransactionInvoiceRoute';
import TransactionOutgoingPaymentRoute from './TransactionOutgoingPaymentRoute';
import TransactionPaymentInvoicesRoute from './TransactionPaymentInvoicesRoute';

interface Match {
  id: string;
}

interface TransactionDetailRouteProps extends RouteComponentProps<Match> {}

const TransactionDetailRoute: React.FC<TransactionDetailRouteProps> = ({
  match,
  location,
}) => {
  const dispatch = useDispatch();
  const transactionId = match.params.id;
  const {
    transaction: {
      fetchTransactionDetailErrorCode,
      loadingTransactionDetail,
      transactionDetail,
    },
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);

  const officeDetails = officeDetailById[transactionDetail?.office?.id!];

  useEffect(() => {
    dispatch(fetchTransactionDetails(transactionId));
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (transactionDetail?.office?.id) {
      dispatch(fetchOfficeDetailById(transactionDetail.office.id));
    }
  }, [dispatch, transactionDetail]);

  const path = [
    { title: 'Home', url: '/' },
    { title: 'Transactions', url: '/transactions' },
  ];

  if (transactionDetail) {
    path.push({
      title: `${transactionDetail.address?.oneLine}`,
      url: `/transactions/${transactionDetail?.id}`,
    });

    if (location.pathname.includes('cda')) {
      path.push({
        title: 'CDAs',
        url: `/transactions/${transactionDetail?.id}/cda`,
      });
    } else if (location.pathname.includes('outgoing')) {
      path.push({
        title: 'Outgoing Payments',
        url: `/transactions/${transactionDetail?.id}/outgoing-payments`,
      });
    } else if (location.pathname.includes('payment')) {
      path.push({
        title: 'Payment Invoices',
        url: `/transactions/${transactionDetail?.id}/payment-invoices`,
      });
    } else if (location.pathname.includes('invoice')) {
      path.push({
        title: 'Invoices',
        url: `/transactions/${transactionDetail?.id}/invoices`,
      });
    }
  }

  return (
    <ResourceContainer
      loading={loadingTransactionDetail}
      LoaderComponent={<DetailPageLoader />}
      isEmpty={!transactionDetail}
      errorCode={fetchTransactionDetailErrorCode}
      resourceName='transaction'
    >
      <PageLayout path={path}>
        {!!transactionDetail && (
          <>
            <TransactionDetailActionBar
              transaction={transactionDetail}
              roles={UserPrincipalRolesEnum.Admin}
            />

            <TransactionDetailErrors errors={transactionDetail.errors!} />

            <TransactionHeader transaction={transactionDetail} />
            <Route path={match.path} exact>
              <div className='grid md:grid-cols-3 grid-cols-1 gap-4 grid-flow-row p-4'>
                <TransactionInformation transaction={transactionDetail} />
                <CommissionSplitCard
                  commissionSplit={transactionDetail.commissionSplits!}
                  transactionId={transactionDetail.id!}
                  transactionType={transactionDetail.transactionType!}
                  paymentParticipants={transactionDetail.paymentParticipants!}
                />
                <CdPayerInformationCard transaction={transactionDetail} />
              </div>
              <TransactionParticipantsSection
                transactionDetail={transactionDetail!}
              />
              <AttachedFees
                attachedFees={transactionDetail.attachedFees!}
                transaction={transactionDetail}
                participants={transactionDetail.participants!}
              />
              {isSaleTransaction(transactionDetail.transactionType!) &&
                transactionDetail.officeAcceptsEscrow && (
                  <EscrowSection
                    transaction={transactionDetail}
                    officeDetails={officeDetails!}
                  />
                )}
              <div className='col-span-12 p-4'>
                <div className='py-2 flex flex-row space-x-2'>
                  <span className='text-xl text-gray-800 font-primary-medium'>
                    Notes
                  </span>
                </div>
                <NotesContainer
                  entityId={transactionDetail.id!}
                  entityType={CreateNoteRequestEntityTypeEnum.Transaction}
                />
              </div>
            </Route>
            <Route
              path={`${match.path}/cda`}
              component={TransactionCDARoute}
              exact
            />
            <Route
              path={`${match.path}/invoices`}
              component={TransactionInvoiceRoute}
              exact
            />
            <Route
              path={`${match.path}/payment-invoices`}
              component={TransactionPaymentInvoicesRoute}
              exact
            />
            <Route
              path={`${match.path}/outgoing-payments`}
              component={TransactionOutgoingPaymentRoute}
              exact
            />
          </>
        )}
      </PageLayout>
    </ResourceContainer>
  );
};

export default TransactionDetailRoute;
