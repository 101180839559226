import React from 'react';
import RealLogoImg from '../assets/img/real_logo_blue.png';

interface RealHeaderProps {}

const RealHeader: React.FC<RealHeaderProps> = () => {
  return (
    <div className='flex justify-center border-b py-4'>
      <img src={RealLogoImg} className='lg:h-6 h-4' alt='Real Logo' />
    </div>
  );
};

export default RealHeader;
