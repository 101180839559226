import {
  ParticipantValue,
  PaymentParticipantValue,
  TransactionErrorSeverityEnum,
  TransactionOwnerValue,
  TransactionResponse,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponseTransactionTypeEnum,
} from '../openapi/arrakis';
import { EnumMap } from '../types';

export const isSaleTransaction = (
  transactionType: TransactionResponseTransactionTypeEnum,
): boolean => {
  const map: EnumMap<TransactionResponseTransactionTypeEnum, boolean> = {
    COMMERCIAL_LEASE: false,
    LEASE: false,
    OTHER: false,
    PLOT: true,
    SALE: true,
    COMPENSATING: true,
    REFERRAL: true,
  };

  return map[transactionType];
};

export const isLeaseTransaction = (
  transactionType: TransactionResponseTransactionTypeEnum,
): boolean => {
  return !isSaleTransaction(transactionType);
};

type Transition = TransactionLifecycleStateValueStateEnum;

export const isTransitionActionable = (lifecycleState: Transition): boolean => {
  const map: EnumMap<Transition, boolean> = {
    APPROVED_FOR_CLOSING: true,
    COMMISSION_DOCUMENT_APPROVED: true,
    COMMISSION_DOCUMENT_GENERATED: false,
    COMMISSION_DOCUMENT_SENT: false,
    CLOSED: true,
    CALCULATE_LEDGER: false,
    COMMISSION_VALIDATED: true,
    NEEDS_COMMISSION_VALIDATION: false,
    NEW: true,
    PAYMENT_ACCEPTED: true,
    PAYMENT_SCHEDULED: false,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: true,
    SETTLED: false,
    WAITING_ON_PAYMENT: false,
    TERMINATED: true,
  };

  return map[lifecycleState];
};

const transactionTransitionToButtonText: EnumMap<Transition, string> = {
  APPROVED_FOR_CLOSING: 'Approve For Closing',
  COMMISSION_DOCUMENT_APPROVED: 'Approve Commission Document',
  COMMISSION_DOCUMENT_GENERATED: 'Generate Commission Document',
  CALCULATE_LEDGER: 'Calculate Ledger',
  COMMISSION_DOCUMENT_SENT: 'Send Commission Document',
  CLOSED: 'Close',
  COMMISSION_VALIDATED: 'Validate Commission',
  NEEDS_COMMISSION_VALIDATION: 'Need Commission Validation',
  NEW: 'New',
  PAYMENT_ACCEPTED: 'Accept Payment',
  PAYMENT_SCHEDULED: 'Schedule Payment',
  READY_FOR_COMMISSION_DOCUMENT_GENERATION:
    'Ready For Commission Document Generation',
  SETTLED: 'Settle',
  WAITING_ON_PAYMENT: 'Waiting On Payment',
  TERMINATED: 'Terminate',
};

export const readableTransitionName = (transition: Transition): string => {
  return transactionTransitionToButtonText[transition];
};

export const isTransactionReadOnly = (
  transaction: TransactionResponse,
): boolean => {
  const map: EnumMap<TransactionLifecycleStateValueStateEnum, boolean> = {
    APPROVED_FOR_CLOSING: false,
    COMMISSION_DOCUMENT_APPROVED: false,
    CALCULATE_LEDGER: false,
    COMMISSION_DOCUMENT_GENERATED: false,
    COMMISSION_DOCUMENT_SENT: false,
    CLOSED: true,
    COMMISSION_VALIDATED: false,
    NEEDS_COMMISSION_VALIDATION: false,
    NEW: true,
    PAYMENT_ACCEPTED: true,
    PAYMENT_SCHEDULED: true,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: false,
    SETTLED: true,
    WAITING_ON_PAYMENT: true,
    TERMINATED: true,
  };

  const isTransactionCritical = transaction.errors?.some(
    (e) => e.severity === TransactionErrorSeverityEnum.Critical,
  );

  if (isTransactionCritical) {
    return true;
  }

  return map[transaction.lifecycleState!.state!];
};

export const isParticipantTransactionOwner = (
  transactionOwner: TransactionOwnerValue,
  participant: PaymentParticipantValue | ParticipantValue,
): boolean => {
  return transactionOwner.id === participant.yentaId;
};
