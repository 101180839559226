import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Edit, EventOutlined, PeopleOutlined } from '@material-ui/icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import pluralize from 'pluralize';
import { TeamResponse } from '../openapi/yenta';
import Avatar from './Avatar';
import IconText from './IconText';
import TeamTypeCell from './table/Cells/TeamTypeCell';
import IconButton from './IconButton';
import EditTeamForm from './team/EditTeamForm';

interface TeamDetailHeaderSectionProps extends RouteComponentProps {
  teamDetailOverview: TeamResponse;
}

const TeamDetailHeaderSection: React.FC<TeamDetailHeaderSectionProps> = ({
  teamDetailOverview,
}) => {
  const [openEditTeamForm, setOpenEditTeamForm] = useState<boolean>(false);
  return (
    <header className='bg-gray-800 lg:p-5 p-3'>
      <div className='grid grid-cols-1 lg:grid-cols-3'>
        <div className='lg:col-span-2 lg:flex lg:items-center text-white'>
          <div className='text-center lg:mt-0 mt-3'>
            <Avatar name={teamDetailOverview.name!} size='xl' />
          </div>
          <div className='lg:ml-5 lg:mt-0 mt-5 text-white'>
            <div className='flex flex-col lg:flex-row text-center lg:text-left'>
              <h4 className='text-2xl'>{teamDetailOverview.name}</h4>
              <div className='lg:ml-5'>
                <TeamTypeCell type={teamDetailOverview.type!} />
              </div>
            </div>
            <div className='mt-4'>
              <IconText
                icon={<PeopleOutlined fontSize='small' />}
                text={`${teamDetailOverview.agents!.length} ${pluralize(
                  'Member',
                  teamDetailOverview.agents!.length,
                )}`}
              />
              <IconText
                icon={<EventOutlined fontSize='small' />}
                text={`Created: ${DateTime.fromMillis(
                  teamDetailOverview.createdAt!,
                ).toFormat('LL/dd/yy')}`}
              />
            </div>
            <div className='mt-4'>
              <IconButton
                variant='outline'
                label='Edit Team'
                leftIcon={<Edit fontSize='small' />}
                onClick={() => setOpenEditTeamForm(true)}
              />
            </div>
          </div>
        </div>
        {/* <Route path={`${match.path}`} exact>
          <div className='mt-5'>
            <TransactionsOverview
              title='Recent Transactions'
              actionText='See all'
              onActionClick={() => {
                history.push(`/teams/${teamDetailOverview.id}/transactions`);
              }}
              transactions={teamDetailOverview.recentTransactions}
            />
          </div>
        </Route> */}
      </div>
      <EditTeamForm
        isOpen={openEditTeamForm}
        onClose={() => setOpenEditTeamForm(false)}
        team={teamDetailOverview}
      />
    </header>
  );
};

export default withRouter(TeamDetailHeaderSection);
