import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TransactionCommissionDocumentRoute from './TransactionCommissionDocumentRoute';

interface Match {
  id: string;
}

interface TransactionCDARouteProps extends RouteComponentProps<Match> {}

const TransactionCDARoute: React.FC<TransactionCDARouteProps> = ({ match }) => {
  const { id } = match.params;

  return (
    <TransactionCommissionDocumentRoute
      transactionId={id}
      headerName='CDAs'
      getUrl={(documentId) => `/cda/${documentId}`}
    />
  );
};

export default TransactionCDARoute;
