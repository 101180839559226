import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import DetailPageLoader from '../components/DetailPageLoader';
import PageLayout from '../components/PageLayout';
import ResourceContainer from '../components/ResourceContainer';
import { fetchApplicationById } from '../slices/ApplicationSlice';
import { RootState } from '../types';
import ApplicationFormContent from './ApplicationFormContent';

interface Match {
  id: string;
}

interface ApplicationFormDetailRouteProps extends RouteComponentProps<Match> {}

const ApplicationFormDetailRoute: React.FC<ApplicationFormDetailRouteProps> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const applicationId = match.params.id;
  const {
    applicationDetail,
    loadingDetail,
    fetchDetailErrorCode,
  } = useSelector((state: RootState) => state.applicationDetail);

  useEffect(() => {
    dispatch(fetchApplicationById(applicationId));
  }, [dispatch, applicationId]);

  return (
    <ResourceContainer
      loading={loadingDetail}
      LoaderComponent={<DetailPageLoader />}
      errorCode={fetchDetailErrorCode}
      isEmpty={!applicationDetail}
      resourceName='ApplicationForm'
    >
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Applications', url: '/applications' },
          {
            title: `${applicationDetail?.firstName} ${applicationDetail?.lastName}`,
            url: `/applications/${applicationDetail?.id}`,
          },
        ]}
      >
        {applicationDetail && (
          <ApplicationFormContent
            application={applicationDetail}
            id={applicationDetail.id!}
            name={`${applicationDetail?.firstName} ${applicationDetail?.lastName}`}
          />
        )}
      </PageLayout>
    </ResourceContainer>
  );
};

export default ApplicationFormDetailRoute;
