import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ErrorCode, TeamState } from '../types';
import ErrorService from '../services/ErrorService';
import {
  AddDomesticTeamMemberRequest,
  AddNormalTeamMemberRequest,
  AddPlatinumTeamMemberRequest,
  CreateDomesticTeamRequest,
  CreateNormalTeamRequest,
  CreatePlatinumTeamRequest,
  TeamControllerApi,
  TeamResponse,
  UpdateTeamRequest,
} from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: TeamState = {
  loading: false,
  teamDetailOverview: null,
  fetchErrorCode: null,
};

const TeamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    errorFetching(state, action: PayloadAction<ErrorCode>) {
      state.fetchErrorCode = action.payload;
    },
    saveTeamDetailOverview(state, action: PayloadAction<TeamResponse>) {
      state.teamDetailOverview = action.payload;
      state.fetchErrorCode = null;
    },
  },
});

export const {
  changeLoading,
  saveTeamDetailOverview,
  errorFetching,
} = TeamSlice.actions;

export const getTeamDetailOverview = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).getTeamByIdUsingGET(id);
    dispatch(saveTeamDetailOverview(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch team detail', e, { team: { id } });
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoading(false));
  }
};

export const updateTeam = (
  id: string,
  req: UpdateTeamRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).updateTeamUsingPUT(id, req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast('Team updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update team', e, {
      team: { req },
    });
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem updating your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export const createDomesticTeam = (
  req: CreateDomesticTeamRequest,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).createDomesticTeamUsingPOST(req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`${data.name} team was created successfully.`));
    return data.id;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create domestic team', e, {
      team: { req },
    });
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem creating your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
  return undefined;
};

export const addDomesticTeamMember = (
  id: string,
  req: AddDomesticTeamMemberRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).addMemberToDomesticTeamUsingPOST(id, req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`Team Member added successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add domestic team member', e, {
      team: { req },
    });

    dispatch(
      showErrorToastForErrorCode(
        'We had a problem adding the member to your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export const createNormalTeam = (
  req: CreateNormalTeamRequest,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).createNormalTeamUsingPOST(req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`${data.name} team was created successfully.`));
    return data.id;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create normal team', e, {
      team: { req },
    });
    dispatch(
      showErrorToast(
        'We had a problem creating your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
  return undefined;
};

export const addNormalTeamMember = (
  id: string,
  req: AddNormalTeamMemberRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).addMemberToNormalTeamUsingPOST(id, req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`Team Member added successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add normal team member', e, {
      team: { req },
    });

    dispatch(
      showErrorToastForErrorCode(
        'We had a problem adding the member to your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export const createPlatinumTeam = (
  req: CreatePlatinumTeamRequest,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).createPlatinumTeamUsingPOST(req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`${data.name} team was created successfully.`));
    return data.id;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create platinum team', e, {
      team: { req },
    });
    dispatch(
      showErrorToast(
        'We had a problem creating your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
  return undefined;
};

export const addPlatinumTeamMember = (
  id: string,
  req: AddPlatinumTeamMemberRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).addMemberToPlatinumTeamUsingPOST(id, req);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`Team Member added successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add platinum team member', e, {
      team: { req },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem adding the member to your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export const deleteTeamMember = (
  agentId: string,
  id: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new TeamControllerApi(
      getYentaConfiguration(),
    ).removeMemberUsingDELETE(agentId, id);
    dispatch(saveTeamDetailOverview(data));
    dispatch(showSuccessToast(`Team Member deleted successfully.`));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to delete team member', e, {
      team: { agentId, id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We had a problem deleting the member from your team.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoading(false));
  }
};

export default TeamSlice.reducer;
