import Bugsnag from '@bugsnag/js';
import Logger from '../utils/Logger';
import { isAxiosError } from '../utils/TypeUtils';
import { ErrorCode } from '../types';

export default class ErrorService {
  static getErrorCode(error: any): ErrorCode {
    if (isAxiosError(error)) {
      const status = error.response?.status;

      if (status === 401) {
        return ErrorCode.UNAUTHORIZED;
      }

      if (status === 403) {
        return ErrorCode.FORBIDDEN;
      }

      if (status === 404) {
        return ErrorCode.NOT_FOUND;
      }
    }

    return ErrorCode.SERVER_ERROR;
  }

  static notify(
    message: string,
    error: Error,
    metadata: { [key: string]: object } = {},
  ): void {
    Logger.error(message, error);
    Bugsnag.notify(new Error(`${message} - ${error.message}`), (event) => {
      Object.keys(metadata).forEach((key) => {
        event.addMetadata(key, metadata[key]);
      });

      if (isAxiosError(error)) {
        event.addMetadata('axios', error.response ?? {});
      }
    });
  }
}
