import { times } from 'lodash';
import { fakeDate, fakeTeamName } from '../../../testUtils/FakerUtils';
import { CalendarItem, GetCalendarEventsResponse } from '../../../types';

const DefaultGetBoardsCalendarEventsResponse: GetCalendarEventsResponse = {
  events: times<CalendarItem>(4, () => ({
    startDate: fakeDate().toISODate(),
    title: fakeTeamName(),
    actionText: fakeTeamName(),
  })),
};
export default DefaultGetBoardsCalendarEventsResponse;
