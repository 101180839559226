import React, { useState } from 'react';
import { Column, Row } from 'react-table';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import PageLayoutWithSearch from '../components/PageLayoutWithSearch';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import {
  OfficeResponse,
  SearchRequestTargetObjectEnum,
} from '../openapi/yenta';
import { capitalizeEnum, formatAddressObject } from '../utils/StringUtils';
import { resourceTableFetchData } from '../utils/TableUtils';
import OfficeTransactionTypeCell from '../components/table/Cells/OfficeTransactionTypeCell';
import OfficeResponseTransactionTypeSelectColumnFilter from '../components/table/Filters/OfficeResponseTransactionTypeSelectColumnFilter';
import NullableTextCell from '../components/table/Cells/NullableTextCell';
import IconButton from '../components/IconButton';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';
import PhoneNumberCell from '../components/table/Cells/PhoneNumberCell';

interface OfficeIndexTableContainerProps {}

export const columns: Array<Column<OfficeResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Transaction Type',
    accessor: 'transactionType',
    Cell: ({ value }) => <OfficeTransactionTypeCell transactionType={value!} />,
    Filter: OfficeResponseTransactionTypeSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Address',
    accessor: 'address',
    Cell: ({ value }) => (
      <NullableTextCell text={value ? formatAddressObject(value) : null} />
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'State/Province',
    accessor: 'address',
    id: 'stateOrProvince',
    Cell: ({ value }) => (
      <NullableTextCell
        text={
          value?.stateOrProvince ? capitalizeEnum(value.stateOrProvince) : null
        }
      />
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    Cell: ({ value }) => <PhoneNumberCell phoneNumber={value} />,
    cardColSize: 6,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
  },
];

export const columnsWithAction: Array<Column<OfficeResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<OfficeResponse> }) => (
      <Link to={`/offices/${row.original.id}`}>
        <IconButton leftIcon={<VisibilityIcon titleAccess='View' />} />
      </Link>
    ),
  },
  ...columns,
];

export const columnsToFetch = columns.map((col) => col.accessor as string);
const columnsForSearch = ['name'];

const OfficeIndexRoute: React.FC<OfficeIndexTableContainerProps> = () => {
  const [search, setSearch] = useState<string>();

  return (
    <PageLayoutWithSearch
      path={[
        { title: 'Home', url: '/' },
        { title: 'Offices', url: '/offices' },
      ]}
      search={search}
      onSearchChange={setSearch}
    >
      <div className='px-4 lg:py-5'>
        <ResourceIndexContainer<OfficeResponse>
          header='Offices'
          columns={columnsWithAction}
          search={search}
          resourceName='office'
          fetchData={(req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              columnsForSearch,
              SearchRequestTargetObjectEnum.Office,
            )
          }
        />
      </div>
    </PageLayoutWithSearch>
  );
};

export default OfficeIndexRoute;
