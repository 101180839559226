import { OfficeResponseTransactionTypeEnum } from '../../../openapi/yenta';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

const OfficeResponseTransactionTypeSelectColumnFilter = <D extends object>(
  props: SelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(OfficeResponseTransactionTypeEnum)(props);
};

export default OfficeResponseTransactionTypeSelectColumnFilter;
