import React from 'react';
import classNames from 'classnames';
import { TabsVariant } from '../types';

export interface ITab {
  name: string;
  badgeContent?: string | number;
  TabComponent: React.FC | React.ReactNode;
}

export interface TabsProps {
  tabs: ITab[];
  variant?: TabsVariant;
  selected?: string;
  RightComponent?: React.FC | React.ReactNode;
  showBorderBottom?: boolean;
  onChange(name: string): void;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  variant = 'default',
  RightComponent,
  showBorderBottom = false,
  selected = tabs[0].name,
  onChange,
}) => {
  const tabSelected = tabs.find((tab) => tab.name === selected);

  return (
    <div>
      <div className='flex flex-row items-center justify-start flex-nowrap pt-1 bg-white'>
        <div className='flex-grow'>
          <div
            className={classNames('flex flex-row', {
              'border-b-2 border-coolGray-200': showBorderBottom,
            })}
          >
            {tabs.map(({ name, badgeContent }) => (
              <button
                key={name}
                className={classNames(
                  'flex flex-row px-3 pb-1 font-thin font-primary-light focus:outline-none items-center justify-center',
                  {
                    'border-primary border-b-2 font-primary-regular':
                      name === selected,
                    'flex-grow': variant === 'spread',
                    '-mb-2px': showBorderBottom,
                  },
                )}
                onClick={() => onChange(name)}
              >
                {name}
                {badgeContent && (
                  <span className='bg-black text-white text-xs rounded-full px-1.5 py-0.5 ml-1.5'>
                    {badgeContent}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
        {RightComponent}
      </div>
      {!!tabSelected && tabSelected.TabComponent}
    </div>
  );
};

export default Tabs;
