import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InvoiceForm from './InvoiceForm';

interface Match {
  id: string;
}

interface InvoiceFormPdfRouteProps extends RouteComponentProps<Match> {}

const InvoiceFormPdfRoute: React.FC<InvoiceFormPdfRouteProps> = ({ match }) => {
  const { id } = match.params;

  return <InvoiceForm commissionDocumentId={id} isPdf />;
};

export default InvoiceFormPdfRoute;
