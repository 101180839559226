import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SendIcon from '@material-ui/icons/Send';
import { FiUsers } from 'react-icons/fi';
import { ReferralResponse } from '../../openapi/arrakis';
import AgentReferralRow from '../AgentReferralRow';
import { ErrorCode, Mapping, SearchAgentResponse } from '../../types';
import SaveReferralAgentFormSidebarModal from '../../forms/SaveReferralAgentFormSidebarModal';
import { AgentResponse } from '../../openapi/yenta';
import ResourceContainer from '../ResourceContainer';
import SectionHeader from '../SectionHeader';
import Card from '../Card';
import ConfirmDeleteRecordModal from '../ConfirmDeleteRecordModal';
import { deleteReferralDispatch } from '../../slices/AgentSlice';
import DisburseReferral from './DisburseReferral';

interface AgentReferralsProps {
  agent: AgentResponse;
  referrals: ReferralResponse[];
  users: Mapping<SearchAgentResponse>;
}

const AgentReferrals: React.FC<AgentReferralsProps> = ({
  agent,
  referrals,
  users,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [addReferral, setAddReferral] = useState<boolean>(false);
  const [
    disburseReferral,
    setDisburseReferral,
  ] = useState<ReferralResponse | null>(null);
  const [
    deletingReferral,
    setDeletingReferral,
  ] = useState<ReferralResponse | null>(null);
  const [editReferral, setEditReferral] = useState<ReferralResponse | null>(
    null,
  );

  return (
    <div>
      <SectionHeader
        icon={<FiUsers />}
        title='Referrals'
        actionText='+ Add'
        onActionClick={() => setAddReferral(true)}
      />
      <div className='mt-2'>
        <Card
          bottomActionText={
            !referrals || referrals?.length === 0 ? '' : 'See all'
          }
          onBottomActionClick={() =>
            history.push(`/people/${agent.id}/referrals`)
          }
        >
          <ResourceContainer
            loading={!referrals}
            isEmpty={referrals?.length === 0}
            errorCode={!referrals ? ErrorCode.SERVER_ERROR : null}
            resourceName='referral'
            emptyIcon={<SendIcon fontSize='large' />}
          >
            {referrals?.slice(0, 2).map((referral) => (
              <div key={referral.id} className='border-b'>
                <AgentReferralRow
                  referral={referral}
                  user={users[referral.receivingAgentUserYentaId!]}
                  onEdit={() => setEditReferral(referral)}
                  onDisburse={() => setDisburseReferral(referral)}
                  onDelete={() => setDeletingReferral(referral)}
                />
              </div>
            ))}
          </ResourceContainer>
        </Card>
      </div>
      <SaveReferralAgentFormSidebarModal
        isOpen={addReferral}
        onClose={() => setAddReferral(false)}
        agent={agent}
      />
      <SaveReferralAgentFormSidebarModal
        isOpen={!!editReferral}
        onClose={() => setEditReferral(null)}
        referral={editReferral!}
      />
      <ConfirmDeleteRecordModal
        onDelete={async () => {
          await dispatch(deleteReferralDispatch(deletingReferral?.id!));
          setDeletingReferral(null);
        }}
        title='Are you sure?'
        subtitle='This referral will be permanently deleted.'
        isOpen={!!deletingReferral}
        onClose={() => setDeletingReferral(null)}
      />
      <DisburseReferral
        disburseReferral={disburseReferral!}
        onClose={() => setDisburseReferral(null)}
      />
    </div>
  );
};

export default AgentReferrals;
