import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Check } from '@material-ui/icons';
import ApplicationStepCompleteMessage from '../ApplicationStepCompleteMessage';
import ApplicationLayout from '../ApplicationLayout';
import ApplicationVerticalStepProgress from './ApplicationVerticalStepProgress';

export interface ApplicationICASigningSuccessProps
  extends RouteComponentProps {}

const ApplicationICASigningSuccess: React.FC<ApplicationICASigningSuccessProps> = ({
  history,
}) => {
  return (
    <ApplicationLayout>
      <div className='w-full mx-auto px-4 my-10 lg:max-w-6xl gap-10 grid grid-cols-1 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          <ApplicationStepCompleteMessage
            icon={<Check fontSize='large' />}
            header='Thank you for signing the independent contractor agreement'
            subtitle='Up next, we will ask you to pay the application fee.'
            nextStepName='Pay Application Fee'
            onNextStepClicked={() =>
              history.push('/onboarding/application-fee')
            }
          />
        </div>
        <div className='lg:col-span-2'>
          <ApplicationVerticalStepProgress />
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationICASigningSuccess;
