import React from 'react';
import { useDispatch } from 'react-redux';
import { UserPreviewResponse } from '../../openapi/yenta';
import Button from '../Button';
import { deleteTeamMember } from '../../slices/TeamSlice';
import ConfirmationModal from '../ConfirmationModal';

interface DeleteTeamMemberModalProps {
  teamId: string;
  memberToDelete: UserPreviewResponse | undefined | null;
  onClose(): void;
}

const DeleteTeamMemberModal: React.FC<DeleteTeamMemberModalProps> = ({
  teamId,
  memberToDelete,
  onClose,
}) => {
  const dispatch = useDispatch();

  return (
    <ConfirmationModal
      isOpen={!!memberToDelete}
      variant='error'
      title='Remove Member'
      subtitle={`Are you sure you want to remove ${memberToDelete?.fullName!}?`}
      onClose={onClose}
    >
      <div className='mt-3 space-x-3'>
        <Button
          label='Remove'
          type='danger'
          onClick={() => {
            dispatch(deleteTeamMember(memberToDelete?.id!, teamId));
            onClose();
          }}
        />
        <Button label='Cancel' type='secondary' onClick={onClose} />
      </div>
    </ConfirmationModal>
  );
};

export default DeleteTeamMemberModal;
