import React from 'react';
import { isSmScreen } from '../utils/BrowserUtils';
import { BreadCrumbsProps } from './BreadCrumbsWithUrls';
import SearchBar from './SearchBar';
import PageLayout from './PageLayout';

interface PageLayoutWithSearchProps extends BreadCrumbsProps {
  search?: string;
  onSearchChange(search: string | undefined): void;
}

const PageLayoutWithSearch: React.FC<PageLayoutWithSearchProps> = ({
  path,
  search,
  onSearchChange,
  children,
}) => {
  return (
    <PageLayout
      path={path}
      RightComponent={
        !isSmScreen() ? (
          <div className='w-96 ml-auto hidden md:block'>
            <SearchBar
              onChange={(text) => onSearchChange(text || undefined)}
              value={search || ''}
            />
          </div>
        ) : undefined
      }
    >
      {children}
    </PageLayout>
  );
};

export default PageLayoutWithSearch;
