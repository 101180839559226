import React from 'react';
import { keys } from 'lodash';
import { StateAbbreviation, StateName } from '../../../types';
import SelectColumnFilter, {
  SelectColumnFilterProps,
} from './Base/SelectColumnFilter';

export interface CountrySelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const StateSelectColumnFilter = <D extends object>(
  props: CountrySelectColumnFilterProps<D>,
) => {
  return (
    <SelectColumnFilter
      {...props}
      options={keys(StateAbbreviation).map((state) => ({
        value: state,
        label: StateName[state as StateAbbreviation],
      }))}
    />
  );
};

export default StateSelectColumnFilter;
