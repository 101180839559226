import { times } from 'lodash';
import { ActiveInactiveType, IPaginateRes, People } from '../../../types';
import {
  fakeCity,
  fakeDate,
  fakeEmail,
  fakeNumber,
  fakePersonFirstName,
  fakePersonLastName,
  fakePhoneNumber,
  fakeProfileImage,
  fakeState,
  randomEnum,
} from '../../../testUtils/FakerUtils';

export const DefaultPeoplePaginationResponse: IPaginateRes<People> = {
  data: times<People>(20, (id) => ({
    id: id + 1,
    firstName: fakePersonFirstName(),
    lastName: fakePersonLastName(),
    email: fakeEmail(),
    phone: fakePhoneNumber(),
    imageUrl: fakeProfileImage(),
    capResetOnDate: fakeDate().toISODate(),
    status: randomEnum<ActiveInactiveType>(ActiveInactiveType),
    licenseState: fakeState(),
    licenseCity: fakeCity(),
    createdAt: fakeDate().toMillis(),
    updatedAt: fakeDate().toMillis(),
  })),
  total: fakeNumber({ min: 50, max: 100 }),
};
