import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { last } from 'lodash';
import { InfoOutlined } from '@material-ui/icons';
import { FormType } from '../../utils/FormUtils';
import { RootState, YesNoType } from '../../types';
import {
  ApplicationControllerApi,
  ApplicationResponseNextStatusEnum,
  ICASignRequest,
} from '../../openapi/yenta';
import FormBasedOnType from '../FormBasedOnType';
import Alert from '../Alert';
import ApplicationLayout from '../ApplicationLayout';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import { saveApplication } from '../../slices/AuthSlice';
import ErrorService from '../../services/ErrorService';

interface ApplicationICASigningProps extends RouteComponentProps {}

const ApplicationICASigning: React.FC<ApplicationICASigningProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const application = last(userDetail?.applications);

  useRedirectAwayOnboardingEffect(
    ApplicationResponseNextStatusEnum.SignIca,
    history,
  );

  const handleSubmit = async (values: any) => {
    try {
      const api = new ApplicationControllerApi(getYentaConfiguration());
      const icaSignRequest: Required<ICASignRequest> = {
        applicationId: application?.id!,
        customAnniversaryDate: values.customAnniversaryDate,
        emergencyContactEmailAddress: values.emergencyContactEmailAddress,
        emergencyContactName: values.emergencyContactName,
        emergencyContactPhoneNumber: values.emergencyContactPhoneNumber,
        emergencyContactRelationship: values.emergencyContactRelationship,
        initials: values.initials,
        entityName: values.entityName,
        name: values.name,
        referringAgentName: values.referringAgentName,
        signature: values.signature,
        optInStockPlan: values.optInStockPlan === YesNoType.YES,
      };

      const { data } = await api.signICAUsingPOST(icaSignRequest);
      dispatch(saveApplication(data));
      history.push('/onboarding/application-ica-signing/success');
    } catch (e) {
      ErrorService.notify('Unable to sign ICA', e);
      setErrorMessage(e.response.data.message);
    }
  };

  return (
    <ApplicationLayout>
      <div className='flex justify-center items-center my-10 lg:mx-4 mx-0'>
        <div className='container'>
          <div className='text-center mx-2 lg:mx-0'>
            <p className='text-xl font-primary-medium'>
              INDEPENDENT CONTRACTOR AGREEMENT
            </p>
            <p className='text-gray-800'>
              Please Review and Sign the document below.
            </p>
          </div>
          {errorMessage && (
            <div className='my-5'>
              <Alert
                text={errorMessage}
                variant='error'
                icon={<InfoOutlined />}
              />
            </div>
          )}
          <div className='bg-gray-100 flex justify-center lg:p-16 lg:p-5 p-0 mt-10 mb-20 lg:mb-40'>
            <FormBasedOnType
              formType={
                application?.commercialAgent
                  ? FormType.COMMERCIAL_ICA
                  : FormType.ICA_STANDARD_V1
              }
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationICASigning;
