import { capitalize } from 'lodash';
import { AddressResponse, UserResponse } from '../openapi/yenta';

export const WEBSITE_REGEX = /^((https?):\/\/)((www.)?)[a-z0-9]+([-/_.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^1[0-9]{10}/;

export const ALPHA_NUMERIC_REGEX_NO_SPACE = /^[a-zA-Z0-9\S]+$/;

export const ALPHA_NUMERIC_REGEX = /[a-zA-Z0-9]/;

export const NUMBER_REGEX = /^[0-9]+$/;

// 100.12 and 100 both are acceptable. 100.1 is not.
export const MONEY_AMOUNT_REGEX = /^\d+(\.\d{2})?$/;

export const NAME_REGEX = /[a-zA-Z]/;

export const UUID_REGEX =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const formatAddressObject = (address: AddressResponse): string => {
  return formatAddress(
    address.streetAddress1 ?? '',
    address.streetAddress2,
    address.city ?? '',
    capitalizeEnum(address.stateOrProvince ?? ''),
    address.zipOrPostalCode ?? '',
  );
};

export const formatAddress = (
  addressLine1: string,
  addressLine2: string | undefined,
  city: string,
  state: string,
  zip: string,
) => {
  return `${addressLine1},${
    addressLine2 ? ` ${addressLine2},` : ''
  } ${city}, ${state} ${zip}`;
};

export const parseInitials = (name: string): string => {
  return name
    .split(' ')
    .map((n) => n && n[0])
    .filter((n) => ALPHA_NUMERIC_REGEX.test(n))
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

export const stringToNumber = (string: string): number => {
  return string.toUpperCase().charCodeAt(0);
};

export const capitalizeEnum = (label: string) =>
  label
    .split('_')
    .map((str) => prettyEnum(str))
    .join(' ');

const prettyEnum = (str: string) => {
  const lower = str.toLowerCase();
  if (lower === 'ica') return 'ICA';
  if (lower === 'mls') return 'MLS';
  if (lower === 'gci') return 'GCI';

  return capitalize(str);
};

export const cleanPhoneNumber = (phoneNumberString: string | undefined) => {
  return ('' + phoneNumberString).replace(/\D/g, '');
};

export const formatPhoneNumber = (
  phoneNumberString: string | undefined,
): string | undefined => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return undefined;
};

export const fullName = (user: UserResponse) =>
  `${user.firstName} ${user.middleName} ${user.lastName}`;

export const getFileNameFromUrl = (url: string) =>
  url?.split('/').pop()?.split('#')[0].split('?')[0];

export const isStringEmpty = (str: string | undefined): boolean => {
  return !str || str.length === 0;
};

export const isEqualAndNotEmpty = (str1: string, str2: string): boolean => {
  if (isStringEmpty(str1) || isStringEmpty(str2)) {
    return false;
  }

  return str1 === str2;
};
