import React from 'react';
import LanyardImg from '../assets/img/lanyard.svg';

export interface LanyardCardProps {}

const LanyardCard: React.FC<LanyardCardProps> = ({ children }) => {
  return (
    <div className='w-full md:max-w-md border-8 border-dark rounded-lg flex flex-col items-center relative bg-white shadow-2xl'>
      <img src={LanyardImg} alt='lanyard' className='absolute -top-36' />
      <div className='p-1 lg:p-4 mt-3 md:mt-6 w-full'>{children}</div>
    </div>
  );
};

export default LanyardCard;
