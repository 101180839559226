import React from 'react';
import { Link } from 'react-router-dom';
import { AgentResponse } from '../openapi/yenta';
import { getYentaImageUrl } from '../utils/ImgUtils';
import { formatPhoneNumber } from '../utils/StringUtils';
import ProfileImage from './ProfileImage';

const AgentRow: React.FC<AgentResponse> = ({
  id,
  avatar,
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
}) => (
  <Link to={`/people/${id}`} className='flex flex-row border-b px-4 py-2'>
    <div className='mr-3'>
      <ProfileImage imageUrl={getYentaImageUrl(avatar)} width={50} />
    </div>
    <div className='font-primary-medium'>
      <p className='text-dark text-lg leading-5'>
        {firstName} {lastName}
      </p>
      <p className='text-gray-400'>{emailAddress}</p>
      <p className='text-gray-400'>{formatPhoneNumber(phoneNumber)}</p>
    </div>
  </Link>
);

export default AgentRow;
