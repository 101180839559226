import React from 'react';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { ToastNotificationData } from '../types';

export interface ToastNotificationProps extends ToastNotificationData {
  onClose(): void;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({
  type,
  title,
  subtitle,
  onClose,
}) => {
  return (
    <div className='flex items-start bg-dark rounded text-white p-4 w-full max-w-md'>
      <div className='mr-2'>
        {type === 'success' && (
          <CheckCircleOutlineOutlinedIcon className='text-success' />
        )}
        {type === 'warning' && (
          <ReportProblemOutlinedIcon className='text-warning' />
        )}
        {type === 'info' && <InfoOutlinedIcon className='text-primary' />}
        {type === 'error' && <ErrorOutlineIcon className='text-error' />}
      </div>
      <div className='flex-grow'>
        <p className='font-primary-regular'>{title}</p>
        {subtitle && <p className='font-primary-light'>{subtitle}</p>}
      </div>
      <button type='button' onClick={onClose} className='cursor-pointer'>
        <ClearOutlinedIcon />
      </button>
    </div>
  );
};

export default ToastNotification;
