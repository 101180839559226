import { times } from 'lodash';
import {
  TransactionOverview,
  ITransactionsOverviewResponse,
} from '../../../types';
import {
  fakeAddress,
  fakeCommissionAmount,
  fakeDate,
  fakeNumber,
} from '../../../testUtils/FakerUtils';

export const DefaultTransactionsOverviewResponse: ITransactionsOverviewResponse = {
  activeListings: fakeNumber({ min: 100, max: 500 }),
  pending: fakeNumber({ min: 10, max: 100 }),
  closed: fakeNumber({ min: 100, max: 500 }),
  openTransactions: times<TransactionOverview>(2, (id) => ({
    id: id + 1,
    title: fakeAddress(),
    closingDate: fakeDate().toISODate(),
    commission: fakeCommissionAmount(),
  })),
  waitingTransactions: times<TransactionOverview>(2, (id) => ({
    id: id + 1,
    title: fakeAddress(),
    closingDate: fakeDate().toISODate(),
    commission: fakeCommissionAmount(),
  })),
};
