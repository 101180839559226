import React from 'react';
import {
  OfficeResponseTransactionTypeEnum,
  OfficePreviewResponseTransactionTypeEnum,
} from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface OfficeTransactionTypeCellProps {
  transactionType:
    | OfficeResponseTransactionTypeEnum
    | OfficePreviewResponseTransactionTypeEnum;
}

const transactionTypeToPillVariant: EnumMap<
  OfficeResponseTransactionTypeEnum,
  PillVariantType
> = {
  SINGLE_CHECK: 'primary',
  SPLIT_CHECK: 'success',
};

const OfficeTransactionTypeCell: React.FC<OfficeTransactionTypeCellProps> = ({
  transactionType,
}) => {
  return (
    <Pill
      text={capitalizeEnum(transactionType)}
      variant={transactionTypeToPillVariant[transactionType]}
    />
  );
};

export default OfficeTransactionTypeCell;
