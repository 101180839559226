import React from 'react';
import ChevronRight from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import { BreadCrumbsNoUrlType } from '../../types';

export interface BreadCrumbsNoUrlProps {
  paths: BreadCrumbsNoUrlType[];
}

const BreadCrumbsWithActions: React.FC<BreadCrumbsNoUrlProps> = ({ paths }) => {
  const itemsLength = paths.length;

  return (
    <div className='flex flex-row overflow-x-auto px-4 py-3'>
      {paths.map((item, index) => (
        <div
          key={item.title}
          className='flex flex-row items-center text-center'
        >
          <p
            className={classNames(
              'whitespace-nowrap max-w-2 truncate lg:max-w-none cursor-pointer font-primary-medium text-base',
              itemsLength - 1 === index && 'text-gray-400',
            )}
            onClick={item.onClick}
          >
            {item.title}
          </p>
          {itemsLength - 1 !== index && (
            <ChevronRight
              className='text-gray-400 text-center'
              fontSize='small'
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default BreadCrumbsWithActions;
