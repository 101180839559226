import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Column } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import { RootState } from '../types';
import DetailPageLoader from '../components/DetailPageLoader';
import ResourceContainer from '../components/ResourceContainer';
import { JobExecutionResponse } from '../openapi/yenta';
import { getCronJobsHistory } from '../slices/CronJobs';
import PageLayout from '../components/PageLayout';
import CronJobsStatusCell from '../components/table/Cells/CronJobsStatusCell';
import MilliDateTimeCell from '../components/table/Cells/MilliDateTimeCell';

interface Match {
  jobName: string;
}
interface CronJobsHistoryRouteProps extends RouteComponentProps<Match> {}

export const columns: Array<Column<JobExecutionResponse>> = [
  {
    Header: 'Name',
    accessor: 'jobExecutionName',
    Cell: ({ value }) => value,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'jobExecutionStatus',
    Cell: ({ value }) => <CronJobsStatusCell status={value!} />,
  },
  {
    Header: 'Log',
    accessor: 'errorLog',
    Cell: ({ value }) => value,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    Cell: ({ value }) => value,
    disableSortBy: true,
  },
  {
    Header: 'Started At',
    accessor: 'startedAt',
    Cell: ({ value }) => <MilliDateTimeCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Completed At',
    accessor: 'completedAt',
    Cell: ({ value }) => <MilliDateTimeCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
];

const CronJobsHistoryRoute: React.FC<CronJobsHistoryRouteProps> = ({
  match: {
    params: { jobName },
  },
}) => {
  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    await dispatch(getCronJobsHistory(jobName));
  }, [dispatch, jobName]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    cronJobs: { cronJobHistory, fetchErrorCode, loading },
  } = useSelector((state: RootState) => state);

  const getCronJobs = () => {
    return Promise.resolve({
      data: cronJobHistory!,
      total: cronJobHistory!.length,
    });
  };

  return (
    <ResourceContainer
      loading={loading}
      isEmpty={!cronJobHistory!.length}
      errorCode={fetchErrorCode}
      LoaderComponent={<DetailPageLoader />}
      resourceName='Jobs History'
    >
      <PageLayout
        path={[
          { title: 'Jobs', url: '/jobs' },
          {
            title: `${jobName} History`,
            url: `/jobs/${jobName}/history`,
          },
        ]}
      >
        <div className='p-4'>
          <ResourceIndexContainer<JobExecutionResponse>
            header={`${jobName} History`}
            columns={columns}
            resourceName='Jobs History'
            fetchData={() => getCronJobs()}
            hidePagination
            hideFilters
          />
        </div>
      </PageLayout>
    </ResourceContainer>
  );
};
export default CronJobsHistoryRoute;
