import React, { useState } from 'react';
import qs from 'qs';
import { get, merge, set } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormFieldConfig, RootState } from '../../../types';
import Button from '../../Button';
import SubmitButton from '../../SubmitButton';
import {
  ApplicationControllerApi,
  ApplicationRequest,
  ApplicationRequestApplicationTypeEnum,
} from '../../../openapi/yenta';
import { FormDataType } from '../ApplicationForm';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { saveApplication } from '../../../slices/AuthSlice';
import ErrorService from '../../../services/ErrorService';

export interface ReviewProps extends RouteComponentProps {
  title: string;
  questions: FormFieldConfig[];
  setCurrentIndex(currentIndex: number): void;
  formData: FormDataType;
  setIsReviewing(isReviewing: boolean): void;
}

const Review: React.FC<ReviewProps> = ({
  title,
  setCurrentIndex,
  formData,
  questions,
  setIsReviewing,
  history,
  location,
}) => {
  const { sponsorCode, sponsorName, loi } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  // TODO: Allow this to be more typescript friendly. ApplicationRequest has changed and we didn't get notified!
  // Add doesBusinessIn array.
  const getConvertedFormData = (): ApplicationRequest => {
    const request: ApplicationRequest = merge(
      {},
      ...questions.map((question) =>
        set(
          {},
          question.name,
          get(formData, question.name)
            ? question.convertValueBeforeSend
              ? question.convertValueBeforeSend(
                  get(formData, question.name),
                  formData,
                )
              : get(formData, question.name)
            : undefined,
        ),
      ),
    );

    request.doesBusinessIn.forEach((license, index) => {
      license.administrativeAreaRequest = {
        country: formData.selectedCountry,
        stateOrProvince: formData.selectedProvinces[index].value,
      };
    });

    return request;
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      setIsError(false);
      const { data } = await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).applyToRealUsingPOST({
        ...getConvertedFormData(),
        emailAddress: userDetail!.emailAddress!,
        sponsoredByCode: sponsorCode as string | undefined,
        applicationType:
          loi === 'true'
            ? ApplicationRequestApplicationTypeEnum.LetterOfIntent
            : ApplicationRequestApplicationTypeEnum.Regular,
      });

      if (loi === 'true') {
        history.push(
          `/onboarding/application-loi-signing?${qs.stringify({
            sponsorCode,
            sponsorName,
          })}`,
        );
      } else {
        history.push(
          `/onboarding/application-form/success?${qs.stringify({
            sponsorCode,
            sponsorName,
          })}`,
        );
      }

      dispatch(saveApplication(data));
    } catch (e) {
      ErrorService.notify('Unable to save application', e);
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='w-full px-4 lg:max-w-xl mx-auto my-10'>
      <p className='text-2xl font-primary-medium text-center mb-4'>{title}</p>
      {isError && (
        <p className='text-red-600 text-center my-4'>
          Oops! something went wrong while saving application form
        </p>
      )}
      <div className='divide-y'>
        {questions.map((question, index) => {
          const displayValue = get(formData, question.name)
            ? question.resolveFieldDisplayValue
              ? question.resolveFieldDisplayValue(get(formData, question.name))
              : get(formData, question.name)
            : 'N/A';

          return (
            <div key={question.name} className='py-2 flex items-center'>
              <div>
                <div className='w-7 h-7 rounded-full bg-gray-200 flex justify-center items-center'>
                  {index + 1}
                </div>
              </div>
              <div className='flex-grow px-3'>
                <p className='leading-5'>
                  {question.reviewLabel ?? question.label}
                </p>
                <p className='leading-5 text-gray-500'>{displayValue}</p>
              </div>
              <Button
                label='Edit'
                type='secondary'
                onClick={() => {
                  setCurrentIndex(index);
                  setIsReviewing(false);
                }}
              />
            </div>
          );
        })}
      </div>
      <div className='flex mt-4'>
        <SubmitButton
          label='Submit'
          isSubmitting={isSubmitting}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default withRouter(Review);
