import React, { useCallback, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Column } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { flatten } from 'lodash';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import { EquityContributionValue } from '../openapi/arrakis';
import { fetchEquity } from '../slices/AgentSlice';
import { RootState } from '../types';
import { numberToMoney } from '../utils/CurrencyUtils';

interface Match {
  id: string;
}

interface AgentDetailEquityRouteProps extends RouteComponentProps<Match> {}

export const columns: Array<Column<EquityContributionValue>> = [
  {
    Header: 'Address',
    accessor: 'address',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => value?.oneLine,
    cardColSize: 7,
  },
  {
    Header: 'Closing Date',
    accessor: 'closingDateActual',
    Cell: ({ value }) => DateTime.fromISO(value!).toFormat('M/d/y'),
    disableSortBy: true,
  },
  {
    Header: 'Contribution Amount',
    accessor: 'contributionAmount',
    Cell: ({ value }) => numberToMoney(value?.amount! || 0),
    disableSortBy: true,
  },
  {
    Header: 'Match Amount',
    accessor: 'matchAmount',
    Cell: ({ value }) => numberToMoney(value?.amount! || 0),
    disableSortBy: true,
  },
  {
    Header: 'Transaction Id',
    accessor: 'transactionId',
    Cell: ({ value }) => <Link to={`/transactions/${value!}`}>{value}</Link>,
    disableSortBy: true,
  },
];

const AgentDetailEquityRoute: React.FC<AgentDetailEquityRouteProps> = ({
  match,
}) => {
  const agentId = match.params.id;
  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    await dispatch(fetchEquity(agentId));
  }, [agentId, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    agentDetail: { equity },
  } = useSelector((state: RootState) => state);

  const allContributions: EquityContributionValue[] =
    flatten(
      equity?.monthlyAmounts?.map(
        (monthlyAmount) => monthlyAmount.contributions || [],
      ),
    ) || [];

  const agentEquity = () => {
    return Promise.resolve({
      data: allContributions,
      total: allContributions!.length,
    });
  };
  return (
    <div className='p-4'>
      <ResourceIndexContainer<EquityContributionValue>
        header='Equity'
        columns={columns}
        resourceName='Equity'
        fetchData={() => agentEquity()}
        hidePagination
        hideFilters
      />
    </div>
  );
};
export default AgentDetailEquityRoute;
