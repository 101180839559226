import React from 'react';
// import { Add } from '@material-ui/icons';
import { ColumnInstance, Filters } from 'react-table';
// import IconButton from '../IconButton';
import FilterOption from './Options/FilterOption';

interface TableOptionsProps<D extends object> {
  columns: Array<ColumnInstance<D>>;
  filter: Filters<D>;
  isFilterOpen: boolean;
  setAllFilters: (
    updater: Filters<D> | ((filters: Filters<D>) => Filters<D>),
  ) => void;
  setFilterOpen(isFilterOpen: boolean): void;
}

const TableOptions = <D extends object>({
  columns,
  filter,
  setAllFilters,
  isFilterOpen,
  setFilterOpen,
}: TableOptionsProps<D>) => {
  return (
    <div className='flex flex-row flex-nowrap space-x-3 justify-end'>
      <FilterOption<D>
        columns={columns}
        open={isFilterOpen}
        setOpen={setFilterOpen}
        filter={filter}
        setAllFilters={setAllFilters}
      />
    </div>
  );
};

export default TableOptions;
