import { useEffect, useState } from 'react';
import DefaultLoader from '../../components/DefaultLoader';
import ResourceContainer from '../../components/ResourceContainer';
import AgentSponsorsTree from '../components/AgentSponsorsTree';
import { getSponsorsTree, TreeNode } from '../utils/AgentSponsorsTreeUtils';

interface AgentSponsorsTreeRouteProps {}

const AgentSponsorsTreeRoute: React.FC<AgentSponsorsTreeRouteProps> = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<TreeNode | null>(null);
  const [radiusGap, setRadiusGap] = useState(0);
  const fontSize = 11;

  useEffect(() => {
    getSponsorsTree()
      .then(setData)
      .finally(() => setLoading(false));
  }, [setData, setLoading]);

  useEffect(() => {
    setRadiusGap(((data?.children.length || 0) * fontSize + 4) / (2 * Math.PI));
  }, [data, setRadiusGap]);

  return (
    <ResourceContainer
      loading={isLoading}
      LoaderComponent={<DefaultLoader />}
      isEmpty={!data}
      resourceName='agent sponsor'
    >
      <AgentSponsorsTree
        radiusGap={radiusGap}
        data={data}
        fontSize={fontSize}
      />
    </ResourceContainer>
  );
};

export default AgentSponsorsTreeRoute;
