import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, CDAFormState, ErrorCode } from '../types';
import ErrorService from '../services/ErrorService';
import {
  CommissionDocumentControllerApi,
  CommissionDocumentResponse,
} from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: CDAFormState = {
  cdaFormDetail: null,
  loadingCDAFormDetail: true,
  fetchingCDAFormDetailErrorCode: null,
};

const CDAFormSlice = createSlice({
  name: 'cdaForm',
  initialState,
  reducers: {
    changeCDAFormDetailLoading(state, action: PayloadAction<boolean>) {
      state.loadingCDAFormDetail = action.payload;
    },
    saveCDAFormDetail(
      state,
      action: PayloadAction<CommissionDocumentResponse>,
    ) {
      state.cdaFormDetail = action.payload;
      state.fetchingCDAFormDetailErrorCode = null;
    },
    errorFetchingCDAFormDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchingCDAFormDetailErrorCode = action.payload;
    },
  },
});

export const {
  saveCDAFormDetail,
  changeCDAFormDetailLoading,
  errorFetchingCDAFormDetail,
} = CDAFormSlice.actions;

export const fetchCDAFormDetail = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeCDAFormDetailLoading(true));

  try {
    const { data } = await new CommissionDocumentControllerApi(
      getArrakisConfiguration(),
    ).getCommissionDocumentByIdUsingGET(id);
    dispatch(saveCDAFormDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch CDA', e);
    dispatch(errorFetchingCDAFormDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeCDAFormDetailLoading(false));
  }
};

export default CDAFormSlice.reducer;
