import React from 'react';

export interface DataRowProps {
  name: string;
  value?: any;
}

const DataRow: React.FC<DataRowProps> = ({ name, value, children }) => {
  return (
    <tr>
      <td className='border py-1.5 px-3'>{name}</td>
      <td className='border py-1.5 px-3'>
        {value} {children}
      </td>
    </tr>
  );
};

export default DataRow;
