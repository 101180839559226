import React from 'react';
import { keys } from 'lodash';
import { capitalizeEnum } from '../../../utils/StringUtils';
import SelectColumnFilter, {
  SelectColumnFilterProps,
} from './Base/SelectColumnFilter';

const EnumSelectColumnGenerator = <T extends object>(SomeEnum: any) => {
  return (props: SelectColumnFilterProps<T>) => {
    const options = keys(SomeEnum).map((key) => ({
      label: capitalizeEnum(SomeEnum[key]),
      value: SomeEnum[key],
    }));

    return <SelectColumnFilter {...props} options={options} />;
  };
};

export default EnumSelectColumnGenerator;
