import { capitalize, keys } from 'lodash';
import { AsyncSelectOption, LanguageType } from '../../../types';

const DefaultLanguagesPaginateResponse: Array<AsyncSelectOption> = keys(
  LanguageType,
).map((lang) => ({
  label: capitalize(lang),
  value: capitalize(lang),
}));

export default DefaultLanguagesPaginateResponse;
