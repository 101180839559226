import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { get } from 'lodash';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import FormErrorMessage from './FormErrorMessage';

interface ControlledTextInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues> {
  errors: FieldErrors<TFieldValues>;
  label?: string;
  placeholder?: string;
  rows?: number;
}

const ControlledTextAreaInput: React.FC<ControlledTextInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  placeholder,
  rows = 1,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && <label htmlFor={name}>{label}</label>}
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={rules}
        render={({ name, value, onChange, onBlur, ref }) => (
          <textarea
            id={name}
            ref={ref}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            placeholder={placeholder}
            rows={rows}
            className='appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full'
          />
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledTextAreaInput;
