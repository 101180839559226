import React from 'react';
import { AgentResponseAgentStatusEnum } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface AgentStatusCellProps {
  status: AgentResponseAgentStatusEnum;
}

const statusToPillVariantMap: EnumMap<
  AgentResponseAgentStatusEnum,
  PillVariantType
> = {
  [AgentResponseAgentStatusEnum.Inactive]: 'danger',
  [AgentResponseAgentStatusEnum.Active]: 'success',
  [AgentResponseAgentStatusEnum.Candidate]: 'warning',
};

const AgentStatusCell: React.FC<AgentStatusCellProps> = ({ status }) => {
  return (
    <Pill
      text={capitalizeEnum(status)}
      variant={statusToPillVariantMap[status]}
    />
  );
};

export default AgentStatusCell;
