import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

export interface ReferralAgentEmailCellProps {
  id: string | undefined;
  externalAgentEmail: string | undefined;
}

const ReferralAgentEmailCell: React.FC<ReferralAgentEmailCellProps> = ({
  id,
  externalAgentEmail,
}) => {
  const { usersById } = useSelector((state: RootState) => state.userIds);
  return <p>{usersById[id!]?.emailAddress || externalAgentEmail || 'NA'}</p>;
};

export default ReferralAgentEmailCell;
