import React, { ChangeEvent, createRef } from 'react';
import classNames from 'classnames';
import { Edit } from '@material-ui/icons';

export type ProfileImageVariant = 'rounded' | 'circle';

export interface ProfileImageProps {
  imageUrl?: string | undefined;
  width: number;
  variant?: ProfileImageVariant;
  editable?: boolean;
  onImageChange?(e: ChangeEvent<HTMLInputElement>): void;
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  imageUrl,
  width = 50,
  variant = 'circle',
  editable = false,
  onImageChange,
}) => {
  const ref = createRef<HTMLInputElement>();
  const variantMapClass: { [x in ProfileImageVariant]: string } = {
    rounded: 'rounded',
    circle: 'rounded-full',
  };

  const handleChangeImage = () => {
    if (!!onImageChange) {
      ref.current?.click();
    }
  };

  return (
    <div
      className={classNames('relative', {
        'cursor-pointer': !!editable,
      })}
      onClick={handleChangeImage}
    >
      <div
        style={{ width, height: width }}
        className={classNames(
          'overflow-hidden',
          classNames(variantMapClass[variant]),
        )}
      >
        <img
          className='w-full h-auto'
          src={imageUrl || '/img/default-avatar.svg'}
          width={width}
          alt='profileimage'
        />
      </div>
      {editable && (
        <div className='d-flex items-center justify-center w-8 h-8 absolute right-0 bottom-0 rounded-full bg-white p-1'>
          <Edit fontSize='small' />
          <input
            type='file'
            className='hidden'
            ref={ref}
            onChange={onImageChange}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileImage;
