import { TransactionResponseTransactionTypeEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

export interface TransactionTypeSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const TransactionTypeSelectColumnFilter = <D extends object>(
  props: TransactionTypeSelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(TransactionResponseTransactionTypeEnum)(
    props,
  );
};

export default TransactionTypeSelectColumnFilter;
