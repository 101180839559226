import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../types';

export interface AuthenticatedRouteProps extends RouteProps {}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        // @ts-ignore
        userDetail ? <Component {...props} /> : <Redirect to='/' />
      }
    />
  );
};

export default AuthenticatedRoute;
