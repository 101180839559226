import React from 'react';
import { CheckCircleOutline } from '@material-ui/icons';
import Pill, { PillVariantType } from '../../Pill';
import { EnumMap } from '../../../types';
import { AttachedFeeValueFeeTypeEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface FeeTypeCellProps {
  status: AttachedFeeValueFeeTypeEnum;
}

const statusToVariant: EnumMap<AttachedFeeValueFeeTypeEnum, PillVariantType> = {
  ADDITIONAL_COMMISSION: 'success',
  MLS: 'primary',
  TRANSACTION_COORDINATOR: 'warning',
  REIMBURSEMENT: 'dark',
};

const statusToIcon: EnumMap<AttachedFeeValueFeeTypeEnum, React.ReactElement> = {
  ADDITIONAL_COMMISSION: <CheckCircleOutline fontSize='small' />,
  MLS: <CheckCircleOutline fontSize='small' />,
  TRANSACTION_COORDINATOR: <CheckCircleOutline fontSize='small' />,
  REIMBURSEMENT: <CheckCircleOutline fontSize='small' />,
};

const FeeTypeCell: React.FC<FeeTypeCellProps> = ({ status }) => {
  const variant: PillVariantType = statusToVariant[status];
  const icon: React.ReactElement = statusToIcon[status];

  return <Pill text={capitalizeEnum(status)} variant={variant!} icon={icon!} />;
};

export default FeeTypeCell;
