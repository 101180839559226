import React, { useState } from 'react';
import qs from 'qs';
import { Column, Row } from 'react-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link, RouteComponentProps } from 'react-router-dom';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import PageLayoutWithSearch from '../components/PageLayoutWithSearch';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import { BoardResponse, SearchRequestTargetObjectEnum } from '../openapi/yenta';
import DateCell from '../components/table/Cells/DateCell';
import BoardStatusPill from '../components/table/Cells/BoardStatusPill';
import { resourceTableFetchData } from '../utils/TableUtils';
import BoardResponseStatusEnumSelectColumnFilter from '../components/table/Filters/BoardResponseStatusEnumSelectColumnFilter';
import IconButton from '../components/IconButton';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';

interface BoardSearchRouteProps extends RouteComponentProps {}

// TODO: Add State/Province
export const columns: Array<Column<BoardResponse>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Logo',
    accessor: 'logo',
    Cell: ({ value }) =>
      value ? <img src={value} className='h-12' alt='logo' /> : 'N/A',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Code',
    accessor: 'code',
    Filter: TextColumnFilter,
    cardColSize: 4,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <BoardStatusPill status={value!} />,
    Filter: BoardResponseStatusEnumSelectColumnFilter,
    cardColSize: 4,
  },
  {
    Header: 'Joined Date',
    accessor: 'joinDate',
    Cell: ({ value }) => (value ? <DateCell date={value} /> : 'N/A'),
    cardColSize: 4,
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value,
    Filter: TextStrictCaseColumnFilter,
  },
];

export const columnsWithAction: Array<Column<BoardResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<BoardResponse> }) => (
      <Link to={`/boards/${row.original.id}`}>
        <IconButton leftIcon={<VisibilityIcon titleAccess='View' />} />
      </Link>
    ),
  },
  ...columns,
];

export const columnsToFetch = columns.map((col) => col.accessor as string);
const columnsForSearch = ['name', 'code'];

const BoardSearchRoute: React.FC<BoardSearchRouteProps> = ({ location }) => {
  const { term, type } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [search, setSearch] = useState<string | undefined>(
    typeof term === 'string' ? term : undefined,
  );

  return (
    <PageLayoutWithSearch
      path={[
        { title: 'Home', url: '/' },
        { title: 'Board', url: '/boards' },
      ]}
      search={search}
      onSearchChange={setSearch}
    >
      <div className='px-4 lg:pt-5'>
        <ResourceIndexContainer<BoardResponse>
          header='Board'
          defaultSelectedTabName={typeof type === 'string' ? type : undefined}
          search={search}
          columns={columnsWithAction}
          initialSort={{ name: 'asc' }}
          fetchData={(req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              columnsForSearch,
              SearchRequestTargetObjectEnum.Board,
              'yenta',
            )
          }
          resourceName='Board'
        />
      </div>
    </PageLayoutWithSearch>
  );
};

export default BoardSearchRoute;
