import React from 'react';
import { getPageCount } from '../utils/TableUtils';
import { ErrorCode } from '../types';
import SearchBar from './SearchBar';
import ResourceContainer from './ResourceContainer';
import ControlDisplayItems from './table/ControlDisplayItems';
import DefaultLoader from './DefaultLoader';
import NextPrevPagination from './table/NextPrevPagination';

interface ListPaginateProps<D> {
  listItems: Array<D>;
  search?: string;
  itemsToShow: number;
  page: number;
  totalItems: number;
  loading: boolean;
  errorCode: null | ErrorCode;
  searchPlaceholder?: string;
  resourceName: string;
  onSearch(searchValue?: string): void;
  goToPage(page: number): void;
  updateTotalItemsToShow(itemsToShow: number): void;
  children(data: Array<D>): React.ReactNode;
}

const ListPaginate = <D extends Object>({
  listItems,
  search,
  itemsToShow,
  page,
  totalItems,
  loading,
  errorCode,
  onSearch,
  goToPage,
  updateTotalItemsToShow,
  searchPlaceholder = 'Search...',
  resourceName,
  children,
}: ListPaginateProps<D>) => {
  const lastPage = getPageCount(totalItems, itemsToShow);

  return (
    <>
      <div className='border border-gray-200 w-full pt-4 my-3 rounded relative'>
        <div className='px-4 mb-3'>
          <SearchBar
            value={search || ''}
            onChange={(search) => onSearch(search || undefined)}
            placeholder={searchPlaceholder}
          />
        </div>
        <ResourceContainer
          loading={!listItems.length && loading}
          isEmpty={!listItems.length}
          errorCode={errorCode}
          resourceName={resourceName}
        >
          {loading && (
            <div className='absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center bg-white bg-opacity-50'>
              <DefaultLoader />
            </div>
          )}
          {children(listItems)}
        </ResourceContainer>
      </div>
      <div className='flex flex-col lg:flex-row space-y-4 lg:space-y-0 items-center justify-between mt-1'>
        <ControlDisplayItems
          itemsToShow={itemsToShow}
          setPageSize={updateTotalItemsToShow}
        />
        <NextPrevPagination
          currentPage={page}
          lastPage={lastPage}
          goToPage={goToPage}
        />
      </div>
    </>
  );
};

export default ListPaginate;
