import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Info } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import {
  ApplicationControllerApi,
  ApplicationResponseNextStatusEnum,
} from '../../openapi/yenta';
import { saveApplication } from '../../slices/AuthSlice';
import { RootState } from '../../types';
import { ReactComponent as ExternalLink } from '../../assets/icons/external-link.svg';
import DocumentToolbarImg from '../../assets/img/document-toolbar.png';
import IconButton from '../IconButton';
import ApplicationLayout from '../ApplicationLayout';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import SubmitButton from '../SubmitButton';
import ErrorService from '../../services/ErrorService';
import { openSupportChat } from '../../utils/Acquire';

interface ApplicationTransferLicenseProps extends RouteComponentProps {}

const ApplicationTransferLicense: React.FC<ApplicationTransferLicenseProps> = ({
  history,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const applicationLastIndex = userDetail!.applications!.length - 1;

  useRedirectAwayOnboardingEffect(
    ApplicationResponseNextStatusEnum.TransferLicense,
    history,
  );

  const agentCanTransferLicense = userDetail?.applications![
    applicationLastIndex
  ]!.doesBusinessIn![0].administrativeArea!.agentCanTransferLicense;

  const agentLicenseTransferred = async () => {
    setIsSubmitting(true);

    try {
      const api = new ApplicationControllerApi(getYentaConfiguration());
      const { data } = await api.licenseTransferredUsingPOST({
        applicationId: userDetail?.applications![applicationLastIndex].id!,
      });
      dispatch(saveApplication(data));
      history.push('/onboarding/application-join-board');
    } catch (e) {
      ErrorService.notify('Unable to transfer license', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (agentCanTransferLicense) {
    return (
      <ApplicationLayout>
        <div className='flex justify-center items-center my-20'>
          <div className='container lg:px-32 px-5'>
            <div className='text-center'>
              <p className='lg:text-4xl text-3xl font-primary-medium'>
                Transfer License
              </p>
            </div>
            <div className='grid lg:grid-cols-3 grid-cols-1 border rounded mt-10'>
              <div className='bg-white col-span-2 flex flex-col justify-center lg:p-16 p-5'>
                <p className='text-xl font-primary-medium'>
                  License Transfer Guide
                </p>
                <p className='text-gray-500 lg:text-lg mt-3'>
                  We have prepared a detailed knowledge base for you on how to
                  transfer the license for each state.
                </p>
                <p className='text-gray-900 mt-3'>
                  State specific instructions can be found in the below link
                </p>
                <div>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    href='https://nzy7h4.acquire.io/kb/knowledge-base-default/license-transfer-instructions'
                    className='mt-3 bg-primary text-white p-2 rounded'
                  >
                    Learn More
                  </a>
                </div>
                <p className='mt-3 text-gray-500'>
                  Have you found the instructions you needed in our knowledge
                  base? If not, please Contact Support.
                </p>
              </div>
              <div className='bg-dark flex flex-col items-center justify-center text-white p-5'>
                <Info style={{ fontSize: 60 }} />
                <p className='text-lg text-center mt-5'>
                  Click the button below when you have completed the transfer of
                  your license
                </p>
                <SubmitButton
                  label='I have transferred my license'
                  isSubmitting={isSubmitting}
                  onClick={agentLicenseTransferred}
                />
                <div className='mt-5'>
                  <IconButton
                    label='Contact Support'
                    onClick={() => openSupportChat()}
                    variant='outline'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  }

  return (
    <ApplicationLayout>
      <div className='flex justify-center items-center my-20'>
        <div className='container lg:px-32 px-5'>
          <div className='text-center'>
            <p className='lg:text-4xl text-3xl font-primary-medium'>
              Transfer License
            </p>
          </div>
          <div className='grid lg:grid-cols-3 grid-cols-1 border rounded mt-10 bg-dark'>
            <div className='col-span-2 flex flex-col justify-center lg:p-20 p-5'>
              <p className='lg:text-4xl text-3xl text-white font-primary-medium'>
                We’re working on it
              </p>
              <p className='text-gray-300 text-justify mt-3'>
                Our team is working on the license transfer process and will
                notify you once it is completed. Note that it may take a few
                days depending on the state you’re in. You can check the average
                processing time for your state in this{' '}
                <a
                  href='https://nzy7h4.acquire.io/kb/knowledge-base-default/license-transfer-instructions'
                  className='text-primary'
                >
                  knowledge base article.
                </a>{' '}
                If you have not found the answer in the knowledge base, please
                feel free to contact our support team.
              </p>
              <div className='mt-10'>
                <IconButton
                  label='Visit the help center'
                  onClick={() => openSupportChat()}
                  variant='outline'
                  rightIcon={
                    <SvgIcon component={ExternalLink} fontSize='small' />
                  }
                />
              </div>
            </div>
            <div className='bg-dark flex flex-col items-center justify-center text-white p-5'>
              <img src={DocumentToolbarImg} alt='document-toolbar' />
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};
export default ApplicationTransferLicense;
