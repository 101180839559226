import React from 'react';
import { useForm } from 'react-hook-form';
import { values } from 'lodash';
import { useDispatch } from 'react-redux';
import SidebarModal from '../SidebarModal';
import ControlledTextInput from '../ControlledTextInput';
import { capitalizeEnum, getFileNameFromUrl } from '../../utils/StringUtils';
import {
  CommissionAdvanceResponse,
  MoneyValueCurrencyEnum,
  UpdateCommissionAdvanceRequest,
  UpdateCommissionAdvanceRequestAdvanceStatusEnum,
} from '../../openapi/arrakis';
import { updateAgentCommissionAdvances } from '../../slices/AgentSlice';
import ControlledFileUploadInput from '../ControlledFileUploadInput';
import ControlledSelectInput from '../ControlledSelectInput';
import Button from '../Button';
import { EMAIL_VALIDATIONS } from '../../utils/Validations';

interface EditAgentCommissionAdvancesSidebarFormProps {
  isOpen: boolean;
  onClose(): void;
  commissionAdvance: CommissionAdvanceResponse;
}

interface FormData {
  amount: number;
  companyEmail: string;
  companyName: string;
  agreementPath: File[];
  advanceStatus: UpdateCommissionAdvanceRequestAdvanceStatusEnum;
  notes: string;
}

const EditAgentCommissionAdvancesSidebarForm: React.FC<EditAgentCommissionAdvancesSidebarFormProps> = ({
  isOpen,
  onClose,
  commissionAdvance,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    const updateCommissionAdvanceRequest: UpdateCommissionAdvanceRequest = {
      amount: { amount: data.amount, currency: MoneyValueCurrencyEnum.Usd },
      companyName: data.companyName,
      companyEmail: data.companyEmail,
      advanceStatus: data.advanceStatus,
      notes: data.notes,
    };

    console.log('[data]', data);

    await dispatch(
      updateAgentCommissionAdvances(
        commissionAdvance?.id!,
        updateCommissionAdvanceRequest,
        data.agreementPath[0],
      ),
    );

    onClose();
  };

  return (
    <SidebarModal
      title='Edit a Commission Advance'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col justify-between h-full'
      >
        <div className='px-4'>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              errors={errors}
              label='Amount in USD'
              name='amount'
              defaultValue={commissionAdvance?.amount?.amount}
              rules={{ required: 'Amount is required' }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              errors={errors}
              label='Name of Company'
              name='companyName'
              defaultValue={commissionAdvance?.companyName}
              rules={{ required: 'Name of Company is required' }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              errors={errors}
              label='Email'
              name='companyEmail'
              defaultValue={commissionAdvance?.companyEmail}
              rules={{
                required: 'Email is required',
                ...EMAIL_VALIDATIONS,
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledFileUploadInput
              control={control}
              errors={errors}
              label='Agreement'
              name='agreementPath'
              placeholder={getFileNameFromUrl(
                commissionAdvance?.agreementPath!,
              )}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              control={control}
              errors={errors}
              label='Status'
              name='advanceStatus'
              defaultValue={commissionAdvance?.advanceStatus}
              rules={{ required: 'Status is required' }}
              options={values(
                UpdateCommissionAdvanceRequestAdvanceStatusEnum,
              ).map((c) => ({
                value: c,
                label: capitalizeEnum(c),
              }))}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              errors={errors}
              label='Notes (Optional)'
              name='notes'
              defaultValue={commissionAdvance?.notes}
            />
          </div>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={onClose}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EditAgentCommissionAdvancesSidebarForm;
