import React from 'react';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Close from '@material-ui/icons/Close';
import WarningRounded from '@material-ui/icons/WarningRounded';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import Pill, { PillVariantType } from '../../Pill';
import { EnumMap } from '../../../types';
import { CommissionDocumentResponseStatusEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface CommissionDocumentStatusCellProps {
  status: CommissionDocumentResponseStatusEnum;
}

const statusToVariant: EnumMap<
  CommissionDocumentResponseStatusEnum,
  PillVariantType
> = {
  [CommissionDocumentResponseStatusEnum.Approved]: 'success',
  [CommissionDocumentResponseStatusEnum.Invalidated]: 'danger',
  [CommissionDocumentResponseStatusEnum.Pending]: 'warning',
  [CommissionDocumentResponseStatusEnum.Uninitialized]: 'primary',
};

const statusToIcon: EnumMap<
  CommissionDocumentResponseStatusEnum,
  React.ReactElement
> = {
  [CommissionDocumentResponseStatusEnum.Invalidated]: (
    <Close fontSize='small' />
  ),
  [CommissionDocumentResponseStatusEnum.Approved]: (
    <CheckCircleOutline fontSize='small' />
  ),
  [CommissionDocumentResponseStatusEnum.Pending]: (
    <WarningRounded fontSize='small' />
  ),
  [CommissionDocumentResponseStatusEnum.Uninitialized]: (
    <PriorityHigh fontSize='small' />
  ),
};

const CommissionDocumentStatusCell: React.FC<CommissionDocumentStatusCellProps> = ({
  status,
}) => {
  const variant: PillVariantType = statusToVariant[status];
  const icon: React.ReactElement = statusToIcon[status];

  return <Pill text={capitalizeEnum(status)} variant={variant!} icon={icon!} />;
};

export default CommissionDocumentStatusCell;
