import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  CreateNoteRequest,
  CreateNoteRequestAllowableRoleEnum,
  CreateNoteRequestEntityTypeEnum,
  NoteControllerApi,
  NoteResponse,
  UpdateNoteRequestAllowableRoleEnum,
} from '../openapi/yenta';
import { getYentaImageUrl } from '../utils/ImgUtils';
import { showApiErrorModal } from '../slices/ErrorSlice';
import ErrorService from '../services/ErrorService';
import ControlledDatePickerInput from './ControlledDatePickerInput';
import ControlledTextInput from './ControlledTextInput';
import NoteItem from './NoteItem';
import Button from './Button';

export interface NotesContainerProps {
  entityId: string;
  entityType: CreateNoteRequestEntityTypeEnum;
}

interface CalendarIconProps {
  value?: string;
  onClick?(): void;
}

interface FormData {
  comment: string;
  followupDate?: string;
}

const CalendarIconInput = forwardRef<HTMLInputElement, CalendarIconProps>(
  ({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref} className='px-1 space-x-2 cursor-pointer'>
      {value ? (
        <p className='mr-8'>{value}</p>
      ) : (
        <CalendarTodayIcon fontSize='small' className='text-gray-400 mb-0.5' />
      )}
    </div>
  ),
);

const NotesContainer: React.FC<NotesContainerProps> = ({
  entityId,
  entityType,
}) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState<NoteResponse[]>([]);
  const { control, errors, handleSubmit, reset } = useForm<FormData>();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await new NoteControllerApi(
        getYentaConfiguration(),
      ).getNoteByIdUsingGET1(entityId, entityType);

      setNotes(data.notes!);
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch notes', e);
    }
  }, [entityId, entityType, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = async (values: FormData) => {
    try {
      const createNoteRequest: CreateNoteRequest = {
        comment: values.comment,
        entityId: entityId,
        followupDate: values.followupDate
          ? DateTime.fromISO(values.followupDate).toMillis()
          : undefined,
        entityType: entityType,
        allowableRole: CreateNoteRequestAllowableRoleEnum.Admin,
      };

      const { data } = await new NoteControllerApi(
        getYentaConfiguration(),
      ).createNoteUsingPUT(createNoteRequest);

      setNotes([...notes, data]);
      reset({ comment: '', followupDate: '' });
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to create note', e);
    }
  };

  const updateFollowUpdate = async (note: NoteResponse) => {
    const { data } = await new NoteControllerApi(
      getYentaConfiguration(),
    ).updateNoteUsingPUT(note.id!, {
      followupDate: undefined,
      comment: note.comment!,
      allowableRole: (note.allowableRole! as unknown) as UpdateNoteRequestAllowableRoleEnum,
    });

    setNotes(notes.map((note) => (note.id === data.id ? data : note)));
  };

  return (
    <div className='px-2 border rounded py-5'>
      <ul>
        {notes.map((note, index) => {
          return (
            <li key={note.id}>
              <NoteItem
                name={note.user?.fullName!}
                comment={note.comment!}
                followUpDate={{
                  date: note.followupDate!,
                  onClick: () => updateFollowUpdate(note),
                }}
                createdAt={note.createdAt!}
                avatar={getYentaImageUrl(note.user?.avatar)}
                isLastItem={notes.length - 1 === index}
              />
            </li>
          );
        })}
      </ul>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex-row flex pr-1 focus:outline-none items-center border rounded related'>
          <ControlledTextInput
            name='comment'
            placeholder='Add a note here...'
            control={control}
            errors={errors}
            rules={{ required: 'Note is required' }}
            noBorder
          />
          <div className='flex items-center'>
            <ControlledDatePickerInput
              customInput={<CalendarIconInput />}
              name='followupDate'
              control={control}
              errors={errors}
              datePickerConfig={{
                isClearable: true,
                minDate: DateTime.local().plus({ days: 1 }).toJSDate(),
                popperModifiers: {},
                popperClassName: 'z-50',
              }}
            />
            <Button
              label='Save'
              type='secondary'
              buttonType='submit'
              onClick={handleSubmit(onSubmit)}
              className='mx-3 text-dark font-primary-medium'
              pill
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default NotesContainer;
