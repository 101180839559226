import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AgentDetailState, ErrorCode } from '../types';
import ErrorService from '../services/ErrorService';
import {
  AgentControllerApi,
  AgentResponse,
  AgentUpdateRequest,
} from '../openapi/yenta';
import {
  AgentControllerApi as ArrakisAgentControllerApi,
  CommissionAdvanceResponse,
  CommissionAdvancesControllerApi,
  CreateCommissionAdvanceRequest,
  DisburseReferralRequest,
  IncomeControllerApi,
  IncomeOverviewResponse,
  IncomeTotalsResponse,
  ReferralRequest,
  ReferralResponse,
  TransactionControllerApi,
  TransactionResponse,
  UpdateCommissionAdvanceRequest,
  AgentResponse as ArrakisAgentResponse,
  EquityControllerApi,
  EquityOverviewResponse,
} from '../openapi/arrakis';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { fetchUserByIds } from './UserIdsSlice';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: AgentDetailState = {
  loadingDetail: false,
  detail: undefined,
  fetchDetailErrorCode: null,
  loadingReferrals: false,
  referrals: undefined,
  fetchReferralsErrorCode: null,
  loadingCommissionAdvances: false,
  commissionAdvances: undefined,
  fetchCommissionAdvancesErrorCode: null,
  loadingPerformance: false,
  performance: undefined,
  fetchPerformanceErrorCode: null,
  transactions: [],
  incomeOverview: {},
  incomeChart: {},
  equity: {},
};

const AgentSlice = createSlice({
  name: 'agentSlice',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveDetail(state, action: PayloadAction<AgentResponse>) {
      state.detail = action.payload;
      state.fetchDetailErrorCode = null;
    },
    updateDetail(state, action: PayloadAction<AgentResponse>) {
      state.detail = action.payload;
    },
    changeLoadingReferrals(state, action: PayloadAction<boolean>) {
      state.loadingReferrals = action.payload;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
    saveReferrals(state, action: PayloadAction<ReferralResponse[]>) {
      state.referrals = action.payload;
      state.fetchReferralsErrorCode = null;
    },
    errorFetchingReferralsDetail(state, action: PayloadAction<ErrorCode>) {
      state.referrals = undefined;
      state.fetchReferralsErrorCode = action.payload;
    },
    addReferral(state, action: PayloadAction<ReferralResponse>) {
      state.referrals?.unshift(action.payload);
    },
    updateReferral(state, action: PayloadAction<ReferralResponse>) {
      const referralIndex = state.referrals?.findIndex(
        (referral) => referral.id === action.payload.id,
      );

      if (referralIndex !== -1) {
        state.referrals![referralIndex!] = action.payload;
      }
    },
    deleteReferral(state, action: PayloadAction<string>) {
      state.referrals = state.referrals?.filter(
        (referral) => referral.id !== action.payload,
      );
    },
    changeLoadingCommissionAdvances(state, action: PayloadAction<boolean>) {
      state.loadingCommissionAdvances = action.payload;
    },
    errorFetchingCommissionAdvances(state, action: PayloadAction<ErrorCode>) {
      state.commissionAdvances = undefined;
      state.fetchCommissionAdvancesErrorCode = action.payload;
    },
    saveCommissionAdvances(
      state,
      action: PayloadAction<CommissionAdvanceResponse[]>,
    ) {
      state.commissionAdvances = action.payload;
      state.fetchCommissionAdvancesErrorCode = null;
    },
    addCommissionAdvances(
      state,
      action: PayloadAction<CommissionAdvanceResponse>,
    ) {
      state.commissionAdvances?.push(action.payload);
    },
    updateCommissionAdvances(
      state,
      action: PayloadAction<CommissionAdvanceResponse>,
    ) {
      let commissionAdvancesIndex = state.commissionAdvances?.findIndex(
        (c) => c.id === action.payload.id,
      );

      if (commissionAdvancesIndex !== -1) {
        state.commissionAdvances![commissionAdvancesIndex!] = action.payload;
      }
    },
    saveTransactions(state, action: PayloadAction<TransactionResponse[]>) {
      state.transactions = action.payload;
    },
    saveIncomeOverview(state, action: PayloadAction<IncomeOverviewResponse>) {
      state.incomeOverview = action.payload;
    },
    saveIncomeChart(state, action: PayloadAction<IncomeTotalsResponse>) {
      state.incomeChart = action.payload;
    },
    savePerformance(state, action: PayloadAction<ArrakisAgentResponse>) {
      state.performance = action.payload;
      state.fetchReferralsErrorCode = null;
    },
    errorFetchingPerformance(state, action: PayloadAction<ErrorCode>) {
      state.performance = undefined;
      state.fetchPerformanceErrorCode = action.payload;
    },
    changeLoadingPerformance(state, action: PayloadAction<boolean>) {
      state.loadingPerformance = action.payload;
    },
    addEquity(state, action: PayloadAction<EquityOverviewResponse>) {
      state.equity = action.payload;
    },
  },
});

export const {
  changeLoadingDetail,
  saveDetail,
  changeLoadingReferrals,
  saveReferrals,
  errorFetchingDetail,
  errorFetchingReferralsDetail,
  updateDetail,
  addReferral,
  updateReferral,
  deleteReferral,
  changeLoadingCommissionAdvances,
  errorFetchingCommissionAdvances,
  saveCommissionAdvances,
  addCommissionAdvances,
  updateCommissionAdvances,
  saveTransactions,
  saveIncomeOverview,
  saveIncomeChart,
  savePerformance,
  errorFetchingPerformance,
  changeLoadingPerformance,
  addEquity,
} = AgentSlice.actions;

export const fetchAgentDetail = (id: string): AppThunk => async (dispatch) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).getAgentByIdUsingGET(id);
    dispatch(saveDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent detail', e, { agent: { id } });
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const updateAgentDetail = (
  id: string,
  values: AgentUpdateRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).updateAgentByIdUsingPUT(id, values);
    dispatch(updateDetail(data));
    dispatch(showSuccessToast('Your profile was successfully updated.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update agent details', e, {
      agent: { id, values },
    });
    dispatch(
      showErrorToast(
        'We had a problem updating your information.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchAgentReferrals = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingReferrals(true));
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).getReferralsUsingGET(id);

    const userIds: string[] = data.referrals?.map(
      (referral) => referral.receivingAgentUserYentaId!,
    )!;

    const finalUserIds = userIds.filter((userId) => userId !== null);

    dispatch(fetchUserByIds(finalUserIds));
    dispatch(saveReferrals(data.referrals!));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent referral', e, { agent: { id } });
    dispatch(errorFetchingReferralsDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingReferrals(false));
  }
};

export const addReferralDispatch = (
  id: string,
  values: ReferralRequest,
  referralAgreement: File,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).createReferralUsingPOST(id, values);

    const { data: finalData } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).updateReferralAttachmentUsingPUT(data.id!, referralAgreement);

    if (finalData.receivingAgentUserYentaId) {
      dispatch(fetchUserByIds([finalData.receivingAgentUserYentaId]));
    }

    dispatch(addReferral(finalData));
    dispatch(showSuccessToast('Referral added Successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add referral', e, {
      referral: { yentaId: id, values },
    });
    dispatch(
      showErrorToast(
        'We had a problem adding a new referral',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }

  return true;
};

export const updateReferralDispatch = (
  referralId: string,
  values: ReferralRequest,
  referralAgreement: File,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      await getArrakisConfiguration(),
    ).updateReferralUsingPUT(referralId, values);

    dispatch(fetchUserByIds([data.receivingAgentUserYentaId!]));

    if (referralAgreement) {
      const {
        data: referralAgreementData,
      } = await new ArrakisAgentControllerApi(
        await getArrakisConfiguration(),
      ).updateReferralAttachmentUsingPUT(referralId, referralAgreement);

      dispatch(updateReferral(referralAgreementData));
    } else {
      dispatch(updateReferral(data));
    }

    dispatch(showSuccessToast('Referral updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update the referral', e, {
      referral: { id: referralId, values },
    });
    dispatch(
      showErrorToast(
        'We had a problem updating the referral',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }

  return true;
};

export const disburseReferralDispatch = (
  referralId: string,
  values: DisburseReferralRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    // TODO: Update the function to replace static data
    const { data } = await new ArrakisAgentControllerApi(
      await getArrakisConfiguration(),
    ).disburseReferralUsingPUT(referralId, values);

    dispatch(updateReferral(data));
    dispatch(showSuccessToast('Referral disbursed Successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to disburse referral', e, {
      referral: { id: referralId },
    });
    dispatch(
      showErrorToast(
        'We had a problem disbursing the referral',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }

  return true;
};

export const deleteReferralDispatch = (referralId: string): AppThunk => async (
  dispatch,
) => {
  try {
    await new ArrakisAgentControllerApi(
      await getArrakisConfiguration(),
    ).deleteReferralUsingDELETE(referralId);

    dispatch(deleteReferral(referralId));
    dispatch(showSuccessToast('Referral deleted successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to delete referral', e, {
      referral: { id: referralId },
    });
    dispatch(
      showErrorToast(
        'We had a problem deleting the referral',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchAgentCommissionAdvances = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingCommissionAdvances(true));
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).getCommissionAdvancesUsingGET(id);

    dispatch(saveCommissionAdvances(data.commissionAdvances));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent referral', e);
    dispatch(errorFetchingCommissionAdvances(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingCommissionAdvances(false));
  }
};

export const addAgentCommissionAdvances = (
  id: string,
  values: CreateCommissionAdvanceRequest,
  agreement: File,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).createCommissionAdvanceUsingPOST(id, values);

    const { data: finalData } = await new CommissionAdvancesControllerApi(
      getArrakisConfiguration(),
    ).updateCommissionAdvanceAttachmentUsingPUT(data.id!, agreement);

    dispatch(addCommissionAdvances(finalData));
    dispatch(showSuccessToast('Added new Commission Advance'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to save new commission advance', e, {
      commissionAdvance: { ...values },
    });
    dispatch(
      showErrorToast(
        'We had a problem adding new commission advance',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const updateAgentCommissionAdvances = (
  commissionAdvanceId: string,
  values: UpdateCommissionAdvanceRequest,
  agreement: File,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CommissionAdvancesControllerApi(
      getArrakisConfiguration(),
    ).updateCommissionAdvanceUsingPUT(commissionAdvanceId, values);

    if (agreement) {
      const { data: agreementData } = await new CommissionAdvancesControllerApi(
        getArrakisConfiguration(),
      ).updateCommissionAdvanceAttachmentUsingPUT(
        commissionAdvanceId,
        agreement,
      );

      dispatch(updateCommissionAdvances(agreementData));
    } else {
      dispatch(updateCommissionAdvances(data));
    }

    dispatch(showSuccessToast('Update Commission Advance'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update commission advance', e, {
      commissionAdvance: { ...values },
    });
    dispatch(
      showErrorToast(
        'We had a problem updating commission advance',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchAgentTransactions = (yentaId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getAllTransactionsUsingGET(yentaId);

    dispatch(saveTransactions(data.transactions!));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent transactions', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching the transactions',
        'Please try again in a few moments.',
      ),
    );
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const fetchIncomeOverview = (yentaId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new IncomeControllerApi(
      await getArrakisConfiguration(),
    ).getYearlyIncomePerformanceOverviewUsingGET(yentaId);
    const { data: incomeChart } = await new IncomeControllerApi(
      await getArrakisConfiguration(),
    ).getIncomeTotalsUsingGET(yentaId);

    dispatch(saveIncomeOverview(data));
    dispatch(saveIncomeChart(incomeChart));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent income details', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching the details',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchAgentPerformance = (yentaId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingPerformance(true));
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).getAgentByYentaIdUsingGET(yentaId);

    dispatch(savePerformance(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent performance', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching agent performance',
        'Please try again in a few moments.',
      ),
    );
    dispatch(errorFetchingPerformance(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingPerformance(false));
  }
};

export const downloadCommissionAdvancesDoc = (
  commissionId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new CommissionAdvancesControllerApi(
      getArrakisConfiguration(),
    ).getPreSignedUrlUsingGET1(commissionId);
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch commission advances document', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching commission advances document',
        'Please try again in a few moments.',
      ),
    );
    dispatch(errorFetchingPerformance(ErrorService.getErrorCode(e)));
    return undefined;
  }
};

export const downloadReferralDoc = (
  referralId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).getPreSignedUrlUsingGET(referralId);
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch referral document', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching the referral document',
        'Please try again in a few moments.',
      ),
    );
    dispatch(errorFetchingPerformance(ErrorService.getErrorCode(e)));
    return undefined;
  }
};

export const fetchEquity = (yentaId: string): AppThunk => async (dispatch) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new EquityControllerApi(
      await getArrakisConfiguration(),
    ).getCurrentRevSharePerformanceOverviewUsingGET(yentaId);

    dispatch(addEquity(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent equity', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching the equity',
        'Please try again in a few moments.',
      ),
    );
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export default AgentSlice.reducer;
