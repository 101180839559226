import React from 'react';
import { DateTime } from 'luxon';

export interface MilliDateCellProps {
  date: number | undefined;
}

const MilliDateCell: React.FC<MilliDateCellProps> = ({ date }) => {
  return (
    <span>{date ? DateTime.fromMillis(date).toFormat('LL/dd/yy') : 'N/A'}</span>
  );
};

export default MilliDateCell;
