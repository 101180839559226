import React, { useState } from 'react';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { Check } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../types';
import Button from '../Button';
import ApplicationStepCompleteMessage from '../ApplicationStepCompleteMessage';
import ApplicationLayout from '../ApplicationLayout';
import config from '../../config';
import ApplicationVerticalStepProgress from './ApplicationVerticalStepProgress';

export interface ApplicationLOISigningSuccessProps
  extends RouteComponentProps {}

const ApplicationLOISigningSuccess: React.FC<ApplicationLOISigningSuccessProps> = ({
  history,
  location,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const { sponsorCode, sponsorName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const referralLink = `${config.reactAppHostUrl}/register?${qs.stringify({
    sponsorCode: userDetail?.sponsorCode,
    sponsorName: `${userDetail?.firstName} ${userDetail?.lastName}`,
  })}`;

  const copyToClipboard = async (value: string) => {
    await navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <ApplicationLayout>
      <div className='w-full mx-auto px-4 my-10 lg:max-w-6xl gap-10 grid grid-cols-1 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          <ApplicationStepCompleteMessage
            icon={<Check fontSize='large' />}
            header='Thank you for signing the letter of intent. You can come back later and sign the ICA when you are ready.'
            nextStepName='Sign Agreement'
            onNextStepClicked={() => {
              history.push(
                `/onboarding/application-ica-signing?${qs.stringify({
                  sponsorCode,
                  sponsorName,
                })}`,
              );
            }}
          />
          <div>
            <div className='border rounded mt-5 '>
              <div className='p-2'>
                <p>Refer an Agent</p>
              </div>
              <div className='p-3 border-t'>
                <div className='flex bg-gray-50 border border-gray-200 mr-2 p-1.5 rounded'>
                  <input
                    value={referralLink}
                    name='link'
                    className='flex-grow bg-gray-50 font-primary-light mr-2 truncate'
                    disabled
                  />
                  <Button
                    type='primary'
                    label={isCopied ? 'Copied Link' : 'Copy Link'}
                    onClick={() => copyToClipboard(referralLink)}
                  />
                </div>
              </div>
            </div>
            <p className='mt-2'>
              Agents who have closed a deal in the prior 12 months will be
              rewarded based on our Revenue Sharing Plan.{' '}
              <a
                href='https://www.joinreal.com/about/details/revenueshare'
                target='_blank'
                rel='noreferrer'
                className='underline'
              >
                Learn more
              </a>
            </p>
          </div>
        </div>
        <div className='lg:col-span-2'>
          <ApplicationVerticalStepProgress />
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationLOISigningSuccess;
