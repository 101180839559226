import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { get } from 'lodash';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { AsyncPaginate } from 'react-select-async-paginate';
import { AsyncSelectAdditional, AsyncSelectOption } from '../types';
import { DEFAULT_PAGE_NUM } from '../constants/AsyncSelectPaginationConstants';
import FormErrorMessage from './FormErrorMessage';

export const ASYNC_MULTI_SELECT_DEBOUNCE = 400;

interface ControlledAsyncSelectInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues> {
  errors: FieldErrors<TFieldValues>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  fetchData(search: string, page?: number): Promise<Array<AsyncSelectOption>>;
}

const ControlledAsyncSelectInput: React.FC<ControlledAsyncSelectInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  placeholder = 'Select',
  disabled = false,
  fetchData,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && <label htmlFor={name}>{label}</label>}
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={rules}
        render={({ name, value, onChange, onBlur }) => (
          <AsyncPaginate
            placeholder={placeholder}
            value={value}
            name={name}
            isDisabled={disabled}
            defaultValue={value}
            // @ts-ignore
            loadOptions={async (
              search,
              _additionalOptions,
              { page }: AsyncSelectAdditional,
            ) => {
              const data = await fetchData(search, page);
              return {
                options: data,
                hasMore: !!data.length,
                additional: {
                  page: page + 1,
                },
              };
            }}
            additional={{
              page: DEFAULT_PAGE_NUM,
            }}
            debounceTimeout={ASYNC_MULTI_SELECT_DEBOUNCE}
            onChange={onChange}
            onBlur={onBlur}
            className='react-select-container'
            classNamePrefix='react-select'
            inputId={name}
          />
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};
export default ControlledAsyncSelectInput;
