import {
  ParticipantResponse,
  ParticipantValueTypeEnum,
  PaymentParticipantValue,
  PaymentParticipantValueTypeEnum,
} from '../openapi/arrakis';
import { EnumMap } from '../types';

export const isAgentParticipant = (
  participantType: PaymentParticipantValueTypeEnum | ParticipantValueTypeEnum,
): boolean => {
  const map: EnumMap<PaymentParticipantValueTypeEnum, boolean> = {
    AGENT: true,
    EXTERNAL_ENTITY: false,
  };

  return map[participantType];
};

export const getParticipantName = (
  participant: PaymentParticipantValue | ParticipantResponse,
) =>
  participant?.firstName || participant?.lastName
    ? `${participant?.firstName} ${participant?.lastName}`
    : participant?.company;
