import React from 'react';
import { DateTime } from 'luxon';

export interface DateCellProps {
  date: string | undefined;
}

const DateCell: React.FC<DateCellProps> = ({ date }) => {
  return (
    <span>{date ? DateTime.fromISO(date).toFormat('LL/dd/yy') : 'N/A'}</span>
  );
};

export default DateCell;
