import {
  SearchControllerApi,
  SearchParamOperatorEnum,
  SearchRequest,
  SearchRequestTargetObjectEnum,
} from '../../openapi/yenta';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { resourceTableFetchData } from '../../utils/TableUtils';

export interface TreeNode {
  index: number;
  id: string;
  name: string;
  parent: TreeNode | null;
  isVisible: boolean | false;
  children: TreeNode[];
}

export const createTreeNode = (
  name: string,
  id: string,
  parent: TreeNode | null,
): TreeNode => {
  return {
    index: -1,
    id,
    name,
    parent,
    isVisible: false,
    children: [],
  };
};

export interface Sponsor {
  id: string;
  fullName: string;
}

export interface FetchedAgent {
  id: string;
  firstName: string;
  lastName: string;
}

export const fetchSponsorsForAgent = async (agentID: string) => {
  const api = new SearchControllerApi(getYentaConfiguration());

  const searchRequest: SearchRequest = {
    filterAnd: [
      {
        column: 'id',
        joinColumn: 'sponsor',
        operator: SearchParamOperatorEnum.Eq,
        stringStrictCase: true,
        value: agentID,
      },
    ],
    joinOn: ['sponsor'],
    filterOr: [],
    pageNumber: 1,
    pageSize: 50,
    responseColumns: ['id', 'firstName', 'lastName'],
    targetObject: SearchRequestTargetObjectEnum.Agent,
  };

  return (await api.searchUsingPOST(searchRequest)).data as any;
};

export const getSponsor = async (agentID: string): Promise<Sponsor[]> => {
  let sponsors: Sponsor[] = [];
  const fetchedSponsors = await fetchSponsorsForAgent(agentID);

  for (let i = 0; i < fetchedSponsors.responseRows.length; i++) {
    const [id, firstName, lastName] = fetchedSponsors.responseRows[i];
    const fullName = firstName + ' ' + lastName;

    sponsors.push({ id, fullName });
  }
  return sponsors;
};

export const createTree = async (root: TreeNode): Promise<TreeNode> => {
  let visitedNodes = new Map<string, boolean>();
  let queue: TreeNode[] = [];

  queue.unshift(root);

  while (queue.length > 0) {
    const currentNode = queue.pop();

    if (currentNode) {
      if (visitedNodes.has(currentNode.id)) continue;
      if (currentNode.parent) currentNode.parent.children.push(currentNode);
      visitedNodes.set(currentNode.id, true);

      const sponsors = await getSponsor(currentNode.id);

      for (let i = 0; i < sponsors.length; i++) {
        const currentSponsor = sponsors[i];

        if (!visitedNodes.has(currentSponsor.id)) {
          const nextNode = createTreeNode(
            currentSponsor.fullName,
            currentSponsor.id,
            currentNode,
          );
          queue.unshift(nextNode);
        }
      }
    }
  }

  return root;
};

export const getAllAgents = async (): Promise<FetchedAgent[]> => {
  let hasNext = true;
  let res: FetchedAgent[] = [];
  let pageNumber = 1;

  while (hasNext) {
    const fetchedAgents = await resourceTableFetchData<FetchedAgent>(
      { page: pageNumber++, pageSize: 100 },
      ['firstName', 'lastName', 'createdAt', 'id'],
      [],
      SearchRequestTargetObjectEnum.Agent,
      'yenta',
      {
        filterAnd: [
          {
            column: 'status',
            operator: SearchParamOperatorEnum.Eq,
            value: 'ACTIVE',
          },
        ],
      },
    );
    res.push(...fetchedAgents.data);
    hasNext = false;
  }
  return res;
};

export const getSponsorsTree = async (): Promise<TreeNode> => {
  let tree = createTreeNode('Agent Sponsors', '', null);
  const allAgents = await getAllAgents();
  let promises = [];

  for (let i = 0; i < allAgents.length; i++) {
    const agent = allAgents[i];
    const { id, firstName, lastName } = agent;
    const fullName = firstName + ' ' + lastName;

    promises.push(createTree(createTreeNode(fullName, id, tree)));
  }

  await Promise.all(promises);

  return tree;
};

export const setTreeDefaultVisiblityState = (
  currentNode: TreeNode,
  depth: number = 0,
) => {
  currentNode.isVisible = true;
  if (depth !== 1) {
    currentNode.children.forEach((child: TreeNode) => {
      setTreeDefaultVisiblityState(child, depth + 1);
    });
  }
};

export const indexTreeNodes = (
  currentNode: TreeNode,
  index: number = 0,
): number => {
  currentNode.index = index;

  currentNode.children.forEach((child: TreeNode) => {
    index = indexTreeNodes(child, index + 1);
  });
  return index;
};

export const getTreeToShow = (
  currentNode: TreeNode,
  counterpart: TreeNode,
): TreeNode => {
  for (let i = 0; i < counterpart.children.length; i++) {
    const counterpartChild = counterpart.children[i];
    if (!counterpartChild.isVisible) continue;

    const nextNode = createTreeNode(
      counterpartChild.name,
      counterpartChild.id,
      currentNode,
    );
    nextNode.index = counterpartChild.index;
    currentNode.children.push(getTreeToShow(nextNode, counterpartChild));
  }
  return currentNode;
};

export const getTreeMaxDepth = (tree: TreeNode) => {
  let maxDepth = 0;
  const dfs = (currentNode: TreeNode, currentDepth: number = 0) => {
    for (let i = 0; i < currentNode.children.length; i++)
      dfs(currentNode.children[i], currentDepth + 1);
    maxDepth = Math.max(maxDepth, currentDepth);
  };
  dfs(tree);
  return maxDepth;
};

export const filpVisibilityOfChildNodes = (
  id: string,
  index: number,
  root: TreeNode,
) => {
  // bfs in root
  let queue: TreeNode[] = [];
  queue.unshift(root);

  while (queue.length > 0) {
    const currentNode = queue.pop();

    if (currentNode?.id === id && currentNode.index === index) {
      for (let i = 0; i < currentNode.children.length; i++) {
        const child = currentNode.children[i];
        child.isVisible = !child.isVisible;
      }
      return true;
    } else {
      if (currentNode)
        for (let i = 0; i < currentNode.children.length; i++)
          queue.unshift(currentNode.children[i]);
    }
  }

  return false;
};
