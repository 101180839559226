import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
} from 'victory';
import { find, isMatch } from 'lodash';
import { compactNumber } from '../utils/MathUtils';
import {
  DEFAULT_MEDIUM_FONT,
  DEFAULT_REGULAR_FONT,
} from '../utils/fonts.constants';

export interface BarChartProps<D> {
  data: Array<D>;
  highlightedData?: Array<D>;
  inactiveTintColor?: string;
  activeTintColor?: string;
  xKey: keyof D;
  yKey: keyof D;
  xShowAlternate?: number;
  yShowAlternate?: number;
}

const BarChart = <D extends object>({
  data,
  highlightedData = [],
  xKey,
  yKey,
  inactiveTintColor = '#D2D5DB',
  activeTintColor = '#000',
  xShowAlternate = 1,
  yShowAlternate = 1,
}: BarChartProps<D>) => {
  const shouldHighlight = (index: number | string) =>
    !!find(highlightedData, (highlight) =>
      isMatch(data[parseInt(`${index}`, 10)], highlight),
    );
  const formatYAxisTick = (
    tick: number,
    index: number,
    ticks: Array<number>,
  ) => {
    const oldTick =
      index !== 0
        ? `$${compactNumber(parseFloat(ticks[index - 1].toFixed(2)))}`
        : undefined;
    const newTick = `$${compactNumber(parseFloat(tick.toFixed(2)))}`;
    return index % yShowAlternate === 0 && (index === 0 || oldTick !== newTick)
      ? newTick
      : '';
  };

  return (
    <div className='flex-1 bg-white overflow-hidden'>
      <div className='-mt-8 -mb-3 -mr-8'>
        <VictoryChart theme={VictoryTheme.grayscale} domainPadding={{ x: 30 }}>
          <VictoryBar
            data={data}
            x={`${xKey}`}
            y={`${yKey}`}
            barRatio={0.8}
            cornerRadius={{
              topLeft: 2,
              topRight: 2,
            }}
            style={{
              data: {
                fill: ({ index }) =>
                  shouldHighlight(index) ? activeTintColor : inactiveTintColor,
              },
            }}
          />
          <VictoryAxis
            tickLabelComponent={
              <VictoryLabel
                style={{
                  fill: ({ index }: any) =>
                    shouldHighlight(index)
                      ? activeTintColor
                      : inactiveTintColor,
                  fontFamily: ({ index }) =>
                    shouldHighlight(index)
                      ? DEFAULT_MEDIUM_FONT
                      : DEFAULT_REGULAR_FONT,
                }}
                className='text-xs'
              />
            }
            tickFormat={(tick: string, index: number) =>
              index % xShowAlternate === 0 ? tick : ''
            }
            style={{
              axis: { stroke: inactiveTintColor },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={formatYAxisTick}
            style={{
              axis: { stroke: 'none' },
              tickLabels: {
                fill: inactiveTintColor,
                fontFamily: DEFAULT_REGULAR_FONT,
              },
            }}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

export default BarChart;
