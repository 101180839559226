import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoginRequest } from '../../openapi/keymaker';
import { AppDispatch } from '../../types';
import { showSuccessToast } from '../../slices/ToastNotificationSlice';
import ChangePasswordSection from './ChangePasswordSection';

interface ChangePasswordRouteProps extends RouteComponentProps {}

const ChangePasswordRoute: React.FC<ChangePasswordRouteProps> = ({
  history,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const onChangePasswordSuccess = (_values: LoginRequest) => {
    dispatch(
      showSuccessToast(
        'Password successfully changed.',
        'Please log back in to the mobile app with your new password.',
      ),
    );

    history.push('/welcome-to-rezen/mobile-app');
  };

  return (
    <ChangePasswordSection
      onChangePasswordSuccess={onChangePasswordSuccess}
      title='Building your future, together'
      subtitle='Please enter a new password'
      buttonText='Change Password'
    />
  );
};

export default ChangePasswordRoute;
