import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Avatar } from '../types';

export type AvatarStackSize = 'small' | 'large' | 'default';

export interface AvatarStackProps {
  avatars: Avatar[];
  numToDisplay?: number;
  variant?: AvatarStackSize;
  displayOverflow?: boolean;
}

const AvatarStack: React.FC<AvatarStackProps> = ({
  avatars,
  numToDisplay = 2,
  variant = 'default',
  displayOverflow = true,
}) => {
  const AvatarStackSizeToClassNameMap: { [type in AvatarStackSize]: string } = {
    default: 'h-10 w-10',
    small: 'h-8 w-8',
    large: 'h-12 w-12',
  };

  const marginLeft = numToDisplay === 1 || avatars.length === 1 ? '' : '-ml-4';

  return (
    <div className='flex items-center'>
      <div className='flex'>
        {avatars.slice(0, numToDisplay).map((avatar, index) => {
          return (
            // Since it is possible that we can have multiple avatars with the same
            // initials, and therefore the ordering does matter, I've disabled this
            // eslint rule here.
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${avatar.imageUrl || avatar.text}-${index}`}>
              {avatar.imageUrl ? (
                <img
                  className={classNames(
                    'rounded-full border-4 border-white',
                    marginLeft,
                    AvatarStackSizeToClassNameMap[variant],
                  )}
                  src={avatar.imageUrl}
                  alt=''
                />
              ) : (
                <span
                  className={classNames(
                    'flex items-center justify-center font-semibold text-gray-600  rounded-full bg-gray-200 border-4 border-white ',
                    marginLeft,
                    AvatarStackSizeToClassNameMap[variant],
                  )}
                >
                  {avatar.text}
                </span>
              )}
            </Fragment>
          );
        })}

        {displayOverflow && avatars.length > numToDisplay && (
          <span
            className={classNames(
              'flex items-center justify-center font-semibold text-gray-600  rounded-full bg-gray-200 border-4 border-white -ml-4',
              AvatarStackSizeToClassNameMap[variant],
            )}
          >
            +{avatars.length - numToDisplay}
          </span>
        )}
      </div>
    </div>
  );
};

export default AvatarStack;
