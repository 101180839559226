import React, { useCallback, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility } from '@material-ui/icons';
import DateCell from '../components/table/Cells/DateCell';
import TransactionStateCell from '../components/table/Cells/TransactionStateCell';
import TransactionTypeCell from '../components/table/Cells/TransactionTypeCell';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import { RootState } from '../types';
import { numberToMoney } from '../utils/CurrencyUtils';
import { fetchAgentTransactions } from '../slices/AgentSlice';
import { TransactionResponse } from '../openapi/arrakis';
import IconButton from '../components/IconButton';
import { formatAddress } from '../utils/StringUtils';
import DetailPageLoader from '../components/DetailPageLoader';
import ResourceContainer from '../components/ResourceContainer';

interface Match {
  id: string;
}

interface AgentDetailTransactionsRouteProps
  extends RouteComponentProps<Match> {}

export const columns: Array<Column<TransactionResponse>> = [
  {
    Header: 'Transaction Code',
    accessor: 'code',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => value,
    cardColSize: 7,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    Cell: ({ value }) => <TransactionTypeCell type={value!} />,
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: 'address',
    Cell: ({ value }) => (
      <div className='w-56'>
        {formatAddress(
          value!.street!,
          value!.street2!,
          value!.city!,
          value!.state!,
          value!.zip!,
        )}
      </div>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ value }) => numberToMoney(value?.amount!, 0),
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Representing',
    accessor: 'transactionOwner',
    Cell: ({ value }) => value?.represents,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'lifecycleState',
    Cell: ({ value }) => <TransactionStateCell status={value!.state!} />,
    disableSortBy: true,
  },
  {
    Header: 'Closing Date',
    accessor: 'closingDateActual',
    Cell: ({ value }) => <DateCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Estimated Closing Date',
    accessor: 'closingDateEstimated',
    Cell: ({ value }) => <DateCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },

  {
    Header: 'Gross Commission',
    accessor: 'grossCommission',
    Cell: ({ value }) => numberToMoney(value?.amount!, 0),
    disableSortBy: true,
  },
  // {
  //   Header: 'Participants Split',
  //   accessor: 'participantsSplit',
  //   Cell: ({ value, row }) =>
  //     `${numberToMoney(value)} (${row.original.participantsSplitPercentage}%)`,
  //   Filter: NumberColumnFilter,
  // },
  // {
  //   Header: 'Total Real Split',
  //   accessor: 'commissionSplits',
  //   Cell: ({ value, row }) =>
  //     `${numberToMoney(value)} (${row.original.totalRealSplitPercentage}%)`,
  //   Filter: NumberColumnFilter,
  // },
  {
    Header: 'State',
    accessor: 'address',
    id: 'state',
    Cell: ({ value }) => value?.state!,
    disableSortBy: true,
  },
];

export const columnsWithAction: Array<Column<TransactionResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<TransactionResponse> }) => (
      <Link to={`/transactions/${row.original.id}`}>
        <IconButton leftIcon={<Visibility />} />
      </Link>
    ),
  },
  ...columns,
];

const AgentDetailTransactionsRoute: React.FC<AgentDetailTransactionsRouteProps> = ({
  match,
}) => {
  const agentId = match.params.id;
  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    await dispatch(fetchAgentTransactions(agentId));
  }, [agentId, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    agentDetail: { transactions, loadingDetail, fetchDetailErrorCode },
  } = useSelector((state: RootState) => state);

  const agentTransactions = () => {
    return Promise.resolve({
      data: transactions,
      total: transactions!.length,
    });
  };

  return (
    <ResourceContainer
      loading={loadingDetail}
      isEmpty={!transactions.length}
      errorCode={fetchDetailErrorCode}
      LoaderComponent={<DetailPageLoader />}
      resourceName='Transaction'
    >
      <div className='p-4'>
        <ResourceIndexContainer<TransactionResponse>
          header='Transactions'
          columns={columnsWithAction}
          resourceName='Transaction'
          fetchData={() => agentTransactions()}
          hidePagination
          hideFilters
        />
      </div>
    </ResourceContainer>
  );
};
export default AgentDetailTransactionsRoute;
