import React from 'react';
import { Check } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationStepCompleteMessage from '../../ApplicationStepCompleteMessage';
import ApplicationLayout from '../../ApplicationLayout';
import ApplicationVerticalStepProgress from '../ApplicationVerticalStepProgress';

export interface ApplicationSubmittedSuccessProps extends RouteComponentProps {}

const ApplicationSubmittedSuccess: React.FC<ApplicationSubmittedSuccessProps> = ({
  history,
}) => {
  return (
    <ApplicationLayout>
      <div className='w-full mx-auto px-4 my-10 lg:max-w-6xl gap-10 grid grid-cols-1 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          <ApplicationStepCompleteMessage
            icon={<Check fontSize='large' />}
            header='We have received your request. We’re confirming that your license and board sponsorship transfer has been completed, and we will activate your account shortly.'
            subtitle='For any assistance, please reach out to our customer support team or call us at 1-855-450-0442.'
            onNextStepClicked={() => history.push('/')}
          />
        </div>
        <div className='lg:col-span-2'>
          <ApplicationVerticalStepProgress />
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationSubmittedSuccess;
