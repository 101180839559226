import React from 'react';
import { DateTime } from 'luxon';
import { LicenseResponse } from '../../openapi/yenta';
import ActiveInactiveCell from '../table/Cells/ActiveInactiveCell';
import { ActiveInactiveType } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';

interface AgentLicensesProps {
  licenses?: Array<LicenseResponse>;
}

const AgentLicenses: React.FC<AgentLicensesProps> = ({ licenses }) => {
  return (
    <div className='flex flex-col space-y-2'>
      {licenses?.map((license) => (
        <div className='rounded border' key={license.number}>
          <div className='bg-gray-200 flex flex-row justify-between space-x-3 p-3'>
            <div className='flex flex-col space-y-1'>
              <p className='text-xs uppercase font-primary-medium tracking-wider'>
                License No
              </p>
              <p className='font-primary-medium'>{license.number}</p>
            </div>
            <div className='flex flex-col space-y-1 text-right'>
              <div className='transform scale-75 -mt-1.5 -mb-2 -mr-2.5'>
                <ActiveInactiveCell
                  status={
                    license.active
                      ? ActiveInactiveType.ACTIVE
                      : ActiveInactiveType.INACTIVE
                  }
                />
              </div>
              <p>
                {capitalizeEnum(license.administrativeArea?.stateOrProvince!)}
              </p>
            </div>
          </div>
          <div className='bg-white flex flex-row justify-between space-x-3 p-3'>
            <div className='flex flex-col space-y-1'>
              <p className='text-xs uppercase font-primary-medium tracking-wider'>
                Expires
              </p>
              <p className='font-primary-medium'>
                {DateTime.fromISO(license.expirationDate!).toFormat(
                  'LL/dd/yyyy',
                )}
              </p>
            </div>
            <div className='flex flex-col space-y-1 text-right'>
              <p className='text-xs uppercase font-primary-medium tracking-wider'>
                Complaints
              </p>
              <p className='font-primary-medium'>
                {license.knownComplaints ? 'Yes' : 'No'}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AgentLicenses;
