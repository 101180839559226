import React from 'react';
import { EnumMap } from '../../../types';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { ReferralResponseReferralStatusEnum } from '../../../openapi/arrakis';

interface AgentReferralStatusCellProps {
  status: ReferralResponseReferralStatusEnum;
}

const statusToVariantClassnameMap: EnumMap<
  ReferralResponseReferralStatusEnum,
  PillVariantType
> = {
  SUBMITTED: 'warning',
  DISBURSED: 'success',
  PAYMENT_RECEIVED: 'primary',
};

export const AgentReferralStatusCell: React.FC<AgentReferralStatusCellProps> = ({
  status,
}) => {
  return (
    <Pill
      text={capitalizeEnum(status)}
      variant={statusToVariantClassnameMap[status]}
    />
  );
};

export default AgentReferralStatusCell;
