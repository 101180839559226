import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import ControlledTextInput from '../../components/ControlledTextInput';
import SubmitButton from '../../components/SubmitButton';
import { AuthControllerApi, LoginRequest } from '../../openapi/keymaker';
import RealLogoImg from '../../assets/img/real_logo_blue.png';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import ErrorService from '../../services/ErrorService';
import { PASSWORD_VALIDATIONS } from '../../utils/Validations';
import Route404 from '../Route404';
import { AppDispatch } from '../../types';
import { getKeymakerConfiguration } from '../../utils/OpenapiConfigurationUtils';

interface ChangePasswordSectionProps {
  onChangePasswordSuccess(req: LoginRequest): void;
  title: string;
  subtitle: string;
  buttonText: string;
}

const ChangePasswordSection: React.FC<ChangePasswordSectionProps> = ({
  onChangePasswordSuccess,
  title,
  subtitle,
  buttonText,
}) => {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { passwordToken, emailAddress } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const {
    control,
    handleSubmit,
    errors,
    getValues,
    formState: { isSubmitting, isValid },
  } = useForm<LoginRequest>({ mode: 'onChange' });

  const onSubmit = async (values: LoginRequest) => {
    try {
      const api = await new AuthControllerApi(getKeymakerConfiguration());
      await api.changePasswordUsingPOST({
        password: values.password,
        token: passwordToken as string,
      });

      onChangePasswordSuccess(values);
    } catch (e) {
      ErrorService.notify('Unable to reset password.', e);
      dispatch(showErrorToast(e.response.data.message));
    }
  };

  if (!passwordToken || !emailAddress) {
    return <Route404 />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='bg-white'>
        <div className='container mx-auto h-screen flex items-center justify-center'>
          <div className='lg:w-1/3 p-10'>
            <div className='w-full flex justify-center items-center'>
              <img
                src={RealLogoImg}
                className='h-5 print:h-5'
                alt='Real Logo'
              />
            </div>
            <p className='mt-5 font-primary-medium text-3xl text-center'>
              {title}
            </p>
            <p className='mt-5 font-primary-medium text-xl text-center'>
              {subtitle}
            </p>
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                label='Email'
                name='usernameOrEmail'
                defaultValue={emailAddress}
                placeholder='Enter username or email'
                errors={errors}
                readOnly
                rules={{
                  required: 'Email address is required',
                }}
              />
            </div>
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                type='password'
                label='Password'
                name='password'
                errors={errors}
                rules={PASSWORD_VALIDATIONS}
              />
            </div>
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                type='password'
                label='Password Confirmation'
                name='confirmPassword'
                errors={errors}
                rules={{
                  required: 'Please re-enter your password',
                  validate: (value) =>
                    getValues().password !== value
                      ? 'Passwords do not match'
                      : undefined,
                }}
              />
            </div>
            <SubmitButton
              label={buttonText}
              isSubmitting={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordSection;
