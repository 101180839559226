import React from 'react';
import { numberToMoney } from '../utils/CurrencyUtils';

export interface SignedNumberProps {
  value: number;
}

const SignedNumber: React.FC<SignedNumberProps> = ({ value }) => {
  if (value > 0) {
    return <p className='text-green-500'>{numberToMoney(value)}</p>;
  } else if (value < 0) {
    return <p className='text-red-500'>{numberToMoney(value)}</p>;
  } else {
    return <p className='text-black'>{numberToMoney(value)}</p>;
  }
};

export default SignedNumber;
