import React, { useEffect, useState } from 'react';
import { Route, Link, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row } from 'react-table';
import { Add, EditOutlined } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { RootState } from '../types';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import PageLayout from '../components/PageLayout';
import TeamDetailHeaderSection from '../components/TeamDetailHeaderSection';
import ResourceContainer from '../components/ResourceContainer';
import DetailPageLoader from '../components/DetailPageLoader';
import { getTeamDetailOverview } from '../slices/TeamSlice';
import ProfileImageCell from '../components/table/Cells/ProfileImageCell';
import TeamAgentNameCell from '../components/table/Cells/TeamAgentNameCell';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import NumberColumnFilter from '../components/table/Filters/NumberColumnFilter';
import {
  TeamMemberResponse,
  TeamResponseTypeEnum,
  UserPreviewResponse,
} from '../openapi/yenta';
import IconButton from '../components/IconButton';
import AddTeamMember from '../components/team/AddTeamMember';
import DeleteTeamMemberModal from '../components/team/DeleteTeamMemberModal';
import { isTeamLeader } from '../utils/TeamHelper';
import { capitalizeEnum, formatPhoneNumber } from '../utils/StringUtils';
import UpdateTeamMembersCommissionSplitSidebarModal from '../components/team/UpdateTeamMembersCommissionSplitSidebarModal';
import TeamTransactionsRoute from './TeamTransactionsRoute';

interface Match {
  id: string;
}

interface TransactionsTableContainerProps extends RouteComponentProps<Match> {}

export const columns: Array<Column<TeamMemberResponse>> = [
  {
    accessor: 'agent',
    id: 'id',
    Cell: ({ value, row }) => (
      <Link
        to={`/people/${row.original.agent?.id}`}
        data-testid={value?.avatar}
      >
        <ProfileImageCell
          name={row.original.agent?.fullName!}
          imageUrl={value?.avatar}
        />
      </Link>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
    cardColSize: 2,
  },
  {
    Header: 'Name',
    accessor: 'agent',
    id: 'name',
    Cell: ({ value, row }) => (
      <Link to={`/people/${row.original.agent?.id}`}>
        <TeamAgentNameCell
          name={value?.fullName!}
          role={row.original.roles![0]}
        />
      </Link>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
    cardColSize: 10,
  },
  {
    Header: 'Role',
    accessor: 'roles',
    Cell: ({ value }) => value!.map((val) => capitalizeEnum(val)).join(', '),
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Phone Number',
    accessor: 'agent',
    id: 'phoneNumber',
    Cell: ({ value }) => formatPhoneNumber(value?.phoneNumber) || 'N/A',
    Filter: TextColumnFilter,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Email',
    accessor: 'agent',
    id: 'emailAddress',
    Cell: ({ value }) => value?.emailAddress,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Commissions',
    accessor: 'memberCommissionSplit',
    Cell: ({ value }) => `${value}% / ${100 - value!}%`,
    Filter: NumberColumnFilter,
    disableSortBy: true,
  },
];

const TeamDetailRoute: React.FC<TransactionsTableContainerProps> = ({
  match,
  location,
}) => {
  const dispatch = useDispatch();
  const teamId = match.params.id;
  const { teamDetailOverview, loading, fetchErrorCode } = useSelector(
    (state: RootState) => state.team,
  );
  const [openAddMemberForm, setOpenAddMemberForm] = useState<boolean>(false);
  const [memberToDelete, setMemberToDelete] = useState<UserPreviewResponse>();
  const [
    editingTeamMember,
    setEditingTeamMember,
  ] = useState<null | TeamMemberResponse>(null);

  useEffect(() => {
    dispatch(getTeamDetailOverview(teamId));
  }, [dispatch, teamId]);
  const path = [
    { title: 'Home', url: '/' },
    { title: 'Team', url: '/teams' },
  ];

  if (teamDetailOverview) {
    path.push({
      title: `${teamDetailOverview.name}`,
      url: `/teams/${teamDetailOverview.id}`,
    });

    if (location.pathname.includes('transactions')) {
      path.push({
        title: 'Transactions',
        url: `/teams/${teamDetailOverview.id}/transactions`,
      });
    }
  }

  const teamAgentsPagination = () => {
    return Promise.resolve({
      data: teamDetailOverview?.agents!,
      total: teamDetailOverview!.agents!.length,
    });
  };

  const showEditCommissionSplit = [
    TeamResponseTypeEnum.Platinum,
    TeamResponseTypeEnum.Normal,
  ].includes(teamDetailOverview?.type!);

  const columnsWithActions: Array<Column<TeamMemberResponse>> = [
    ...columns,
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }: { row: Row<TeamMemberResponse> }) => (
        <div className='flex flex-nowrap space-x-3'>
          {showEditCommissionSplit && !isTeamLeader(row.original) && (
            <IconButton
              leftIcon={<EditOutlined fontSize='small' titleAccess='Edit' />}
              onClick={() => setEditingTeamMember(row.original)}
            />
          )}
          {!isTeamLeader(row.original) && (
            <IconButton
              variant='danger'
              label='Remove'
              leftIcon={<DeleteIcon />}
              onClick={() => setMemberToDelete(row.original.agent)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <ResourceContainer
      loading={loading}
      isEmpty={!teamDetailOverview}
      errorCode={fetchErrorCode}
      LoaderComponent={<DetailPageLoader />}
      resourceName='team detail'
    >
      <PageLayout path={path}>
        {!!teamDetailOverview && (
          <TeamDetailHeaderSection teamDetailOverview={teamDetailOverview} />
        )}
        <Route path={`${match.path}`} exact>
          <div className='px-4 pt-5 pb-10'>
            <ResourceIndexContainer<TeamMemberResponse>
              header='Agents'
              columns={columnsWithActions}
              resourceName='Team'
              fetchData={() => teamAgentsPagination()}
              hidePagination
              hideFilters
              RightComponent={
                <IconButton
                  leftIcon={<Add fontSize='small' />}
                  label='Add member'
                  variant='default'
                  onClick={() => setOpenAddMemberForm(true)}
                />
              }
            />
          </div>
          <AddTeamMember
            isOpen={openAddMemberForm}
            onClose={() => setOpenAddMemberForm(false)}
            teamId={teamDetailOverview?.id!}
            teamType={teamDetailOverview?.type!}
          />
          <DeleteTeamMemberModal
            teamId={teamDetailOverview?.id!}
            memberToDelete={memberToDelete}
            onClose={() => setMemberToDelete(undefined)}
          />
          {!!teamDetailOverview && (
            <UpdateTeamMembersCommissionSplitSidebarModal
              team={teamDetailOverview}
              member={editingTeamMember}
              onClose={() => setEditingTeamMember(null)}
              teamType={teamDetailOverview?.type!}
            />
          )}
        </Route>
        <Route
          path={`${match.path}/transactions`}
          component={TeamTransactionsRoute}
          exact
        />
      </PageLayout>
    </ResourceContainer>
  );
};

export default TeamDetailRoute;
