import { times } from 'lodash';
import { Escrow, IPaginateRes } from '../../../types';
import {
  fakeDate,
  fakeNumber,
  fakePersonFullName,
  fakeProfileImage,
  randomEnum,
  fakeWords,
} from '../../../testUtils/FakerUtils';
import { TransactionLifecycleStateValueStateEnum } from '../../../openapi/arrakis';

export const DefaultEscrowPaginationResponse: IPaginateRes<Escrow> = {
  data: times<Escrow>(3, (id) => ({
    id: id + 1,
    amount: fakeNumber({ min: 1000, max: 100000 }),
    status: randomEnum<TransactionLifecycleStateValueStateEnum>(
      TransactionLifecycleStateValueStateEnum,
    ),
    date: fakeDate().toISODate(),
    info: fakeWords(10),
    updatedBy: {
      name: fakePersonFullName(),
      photo: fakeProfileImage(),
    },
  })),
  total: fakeNumber({ min: 10, max: 20 }),
};
