import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { last } from 'lodash';
import { fetchFrontLineForAgent } from '../../slices/NetworkSlice';
import { NetworkResponse, RootState } from '../../types';
import ResourceContainer from '../ResourceContainer';
import DetailPageLoader from '../DetailPageLoader';
import SidebarModal from '../SidebarModal';
import NetworkHeader from './NetworkHeader';
import NetworkRow from './NetworkRow';
import BreadCrumbsWithActions from './BreadCrumbsWithActions';

interface NetworkProps {
  isOpen: boolean;
  onClose(): void;
  selectAgents: NetworkResponse[];
}

const Network: React.FC<NetworkProps> = ({ isOpen, onClose, selectAgents }) => {
  const dispatch = useDispatch();
  const { networksById, loading, fetchErrorCode } = useSelector(
    (state: RootState) => state.network,
  );
  const [tier, setTier] = useState<number>(selectAgents.length);
  const [currentAgent, setCurrentAgent] = useState<NetworkResponse>(
    last(selectAgents)!,
  );
  const [agents, setAgents] = useState<NetworkResponse[]>([...selectAgents]);

  useEffect(() => {
    dispatch(fetchFrontLineForAgent(currentAgent.id));
  }, [currentAgent.id, dispatch]);

  return (
    <SidebarModal
      title='My Network'
      subtitle='List of people in your network'
      isOpen={isOpen}
      onClose={onClose}
    >
      <BreadCrumbsWithActions
        paths={[
          {
            title: 'My Network',
          },
          ...agents.map((agent: NetworkResponse, i: number) => ({
            title: `${agent.firstName} ${agent.lastName}`,
            onClick: () => {
              setCurrentAgent(agent);
              setTier(i + 1);
              setAgents(agents.slice(0, i + 1));
            },
          })),
        ]}
      />
      <NetworkHeader
        name={`${currentAgent.firstName} ${currentAgent.lastName}`}
        imageUrl={currentAgent.avatar!}
        tier={tier}
      />
      <ResourceContainer
        loading={loading}
        isEmpty={!networksById[currentAgent.id]?.length}
        errorCode={fetchErrorCode}
        LoaderComponent={<DetailPageLoader />}
        resourceName='sponsor agent'
        emptyMessage={`${currentAgent.firstName} ${currentAgent.lastName} has not referred any other agents.`}
      >
        <div className='divide-y divide-gray-200'>
          {networksById[currentAgent.id]?.map((agent) => (
            <div key={agent.id} className='px-4'>
              <NetworkRow
                name={`${agent.firstName} ${agent.lastName}`}
                imageUrl={agent.avatar!}
                tier={tier}
                sizeOfNetwork={agent.sizeOfNetwork!}
                onClick={() => {
                  setCurrentAgent(agent);
                  setTier(tier + 1);
                  setAgents([...agents, agent]);
                }}
              />
            </div>
          ))}
        </div>
      </ResourceContainer>
    </SidebarModal>
  );
};

export default Network;
