import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppDispatch,
  ErrorCode,
  NetworkResponse,
  NetworkState,
} from '../types';
import {
  SearchControllerApi,
  SearchParamOperatorEnum,
  SearchRequest,
  SearchRequestTargetObjectEnum,
} from '../openapi/yenta';
import { hydrateSearchResponse } from '../utils/TableUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: NetworkState = {
  loading: true,
  networksById: {},
  fetchErrorCode: null,
};

const NetworkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    errorFetching(state, action: PayloadAction<ErrorCode>) {
      state.fetchErrorCode = action.payload;
    },
    saveNetworksById(
      state,
      action: PayloadAction<{ finalData: NetworkResponse[]; id: string }>,
    ) {
      state.networksById[action.payload.id] = action.payload.finalData;
      state.fetchErrorCode = null;
    },
  },
});

export const {
  changeLoading,
  errorFetching,
  saveNetworksById,
} = NetworkSlice.actions;

export const fetchFrontLineForAgent = (id: string) => async (
  dispatch: AppDispatch,
) => {
  dispatch(changeLoading(true));

  try {
    const searchRequest: SearchRequest = {
      filterAnd: [
        {
          column: 'id',
          joinColumn: 'sponsor',
          operator: SearchParamOperatorEnum.Eq,
          stringStrictCase: true,
          value: id,
        },
      ],
      filterOr: [],
      joinOn: ['sponsor'],
      order: [],
      pageNumber: 1,
      pageSize: 20,
      responseColumns: [
        'id',
        'firstName',
        'lastName',
        'avatar',
        'sizeOfNetwork',
      ],
      targetObject: SearchRequestTargetObjectEnum.Agent,
    };

    const { data } = await new SearchControllerApi(
      await getYentaConfiguration(),
    ).searchUsingPOST(searchRequest);

    const finalData = hydrateSearchResponse<NetworkResponse>(data);
    dispatch(saveNetworksById({ finalData, id }));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent sponsor detail', e, {
      agent: { id },
    });
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoading(false));
  }
};

export default NetworkSlice.reducer;
