import React from 'react';
import { Check } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationStepCompleteMessage from '../../ApplicationStepCompleteMessage';
import ApplicationLayout from '../../ApplicationLayout';
import ApplicationVerticalStepProgress from '../ApplicationVerticalStepProgress';

export interface ApplicationFeeSuccessProps extends RouteComponentProps {}

const ApplicationFeeSuccess: React.FC<ApplicationFeeSuccessProps> = ({
  history,
}) => {
  return (
    <ApplicationLayout>
      <div className='w-full mx-auto px-4 my-10 lg:max-w-6xl gap-10 grid grid-cols-1 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          <ApplicationStepCompleteMessage
            icon={<Check fontSize='large' />}
            header='Thank you, we’ve received your payment.'
            nextStepName='Transfer License '
            onNextStepClicked={() =>
              history.push('/onboarding/application-transfer-license')
            }
          />
        </div>
        <div className='lg:col-span-2'>
          <ApplicationVerticalStepProgress />
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationFeeSuccess;
