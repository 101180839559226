import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SidebarModal from '../SidebarModal';
import ControlledTextInput from '../ControlledTextInput';
import {
  CreateCommissionAdvanceRequest,
  MoneyValueCurrencyEnum,
  TransactionPreviewResponse,
} from '../../openapi/arrakis';
import { addAgentCommissionAdvances } from '../../slices/AgentSlice';
import ControlledFileUploadInput from '../ControlledFileUploadInput';
import ControlledSelectInput from '../ControlledSelectInput';
import { AgentResponse } from '../../openapi/yenta';
import Button from '../Button';
import { EMAIL_VALIDATIONS } from '../../utils/Validations';

interface AddAgentCommissionAdvancesSidebarFormProps {
  isOpen: boolean;
  onClose(): void;
  agent: AgentResponse;
  openTransactions: TransactionPreviewResponse[];
}

interface FormData {
  transactionId: string;
  amount: number;
  companyName: string;
  companyEmail: string;
  agreementPath: File[];
  notes: string;
}

const AddAgentCommissionAdvancesSidebarForm: React.FC<AddAgentCommissionAdvancesSidebarFormProps> = ({
  isOpen,
  onClose,
  agent,
  openTransactions,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    const createCommissionAdvanceRequest: CreateCommissionAdvanceRequest = {
      amount: { amount: data.amount, currency: MoneyValueCurrencyEnum.Usd },
      companyName: data.companyName,
      companyEmail: data.companyEmail,
      transactionId: data.transactionId,
      notes: data.notes,
    };

    await dispatch(
      addAgentCommissionAdvances(
        agent?.id!,
        createCommissionAdvanceRequest,
        data.agreementPath[0],
      ),
    );

    onClose();
  };

  return (
    <SidebarModal
      title='Add a Commission Advance'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col justify-between h-full'
      >
        <div className='px-4'>
          <div className='mt-5'>
            <ControlledSelectInput
              name='transactionId'
              control={control}
              errors={errors}
              label='Transaction'
              placeholder=''
              options={
                openTransactions?.length === 0
                  ? [
                      {
                        label: `No Open Transactions`,
                        value: '',
                        disabled: true,
                      },
                    ]
                  : [
                      {
                        label: `Select Transaction`,
                        value: '',
                        disabled: true,
                      },
                      ...openTransactions?.map((transaction) => ({
                        value: `${transaction.id}`,
                        label: `${transaction.address?.oneLine} - ${transaction.code}`,
                      })),
                    ]
              }
              rules={{
                required: 'Transaction is required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              errors={errors}
              label='Amount in USD'
              name='amount'
              rules={{ required: 'Amount is required' }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              errors={errors}
              label='Name of Company'
              name='companyName'
              rules={{ required: 'Name of Company is required' }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              errors={errors}
              label='Email'
              name='companyEmail'
              rules={{
                required: 'Email is required',
                ...EMAIL_VALIDATIONS,
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledFileUploadInput
              control={control}
              errors={errors}
              label='Agreement'
              name='agreementPath'
              rules={{ required: 'Agreement is required' }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              errors={errors}
              label='Notes (Optional)'
              name='notes'
            />
          </div>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Add'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={onClose}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default AddAgentCommissionAdvancesSidebarForm;
