import { values } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppDispatch } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import Button from '../Button';
import ControlledSelectInput from '../ControlledSelectInput';
import ControlledTextAreaInput from '../ControlledTextAreaInput';
import SidebarModal from '../SidebarModal';
import {
  MonitorEventResponse,
  MonitorEventResponseInvestigationStatusEnum,
  MonitorEventsControllerApi,
} from '../../openapi/arrakis';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import ErrorService from '../../services/ErrorService';
import { showApiErrorModal } from '../../slices/ErrorSlice';

interface EditMonitoringEventProps extends RouteComponentProps {
  isOpen: boolean;
  onClose(): void;
  eventData: MonitorEventResponse | undefined;
}

const EditMonitoringEvent: React.FC<EditMonitoringEventProps> = ({
  isOpen,
  onClose,
  eventData,
}) => {
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const dispatch: AppDispatch = useDispatch();

  const onSubmit = async (values: any) => {
    try {
      await new MonitorEventsControllerApi(
        getArrakisConfiguration(),
      ).updateMonitorEventUsingPUT(eventData?.id!, {
        notes: values.notes,
        investigationStatus: values.investigationStatus,
      });
      onClose();
      dispatch(showSuccessToast('Monitor event saved successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error saving monitor event', e, {
        eventData: eventData!,
      });
      dispatch(
        showErrorToast(
          'We had a problem updating event',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <SidebarModal
      title='Edit Event'
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='event-form'
      >
        <div className='p-4'>
          <div>
            <ControlledTextAreaInput
              control={control}
              label='Notes'
              name='notes'
              placeholder='Notes'
              errors={errors}
              defaultValue={eventData?.notes}
              rows={2}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              control={control}
              errors={errors}
              name='investigationStatus'
              label='Investigation Status'
              options={[
                {
                  label: `Select Status`,
                  value: '',
                  disabled: true,
                },
                ...values(MonitorEventResponseInvestigationStatusEnum).map(
                  (c) => ({
                    value: c,
                    label: capitalizeEnum(c),
                  }),
                ),
              ]}
              defaultValue={eventData?.investigationStatus}
              rules={{
                required: 'Required',
              }}
            />
          </div>
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200  bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2  w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Update'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default withRouter(EditMonitoringEvent);
