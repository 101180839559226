import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ControlledSelectInput from '../ControlledSelectInput';
import {
  ParticipantValue,
  PaymentParticipantValue,
  TransactionResponse,
  UpdateParticipantRequest,
  UpdateParticipantRequestParticipantRoleEnum,
} from '../../openapi/arrakis';
import Button from '../Button';
import SidebarModal from '../SidebarModal';
import { updateTransactionParticipant } from '../../slices/TransactionSlice';
import { AppDispatch } from '../../types';

interface ChangeCdPayerSidebarModalProps {
  isOpen: boolean;
  transaction: TransactionResponse;
  onClose(): void;
}

const ChangeCdPayerSidebarModal: React.FC<ChangeCdPayerSidebarModalProps> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const cdPayer = transaction.cdPayer;
  const allParticipants: Array<ParticipantValue | PaymentParticipantValue> = [
    transaction.cdPayer!,
    ...transaction.otherParticipants!,
    ...transaction.paymentParticipants!,
  ].filter((p) => !!p);
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (value: { payer: string }) => {
    const participant:
      | ParticipantValue
      | PaymentParticipantValue
      | undefined = allParticipants.find((p) =>
      !value.payer ? p.commissionDocumentPayer : p.id === value.payer,
    );

    if (participant) {
      const data: UpdateParticipantRequest = {
        emailAddress: participant?.emailAddress,
        firstName: participant?.firstName,
        lastName: participant?.lastName,
        phoneNumber: participant?.phoneNumber,
        commissionDocumentRecipient: !!participant?.commissionDocumentRecipient,
        payer: !!value.payer,
        passThrough: !!participant?.passThrough,
        paidByReal: !!participant?.paidByReal,
        personalDeal:
          'personalDeal' in participant! ? !!participant.personalDeal : false,
        participantRole: (participant.role as unknown) as UpdateParticipantRequestParticipantRoleEnum,
        address: participant?.address,
        company: participant?.company || '',
      };

      const success = await dispatch(
        updateTransactionParticipant(participant?.id!, transaction.id!, data),
      );
      if (success) {
        onClose();
      }
    }
  };

  return (
    <SidebarModal
      title='Change Commission Payer'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <div className='mt-5'>
            <ControlledSelectInput
              name='payer'
              control={control}
              errors={errors}
              label='Commission Payer'
              options={[
                {
                  label: 'none',
                  value: '',
                  disabled: true,
                },
                ...allParticipants.map((participant) => ({
                  value: participant.id!,
                  label: `${participant.firstName} ${participant.lastName}`,
                })),
              ]}
              defaultValue={cdPayer?.id}
            />
          </div>
        </div>
        <div className='p-4 bg-white absolute border-t border-gray-200 bottom-0 space-x-5 w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='reset'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default ChangeCdPayerSidebarModal;
