import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  EscrowRequest,
  EscrowResponse,
  MoneyValueCurrencyEnum,
} from '../../openapi/arrakis';
import {
  createEscrow,
  updateEscrowDetails,
} from '../../slices/TransactionSlice';
import {
  formatAddressObject,
  MONEY_AMOUNT_REGEX,
} from '../../utils/StringUtils';
import Button from '../Button';
import ControlledTextInput from '../ControlledTextInput';
import GridCard from '../GridCard';
import SidebarModal from '../SidebarModal';
import { OfficeResponse } from '../../openapi/yenta';

interface EscrowAddFormProps {
  isOpen: boolean;
  onClose(): void;
  escrowDetails?: EscrowResponse;
  transactionId: string;
  officeDetails: OfficeResponse;
}

const EscrowAddForm: React.FC<EscrowAddFormProps> = ({
  isOpen,
  onClose,
  escrowDetails,
  transactionId,
  officeDetails,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: escrowDetails,
  });

  const onSubmit = async (data: any) => {
    if (escrowDetails) {
      const finalData: EscrowRequest = {
        amount: {
          currency: escrowDetails?.amount?.currency,
          amount: data.amount,
        },
        info: data.info,
      };
      await dispatch(updateEscrowDetails(finalData, escrowDetails?.id!));
    } else {
      const finalData: EscrowRequest = {
        amount: {
          currency: MoneyValueCurrencyEnum.Usd,
          amount: data.amount,
        },
        info: data.info,
      };
      await dispatch(createEscrow(transactionId, finalData));
    }
  };
  return (
    <SidebarModal
      title='Escrow Details'
      subtitle='Add details about the escrow'
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='escrow-form'
      >
        <div className='p-4'>
          <div>
            <ControlledTextInput
              control={control}
              label='Enter Amount *'
              name='amount'
              placeholder='2000'
              errors={errors}
              rules={{
                required: 'Please enter a amount',
                pattern: {
                  value: MONEY_AMOUNT_REGEX,
                  message: 'Please enter a valid amount',
                },
              }}
              defaultValue={escrowDetails?.amount?.amount!}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              name='info'
              control={control}
              errors={errors}
              label='Additional Info (Optional)'
              placeholder='Your notes'
              defaultValue={escrowDetails?.info}
            />
          </div>
          <div className='mt-5'>
            <p className='text-base text-gray-800 font-primary-medium'>
              Escrow Account Details
            </p>
            <div className='bg-gray-100 mt-1'>
              <GridCard
                items={[
                  {
                    name: 'Account Details',
                    value: (
                      <div className='flex flex-col'>
                        <p>{officeDetails?.trustAccount?.accountName}</p>
                        <p>{officeDetails?.trustAccount?.bankName}</p>
                        <p>
                          {officeDetails?.trustAccount?.bankAddress &&
                            formatAddressObject(
                              officeDetails.trustAccount?.bankAddress,
                            )}
                        </p>
                      </div>
                    ),
                  },
                  {
                    name: 'Routing number/ transit number',
                    value: `${officeDetails?.trustAccount?.bankRoutingNumber}`,
                  },
                  {
                    name: 'Account Number',
                    value: `${officeDetails?.trustAccount?.accountNumber}`,
                  },
                ]}
                colCount={1}
              />
            </div>
          </div>
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={onClose}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EscrowAddForm;
