import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { TeamResponse } from '../../openapi/yenta';
import { updateTeam } from '../../slices/TeamSlice';
import Button from '../Button';
import ControlledTextInput from '../ControlledTextInput';
import SidebarModal from '../SidebarModal';

interface EditTeamFormProps {
  isOpen: boolean;
  onClose(): void;
  team: TeamResponse;
}

const EditTeamForm: React.FC<EditTeamFormProps> = ({
  isOpen,
  onClose,
  team,
}) => {
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const dispatch = useDispatch();
  const onSubmit = async (data: any) => {
    await dispatch(updateTeam(team.id!, { name: data.name }));
    onClose();
  };

  return (
    <SidebarModal
      title='Edit Team'
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-team-form'
      >
        <div className='p-4'>
          <div>
            <ControlledTextInput
              control={control}
              errors={errors}
              name='name'
              placeholder='Team Name'
              label='Name'
              defaultValue={team.name}
              rules={{
                required: 'Required',
              }}
            />
          </div>
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200  bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2  w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Update'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EditTeamForm;
