import {
  AddressResponseStateOrProvinceEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  OfficePreviewResponseTransactionTypeEnum,
} from '../openapi/yenta';
import {
  EnumMap,
  ParticipantRole,
  ParticipantType,
  WorksWithType,
} from '../types';

const transactionTypeMap: EnumMap<
  OfficePreviewResponseTransactionTypeEnum,
  string
> = {
  SINGLE_CHECK: 'Single Check',
  SPLIT_CHECK: 'Split Check',
};

export const transactionDisbursementTypeToDisplayName = (
  transactionType: OfficePreviewResponseTransactionTypeEnum,
) => {
  return transactionTypeMap[transactionType];
};

const participantTypeMap: EnumMap<ParticipantType, string> = {
  AGENT: 'Agent',
  EXTERNAL_ENTITY: 'External Entity',
};

export const participantTypeToDisplayName = (
  participantType: ParticipantType,
) => {
  return participantTypeMap[participantType];
};

const participantRoleMap: EnumMap<ParticipantRole, string> = {
  SELLERS_AGENT: "Seller's Agent",
  BUYERS_AGENT: "Buyer's Agent",
  BUYER: 'Buyer',
  CLOSER: 'Closer',
  SELLER: 'Seller',
};

export const participantRoleToDisplayName = (
  participantRole: ParticipantRole,
) => {
  return participantRoleMap[participantRole];
};

export const WorksWithTypeMap: EnumMap<WorksWithType, string> = {
  BUYERS: 'Buyers',
  SELLERS: 'Sellers',
  BUYERS_SELLERS: 'Buyers and Sellers',
};

export const WorksWithTypeToDisplayName = (WorksWithType: WorksWithType) => {
  return WorksWithTypeMap[WorksWithType];
};

export const StateOrProvinceMap: EnumMap<
  AdministrativeAreaResponseStateOrProvinceEnum,
  string
> = {
  ALABAMA: 'Alabama',
  ALASKA: 'Alaska',
  ALBERTA: 'Alberta',
  ARIZONA: 'Arizona',
  ARKANSAS: 'Arkansas',
  BRITISH_COLUMBIA: 'British Columbia',
  CALIFORNIA: 'California',
  COLORADO: 'Colorado',
  CONNECTICUT: 'Connecticut',
  DELAWARE: 'Delaware',
  DISTRICT_OF_COLUMBIA: 'District Of Columbia',
  FLORIDA: 'Florida',
  GEORGIA: 'Georgia',
  HAWAII: 'Hawaii',
  IDAHO: 'Idaho',
  ILLINOIS: 'Illinois',
  INDIANA: 'Indiana',
  IOWA: 'Iowa',
  KANSAS: 'Kansas',
  KENTUCKY: 'Kentucky',
  LOUISIANA: 'Louisiana',
  MAINE: 'Maine',
  MANITOBA: 'Manitoba',
  MARYLAND: 'Maryland',
  MASSACHUSETTS: 'Massachusetts',
  MICHIGAN: 'Michigan',
  MINNESOTA: 'Minnesota',
  MISSISSIPPI: 'Mississippi',
  MISSOURI: 'Missouri',
  MONTANA: 'Montana',
  NEBRASKA: 'Nebraska',
  NEVADA: 'Nevada',
  NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland And Labrador',
  NEW_BRUNSWICK: 'New Brunswick',
  NEW_HAMPSHIRE: 'New Hampshire',
  NEW_JERSEY: 'New Jersey',
  NEW_MEXICO: 'New Mexico',
  NEW_YORK: 'New York',
  NORTH_CAROLINA: 'North Carolina',
  NORTH_DAKOTA: 'North Dakota',
  NOVA_SCOTIA: 'Nova Scotia',
  OHIO: 'Ohio',
  OKLAHOMA: 'Oklahoma',
  ONTARIO: 'Ontario',
  OREGON: 'Oregon',
  PENNSYLVANIA: 'Pennsylvania',
  PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
  PUERTO_RICO: 'Puerto Rico',
  QUEBEC: 'Quebec',
  RHODE_ISLAND: 'Rhode Island',
  SASKATCHEWAN: 'Saskatchewan',
  SOUTH_CAROLINA: 'South Carolina',
  SOUTH_DAKOTA: 'South Dakota',
  TENNESSEE: 'Tennessee',
  TEXAS: 'Texas',
  UTAH: 'Utah',
  VERMONT: 'Vermont',
  VIRGINIA: 'Virginia',
  WASHINGTON: 'Washington',
  WEST_VIRGINIA: 'West Virginia',
  WISCONSIN: 'Wisconsin',
  WYOMING: 'Wyoming',
};

export const StateOrProvinceToDisplayName = (
  AdministrativeAreaResponseStateOrProvinceEnum:
    | AddressResponseStateOrProvinceEnum
    | AdministrativeAreaRequestStateOrProvinceEnum
    | AdministrativeAreaResponseStateOrProvinceEnum,
) => {
  return StateOrProvinceMap[AdministrativeAreaResponseStateOrProvinceEnum];
};
