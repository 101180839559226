import {
  SearchResponse,
  SearchResponseTargetObjectEnum,
} from '../../openapi/yenta';

export const DefaultAgentsPaginationResponse: Required<SearchResponse> = {
  pageNumber: 1,
  pageSize: 20,
  targetObject: SearchResponseTargetObjectEnum.Agent,
  responseColumns: ['id', 'firstName', 'lastName'],
  params: [],
  responseRows: [
    ['81c023e5-3e36-499a-b8cb-5dfea80b5ec5', 'Travis', 'Busbee'],
    ['1a2edc09-c446-4080-9e25-1731f8f99cf2', 'Hunter', 'D’Andraia'],
    ['1003c2b8-80fd-4487-b560-202f81a65348', 'Lynzara', 'Shalit'],
    ['2b321ef5-7e3f-420c-8634-9760069d92d0', 'Eric N', 'Nyarko'],
    ['cff2c591-a837-4ce0-84c8-f0715dc70bb1', 'Jungje', 'Jo'],
    ['8e44e97d-4635-40fc-ab49-3ce02b2140ae', 'Olga', 'Cisneros'],
    ['8de82771-b8a6-4b67-90b3-30e844c7a053', 'Michael', 'Rose'],
    ['cdf9f546-a419-4f07-8387-a555215627d4', 'Scott', 'Bosmans'],
    ['662c2685-3022-49a1-ade5-86b659de96ae', 'Stevi', 'Chadwick'],
    ['88d1ebd3-66c3-4fb4-888e-4165253e41fc', 'Robert', 'Paiz'],
    ['e895fa82-f2a0-4ac3-83f1-de1087cad2f8', 'Matthew', 'Reyna'],
    ['4193e796-044e-4dd9-95aa-7bcaed12674e', 'Kelly', 'Nicholls'],
    ['a2ff84d6-e697-4afc-a996-2d872996cab5', 'Tiffany', 'Kennard'],
    ['bbd03ed0-cb12-4a96-9481-d4660ffbb1a1', 'Chance', 'Pircher'],
    ['4e85eb79-ab59-4049-9f58-751abdb96c53', 'Anita', 'Frank'],
    ['27c731d8-ff38-4a25-a5e0-4a86ed85b535', 'Kim', 'Brown'],
    ['17b6b822-2713-4868-b23e-2164d5e936c0', 'Mamie Jean', 'Yoder'],
    ['2c220a16-3594-4e27-846f-78259342f8b7', 'Bobby', 'Anderson'],
    ['5bb2ecb4-f233-42f5-8154-8b9b60af8267', 'Krista', 'Frauenholtz'],
    ['da9f7334-4400-4b73-a204-07ccb5371308', 'Claudia', 'Hadjez'],
  ],
  hasNext: true,
};
