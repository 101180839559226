import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { RootState } from '../types';
import { FormComponentProps } from '../utils/FormUtils';
import ImgLogo from '../assets/img/real_logo_blue.png';
import { NUMBER_REGEX } from '../utils/StringUtils';
import TamirsSignatureImg from '../assets/img/tamirs-signature-loi.png';
import ControlledDatePickerInput from './ControlledDatePickerInput';
import HighlightedDiv from './HighlightedDiv';
import ControlledTextInput from './ControlledTextInput';
import ControlledSignatureInput from './ControlledSignatureInput';
import SubmitButton from './SubmitButton';

interface LetterOfIntentFormProps extends FormComponentProps {}

const LetterOfIntentForm: React.FC<LetterOfIntentFormProps> = ({
  onSubmit,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm();

  return (
    <div className='bg-white lg:p-24 p-5 text-justify font-arial text-center'>
      <img src={ImgLogo} className='h-8' alt='Real Logo' />
      <h1 className='text-2xl lg:text-5xl my-10'>
        Letter of Intent to Join Real
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='my-10'>
          <div className='block lg:inline-block max-w-xs lg:mx-2'>
            <ControlledDatePickerInput
              control={control}
              label='Date'
              errors={errors}
              name='date'
              defaultValue={DateTime.local().toISODate()}
              readOnly
            />
          </div>
        </div>
        <div>
          My name is
          <div className='block lg:inline-block lg:mx-2 lg:mt-2'>
            <ControlledTextInput
              label='Name'
              name='name'
              errors={errors}
              control={control}
              defaultValue={userDetail?.fullName}
              readOnly
            />
          </div>
          and I am a resident in
          <div className='block lg:inline-block lg:ml-2 lg:mt-2'>
            <HighlightedDiv>
              <ControlledTextInput
                errors={errors}
                control={control}
                name='residentIn'
                label='Location'
                rules={{
                  required: 'Location is required',
                }}
              />
            </HighlightedDiv>
          </div>
          . I am signing this letter of intent (LOI) to express my intent to
          join Real The Real Brokerage Inc. within
          <div className='block lg:inline-block lg:mx-2 lg:mt-2'>
            <HighlightedDiv>
              <ControlledTextInput
                errors={errors}
                control={control}
                name='numDays'
                label='Days'
                defaultValue='90'
                readOnly
                rules={{
                  required: 'Days is required',
                  pattern: {
                    value: NUMBER_REGEX,
                    message: 'Invalid Days',
                  },
                }}
              />
            </HighlightedDiv>
          </div>
          days.
        </div>
        <p className='mt-10'>
          I may sponsor agents to Real before I join. I understand that after I
          join Real, I will be eligible for a revenue share on any agents I
          sponsor to join Real from today’s date until I join (the LOI period),
          per the terms published in Real’s revenue sharing plan.
        </p>
        <p className='mt-10'>
          I understand that I am not able to participate in the revenue sharing
          plan until I have signed the Real ICA and transferred my license to
          Real, nor will I be paid a revenue share retroactive to my join date.
        </p>
        <p className='mt-10'>Sincerely,</p>
        <div className='my-10'>
          Agent Signature:
          <div className='block lg:inline-block max-w-xs lg:mx-2'>
            <HighlightedDiv>
              <ControlledSignatureInput
                control={control}
                label='Signature'
                errors={errors}
                name='signature'
                rules={{
                  required: 'Signature is required',
                }}
              />
            </HighlightedDiv>
          </div>
        </div>
        <p className='mt-10'>
          I accept this LOI and the terms described on behalf of Real. This LOI
          is completely confidential and is solely for Real’s in-house use. Your
          name, brokerage, logo, and any likeness will not be used in any form
          of communication until after you formally join Real.
        </p>
        <div className='mt-10'>
          <img
            src={TamirsSignatureImg}
            alt="Tamir's Signature"
            className='h-10 mb-2'
          />
          <p>Tamir Poleg CEO: Real Broker, LLC.</p>
        </div>
        <div className='flex justify-end'>
          <SubmitButton
            label='Sign and Submit'
            isSubmitting={isSubmitting}
            inline
          />
        </div>
        <p className='mt-20'>
          <strong>Real Broker, LLC</strong> | 27 W. 24th St., Suite 407, New
          York, NY 10010 | 1-855-450-0442
        </p>
      </form>
    </div>
  );
};

export default LetterOfIntentForm;
