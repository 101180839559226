import React from 'react';
import SwitchColumnFilter, {
  SwitchColumnFilterProps,
} from './Base/SwitchColumnFilter';

export interface TransactionVerifiedSwitchColumnFilterProps<D extends object>
  extends SwitchColumnFilterProps<D> {}

const TransactionVerifiedSwitchColumnFilter = <D extends object>(
  props: TransactionVerifiedSwitchColumnFilterProps<D>,
) => {
  return <SwitchColumnFilter {...props} label='Transaction Verified' />;
};

export default TransactionVerifiedSwitchColumnFilter;
