import React, { useEffect } from 'react';
import qs from 'qs';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthCookie } from '../utils/AuthUtils';
import { fetchAuthUserDetail } from '../slices/AuthSlice';

interface AuthRedirectRouteProps extends RouteComponentProps {}

const AuthRedirectRoute: React.FC<AuthRedirectRouteProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { jwtToken, path } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    setAuthCookie(jwtToken as string);
    dispatch(fetchAuthUserDetail());
  }, [dispatch, jwtToken, path]);

  return <Redirect to={path} />;
};

export default AuthRedirectRoute;
