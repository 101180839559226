import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { InvoiceResponseStatusEnum } from '../../../openapi/arrakis';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { EnumMap } from '../../../types';

interface InvoicesStatusCellProps {
  type: InvoiceResponseStatusEnum;
}

const typeToVariant: EnumMap<InvoiceResponseStatusEnum, PillVariantType> = {
  [InvoiceResponseStatusEnum.Cancel]: 'danger',
  [InvoiceResponseStatusEnum.CancelConfirmed]: 'danger',
  [InvoiceResponseStatusEnum.InvoiceCreated]: 'primary',
  [InvoiceResponseStatusEnum.NetsuiteError]: 'danger',
  [InvoiceResponseStatusEnum.Open]: 'warning',
  [InvoiceResponseStatusEnum.Paid]: 'success',
  [InvoiceResponseStatusEnum.TransactionMissing]: 'danger',
  [InvoiceResponseStatusEnum.PaidConfirmed]: 'success',
  [InvoiceResponseStatusEnum.GeneralError]: 'danger',
};

const typeToIcon: EnumMap<InvoiceResponseStatusEnum, React.ReactElement> = {
  [InvoiceResponseStatusEnum.Cancel]: <CloseIcon fontSize='small' />,
  [InvoiceResponseStatusEnum.CancelConfirmed]: <CloseIcon fontSize='small' />,
  [InvoiceResponseStatusEnum.InvoiceCreated]: <InfoIcon fontSize='small' />,
  [InvoiceResponseStatusEnum.NetsuiteError]: <CloseIcon fontSize='small' />,
  [InvoiceResponseStatusEnum.Open]: <WarningIcon fontSize='small' />,
  [InvoiceResponseStatusEnum.Paid]: <CheckCircleIcon fontSize='small' />,
  [InvoiceResponseStatusEnum.TransactionMissing]: (
    <CloseIcon fontSize='small' />
  ),
  [InvoiceResponseStatusEnum.PaidConfirmed]: (
    <CheckCircleIcon fontSize='small' />
  ),
  [InvoiceResponseStatusEnum.GeneralError]: <CloseIcon fontSize='small' />,
};

const InvoicesStatusCell: React.FC<InvoicesStatusCellProps> = ({ type }) => {
  const variant: PillVariantType = typeToVariant[type];
  const icon: React.ReactElement = typeToIcon[type];

  return <Pill text={capitalizeEnum(type)} variant={variant!} icon={icon!} />;
};

export default InvoicesStatusCell;
