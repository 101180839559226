import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Bugsnag from '@bugsnag/js';
import { AppThunk, AuthState } from '../types';
import {
  ApplicationResponse,
  UserControllerApi,
  AgentResponse,
} from '../openapi/yenta';
import {
  getKeymakerConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { getAuthCookie, deleteAuthCookie } from '../utils/AuthUtils';
import { AuthControllerApi, UserDetails } from '../openapi/keymaker';

export const initialState: AuthState = {
  loadingUserDetail: false,
  userDetail: null,
  userVerifyInfo: null,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loadingUserDetail = action.payload;
    },
    saveUserDetail(state, action: PayloadAction<AgentResponse>) {
      state.userDetail = action.payload;
    },
    saveApplication(state, action: PayloadAction<ApplicationResponse>) {
      let applicationIndex = state.userDetail?.applications?.findIndex(
        (a) => a.id === action.payload.id,
      );
      if (applicationIndex !== -1) {
        state.userDetail!.applications![applicationIndex!] = action.payload;
      } else {
        state.userDetail!.applications = [action.payload];
      }
    },
    saveUserVerifyInfo(state, action: PayloadAction<UserDetails>) {
      state.userVerifyInfo = action.payload;
    },
  },
});

export const {
  changeLoading,
  saveUserDetail,
  saveApplication,
  saveUserVerifyInfo,
} = AuthSlice.actions;

export const fetchAuthUserDetail = (
  loading = true,
): AppThunk<Promise<AgentResponse | undefined>> => async (dispatch) => {
  if (loading) {
    dispatch(changeLoading(true));
  }

  try {
    const { data } = await new UserControllerApi(
      getYentaConfiguration(),
    ).getCurrentUserUsingGET();
    await dispatch(saveUserDetail(data));
    await dispatch(fetchUserVerifyInfo());
    Bugsnag.setUser(
      `${data.id}`,
      data.emailAddress,
      `${data.firstName} ${data.lastName}`,
    );
    return data;
  } catch (e) {
    deleteAuthCookie();
    return undefined;
  } finally {
    if (loading) {
      dispatch(changeLoading(false));
    }
  }
};

export const fetchUserVerifyInfo = (): AppThunk => async (dispatch) => {
  try {
    const token = getAuthCookie();
    const { data } = await new AuthControllerApi(
      getKeymakerConfiguration(),
    ).authenticateUserUsingGET(token!);
    await dispatch(saveUserVerifyInfo(data));
  } catch (error) {}
};

export default AuthSlice.reducer;
