import { Close } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  ApplicationResponse,
  ApplicationUpdateRequest,
  LicenseRequest,
} from '../../openapi/yenta';
import { editApplicationForm } from '../../slices/ApplicationSlice';
import { countryMapState } from '../../testUtils/OnboardingUtils';
import { ISelectOption, YesNoType } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import Button from '../Button';
import ControlledDatePickerInput from '../ControlledDatePickerInput';
import ControlledMultiSelectInput from '../ControlledMultiSelectInput';
import ControlledRadioInput from '../ControlledRadioInput';
import ControlledTextInput from '../ControlledTextInput';
import SidebarModal from '../SidebarModal';
import ControlledPhoneNumberInput from '../ControlledPhoneNumberInput';
import { PHONE_NUMBER_VALIDATIONS } from '../../utils/Validations';

interface ApplicationEditFormProps {
  isOpen: boolean;
  onClose(): void;
  application: ApplicationResponse;
}

const ApplicationEditForm: React.FC<ApplicationEditFormProps> = ({
  application,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const defaultValues: any = useMemo(
    () => ({
      ...application,
      commercialAgent: application.commercialAgent
        ? YesNoType.YES
        : YesNoType.NO,
      addresses: application.doesBusinessIn!.map<ISelectOption>((item) => ({
        value: item.administrativeArea!.stateOrProvince!,
        label: capitalizeEnum(item.administrativeArea!.stateOrProvince!),
      })),
    }),

    [application],
  );

  const {
    control,
    reset,
    errors,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<ApplicationResponse>({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'doesBusinessIn',
  });

  let watchProvinceOrState: {
    value: AdministrativeAreaResponseStateOrProvinceEnum;
    label: string;
  }[] = watch('addresses');

  const onSubmit = async (data: any) => {
    const doesBusinessIn: Required<LicenseRequest[]> = [];
    for (let index = 0; index < data.addresses.length; index++) {
      doesBusinessIn.push({
        number: data.doesBusinessIn[index].number,
        expirationDate: data.doesBusinessIn[index].expirationDate,
        administrativeAreaRequest: {
          country: defaultValues.doesBusinessIn[0].administrativeArea.country,
          stateOrProvince: data.addresses[index].value,
        },
        knownComplaints:
          data.doesBusinessIn[index].knownComplaints === YesNoType.YES,
        active: data.doesBusinessIn[index].active === YesNoType.YES,
      });
    }

    const finalData: ApplicationUpdateRequest = {
      firstName: application.firstName!,
      lastName: application.lastName!,
      emailAddress: application.emailAddress!,
      commercialAgent: data.commercialAgent === YesNoType.YES,
      birthDate: data.birthDate,
      boardOrMls: data.boardOrMls,
      currentBrokerage: data.currentBrokerage,
      estimatedSales: data.estimatedSales,
      phoneNumber: data.phoneNumber,
      doesBusinessIn: doesBusinessIn,
    };

    await dispatch(editApplicationForm(application.id!, finalData));
    onClose();
  };

  const [addressLength, setAddressLength] = useState(
    watchProvinceOrState.length,
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    const appendBusiness = (state: any) => {
      append({
        active: null,
        administrativeArea: {
          agentCanTransferLicense: null,
          country: AdministrativeAreaResponseCountryEnum.UnitedStates,
          stateOrProvince: state,
        },
        expirationDate: null,
        knownComplaints: null,
        number: '',
      });
    };

    watchProvinceOrState.forEach((state) => {
      const stateExists: boolean = fields.some(
        (item) => item.administrativeArea.stateOrProvince === state.value,
      );
      if (!stateExists) {
        if (addressLength < watchProvinceOrState.length) {
          appendBusiness(
            watchProvinceOrState[watchProvinceOrState.length - 1].value,
          );
          setAddressLength(watchProvinceOrState.length);
        } else if (addressLength > fields.length) {
          setValue(
            'addresses',
            watchProvinceOrState.filter((ele) => {
              return ele.value !== state.value;
            }),
          );
          setAddressLength(watchProvinceOrState.length - 1);
        }
      }
    });

    fields.forEach((state, index) => {
      const stateExists = watchProvinceOrState.some(
        (item) => item.value === state.administrativeArea.stateOrProvince,
      );
      if (addressLength < fields.length && !stateExists) {
        remove(index);
        setAddressLength(watchProvinceOrState.length - 1);
      }
    });
    setAddressLength(watchProvinceOrState.length);
  }, [
    append,
    application.doesBusinessIn,
    watchProvinceOrState,
    fields,
    defaultValues,
    setValue,
    addressLength,
    remove,
  ]);

  const defaultCountry: AdministrativeAreaResponseCountryEnum =
    application.doesBusinessIn && application.doesBusinessIn[0]
      ? application.doesBusinessIn[0].administrativeArea!.country!
      : AdministrativeAreaResponseCountryEnum.UnitedStates;

  return (
    <SidebarModal
      title='Editing: Application Form'
      subtitle='some message related to the card we’re editing.'
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-application-form'
      >
        <div className='p-4'>
          <div>
            <ControlledPhoneNumberInput
              control={control}
              label='Phone Number'
              name='phoneNumber'
              placeholder='+1 (702) 123-4567'
              errors={errors}
              rules={{
                required: 'Please enter your phone number',
                ...PHONE_NUMBER_VALIDATIONS,
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledDatePickerInput
              name='birthDate'
              control={control}
              errors={errors}
              label='Date of Birth'
              placeholder='Date of Birth'
              rules={{
                required: 'Please enter your date of birth',
              }}
            />
          </div>

          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Enter the board / MLS Name'
              name='boardOrMls'
              placeholder='NY State MLS'
              errors={errors}
              rules={{
                required: 'Please enter the name of the Board / MLS',
              }}
            />
            <label className='flex items-center justify-start space-x-2 mt-3'>
              <input
                type='checkbox'
                className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
                value='I do not belong to a Board / MLS.'
                onChange={({ target: { checked, value } }) => {
                  setValue('boardOrMls', checked ? value : '');
                }}
                checked={
                  watch('boardOrMls') === 'I do not belong to a Board / MLS.'
                }
              />
              <p>I do not belong to a Board / MLS.</p>
            </label>
          </div>
          <div className='mt-5'>
            <ControlledMultiSelectInput
              name='addresses'
              label='In which state / province do you operate?'
              rules={{
                required: 'Please select a state / province',
                validate: (
                  value?: AdministrativeAreaRequestStateOrProvinceEnum[],
                ) =>
                  !value?.length
                    ? 'Please select a state / province'
                    : undefined,
              }}
              placeholder='Choose state / province'
              control={control}
              errors={errors}
              options={countryMapState[defaultCountry]}
            />
          </div>
          {fields.map((field, index) => {
            return (
              <div key={field.id} className='border rounded mt-5 p-2'>
                <div className='flex flex-row justify-between'>
                  <p>
                    {capitalizeEnum(field.administrativeArea.stateOrProvince)}
                  </p>
                  <div>
                    <Close
                      fontSize='small'
                      className='text-gray-500 cursor-pointer'
                      onClick={() => remove(index)}
                    />
                  </div>
                </div>
                <div className='mt-2'>
                  <ControlledTextInput
                    control={control}
                    label='Please enter the License number below'
                    name={`doesBusinessIn[${index}].number`}
                    placeholder='123456789'
                    errors={errors}
                    rules={{
                      required: 'Please enter your license number',
                    }}
                    defaultValue={`${field.number}`}
                  />
                </div>
                <div className='mt-5'>
                  <ControlledRadioInput
                    errors={errors}
                    name={`doesBusinessIn[${index}].active`}
                    label='Is your license active?'
                    rules={{
                      required: 'Please choose one',
                    }}
                    options={[
                      {
                        label: 'Yes',
                        value: YesNoType.YES,
                      },
                      {
                        label: 'No',
                        value: YesNoType.NO,
                      },
                    ]}
                    control={control}
                    inline
                    defaultValue={field.active ? YesNoType.YES : YesNoType.NO}
                  />
                </div>
                <div className='mt-5'>
                  <ControlledDatePickerInput
                    name={`doesBusinessIn[${index}].expirationDate`}
                    control={control}
                    errors={errors}
                    label='License Expiration date'
                    placeholder='2022'
                    rules={{
                      required: 'Please choose a date.',
                    }}
                    defaultValue={field.expirationDate}
                  />
                </div>
                <div className='mt-5'>
                  <ControlledRadioInput
                    errors={errors}
                    name={`doesBusinessIn[${index}].knownComplaints`}
                    label='Registered Complaint Filed'
                    rules={{
                      required: 'Please choose one',
                    }}
                    options={[
                      {
                        label: 'Yes',
                        value: YesNoType.YES,
                      },
                      {
                        label: 'No',
                        value: YesNoType.NO,
                      },
                    ]}
                    control={control}
                    inline
                    defaultValue={
                      field.knownComplaints ? YesNoType.YES : YesNoType.NO
                    }
                  />
                </div>
              </div>
            );
          })}

          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Current Brokerage Name'
              name='currentBrokerage'
              errors={errors}
              rules={{
                required: "Please enter your current brokerage's name",
              }}
            />
            <label className='flex items-center justify-start space-x-2 mt-3'>
              <input
                type='checkbox'
                className='rounded-full border-none ring-1 ring-gray-200 focus:outline-none focus:ring-0 h-4 w-4 text-primary'
                value='I do not currently belong to a brokerage.'
                onChange={({ target: { checked, value } }) => {
                  setValue('currentBrokerage', checked ? value : '');
                }}
                checked={
                  watch('currentBrokerage') ===
                  'I do not currently belong to a brokerage.'
                }
              />
              <p>I do not currently belong to a brokerage.</p>
            </label>
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Estimated Sales Volume in last 12 months'
              name='estimatedSales'
              errors={errors}
              rules={{
                required: 'Please enter the Estimated Sales Amount.',
              }}
            />
          </div>
          <div className='mt-5 pb-20'>
            <ControlledRadioInput
              errors={errors}
              name='commercialAgent'
              label='Commercial-only Agent'
              rules={{
                required: 'Please choose one',
              }}
              options={[
                {
                  label: 'Yes',
                  value: YesNoType.YES,
                },
                {
                  label: 'No',
                  value: YesNoType.NO,
                },
              ]}
              control={control}
              inline
            />
          </div>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => {
              reset();
              onClose();
            }}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default ApplicationEditForm;
