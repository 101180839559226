import { Configuration as YentaConfiguration } from '../openapi/yenta';
import { Configuration as KeymakerConfiguration } from '../openapi/keymaker';
import { Configuration as ArrakisConfiguration } from '../openapi/arrakis';

import config from '../config';
import { getAuthCookie } from './AuthUtils';

export const getYentaConfiguration = () =>
  new YentaConfiguration({
    apiKey: `Bearer ${getAuthCookie()}`,
    basePath: config.apiBasePath.yenta,
  });

export const getKeymakerConfiguration = () =>
  new KeymakerConfiguration({
    apiKey: `Bearer ${getAuthCookie()}`,
    basePath: config.apiBasePath.keymaker,
  });

export const getArrakisConfiguration = () =>
  new ArrakisConfiguration({
    apiKey: `Bearer ${getAuthCookie()}`,
    basePath: config.apiBasePath.arrakis,
  });
