import React from 'react';
import DefaultLoader from './DefaultLoader';
import DefaultError from './DefaultError';
import DefaultEmpty from './DefaultEmpty';

export interface TableResourceContainerProps {
  loading: boolean;
  isEmpty: boolean;
  isError: boolean;
  resourceName: string;
  emptyMessage?: string;
  errorMessage?: string;
  colSpan: number;
}

const TableResourceContainer: React.FC<TableResourceContainerProps> = ({
  loading,
  isEmpty,
  isError,
  resourceName,
  emptyMessage,
  errorMessage,
  colSpan,
  children,
}) => {
  const finalEmptyMessage =
    emptyMessage || `There are no ${resourceName.toLowerCase()}s to display.`;

  const finalErrorMessage =
    errorMessage ||
    `We had trouble fetching the ${resourceName}. Please try again in a few moments.`;

  if (loading) {
    return (
      <>
        {children}
        <tr className='absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center bg-white bg-opacity-50'>
          <td colSpan={colSpan}>
            <DefaultLoader />
          </td>
        </tr>
      </>
    );
  }

  if (isError) {
    return (
      <tr>
        <td colSpan={colSpan}>
          <div className='w-full h-full p-5 items-center justify-center'>
            <DefaultError message={finalErrorMessage} />
          </div>
        </td>
      </tr>
    );
  }

  if (isEmpty) {
    return (
      <tr>
        <td colSpan={colSpan}>
          <div className='w-full h-full p-5 items-center justify-center'>
            <DefaultEmpty message={finalEmptyMessage} />
          </div>
        </td>
      </tr>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default TableResourceContainer;
