import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { values } from 'lodash';
import SidebarModal from '../components/SidebarModal';
import ControlledTextInput from '../components/ControlledTextInput';
import Button from '../components/Button';
import ControlledSelectInput from '../components/ControlledSelectInput';
import {
  AgentCreditControllerApi,
  CreditResponseTypeEnum,
  MoneyValueCurrencyEnum,
} from '../openapi/arrakis';
import { capitalizeEnum } from '../utils/StringUtils';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import ControlledDatePickerInput from '../components/ControlledDatePickerInput';
import {
  showErrorToastForErrorCode,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import ErrorService from '../services/ErrorService';
import { showApiErrorModal } from '../slices/ErrorSlice';

interface AddAgentCreditFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  agentId: string;
}

const AddAgentCreditFormSidebarModal: React.FC<AddAgentCreditFormSidebarModalProps> = ({
  isOpen,
  onClose,
  agentId,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    errors,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data: any) => {
    const finalData = {
      title: data.title,
      issuerNote: data.issuerNote,
      issuedOn: data.issuedOn,
      amount: {
        amount: data.amount,
        currency: MoneyValueCurrencyEnum.Usd,
      },
      number: data.number,
    };

    try {
      switch (data.type) {
        case CreditResponseTypeEnum.BrokerageFee:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueBrokerageFeeCreditUsingPOST(agentId, finalData);
          break;
        case CreditResponseTypeEnum.Cap:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueCapCreditUsingPOST(agentId, finalData);
          break;
        case CreditResponseTypeEnum.CumulativeGci:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueCumulativeGCICreditUsingPOST(agentId, finalData);
          break;
        case CreditResponseTypeEnum.NumberOfExecutedTransactions:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueNumberOfExecutedTransactionsCreditUsingPOST(
            agentId,
            finalData,
          );
          break;
        case CreditResponseTypeEnum.NumberOfPersonalTransactions:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issueNumberOfPersonalTransactionsCreditUsingPOST(
            agentId,
            finalData,
          );
          break;
        case CreditResponseTypeEnum.PostCapFee:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issuePostCapFeeCreditUsingPOST(agentId, finalData);
          break;
        case CreditResponseTypeEnum.PostCapEquityPurchase:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issuePostCapEquityPurchaseCreditUsingPOST(agentId, finalData);
          break;
        case CreditResponseTypeEnum.PreCapEquityPurchase:
          await new AgentCreditControllerApi(
            getArrakisConfiguration(),
          ).issuePreCapEquityPurchaseCreditUsingPOST(agentId, finalData);
          break;
      }
      onClose();
      dispatch(showSuccessToast('Credit added successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToastForErrorCode(
          'We were unable to create the agent credit.',
          ErrorService.getErrorCode(e),
        ),
      );
      ErrorService.notify('Error adding agent credit', e, {
        addAgentCredit: {
          agentYentaId: agentId,
          req: finalData,
        },
      });
    }
  };

  const type = watch('type');

  return (
    <SidebarModal
      title='Add an Agent Credit'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='add-agent-credit-form'
      >
        <div className='p-4'>
          <div className='mt-5'>
            <ControlledSelectInput
              name='type'
              control={control}
              errors={errors}
              label='Type'
              placeholder='Type'
              options={[
                {
                  label: `Select Type`,
                  value: '',
                  disabled: true,
                },
                ...values(CreditResponseTypeEnum).map((type) => ({
                  value: type,
                  label: capitalizeEnum(type),
                })),
              ]}
              rules={{
                required: 'Type is Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Issuer Title'
              name='title'
              errors={errors}
              rules={{
                required: 'Issuer title is required',
              }}
            />
          </div>
          {type === CreditResponseTypeEnum.NumberOfPersonalTransactions ||
          type === CreditResponseTypeEnum.NumberOfExecutedTransactions ? (
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                label='Number'
                name='number'
                errors={errors}
                rules={{
                  required: 'number is required',
                }}
              />
            </div>
          ) : (
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                label='Credit Amount in USD'
                name='amount'
                errors={errors}
                rules={{
                  required: 'Credit amount is required',
                }}
              />
            </div>
          )}
          <div className='mt-5'>
            <ControlledDatePickerInput
              name='issuedOn'
              control={control}
              errors={errors}
              label='Issued On'
              rules={{
                required: 'Issued on date is Required',
              }}
            />
          </div>
          <div className='mt-5 pb-20'>
            <ControlledTextInput
              control={control}
              label='Issuer Note'
              name='issuerNote'
              errors={errors}
              rules={{
                required: 'Issuer note is required',
              }}
            />
          </div>
        </div>
        <div className='p-4 bg-white absolute border-t border-gray-200 bottom-0 space-x-5 w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Add'
            size='lg'
          />
          <Button
            buttonType='reset'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default AddAgentCreditFormSidebarModal;
