import React from 'react';
import {
  FiEdit3,
  // FiTrash2
} from 'react-icons/fi';
import IconButton from '../../IconButton';

interface ActionCellProps<D extends object> {
  data: D;
  onEdit(data: D): void;
  onDelete(data: D): void;
}

const ActionCell = <D extends object>({
  data,
  onEdit,
}: // onDelete,
ActionCellProps<D>) => {
  return (
    <div className='space-x-2 flex flex-nowrap'>
      <IconButton
        variant='outline'
        leftIcon={<FiEdit3 title='Edit' />}
        onClick={() => onEdit(data)}
      />
      {/*<IconButton*/}
      {/*  variant='outline'*/}
      {/*  leftIcon={<FiTrash2 title='Delete' />}*/}
      {/*  onClick={() => onDelete(data)}*/}
      {/*/>*/}
    </div>
  );
};

export default ActionCell;
