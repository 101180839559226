import React from 'react';
import classNames from 'classnames';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { get } from 'lodash';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import FormErrorMessage from './FormErrorMessage';

interface ControlledFileUploadInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues> {
  errors: FieldErrors<TFieldValues>;
  label?: string;
  buttonLabel?: string;
  placeholder?: string;
  accept?: string;
  multiple?: boolean;
  rightAction?: {
    text: string;
    onClick(): void;
  };
}

const ControlledFileUploadInput: React.FC<ControlledFileUploadInputProps> = ({
  errors,
  control,
  name,
  label,
  placeholder,
  buttonLabel = 'Browse',
  accept,
  multiple,
  rules,
  rightAction,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      <div className='flex flex-row justify-between'>
        {label && (
          <label className='inline-block' htmlFor={name}>
            {label}
          </label>
        )}

        {rightAction && (
          <a
            className='text-primary underline'
            href='#'
            onClick={rightAction.onClick}
          >
            {rightAction.text}
          </a>
        )}
      </div>

      <Controller
        id={name}
        control={control}
        name={name}
        rules={rules}
        defaultValue=''
        render={({ name, value, ref, onChange }) => {
          const fileNames = (value || []).map((f: File) => f.name).join(', ');

          return (
            <div>
              <input
                id={name}
                ref={ref}
                onChange={(e) => {
                  onChange(Array.from(e.target.files!));
                }}
                placeholder={placeholder}
                accept={accept}
                type='file'
                name={name}
                multiple={multiple}
                className='hidden'
              />
              <div
                className={classNames(
                  'flex items-center appearance-none text-gray-500 border border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
                )}
              >
                <label htmlFor={name} className='border-r px-4 py-1.5'>
                  {buttonLabel}
                </label>
                <span className='px-3 line-clamp-1'>
                  {fileNames || placeholder}
                </span>
              </div>
            </div>
          );
        }}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledFileUploadInput;
