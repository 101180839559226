import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Column } from 'react-table';
import ResourceIndexContainer from '../../containers/ResourceIndexContainer';
import {
  EscrowResponse,
  EscrowResponseEscrowStatusEnum,
  TransactionResponse,
} from '../../openapi/arrakis';
import {
  disburseEscrow,
  markEscrowAsReceived,
} from '../../slices/TransactionSlice';
import { numberToMoney } from '../../utils/CurrencyUtils';
import { isTransactionReadOnly } from '../../utils/TransactionHelper';
// import AvatarPill from '../AvatarPill';
import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';
import DateCell from '../table/Cells/DateCell';
import EscrowStatusCell from '../table/Cells/EscrowStatusCell';
import DateColumnFilter from '../table/Filters/DateColumnFilter';
import { OfficeResponse } from '../../openapi/yenta';
import EscrowAddForm from './EscrowAddForm';

interface EscrowSectionProps {
  transaction: TransactionResponse;
  officeDetails: OfficeResponse;
}

const columns: Array<Column<EscrowResponse>> = [
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => numberToMoney(value?.amount! || 0),
  },
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ value }) => <DateCell date={value!} />,
    Filter: DateColumnFilter,
  },
  {
    Header: 'Status',
    accessor: 'escrowStatus',
    Cell: ({ value }) => <EscrowStatusCell status={value!} />,
  },
  {
    Header: 'Info',
    accessor: 'info',
    disableFilters: true,
    Cell: ({ value }) => <p>{value}</p>,
  },
];

const ORIGINAL_COL_LENGTH = columns.length;

const EscrowSection: React.FC<EscrowSectionProps> = ({
  transaction,
  officeDetails,
}) => {
  const dispatch = useDispatch();
  const [
    openEscrowFormSidebarModal,
    setOpenEscrowFormSidebarModal,
  ] = useState<boolean>(false);
  const [openMarkAsReceived, setOpenMarkAsReceivedModal] = useState<boolean>(
    false,
  );
  const [openDisbursement, setOpenDisbursementModal] = useState<boolean>(false);
  const [escrowRowDetails, setEscrowRowDetails] = useState<EscrowResponse>();

  columns[ORIGINAL_COL_LENGTH] = {
    Header: 'Actions',
    accessor: 'id',
    Cell: ({ row }) => (
      <div className='md:space-x-3 flex md:flex-row flex-col'>
        {!isTransactionReadOnly(transaction) && (
          <Button
            label='Edit'
            type='outline'
            onClick={() => {
              setEscrowRowDetails(row.original);
              setOpenEscrowFormSidebarModal(true);
            }}
          />
        )}
        {row.original.escrowStatus ===
          EscrowResponseEscrowStatusEnum.Submitted && (
          <Button
            label='Mark as Received'
            type='outline'
            onClick={() => {
              setEscrowRowDetails(row.original!);
              setOpenMarkAsReceivedModal(true);
            }}
          />
        )}
      </div>
    ),
  };

  return (
    <div className='col-span-12 p-4'>
      <div className='col-span-12'>
        <div className='py-2 flex flex-row justify-between'>
          <span className='text-xl text-gray-800 font-primary-medium'>
            Escrow
          </span>
          {!isTransactionReadOnly(transaction) && (
            <div className='space-x-3'>
              <Button
                type='outline'
                label='+ Add'
                onClick={() => {
                  setEscrowRowDetails(undefined);
                  setOpenEscrowFormSidebarModal(true);
                }}
              />
              <Button
                label='Disburse'
                type='primary'
                onClick={() => {
                  setOpenDisbursementModal(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className='py-0'>
        <ResourceIndexContainer<EscrowResponse>
          columns={columns}
          resourceName='Escrow'
          fetchData={() =>
            new Promise((resolve) =>
              resolve({
                data: transaction.escrows!,
                total: transaction.escrows!.length,
              }),
            )
          }
          hidePagination
        />
      </div>
      <EscrowAddForm
        isOpen={openEscrowFormSidebarModal}
        onClose={() => setOpenEscrowFormSidebarModal(false)}
        escrowDetails={escrowRowDetails!}
        transactionId={transaction.id!}
        officeDetails={officeDetails}
      />
      <ConfirmationModal
        isOpen={openMarkAsReceived}
        variant='info'
        title='Mark as Received'
        onClose={() => setOpenMarkAsReceivedModal(false)}
      >
        <div>
          <p className='text-lg font-primary-medium'>
            * Received amount of{' '}
            {numberToMoney(escrowRowDetails?.amount?.amount! || 0)}
          </p>
        </div>

        <div className='mt-3 space-x-3'>
          <Button
            label='Received'
            type='primary'
            onClick={() =>
              dispatch(markEscrowAsReceived(escrowRowDetails?.id!))
            }
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setOpenMarkAsReceivedModal(false)}
          />
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={openDisbursement}
        variant='info'
        title='Confirm Escrow Disbursement'
        onClose={() => setOpenDisbursementModal(false)}
      >
        <div>
          <p className='text-lg font-primary'>
            Are you sure you would like to disburse the escrow payments? You
            cannot undo this.
          </p>
        </div>

        <div className='mt-3 space-x-3'>
          <Button
            label='Disburse'
            type='primary'
            onClick={() => {
              dispatch(disburseEscrow(transaction.id!));
            }}
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setOpenDisbursementModal(false)}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default EscrowSection;
