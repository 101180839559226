import React from 'react';
import DocumentFooter from './DocumentFooter';
import DocumentMastHead from './DocumentMastHead';
import DocumentTitle from './DocumentTitle';

interface FormLayoutProps {
  title: string;
  identifier: string;
  companyName: string;
  companyAddress: string;
}

const FormLayout: React.FC<FormLayoutProps> = ({
  identifier,
  title,
  companyName,
  companyAddress,
  children,
}) => {
  return (
    <div>
      <DocumentMastHead
        companyName={companyName}
        companyAddress={companyAddress}
      />
      <DocumentTitle title={title} />
      <div className='p-5'>{children}</div>
      <div className='print:fixed bottom-0 w-full'>
        <DocumentFooter identifier={identifier} />
      </div>
    </div>
  );
};

export default FormLayout;
