import { SvgIcon } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BusinessIcon from '@material-ui/icons/Business';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { DescriptionOutlined, Edit, PaymentTwoTone } from '@material-ui/icons';
import { numberToMoney } from '../../utils/CurrencyUtils';
import IconButton from '../IconButton';
import Pill from '../Pill';
import { ReactComponent as CalendarClock } from '../../assets/icons/calendar-clock.svg';
import { ReactComponent as CalendarCursor } from '../../assets/icons/calendar-cursor.svg';
import { ReactComponent as SkySlope } from '../../assets/icons/skyslope.svg';
import { TransactionResponse } from '../../openapi/arrakis';
import { capitalizeEnum, isStringEmpty } from '../../utils/StringUtils';
import CopyToClipboard from '../CopyToClipboard';
import EditTransactionVerifiedSidebarModal from '../../forms/EditTransactionVerifiedSidebarModal';

interface TransactionHeaderProps {
  transaction: TransactionResponse;
}

const TransactionHeader: React.FC<TransactionHeaderProps> = ({
  transaction,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <header className='px-4 py-8 flex-grow flex md:flex-row flex-col bg-mineshaft-500'>
      <div className='m-auto mb-4 md:m-0 md:pr-5'>
        {/*todo: find best image to transactionDetailHeader*/}
      </div>
      <div className='flex flex-col justify-items-center'>
        <div className='flex md:flex-row flex-col md:space-y-0 space-x-2 space-y-4 pb-4 md:pb-1 items-center text-center md:text-left'>
          <p className='text-2xl text-white font-semibold'>
            {transaction.address?.oneLine}
          </p>
          <Pill
            text={capitalizeEnum(transaction.lifecycleState!.state!)}
            variant='success'
          />
          {transaction.verified ? (
            <Pill
              icon={<CheckCircleOutlineIcon />}
              text='Verified'
              variant='success'
            />
          ) : (
            <Pill text='Not Verified' variant='warning' />
          )}
          <IconButton
            variant='outline'
            leftIcon={<Edit fontSize='small' titleAccess='EditVerified' />}
            onClick={() => setIsOpen(true)}
          />
        </div>
        <div className='text-white flex md:flex-row flex-col md:space-y-0 space-x-2 space-y-4 pb-4 md:pb-1 items-center text-center md:text-left'>
          <p>Code: {transaction.code}</p>
          <CopyToClipboard value={transaction.code!} />
        </div>

        <div className='flex flex-row items-center space-x-2'>
          <LocalOfferIcon fontSize='small' className='text-white' />
          <p className='text-white'>
            {numberToMoney(transaction.price?.amount!)}
          </p>
        </div>
        <div className='flex flex-row items-center space-x-2'>
          <BusinessIcon fontSize='small' className='text-white' />
          <Link to={`/offices/${transaction.office?.id}`}>
            <p className='text-white'>{transaction.office?.name}</p>
          </Link>
          <Pill
            text={
              transaction.office?.splitCheck ? 'Split Check' : 'Single Check'
            }
            variant='primary'
          />
        </div>
        <div className='flex md:flex-row flex-col md:space-x-3 md:space-y-0 space-y-3 py-2'>
          <div className='flex flex-row items-center'>
            <SvgIcon
              component={CalendarClock}
              viewBox='0 0 15 15'
              className='text-white'
            />
            <p className='text-white font-primary-medium'>Closing Date:</p>
            <p className='text-white font-primary-regular ml-2'>
              {transaction.closingDateActual
                ? DateTime.fromISO(
                    transaction.closingDateActual,
                  ).toLocaleString()
                : 'N/A'}
            </p>
          </div>

          <div className='flex flex-row items-center'>
            <SvgIcon
              component={CalendarCursor}
              viewBox='0 0 15 15'
              className='text-white'
            />
            <p className='text-white font-primary-medium mr-1'>
              Estimated Closing Date:
            </p>
            <p className='text-white font-primary-regular ml-2'>
              {transaction.closingDateEstimated
                ? DateTime.fromISO(
                    transaction.closingDateEstimated,
                  ).toLocaleString()
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className='flex md:flex-row flex-col items-center space-x-2 md:space-y-0 space-y-3'>
          {!isStringEmpty(transaction.skySlopeUrl) && (
            <a
              href={transaction.skySlopeUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconButton
                variant='outline'
                leftIcon={<SvgIcon component={SkySlope} viewBox='0 0 15 15' />}
                label='View on SkySlope'
              />
            </a>
          )}
          {/* <IconButton label='Explain Payouts' /> */}
          <Link to={`/transactions/${transaction.id}/payment-invoices`}>
            <IconButton
              label='View Payment Invoices'
              variant='outline'
              leftIcon={<DescriptionOutlined />}
            />
          </Link>
          <Link to={`/transactions/${transaction.id}/outgoing-payments`}>
            <IconButton
              label='View Outgoing Payments'
              variant='outline'
              leftIcon={<PaymentTwoTone />}
            />
          </Link>
        </div>
      </div>
      <EditTransactionVerifiedSidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        transaction={transaction!}
      />
    </header>
  );
};

export default TransactionHeader;
