import React from 'react';
import classNames from 'classnames';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { DateTime } from 'luxon';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { get } from 'lodash';
import FormErrorMessage from './FormErrorMessage';

interface ControlledDatePickerInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues> {
  errors: FieldErrors<TFieldValues>;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  customInput?: React.ReactElement;
  datePickerConfig?: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  >;
}

const ControlledDatePickerInput: React.FC<ControlledDatePickerInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  placeholder,
  readOnly,
  customInput,
  datePickerConfig = {},
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && (
        <label className='inline-block' htmlFor={name}>
          {label}
        </label>
      )}
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={rules}
        render={({ name, value, onChange, onBlur, ref }) => (
          <DatePicker
            {...datePickerConfig}
            name={name}
            selected={value ? DateTime.fromISO(value).toJSDate() : null}
            onChange={(date: Date) =>
              onChange(DateTime.fromJSDate(date).toISODate())
            }
            placeholderText={placeholder}
            autoComplete='off'
            onBlur={onBlur}
            ref={ref}
            readOnly={readOnly}
            dateFormatCalendar='MMMM'
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            id={name}
            customInput={customInput}
            className={classNames(
              !customInput &&
                'appearance-none p-1.5 border-gray-200 rounded bg-white focus:outline-none focus:ring-0 w-full',
              {
                'bg-gray-50': readOnly,
              },
            )}
          />
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledDatePickerInput;
