import React from 'react';
import { ApplicationResponseStatusEnum } from '../../../openapi/yenta';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { EnumMap } from '../../../types';

interface ApplicationStatusProps {
  status: ApplicationResponseStatusEnum;
}

const statusToPillType: EnumMap<
  ApplicationResponseStatusEnum,
  PillVariantType
> = {
  LETTER_OF_INTENT_SIGNED: 'warning',
  SIGN_LETTER_OF_INTENT: 'warning',
  REJECTED: 'danger',
  APPROVED: 'success',
  BOARD_JOINED: 'warning',
  FEES_PAID: 'warning',
  ICA_SIGNED: 'warning',
  JOIN_BOARD: 'warning',
  JOIN_MLS: 'warning',
  LICENSE_TRANSFERRED: 'warning',
  MLS_JOINED: 'warning',
  PAY_FEES: 'warning',
  PENDING_APPROVAL: 'warning',
  SIGN_ICA: 'warning',
  STARTED: 'danger',
  TRANSFER_LICENSE: 'warning',
};

const ApplicationStatus: React.FC<ApplicationStatusProps> = ({ status }) => {
  return (
    <Pill text={capitalizeEnum(status)} variant={statusToPillType[status]} />
  );
};

export default ApplicationStatus;
