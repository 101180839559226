import { Clear, Check } from '@material-ui/icons';
import React, { Fragment } from 'react';
import classNames from 'classnames';

interface ProfileScoreItem {
  label: string;
  completed: boolean;
}

interface Props {
  profileScoreItems: ProfileScoreItem[];
}

const ProfileProgressRows: React.FC<Props> = ({ profileScoreItems }) => {
  const pathLength = profileScoreItems.length;
  return (
    <div className='flex flex-col'>
      {profileScoreItems.map(({ label, completed }, index) => {
        return (
          <Fragment key={label}>
            <span className='font-semibold px-4 py-2'>
              {completed ? (
                <Check fontSize='small' titleAccess='check' />
              ) : (
                <Clear fontSize='small' titleAccess='clear' />
              )}
              <span
                className={classNames('ml-2', {
                  'text-gray-400': completed,
                })}
              >
                {label}
              </span>
            </span>
            {pathLength - 1 !== index && <div className='border-b' />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default ProfileProgressRows;
