import React from 'react';

export interface StatisticsBlockItems {
  title: string | number;
  subtitle: string;
}

export interface StatisticsBlockProps {
  items: StatisticsBlockItems[];
}

const StatisticsBlock: React.FC<StatisticsBlockProps> = ({ items }) => {
  return (
    <div className='p-3 inline-block rounded border border-gray-600'>
      {items.map((item) => (
        <div key={item.title} className='text-white px-3 inline-block'>
          <span className='block text-center font-primary-medium text-2xl'>
            {item.title}
          </span>
          <p className='font-primary-light'>{item.subtitle}</p>
        </div>
      ))}
    </div>
  );
};

export default StatisticsBlock;
