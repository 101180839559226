import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import FormLayout from '../components/FormLayout';
import { fetchCDAFormDetail } from '../slices/CDAFormSlice';
import { RootState } from '../types';
import ResourceContainer from '../components/ResourceContainer';
import DataTable from '../components/DataTable';
import DataRowCapitalized from '../components/DataRowCapitalized';
import { numberToMoney } from '../utils/CurrencyUtils';
import Pill from '../components/Pill';
import ItemInfo from '../components/ItemInfo';
// import ScanQRCode from '../components/ScanQRCode';
import PagedPrint from '../components/PagedPrint';
import { capitalizeEnum } from '../utils/StringUtils';
import DivContainer from '../components/DivContainer';
import { getDocumentIdentifier } from '../utils/CommissionDocumentHelper';
import HighlightedDiv from '../components/HighlightedDiv';
import TamirsSignatureImg from '../assets/img/tamirs-signature.png';

export interface CDAFormProps {
  commissionDocumentId: string;
  isPdf: boolean;
}

const CDAForm: React.FC<CDAFormProps> = ({ commissionDocumentId, isPdf }) => {
  const dispatch = useDispatch();

  const {
    cdaFormDetail,
    fetchingCDAFormDetailErrorCode,
    loadingCDAFormDetail,
  } = useSelector((state: RootState) => state.cdaForm);

  useEffect(() => {
    dispatch(fetchCDAFormDetail(commissionDocumentId));
  }, [commissionDocumentId, dispatch]);

  const Container = isPdf ? PagedPrint : DivContainer;

  return (
    <ResourceContainer
      loading={loadingCDAFormDetail}
      isEmpty={!cdaFormDetail}
      errorCode={fetchingCDAFormDetailErrorCode}
      resourceName='Commission Disbursement Authorization'
    >
      <Container>
        {!!cdaFormDetail && (
          <FormLayout
            title={cdaFormDetail.documentTitle!}
            companyName={cdaFormDetail.companyName!}
            companyAddress={cdaFormDetail.officeAddress!}
            identifier={getDocumentIdentifier(cdaFormDetail)}
          >
            <p className='text-center mb-2 print:text-xs'>
              Revision {cdaFormDetail.revision} |{' '}
              {DateTime.fromMillis(cdaFormDetail.createdAt!).toFormat(
                'MMM d, yyyy - HH:mm ',
              )}
            </p>
            <div className='grid grid-cols-1 lg:grid-cols-2 print:grid-cols-2 gap-4 print:text-xs'>
              <DataTable>
                <DataRowCapitalized name='Reference Number'>
                  <span>{cdaFormDetail.transactionCode}</span>
                </DataRowCapitalized>
                <DataRowCapitalized
                  name='Property Address'
                  value={cdaFormDetail.propertyAddress}
                />
                <DataRowCapitalized
                  name='Anticipated closing date'
                  value={
                    cdaFormDetail.anticipatedClosingDate
                      ? DateTime.fromISO(
                          cdaFormDetail.anticipatedClosingDate,
                        ).toFormat('LL-dd-yyyy')
                      : 'N/A'
                  }
                />
                <DataRowCapitalized name='Sales Price'>
                  <span>{numberToMoney(cdaFormDetail.price!.amount!)}</span>
                </DataRowCapitalized>
              </DataTable>
              <DataTable>
                <DataRowCapitalized
                  name='Transaction Owner'
                  value={cdaFormDetail.transactionOwner?.name}
                />
                <DataRowCapitalized
                  name='Representing'
                  value={cdaFormDetail.representing}
                />
                <DataRowCapitalized
                  name='Buyer'
                  value={cdaFormDetail.buyerName}
                />
                <DataRowCapitalized
                  name='Seller'
                  value={cdaFormDetail.sellerName}
                />
                {cdaFormDetail.buyersAgentCommission && (
                  <DataRowCapitalized
                    name="Buyer's Agent Commission"
                    value={numberToMoney(
                      cdaFormDetail.buyersAgentCommission.amount!,
                    )}
                  />
                )}
                {cdaFormDetail.sellersAgentCommission && (
                  <DataRowCapitalized
                    name="Seller's Agent Commission"
                    value={numberToMoney(
                      cdaFormDetail.sellersAgentCommission.amount!,
                    )}
                  />
                )}
                {cdaFormDetail.otherAgentCommission && (
                  <DataRowCapitalized
                    name='Other Agent Commission'
                    value={numberToMoney(
                      cdaFormDetail.otherAgentCommission.amount!,
                    )}
                  />
                )}
                <DataRowCapitalized name='Total Gross Commission'>
                  <span>
                    {numberToMoney(
                      cdaFormDetail.grossCommission
                        ? cdaFormDetail.grossCommission?.amount!
                        : 0,
                    )}
                  </span>
                </DataRowCapitalized>
              </DataTable>
            </div>
            <table className='border border-gray-600 border-collapse rounded table-auto w-full my-8 '>
              <tbody>
                {cdaFormDetail.payees?.map((payee) => (
                  <tr key={payee.fullName} className='bi-avoid'>
                    <td className='border p-2'>
                      <div className='flex flex-row justify-between'>
                        <HighlightedDiv>
                          <div className='flex items-center flex-wrap'>
                            <p className='text-lg print:text-md font-primary-medium mr-2'>
                              {payee.fullName}
                            </p>
                            <div className='print:ml-2'>
                              <Pill
                                text={capitalizeEnum(
                                  payee.transactionParticipantRole!,
                                ).toUpperCase()}
                                variant='dark'
                                fontSize='text-xs'
                              />
                            </div>
                            <span className='print:text-md text-lg ml-2'>
                              {payee.amount
                                ? numberToMoney(payee.amount.amount!)
                                : 'N/A'}
                            </span>
                          </div>
                        </HighlightedDiv>
                      </div>
                      <div className='grid grid-cols-1 lg:grid-cols-3 print:grid-cols-3 space-y-4 lg:space-x-2 lg:space-y-0 print:space-x-2 print:space-y-0 mt-2 text-xs'>
                        {payee.emailAddress && (
                          <ItemInfo title='Email:' value={payee.emailAddress} />
                        )}
                        {payee.phoneNumber && (
                          <ItemInfo title='Phone:' value={payee.phoneNumber} />
                        )}
                        {payee.licenseNumber && (
                          <ItemInfo
                            title='License:'
                            value={payee.licenseNumber}
                          />
                        )}
                      </div>

                      {payee.paymentInstructions && (
                        <div>
                          <p className='text-xs uppercase font-primary-medium tracking-wider text-gray-700 mb-1 mt-3 print:text-xs'>
                            Payment Instructions:
                          </p>
                          <div className='border rounded'>
                            <p className='p-3 whitespace-pre-wrap text-sm print:text-xs'>
                              {payee.paymentInstructions}
                            </p>

                            {!!payee.bankAccountNumber && (
                              <div className='flex flex-col lg:flex-row print:flex-row space-y-4 lg:space-y-0 print:space-y-0 justify-between p-3 print:text-xs border-t whitespace-pre-wrap text-sm'>
                                <ItemInfo
                                  title='Bank:'
                                  value={payee.bankName!}
                                />
                                <ItemInfo
                                  title='Account Name:'
                                  value={payee.bankAccountName!}
                                />
                                <ItemInfo
                                  title='Routing Number (for wires):'
                                  value={payee.bankRoutingNumber!}
                                />
                                <ItemInfo
                                  title='Account Number:'
                                  value={payee.bankAccountNumber!}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {!!payee.wiringInstructions && (
                        <div>
                          <p className='text-xs uppercase font-primary-medium tracking-wider text-gray-700 mb-1 mt-3 print:text-xs'>
                            Wiring Instructions:
                          </p>
                          <div className='p-3 border rounded whitespace-pre-wrap text-sm print:text-xs'>
                            {payee.wiringInstructions}
                          </div>
                        </div>
                      )}
                      {!!payee.additionalInstructions && (
                        <div>
                          <p className='text-xs uppercase font-primary-medium tracking-wider text-gray-700 mb-1 mt-3 print:text-xs'>
                            Additional Instructions:
                          </p>
                          <div className='p-3 border rounded whitespace-pre-wrap text-sm print:text-xs'>
                            {payee.additionalInstructions}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='mt-4'>
              {cdaFormDetail.comments && (
                <p className='text-lg print:text-base'>
                  <span className='medium mr-1'>Comments:</span>
                  {cdaFormDetail.comments}
                </p>
              )}
              <p className='text-lg print:text-base mt-2'>
                <span className='medium mr-1'>Date Approved:</span>
                {cdaFormDetail.approvedOn
                  ? DateTime.fromISO(cdaFormDetail.approvedOn).toFormat(
                      'LL-dd-yyyy',
                    )
                  : 'N/A'}
              </p>
              {/*<div className='flex justify-end mt-5'>*/}
              {/*  <div className='w-24'>*/}
              {/*    <ScanQRCode imageUrl={cdaFormDetail.qrCodeUrl} label='Scan' />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className='mt-4'>
              <p className='text-lg print:text-base'>
                Approved By: Tamir Poleg, CEO
              </p>
              <img
                src={TamirsSignatureImg}
                alt="Tamir's Signature"
                className='h-10 mb-5'
              />
            </div>
          </FormLayout>
        )}
      </Container>
    </ResourceContainer>
  );
};

export default CDAForm;
