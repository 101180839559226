import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SidebarModal from '../components/SidebarModal';
import ControlledDatePickerInput from '../components/ControlledDatePickerInput';
import Button from '../components/Button';
import { TransactionResponse } from '../openapi/arrakis';
import { updateTransactionInformation } from '../slices/TransactionSlice';
import { AppDispatch } from '../types';
import { isStringEmpty } from '../utils/StringUtils';

interface EditTransactionInformationFormSidebarModalProps {
  transaction: TransactionResponse;
  isOpen: boolean;
  onClose(): void;
}

const EditTransactionInformationFormSidebarModal: React.FC<EditTransactionInformationFormSidebarModalProps> = ({
  transaction,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const onSubmit = async (values: any) => {
    const newClosingDateActual = isStringEmpty(values.closingDateActual)
      ? undefined
      : values.closingDateActual;

    const newClosingDateEstimated = isStringEmpty(values.closingDateEstimated)
      ? undefined
      : values.closingDateEstimated;

    await dispatch(
      updateTransactionInformation(transaction.id!, {
        closingDateActual: newClosingDateActual,
        closingDateEstimated: newClosingDateEstimated,
      }),
    );
    onClose();
  };

  return (
    <SidebarModal
      title='Edit Transaction Information'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-2 md:p-4'>
          <div>
            <ControlledDatePickerInput
              label='Estimated Closing Date'
              control={control}
              errors={errors}
              name='closingDateEstimated'
              placeholder='Estimated Closing Date'
              defaultValue={transaction.closingDateEstimated}
              rules={{ required: 'Required' }}
            />
          </div>
          <div className='mt-5 pb-20'>
            <ControlledDatePickerInput
              control={control}
              errors={errors}
              label='Actual Closing Date'
              name='closingDateActual'
              placeholder='Actual Closing Date'
              defaultValue={transaction.closingDateActual}
            />
          </div>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Update'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EditTransactionInformationFormSidebarModal;
