import { AdministrativeAreaRequestStateOrProvinceEnum } from '../openapi/yenta';

export const CANADIAN_STATES: AdministrativeAreaRequestStateOrProvinceEnum[] = [
  AdministrativeAreaRequestStateOrProvinceEnum.Ontario,
  AdministrativeAreaRequestStateOrProvinceEnum.Quebec,
  AdministrativeAreaRequestStateOrProvinceEnum.NovaScotia,
  AdministrativeAreaRequestStateOrProvinceEnum.NewBrunswick,
  AdministrativeAreaRequestStateOrProvinceEnum.Manitoba,
  AdministrativeAreaRequestStateOrProvinceEnum.BritishColumbia,
  AdministrativeAreaRequestStateOrProvinceEnum.PrinceEdwardIsland,
  AdministrativeAreaRequestStateOrProvinceEnum.Saskatchewan,
  AdministrativeAreaRequestStateOrProvinceEnum.Alberta,
  AdministrativeAreaRequestStateOrProvinceEnum.NewfoundlandAndLabrador,
];

export const US_STATES: AdministrativeAreaRequestStateOrProvinceEnum[] = [
  AdministrativeAreaRequestStateOrProvinceEnum.Alabama,
  AdministrativeAreaRequestStateOrProvinceEnum.Alaska,
  AdministrativeAreaRequestStateOrProvinceEnum.Arizona,
  AdministrativeAreaRequestStateOrProvinceEnum.Arkansas,
  AdministrativeAreaRequestStateOrProvinceEnum.California,
  AdministrativeAreaRequestStateOrProvinceEnum.Colorado,
  AdministrativeAreaRequestStateOrProvinceEnum.Connecticut,
  AdministrativeAreaRequestStateOrProvinceEnum.Delaware,
  AdministrativeAreaRequestStateOrProvinceEnum.DistrictOfColumbia,
  AdministrativeAreaRequestStateOrProvinceEnum.Florida,
  AdministrativeAreaRequestStateOrProvinceEnum.Georgia,
  AdministrativeAreaRequestStateOrProvinceEnum.Hawaii,
  AdministrativeAreaRequestStateOrProvinceEnum.Idaho,
  AdministrativeAreaRequestStateOrProvinceEnum.Illinois,
  AdministrativeAreaRequestStateOrProvinceEnum.Indiana,
  AdministrativeAreaRequestStateOrProvinceEnum.Iowa,
  AdministrativeAreaRequestStateOrProvinceEnum.Kansas,
  AdministrativeAreaRequestStateOrProvinceEnum.Kentucky,
  AdministrativeAreaRequestStateOrProvinceEnum.Louisiana,
  AdministrativeAreaRequestStateOrProvinceEnum.Maine,
  AdministrativeAreaRequestStateOrProvinceEnum.Maryland,
  AdministrativeAreaRequestStateOrProvinceEnum.Massachusetts,
  AdministrativeAreaRequestStateOrProvinceEnum.Michigan,
  AdministrativeAreaRequestStateOrProvinceEnum.Minnesota,
  AdministrativeAreaRequestStateOrProvinceEnum.Mississippi,
  AdministrativeAreaRequestStateOrProvinceEnum.Missouri,
  AdministrativeAreaRequestStateOrProvinceEnum.Montana,
  AdministrativeAreaRequestStateOrProvinceEnum.Nebraska,
  AdministrativeAreaRequestStateOrProvinceEnum.Nevada,
  AdministrativeAreaRequestStateOrProvinceEnum.NewHampshire,
  AdministrativeAreaRequestStateOrProvinceEnum.NewJersey,
  AdministrativeAreaRequestStateOrProvinceEnum.NewMexico,
  AdministrativeAreaRequestStateOrProvinceEnum.NewYork,
  AdministrativeAreaRequestStateOrProvinceEnum.NorthCarolina,
  AdministrativeAreaRequestStateOrProvinceEnum.NorthDakota,
  AdministrativeAreaRequestStateOrProvinceEnum.Ohio,
  AdministrativeAreaRequestStateOrProvinceEnum.Oklahoma,
  AdministrativeAreaRequestStateOrProvinceEnum.Oregon,
  AdministrativeAreaRequestStateOrProvinceEnum.Pennsylvania,
  AdministrativeAreaRequestStateOrProvinceEnum.PuertoRico,
  AdministrativeAreaRequestStateOrProvinceEnum.RhodeIsland,
  AdministrativeAreaRequestStateOrProvinceEnum.SouthCarolina,
  AdministrativeAreaRequestStateOrProvinceEnum.SouthDakota,
  AdministrativeAreaRequestStateOrProvinceEnum.Tennessee,
  AdministrativeAreaRequestStateOrProvinceEnum.Texas,
  AdministrativeAreaRequestStateOrProvinceEnum.Utah,
  AdministrativeAreaRequestStateOrProvinceEnum.Vermont,
  AdministrativeAreaRequestStateOrProvinceEnum.Virginia,
  AdministrativeAreaRequestStateOrProvinceEnum.Washington,
  AdministrativeAreaRequestStateOrProvinceEnum.WestVirginia,
  AdministrativeAreaRequestStateOrProvinceEnum.Wisconsin,
  AdministrativeAreaRequestStateOrProvinceEnum.Wyoming,
];
