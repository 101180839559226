import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Column,
  IdType,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import classNames from 'classnames';
import { IPaginateReq, IPaginateRes } from '../types';
import { PaginateMockRealAPI } from '../utils/PaginateMockRealAPI';
import { DEFAULT_PAGE_SIZE } from '../constants/TableConstants';
import { isSmScreen } from '../utils/BrowserUtils';
import SearchBar from '../components/SearchBar';
import TableOptions from '../components/table/TableOptions';
import Tabs from '../components/Tabs';
import {
  getPageCount,
  mapToStateFilter,
  mapToStateSortBy,
} from '../utils/TableUtils';
import { SearchParam } from '../openapi/yenta';
import ResourceTable, { ResourceTableVariant } from './ResourceTable';

export interface IResourceIndexTab<D> {
  name: string;
  filter?: TableResourceFilter<D>;
}

export type InitialSort<T> = {
  [key in keyof T]?: 'asc' | 'desc';
};

export type TableResourceFilter<T> = {
  [key in keyof T]?: T[key] | SearchParam;
};

export type ApiHandlerMethodType = keyof PaginateMockRealAPI;

interface TableProps<D extends object> {
  header?: string;
  tabs?: Array<IResourceIndexTab<D>>;
  defaultSelectedTabName?: string;
  columns: Array<Column<D>>;
  hiddenColumns?: Array<IdType<D>>;
  filter?: TableResourceFilter<D>;
  initialSort?: InitialSort<D>;
  search?: string;
  resourceName: string;
  fetchData: (req: IPaginateReq<D>) => Promise<IPaginateRes<D>>;
  hideFilters?: boolean;
  hidePagination?: boolean;
  RightComponent?: React.ReactElement;
}

const ResourceIndexContainer = <D extends object>({
  header,
  tabs = [],
  defaultSelectedTabName,
  columns,
  initialSort,
  hiddenColumns = [],
  filter,
  search,
  fetchData,
  resourceName,
  hideFilters = false,
  hidePagination = false,
  RightComponent,
}: TableProps<D>): ReactElement => {
  const [selectedTab, setSelectedTab] = useState(
    defaultSelectedTabName || tabs[0]?.name,
  );
  const [isFilterOpen, setFilterOpen] = useState(false);
  const isNoTabs = !tabs.length;
  const [pageCount, setPageCount] = useState(0);
  const [paginatedRes, setPaginatedRes] = useState<IPaginateRes<D>>({
    total: 0,
    data: [],
  });

  const tableInstance = useTable(
    {
      columns,
      data: paginatedRes.data,
      manualGlobalFilter: true,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
      defaultCanFilter: false,
      initialState: {
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        filters: mapToStateFilter(filter),
        sortBy: mapToStateSortBy(initialSort),
        globalFilter: search || undefined,
        hiddenColumns,
      },
      pageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  const handleFetch = async (
    req: IPaginateReq<D>,
  ): Promise<IPaginateRes<D>> => {
    const res = await fetchData(req);
    setPaginatedRes(res);
    setPageCount(getPageCount(res.total, tableInstance.state.pageSize));
    return res;
  };

  const renderTable = () => (
    <ResourceTable<D>
      {...tableInstance}
      resourceName={resourceName}
      fetchData={handleFetch}
      variant={
        isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
      }
      hidePagination={hidePagination}
    />
  );

  const renderTableOptions = () => {
    return (
      <div className='flex flex-row'>
        {!hideFilters && (
          <TableOptions<D>
            columns={tableInstance.columns}
            filter={tableInstance.state.filters}
            setAllFilters={tableInstance.setAllFilters}
            setFilterOpen={setFilterOpen}
            isFilterOpen={isFilterOpen}
          />
        )}
        {RightComponent && <div className='ml-3'>{RightComponent}</div>}
      </div>
    );
  };

  const filterTabs = tabs.map(({ name }: IResourceIndexTab<D>) => ({
    name,
    TabComponent: <div className='mt-4'>{renderTable()}</div>,
  }));

  const handleChange = useCallback(() => {
    if (!isNoTabs) {
      const currentTab = tabs.find((t) => t.name === selectedTab);
      tableInstance.setAllFilters(
        mapToStateFilter({ ...filter, ...currentTab?.filter }),
      );
    }
  }, [filter, isNoTabs, selectedTab, tableInstance, tabs]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  useEffect(() => {
    tableInstance.setGlobalFilter(search || undefined);
  }, [search, tableInstance]);

  return (
    <div>
      {header && (
        <div
          className={classNames('flex flex-row items-center', {
            'mb-4': isNoTabs,
          })}
        >
          {isSmScreen() && (
            <div className='flex-grow lg:hidden mr-4'>
              <SearchBar
                onChange={(text) =>
                  tableInstance.setGlobalFilter(text || undefined)
                }
                value={tableInstance.state.globalFilter || ''}
              />
            </div>
          )}
          <h1 className='text-xl mb-1 hidden lg:block'>{header}</h1>

          {(isSmScreen() || isNoTabs) && (
            <div className='ml-auto'>{renderTableOptions()} </div>
          )}
        </div>
      )}
      {isNoTabs ? (
        renderTable()
      ) : (
        <Tabs
          onChange={setSelectedTab}
          selected={selectedTab}
          tabs={filterTabs}
          RightComponent={
            <div className='hidden lg:block'>{renderTableOptions()}</div>
          }
          variant={isSmScreen() ? 'spread' : 'default'}
          showBorderBottom={isSmScreen()}
        />
      )}
    </div>
  );
};

export default ResourceIndexContainer;
