import React from 'react';
import { useDispatch } from 'react-redux';
import { values } from 'lodash';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModal';
import Button from '../Button';
import ControlledSelectInput from '../ControlledSelectInput';
import { capitalizeEnum, MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import ControlledTextInput from '../ControlledTextInput';
import { CreateTestTransactionRequestRepresentingEnum } from '../../openapi/arrakis';
import { createTestTransaction } from '../../slices/TransactionSlice';
import { LicenseResponse } from '../../openapi/yenta';
import { AppDispatch } from '../../types';

interface CreateTransactionProps extends RouteComponentProps {
  createTransaction: boolean;
  setCreateTransaction(val: boolean): void;
  agentId: string;
  licenses: LicenseResponse[];
}

const CreateTransaction: React.FC<CreateTransactionProps> = ({
  setCreateTransaction,
  createTransaction,
  agentId,
  history,
  licenses,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const licensedStates = licenses.map(
    (states) => states.administrativeArea!.stateOrProvince!,
  );

  const onSubmit = async (data: any) => {
    const transaction = await dispatch(
      createTestTransaction({
        agentId,
        ...data,
        rental: data.rental === 'true',
      }),
    );
    setCreateTransaction(false);

    if (transaction) {
      history.push(`/transactions/${transaction.id}`);
    }
  };

  return (
    <ConfirmationModal
      isOpen={createTransaction}
      variant='info'
      title='Create Test Transaction'
      onClose={() => {
        setCreateTransaction(false);
      }}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='create-transaction'
      >
        <div className='mt-2'>
          <ControlledSelectInput
            name='representing'
            control={control}
            errors={errors}
            label='Representing'
            placeholder='Representing'
            defaultValue={null}
            options={[
              { label: '', value: '', disabled: true },
              ...values([
                CreateTestTransactionRequestRepresentingEnum.Buyer,
                CreateTestTransactionRequestRepresentingEnum.Seller,
              ]).map((item) => ({
                value: item,
                label: capitalizeEnum(item),
              })),
            ]}
            rules={{
              required: 'Required',
            }}
          />
        </div>
        <div className='mt-2'>
          <ControlledSelectInput
            name='stateOrProvince'
            control={control}
            errors={errors}
            label='State'
            placeholder='State'
            options={[
              { label: '', value: '', disabled: true },
              ...values(licensedStates).map((state) => ({
                value: state,
                label: capitalizeEnum(state),
              })),
            ]}
            rules={{
              required: 'Please select a state',
            }}
          />
        </div>

        <div className='mt-2'>
          <ControlledTextInput
            control={control}
            label='Price'
            name='price'
            placeholder='Price'
            errors={errors}
            rules={{
              pattern: {
                value: MONEY_AMOUNT_REGEX,
                message: 'Please enter a valid amount',
              },
              required: 'Please provide a price',
            }}
          />
        </div>

        <div className='mt-2'>
          <ControlledSelectInput
            name='rental'
            control={control}
            errors={errors}
            label='Is Rental?'
            placeholder='Rental'
            options={[
              { label: 'Rental', value: '', disabled: true },
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            rules={{
              required: 'Is this a rental listing?',
            }}
          />
        </div>
        <div className='mt-2'>
          <ControlledTextInput
            control={control}
            label='Sales Commission Amount'
            name='salesCommissionAmount'
            placeholder=''
            errors={errors}
            rules={{
              pattern: {
                value: MONEY_AMOUNT_REGEX,
                message: 'Please enter a valid amount',
              },
              required: 'Please provide a sales commission amount',
            }}
          />
        </div>
        <div className='my-2'>
          <ControlledTextInput
            control={control}
            label='Listing Commission Amount'
            name='listingCommissionAmount'
            placeholder=''
            errors={errors}
            rules={{
              pattern: {
                value: MONEY_AMOUNT_REGEX,
                message: 'Please enter a valid amount',
              },
              required: 'Please provide a listing commission amount',
            }}
          />
        </div>
        <div className='mt-3 space-x-3'>
          <Button
            label={isSubmitting ? 'Submitting...' : 'Create'}
            disabled={isSubmitting}
            type='primary'
            buttonType='submit'
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setCreateTransaction(false)}
          />
        </div>
      </form>
    </ConfirmationModal>
  );
};

export default withRouter(CreateTransaction);
