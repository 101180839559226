import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { History } from '@material-ui/icons';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import { RootState } from '../types';
import IconButton from '../components/IconButton';
import DetailPageLoader from '../components/DetailPageLoader';
import ResourceContainer from '../components/ResourceContainer';
import { JobExecutionResponse } from '../openapi/yenta';
import { getLatestCronJobs } from '../slices/CronJobs';
import CronJobsStatusCell from '../components/table/Cells/CronJobsStatusCell';
import MilliDateTimeCell from '../components/table/Cells/MilliDateTimeCell';

interface CronJobsRouteProps {}

export const columns: Array<Column<JobExecutionResponse>> = [
  {
    Header: 'Name',
    accessor: 'jobExecutionName',
    Cell: ({ value }) => value,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'jobExecutionStatus',
    Cell: ({ value }) => <CronJobsStatusCell status={value!} />,
  },
  {
    Header: 'Log',
    accessor: 'errorLog',
    Cell: ({ value }) => value,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    Cell: ({ value }) => value,
    disableSortBy: true,
  },
  {
    Header: 'Started At',
    accessor: 'startedAt',
    Cell: ({ value }) => <MilliDateTimeCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
  {
    Header: 'Completed At',
    accessor: 'completedAt',
    Cell: ({ value }) => <MilliDateTimeCell date={value!} />,
    disableSortBy: true,
    cardColSize: 6,
  },
];

export const columnsWithAction: Array<Column<JobExecutionResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<JobExecutionResponse> }) => (
      <Link to={`/jobs/${row.original.jobExecutionName}/history`}>
        <IconButton leftIcon={<History />} />
      </Link>
    ),
  },
  ...columns,
];

const CronJobsRoute: React.FC<CronJobsRouteProps> = () => {
  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    await dispatch(getLatestCronJobs());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    cronJobs: { cronJobs, fetchErrorCode, loading },
  } = useSelector((state: RootState) => state);

  const getCronJobs = () => {
    return Promise.resolve({
      data: cronJobs!,
      total: cronJobs!.length,
    });
  };

  return (
    <ResourceContainer
      loading={loading}
      isEmpty={!cronJobs!.length}
      errorCode={fetchErrorCode}
      LoaderComponent={<DetailPageLoader />}
      resourceName='Jobs'
    >
      <div className='p-4'>
        <ResourceIndexContainer<JobExecutionResponse>
          header='Jobs'
          columns={columnsWithAction}
          resourceName='Jobs'
          fetchData={() => getCronJobs()}
          hidePagination
          hideFilters
        />
      </div>
    </ResourceContainer>
  );
};
export default CronJobsRoute;
