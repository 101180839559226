import React from 'react';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';

export type PageAnnouncementVariant =
  | 'default'
  | 'success'
  | 'warning'
  | 'danger';

export interface PageAnnouncementProps {
  titleIcon: React.ReactElement;
  title: string;
  content: string;
  show: boolean;
  variant: PageAnnouncementVariant;
  RightComponent?: React.FC | React.ReactNode;
  onClose?(): void;
}

const PageAnnouncement: React.FC<PageAnnouncementProps> = ({
  titleIcon,
  title,
  content,
  show,
  onClose,
  variant = 'default',
  RightComponent,
}) => {
  const variantClassMap: { [x in PageAnnouncementVariant]: string } = {
    default: 'bg-primary text-primary',
    danger: 'bg-error text-error',
    success: 'bg-success text-success',
    warning: 'bg-warning text-warning',
  };

  if (!show) {
    return null;
  }

  return (
    <div className='flex flex-col lg:flex-row flex-wrap lg:items-center py-2 px-4 bg-gray-100 relative'>
      <div
        className={classNames(
          'flex items-center justify-center py-1 px-2 bg-opacity-10 rounded lg:mr-3 mb-2 lg:mb-0',
          variantClassMap[variant],
        )}
      >
        {titleIcon}
        <span className='ml-1 font-primary-medium'>{title}</span>
      </div>
      <p className='flex-1 font-primary-medium'>{content}</p>
      {RightComponent ?? (
        <button
          className='block cursor-pointer bg-transparent focus:outline-none absolute right-6 top-3 lg:static'
          title='announcement-close-button'
          onClick={onClose}
        >
          <Close fontSize='small' />
        </button>
      )}
    </div>
  );
};

export default PageAnnouncement;
