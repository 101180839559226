import React from 'react';
import Avatar from '../Avatar';
import Pill from '../Pill';

export interface NetworkHeaderProps {
  name: string;
  imageUrl: string;
  tier: number;
}

const NetworkHeader: React.FC<NetworkHeaderProps> = ({
  name,
  imageUrl,
  tier,
}) => {
  return (
    <div
      className='py-3 px-4 flex justify-between items-center bg-gray-100'
      title='network-header'
    >
      <div className='flex items-center'>
        <Avatar name={name} imageUrl={imageUrl} size='xs' />
        <div className='px-3'>
          <p className='font-primary-medium text-base'>{name}</p>
        </div>
      </div>
      <Pill text={`Tier ${tier}`} variant='dark' />
    </div>
  );
};

export default NetworkHeader;
