import { AddressStateEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

const AddressStateEnumSelectColumnFilter = <D extends object>(
  props: SelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(AddressStateEnum)(props);
};

export default AddressStateEnumSelectColumnFilter;
