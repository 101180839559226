import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { values } from 'lodash';
import {
  AttachedFeeRequest,
  AttachedFeeRequestFeeTypeEnum,
  AttachedFeeValue,
  AttachedFeeValueFeeTypeEnum,
  MoneyValueCurrencyEnum,
} from '../../openapi/arrakis';
import { capitalizeEnum, MONEY_AMOUNT_REGEX } from '../../utils/StringUtils';
import Button from '../Button';
import ControlledTextInput from '../ControlledTextInput';
import SidebarModal from '../SidebarModal';
import ControlledSelectInput from '../ControlledSelectInput';
import { RootState, YesNoType } from '../../types';
import {
  addAttachedFee,
  updateAttachedFee,
} from '../../slices/TransactionSlice';

interface AddAttachedFeeProps {
  isOpen: boolean;
  onClose(): void;
  attachedFee?: AttachedFeeValue;
  transactionId: string;
}

interface FormData {
  amount: string;
  description: string;
  feeType: AttachedFeeRequestFeeTypeEnum;
  participantId: string;
  passthrough: YesNoType;
  transactionCoordinator: string;
}

const AddAttachedFee: React.FC<AddAttachedFeeProps> = ({
  isOpen,
  onClose,
  transactionId,
  attachedFee,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    errors,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: attachedFee,
  });
  const { transactionDetail } = useSelector(
    (state: RootState) => state.transaction,
  );
  const feeType = watch('feeType', attachedFee?.feeType);
  const participantId = watch('participantId', attachedFee?.counterpartyId);
  const [participantLabel, setParticipantLabel] = useState('Participant');
  const [descriptionLabel, setDescriptionLabel] = useState('Description');

  useEffect(() => {
    setDescriptionLabel('Description');
    if (feeType === AttachedFeeValueFeeTypeEnum.AdditionalCommission) {
      setParticipantLabel('Who is receiving this amount?');
    }
    if (feeType === AttachedFeeValueFeeTypeEnum.Mls) {
      setParticipantLabel('Who is paying this fee?');
    }
    if (feeType === AttachedFeeValueFeeTypeEnum.Reimbursement) {
      setParticipantLabel('Who is getting reimbursed?');
    }
    if (feeType === AttachedFeeValueFeeTypeEnum.TransactionCoordinator) {
      setParticipantLabel('Who is paying this fee?');
      setDescriptionLabel('Transaction Coordinator Name');
    }
  }, [attachedFee, feeType, participantId, setValue, transactionDetail]);

  const onSubmit = async (data: FormData) => {
    if (attachedFee) {
      const finalData: AttachedFeeRequest = {
        amount: {
          currency: attachedFee?.amount?.currency,
          amount: +data.amount,
        },
        description:
          data.feeType === AttachedFeeRequestFeeTypeEnum.TransactionCoordinator
            ? `Transaction Coordinator: ${data.description}`
            : data.description,
        feeType: data.feeType,
        participantId: data.participantId,
      };
      await dispatch(
        updateAttachedFee(attachedFee?.id!, transactionId, finalData),
      );
    } else {
      const finalData: AttachedFeeRequest = {
        amount: {
          currency: MoneyValueCurrencyEnum.Usd,
          amount: +data.amount,
        },
        description:
          data.feeType === AttachedFeeRequestFeeTypeEnum.TransactionCoordinator
            ? `Transaction Coordinator: ${data.description}`
            : data.description,
        feeType: data.feeType,
        participantId: data.participantId,
      };
      await dispatch(addAttachedFee(transactionId, finalData));
    }
  };
  return (
    <SidebarModal
      title='Add Additional Fee'
      subtitle='Add details about the Fee'
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='fee-form'
      >
        <div className='p-4'>
          <div>
            <ControlledSelectInput
              control={control}
              errors={errors}
              label='Fee Type'
              name='feeType'
              placeholder='Fee Type'
              defaultValue={attachedFee?.feeType}
              rules={{ required: 'Fee Type is required' }}
              options={[
                { label: 'Fee Type', disabled: true, value: '' },
                ...values(AttachedFeeRequestFeeTypeEnum).map((c) => ({
                  value: c,
                  label: capitalizeEnum(c),
                })),
              ]}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              control={control}
              errors={errors}
              name='participantId'
              placeholder='Search Member'
              label={participantLabel}
              defaultValue={attachedFee?.counterpartyId}
              options={[
                { label: 'Participant', disabled: true, value: '' },
                ...transactionDetail!.participants!.map((resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.firstName} ${resp.lastName}`,
                })),
              ]}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Enter Amount *'
              name='amount'
              placeholder='2000'
              errors={errors}
              rules={{
                required: 'Please enter a amount',
                pattern: {
                  value: MONEY_AMOUNT_REGEX,
                  message: 'Please enter a valid amount',
                },
              }}
              defaultValue={attachedFee?.amount?.amount!}
            />
          </div>

          <div className='mt-5'>
            <ControlledTextInput
              name='description'
              control={control}
              errors={errors}
              label={descriptionLabel}
              placeholder={descriptionLabel}
              defaultValue={attachedFee?.description?.replace(
                'Transaction Coordinator: ',
                '',
              )}
              rules={{
                required: 'Required',
              }}
            />
          </div>
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2 w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={onClose}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default AddAttachedFee;
