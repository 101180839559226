import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { OutgoingPaymentResponsePaymentSystemEnum } from '../../../openapi/arrakis';
import { EnumMap } from '../../../types';

export interface OutgoingPaymentPaymentSystemCellProps {
  type: OutgoingPaymentResponsePaymentSystemEnum;
}

const typeToVariant: EnumMap<
  OutgoingPaymentResponsePaymentSystemEnum,
  PillVariantType
> = {
  [OutgoingPaymentResponsePaymentSystemEnum.Tipalti]: 'success',
  [OutgoingPaymentResponsePaymentSystemEnum.Netsuite]: 'primary',
};

const typeToIcon: EnumMap<
  OutgoingPaymentResponsePaymentSystemEnum,
  React.ReactElement
> = {
  [OutgoingPaymentResponsePaymentSystemEnum.Tipalti]: (
    <CheckCircleIcon fontSize='small' />
  ),
  [OutgoingPaymentResponsePaymentSystemEnum.Netsuite]: (
    <InfoIcon fontSize='small' />
  ),
};

const OutgoingPaymentPaymentSystemCell: React.FC<OutgoingPaymentPaymentSystemCellProps> = ({
  type,
}) => {
  const variant: PillVariantType = typeToVariant[type];
  const icon: React.ReactElement = typeToIcon[type];

  return <Pill text={capitalizeEnum(type)} variant={variant!} icon={icon!} />;
};

export default OutgoingPaymentPaymentSystemCell;
