import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { AppThunk, ErrorCode, MLSState } from '../types';
import MockRealApi from '../utils/MockRealApi';
import ErrorService from '../services/ErrorService';
import { MlsControllerApi, MlsResponse } from '../openapi/yenta';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: MLSState = {
  loadingDetail: false,
  detail: undefined,
  fetchDetailErrorCode: null,
  calendarEvents: [],
};

const MLSSlice = createSlice({
  name: 'mls',
  initialState,
  reducers: {
    changeLoadingDetail(state, action: PayloadAction<boolean>) {
      state.loadingDetail = action.payload;
    },
    saveDetail(state, action: PayloadAction<MlsResponse>) {
      state.detail = action.payload;
      state.fetchDetailErrorCode = null;
    },
    errorFetchingDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchDetailErrorCode = action.payload;
    },
    addCalendarEvents(state, action: PayloadAction<any>) {
      state.calendarEvents = _.chain(action.payload)
        .groupBy((day) => DateTime.fromISO(day.startDate).toFormat('ccc DD'))
        .map((value, key) => ({ day: key, events: value }))
        .value();
    },
  },
});

export const {
  changeLoadingDetail,
  saveDetail,
  errorFetchingDetail,
  addCalendarEvents,
} = MLSSlice.actions;

export const mlsDetail = (id: string): AppThunk => async (dispatch) => {
  dispatch(changeLoadingDetail(true));
  try {
    const { data } = await new MlsControllerApi(
      getYentaConfiguration(),
    ).getMlsByIdUsingGET(id);
    dispatch(saveDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch MLS mls', e, {
      mls: { id },
    });
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export const fetchMLSCalendarEvents = (id: number): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingDetail(true));
  try {
    const api = new MockRealApi();
    const { events } = await api.getMLSCalendarEvents(id);
    dispatch(addCalendarEvents(events));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch calendar events for MLS', e, {
      mls: { id },
    });
    dispatch(errorFetchingDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingDetail(false));
  }
};

export default MLSSlice.reducer;
