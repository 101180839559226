import { times } from 'lodash';
import { IPaginateRes, MLS, StateAbbreviation } from '../../../types';
import {
  fakeCompany,
  fakeCompanyImage,
  fakeLicenseNumber,
  fakeNumber,
  fakePersonFullName,
  fakeProfileImage,
  randomEnum,
} from '../../../testUtils/FakerUtils';

export const DefaultMLSResponse: IPaginateRes<MLS> = {
  data: times<MLS>(20, (id) => ({
    id: id + 1,
    name: fakeCompany(),
    logo: fakeCompanyImage(),
    code: fakeLicenseNumber(),
    state: randomEnum<StateAbbreviation>(StateAbbreviation),
    brokerImage: fakeProfileImage(),
    brokerName: fakePersonFullName(),
  })),
  total: fakeNumber({ min: 50, max: 100 }),
};
