import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { History } from 'history';
import { RootState } from '../types';
import { ApplicationResponseNextStatusEnum } from '../openapi/yenta';
import { getNextUrlForApplicationStatus } from '../testUtils/OnboardingUtils';

const useRedirectAwayOnboardingEffect = (
  currentStep: ApplicationResponseNextStatusEnum,
  history: History,
) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!!userDetail?.applications?.length) {
      const application =
        userDetail.applications[userDetail.applications.length - 1];
      if (currentStep !== application.nextStatus) {
        history.push(getNextUrlForApplicationStatus(application.nextStatus!));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRedirectAwayOnboardingEffect;
