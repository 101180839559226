import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uniqBy } from 'lodash';
import RefreshIcon from '@material-ui/icons/Refresh';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SyncIcon from '@material-ui/icons/Sync';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import {
  TransactionResponse,
  TransactionLifecycleStateValueStateEnum,
  TransactionLifecycleStateValue,
} from '../../openapi/arrakis';
import { UserPrincipalRolesEnum } from '../../openapi/yenta';
import {
  getProcessTransaction,
  resetFromSkySlope,
  transitionTransaction,
} from '../../slices/TransactionSlice';
import IconButton from '../IconButton';
import TransactionAction from '../TransactionAction';
import {
  isTransitionActionable,
  readableTransitionName,
} from '../../utils/TransactionHelper';
import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';
import DoubleConfirmationModal from '../DoubleConfirmationModal';
import Dropdown from '../Dropdown';

interface TransactionDetailActionBarProps {
  transaction: TransactionResponse;
  roles: UserPrincipalRolesEnum;
}

type Transition = TransactionLifecycleStateValueStateEnum;

const TransactionDetailActionBar: React.FC<TransactionDetailActionBarProps> = ({
  transaction,
  roles,
}) => {
  const dispatch = useDispatch();
  const [activeTransition, setActiveTransition] = useState<Transition>();
  const [
    retryTransition,
    setRetryTransition,
  ] = useState<TransactionLifecycleStateValueStateEnum>();
  const [
    isResetFromSkySlopeOpen,
    setIsResetFromSkySlopeOpen,
  ] = useState<boolean>(false);
  const [isSyncFromSkySlopeOpen, setIsSyncFromSkySlopeOpen] = useState<boolean>(
    false,
  );
  const [
    isRefreshingFromSkyslope,
    setIsRefreshingFromSkyslope,
  ] = useState<boolean>(false);

  let primaryTransitionStates: TransactionLifecycleStateValue[];
  let secondaryTransitionStates: TransactionLifecycleStateValue[];
  if (roles === UserPrincipalRolesEnum.Admin) {
    primaryTransitionStates = [
      transaction.nextPrimaryAdminTransition!,
      transaction.nextPrimaryUserTransition!,
    ];
    secondaryTransitionStates = [
      ...(transaction.nextSecondaryAdminTransitions || []),
      ...(transaction.nextSecondaryUserTransitions || []),
    ];
  } else {
    primaryTransitionStates = [transaction.nextPrimaryUserTransition!];
    secondaryTransitionStates = transaction.nextSecondaryUserTransitions || [];
  }

  const actionablePrimaryTransactions = uniqBy<TransactionLifecycleStateValue>(
    primaryTransitionStates.filter(
      (transition: TransactionLifecycleStateValue) =>
        transition && isTransitionActionable(transition.state!),
    ),
    'state',
  );

  const actionableSecondaryTransactions = uniqBy<TransactionLifecycleStateValue>(
    secondaryTransitionStates.filter(
      (transition: TransactionLifecycleStateValue) =>
        transition && isTransitionActionable(transition.state!),
    ),
    'state',
  );

  return (
    <>
      <section className='grid lg:grid-cols-2 grid-cols-1 gap-y-2 px-4 py-2'>
        <div className='grid lg:flex lg:flex-row gap-2'>
          {actionablePrimaryTransactions.map(
            (transition: TransactionLifecycleStateValue) => {
              return (
                <TransactionAction
                  key={transition.state!}
                  action={transition.state!}
                  onClick={() => setActiveTransition(transition.state!)}
                />
              );
            },
          )}
          {/* TODO: Change this once we are done testing. */}
          {/*{transaction.currentStateFailed && (*/}
          {true && (
            <IconButton
              leftIcon={<RefreshIcon />}
              buttonType='button'
              variant='default'
              label={`Retry ${readableTransitionName(
                transaction.lifecycleState!.state!,
              )}`}
              onClick={() =>
                setRetryTransition(transaction.lifecycleState!.state!)
              }
            />
          )}
        </div>
        <div className='grid lg:place-self-end'>
          <Dropdown
            icon={<MoreVertIcon titleAccess='more' />}
            noBorder
            items={[
              ...actionableSecondaryTransactions.map(
                (transition: TransactionLifecycleStateValue) => ({
                  icon: <SwapHoriz />,
                  text: readableTransitionName(transition.state!),
                  onClick: () => setActiveTransition(transition.state!),
                }),
              ),
              {
                icon: <RefreshIcon />,
                text: 'Reset from SkySlope',
                colorType: 'danger',
                onClick: () => setIsResetFromSkySlopeOpen(true),
              },
              {
                icon: <SyncIcon />,
                text: 'Sync With SkySlope',
                onClick: () => setIsSyncFromSkySlopeOpen(true),
              },
            ]}
          />
        </div>
      </section>
      <ConfirmationModal
        isOpen={!!activeTransition}
        variant='info'
        title='This transaction is going places...'
        subtitle={`Are you sure you want to transition the transaction to ${readableTransitionName(
          activeTransition!,
        )}?`}
        onClose={() => {
          setActiveTransition(undefined);
        }}
      >
        <div className='mt-3 space-x-3'>
          <Button
            label='Transition'
            type='primary'
            onClick={async () => {
              await dispatch(
                transitionTransaction(activeTransition!, transaction.id!),
              );
              setActiveTransition(undefined);
            }}
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setActiveTransition(undefined)}
          />
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={!!retryTransition}
        variant='info'
        title={`Retry ${readableTransitionName(retryTransition!)}?`}
        subtitle={`Are you sure you'd like to reprocess this transaction?`}
        onClose={() => {
          setRetryTransition(undefined);
        }}
      >
        <div className='mt-3 space-x-3'>
          <Button
            label='Reprocess'
            type='primary'
            onClick={async () => {
              await dispatch(getProcessTransaction(transaction.id!));
              setRetryTransition(undefined);
            }}
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setRetryTransition(undefined)}
          />
        </div>
      </ConfirmationModal>
      <DoubleConfirmationModal
        variant='warning'
        title="Beware: You're about to override changes to this transaction"
        subtitle='By pressing continue, we will reset this transaction to what is currently on SkySlope.
            Any changes made here will be lost.'
        isOpen={isResetFromSkySlopeOpen}
        isSubmitting={isRefreshingFromSkyslope}
        onClose={() => setIsResetFromSkySlopeOpen(false)}
        onConfirm={async () => {
          setIsRefreshingFromSkyslope(true);
          await dispatch(resetFromSkySlope(transaction.id!));
          setIsRefreshingFromSkyslope(false);
          setIsResetFromSkySlopeOpen(false);
        }}
      />
      <ConfirmationModal
        variant='warning'
        title="You're about to sync this transaction from SkySlope"
        subtitle='Some metadata will change, but all participants and information entered here will persist.'
        isOpen={isSyncFromSkySlopeOpen}
        onClose={() => setIsSyncFromSkySlopeOpen(false)}
      >
        <div className='mt-3 space-x-3'>
          <Button
            label='Confirm'
            type='primary'
            onClick={async () => {
              await dispatch(resetFromSkySlope(transaction.id!, 'false'));
              setIsSyncFromSkySlopeOpen(false);
            }}
          />
          <Button
            label='Cancel'
            type='secondary'
            onClick={() => setIsSyncFromSkySlopeOpen(false)}
          />
        </div>
      </ConfirmationModal>
    </>
  );
};

export default TransactionDetailActionBar;
