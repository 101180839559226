import { OutgoingPaymentResponseStatusEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

export interface OutgoingPaymentstatusSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const OutgoingPaymentStatusSelectColumnFilter = <D extends object>(
  props: OutgoingPaymentstatusSelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(OutgoingPaymentResponseStatusEnum)(props);
};

export default OutgoingPaymentStatusSelectColumnFilter;
