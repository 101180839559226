import { ChevronLeft } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AgentResponse } from '../../openapi/yenta';
import { AppDispatch, AsyncSelectOption, RootState } from '../../types';
import { capitalizeEnum, NAME_REGEX } from '../../utils/StringUtils';
import { searchForRegisteredAgents } from '../../utils/TableUtils';
import Button from '../Button';
import ControlledAsyncSelectInput from '../ControlledAsyncSelectInput';
import ControlledRadioInput from '../ControlledRadioInput';
import ControlledTextInput from '../ControlledTextInput';
import IconButton from '../IconButton';
import SidebarModal from '../SidebarModal';
import ControlledSelectInput from '../ControlledSelectInput';
import {
  CreateParticipantRequest,
  ParticipantValueRoleEnum,
  PaymentParticipantValueTypeEnum,
} from '../../openapi/arrakis';
import {
  EMAIL_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../utils/Validations';
import {
  addCommissionParticipant,
  addOPCityCommissionParticipant,
  uploadW9Form,
} from '../../slices/TransactionSlice';
import { fetchUserByIds } from '../../slices/UserIdsSlice';
import ControlledToggleInput from '../ControlledToggleInput';
import ControlledPhoneNumberInput from '../ControlledPhoneNumberInput';
import ControlledFileUploadInput from '../ControlledFileUploadInput';

interface AddParticipantsFormProps {
  isOpen: boolean;
  onClose(): void;
  transactionId: string;
}

const OPCITY_KEY = 'opCity';

const AddParticipantsForm: React.FC<AddParticipantsFormProps> = ({
  isOpen,
  onClose,
  transactionId,
}) => {
  const {
    userIds: { usersById },
  } = useSelector((state: RootState) => state);
  const {
    control,
    errors,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const dispatch: AppDispatch = useDispatch();
  const type = watch('type', PaymentParticipantValueTypeEnum.ExternalEntity);
  const participantRole: ParticipantValueRoleEnum = watch('participantRole');
  const yentaId = watch('yentaId', { value: null });

  useEffect(() => {
    if (yentaId.value) {
      dispatch(fetchUserByIds([yentaId.value]));
    }
  }, [dispatch, yentaId.value]);

  const onSubmit = async (data: any) => {
    if (data.type === OPCITY_KEY) {
      await dispatch(addOPCityCommissionParticipant(transactionId));
    } else {
      const finalData: Required<CreateParticipantRequest> = {
        emailAddress:
          data.emailAddress || usersById[data.yentaId?.value]?.emailAddress,
        firstName: data.firstName || usersById[data.yentaId?.value]?.firstName,
        lastName: data.lastName || usersById[data.yentaId?.value]?.lastName,
        phoneNumber:
          data.phoneNumber || usersById[data.yentaId?.value]?.phoneNumber,
        yentaId: data.yentaId?.value,
        commissionDocumentRecipient: !!data.commissionDocumentRecipient,
        payer: false,
        passThrough: !!data.passThrough,
        paidByReal: !!data.paidByReal,
        personalDeal: !!data.personalDeal,
        address: data.address,
        company: data.company || '',
        paymentInstructions: '',
        participantRole: data.participantRole,
      };
      const participant = await dispatch(
        addCommissionParticipant(transactionId, finalData),
      );

      if (data.w9form && participant) {
        await dispatch(uploadW9Form(participant.id!, data.w9form[0]));
      }
    }
    onClose();
  };

  return (
    <SidebarModal
      title='Add Participant'
      subtitle='Add participant to this transaction.'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-2 md:p-4'>
          <IconButton
            leftIcon={<ChevronLeft />}
            label='Back'
            variant='outline'
            onClick={onClose}
          />

          <div className='mt-5'>
            <ControlledRadioInput
              errors={errors}
              label='Participant Type'
              options={[
                {
                  label: 'Real Agent',
                  value: PaymentParticipantValueTypeEnum.Agent,
                },
                {
                  label: 'External Participant',
                  value: PaymentParticipantValueTypeEnum.ExternalEntity,
                },
                {
                  label: 'Op City',
                  value: OPCITY_KEY,
                },
              ]}
              name='type'
              defaultValue={PaymentParticipantValueTypeEnum.ExternalEntity}
              control={control}
              rules={{
                required: 'Required',
              }}
              inline
            />
          </div>
          {type !== OPCITY_KEY ? (
            <div>
              <div className='mt-5'>
                <ControlledSelectInput
                  name='participantRole'
                  control={control}
                  errors={errors}
                  label='Role'
                  placeholder='Role'
                  rules={{ required: 'Required' }}
                  options={[
                    {
                      label: `Select Role`,
                      value: '',
                      disabled: true,
                    },
                    ...values(ParticipantValueRoleEnum).map((role) => ({
                      value: role,
                      label: capitalizeEnum(role),
                    })),
                  ]}
                />
              </div>
              {PaymentParticipantValueTypeEnum.Agent === type ? (
                <div className='mt-5'>
                  <ControlledAsyncSelectInput
                    control={control}
                    errors={errors}
                    name='yentaId'
                    placeholder='Search'
                    label='Name of Participant'
                    fetchData={async (search, page) => {
                      const searchResponse = await searchForRegisteredAgents<
                        Pick<AgentResponse, 'id' | 'firstName' | 'lastName'>
                      >(
                        ['id', 'firstName', 'lastName'],
                        ['firstName', 'lastName'],
                        page,
                        search,
                      );

                      const options: AsyncSelectOption[] = searchResponse.map(
                        (resp) => ({
                          value: `${resp.id}`,
                          label: `${resp.firstName} ${resp.lastName}`,
                        }),
                      );

                      return options;
                    }}
                    rules={{
                      required: 'Required',
                    }}
                  />
                </div>
              ) : (
                <div>
                  <div className='mt-5 flex flex-row space-x-2'>
                    <ControlledTextInput
                      control={control}
                      label='First Name'
                      name='firstName'
                      placeholder='John'
                      errors={errors}
                      rules={{
                        minLength: {
                          value: 2,
                          message: 'Must be at least 2 characters',
                        },
                        pattern: {
                          value: NAME_REGEX,
                          message: 'Invalid Name',
                        },
                      }}
                    />
                    <ControlledTextInput
                      control={control}
                      label='Last Name'
                      name='lastName'
                      placeholder='Doe'
                      errors={errors}
                      rules={{
                        minLength: {
                          value: 2,
                          message: 'Must be at least 2 characters',
                        },
                        pattern: {
                          value: NAME_REGEX,
                          message: 'Invalid Name',
                        },
                      }}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledTextInput
                      control={control}
                      label='Email'
                      name='emailAddress'
                      placeholder='jane@example.com'
                      errors={errors}
                      rules={{
                        ...EMAIL_VALIDATIONS,
                      }}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledPhoneNumberInput
                      control={control}
                      label='Mobile Number'
                      name='phoneNumber'
                      placeholder='+1 (702) 123-4567'
                      errors={errors}
                      rules={{
                        ...PHONE_NUMBER_VALIDATIONS,
                      }}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledTextInput
                      control={control}
                      label='Address'
                      name='address'
                      placeholder='P150, New Amsterdam, New York'
                      errors={errors}
                      rules={{
                        required: 'Please enter your address',
                      }}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledTextInput
                      control={control}
                      label='Brokerage Name'
                      name='company'
                      placeholder='Brokerage Name'
                      errors={errors}
                      rules={{
                        required: 'Please enter your brokerage name',
                      }}
                    />
                  </div>
                  <div className='mt-5'>
                    <ControlledFileUploadInput
                      errors={errors}
                      name='w9form'
                      control={control}
                      label='Upload W9 PDF'
                      placeholder='Choose file'
                      accept='.pdf'
                    />
                  </div>
                </div>
              )}
              {participantRole !== ParticipantValueRoleEnum.ExternalAgent && (
                <div className='mt-5 pb-20'>
                  <div className='divide-y border rounded'>
                    <ControlledToggleInput
                      name='personalDeal'
                      control={control}
                      errors={errors}
                      defaultValue={false}
                      title='Personal Deal?'
                    />
                    <ControlledToggleInput
                      name='commissionDocumentRecipient'
                      control={control}
                      errors={errors}
                      defaultValue={false}
                      title='Receives Invoice/CDA?'
                    />
                    <ControlledToggleInput
                      name='paidByReal'
                      control={control}
                      errors={errors}
                      defaultValue={false}
                      title='Is Single Check?'
                    />
                    <ControlledToggleInput
                      name='passThrough'
                      control={control}
                      errors={errors}
                      defaultValue={false}
                      title='Pass Through Deal?'
                    />
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200  bottom-0 space-x-5 flex flex-row justify-start items-center sticky py-2 w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Add New'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default AddParticipantsForm;
