import config from '../config';

export const getStripePaymentUrl = (paymentId: string) => {
  let url = `https://dashboard.stripe.com`;

  if (config.stripe.publishableKey.includes('test')) {
    url += '/test';
  }

  return url + `/payments/${paymentId}`;
};
