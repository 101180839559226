import React from 'react';
import IconButton from '../IconButton';

export interface StepPaginationProps {
  previousText?: string;
  nextText?: string;
  showSkip?: boolean;
  onPrevious?(): void;
  onNext?(): void;
  onSkip?(): void;
}

const StepPagination: React.FC<StepPaginationProps> = ({
  previousText = 'Previous',
  nextText = 'Next',
  showSkip,
  onPrevious,
  onNext,
  onSkip,
}) => (
  <div className='flex'>
    {previousText && (
      <IconButton label={previousText} onClick={onPrevious} variant='outline' />
    )}
    <div className='flex ml-auto'>
      {showSkip && (
        <button
          type='button'
          onClick={onSkip}
          className='py-1 px-2 focus:outline-none mr-3 font-primary-light text-gray-400'
        >
          Skip
        </button>
      )}
      {nextText && (
        <IconButton buttonType='submit' label={nextText} onClick={onNext} />
      )}
    </div>
  </div>
);

export default StepPagination;
