import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MainMenu from '../components/MainMenu';
import { isSmScreen } from '../utils/BrowserUtils';

interface MainSidebarProps {}

const MainSidebar: React.FC<MainSidebarProps> = () => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    // This will prevent the background from being scrolled through the menu on mobile
    document.body.style.overflow = isCollapsed ? 'auto' : 'hidden';
  }, [isCollapsed]);

  // hide sidebar on mobile of the location path changes
  useEffect(() => {
    if (isSmScreen()) {
      setIsCollapsed(true);
    }
  }, [location.pathname]);

  return <MainMenu isCollapsed={isCollapsed} toggleCollapse={setIsCollapsed} />;
};

export default MainSidebar;
