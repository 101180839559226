import { EnumMap, FeatureFlagRow, FeatureFlagTypeEnum } from '../types';

export const FEATURE_FLAG_CONFIG: EnumMap<
  FeatureFlagTypeEnum,
  FeatureFlagRow
> = {
  [FeatureFlagTypeEnum.BASIC_FEATURE]: {
    name: 'Basic Feature',
    subtitle: 'This enables basic feature',
  },
  [FeatureFlagTypeEnum.ADMIN_FEATURE]: {
    name: 'Admin Feature',
    subtitle: 'This enables admin features',
  },
};
