import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Column } from 'react-table';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import {
  OutgoingPaymentResponse,
  SearchParamOperatorEnum,
  SearchRequestTargetObjectEnum,
} from '../openapi/arrakis';
import OutgoingPaymentStatusCell from '../components/table/Cells/OutgoingPaymentStatusCell';
import { resourceTableFetchData } from '../utils/TableUtils';

interface Match {
  id: string;
}

interface AgentDetailPaymentsRouteProps extends RouteComponentProps<Match> {}

export const columns: Array<Column<OutgoingPaymentResponse>> = [
  {
    Header: 'Payment Details',
    accessor: 'displayLine1',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value, row }) => `${value}  ${row.original.displayLine2!}`,
    cardColSize: 7,
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'state',
    Cell: ({ value }) => <OutgoingPaymentStatusCell type={value!} />,
    disableSortBy: true,
  },
];

const AgentDetailPaymentsRoute: React.FC<AgentDetailPaymentsRouteProps> = ({
  match,
}) => {
  const agentId = match.params.id;
  const columnsToFetch = [
    ...columns.map((col) => col.accessor as string),
    'displayLine2',
  ];

  return (
    <div className='p-4'>
      <ResourceIndexContainer<OutgoingPaymentResponse>
        header='Payments'
        columns={columns}
        resourceName='Payment'
        fetchData={(req) =>
          resourceTableFetchData(
            req,
            columnsToFetch,
            [],
            SearchRequestTargetObjectEnum.OutgoingPayment,
            'arrakis',
            {
              filterAnd: [
                {
                  column: 'yentaUserId',
                  operator: SearchParamOperatorEnum.Eq,
                  value: agentId,
                  stringStrictCase: true,
                },
              ],
            },
          )
        }
        hidePagination
        hideFilters
      />
    </div>
  );
};
export default AgentDetailPaymentsRoute;
