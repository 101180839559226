import React, { useState } from 'react';
import { Column, Row } from 'react-table';
import { FiDollarSign } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import ResourceIndexContainer from '../../containers/ResourceIndexContainer';
import { resourceTableFetchData } from '../../utils/TableUtils';
import AddAgentCreditFormSidebarModal from '../../forms/AddAgentCreditFormSidebarModal';
import SectionHeader from '../SectionHeader';
import {
  CreditResponse,
  SearchRequestTargetObjectEnum,
} from '../../openapi/arrakis';
import { AgentResponse, SearchParamOperatorEnum } from '../../openapi/yenta';
import { numberToMoney } from '../../utils/CurrencyUtils';
import ConfirmDeleteRecordModal from '../ConfirmDeleteRecordModal';
import EditAgentCreditFormSidebarModal from '../../forms/EditAgentCreditFormSidebarModal';
import { capitalizeEnum } from '../../utils/StringUtils';
// import ActionCell from '../table/Cells/ActionCell';

export const columns: Array<Column<CreditResponse>> = [
  {
    Header: 'Title',
    accessor: 'title',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => capitalizeEnum(value!),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }: { row: Row<CreditResponse> }) =>
      row.original.amount
        ? numberToMoney(row.original.amount.amount!)
        : row.original.number,
  },
  {
    Header: 'Issued On',
    accessor: 'issuedOn',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Issuer Note',
    accessor: 'issuerNote',
    disableFilters: true,
    disableSortBy: true,
  },
];

export const columnsToFetch = [
  ...columns.map((col) => col.accessor as string),
  'number',
];

interface AgentCreditsProps {
  agent: AgentResponse;
}

const AgentCredits: React.FC<AgentCreditsProps> = ({ agent }) => {
  const history = useHistory();
  const [addAgentCredit, setAddAgentCredit] = useState<boolean>(false);
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const [
    deletingAgentCredit,
    setDeletingAgentCredit,
  ] = useState<CreditResponse | null>(null);
  const [
    editingAgentCredit,
    setEditingAgentCredit,
  ] = useState<CreditResponse | null>(null);

  const columnsWithAction: Array<Column<CreditResponse>> = [
    ...columns,
    // {
    //   Header: 'Actions',
    //   accessor: 'title',
    //   id: 'actions',
    //   disableSortBy: true,
    //   disableFilters: true,
    //   Cell: ({ row: { original } }) => (
    //     <ActionCell<CreditResponse>
    //       data={original}
    //       onDelete={() => {
    //         setDeletingAgentCredit(original);
    //         setAddAgentCredit(false);
    //       }}
    //       onEdit={() => {
    //         setEditingAgentCredit(original);
    //         setAddAgentCredit(false);
    //       }}
    //     />
    //   ),
    // },
  ];

  return (
    <div>
      <SectionHeader
        icon={<FiDollarSign />}
        title='Credits'
        actionText='Add'
        onActionClick={() => setAddAgentCredit(true)}
      />
      <div className='mt-4'>
        <ResourceIndexContainer<CreditResponse>
          resourceName='credit'
          columns={columnsWithAction}
          fetchData={async (req) => {
            const res = await resourceTableFetchData(
              { ...req, pageSize: 10 },
              columnsToFetch,
              [],
              SearchRequestTargetObjectEnum.AgentCredit,
              'arrakis',
              {
                joinOn: ['agent'],
                filterAnd: [
                  {
                    column: 'id',
                    joinColumn: 'agent',
                    value: agent.id,
                    operator: SearchParamOperatorEnum.Eq,
                    stringStrictCase: true,
                  },
                ],
              },
            );

            setSeeAll(res.total > 10);
            return res;
          }}
          initialSort={{ issuedOn: 'desc' }}
          hideFilters
          hidePagination
        />
        {seeAll && (
          <button
            onClick={() => history.push(`/people/${agent.id}/credits`)}
            className='text-primary font-primary-medium focus:outline-none hover:opacity-70 px-4 py-2'
          >
            See all
          </button>
        )}
      </div>
      <AddAgentCreditFormSidebarModal
        isOpen={addAgentCredit}
        onClose={() => setAddAgentCredit(false)}
        agentId={agent.id!}
      />
      <EditAgentCreditFormSidebarModal
        isOpen={!!editingAgentCredit}
        agentCredit={editingAgentCredit!}
        onClose={() => setEditingAgentCredit(null)}
      />
      <ConfirmDeleteRecordModal
        onDelete={() => {}}
        title='Are you sure?'
        subtitle='This agent credit will be permanently deleted.'
        isOpen={!!deletingAgentCredit}
        onClose={() => setDeletingAgentCredit(null)}
      />
    </div>
  );
};
export default React.memo(AgentCredits);
