/* eslint-disable react/jsx-key */
/* disabling eslint because .map keys is handled by plugin */
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import classNames from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { TableInstance, useAsyncDebounce } from 'react-table';
import { map } from 'lodash';
import { IPaginateReq, IPaginateRes } from '../types';
import Logger from '../utils/Logger';
import TableResourceContainer from '../components/TableResourceContainer';
import ControlDisplayItems from '../components/table/ControlDisplayItems';
import TableLoader from '../components/TableLoader';
import ErrorService from '../services/ErrorService';
import NextPrevPagination from '../components/table/NextPrevPagination';
import { InitialSort } from './ResourceIndexContainer';

export enum ResourceTableVariant {
  ROW = 'row',
  CARD = 'card',
}

interface GeneralTableProps<D extends object> extends TableInstance<D> {
  fetchData: (
    req: IPaginateReq<D>,
  ) => Promise<IPaginateRes<D>> | IPaginateRes<D>;
  resourceName: string;
  variant?: ResourceTableVariant;
  hidePagination?: boolean;
  initialSort?: InitialSort<D>;
}

const ResourceTable = <D extends object>({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  visibleColumns,
  gotoPage,
  setPageSize,
  resourceName,
  state,
  fetchData,
  pageCount,
  variant = ResourceTableVariant.ROW,
  hidePagination = false,
}: GeneralTableProps<D>) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [paginatedRes, setPaginatedRes] = useState<IPaginateRes<D>>({
    total: 0,
    data: [],
  });

  const onStateChange = useAsyncDebounce(async () => {
    setLoading(true);
    try {
      Logger.debug('Fetching API with state:', state);

      const req: IPaginateReq<D> = {
        page: state.pageIndex,
        pageSize: state.pageSize,
        search: state.globalFilter || undefined,
        filter: Object.assign(
          {},
          ...map(state.filters, ({ id, value }) => ({ [id]: value })),
        ),
        sortBy: Object.assign(
          {},
          ...map(state.sortBy, ({ id, desc }) => ({
            [id]: desc ? 'desc' : 'asc',
          })),
        ),
      };

      const res = await fetchData(req);

      setPaginatedRes(res);
      setIsError(false);
    } catch (e) {
      ErrorService.notify('Unable to fetch data', e);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }, 400);

  useEffect(() => {
    onStateChange();
  }, [state, onStateChange]);

  if (loading && !paginatedRes.data.length) {
    return <TableLoader />;
  }

  return (
    <div className='w-full overflow-x-auto'>
      <table {...getTableProps()} className='relative table-auto w-full'>
        {variant === ResourceTableVariant.ROW && (
          <thead className='bg-secondary-gray'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className='font-primary-regular font-normal align-top p-2 whitespace-nowrap'
                  >
                    <div
                      className={classNames('flex flex-row flex-nowrap', {
                        'cursor-pointer': column.canSort,
                      })}
                      onClick={() => {
                        if (column.canSort) {
                          column.toggleSortBy(
                            column.isSorted && !column.isSortedDesc,
                          );
                        }
                      }}
                    >
                      {column.render('Header')}
                      {column.canSort && (
                        <div className='flex flex-col flex-nowrap justify-end'>
                          <ArrowDropUpIcon
                            className={classNames(
                              'text-gray-400 -mt-1',
                              column.isSorted && !column.isSortedDesc
                                ? 'invisible'
                                : 'visible',
                            )}
                            fontSize='small'
                          />
                          <ArrowDropDownIcon
                            className={classNames(
                              'text-gray-400 -mt-3.5',
                              column.isSorted && column.isSortedDesc
                                ? 'invisible'
                                : 'visible',
                            )}
                            fontSize='small'
                          />
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody
          {...getTableBodyProps()}
          className={classNames({
            'grid grid-flow-row grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4':
              variant === ResourceTableVariant.CARD,
          })}
        >
          <TableResourceContainer
            loading={loading}
            isEmpty={!paginatedRes.data.length}
            isError={isError}
            resourceName={resourceName}
            colSpan={visibleColumns.length}
          >
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classNames({
                    'border-b border-secondary-gray':
                      variant === ResourceTableVariant.ROW,
                    'grid grid-cols-12 grid-flow-row':
                      variant === ResourceTableVariant.CARD,
                  })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={classNames('align-middle p-2', {
                          [`border col-span-${cell.column.cardColSize || 12}`]:
                            variant === ResourceTableVariant.CARD,
                        })}
                      >
                        {variant === ResourceTableVariant.CARD && (
                          <p className='text-xs text-gray-400 font-primary-medium uppercase'>
                            {cell.column.render('Header')}
                          </p>
                        )}
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </TableResourceContainer>
        </tbody>
        {!hidePagination && (
          <tfoot>
            <tr>
              <td colSpan={visibleColumns.length} className='text-left py-5'>
                <div className='flex flex-row items-center justify-start lg:justify-between'>
                  <div className='hidden lg:block'>
                    <ControlDisplayItems
                      itemsToShow={state.pageSize}
                      setPageSize={setPageSize}
                    />
                  </div>
                  <NextPrevPagination
                    currentPage={state.pageIndex}
                    lastPage={pageCount}
                    goToPage={gotoPage}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default ResourceTable;
