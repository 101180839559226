import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export interface SideBarItemProps {
  icon: React.ReactElement;
  label: string;
  linkTo?: string;
  collapsed?: boolean;
  exact?: boolean;
}

const SideBarItem: React.FC<SideBarItemProps> = ({
  icon,
  label,
  linkTo = '/',
  collapsed = false,
  exact = false,
}) => {
  return (
    <NavLink
      to={linkTo}
      className='flex flex-row rounded items-center my-2 p-1 px-2 text-white hover:text-white hover:bg-trueGray-700'
      activeClassName='sidebar-item-active'
      exact={exact}
    >
      {icon && (
        <span className={classNames('p-1 pr-3 flex items-center')}>{icon}</span>
      )}
      {!collapsed && (
        <span className={classNames('items-center')}>{label}</span>
      )}
    </NavLink>
  );
};

export default SideBarItem;
