import React from 'react';
import { FilterProps } from 'react-table';
import { keys } from 'lodash';
import { DateTime } from 'luxon';
import {
  DateFilterType,
  DateFilterTypeLabel,
  FilterColumnsToProcess,
} from '../../../types';
import ControlledTextInput from '../../ControlledTextInput';
import ControlledSelectInput from '../../ControlledSelectInput';
import ControlledDatePickerInput from '../../ControlledDatePickerInput';
import { PROCESS_FILTER_COLUMN } from '../../../utils/TableUtils';
import { NUMBER_REGEX } from '../../../utils/StringUtils';

const dateOptions = keys(DateFilterType) as DateFilterType[];

export interface DatePickerColumnFilterProps<D extends object>
  extends FilterProps<D> {}

const DateColumnFilter = <D extends object>({
  column,
  errors,
  watch,
  control,
}: DatePickerColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}[${FilterColumnsToProcess.DATE}][${column.id}]`;
  const selectedDateType =
    (watch(`${prefixFieldName}[type]`) as DateFilterType) ||
    DateFilterType.inTheLast;
  const startDate = watch(`${prefixFieldName}[start_date]`);
  const endDate = watch(`${prefixFieldName}[end_date]`);

  return (
    <div className='space-y-1'>
      <ControlledSelectInput
        control={control}
        errors={errors}
        options={dateOptions.map((dateOption) => ({
          label: DateFilterTypeLabel[dateOption],
          value: dateOption,
        }))}
        name={`${prefixFieldName}[type]`}
        rules={{
          required: 'Required',
        }}
        defaultValue={DateFilterType.inTheLast}
      />
      {selectedDateType === DateFilterType.inTheLast && (
        <div className='grid grid-cols-2 gap-1'>
          <ControlledTextInput
            control={control}
            errors={errors}
            type='number'
            name={`${prefixFieldName}[duration]`}
            rules={{
              required: 'Required',
              pattern: { value: NUMBER_REGEX, message: 'Please input number' },
            }}
            defaultValue=''
          />
          <ControlledSelectInput
            control={control}
            errors={errors}
            options={[
              { label: 'Day', value: 'day' },
              { label: 'Month', value: 'month' },
            ]}
            name={`${prefixFieldName}[duration_type]`}
            rules={{
              required: 'Required',
            }}
            defaultValue='day'
          />
        </div>
      )}
      {selectedDateType !== DateFilterType.inTheLast && (
        <div className='flex justify-between space-x-1'>
          <ControlledDatePickerInput
            control={control}
            errors={errors}
            name={`${prefixFieldName}[start_date]`}
            rules={{
              required: 'Required',
            }}
            datePickerConfig={{
              selectsStart: selectedDateType === DateFilterType.between,
              startDate: startDate
                ? DateTime.fromISO(startDate).toJSDate()
                : undefined,
              maxDate: endDate
                ? DateTime.fromISO(endDate).toJSDate()
                : undefined,
            }}
          />
          {selectedDateType === DateFilterType.between && (
            <ControlledDatePickerInput
              control={control}
              errors={errors}
              name={`${prefixFieldName}[end_date]`}
              rules={{
                required: 'Required',
              }}
              datePickerConfig={{
                selectsEnd: true,
                endDate: endDate
                  ? DateTime.fromISO(endDate).toJSDate()
                  : undefined,
                minDate: startDate
                  ? DateTime.fromISO(startDate).toJSDate()
                  : undefined,
                popperModifiers: {},
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DateColumnFilter;
