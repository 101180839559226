import AgentSponsorTreeScreenShot from '../assets/images/AgentSponsorTreeScreenShot.png';
import AgentApplicationMapScreenShot from '../assets/images/AgentApplicationMapScreenShot.png';
import TransactionCommissionMapScreenShot from '../assets/images/TransactionCommissionMapScreenShot.png';
import AgentSponsorsTreeRoute from '../Routes/AgentSponsorsTreeRoute';
import AgentApplicationsMapRoute from '../Routes/AgentApplicationsMapRoute';
import TransactionCommissionMapRoute from '../Routes/TransactionCommissionMapRoute';

export interface ExperimentalProject {
  id: string;
  title: string;
  desc: string;
  imgSrc: string;
  link: string;
  component: React.FC<any>;
}

export const Projects: ExperimentalProject[] = [
  {
    id: 'AgentSponsorsTree',
    title: 'Agent Sponsors Tree',
    desc: 'This is a tree that shows all the sponsors of all the agents.',
    imgSrc: AgentSponsorTreeScreenShot,
    link: '/experimental/AgentSponsorsTree',
    component: AgentSponsorsTreeRoute,
  },
  {
    id: 'AgentApplicationMap',
    title: 'Agent Application Map',
    desc:
      'This is a map that shows all the agent applications from various states',
    imgSrc: AgentApplicationMapScreenShot,
    link: '/experimental/AgentApplicationMap',
    component: AgentApplicationsMapRoute,
  },
  {
    id: 'TransactionCommitionMap',
    title: 'Transaction Commission Map',
    desc:
      'This is a map that shows the Trancsaction Commission from various states',
    imgSrc: TransactionCommissionMapScreenShot,
    link: '/experimental/TransactionCommitionMap',
    component: TransactionCommissionMapRoute,
  },
];
