import React from 'react';
import RealLogoImg from '../../assets/img/real_logo_blue.png';
import AppStore from '../../assets/img/AppStore.png';
import PlayStore from '../../assets/img/PlayStore.png';
import IPhones from '../../assets/img/IPhones.png';
import Pill from '../../components/Pill';

interface ContinueOnMobileRouteProps {}

const IPHONE_APP_STORE_LINK =
  'https://apps.apple.com/us/app/real-brokerage/id1548909981';

const ANDROID_APP_STORE_LINK = '#';

const ContinueOnMobileRoute: React.FC<ContinueOnMobileRouteProps> = () => {
  return (
    <div className='container mx-auto h-screen flex flex-col items-center justify-end'>
      <div className='lg:w-1/3 md:w-1/2 p-10'>
        <div className='w-full flex justify-center items-center'>
          <img src={RealLogoImg} className='h-5 print:h-5' alt='Real Logo' />
        </div>
        <p className='mt-5 font-primary-medium text-2xl text-center'>
          Get started with our mobile apps
        </p>
        <p className='mt-5 font-primary-regular text-base text-center'>
          Download the official Real app for Agents.
        </p>
        <div className='mt-5 w-full flex gap-3 justify-center items-center'>
          <a
            href={IPHONE_APP_STORE_LINK}
            target='_blank'
            rel='noreferrer'
            className='flex flex-col items-center'
          >
            <img
              src={AppStore}
              className='h-8 print:h-5 mb-5'
              alt='App Store Logo'
            />
            <Pill text='Available Now' variant='success' />
          </a>

          <a
            href={ANDROID_APP_STORE_LINK}
            className='flex flex-col items-center'
          >
            <img
              src={PlayStore}
              className='h-8 print:h-5 mb-5'
              alt='Play Store Logo'
            />
            <Pill text='Coming Soon' variant='primary' />
          </a>
        </div>

        <div className='mt-5'>
          <p className='font-primary text-gray-800'>
            Note: Google is taking a bit longer to review our app. We expect to
            have the app available for our Android users in the next few days!
          </p>
        </div>
      </div>
      <div className='flex justify-center'>
        <img src={IPhones} className='' alt='Mobile App Previews' />
      </div>
    </div>
  );
};

export default ContinueOnMobileRoute;
