import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  TeamControllerApi,
  TeamMemberResponse,
  TeamResponse,
  TeamResponseTypeEnum,
  UpdateTeamMemberCommissionSplitRequest,
} from '../../openapi/yenta';
import { NUMBER_REGEX } from '../../utils/StringUtils';
import Button from '../Button';
import ControlledTextInput from '../ControlledTextInput';
import SidebarModal from '../SidebarModal';
import ErrorService from '../../services/ErrorService';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { saveTeamDetailOverview } from '../../slices/TeamSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';

interface UpdateTeamMembersCommissionSplitSidebarModalProps {
  member: TeamMemberResponse | null;
  team: TeamResponse;
  onClose(): void;
  teamType: TeamResponseTypeEnum;
}

const UpdateTeamMembersCommissionSplitSidebarModal: React.FC<UpdateTeamMembersCommissionSplitSidebarModalProps> = ({
  member,
  team,
  onClose,
  teamType,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<UpdateTeamMemberCommissionSplitRequest>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const updateMembers = async (
    updateReq: UpdateTeamMemberCommissionSplitRequest,
  ) => {
    const api = new TeamControllerApi(getYentaConfiguration());

    if (team.type === TeamResponseTypeEnum.Normal) {
      return api.updateMemberInNormalTeamUsingPUT(
        member?.agent?.id!,
        team.id!,
        updateReq,
      );
    }

    return api.updateMemberInPlatinumTeamUsingPUT(
      member?.agent?.id!,
      team.id!,
      updateReq,
    );
  };

  const onSubmit = async (values: UpdateTeamMemberCommissionSplitRequest) => {
    try {
      const { data } = await updateMembers(values);
      dispatch(saveTeamDetailOverview(data));
      dispatch(showSuccessToast('Commission split updated.'));
      onClose();
    } catch (e) {
      dispatch(showErrorToast(e.response?.data?.apierror?.debugMessage));
      ErrorService.notify('Cannot update members commission split', e, {
        member: { ...member },
        data: values,
      });
    }
  };

  return (
    <SidebarModal
      title={`Update ${member?.agent?.fullName}'s Commission Split`}
      isOpen={!!member}
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='create-team-form'
      >
        <div className='p-4'>
          <ControlledTextInput
            control={control}
            errors={errors}
            name='commissionSplit'
            label='Commission Split'
            rules={{
              required: 'Commission split is required.',
              min: {
                value: 0,
                message: 'Min. is 0.',
              },
              max: {
                value: teamType === TeamResponseTypeEnum.Normal ? 75 : 100,
                message:
                  teamType === TeamResponseTypeEnum.Normal
                    ? 'Max is 75.'
                    : 'Max is 100.',
              },
              pattern: {
                value: NUMBER_REGEX,
                message: 'Please input a valid number.',
              },
            }}
            defaultValue={member?.memberCommissionSplit}
          />
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200  bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2  w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={onClose}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default UpdateTeamMembersCommissionSplitSidebarModal;
