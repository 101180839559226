import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import Logger from '../utils/Logger';

export interface GuestRouteProps extends RouteProps {}

const GuestRoute: React.FC<GuestRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userDetail) {
          Logger.debug('GuestRoute: redirecting to /');
        } else {
          Logger.debug('GuestRoute: redirecting to component.');
        }

        // @ts-ignore
        return userDetail ? <Redirect to='/' /> : <Component {...props} />;
      }}
    />
  );
};

export default GuestRoute;
