import { DoneAll } from '@material-ui/icons';
import React, { useState } from 'react';

interface CopyToClipboardProps {
  label?: string;
  value: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  value,
  label = 'Copy to Clipboard',
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [text] = useState(value);
  const onCopyText = () => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(text);
    else {
      // text area method
      let textArea = document.createElement('textarea');
      textArea.value = text;
      // make the textarea out of viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className='my-1 flex flex-row items-center space-x-2'>
      <button
        className='flex items-center text-black space-x-1 rounded bg-white border border-gray-200 ring-gray-200 ring-1 border-1 p-0.5 focus:outline-none'
        onClick={() => onCopyText()}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 24 24'
        >
          <path
            d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z'
            fill='black'
          />
        </svg>
        <p className='text-xs'>{label}</p>
      </button>
      {isCopied && (
        <div className='flex space-x-1 items-center justify-center my-1'>
          <DoneAll style={{ fontSize: 16 }} />
          <p className='text-xs'>Copied</p>
        </div>
      )}
    </div>
  );
};

export default CopyToClipboard;
