import { MonitorEventResponseInvestigationStatusEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

const MonitorEventResponseInvestigationStatusEnumSelectColumnFilter = <
  D extends object
>(
  props: SelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(
    MonitorEventResponseInvestigationStatusEnum,
  )(props);
};

export default MonitorEventResponseInvestigationStatusEnumSelectColumnFilter;
