import { FilterValue } from 'react-table';
import { flatten, isObject, keys, map, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import {
  DateFilterType,
  FilterColumnsToProcess,
  IPaginateReq,
  ISelectOption,
  NumberFilterType,
  SearchAPIType,
} from '../types';
import {
  InitialSort,
  TableResourceFilter,
} from '../containers/ResourceIndexContainer';
import {
  SearchControllerApi,
  SearchOrder,
  SearchParam,
  SearchParamOperatorEnum,
  SearchRequest,
  SearchRequestTargetObjectEnum,
  SearchResponse as YentaSearchResponse,
  UserResponseAgentStatusEnum,
} from '../openapi/yenta';
import {
  SearchControllerApi as SearchControllerApiArrakis,
  SearchRequest as SearchRequestArrakis,
  SearchRequestTargetObjectEnum as SearchRequestTargetObjectEnumArrakis,
  SearchResponse as ArrakisSearchResponse,
} from '../openapi/arrakis';
import {
  getArrakisConfiguration,
  getYentaConfiguration,
} from './OpenapiConfigurationUtils';
import { capitalizeEnum } from './StringUtils';

export const PROCESS_FILTER_COLUMN = 'PROCESS_FILTER_COLUMN';

export type IFilter = {
  [id in string]: FilterValue;
};

const processDatesFilter = (value: FilterValue, name: string): IFilter => {
  const config: {
    [type in DateFilterType]: { [x: string]: Array<SearchParam> };
  } = {
    [DateFilterType.inTheLast]: {
      [name]: [
        {
          value: DateTime.local()
            .minus({
              [value['duration_type']]: value['duration'],
            })
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
      ],
    },
    [DateFilterType.between]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .startOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
        {
          value: DateTime.fromISO(value['end_date'])
            .endOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
    [DateFilterType.equals]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .startOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gt,
        },
        {
          value: DateTime.fromISO(value['start_date'])
            .endOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Lt,
        },
      ],
    },
    [DateFilterType.isAfter]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .endOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gt,
        },
      ],
    },
    [DateFilterType.isAfterOrOn]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .startOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
      ],
    },
    [DateFilterType.isBefore]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .startOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Lt,
        },
      ],
    },
    [DateFilterType.isBeforeOrOn]: {
      [name]: [
        {
          value: DateTime.fromISO(value['start_date'])
            .endOf('day')
            .toMillis()
            .toString(),
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
  };

  return config[value['type'] as DateFilterType];
};

const processEnumsFilter = (value: FilterValue, name: string): IFilter => {
  return {
    [name]: {
      value: value,
      column: name,
      operator: SearchParamOperatorEnum.Eq,
    },
  };
};

const processStrictCaseFilter = (value: FilterValue, name: string): IFilter => {
  return {
    [name]: {
      value: value,
      column: name,
      operator: SearchParamOperatorEnum.Eq,
      stringStrictCase: true,
    },
  };
};

const processBooleanFilter = (value: FilterValue, name: string): IFilter => {
  return {
    [name]: {
      value: value,
      column: name,
      operator: SearchParamOperatorEnum.Eq,
    },
  };
};

const processNumbersFilter = (value: FilterValue, name: string): IFilter => {
  const config: {
    [type in NumberFilterType]: { [x: string]: Array<SearchParam> };
  } = {
    [NumberFilterType.equals]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Eq,
        },
      ],
    },
    [NumberFilterType.between]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
        {
          value: value['end_number'],
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
    [NumberFilterType.greaterThan]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Gt,
        },
      ],
    },
    [NumberFilterType.greaterThanOrEqual]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Gte,
        },
      ],
    },
    [NumberFilterType.lessThan]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Lt,
        },
      ],
    },
    [NumberFilterType.lessThanOrEqual]: {
      [name]: [
        {
          value: value['start_number'],
          column: name,
          operator: SearchParamOperatorEnum.Lte,
        },
      ],
    },
  };

  return config[value['type'] as NumberFilterType];
};

export const getProcessedFilterData = (
  value: FilterValue,
  type: FilterColumnsToProcess,
): Array<IFilter> => {
  if (type === FilterColumnsToProcess.DATE) {
    return map(value, (value: FilterValue, name: string) =>
      processDatesFilter(value, name),
    );
  }

  if (type === FilterColumnsToProcess.ENUM) {
    return map(value, (value: FilterValue, name: string) =>
      processEnumsFilter(value, name),
    );
  }

  if (type === FilterColumnsToProcess.STRICT_CASE) {
    return map(value, (value: FilterValue, name: string) =>
      processStrictCaseFilter(value, name),
    );
  }

  if (type === FilterColumnsToProcess.BOOLEAN) {
    return map(value, (value: FilterValue, name: string) =>
      processBooleanFilter(value, name),
    );
  }

  return map(value, (value: FilterValue, name: string) =>
    processNumbersFilter(value, name),
  );
};

export const mapToStateFilter = <D extends object>(
  filter?: TableResourceFilter<D>,
) =>
  map(filter, (value, id) => ({
    id,
    value,
  }));

export const mapToStateSortBy = <D extends object>(
  initialSort?: InitialSort<D>,
) =>
  map(initialSort, (value, id) => ({
    id,
    desc: value === 'desc',
  }));

export const getPageCount = (total: number, pageSize: number) =>
  Math.ceil(total / pageSize);

export const getJoinedColumnFilter = (
  filter: FilterValue,
  joinColumnsMap: { [column: string]: string },
) =>
  !!joinColumnsMap[filter.column]
    ? { ...filter, joinColumn: joinColumnsMap[filter.column] }
    : filter;

export const resourceTableFetchData = async <D extends object>(
  req: IPaginateReq<D>,
  responseColumns: string[],
  searchColumns: string[],
  targetObject:
    | SearchRequestTargetObjectEnum
    | SearchRequestTargetObjectEnumArrakis,
  apiBuild: SearchAPIType = 'yenta',
  searchDefaults: Partial<SearchRequest> = {},
  joinColumnsMap: { [column: string]: string } = {},
) => {
  let api: any;
  if (apiBuild === 'yenta') {
    api = new SearchControllerApi(getYentaConfiguration());
  }
  if (apiBuild === 'arrakis') {
    api = new SearchControllerApiArrakis(getArrakisConfiguration());
  }

  const order = map(req.sortBy, (sortBy: 'asc' | 'desc', key: string) => ({
    column: key,
    asc: sortBy === 'asc',
  })) as SearchOrder[];

  const searchRequest: Required<SearchRequest | SearchRequestArrakis> = {
    filterAnd: sortBy(
      [
        ...flatten([
          ...map(req.filter, (filter: FilterValue, key: string) =>
            getJoinedColumnFilter(
              isObject(filter)
                ? filter
                : {
                    column: key,
                    operator: SearchParamOperatorEnum.Like,
                    value: filter,
                  },
              joinColumnsMap,
            ),
          ),
        ]),
        ...(searchDefaults.filterAnd || []),
      ],
      'column',
    ),
    joinOn: searchDefaults.joinOn || [],
    filterOr: req.search
      ? searchColumns.map((s) =>
          getJoinedColumnFilter(
            {
              column: s,
              operator: SearchParamOperatorEnum.Like,
              value: req.search,
            },
            joinColumnsMap,
          ),
        )
      : [],
    order: [...(searchDefaults.order || []), ...order],
    pageNumber: req.page + 1,
    pageSize: req.pageSize,
    responseColumns: responseColumns,
    targetObject,
  };
  const response = await api.searchUsingPOST(searchRequest);
  const hydratedData = hydrateSearchResponse<D>(response.data);

  return {
    data: hydratedData,
    total:
      response.data.pageNumber! * response.data.pageSize! +
      (response.data.hasNext! ? response.data.pageSize! : 0),
  };
};

export const resourceListFetchData = async <D extends object>(
  req: IPaginateReq<D>,
  search: string | undefined,
  searchParameters: {
    filterJoinColumn: string;
    filterValue: string;
    joinOn: string[];
    searchColumns: string[];
    responseColumns: string[];
    targetObject: SearchRequestTargetObjectEnum;
  },
) => {
  const api = new SearchControllerApi(getYentaConfiguration());

  const searchRequest: Required<SearchRequest> = {
    filterAnd: [
      {
        operator: SearchParamOperatorEnum.Eq,
        column: 'id',
        joinColumn: searchParameters.filterJoinColumn,
        stringStrictCase: true,
        value: searchParameters.filterValue,
      },
    ],
    joinOn: searchParameters.joinOn || [],
    filterOr: search
      ? searchParameters.searchColumns.map((s) => ({
          column: s,
          operator: SearchParamOperatorEnum.Like,
          value: search,
        }))
      : [],
    order: map(req.sortBy, (sortBy: 'asc' | 'desc', key: string) => ({
      column: key,
      asc: sortBy === 'asc',
    })) as SearchOrder[],
    pageNumber: req.page + 1,
    pageSize: req.pageSize,
    responseColumns: searchParameters.responseColumns,
    targetObject: searchParameters.targetObject,
  };
  const response = await api.searchUsingPOST(searchRequest);
  const hydratedData = hydrateSearchResponse<D>(response.data);

  return {
    data: hydratedData,
    total:
      response.data.pageNumber! * response.data.pageSize! +
      (response.data.hasNext! ? response.data.pageSize! : 0),
  };
};

export const performSearchRequest = async <T,>(
  responseColumns: string[],
  searchColumns: string[],
  page: number = 1,
  targetObject: SearchRequestTargetObjectEnum,
  search: string | undefined,
  searchDefaults: Partial<
    Pick<SearchRequest, 'filterAnd' | 'joinOn' | 'order'>
  > = {},
): Promise<T[]> => {
  const api = new SearchControllerApi(getYentaConfiguration());

  const searchRequest: Required<SearchRequest> = {
    filterAnd: [...(searchDefaults.filterAnd || [])],
    joinOn: [...(searchDefaults.joinOn || [])],
    filterOr: search
      ? searchColumns.map((s) => ({
          column: s,
          operator: SearchParamOperatorEnum.Like,
          value: search,
        }))
      : [],
    order: [...(searchDefaults.order || [])],
    pageNumber: page + 1,
    pageSize: 20,
    responseColumns: responseColumns,
    targetObject,
  };

  const response = await api.searchUsingPOST(searchRequest);

  return hydrateSearchResponse<T>(response.data);
};

export const searchForRegisteredAgents = async <T,>(
  responseColumns: string[],
  searchColumns: string[],
  page: number = 1,
  search: string | undefined,
): Promise<T[]> => {
  return performSearchRequest<T>(
    responseColumns,
    searchColumns,
    page,
    SearchRequestTargetObjectEnum.Agent,
    search,
    {
      filterAnd: [
        {
          column: 'status',
          operator: SearchParamOperatorEnum.Eq,
          value: UserResponseAgentStatusEnum.Active,
        },
      ],
    },
  );
};

export const hydrateSearchResponse = <T,>(
  searchResponse: YentaSearchResponse | ArrakisSearchResponse,
): T[] => {
  return searchResponse.responseRows!.map((row) =>
    hydrateSearchRow<T>(searchResponse.responseColumns!, row),
  );
};

export const hydrateSearchRow = <T,>(columns: any[], row: any[]): T => {
  const model: any = {};

  row.forEach((value: any, index) => {
    model[columns[index]] = value?.value ?? value;
  });

  return model;
};

export const enumFilter = (name: string, value: string): SearchParam => {
  return {
    column: name,
    value,
    operator: SearchParamOperatorEnum.Eq,
  };
};

export const getSelectOptionsForEnums = (enumObj: any): ISelectOption[] => {
  return keys(enumObj).map((key) => ({
    label: capitalizeEnum(enumObj[key]),
    value: enumObj[key],
  }));
};
