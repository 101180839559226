import React from 'react';
import { AdministrativeAreaResponseStateOrProvinceEnum } from '../../../openapi/yenta';
import { StateOrProvinceToDisplayName } from '../../../utils/DisplayHelpers';
import StateIcon from '../../StateIcon';

export interface StateIconCellProps {
  state: AdministrativeAreaResponseStateOrProvinceEnum;
}
const StateOrProvinceIconCell: React.FC<StateIconCellProps> = ({ state }) => {
  return (
    <div className='flex flex-row space-x-2 items-center'>
      <StateIcon state={state} width={30} variant='circle' />
      <p>{StateOrProvinceToDisplayName(state)}</p>
    </div>
  );
};

export default StateOrProvinceIconCell;
