import classNames from 'classnames';
import React from 'react';

interface CustomSwitchProps {
  label?: string;
  rightIcon?: string | React.ReactElement;
  leftIcon?: string | React.ReactElement;
  toggle: boolean;
  onClick?(): void;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  label = '',
  toggle,
  leftIcon,
  rightIcon,
  onClick,
}) => {
  return (
    <div
      className='flex flex-row space-x-3 cursor-pointer items-center'
      data-testid='toggle-switch'
      onClick={onClick}
    >
      <p>{label}</p>
      <button
        type='button'
        className={classNames(
          'bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ',
          toggle ? 'bg-primary' : 'bg-gray-200',
        )}
      >
        <span
          className={classNames(
            'translate-x-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            toggle ? 'translate-x-5' : 'translate-x-0',
          )}
        >
          <span
            className={classNames(
              'opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              toggle
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-200',
            )}
          >
            <div
              className={classNames(
                'text-gray-400',
                'text-xs',
                toggle ? 'hidden' : 'flex',
              )}
            >
              {leftIcon}
            </div>
          </span>
          <span
            className={classNames(
              'opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              toggle
                ? 'opacity-100 ease-in duration-200'
                : 'opacity-0 ease-out duration-100',
            )}
          >
            <div
              className={classNames(
                'text-gray-400',
                'text-xs',
                toggle ? 'flex' : 'hidden',
              )}
            >
              {rightIcon}
            </div>
          </span>
        </span>
      </button>
    </div>
  );
};

export default CustomSwitch;
