import {
  TeamMemberResponse,
  TeamMemberResponseRolesEnum,
  TeamResponseTypeEnum,
} from '../openapi/yenta';

export const isTeamMember = (membership: TeamMemberResponse): boolean => {
  return membership.roles?.indexOf(TeamMemberResponseRolesEnum.Member) !== -1;
};

export const isTeamLeader = (membership: TeamMemberResponse): boolean => {
  return membership.roles?.indexOf(TeamMemberResponseRolesEnum.Leader) !== -1;
};

export const teamRolesForType = (
  teamType: TeamResponseTypeEnum,
): TeamMemberResponseRolesEnum[] => {
  if (teamType === TeamResponseTypeEnum.Group) {
    return [
      TeamMemberResponseRolesEnum.Member,
      TeamMemberResponseRolesEnum.Admin,
    ];
  }

  return [
    TeamMemberResponseRolesEnum.Member,
    TeamMemberResponseRolesEnum.Leader,
  ];
};
