import React from 'react';
import { MlsResponseStatusEnum } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';

interface MlsStatusPillProps {
  status: MlsResponseStatusEnum;
}

const statusToVariant: EnumMap<MlsResponseStatusEnum, PillVariantType> = {
  ACTIVE: 'success',
  INACTIVE: 'danger',
  NOT_MEMBERS: 'warning',
  PENDING: 'warning',
  WILLING_TO_JOIN: 'primary',
};

const MlsStatusPill: React.FC<MlsStatusPillProps> = ({ status }) => {
  return (
    <Pill text={capitalizeEnum(status)} variant={statusToVariant[status]} />
  );
};

export default MlsStatusPill;
