/* tslint:disable */
/* eslint-disable */
/**
 * KeyMaker API
 * Authenticate with REAL credentials
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface GrantedAuthority
 */
export interface GrantedAuthority {
    /**
     * 
     * @type {string}
     * @memberof GrantedAuthority
     */
    authority?: string;
}
/**
 * 
 * @export
 * @interface IdentityAvailability
 */
export interface IdentityAvailability {
    /**
     * 
     * @type {boolean}
     * @memberof IdentityAvailability
     */
    available?: boolean;
}
/**
 * 
 * @export
 * @interface JwtAuthenticationResponse
 */
export interface JwtAuthenticationResponse {
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    tokenType?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtAuthenticationResponse
     */
    userId?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    usernameOrEmail: string;
}
/**
 * 
 * @export
 * @interface PasswordUpdateRequest
 */
export interface PasswordUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdateRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdateRequest
     */
    token: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    usernameOrEmail: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordResponse
     */
    expiryDate?: number;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordResponse
     */
    userId?: string;
}
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    username: string;
}
/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    accountNonExpired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    accountNonLocked?: boolean;
    /**
     * 
     * @type {Array<GrantedAuthority>}
     * @memberof UserDetails
     */
    authorities?: Array<GrantedAuthority>;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    credentialsNonExpired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    username?: string;
}

/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Auth Exchange with Auth Token
         * @param {string} jwtToken jwtToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingGET: async (jwtToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtToken' is not null or undefined
            assertParamExists('authenticateUserUsingGET', 'jwtToken', jwtToken)
            const localVarPath = `/api/v1/auth/verify/{jwtToken}`
                .replace(`{${"jwtToken"}}`, encodeURIComponent(String(jwtToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('authenticateUserUsingPOST', 'loginRequest', loginRequest)
            const localVarPath = `/api/v1/auth/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change Password
         * @param {PasswordUpdateRequest} passwordUpdateRequest passwordUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST: async (passwordUpdateRequest: PasswordUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordUpdateRequest' is not null or undefined
            assertParamExists('changePasswordUsingPOST', 'passwordUpdateRequest', passwordUpdateRequest)
            const localVarPath = `/api/v1/auth/changepassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailAvailabilityUsingGET: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('checkEmailAvailabilityUsingGET', 'email', email)
            const localVarPath = `/api/v1/auth/checkemailavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if username is available
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUsernameAvailabilityUsingGET: async (username: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('checkUsernameAvailabilityUsingGET', 'username', username)
            const localVarPath = `/api/v1/auth/checkusernameavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotpasswordUsingPOST: async (resetPasswordRequest: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('forgotpasswordUsingPOST', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/v1/auth/forgotpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign/Register
         * @param {SignUpRequest} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserUsingPOST: async (signUpRequest: SignUpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpRequest' is not null or undefined
            assertParamExists('registerUserUsingPOST', 'signUpRequest', signUpRequest)
            const localVarPath = `/api/v1/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ResetPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST: async (resetPasswordRequest: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetPasswordUsingPOST', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/v1/auth/resetpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Auth Exchange with Auth Token
         * @param {string} jwtToken jwtToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUserUsingGET(jwtToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUserUsingGET(jwtToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUserUsingPOST(loginRequest: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUserUsingPOST(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change Password
         * @param {PasswordUpdateRequest} passwordUpdateRequest passwordUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordUsingPOST(passwordUpdateRequest: PasswordUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordUsingPOST(passwordUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailAvailabilityUsingGET(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEmailAvailabilityUsingGET(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if username is available
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUsernameAvailabilityUsingGET(username: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUsernameAvailabilityUsingGET(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotpasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotpasswordUsingPOST(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign/Register
         * @param {SignUpRequest} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUserUsingPOST(signUpRequest: SignUpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUserUsingPOST(signUpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ResetPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordUsingPOST(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Auth Exchange with Auth Token
         * @param {string} jwtToken jwtToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingGET(jwtToken: string, options?: any): AxiosPromise<UserDetails> {
            return localVarFp.authenticateUserUsingGET(jwtToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(loginRequest: LoginRequest, options?: any): AxiosPromise<JwtAuthenticationResponse> {
            return localVarFp.authenticateUserUsingPOST(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change Password
         * @param {PasswordUpdateRequest} passwordUpdateRequest passwordUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(passwordUpdateRequest: PasswordUpdateRequest, options?: any): AxiosPromise<any> {
            return localVarFp.changePasswordUsingPOST(passwordUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if email is available
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailAvailabilityUsingGET(email: string, options?: any): AxiosPromise<IdentityAvailability> {
            return localVarFp.checkEmailAvailabilityUsingGET(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if username is available
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUsernameAvailabilityUsingGET(username: string, options?: any): AxiosPromise<IdentityAvailability> {
            return localVarFp.checkUsernameAvailabilityUsingGET(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotpasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<any> {
            return localVarFp.forgotpasswordUsingPOST(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign/Register
         * @param {SignUpRequest} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserUsingPOST(signUpRequest: SignUpRequest, options?: any): AxiosPromise<JwtAuthenticationResponse> {
            return localVarFp.registerUserUsingPOST(signUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ResetPassword
         * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<ResetPasswordResponse> {
            return localVarFp.resetPasswordUsingPOST(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @summary Auth Exchange with Auth Token
     * @param {string} jwtToken jwtToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUserUsingGET(jwtToken: string, options?: any) {
        return AuthControllerApiFp(this.configuration).authenticateUserUsingGET(jwtToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginRequest} loginRequest loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUserUsingPOST(loginRequest: LoginRequest, options?: any) {
        return AuthControllerApiFp(this.configuration).authenticateUserUsingPOST(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change Password
     * @param {PasswordUpdateRequest} passwordUpdateRequest passwordUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public changePasswordUsingPOST(passwordUpdateRequest: PasswordUpdateRequest, options?: any) {
        return AuthControllerApiFp(this.configuration).changePasswordUsingPOST(passwordUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if email is available
     * @param {string} email email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public checkEmailAvailabilityUsingGET(email: string, options?: any) {
        return AuthControllerApiFp(this.configuration).checkEmailAvailabilityUsingGET(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if username is available
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public checkUsernameAvailabilityUsingGET(username: string, options?: any) {
        return AuthControllerApiFp(this.configuration).checkUsernameAvailabilityUsingGET(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ForgotPassword
     * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public forgotpasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any) {
        return AuthControllerApiFp(this.configuration).forgotpasswordUsingPOST(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign/Register
     * @param {SignUpRequest} signUpRequest signUpRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public registerUserUsingPOST(signUpRequest: SignUpRequest, options?: any) {
        return AuthControllerApiFp(this.configuration).registerUserUsingPOST(signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ResetPassword
     * @param {ResetPasswordRequest} resetPasswordRequest resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public resetPasswordUsingPOST(resetPasswordRequest: ResetPasswordRequest, options?: any) {
        return AuthControllerApiFp(this.configuration).resetPasswordUsingPOST(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KeyMakerControllerApi - axios parameter creator
 * @export
 */
export const KeyMakerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary adminApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiCallUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keymaker/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary protectedApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        protectedApiCallUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keymaker/protected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary publicApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicApiCallUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keymaker/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeyMakerControllerApi - functional programming interface
 * @export
 */
export const KeyMakerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeyMakerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary adminApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiCallUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiCallUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary protectedApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async protectedApiCallUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.protectedApiCallUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary publicApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicApiCallUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicApiCallUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KeyMakerControllerApi - factory interface
 * @export
 */
export const KeyMakerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeyMakerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary adminApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiCallUsingGET(options?: any): AxiosPromise<string> {
            return localVarFp.adminApiCallUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary protectedApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        protectedApiCallUsingGET(options?: any): AxiosPromise<string> {
            return localVarFp.protectedApiCallUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary publicApiCall
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicApiCallUsingGET(options?: any): AxiosPromise<string> {
            return localVarFp.publicApiCallUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeyMakerControllerApi - object-oriented interface
 * @export
 * @class KeyMakerControllerApi
 * @extends {BaseAPI}
 */
export class KeyMakerControllerApi extends BaseAPI {
    /**
     * 
     * @summary adminApiCall
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyMakerControllerApi
     */
    public adminApiCallUsingGET(options?: any) {
        return KeyMakerControllerApiFp(this.configuration).adminApiCallUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary protectedApiCall
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyMakerControllerApi
     */
    public protectedApiCallUsingGET(options?: any) {
        return KeyMakerControllerApiFp(this.configuration).protectedApiCallUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary publicApiCall
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyMakerControllerApi
     */
    public publicApiCallUsingGET(options?: any) {
        return KeyMakerControllerApiFp(this.configuration).publicApiCallUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


