import React from 'react';
import { useForm } from 'react-hook-form';
import { values } from 'lodash';
import { useDispatch } from 'react-redux';
import SidebarModal from '../components/SidebarModal';
import ControlledTextInput from '../components/ControlledTextInput';
import Button from '../components/Button';
import ControlledSelectInput from '../components/ControlledSelectInput';
import { capitalizeEnum } from '../utils/StringUtils';
import ControlledTextAreaInput from '../components/ControlledTextAreaInput';
import ErrorService from '../services/ErrorService';
import {
  showErrorToast,
  showSuccessToast,
} from '../slices/ToastNotificationSlice';
import {
  OutgoingPaymentResponseStatusEnum,
  OutgoingPaymentsControllerApi,
} from '../openapi/arrakis';
import { getArrakisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { OutgoingPaymentsResponseModified } from '../components/outgoingPayment/OugoingPaymentsComponent';

interface EditOutgoingPaymentFormSidebarModalProps {
  isOpen: boolean;
  onClose(reload?: boolean): void;
  outgoingPayment: OutgoingPaymentsResponseModified;
}

const EditOutgoingPaymentFormSidebarModal: React.FC<EditOutgoingPaymentFormSidebarModalProps> = ({
  isOpen,
  onClose,
  outgoingPayment,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (values: any) => {
    try {
      await new OutgoingPaymentsControllerApi(
        getArrakisConfiguration(),
      ).updateOutgoingPaymentUsingPUT(outgoingPayment.id!, values);
      onClose(true);
      dispatch(showSuccessToast('Outgoing payment saved successfully.'));
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Error saving outgoing payment', e, {
        outgoingPayment: outgoingPayment!,
      });
      dispatch(
        showErrorToast(
          'We had a problem updating outgoing payments',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  return (
    <SidebarModal
      title={`Edit (${outgoingPayment?.transactionCode})`}
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <ControlledTextInput
            control={control}
            label='Confirmation Code'
            name='confirmationCode'
            defaultValue={outgoingPayment?.confirmationCode}
            errors={errors}
            rules={{
              required: 'Required',
            }}
          />
          <div className='mt-5'>
            <ControlledSelectInput
              control={control}
              label='Status'
              name='paymentStatus'
              defaultValue={outgoingPayment?.status}
              options={values(OutgoingPaymentResponseStatusEnum).map(
                (status) => ({
                  label: capitalizeEnum(status),
                  value: status,
                }),
              )}
              errors={errors}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5 pb-20'>
            <ControlledTextAreaInput
              control={control}
              label='Note'
              placeholder='Note for reference...'
              rows={4}
              name='payerNote'
              defaultValue={outgoingPayment?.payerNote}
              errors={errors}
            />
          </div>
        </div>
        <div className='p-4 bg-white border-t border-gray-200 sticky bottom-0 space-x-5'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EditOutgoingPaymentFormSidebarModal;
