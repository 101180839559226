import React from 'react';
import { EnumMap } from '../../../types';
import Pill, { PillVariantType } from '../../Pill';
import { TeamResponseTypeEnum } from '../../../openapi/yenta';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface TeamTypeCellProps {
  type: TeamResponseTypeEnum;
}

const pillTypeToString: EnumMap<TeamResponseTypeEnum, PillVariantType> = {
  GROUP: 'warning',
  PLATINUM: 'danger',
  DOMESTIC: 'success',
  NORMAL: 'primary',
};

const TeamTypeCell: React.FC<TeamTypeCellProps> = ({ type }) => {
  return <Pill text={capitalizeEnum(type)} variant={pillTypeToString[type]} />;
};

export default TeamTypeCell;
