import qs from 'qs';
import {
  AsyncSelectOption,
  GetCalendarEventsResponse,
  ITransactionsOverviewResponse,
} from '../types';
import { DefaultTransactionsOverviewResponse } from '../fixtures/api/transactions/DefaultTransactionsOverviewResponse';
import DefaultLanguagesPaginateResponse from '../fixtures/api/languages/DefaultLanguagesPaginateResponse';
import DefaultGetOfficeCalendarEventsResponse from '../fixtures/api/office/DefaultGetOfficeCalendarEventsResponse';
import DefaultGetMLSCalendarEventsResponse from '../fixtures/api/mls/DefaultGetMLSCalendarEventsResponse';
import { DEFAULT_PAGE_NUM } from '../constants/AsyncSelectPaginationConstants';
import DefaultGetBoardsCalendarEventsResponse from '../fixtures/api/boards/DefaultGetBoardsCalendarEventsResponse';
import { PaginateMockRealAPI } from './PaginateMockRealAPI';
import Logger from './Logger';

export default class MockRealApi extends PaginateMockRealAPI {
  public transactionOverview(
    _id: number,
  ): Promise<ITransactionsOverviewResponse> {
    return this.returnMock<ITransactionsOverviewResponse>(
      DefaultTransactionsOverviewResponse,
    );
  }

  public getOfficeCalendarEvents(
    _id: number,
  ): Promise<GetCalendarEventsResponse> {
    return this.returnMock<GetCalendarEventsResponse>(
      DefaultGetOfficeCalendarEventsResponse,
    );
  }

  public getMLSCalendarEvents(_id: number): Promise<GetCalendarEventsResponse> {
    return this.returnMock<GetCalendarEventsResponse>(
      DefaultGetMLSCalendarEventsResponse,
    );
  }

  public getBoardCalendarEvents(
    _id: number,
  ): Promise<GetCalendarEventsResponse> {
    return this.returnMock<GetCalendarEventsResponse>(
      DefaultGetBoardsCalendarEventsResponse,
    );
  }

  public getAsyncLanguages(
    search: string,
    page: number = DEFAULT_PAGE_NUM,
  ): Promise<Array<AsyncSelectOption>> {
    Logger.debug(
      `calling /languages?${qs.stringify({
        search: search || undefined,
        page,
      })}`,
    );
    return this.returnMock<Array<AsyncSelectOption>>(
      DefaultLanguagesPaginateResponse,
    );
  }
}
