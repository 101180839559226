import Phone from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import React from 'react';

export interface DocumentFooterProps {
  identifier: string;
}

const DocumentFooter: React.FC<DocumentFooterProps> = ({ identifier }) => {
  return (
    <div className='flex text-sm flex-col md:flex-row print:flex-row space-y-2 md:space-y-0 print:space-y-0 justify-between md:p-5 print:px-5 print:py-3 p-2 border-t'>
      <div className='flex flex-col md:flex-row print:flex-row space-y-2 md:space-y-0 md:space-x-4 print:space-x-4 print:space-y-0'>
        <p className='text-gray-500 whitespace-nowrap print:text-xs'>
          {' '}
          For Assistance:
        </p>
        <p className='whitespace-nowrap print:text-xs'>
          <Phone fontSize='inherit' className='mr-1' />
          +1 413-248-7325
        </p>
        <p className='whitespace-nowrap print:text-xs'>
          <MailOutlineIcon fontSize='inherit' className='mr-1' />
          support@joinreal.com
        </p>
      </div>
      <div className='flex flex-col md:flex-row print:flex-row space-y-2 md:space-y-0 md:space-x-4 md:items-end print:space-y-0 print:space-x-4 print:items-end'>
        <p className='whitespace-nowrap md:mr-16 print:mr-20 print:text-xs'>
          {identifier}
        </p>
      </div>
    </div>
  );
};

export default DocumentFooter;
