import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { values, get } from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { MdAdd, MdDelete } from 'react-icons/md';
import { FaAddressCard } from 'react-icons/fa';
import SidebarModal from '../SidebarModal';
import Button from '../Button';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AgentControllerApi,
  AgentResponse,
  AgentUpdateLicenseBoardMlsRequest,
  BoardResponse,
  MlsResponse,
  SearchRequestTargetObjectEnum,
} from '../../openapi/yenta';
import ControlledAsyncMultiSelectInput from '../ControlledAsyncMultiSelectInput';
import { performSearchRequest } from '../../utils/TableUtils';
import { AppDispatch, AsyncSelectOption } from '../../types';
import ControlledTextInput from '../ControlledTextInput';
import ControlledSelectInput from '../ControlledSelectInput';
import { capitalizeEnum } from '../../utils/StringUtils';
import { countryMapState } from '../../testUtils/OnboardingUtils';
import ControlledDatePickerInput from '../ControlledDatePickerInput';
import ControlledToggleInput from '../ControlledToggleInput';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { fetchAgentDetail } from '../../slices/AgentSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import IconButton from '../IconButton';
import ErrorService from '../../services/ErrorService';
import FormErrorMessage from '../FormErrorMessage';
import ResourceContainer from '../ResourceContainer';

interface EditAgentLicensesSidebarModalProps {
  agent: AgentResponse;
  isOpen: boolean;
  onClose(): void;
}

const validationSchema = yup.object().shape({
  mls: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .min(1, 'MLS is required')
    .required('MLS is required'),
  boards: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .min(1, 'Board is required')
    .required('Board is required'),
  licenses: yup
    .array()
    .of(
      yup.object().shape({
        expirationDate: yup.string().required('Expiration Date is required.'),
        number: yup.string().required('License Number is required.'),
        administrativeAreaRequest: yup.object().shape({
          country: yup.string().required('Country is required.'),
          stateOrProvince: yup.string().required('State/Province is required.'),
        }),
      }),
    )
    .min(1, 'License is required')
    .required('License is required'),
});

const EditAgentLicensesSidebarModal: React.FC<EditAgentLicensesSidebarModalProps> = ({
  agent,
  isOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const defaultValues = useMemo(
    () => ({
      mls: agent.mls!.map((m) => ({ value: m.id!, label: m.name! })),
      boards: agent.boards!.map((board) => ({
        value: board.id!,
        label: board.name!,
      })),
      licenses: agent.licenses!.map((license) => ({
        ...license,
        administrativeAreaRequest: { ...license.administrativeArea! },
      })),
    }),
    [agent],
  );
  const {
    control,
    errors,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'licenses',
  });
  const licenseErrorMsg = get(errors.licenses, 'message');

  const onSubmit = async (data: any) => {
    const formValues: AgentUpdateLicenseBoardMlsRequest = {
      licenses: data.licenses,
      mlsIds: data.mls?.map((m: AsyncSelectOption) => m.value),
      boardIds: data.boards?.map((b: AsyncSelectOption) => b.value),
    };

    try {
      await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateAgentLicenseBoardMlsByIdUsingPUT(agent?.id!, formValues);

      await new AgentControllerApi(
        getYentaConfiguration(),
      ).updateLicenseVerifiedUsingPUT(agent?.id!, {
        licenseVerified: true,
      });

      await dispatch(fetchAgentDetail(agent?.id!));
      onClose();
      dispatch(showSuccessToast('Licenses updated successfully.'));
    } catch (e) {
      ErrorService.notify('Error updating licenses', e, {
        agent: { id: agent.id },
        data: { ...data },
      });
      dispatch(showErrorToast('Error updating licenses'));
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <SidebarModal
      title='Edit Licenses, MLSs & Boards'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-4'>
          <ControlledAsyncMultiSelectInput
            control={control}
            errors={errors}
            name='mls'
            label='MLSs'
            defaultValue={agent.mls?.map((m) => ({
              value: m.id,
              label: m.name,
            }))}
            fetchData={async (search, page) => {
              const searchResponse = await performSearchRequest<
                Pick<MlsResponse, 'id' | 'name'>
              >(
                ['id', 'name'],
                ['name'],
                page,
                SearchRequestTargetObjectEnum.Mls,
                search,
                {
                  order: [
                    {
                      asc: true,
                      column: 'name',
                    },
                  ],
                },
              );

              const options: AsyncSelectOption[] = searchResponse.map(
                (resp) => ({
                  value: `${resp.id}`,
                  label: `${resp.name}`,
                }),
              );

              return options;
            }}
          />
          <div className='mt-4'>
            <ControlledAsyncMultiSelectInput
              control={control}
              errors={errors}
              defaultValue={agent.boards?.map((board) => ({
                value: board.id,
                label: board.name,
              }))}
              name='boards'
              label='Boards'
              fetchData={async (search, page) => {
                const searchResponse = await performSearchRequest<
                  Pick<BoardResponse, 'id' | 'name'>
                >(
                  ['id', 'name'],
                  ['name'],
                  page,
                  SearchRequestTargetObjectEnum.Board,
                  search,
                  {
                    order: [
                      {
                        asc: true,
                        column: 'name',
                      },
                    ],
                  },
                );

                const options: AsyncSelectOption[] = searchResponse.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.name}`,
                  }),
                );

                return options;
              }}
            />
          </div>
          <div className='flex justify-between items-center mt-4'>
            <p className='font-primary-medium'>Licenses</p>
            <IconButton
              variant='outline'
              buttonType='button'
              label='Add'
              leftIcon={<MdAdd />}
              onClick={() =>
                append({
                  administrativeAreaRequest: {
                    country: AdministrativeAreaRequestCountryEnum.UnitedStates,
                    stateOrProvince:
                      AdministrativeAreaRequestStateOrProvinceEnum.Alabama,
                  },
                  active: true,
                  knownComplaints: false,
                  number: '',
                  expirationDate: '',
                })
              }
            />
          </div>
          {licenseErrorMsg && <FormErrorMessage message={licenseErrorMsg} />}
          <ResourceContainer
            loading={false}
            isEmpty={!fields.length}
            resourceName='license'
            emptyIcon={<FaAddressCard size={38} />}
          >
            {fields.map((field, index) => (
              <div className='mt-2 p-3 rounded border relative' key={field.id}>
                <ControlledSelectInput
                  label='Country'
                  control={control}
                  errors={errors}
                  options={values(AdministrativeAreaRequestCountryEnum)
                    .sort((a, z) => a.localeCompare(z))
                    .map((country) => ({
                      value: country,
                      label: capitalizeEnum(country),
                    }))}
                  name={`licenses[${index}].administrativeAreaRequest.country`}
                  defaultValue={
                    field.administrativeAreaRequest?.country ||
                    AdministrativeAreaRequestCountryEnum.UnitedStates
                  }
                />
                <div className='mt-2'>
                  <ControlledSelectInput
                    label='State/Province'
                    control={control}
                    errors={errors}
                    options={
                      countryMapState[
                        (watch(
                          `licenses[${index}].administrativeAreaRequest.country`,
                        ) as AdministrativeAreaRequestCountryEnum) ||
                          AdministrativeAreaRequestCountryEnum.UnitedStates
                      ]
                    }
                    name={`licenses[${index}].administrativeAreaRequest.stateOrProvince`}
                    defaultValue={
                      field.administrativeAreaRequest?.stateOrProvince
                    }
                  />
                </div>
                <div className='mt-2'>
                  <ControlledTextInput
                    errors={errors}
                    control={control}
                    name={`licenses[${index}].number`}
                    label='Number'
                    rules={{ required: 'License Number is required.' }}
                    defaultValue={field.number}
                  />
                </div>
                <div className='mt-2'>
                  <ControlledDatePickerInput
                    label='Expiration Date'
                    control={control}
                    errors={errors}
                    name={`licenses[${index}].expirationDate`}
                    defaultValue={field.expirationDate}
                  />
                </div>
                <div className='mt-2 -ml-2'>
                  <ControlledToggleInput
                    errors={errors}
                    control={control}
                    title='License Active?'
                    name={`licenses[${index}].active`}
                    defaultValue={field.active}
                  />
                  <ControlledToggleInput
                    errors={errors}
                    control={control}
                    title='Known Complaints?'
                    name={`licenses[${index}].knownComplaints`}
                    defaultValue={field.knownComplaints}
                  />
                </div>
                <div className='absolute right-1 top-1'>
                  <IconButton
                    variant='outline'
                    leftIcon={<MdDelete />}
                    onClick={() => remove(index)}
                  />
                </div>
              </div>
            ))}
          </ResourceContainer>
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center sticky px-3 py-2 w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Save'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={onClose}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default EditAgentLicensesSidebarModal;
