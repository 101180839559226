import React from 'react';
import { Phone, Email } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { AgentPreviewResponse } from '../openapi/yenta';
import { formatPhoneNumber } from '../utils/StringUtils';
import ProfileImage from './ProfileImage';
import IconText from './IconText';
// import { LocationOn, Person,Event } from '@material-ui/icons';
// import { DateTime } from 'luxon';

interface BrokerCardProps {
  broker: AgentPreviewResponse;
}

const BrokerCard: React.FC<BrokerCardProps> = ({ broker }) => {
  return (
    <div className='border border-gray-200 p-4 rounded text-gray-700'>
      <div className='flex items-center'>
        <ProfileImage imageUrl={broker!.avatar!} width={70} variant='rounded' />
        <div className='ml-3'>
          <h4 className='text-2xl'>{broker.fullName!}</h4>
          {/* <p className='font-primary-light'>
              {StateOrProvinceToDisplayName(
                broker!.stateOrProvince!,
              )}
            </p> */}
        </div>
      </div>
      <div className='my-3'>
        <div className='mt-1'>
          {broker.phoneNumber && (
            <a href={`tel:${broker.phoneNumber}`}>
              <IconText
                icon={<Phone fontSize='small' />}
                text={formatPhoneNumber(broker.phoneNumber)!}
              />
            </a>
          )}
        </div>
        {/* <div className='mt-1'>
            <IconText
              icon={<LocationOn fontSize='small' />}
              text={formatAddressObject(broker.address!)}
            />
          </div> */}
        <div className='mt-1'>
          <a href={`mailto:${broker.emailAddress}`}>
            <IconText
              icon={<Email fontSize='small' />}
              text={broker!.emailAddress!}
            />
          </a>
        </div>
        {/* <div className='mt-1'>
            <IconText
              icon={<Person fontSize='small' />}
              text={broker.bio}
              lineClamp={3}
            />
          </div>

          <div className='mt-1'>
            <IconText
              icon={<Event fontSize='small' />}
              text={`${DateTime.fromISO(broker.dob).toFormat('D')}`}
            />
          </div> */}
      </div>
      <Link
        className='border-none text-primary bg-current focus:outline-none mt-3'
        to={`/people/${broker.id}`}
      >
        See More
      </Link>
    </div>
  );
};

export default BrokerCard;
