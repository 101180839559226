import { combineReducers } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { RootState } from '../types';
import OfficeSlice from './OfficeSlice';
import MLSSlice from './MLSSlice';
import TeamSlice from './TeamSlice';
import TransactionSlice from './TransactionSlice';
import AgentSlice from './AgentSlice';
import CDAFormSlice from './CDAFormSlice';
import AuthSlice from './AuthSlice';
import ToastNotificationSlice from './ToastNotificationSlice';
import { RESET_APP } from './actions/authActions';
import BoardSlice from './BoardSlice';
import ApplicationSlice from './ApplicationSlice';
import NetworkSlice from './NetworkSlice';
import UserIdsSlice from './UserIdsSlice';
import ErrorSlice from './ErrorSlice';
import CronJobs from './CronJobs';

const appReducer = combineReducers({
  office: OfficeSlice,
  mls: MLSSlice,
  board: BoardSlice,
  team: TeamSlice,
  transaction: TransactionSlice,
  agentDetail: AgentSlice,
  cdaForm: CDAFormSlice,
  auth: AuthSlice,
  toastNotification: ToastNotificationSlice,
  applicationDetail: ApplicationSlice,
  network: NetworkSlice,
  userIds: UserIdsSlice,
  errorDetails: ErrorSlice,
  cronJobs: CronJobs,
});

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
