import React from 'react';
import { sum } from 'lodash';
import {
  UpdateParticipantRequest,
  PaymentParticipantValue,
} from '../openapi/arrakis';
import { numberToMoney } from '../utils/CurrencyUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import CollapsibleTableRow from './CollapsibleTableRow';
import CollapsibleTableRowItem from './CollapsibleTableRowItem';
import ParticipantCard from './ParticipantCard';
import SignedNumber from './SignedNumber';

export interface PaymentParticipantCardProps {
  participant: PaymentParticipantValue;
  onEdit(): void;
  onDelete(): void;
  toggleFlag(flags: keyof UpdateParticipantRequest, value: boolean): void;
  isReadonly?: boolean;
  transactionOwner: boolean;
}

const PaymentParticipantCard: React.FC<PaymentParticipantCardProps> = ({
  participant,
  onEdit,
  onDelete,
  toggleFlag,
  isReadonly,
  transactionOwner,
}) => {
  return (
    <div className='w-full'>
      <ParticipantCard
        participant={participant}
        onDelete={onDelete}
        onEdit={onEdit}
        isReadonly={isReadonly}
        toggleFlag={toggleFlag}
        transactionOwner={transactionOwner}
      >
        <table className='w-full divide-y-2 border border-gray-200 border-collapse'>
          <CollapsibleTableRow
            name='Commission Split'
            ValueComponent={
              <p>{participant.payoutSetting?.percentage?.string}</p>
            }
          />
          <CollapsibleTableRow
            name='Total ($)'
            ValueComponent={
              <p>{numberToMoney(participant.commissionTotal?.amount!)}</p>
            }
          />
          <CollapsibleTableRow
            name='Real'
            ValueComponent={
              <SignedNumber
                value={sum(
                  participant?.items?.map((item) => item.amount?.amount),
                )}
              />
            }
          >
            {participant?.items?.length !== 0 &&
              participant?.items?.map((item) => (
                <CollapsibleTableRowItem
                  key={item.id}
                  name={capitalizeEnum(item.ledgerItemType!)}
                  value={<SignedNumber value={item.amount?.amount!} />}
                />
              ))}
          </CollapsibleTableRow>

          <CollapsibleTableRow
            name='Net Payout'
            ValueComponent={
              <SignedNumber value={participant.netPayout?.amount!} />
            }
          />
        </table>
      </ParticipantCard>
    </div>
  );
};

export default PaymentParticipantCard;
