import React from 'react';
import classNames from 'classnames';
import { ButtonSizeType, ButtonType } from '../types';

export type ButtonTypeType = 'submit' | 'reset' | 'button';

export interface ButtonProps {
  type?: ButtonType;
  size?: ButtonSizeType;
  label: string;
  pill?: boolean;
  onClick?: () => void;
  buttonType?: ButtonTypeType;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  type = 'primary',
  size = 'sm',
  label,
  pill = false,
  buttonType = 'button',
  className,
  disabled = false,
  onClick,
}) => {
  const buttonTypeToClassNameMap: { [type in ButtonType]: string } = {
    primary: 'bg-primary text-white',
    secondary: 'bg-gray-200 text-gray-600',
    danger: 'bg-red-500 text-white',
    outline: 'border border-gray-200 bg-white text-black',
  };
  const sizeToClassNameMap: { [key in ButtonSizeType]: string } = {
    xs: 'py-0.5 px-2',
    sm: 'py-1 px-3',
    lg: 'py-1.5 px-5',
    xl: 'py-2 px-8',
  };
  const pillClass = pill ? 'rounded-full' : 'rounded';
  const fontToClassNameMap: { [type in ButtonSizeType]: string } = {
    xs: 'text-xs',
    sm: 'text-sm',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  return (
    <button
      type={buttonType}
      className={classNames(
        'focus:outline-none hover:opacity-70',
        disabled ? 'bg-gray-500 text-white' : buttonTypeToClassNameMap[type],
        sizeToClassNameMap[size],
        fontToClassNameMap[size],
        pillClass,
        className,
        disabled ? 'opacity-70' : 'opacity-100',
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
