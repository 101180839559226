import React, { useCallback, useMemo } from 'react';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { numberToMoney } from '../../utils/CurrencyUtils';
import AgentCommissionAdvancesStatusCell from '../table/Cells/AgentCommissionAdvancesStatusCell';
import ActionCell from '../table/Cells/ActionCell';
import { isSmScreen } from '../../utils/BrowserUtils';
import ResourceTable, {
  ResourceTableVariant,
} from '../../containers/ResourceTable';
import Button from '../Button';
import { CommissionAdvanceResponse } from '../../openapi/arrakis';
import { downloadCommissionAdvancesDoc } from '../../slices/AgentSlice';
import { AppDispatch } from '../../types';
import TextStrictCaseColumnFilter from '../table/Filters/TextStrictCaseColumnFilter';
import IconButton from '../IconButton';

interface AgentCommissionAdvancesTableProps {
  data: CommissionAdvanceResponse[];
  setDeletingCommissionAdvances: (data: CommissionAdvanceResponse) => void;
  setEditingCommissionAdvances: (data: CommissionAdvanceResponse) => void;
}

const AgentCommissionAdvancesTable: React.FC<AgentCommissionAdvancesTableProps> = ({
  data,
  setDeletingCommissionAdvances,
  setEditingCommissionAdvances,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const getDownloadUrl = useCallback(
    async (id: string) => {
      let url = await dispatch(downloadCommissionAdvancesDoc(id!));
      if (url) window.open(url, '_blank');
    },
    [dispatch],
  );

  const columns: Column<CommissionAdvanceResponse>[] = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: 'amount',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => numberToMoney(value?.amount!),
      },
      {
        Header: 'Name of Company',
        accessor: 'companyName',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Email',
        accessor: 'companyEmail',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Agreement',
        accessor: 'id',
        id: 'downloadId',
        Cell: ({ value }) => (
          <Button
            className='flex flex-nowrap items-center space-x-2'
            type='outline'
            label='Download Agreement'
            onClick={() => getDownloadUrl(value!)}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'advanceStatus',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <AgentCommissionAdvancesStatusCell status={value!} />
        ),
      },
      {
        Header: 'Transaction',
        accessor: 'transaction',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value: transaction }) =>
          transaction ? (
            <Link to={`/transactions/${transaction.id}`}>
              <IconButton
                leftIcon={<ExitToAppIcon titleAccess='Link' fontSize='small' />}
                variant='outline'
                label={transaction.code}
              />
            </Link>
          ) : (
            'N/A'
          ),
      },
      {
        Header: 'Notes',
        id: 'notes',
        accessor: 'notes',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'id',
        disableSortBy: true,
        Filter: TextStrictCaseColumnFilter,
        Cell: ({ row }) => (
          <ActionCell<CommissionAdvanceResponse>
            data={row.original}
            onDelete={setDeletingCommissionAdvances}
            onEdit={setEditingCommissionAdvances}
          />
        ),
      },
    ],
    [
      getDownloadUrl,
      setDeletingCommissionAdvances,
      setEditingCommissionAdvances,
    ],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <ResourceTable<CommissionAdvanceResponse>
      {...tableInstance}
      resourceName='commission advance'
      fetchData={() => ({
        data,
        total: data.length,
      })}
      variant={
        isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
      }
      hidePagination
    />
  );
};

export default AgentCommissionAdvancesTable;
