import { SearchRequestTargetObjectEnum } from '../../openapi/yenta';
import { performSearchRequest } from '../../utils/TableUtils';

export interface Application {
  stateName: string;
  createdAt: Date;
}

export const createApplication = (
  stateName: string,
  createdAt: Date,
): Application => ({
  stateName,
  createdAt,
});

export interface FetchedApplication {
  doesBusinessIn: any[];
  createdAt: number;
}

export const getStateName = (application: FetchedApplication) =>
  application.doesBusinessIn[0].administrativeArea.stateOrProvince
    .replaceAll('_', ' ')
    .toLowerCase();

export const getApplicationDate = (application: FetchedApplication) =>
  new Date(application.createdAt);

export const getAgentApplications = async (): Promise<Application[]> => {
  const fetchData = async (pageNumber = 1) => {
    const json = await performSearchRequest<FetchedApplication>(
      ['doesBusinessIn', 'createdAt'],
      [],
      pageNumber,
      SearchRequestTargetObjectEnum.Application,
      '',
    );

    let data: Application[] = [];

    for (let i = 0; i < json.length; i++) {
      const stateName = getStateName(json[i]);
      const createdDate = getApplicationDate(json[i]);
      data.push(createApplication(stateName, createdDate));
    }

    if (json.length) {
      data.push(...(await fetchData(pageNumber + 1)));
    }
    return data;
  };

  return await fetchData();
};

export const getStateApplicationMap = (
  applications: Application[],
  startDate: string,
  endDate: string,
) => {
  let map = new Map();

  for (let i = 0; i < applications.length; i++) {
    const application = applications[i];

    const stateName = application.stateName;
    const createdDate = application.createdAt;

    if (startDate.length !== 0 && new Date(startDate) > createdDate) continue;
    if (endDate.length !== 0 && new Date(endDate) < createdDate) continue;

    if (map.has(stateName)) {
      map.set(stateName, map.get(stateName) + 1);
    } else {
      map.set(stateName, 1);
    }
  }

  return map;
};
