import React from 'react';
import { AvatarPillCellItem } from '../../../types';
import Pill from '../../Pill';
import AvatarPill from '../../AvatarPill';
import AvatarStack from '../../AvatarStack';

export interface AvatarPillCellProps {
  items: AvatarPillCellItem[];
  emptyText: string;
}

const AvatarPillCell: React.FC<AvatarPillCellProps> = ({
  items,
  emptyText,
}) => {
  if (items.length === 0) {
    return <Pill text={emptyText} />;
  }

  return (
    <div className='flex items-center w-52'>
      <AvatarPill text={items[0].name} imageUrl={items[0].imageUrl} />
      {items.length > 1 && (
        <AvatarStack avatars={[{ text: `+${items.length - 1}` }]} />
      )}
    </div>
  );
};

export default AvatarPillCell;
