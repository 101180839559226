import { values, get, omit } from 'lodash';
import { DateTime } from 'luxon';
import {
  CountryMapStateOptions,
  FAQType,
  FormFieldConfig,
  InputFieldType,
  ISelectOption,
  onBoardingStepType,
  YesNoType,
} from '../types';
import {
  capitalizeEnum,
  formatPhoneNumber,
  PHONE_REGEX,
} from '../utils/StringUtils';
import {
  ApplicationResponseNextStatusEnum,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
} from '../openapi/yenta';

import { CANADIAN_STATES, US_STATES } from '../utils/StateUtils';

export const CREATE_ACCOUNT_INDEX = 0;
export const APPLICATION_FORM_INDEX = 1;
export const ICA_INDEX = 2;
export const APPLICATION_FEE_INDEX = 3;
export const TRANSFER_LICENSE_INDEX = 4;
export const JOIN_THE_BOARD_INDEX = 5;
export const COMPLETED_INDEX = 6;

export const onboardingSteps: onBoardingStepType[] = [
  {
    name: 'Create Account',
  },
  {
    name: 'Application Form',
  },
  {
    name: 'Independent Contractor Agreement',
  },
  {
    name: 'Application Fee',
  },
  {
    name: 'Transfer License',
  },
  {
    name: 'Join Boards',
  },
];

export const onboardingStepsWithLOI: onBoardingStepType[] = [
  {
    name: 'Create Account',
  },
  {
    name: 'Application Form',
  },
  {
    name: 'Letter Of Intent',
  },
  {
    name: 'Independent Contractor Agreement',
  },
  {
    name: 'Application Fee',
  },
  {
    name: 'Transfer License',
  },
  {
    name: 'Join Boards',
  },
];

export enum ApplicationFormFieldName {
  PHONE_NUMBER = 'phoneNumber',
  BIRTH_DATE = 'birthDate',
  JURISDICTION_COUNTRY = 'selectedCountry',
  DOES_BUSINESS_IN = 'doesBusinessIn',
  BOARD_OR_STATE = 'boardOrMls',
  LICENSE_NUMBER = 'number',
  LICENSE_ACTIVE = 'active',
  LICENSE_EXPIRES_AT = 'expirationDate',
  LICENSE_ANY_KNOWN_COMPLAINTS = 'knownComplaints',
  SELECTED_PROVINCES = 'selectedProvinces',
  CURRENT_BROKERAGE = 'currentBrokerage',
  ESTIMATED_SALES = 'estimatedSales',
  COMMERCIAL_AGENT = 'commercialAgent',
}

export const countryMapState: CountryMapStateOptions = {
  [AdministrativeAreaRequestCountryEnum.UnitedStates]: values(US_STATES)
    .sort((a, z) => a.localeCompare(z))
    .map<ISelectOption>((state) => ({
      value: state,
      label: capitalizeEnum(state),
    })),
  [AdministrativeAreaRequestCountryEnum.Canada]: values(CANADIAN_STATES)
    .sort((a, z) => a.localeCompare(z))
    .map((state) => ({
      value: state,
      label: capitalizeEnum(state),
    })),
};

export const applicationFormQuestions = (
  name: string,
  countrySelected: AdministrativeAreaRequestCountryEnum = AdministrativeAreaRequestCountryEnum.UnitedStates,
  provincesSelected: ISelectOption[] = [],
): Array<FormFieldConfig> => {
  const licensesForProvinces: FormFieldConfig[] = [];
  provincesSelected.forEach((option, index) => {
    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].${ApplicationFormFieldName.LICENSE_NUMBER}`,
      label: `What is your real estate license number in ${option.label}?`,
      helperText: 'Please enter the license number below',
      placeholder: 'NY4235567',
      type: InputFieldType.TEXT,
      rules: {
        required: 'Please enter your license number',
      },
    });

    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].${ApplicationFormFieldName.LICENSE_ACTIVE}`,
      label: `Is your license active in ${option.label}?`,
      helperText: 'Please choose one',
      placeholder: '123456789',
      type: InputFieldType.RADIO,
      rules: {
        required: 'Please choose one',
      },
      options: [
        {
          label: 'Yes',
          value: YesNoType.YES,
        },
        {
          label: 'No',
          value: YesNoType.NO,
        },
      ],
      resolveFieldDisplayValue(status: YesNoType) {
        return capitalizeEnum(status);
      },
      convertValueBeforeSend(status: YesNoType) {
        return status === YesNoType.YES;
      },
    });

    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].${ApplicationFormFieldName.LICENSE_EXPIRES_AT}`,
      label: `When does your license expire in ${option.label}?`,
      helperText: 'Please choose a date',
      placeholder: 'YYYY-MM-DD',
      type: InputFieldType.DATE,
      datePickerConfig: {
        minDate: DateTime.local().minus({ years: 15 }).toJSDate(),
        maxDate: DateTime.local().plus({ years: 15 }).toJSDate(),
      },
      resolveFieldDisplayValue(date: string) {
        return DateTime.fromISO(date).toFormat('LL/dd/yyyy');
      },
      rules: {
        required: 'Please choose a date.',
      },
    });

    licensesForProvinces.push({
      name: `${ApplicationFormFieldName.DOES_BUSINESS_IN}[${index}].${ApplicationFormFieldName.LICENSE_ANY_KNOWN_COMPLAINTS}`,
      label: `Have you had a registered complaint filed against you in ${option.label}?`,
      helperText: 'Please choose one',
      type: InputFieldType.RADIO,
      rules: {
        required: 'Please choose one',
      },
      options: [
        {
          label: 'Yes',
          value: YesNoType.YES,
        },
        {
          label: 'No',
          value: YesNoType.NO,
        },
      ],
      resolveFieldDisplayValue(status: YesNoType) {
        return capitalizeEnum(status);
      },
      convertValueBeforeSend(status: YesNoType) {
        return status === YesNoType.YES;
      },
    });
  });

  return [
    {
      name: ApplicationFormFieldName.PHONE_NUMBER,
      label: `Hi, ${name} 👋`,
      secondaryLabel:
        'Please fill out the following to complete the application form',
      helperText: 'Mobile Number',
      placeholder: '+1 (702) 123-4567',
      type: InputFieldType.PHONE,
      rules: {
        required: 'Please enter your mobile phone number',
        pattern: {
          value: PHONE_REGEX,
          message: 'Please enter a valid phone number',
        },
      },
      reviewLabel: 'What is your mobile phone number?',
      resolveFieldDisplayValue(phoneNumber: string) {
        return formatPhoneNumber(phoneNumber);
      },
    },
    {
      name: ApplicationFormFieldName.BIRTH_DATE,
      label: 'What is your date of birth?',
      helperText: 'Please select a date',
      placeholder: 'MM-DD-YYYY',
      type: InputFieldType.DATE,
      datePickerConfig: {
        minDate: DateTime.local().minus({ years: 100 }).toJSDate(),
        maxDate: DateTime.local().toJSDate(),
      },
      rules: {
        required: 'Please enter your date of birth',
      },
      resolveFieldDisplayValue(date: string) {
        return DateTime.fromISO(date).toFormat('LL/dd/yyyy');
      },
    },
    {
      name: ApplicationFormFieldName.JURISDICTION_COUNTRY,
      label: 'In which country do you reside?',
      helperText: 'Please select a country from below',
      type: InputFieldType.SELECT,
      rules: {
        required: 'Please select a country',
      },
      defaultValue: countrySelected,
      options: [
        {
          label: 'Choose Country',
          value: '',
          disabled: true,
        },
        ...values(AdministrativeAreaRequestCountryEnum)
          .sort((a, z) => a.localeCompare(z))
          .map((country) => ({
            value: country,
            label: capitalizeEnum(country),
          })),
      ],
      resolveFieldDisplayValue(country: AdministrativeAreaRequestCountryEnum) {
        return capitalizeEnum(country);
      },
    },
    {
      name: ApplicationFormFieldName.SELECTED_PROVINCES,
      label: 'In which state / province do you operate?',
      helperText: 'Please select a state / province from below',
      type: InputFieldType.MULTISELECT,
      rules: {
        required: 'Please select a state / province',
        validate: (value?: AdministrativeAreaRequestStateOrProvinceEnum[]) =>
          !value?.length ? 'Please select a state / province' : undefined,
      },
      placeholder: 'Choose state / province',
      options: countryMapState[countrySelected],
      resolveFieldDisplayValue(states: ISelectOption[]) {
        return states.map((state) => state.label).join(', ');
      },
      convertValueBeforeSend(states: ISelectOption[], formData: any) {
        return states.map((state) => ({
          country: get(formData, ApplicationFormFieldName.JURISDICTION_COUNTRY),
          stateOrProvince: state.value,
        }));
      },
    },
    {
      name: ApplicationFormFieldName.BOARD_OR_STATE,
      label: 'Are you a member of a Board / MLS?',
      helperText: 'Enter the name of the Board / MLS',
      type: InputFieldType.TEXT,
      rules: {
        required: 'Please enter the name of the Board / MLS',
      },
      naMessage: 'I do not belong to a Board / MLS.',
    },
    ...licensesForProvinces,
    {
      name: ApplicationFormFieldName.CURRENT_BROKERAGE,
      label: 'What is the name of your current brokerage?',
      helperText: 'Please enter the name',
      type: InputFieldType.TEXT,
      rules: {
        required: "Please enter your current brokerage's name",
      },
      naMessage: 'I do not currently belong to a brokerage.',
    },
    {
      name: ApplicationFormFieldName.ESTIMATED_SALES,
      label: 'Estimated Sales',
      helperText: 'What was your estimated sales volume in the last 12 months?',
      type: InputFieldType.TEXT,
      rules: {
        required: 'Please enter the Estimated Sales Amount.',
      },
    },
    {
      name: ApplicationFormFieldName.COMMERCIAL_AGENT,
      label: 'Are you a commercial-only agent?',
      helperText: 'Please choose one',
      type: InputFieldType.RADIO,
      options: [
        {
          label: 'Yes',
          value: YesNoType.YES,
        },
        {
          label: 'No',
          value: YesNoType.NO,
        },
      ],
      rules: {
        required: 'Please choose one.',
      },
      resolveFieldDisplayValue(status: YesNoType) {
        return capitalizeEnum(status);
      },
      convertValueBeforeSend(status: YesNoType) {
        return status === YesNoType.YES;
      },
    },
  ];
};

export const FAQs: FAQType[] = [
  {
    question: 'What’s the best thing about Switzerland?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: 'How do you make holy water?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: 'What do you call someone with no body and no nose?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: 'Why do you never see elephants hiding in trees?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: 'Why did the invisible man turn down the job offer?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
];

export const getFlattenedObjectWithDotNotation = (values: any, prefix = '') =>
  omit(
    Object.keys(values).reduce((acc: any, k) => {
      const pre = prefix.length ? prefix + '.' : '';
      if (typeof values[k] === 'object' && !Array.isArray(values[k]))
        Object.assign(
          acc,
          getFlattenedObjectWithDotNotation(values[k], pre + k),
        );
      else acc[pre + k] = values[k];
      return acc;
    }, {}),
    ['doesBusinessIn.toUnique'],
  );

export const getNextUrlForApplicationStatus = (
  step: ApplicationResponseNextStatusEnum,
) => {
  switch (step) {
    case ApplicationResponseNextStatusEnum.Started:
      return '/onboarding/application-form';
    case ApplicationResponseNextStatusEnum.SignLetterOfIntent:
      return '/onboarding/application-loi-signing';
    case ApplicationResponseNextStatusEnum.SignIca:
      return '/onboarding/application-ica-signing';
    case ApplicationResponseNextStatusEnum.PayFees:
      return '/onboarding/application-fee';
    case ApplicationResponseNextStatusEnum.TransferLicense:
      return '/onboarding/application-transfer-license';
    case ApplicationResponseNextStatusEnum.JoinBoard:
      return '/onboarding/application-join-board';
    case ApplicationResponseNextStatusEnum.PendingApproval:
      return '/onboarding/application-submitted/success';
    case ApplicationResponseNextStatusEnum.Approved:
      return '/welcome-to-rezen/mobile-app';
    default:
      return '/onboarding/application-form';
  }
};

export const getIndexFromStatus = (
  status: ApplicationResponseNextStatusEnum,
) => {
  switch (status) {
    case ApplicationResponseNextStatusEnum.Started:
      return 1;
    case ApplicationResponseNextStatusEnum.SignIca:
      return 2;
    case ApplicationResponseNextStatusEnum.PayFees:
      return 3;
    case ApplicationResponseNextStatusEnum.TransferLicense:
      return 4;
    case ApplicationResponseNextStatusEnum.JoinBoard:
      return 5;
    case ApplicationResponseNextStatusEnum.PendingApproval:
      return 6;
    case ApplicationResponseNextStatusEnum.Approved:
    case ApplicationResponseNextStatusEnum.Rejected:
      return 7;
    default:
      return 1;
  }
};

export const getIndexFromStatusWithLOI = (
  status: ApplicationResponseNextStatusEnum,
) => {
  switch (status) {
    case ApplicationResponseNextStatusEnum.Started:
      return 1;
    case ApplicationResponseNextStatusEnum.SignLetterOfIntent:
      return 2;
    case ApplicationResponseNextStatusEnum.SignIca:
      return 3;
    case ApplicationResponseNextStatusEnum.PayFees:
      return 4;
    case ApplicationResponseNextStatusEnum.TransferLicense:
      return 5;
    case ApplicationResponseNextStatusEnum.JoinBoard:
      return 6;
    case ApplicationResponseNextStatusEnum.PendingApproval:
      return 7;
    case ApplicationResponseNextStatusEnum.Approved:
    case ApplicationResponseNextStatusEnum.Rejected:
      return 8;
    default:
      return 1;
  }
};
