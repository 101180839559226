import React, { useState } from 'react';
import qs from 'qs';
import { InfoOutlined, PlayCircleFilledWhite } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Alert from '../components/Alert';
import ControlledTextInput from '../components/ControlledTextInput';
import LanyardCard from '../components/LanyardCard';
import { AuthControllerApi } from '../openapi/keymaker';
import { UserControllerApi } from '../openapi/yenta';
import { fetchAuthUserDetail } from '../slices/AuthSlice';
import { ALPHA_NUMERIC_REGEX_NO_SPACE, NAME_REGEX } from '../utils/StringUtils';
import DottedBackground from '../assets/icons/dottedBackground.svg';
import TamirPoleg from '../assets/img/tamirPoleg.png';
import IconButton from '../components/IconButton';
import { setAuthCookie } from '../utils/AuthUtils';
import {
  getKeymakerConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import SubmitButton from '../components/SubmitButton';
import { EMAIL_VALIDATIONS, PASSWORD_VALIDATIONS } from '../utils/Validations';
import { TAMIRS_VIDEO_URL } from '../constants/AncillaryConstants';

interface RegisterRouteProps extends RouteComponentProps {}

interface RegisterFormValues {
  firstName: string;
  lastName: string;
  username: string;
  emailAddress: string;
  password: string;
  confirmPassword: string;
}

const RegisterRoute: React.FC<RegisterRouteProps> = ({ history, location }) => {
  const { sponsorCode, sponsorName, loi } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const dispatch = useDispatch();
  const {
    control,
    clearErrors,
    handleSubmit,
    errors,
    setError,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const onSubmit = async (values: RegisterFormValues) => {
    try {
      await new UserControllerApi(getYentaConfiguration()).signupUsingPOST({
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        password: values.password,
      });

      const {
        data: { accessToken },
      } = await new AuthControllerApi(
        getKeymakerConfiguration(),
      ).authenticateUserUsingPOST({
        password: values.password,
        usernameOrEmail: values.emailAddress,
      });

      setAuthCookie(accessToken!);
      dispatch(fetchAuthUserDetail());

      history.push(
        `/onboarding/application-form?${qs.stringify({
          sponsorCode,
          sponsorName,
          loi,
        })}`,
      );
    } catch (e) {
      setError('submit', {
        message: e.response.data.message,
      });
    }
  };

  return (
    <div className='grid grid-flow-row lg:grid-flow-col grid-cols-1 lg:grid-cols-2'>
      <div className='flex justify-center items-center h-screen px-2 pt-36 lg:pt-0 bg-gray-100'>
        <LanyardCard>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='bg-gray-100'>
              <div className='bg-white p-5'>
                <p className='font-primary-medium text-xl'>
                  Please fill out the form below to get started.
                </p>
                {errors.submit && (
                  <div className='mt-3'>
                    <Alert
                      text={errors.submit.message}
                      variant='error'
                      icon={<InfoOutlined />}
                    />
                  </div>
                )}
                <div className='mt-2 flex flex-row space-x-4'>
                  <ControlledTextInput
                    control={control}
                    label='First Name'
                    name='firstName'
                    placeholder='Jane'
                    errors={errors}
                    rules={{
                      required: 'First Name is required',
                      minLength: {
                        value: 2,
                        message: 'Must be at least 2 characters',
                      },
                      pattern: {
                        value: NAME_REGEX,
                        message: 'Invalid First Name',
                      },
                    }}
                  />
                  <ControlledTextInput
                    control={control}
                    label='Last Name'
                    name='lastName'
                    placeholder='Doe'
                    errors={errors}
                    rules={{
                      required: 'Last Name is required',
                      minLength: {
                        value: 2,
                        message: 'Must be at least 2 characters',
                      },
                      pattern: {
                        value: NAME_REGEX,
                        message: 'Invalid Last Name',
                      },
                    }}
                  />
                </div>
                <div className='mt-2'>
                  <ControlledTextInput
                    control={control}
                    label='Username'
                    name='username'
                    placeholder='jane123'
                    errors={errors}
                    rules={{
                      required: 'Username is required',
                      maxLength: {
                        value: 15,
                        message: 'Username must be fewer than 15 characters',
                      },
                      pattern: {
                        value: ALPHA_NUMERIC_REGEX_NO_SPACE,
                        message: 'Invalid username',
                      },
                      validate: async (value) => {
                        const { data: username } = await new AuthControllerApi(
                          getKeymakerConfiguration(),
                        ).checkUsernameAvailabilityUsingGET(value);
                        return !username.available
                          ? 'Username is already taken'
                          : undefined;
                      },
                    }}
                  />
                </div>
                <div className='mt-2'>
                  <ControlledTextInput
                    control={control}
                    label='Email'
                    name='emailAddress'
                    placeholder='jane@example.com'
                    errors={errors}
                    rules={{
                      ...EMAIL_VALIDATIONS,
                      required: 'Email is required',
                      validate: async (value) => {
                        const { data: email } = await new AuthControllerApi(
                          getKeymakerConfiguration(),
                        ).checkEmailAvailabilityUsingGET(value);

                        return !email.available
                          ? 'Email is already taken'
                          : undefined;
                      },
                    }}
                  />
                </div>
                <div className='mt-2'>
                  <ControlledTextInput
                    control={control}
                    type='password'
                    label='Password'
                    name='password'
                    errors={errors}
                    rules={PASSWORD_VALIDATIONS}
                  />
                </div>
                <div className='mt-2'>
                  <ControlledTextInput
                    control={control}
                    type='password'
                    label='Password Confirmation'
                    name='confirmPassword'
                    errors={errors}
                    rules={{
                      required: 'Please re-enter your password',
                      validate: (value) =>
                        getValues().password !== value
                          ? 'Passwords do not match'
                          : undefined,
                    }}
                  />
                </div>
                <SubmitButton
                  label='Create Account'
                  isSubmitting={isSubmitting}
                  onClick={() => {
                    if (errors.submit) {
                      clearErrors();
                    }
                  }}
                />
              </div>
            </div>
          </form>
        </LanyardCard>
      </div>
      <div className='invisible lg:visible flex justify-center items-center h-3/4'>
        <div className='flex flex-col'>
          <div className='z-10 flex flex-col mt-10'>
            <p className='text-4xl font-primary-bold'>
              Hear from CEO Tamir Poleg
            </p>
            {playVideo ? (
              <div className='self-center'>
                <video
                  className='pt-5'
                  src={TAMIRS_VIDEO_URL}
                  controls
                  autoPlay
                  muted
                  width={345}
                  height={217}
                />
              </div>
            ) : (
              <div className='self-center'>
                <img
                  className='pt-5 shadow-2xl'
                  src={TamirPoleg}
                  alt='tamirPoleg'
                />
                <div className='-mt-12 ml-2 flex flex-row space-x-2 items-center'>
                  <PlayCircleFilledWhite
                    className='text-white'
                    fontSize='large'
                  />
                  <IconButton
                    label='Play'
                    variant='pill'
                    onClick={() => setPlayVideo(true)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='self-center z-0 fixed'>
            <img src={DottedBackground} alt='lanyard' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterRoute;
