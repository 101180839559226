import React from 'react';
import { CheckCircle, Error } from '@material-ui/icons';
import { MonitorEventResponseEventStatusEnum } from '../../../openapi/arrakis';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface MonitorStatusStatusPillProps {
  type: MonitorEventResponseEventStatusEnum;
}

type TypeMap<T> = {
  [key in MonitorEventResponseEventStatusEnum]: T;
};

const typeToVariant: TypeMap<PillVariantType> = {
  [MonitorEventResponseEventStatusEnum.Succeeded]: 'success',
  [MonitorEventResponseEventStatusEnum.Failed]: 'danger',
};

const typeToIcon: TypeMap<React.ReactElement> = {
  [MonitorEventResponseEventStatusEnum.Succeeded]: (
    <CheckCircle fontSize='small' />
  ),
  [MonitorEventResponseEventStatusEnum.Failed]: <Error fontSize='small' />,
};

const MonitorStatusStatusPill: React.FC<MonitorStatusStatusPillProps> = ({
  type,
}) => {
  const variant: PillVariantType = typeToVariant[type];
  const icon: React.ReactElement = typeToIcon[type];

  return <Pill text={capitalizeEnum(type)} variant={variant!} icon={icon!} />;
};

export default MonitorStatusStatusPill;
