import React from 'react';
import { Link } from 'react-router-dom';
import { FiDollarSign } from 'react-icons/fi';
import CircleSplitChart from '../CircleSplitChart';
import {
  CommissionSplitParticipantValue,
  PaymentParticipantValue,
  TransactionResponseTransactionTypeEnum,
} from '../../openapi/arrakis';
import { isSaleTransaction } from '../../utils/TransactionHelper';
import { numberToMoney } from '../../utils/CurrencyUtils';
import ResourceContainer from '../ResourceContainer';
import { Mapping } from '../../types';
import { getParticipantName } from '../../utils/ParticipantHelper';

interface CommissionSplitCardProps {
  commissionSplit: CommissionSplitParticipantValue[];
  transactionId: string;
  transactionType: TransactionResponseTransactionTypeEnum;
  paymentParticipants: PaymentParticipantValue[];
}

const colors: string[] = [
  '#05C3F9',
  '#007DBB',
  '#003C77',
  '#00284e',
  '#00162d',
];

const CommissionSplitCard: React.FC<CommissionSplitCardProps> = ({
  commissionSplit,
  transactionId,
  transactionType,
  paymentParticipants,
}) => {
  const participantIdToName: Mapping<{ name: string }> = {};

  paymentParticipants.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.yentaId!]: { name: getParticipantName(item) },
      }),
    participantIdToName,
  );

  const circleSplitData = commissionSplit.map((item, index) => {
    return {
      name: participantIdToName[item.yentaId!]?.name,
      value: item.percentage?.value,
      fill: colors[index % colors.length],
    };
  });

  return (
    <div className='flex flex-col'>
      <div className='py-2 flex flex-row justify-between'>
        <span className='text-xl text-gray-800 font-primary-medium'>
          Commission Split
        </span>
        {isSaleTransaction(transactionType) ? (
          <Link
            to={`/transactions/${transactionId}/cda`}
            className='underline text-primary'
          >
            View CDAs
          </Link>
        ) : (
          <Link
            to={`/transactions/${transactionId}/invoices`}
            className='underline text-primary'
          >
            View Invoices
          </Link>
        )}
      </div>
      <div className='border rounded'>
        <ResourceContainer
          loading={false}
          isEmpty={!commissionSplit.length}
          resourceName='Commission Split'
          emptyIcon={<FiDollarSign size={28} />}
        >
          <div className='grid grid-flow-row lg:grid-flow-col items-center'>
            <div>
              <CircleSplitChart
                data={circleSplitData}
                width={200}
                xKey='name'
                yKey='value'
              />
            </div>
            <div className='px-3'>
              {commissionSplit.map((item, index) => {
                return (
                  <div
                    key={item.yentaId}
                    className='flex flex-row justify-between py-3'
                  >
                    <div className='flex flex-row space-x-2'>
                      <div
                        className='w-1.5 rounded max-h-10'
                        style={{
                          backgroundColor: colors[index % colors.length],
                        }}
                      />
                      <div>
                        <p className='font-primary-medium text-gray-800'>
                          {participantIdToName[item.yentaId!]?.name}
                        </p>
                        <p className='font-primary-light'>
                          {item.percentage?.string} split
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className='font-primary-regular'>
                        {numberToMoney(item.amount?.amount!)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ResourceContainer>
      </div>
    </div>
  );
};

export default CommissionSplitCard;
