import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import classNames from 'classnames';

type ColorType = 'primary' | 'danger' | 'warning' | 'success';

interface Item {
  icon?: React.ReactElement;
  text: string;
  colorType?: ColorType;
  onClick(): void;
}

interface DropdownProps {
  icon?: React.ReactElement;
  label?: string;
  items: Item[];
  noBorder?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  icon,
  items,
  noBorder,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const colorTypeClassNameMap: { [type in ColorType]: string } = {
    primary: 'text-primary',
    danger: 'text-error',
    warning: 'text-warning',
    success: 'text-success',
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom']}
      align='end'
      onClickOutside={() => setIsOpen(false)}
      containerClassName='fixed z-50'
      content={
        <div className='mt-2 w-64 py-1 w-60 ring-2 ring-gray-200 rounded-md shadow-lg bg-white'>
          {items.map((item) => (
            <div
              key={item.text}
              className={classNames(
                'flex flex-row items-center py-2 my-1 hover:bg-trueGray-200 cursor-pointer px-4 text-danger',
                item.colorType ? colorTypeClassNameMap[item.colorType] : '',
              )}
              onClick={() => {
                item.onClick();
                setIsOpen(false);
              }}
            >
              <span className='pr-2'>{item.icon}</span>
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      }
    >
      <div
        className={classNames(
          'flex items-center justify-center cursor-pointer py-1 px-2 rounded space-x-2',
          noBorder ? '' : 'border border-gray-200',
        )}
        onClick={() => setIsOpen(true)}
      >
        {icon && <span>{icon}</span>}
        {label && <span>{label}</span>}
      </div>
    </Popover>
  );
};

export default Dropdown;
