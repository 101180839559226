import React from 'react';
import classNames from 'classnames';
import { EnumMap } from '../types';

export type AlertVariantType = 'success' | 'info' | 'warning' | 'error';

export interface AlertProps {
  text: string;
  variant: AlertVariantType;
  icon?: JSX.Element;
}

const Alert: React.FC<AlertProps> = ({ text, variant, icon }) => {
  const alertVariantToClassNameMap: EnumMap<AlertVariantType, string> = {
    success: 'bg-success',
    info: 'bg-primary',
    warning: 'bg-warning',
    error: 'bg-error',
  };

  return (
    <div
      className={classNames(
        'text-white rounded p-2',
        alertVariantToClassNameMap[variant],
      )}
    >
      <div className='flex items-center'>
        {icon}
        <span className='font-primary-regular ml-1 pt-0.5'>{text}</span>
      </div>
    </div>
  );
};

export default Alert;
