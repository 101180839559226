import { useEffect, useState } from 'react';
import * as d3 from 'd3';
import {
  filpVisibilityOfChildNodes,
  getTreeMaxDepth,
  createTreeNode,
  getTreeToShow,
  indexTreeNodes,
  setTreeDefaultVisiblityState,
  TreeNode,
} from '../utils/AgentSponsorsTreeUtils';

interface AgentSponsorsTreeProps {
  data: TreeNode | null;
  fontSize: number;
  radiusGap: number;
}

const AgentSponsorsTree: React.FC<AgentSponsorsTreeProps> = ({
  data,
  fontSize,
  radiusGap,
}) => {
  const [treeToShow, setTreeToShow] = useState<TreeNode>(
    data || createTreeNode('', '', null),
  );
  const [fullData, setFullData] = useState<TreeNode>(
    data || createTreeNode('', '', null),
  );
  const [previousZoom, setPreviousZoom] = useState<d3.ZoomTransform | null>(
    null,
  );
  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!data) return;
    const fullTree = data;
    const tree = createTreeNode(fullTree.name, fullTree.id, null);

    setTreeDefaultVisiblityState(fullTree);
    indexTreeNodes(fullTree);
    getTreeToShow(tree, fullTree);
    setFullData(fullTree);
    setTreeToShow(tree);
  }, [setFullData, data]);

  // prettier-ignore
  useEffect(() => {
    if (!data) return;
    if (!svgRef) return;

    const width = svgRef.clientWidth, height = svgRef.clientHeight;
    
    const radius = radiusGap * getTreeMaxDepth(treeToShow);
    const tree = d3
      .tree()
      .size([Math.PI * 2, radius])
      .separation((a, b) => (a.parent === b.parent ? 1 : 2) / a.depth);

    const root = tree(d3.hierarchy(treeToShow).sort((a, b) => d3.ascending(a.data.name, b.data.name)));

    const svg = d3
      .select(svgRef)
      .attr("width", width)
      .attr("height", height);            

    svg.html("");

    const lines = svg.append("g");
    const dots = svg.append("g");
    const names = svg.append("g");

    lines.attr("transform", `translate(${(width) / 2}, ${(height) / 2})`)
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5)
        .selectAll("path")
        .data(root.links())
        .join("path")
          .attr("d", d3.linkRadial<any, any>().angle(d => d.x).radius(d => d.y));
    
    dots.attr("transform", `translate(${(width) / 2}, ${(height) / 2})`)
      .selectAll("circle")
      .data(root.descendants())
      .join("circle")
        .attr("transform", d => `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`)
        .attr("fill", d => (d.children ? "#555" : "#999"))
        .attr("r", 2.5);

    names.attr("transform", `translate(${(width) / 2}, ${(height) / 2})`)
      .attr("font-family", "sans-serif")
      .attr("font-size", fontSize)
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", 3)
        .selectAll("text")
        .data(root.descendants())
        .join("text")
          .attr("transform", d => `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y}, 0) rotate(${d.x >= Math.PI ? 180 : 0})`)
          .attr("dy", "0.31em")
          .attr("x", d => ((d.x < Math.PI) === !d.children ? 6 : -6))
          .attr("text-anchor", d => ((d.x < Math.PI) === !d.children ? "start" : "end"))
          .text((d: any) => d.data.name)
          .style("cursor", "pointer")
          .on('click', (_, d: any) => {
            const oldFullData = Object.assign({}, fullData);
            const { id, index } = d.data;
            const _treeToShow = createTreeNode(data.name, data.id, null);

            filpVisibilityOfChildNodes(id, index, oldFullData);
            getTreeToShow(_treeToShow, oldFullData);
            setTreeToShow(_treeToShow);
            setFullData(oldFullData);
        })

    const namesClone = names.clone(true)
      .lower()
      .attr("stroke", "white");

    // zooming and panning
    const zoom = d3.zoom<any, any>().scaleExtent([0.5, 32]).on("zoom", ({ transform }) => {
      setPreviousZoom(transform);
      lines.attr("transform", transform);
      dots.attr("transform", transform);
      names.attr("transform", transform);
      namesClone.attr("transform", transform);
    });

    if (previousZoom) {
      svg.call(zoom).call(zoom.transform, previousZoom);
    } else {
      const defaultZoom = d3.zoomIdentity.translate(width / 2, height / 2);
      svg.call(zoom).call(zoom.transform, defaultZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgRef, data, fontSize, radiusGap, fullData, treeToShow]);

  return <svg ref={setSvgRef} width='100%' height='100vh' />;
};

export default AgentSponsorsTree;
