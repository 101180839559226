import React, { useState } from 'react';
import qs from 'qs';
import { Column, Row } from 'react-table';
import { Link, RouteComponentProps } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ProfileImageCell from '../components/table/Cells/ProfileImageCell';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import PageLayoutWithSearch from '../components/PageLayoutWithSearch';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import {
  AgentResponse,
  AgentResponseAgentStatusEnum,
  SearchRequestTargetObjectEnum,
} from '../openapi/yenta';
import { enumFilter, resourceTableFetchData } from '../utils/TableUtils';
import DateCell from '../components/table/Cells/DateCell';
import AgentStatusCell from '../components/table/Cells/AgentStatusCell';
import MilliDateCell from '../components/table/Cells/MilliDateCell';
import IconButton from '../components/IconButton';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';
import PhoneNumberCell from '../components/table/Cells/PhoneNumberCell';

interface PeopleTableContainerProps extends RouteComponentProps {}

export interface AgentResponseModified extends AgentResponse {
  status?: AgentResponseAgentStatusEnum;
  anniversaryDate?: string;
}

// TODO: Add License State and City.
export const columns: Column<AgentResponseModified>[] = [
  {
    Header: 'Image',
    accessor: 'avatar',
    Cell: ({ value, row }) => (
      <ProfileImageCell
        name={`${row.original.firstName} ${row.original.lastName}`}
        imageUrl={value}
      />
    ),
    disableSortBy: true,
    disableFilters: true,
    cardColSize: 2,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
    cardColSize: 5,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
    cardColSize: 5,
  },
  {
    Header: 'Email',
    accessor: 'emailAddress',
    Cell: ({ value }) => value,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
    Cell: ({ value }) => <PhoneNumberCell phoneNumber={value} />,
    cardColSize: 6,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Anniversary Date',
    accessor: 'anniversaryDate',
    Cell: ({ value }) => (value ? <DateCell date={value} /> : 'N/A'),
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => (value ? <MilliDateCell date={value} /> : 'N/A'),
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (value ? <AgentStatusCell status={value} /> : 'N/A'),
    disableFilters: true,
    cardColSize: 6,
  },
  {
    Header: 'ID',
    accessor: 'id',
    disableSortBy: true,
    Filter: TextStrictCaseColumnFilter,
  },
  // {
  //   Header: 'License State',
  //   // @ts-ignore
  //   accessor: 'licenses[0].administrativeArea.stateOrProvince',
  //   Filter: StateSelectColumnFilter,
  //   cardColSize: 6,
  // },
];

export const columnsWithAction: Array<Column<AgentResponseModified>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<AgentResponseModified> }) => (
      <Link to={`/people/${row.original.id}`}>
        <IconButton leftIcon={<VisibilityIcon titleAccess='View' />} />
      </Link>
    ),
  },
  ...columns,
];

const columnsToSearch = ['firstName', 'lastName', 'emailAddress'];
export const columnsToFetch: string[] = columns.map(
  (col) => col.accessor as string,
);

const PeopleSearchRoute: React.FC<PeopleTableContainerProps> = ({
  location,
}) => {
  const { term } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [search, setSearch] = useState<string | undefined>(
    typeof term === 'string' ? term : undefined,
  );

  return (
    <PageLayoutWithSearch
      path={[
        { title: 'Home', url: '/' },
        { title: 'People', url: '/people' },
      ]}
      search={search}
      onSearchChange={setSearch}
    >
      <div className='px-4 lg:pt-5'>
        <ResourceIndexContainer<AgentResponseModified>
          header='People'
          columns={columnsWithAction}
          search={search}
          filter={{
            status: enumFilter('status', AgentResponseAgentStatusEnum.Active),
          }}
          initialSort={{ lastName: 'asc' }}
          fetchData={(req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              columnsToSearch,
              SearchRequestTargetObjectEnum.Agent,
              'yenta',
            )
          }
          resourceName='people'
        />
      </div>
    </PageLayoutWithSearch>
  );
};

export default PeopleSearchRoute;
