import React from 'react';
import { BoardResponseStatusEnum } from '../../../openapi/yenta';
import { EnumMap } from '../../../types';
import Pill, { PillVariantType } from '../../Pill';
import { capitalizeEnum } from '../../../utils/StringUtils';

interface BoardStatusPillProps {
  status: BoardResponseStatusEnum;
}

const statusToVariant: EnumMap<BoardResponseStatusEnum, PillVariantType> = {
  ACTIVE: 'success',
  INACTIVE: 'danger',
  NOT_MEMBERS: 'warning',
  PENDING: 'warning',
  WILLING_TO_JOIN: 'primary',
};

const BoardStatusPill: React.FC<BoardStatusPillProps> = ({ status }) => {
  return (
    <Pill text={capitalizeEnum(status)} variant={statusToVariant[status]} />
  );
};

export default BoardStatusPill;
