import React from 'react';
import { Link } from 'react-router-dom';
import InternalServer500Img from '../assets/icons/500.svg';

interface Route500Props {}

const Route500: React.FC<Route500Props> = () => {
  return (
    <div className='h-screen flex flex-col justify-center items-center -mt-10'>
      <div>
        <img src={InternalServer500Img} alt='500' />
        <p className='text-center font-primary-medium text-dark text-lg'>
          Something went wrong.
        </p>
      </div>
      <Link
        to='/support'
        className='bg-primary rounded text-white items-center p-2 my-4 mx-auto'
      >
        Contact Support
      </Link>
    </div>
  );
};

export default Route500;
