import React, { useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { ExitToApp, MoreVert } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ReactComponent as AccountPreferences } from '../assets/icons/account-preferences.svg';
import { ReactComponent as ResetPassword } from '../assets/icons/reset-password.svg';
import { resetApp } from '../slices/actions/authActions';
import { deleteAuthCookie } from '../utils/AuthUtils';
import { AgentResponse } from '../openapi/yenta';
import IconButton from './IconButton';
import SideBarUserItem from './SideBarUserItem';

interface Props {
  userDetail: AgentResponse;
}
const SideBarUserControl: React.FC<Props> = ({ userDetail }) => {
  const dispatch = useDispatch();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const logout = () => {
    deleteAuthCookie();
    dispatch(resetApp());
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['top']}
      padding={-8}
      align='end'
      onClickOutside={() => setIsPopoverOpen(false)}
      containerClassName='fixed z-50'
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            popoverRect={popoverRect}
            childRect={childRect}
            arrowColor='gray'
            arrowSize={10}
            arrowStyle={{ opacity: 0.4 }}
            className='popover-arrow-container'
          >
            <div className='bg-trueGray-600 rounded-lg w-64 py-1 items-start'>
              <Link
                to={`/people/${userDetail?.id}`}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              >
                <SideBarUserItem
                  icon={
                    <SvgIcon
                      component={AccountPreferences}
                      viewBox='0 0 20 20'
                    />
                  }
                  label='Account preferences'
                />
              </Link>

              <Link to='/reset-password' onClick={logout}>
                <SideBarUserItem
                  icon={
                    <SvgIcon component={ResetPassword} viewBox='0 0 19 19' />
                  }
                  label='Reset Password'
                />
              </Link>
              <button className='w-full' type='button' onClick={logout}>
                <SideBarUserItem icon={<ExitToApp />} label='Log out' />
              </button>
            </div>
          </ArrowContainer>
        );
      }}
    >
      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <IconButton leftIcon={<MoreVert />} variant='no-outline' />
      </div>
    </Popover>
  );
};

export default SideBarUserControl;
