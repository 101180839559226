import React from 'react';
import classNames from 'classnames';
import SearchIcon from '@material-ui/icons/Search';

export interface SearchBarProps {
  placeholder?: string;
  onChange: (str: string) => void;
  value: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onChange,
  placeholder = 'Search',
  value,
}) => {
  return (
    <div
      className={classNames(
        'w-full flex flex-row bg-trueGray-100 items-center px-2 rounded',
      )}
    >
      <SearchIcon className='text-gray-400' fontSize='small' />
      <input
        type='text'
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className='w-full appearance-none p-2 border-none bg-transparent bg-trueGray-100 focus:outline-none focus:ring-0 primary-light flex-grow'
      />
    </div>
  );
};

export default SearchBar;
