import React from 'react';
import { TransactionLifecycleStateValueStateEnum } from '../openapi/arrakis';
import { readableTransitionName } from '../utils/TransactionHelper';
import IconButton from './IconButton';

interface TransactionActionProps {
  action: TransactionLifecycleStateValueStateEnum;
  onClick(): void;
}

const TransactionAction: React.FC<TransactionActionProps> = ({
  action,
  onClick,
}) => {
  return (
    <IconButton
      buttonType='button'
      variant='outline'
      label={readableTransitionName(action)}
      onClick={onClick}
    />
  );
};

export default TransactionAction;
