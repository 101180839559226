import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import { last } from 'lodash';
import { InfoOutlined } from '@material-ui/icons';
import { FormType } from '../../utils/FormUtils';
import { RootState } from '../../types';
import {
  ApplicationControllerApi,
  ApplicationResponseNextStatusEnum,
} from '../../openapi/yenta';
import FormBasedOnType from '../FormBasedOnType';
import Alert from '../Alert';
import ApplicationLayout from '../ApplicationLayout';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import { saveApplication } from '../../slices/AuthSlice';
import ErrorService from '../../services/ErrorService';

interface ApplicationLOISigningProps extends RouteComponentProps {}

const ApplicationLOISigning: React.FC<ApplicationLOISigningProps> = ({
  history,
  location,
}) => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const application = last(userDetail?.applications);
  const { sponsorCode, sponsorName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useRedirectAwayOnboardingEffect(
    ApplicationResponseNextStatusEnum.SignLetterOfIntent,
    history,
  );

  const handleSubmit = async (values: any) => {
    try {
      const api = new ApplicationControllerApi(getYentaConfiguration());
      const { data } = await api.signLetterOfIntentUsingPOST({
        applicationId: application?.id!,
        name: values.name,
        numDays: values.numDays,
        residentIn: values.residentIn,
        signature: values.signature,
      });
      dispatch(saveApplication(data));
      return history.push(
        `/onboarding/application-loi-signing/success?${qs.stringify({
          sponsorCode,
          sponsorName,
        })}`,
      );
    } catch (e) {
      ErrorService.notify('Unable to sign LOI', e);
      setErrorMessage(e.response.data.message);
    }
  };

  return (
    <ApplicationLayout>
      <div className='flex justify-center items-center my-10 lg:mx-4 mx-0'>
        <div className='container'>
          <div className='text-center mx-2 lg:mx-0'>
            <p className='text-xl font-primary-medium'>LETTER OF INTENT</p>
            <p className='text-gray-800'>
              Please Review and Sign the document below.
            </p>
          </div>
          {errorMessage && (
            <div className='my-5'>
              <Alert
                text={errorMessage}
                variant='error'
                icon={<InfoOutlined />}
              />
            </div>
          )}
          <div className='bg-gray-100 flex justify-center lg:p-16 lg:p-5 p-0 mt-10 mb-20 lg:mb-40'>
            <FormBasedOnType
              formType={FormType.LETTER_OF_INTENT}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default ApplicationLOISigning;
