import React from 'react';
import { Switch } from 'react-router-dom';
import ApplicationForm from '../components/onboarding/ApplicationForm';
import ApplicationFormSuccess from '../components/onboarding/application-form/ApplicationFormSuccess';
import ApplicationTransferLicense from '../components/onboarding/ApplicationTransferLicense';
import ApplicationJoinBoard from '../components/onboarding/ApplicationJoinBoard';
import ApplicationSubmittedSuccess from '../components/onboarding/join-board/ApplicationSubmittedSuccess';
import ApplicationFee from '../components/onboarding/ApplicationFee';
import ApplicationFeeSuccess from '../components/onboarding/application-fee/ApplicationFeeSuccess';
import ApplicationICASigning from '../components/onboarding/ApplicationICASigning';
import ApplicationLOISigning from '../components/onboarding/ApplicationLOISigning';
import ApplicationICASigningSuccess from '../components/onboarding/ApplicationICASigningSuccess';
import ApplicationLOISigningSuccess from '../components/onboarding/ApplicationLOISigningSuccess';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import ErrorRoutes from './ErrorRoutes';

export interface OnboardingRoutesProps {}

const OnboardingRoutes: React.FC<OnboardingRoutesProps> = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        path='/onboarding/application-form'
        component={ApplicationForm}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-form/success'
        component={ApplicationFormSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-transfer-license'
        component={ApplicationTransferLicense}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-join-board'
        component={ApplicationJoinBoard}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-fee'
        component={ApplicationFee}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-fee/success'
        component={ApplicationFeeSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-ica-signing'
        component={ApplicationICASigning}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-ica-signing/success'
        component={ApplicationICASigningSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-submitted/success'
        component={ApplicationSubmittedSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-loi-signing'
        component={ApplicationLOISigning}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-loi-signing/success'
        component={ApplicationLOISigningSuccess}
        exact
      />
      <ErrorRoutes />
    </Switch>
  );
};

export default OnboardingRoutes;
