import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ErrorCode, CronJobsState } from '../types';
import ErrorService from '../services/ErrorService';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import {
  JobExecutionControllerApi,
  JobExecutionsResponse,
} from '../openapi/yenta/api';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: CronJobsState = {
  loading: false,
  cronJobs: [],
  cronJobHistory: [],
  fetchErrorCode: null,
};

const CronJobsSlice = createSlice({
  name: 'cronjobs',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    errorFetching(state, action: PayloadAction<ErrorCode>) {
      state.fetchErrorCode = action.payload;
    },
    saveLatestCronJobs(state, action: PayloadAction<JobExecutionsResponse>) {
      state.cronJobs = action.payload.jobExecutionResponses;
      state.fetchErrorCode = null;
    },
    saveCronJobHistory(state, action: PayloadAction<JobExecutionsResponse>) {
      state.cronJobHistory = action.payload.jobExecutionResponses;
      state.fetchErrorCode = null;
    },
  },
});

export const {
  changeLoading,
  saveLatestCronJobs,
  errorFetching,
  saveCronJobHistory,
} = CronJobsSlice.actions;

export const getLatestCronJobs = (): AppThunk => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new JobExecutionControllerApi(
      await getYentaConfiguration(),
    ).getLatestJobExecutionsUsingGET();
    dispatch(saveLatestCronJobs(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch latest cron jobs', e);
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoading(false));
  }
};

export const getCronJobsHistory = (jobName: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new JobExecutionControllerApi(
      await getYentaConfiguration(),
    ).getJobExecutionsUsingGET(jobName);
    dispatch(saveCronJobHistory(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch cron jobs history', e);
    dispatch(errorFetching(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoading(false));
  }
};

export default CronJobsSlice.reducer;
