import React from 'react';
import classNames from 'classnames';
import {
  Menu,
  MenuOpen,
  Person,
  HomeWorkTwoTone,
  PeopleOutlined,
  AccountBalanceWallet,
  PaymentTwoTone,
  EventNote,
  DescriptionOutlined,
  Schedule,
  Extension,
} from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GavelIcon from '@material-ui/icons/Gavel';
import ListIcon from '@material-ui/icons/List';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RealLogoImg from '../assets/img/realLogo.png';
import { RootState } from '../types';
import { getYentaImageUrl } from '../utils/ImgUtils';
import SideBarItem from './SideBarItem';
import SideBarMenu from './SideBarUserControl';
import ProfileImage from './ProfileImage';
import SystemTime from './SystemTime';

interface SideBarProps {
  isCollapsed: boolean;
  toggleCollapse(str: boolean): void;
}

const MainMenu: React.FC<SideBarProps> = ({ isCollapsed, toggleCollapse }) => {
  const toggleMenu = () => toggleCollapse(!isCollapsed);
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);

  return (
    <div>
      <div className='flex flex-row items-center lg:hidden py-3 px-4 bg-trueGray-800'>
        <Menu className='text-white' onClick={toggleMenu} />
        <Link to='/'>
          <img src={RealLogoImg} className='h-5 ml-4' alt='Real Logo' />
        </Link>
      </div>
      <div
        className={classNames(
          'fixed left-0 top-0 bottom-0 right-0 lg:static z-50',
          { 'hidden lg:block': isCollapsed },
        )}
      >
        <div
          className='absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-30 lg:hidden'
          role='button'
          onClick={toggleMenu}
        />
        <div
          className={classNames(
            'h-screen bg-trueGray-800 p-3 overflow-y-auto overflow-x-hidden relative',
            isCollapsed
              ? 'w-18'
              : 'w-72 lg:w-80 animate-slide-in-left lg:animate-none',
          )}
        >
          <div
            className={classNames(
              'flex flex-row text-center  pt-3 pb-8',
              isCollapsed ? 'justify-center' : 'justify-between',
            )}
          >
            {!isCollapsed && (
              <Link to='/'>
                <img src={RealLogoImg} alt='real logo' className='h-5 lg:h-7' />
              </Link>
            )}
            <span
              className='material-icons text-white cursor-pointer hidden lg:block'
              onClick={toggleMenu}
            >
              {isCollapsed ? <Menu /> : <MenuOpen titleAccess='menu-open' />}
            </span>
          </div>
          <div>
            {!isCollapsed && <SystemTime />}
            <SideBarItem
              icon={<AssignmentIcon />}
              label='Applications'
              linkTo='/applications'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<Person />}
              label='People'
              linkTo='/people'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<PeopleOutlined />}
              label='Teams'
              linkTo='/teams'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<HomeWorkTwoTone />}
              label='Offices'
              linkTo='/offices'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<AccountBalanceWallet />}
              label='Transactions'
              linkTo='/transactions'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<DescriptionOutlined />}
              label='Invoices'
              linkTo='/invoices'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<PaymentTwoTone />}
              label='Outgoing Payments'
              linkTo='/outgoing-payments'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<ListIcon />}
              label='MLS'
              linkTo='/mls'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<GavelIcon />}
              label='Boards'
              linkTo='/boards'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<EventNote />}
              label='Events'
              linkTo='/events'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<Schedule />}
              label='Jobs'
              linkTo='/jobs'
              collapsed={isCollapsed}
            />
            <SideBarItem
              icon={<Extension />}
              label='Experimental'
              linkTo='/experimental'
              collapsed={isCollapsed}
            />
          </div>
          <div className='flex flex-row justify-between items-center bottom-0 left-0 right-0 px-3 absolute py-2 border-t border-trueGray-600 w-full'>
            <div className='flex flex-row items-center'>
              <ProfileImage
                width={50}
                imageUrl={getYentaImageUrl(userDetail?.avatar)}
              />
              {!isCollapsed && (
                <span className='text-white pl-3'>{userDetail?.fullName}</span>
              )}
            </div>
            {!isCollapsed && <SideBarMenu userDetail={userDetail!} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
