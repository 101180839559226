import React, { useCallback, useMemo, useState } from 'react';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useDispatch } from 'react-redux';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
import { ReferralResponse } from '../openapi/arrakis';
import ResourceTable, {
  ResourceTableVariant,
} from '../containers/ResourceTable';
import { isSmScreen } from '../utils/BrowserUtils';
import Button from '../components/Button';
import AgentReferralStatusCell from '../components/table/Cells/AgentReferralStatusCell';
import ReferralAgentNameCell from '../components/table/Cells/ReferralAgentNameCell';
import ReferralAgentEmailCell from '../components/table/Cells/ReferralAgentEmailCell';
import SaveReferralAgentFormSidebarModal from '../forms/SaveReferralAgentFormSidebarModal';
import ConfirmDeleteRecordModal from '../components/ConfirmDeleteRecordModal';
import {
  deleteReferralDispatch,
  downloadReferralDoc,
} from '../slices/AgentSlice';
import { AgentResponse } from '../openapi/yenta';
import IconButton from '../components/IconButton';
import DisburseReferral from '../components/Agent/DisburseReferral';
import { AppDispatch } from '../types';
import TextStrictCaseColumnFilter from '../components/table/Filters/TextStrictCaseColumnFilter';
import { isReferralActionable } from '../utils/ReferralsUtils';

interface AgentDetailReferralsRouteProps {
  agent: AgentResponse;
  referrals: ReferralResponse[];
}

const AgentDetailReferralsRoute: React.FC<AgentDetailReferralsRouteProps> = ({
  agent,
  referrals,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const getDownloadUrl = useCallback(
    async (id: string) => {
      let url = await dispatch(downloadReferralDoc(id!));
      if (url) window.open(url, '_blank');
    },
    [dispatch],
  );
  const data = referrals || [];
  const [addReferral, setAddReferral] = useState<boolean>(false);
  const [
    disburseReferral,
    setDisburseReferral,
  ] = useState<ReferralResponse | null>(null);
  const [
    deletingReferral,
    setDeletingReferral,
  ] = useState<ReferralResponse | null>(null);
  const [editReferral, setEditReferral] = useState<ReferralResponse | null>(
    null,
  );

  const columns: Array<Column<ReferralResponse>> = useMemo(
    () => [
      {
        Header: 'Agent Name',
        accessor: 'receivingAgentUserYentaId',
        id: 'agentName',
        Cell: ({ value, row }) => (
          <ReferralAgentNameCell
            id={value}
            externalAgentName={row.original.externalAgentName}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Agent Email',
        accessor: 'receivingAgentUserYentaId',
        id: 'agentEmail',
        Cell: ({ value, row }) => (
          <ReferralAgentEmailCell
            id={value}
            externalAgentEmail={row.original.externalAgentEmail}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Client Name',
        accessor: 'clientName',
        Cell: ({ value }) => value || 'NA',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Client Email',
        accessor: 'clientEmail',
        Cell: ({ value }) => value || 'NA',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Property Address',
        accessor: 'propertyAddress',
        Cell: ({ value }) => value || 'NA',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'referralStatus',
        Cell: ({ value }) => <AgentReferralStatusCell status={value!} />,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'External Agent Brokerage Name',
        accessor: 'externalAgentBrokerageName',
        Cell: ({ value }) => value || 'NA',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Expected Close Date',
        accessor: 'expectedCloseDate',
        Cell: ({ value }) => value,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Referral Agreement',
        accessor: 'id',
        id: 'downloadId',
        Cell: ({ value }) => (
          <Button
            className='flex flex-nowrap items-center space-x-2'
            type='outline'
            label='Download Agreement'
            onClick={() => getDownloadUrl(value!)}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ value }) => value,
        Filter: TextStrictCaseColumnFilter,
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        accessor: 'referralStatus',
        id: 'Actions',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value, row: { original } }) => (
          <div>
            {isReferralActionable(value!) && (
              <div className='space-x-2 flex flex-nowrap'>
                <Button
                  label='Disburse'
                  type='outline'
                  onClick={() => setDisburseReferral(original)}
                />
                <IconButton
                  variant='outline'
                  leftIcon={<FiEdit3 title='Edit' size={18} />}
                  onClick={() => setEditReferral(original)}
                />
                <IconButton
                  variant='outline'
                  leftIcon={<FiTrash2 title='Delete' size={18} />}
                  onClick={() => setDeletingReferral(original)}
                />
              </div>
            )}
          </div>
        ),
      },
    ],
    [getDownloadUrl],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <div className='px-4 mt-4'>
      <div className='flex flex-row items-center mb-4'>
        <span className='font-bold text-xl'>Referrals</span>
        <button
          onClick={() => setAddReferral(true)}
          className='focus:outline-none ml-2 text-primary'
        >
          Add
        </button>
      </div>
      <ResourceTable<ReferralResponse>
        {...tableInstance}
        resourceName='Referral'
        fetchData={() => ({
          data,
          total: data.length,
        })}
        variant={
          isSmScreen() ? ResourceTableVariant.CARD : ResourceTableVariant.ROW
        }
        hidePagination
      />
      <SaveReferralAgentFormSidebarModal
        isOpen={addReferral}
        onClose={() => setAddReferral(false)}
        agent={agent}
      />
      <SaveReferralAgentFormSidebarModal
        isOpen={!!editReferral}
        onClose={() => setEditReferral(null)}
        referral={editReferral!}
      />
      <ConfirmDeleteRecordModal
        onDelete={async () => {
          await dispatch(deleteReferralDispatch(deletingReferral?.id!));
          setDeletingReferral(null);
        }}
        title='Are you sure?'
        subtitle='This referral will be permanently deleted.'
        isOpen={!!deletingReferral}
        onClose={() => setDeletingReferral(null)}
      />
      <DisburseReferral
        disburseReferral={disburseReferral}
        onClose={() => setDisburseReferral(null)}
      />
    </div>
  );
};
export default AgentDetailReferralsRoute;
