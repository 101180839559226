import React from 'react';
import { StateName, StateAbbreviation } from '../../../types';
import StateIcon from '../../StateIcon';

export interface StateIconCellProps {
  state: StateAbbreviation;
}

// TODO: Replace with StateOrProvinceIconCell when api is ready
const StateIconCell: React.FC<StateIconCellProps> = ({ state }) => {
  return (
    <div className='flex flex-row space-x-2 items-center'>
      <StateIcon state={StateAbbreviation[state]} width={30} variant='circle' />
      <p>{StateName[state]}</p>
    </div>
  );
};

export default StateIconCell;
