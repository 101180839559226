import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { IoIosInformationCircle } from 'react-icons/io';
import CustomSwitch from './CustomSwitch';
import DefaultLoader from './DefaultLoader';

interface ParticipantToggleFlagOptionProps {
  value: boolean;
  onChange(value: boolean): void;
  title: string;
  tooltip?: string;
  loading?: boolean;
}

const ToggleRow: React.FC<ParticipantToggleFlagOptionProps> = ({
  value,
  onChange,
  title,
  tooltip,
  loading,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();
  return (
    <div className='flex justify-between items-center'>
      <div className='flex flex-row py-1 items-center space-x-3'>
        <CustomSwitch
          toggle={value}
          onClick={() => !loading && onChange(!value)}
        />
        <p>{title}</p>
        {tooltip && (
          <div>
            <div ref={setTriggerRef}>
              <IoIosInformationCircle size={20} />
            </div>
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip' })}
              >
                <div {...getArrowProps({ className: 't-arrow' })} />
                {tooltip}
              </div>
            )}
          </div>
        )}
      </div>
      {loading && (
        <div className='pr-3'>
          <DefaultLoader noPadding iconSize='default' />
        </div>
      )}
    </div>
  );
};

export default ToggleRow;
