import React, { useState } from 'react';
import { FiDollarSign } from 'react-icons/fi';
import SectionHeader from '../SectionHeader';
import ConfirmDeleteRecordModal from '../ConfirmDeleteRecordModal';
import {
  CommissionAdvanceResponse,
  TransactionsOverviewResponse,
} from '../../openapi/arrakis';
import { AgentResponse } from '../../openapi/yenta';
import DefaultError from '../DefaultError';
import AddAgentCommissionAdvancesSidebarForm from './AddAgentCommissionAdvancesSidebarForm';
import AgentCommissionAdvancesTable from './AgentCommissionAdvancesTable';
import EditAgentCommissionAdvancesSidebarForm from './EditAgentCommissionAdvancesSidebarForm';

interface AgentCommissionAdvancesProps {
  agent: AgentResponse;
  commissionAdvances: CommissionAdvanceResponse[];
  currentTransactionOverview: TransactionsOverviewResponse;
}

const AgentCommissionAdvances: React.FC<AgentCommissionAdvancesProps> = ({
  agent,
  commissionAdvances,
  currentTransactionOverview,
}) => {
  const [
    addingCommissionAdvances,
    setAddingCommissionAdvances,
  ] = useState<boolean>(false);
  const [
    editingCommissionAdvances,
    setEditingCommissionAdvances,
  ] = useState<CommissionAdvanceResponse | null>(null);
  const [
    deletingCommissionAdvances,
    setDeletingCommissionAdvances,
  ] = useState<CommissionAdvanceResponse | null>(null);

  return (
    <div>
      <SectionHeader
        icon={<FiDollarSign />}
        title='Commission Advances'
        actionText='Add'
        onActionClick={() => setAddingCommissionAdvances(true)}
      />
      <div className='mt-4'>
        {commissionAdvances ? (
          <AgentCommissionAdvancesTable
            data={commissionAdvances}
            setDeletingCommissionAdvances={setDeletingCommissionAdvances}
            setEditingCommissionAdvances={setEditingCommissionAdvances}
          />
        ) : (
          <DefaultError message='We had trouble fetching the commission advances. Please try again in a few moments.' />
        )}
      </div>
      <AddAgentCommissionAdvancesSidebarForm
        isOpen={addingCommissionAdvances}
        onClose={() => setAddingCommissionAdvances(false)}
        openTransactions={currentTransactionOverview.openTransactions!}
        agent={agent}
      />
      <EditAgentCommissionAdvancesSidebarForm
        isOpen={!!editingCommissionAdvances}
        onClose={() => setEditingCommissionAdvances(null)}
        commissionAdvance={editingCommissionAdvances!}
      />
      <ConfirmDeleteRecordModal
        onDelete={() => {}}
        title='Are you sure?'
        subtitle='This commission advance will be permanently deleted.'
        isOpen={!!deletingCommissionAdvances}
        onClose={() => setDeletingCommissionAdvances(null)}
      />
    </div>
  );
};

export default AgentCommissionAdvances;
