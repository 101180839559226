import React from 'react';
import { CheckCircleOutline } from '@material-ui/icons';
import Pill, { PillVariantType } from '../../Pill';
import { EnumMap } from '../../../types';
import { EscrowResponseEscrowStatusEnum } from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';

export interface EscrowStatusCellProps {
  status: EscrowResponseEscrowStatusEnum;
}

const statusToVariant: EnumMap<
  EscrowResponseEscrowStatusEnum,
  PillVariantType
> = {
  DISBURSED: 'success',
  RECEIVED: 'primary',
  SUBMITTED: 'warning',
};

const statusToIcon: EnumMap<
  EscrowResponseEscrowStatusEnum,
  React.ReactElement
> = {
  RECEIVED: <CheckCircleOutline fontSize='small' />,
  DISBURSED: <CheckCircleOutline fontSize='small' />,
  SUBMITTED: <CheckCircleOutline fontSize='small' />,
};

const EscrowStatusCell: React.FC<EscrowStatusCellProps> = ({ status }) => {
  const variant: PillVariantType = statusToVariant[status];
  const icon: React.ReactElement = statusToIcon[status];

  return <Pill text={capitalizeEnum(status)} variant={variant!} icon={icon!} />;
};

export default EscrowStatusCell;
