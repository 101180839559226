import React from 'react';
import { values } from 'lodash';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { TeamResponseTypeEnum } from '../../../openapi/yenta';
import SelectColumnFilter, {
  SelectColumnFilterProps,
} from './Base/SelectColumnFilter';

export interface TeamTypeSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const TeamTypeSelectColumnFilter = <D extends object>(
  props: TeamTypeSelectColumnFilterProps<D>,
) => {
  return (
    <SelectColumnFilter
      {...props}
      options={values(TeamResponseTypeEnum).map((type) => ({
        label: capitalizeEnum(type),
        value: type,
      }))}
    />
  );
};

export default TeamTypeSelectColumnFilter;
