import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { MdClose } from 'react-icons/md';
import { usePopperTooltip } from 'react-popper-tooltip';
import { capitalizeEnum, formatPhoneNumber } from '../utils/StringUtils';
import {
  UpdateParticipantRequest,
  ParticipantValue,
  PaymentParticipantValue,
} from '../openapi/arrakis';
import { isAgentParticipant } from '../utils/ParticipantHelper';
import AvatarPill from './AvatarPill';
import GridCard from './GridCard';
import IconText from './IconText';
import ToggleRow from './ToggleRow';

export interface ToggleableFieldConfig {
  key: keyof UpdateParticipantRequest;
  label: string;
  tooltip?: string;
  participantKey: keyof PaymentParticipantValue | keyof ParticipantValue;
  loading: boolean;
}

export interface ParticipantCardProps {
  participant: PaymentParticipantValue | ParticipantValue;
  onEdit(): void;
  onDelete(): void;
  toggleFlag(flags: keyof UpdateParticipantRequest, value: boolean): void;
  isReadonly?: boolean;
  transactionOwner?: boolean;
}

const ParticipantCard: React.FC<ParticipantCardProps> = ({
  participant,
  children,
  onEdit,
  onDelete,
  isReadonly,
  toggleFlag,
  transactionOwner,
}) => {
  const [isFlagsShown, setIsFlagsShown] = useState(false);
  const [toggleableFieldConfig, setToggleableFieldConfig] = useState<
    ToggleableFieldConfig[]
  >([
    {
      key: 'commissionDocumentRecipient',
      label: 'Receives Invoice/CDA?',
      participantKey: 'commissionDocumentRecipient',
      loading: false,
    },
    {
      key: 'paidByReal',
      label: 'Is Single Check?',
      participantKey: 'paidByReal',
      loading: false,
    },
    {
      key: 'passThrough',
      label: 'Pass Through Deal?',
      participantKey: 'passThrough',
      loading: false,
    },
  ]);

  useEffect(() => {
    if (
      'personalDeal' in participant &&
      toggleableFieldConfig[0].key !== 'personalDeal'
    ) {
      setToggleableFieldConfig((config) => [
        {
          key: 'personalDeal',
          label: 'Personal Deal?',
          participantKey: 'personalDeal',
          loading: false,
        },
        ...config,
      ]);
    }
  }, [participant, toggleableFieldConfig]);

  const changeToggleLoading = (key: string, state: boolean) => {
    const updatedToggleFieldConfig = toggleableFieldConfig.map((toggle) => {
      if (toggle.key === key) {
        toggle.loading = state;
        return toggle;
      }
      return toggle;
    })!;
    setToggleableFieldConfig(updatedToggleFieldConfig);
  };
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const participantName =
    participant?.firstName || participant?.lastName
      ? `${participant?.firstName} ${participant?.lastName}`
      : participant?.company;

  return (
    <section className='relative overflow-hidden' id='participantCard'>
      <GridCard
        items={[
          {
            name: 'name',
            value: (
              <div className='flex items-center space-x-2'>
                {isAgentParticipant(participant.type!) ? (
                  // TODO: Add avatar when available in api
                  <Link to={`/people/${participant?.yentaId!}`}>
                    <AvatarPill imageUrl='' text={participantName || 'N/A'} />
                  </Link>
                ) : (
                  // TODO: Add avatar when available in api
                  <AvatarPill imageUrl='' text={participantName || 'N/A'} />
                )}
                {transactionOwner && (
                  <div>
                    <div ref={setTriggerRef}>
                      <SupervisedUserCircleIcon data-testid='Owner' />
                    </div>
                    {visible && (
                      <div
                        ref={setTooltipRef}
                        {...getTooltipProps({ className: 'tooltip' })}
                      >
                        <div {...getArrowProps({ className: 't-arrow' })} />
                        Transaction Owner
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
          },
          {
            name: 'contact information',
            value: (
              <div className='flex flex-col'>
                {participant.emailAddress && <p>{participant.emailAddress}</p>}
                {participant.phoneNumber && (
                  <p>{formatPhoneNumber(participant.phoneNumber)}</p>
                )}
                {!participant.emailAddress && !participant.phoneNumber && (
                  <p>N/A</p>
                )}
              </div>
            ),
          },
          {
            name: 'type',
            value: capitalizeEnum(participant.type!),
          },
          {
            name: 'role',
            value: capitalizeEnum(participant.role!),
          },
        ]}
      />
      {children}
      {!isReadonly && (
        <div className='border border-gray-200 border-collapse w-full flex justify-center items-center'>
          <div
            className='border-r flex justify-center w-full p-2 cursor-pointer'
            onClick={onEdit}
          >
            <IconText
              icon={<EditOutlinedIcon color='action' fontSize='small' />}
              text='Edit'
            />
          </div>
          <div
            className='border-r flex justify-center w-full p-2 cursor-pointer'
            onClick={() => setIsFlagsShown(true)}
          >
            <IconText
              icon={<ToggleOffIcon color='action' fontSize='small' />}
              text='Toggle Flags'
            />
          </div>
          <div
            className='flex justify-center w-full p-2 cursor-pointer'
            onClick={onDelete}
          >
            <IconText
              icon={
                <DeleteOutlineOutlinedIcon color='action' fontSize='small' />
              }
              text='Delete'
            />
          </div>
        </div>
      )}
      {isFlagsShown && (
        <div className='absolute z-10 top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70 flex flex-col justify-end'>
          <div className='flex justify-end'>
            <button
              type='button'
              className='focus:outline-none'
              onClick={() => setIsFlagsShown(false)}
            >
              <MdClose size={32} className='text-white' />
            </button>
          </div>
          <div className='bg-white border animate-slide-in-up divide-y'>
            {toggleableFieldConfig.map(
              ({ key, label, tooltip, participantKey, loading }) => (
                <ToggleRow
                  key={key}
                  // @ts-ignore
                  value={!!participant[participantKey]}
                  onChange={async (value) => {
                    changeToggleLoading(key, true);
                    await toggleFlag(key, value);
                    changeToggleLoading(key, false);
                  }}
                  title={label}
                  tooltip={tooltip}
                  loading={loading}
                />
              ),
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default ParticipantCard;
