import { times } from 'lodash';
import {
  IPaginateRes,
  StateAbbreviation,
  Transaction,
  TransactionAgent,
} from '../../../types';
import {
  fakeAddress,
  fakeCommissionSplit,
  fakeDate,
  fakeNumber,
  fakePersonFullName,
  fakeProfileImage,
  randomEnum,
} from '../../../testUtils/FakerUtils';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';

export const DefaultTransactionsPaginationResponse: IPaginateRes<Transaction> = {
  data: times<Transaction>(20, (id) => ({
    id: id + 1,
    name: fakeAddress(),
    status: randomEnum<TransactionLifecycleStateValueStateEnum>(
      TransactionLifecycleStateValueStateEnum,
    ),
    type: randomEnum<TransactionResponseTransactionTypeEnum>(
      TransactionResponseTransactionTypeEnum,
    ),
    closingDate: fakeDate().toISODate(),
    salesPrice: fakeNumber({ min: 10000000, max: 99999999 }),
    totalCommission: fakeNumber({ min: 500, max: 1000 }),
    participantsSplit: fakeNumber({ min: 100, max: 500 }),
    participantsSplitPercentage: fakeCommissionSplit(),
    totalRealSplit: fakeNumber({ min: 100, max: 500 }),
    totalRealSplitPercentage: fakeCommissionSplit(),
    teamId: fakeNumber({ min: 1, max: 1000 }),
    agents: times<TransactionAgent>(2, (id) => ({
      id: id + 1,
      name: fakePersonFullName(),
      imageUrl: fakeProfileImage(),
    })),
    state: randomEnum<StateAbbreviation>(StateAbbreviation),
    transactionCode: 'HHU-SZ9-1ZX-S43',
  })),
  total: fakeNumber({ min: 50, max: 100 }),
};
