import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainRoutes from './routes/MainRoutes';
import OnboardingRoutes from './routes/OnboardingRoutes';
import { RootState } from './types';
import DefaultLoader from './components/DefaultLoader';
import { fetchAuthUserDetail } from './slices/AuthSlice';
import { getAuthCookie } from './utils/AuthUtils';
import useRegisterWindowHeightEffect from './hooks/useRegisterWindowHeightEffect';
import ToastNotificationContainer from './containers/ToastNotificationContainer';
import InvoiceFormPdfRoute from './routes/InvoiceFormPdfRoute';
import CDAFormPdfRoute from './routes/CDAFormPdfRoute';
import RezenRoute from './routes/WelcomeToRezen/RezenRoute';
import ContinueOnMobileRoute from './routes/WelcomeToRezen/ContinueOnMobileRoute';
import { hideSupportWidget } from './utils/Acquire';
import ApiErrorModal from './components/ApiErrorModal';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import InvoiceFormRoute from './routes/InvoiceFormRoute';
import CDAFormRoute from './routes/CDAFormRoute';
import AuthRedirectRoute from './routes/AuthRedirectRoute';
import RezenICASigningRoute from './routes/WelcomeToRezen/RezenICASigningRoute';

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { loadingUserDetail } = useSelector((state: RootState) => state.auth);

  const fetchAuth = useCallback(async () => {
    if (getAuthCookie()) {
      await dispatch(fetchAuthUserDetail());
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    hideSupportWidget();
  }, []);

  useEffect(() => {
    fetchAuth();
  }, [fetchAuth]);

  useRegisterWindowHeightEffect();

  if (loading || loadingUserDetail) {
    return (
      <div className='h-screen w-screen'>
        <DefaultLoader />
      </div>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path='/pdf'>
            <AuthenticatedRoute
              path='/pdf/invoice/:id'
              component={InvoiceFormPdfRoute}
            />
            <AuthenticatedRoute
              path='/pdf/cda/:id'
              component={CDAFormPdfRoute}
            />
          </Route>
          <Route path='/app'>
            <AuthenticatedRoute path='/app/cda/:id' component={CDAFormRoute} />
            <AuthenticatedRoute
              path='/app/invoice/:id'
              component={InvoiceFormRoute}
            />
          </Route>
          <Route path='/redirect' component={AuthRedirectRoute} exact />

          {/* Keeping this as Route and not AuthenticatedRoute to keep proper 40{1,4} routes */}
          <Route path='/onboarding' component={OnboardingRoutes} />

          <Route path='/welcome-to-rezen' component={RezenRoute} exact />
          <AuthenticatedRoute
            path='/welcome-to-rezen/ica-signing'
            component={RezenICASigningRoute}
            exact
          />
          <Route
            path='/welcome-to-rezen/mobile-app'
            component={ContinueOnMobileRoute}
            exact
          />
          <MainRoutes />
        </Switch>
      </BrowserRouter>
      <ToastNotificationContainer />
      <ApiErrorModal />
    </div>
  );
};

export default App;
