import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TransactionCommissionDocumentRoute from './TransactionCommissionDocumentRoute';

interface Match {
  id: string;
}

interface TransactionInvoiceRouteProps extends RouteComponentProps<Match> {}

const TransactionCDARoute: React.FC<TransactionInvoiceRouteProps> = ({
  match,
}) => {
  const { id } = match.params;

  return (
    <TransactionCommissionDocumentRoute
      transactionId={id}
      headerName='Invoices'
      getUrl={(documentId) => `/invoice/${documentId}`}
    />
  );
};

export default TransactionCDARoute;
