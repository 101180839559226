import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Avatar from '../Avatar';
import { LAST_ALLOWABLE_TIER } from '../../constants/NetworkConstants';

export interface NetworkRowProps {
  name: string;
  tier: number;
  imageUrl: string;
  sizeOfNetwork: number;
  onClick(): void;
}

const NetworkRow: React.FC<NetworkRowProps> = ({
  name,
  tier,
  imageUrl,
  sizeOfNetwork,
  onClick,
}) => {
  const isClickable = tier !== LAST_ALLOWABLE_TIER;

  return (
    <div onClick={isClickable ? onClick : () => null}>
      <div className='py-2 flex justify-between items-center cursor-pointer'>
        <div className='flex items-center'>
          <Avatar name={name} imageUrl={imageUrl} size='xs' />
          <div className='px-3'>
            <p className='font-primary-medium text-base'>{name}</p>
            <div className='flex items-center'>
              <p className='font-primary-medium text-base mr-2'>
                {`Tier ${tier}`}
              </p>
            </div>
          </div>
        </div>
        <div className='flex items-center'>
          <span className='inline-flex items-center justify-center px-2 py-1 mr-2 text-xs text-black bg-gray-200 rounded-full'>
            {sizeOfNetwork}
          </span>
          {isClickable && (
            <div className='flex flex-col items-end'>
              <ChevronRightIcon fontSize='small' style={{ color: '#05C3F9' }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NetworkRow;
