import { CommissionDocumentResponseStatusEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

export interface CommissionDocumentResponseStatusSelectColumnFilterProps<
  D extends object
> extends SelectColumnFilterProps<D> {}

const CommissionDocumentResponseStatusSelectColumnFilter = <D extends object>(
  props: CommissionDocumentResponseStatusSelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(CommissionDocumentResponseStatusEnum)(
    props,
  );
};

export default CommissionDocumentResponseStatusSelectColumnFilter;
