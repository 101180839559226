import React from 'react';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import SidebarModal from '../components/SidebarModal';
import ControlledTextInput from '../components/ControlledTextInput';
import { numberToMoney } from '../utils/CurrencyUtils';
import { InvoiceResponse } from '../openapi/arrakis';
import { capitalizeEnum } from '../utils/StringUtils';

interface InvoiceFormSidebarModalProps {
  isOpen: boolean;
  onClose(reload?: boolean): void;
  invoice: InvoiceResponse;
}

const InvoiceFormSidebarModal: React.FC<InvoiceFormSidebarModalProps> = ({
  isOpen,
  onClose,
  invoice,
}) => {
  const { control, errors } = useForm();

  return (
    <SidebarModal title='Invoice Details' isOpen={isOpen} onClose={onClose}>
      <form className='flex flex-col flex-auto justify-between h-full'>
        <div className='p-4'>
          <ControlledTextInput
            control={control}
            label='Status'
            name='status'
            defaultValue={invoice?.status && capitalizeEnum(invoice?.status)}
            errors={errors}
            readOnly
          />
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Transaction Code'
              name='transactionId'
              defaultValue={invoice?.transactionCode}
              errors={errors}
              readOnly
            />
          </div>
          {invoice?.transactionCode && (
            <Link to={`/transactions/code/${invoice?.transactionCode}`}>
              <span className='text-primary flex items-center'>
                View Transaction <FiExternalLink className='ml-2' />
              </span>
            </Link>
          )}
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Invoice Number'
              name='invoiceNumber'
              defaultValue={invoice?.invoiceNumber}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Amount'
              name='invoicedAmount'
              defaultValue={
                invoice?.invoicedAmount?.amount &&
                numberToMoney(invoice.invoicedAmount.amount)
              }
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='First Name'
              name='firstName'
              defaultValue={invoice?.firstName}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Last Name'
              name='lastName'
              defaultValue={invoice?.lastName}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Company'
              name='company'
              defaultValue={invoice?.company}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Paid At'
              name='paidAt'
              defaultValue={
                invoice?.paidAt &&
                DateTime.fromMillis(invoice?.paidAt).toFormat('LL/dd/yy')
              }
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Payment System'
              name='paymentSystem'
              defaultValue={
                invoice?.paymentSystem && capitalizeEnum(invoice?.paymentSystem)
              }
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Payment System ID'
              name='paymentSystemId'
              defaultValue={invoice?.paymentSystemId}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Error'
              name='error'
              defaultValue={invoice?.error}
              errors={errors}
              readOnly
            />
          </div>
          <div className='mt-5 pb-20'>
            <ControlledTextInput
              control={control}
              label='Payer Note'
              name='payerNote'
              defaultValue={invoice?.payerNote}
              errors={errors}
              readOnly
            />
          </div>
        </div>
      </form>
    </SidebarModal>
  );
};

export default InvoiceFormSidebarModal;
