import { times } from 'lodash';
import {
  ActiveInactiveType,
  IPaginateRes,
  Member,
  Team,
  TeamType,
} from '../../../types';
import {
  fakeNumber,
  fakePersonFullName,
  fakeProfileImage,
  fakeTeamName,
  randomEnum,
} from '../../../testUtils/FakerUtils';

export const DefaultTeamPaginationResponse: IPaginateRes<Team> = {
  data: times<Team>(20, (id) => ({
    id: id + 1,
    name: fakeTeamName(),
    totalMembers: fakeNumber({ min: 50, max: 100 }),
    leader: {
      id: id + 1,
      name: fakePersonFullName(),
      imageUrl: fakeProfileImage(),
    },
    members: times<Member>(5, (id) => ({
      id: id + 1,
      name: fakePersonFullName(),
      imageUrl: fakeProfileImage(),
    })),
    type: randomEnum<TeamType>(TeamType),
    status: randomEnum<ActiveInactiveType>(ActiveInactiveType),
  })),
  total: fakeNumber({ min: 50, max: 100 }),
};
