import { AxiosError } from 'axios';

// https://stackoverflow.com/a/58278753
// Learn more about user defined type guards here:
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
export const isEnumTypeGuardGenerator = <T>(e: T) => (
  token: any,
): token is T[keyof T] => Object.values(e).includes(token as T[keyof T]);

export const isAxiosError = (error: any): error is AxiosError =>
  error.isAxiosError === true;
