import { LocationOn, Mail, PermIdentity, Phone } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React from 'react';
import IconText from './IconText';

export interface ApplicationHeaderProps {
  id: string;
  name: string;
  state: string;
  phoneNumber: string;
  primaryEmail: string;
  licenseNumber: string;
  expiryDate: string;
}

const ApplicationHeader: React.FC<ApplicationHeaderProps> = ({
  id,
  name,
  phoneNumber,
  primaryEmail,
  state,
  licenseNumber,
  expiryDate,
}) => {
  return (
    <div className='flex flex-col px-10 py-2 bg-gray-800 text-white'>
      <div className='flex flex-col md:pb-4 lg:pb-0 space-y-1'>
        <p className='text-2xl font-primary-medium'>{name}</p>
        <div className='flex flex-row space-x-2 items-center'>
          <p>Application ID: </p>
          <p>#{id}</p>
        </div>
        <IconText icon={<LocationOn fontSize='small' />} text={state} />

        <a href={`tel:${phoneNumber}`}>
          <IconText icon={<Phone fontSize='small' />} text={phoneNumber!} />
        </a>

        <a href={`mailto:${primaryEmail}`}>
          <IconText icon={<Mail fontSize='small' />} text={primaryEmail!} />
        </a>

        <div className='flex flex-col md:flex-row space-x-2 space-y-1 mb-2 md:items-center'>
          <IconText
            icon={<PermIdentity fontSize='small' className='text-gray-200' />}
            text={`License No: ${licenseNumber}`}
          />

          <div className='font-primary-light flex flex-row'>
            <p className='pr-2'>•</p> Expires:{' '}
            {DateTime.fromISO(expiryDate!).toFormat('yyyy-MM-dd')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationHeader;
