import React from 'react';
import { useSelector } from 'react-redux';
import { last } from 'lodash';
import { RootState } from '../../types';
import { ApplicationResponseApplicationTypeEnum } from '../../openapi/yenta';
import {
  getIndexFromStatus,
  getIndexFromStatusWithLOI,
  onboardingSteps,
  onboardingStepsWithLOI,
} from '../../testUtils/OnboardingUtils';
import VerticalStepProgress from './VerticalStepProgress';

export interface ApplicationVerticalStepProgressProps {}

const ApplicationVerticalStepProgress: React.FC<ApplicationVerticalStepProgressProps> = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const application = last(userDetail?.applications);

  const loi: boolean =
    application?.applicationType ===
    ApplicationResponseApplicationTypeEnum.LetterOfIntent;

  return (
    <VerticalStepProgress
      steps={loi ? onboardingStepsWithLOI : onboardingSteps}
      inProgressIndex={
        loi
          ? getIndexFromStatusWithLOI(application?.nextStatus!)
          : getIndexFromStatus(application?.nextStatus!)
      }
    />
  );
};

export default ApplicationVerticalStepProgress;
