import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthenticatedAdminRoute from '../components/AuthenticatedAdminRoute';
import GuestRoute from '../components/GuestRoute';
import { RootState } from '../types';
import ExperimentalRoute from '../experimental/Routes/ExperimentalRoute';
import PeopleSearchRoute from './PeopleSearchRoute';
import AgentDetailRoute from './AgentDetailRoute';
import OfficeIndexRoute from './OfficeIndexRoute';
import OfficeDetailRoute from './OfficeDetailRoute';
import TeamSearchRoute from './TeamSearchRoute';
import TeamDetailRoute from './TeamDetailRoute';
import MLSSearchRoute from './MLSSearchRoute';
import MLSDetailRoute from './MLSDetailRoute';
import TransactionsRoute from './TransactionsRoute';
import TransactionDetailRoute from './TransactionDetailRoute';
import ErrorRoutes from './ErrorRoutes';
import LoginRoute from './LoginRoute';
import RegisterRoute from './RegisterRoute';
import ResetPasswordRoute from './ResetPasswordRoute';
import ApplicationFormDetailRoute from './ApplicationFormDetailRoute';
import BoardSearchRoute from './BoardSearchRoute';
import BoardDetailRoute from './BoardDetailRoute';
import ApplicationFormIndex from './ApplicationFormIndex';
import InvoiceFormRoute from './InvoiceFormRoute';
import CDAFormRoute from './CDAFormRoute';
import OutgoingPaymentsIndexRoute from './OutgoingPaymentsIndexRoute';
import FlagsIndex from './secret/FlagsIndexRoute';
import MonitorEventsRoute from './MonitorEventsRoute';
import InvoicesIndexRoute from './InvoicesIndexRoute';
import TransactionDetailsByCodeRoute from './TransactionDetailsByCodeRoute';
import CronJobsRoute from './CronJobsRoute';
import CronJobsHistoryRoute from './CronJobsHistoryRoute';
import ChangePasswordRoute from './ChangePassword/ChangePasswordRoute';

interface AuthRoutesProps {}

const MainRoutes: React.FC<AuthRoutesProps> = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  return (
    <Switch>
      <Redirect
        path='/'
        from='/'
        to={!!userDetail ? '/people' : '/login'}
        exact
      />
      <GuestRoute path='/login' component={LoginRoute} exact />
      <GuestRoute path='/register' component={RegisterRoute} exact />
      <GuestRoute path='/reset-password' component={ResetPasswordRoute} exact />
      <Route path='/change-password' component={ChangePasswordRoute} exact />
      <AuthenticatedAdminRoute
        path='/people'
        component={PeopleSearchRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/people/:id'
        component={AgentDetailRoute}
      />
      <AuthenticatedAdminRoute
        path='/offices'
        component={OfficeIndexRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/offices/:id'
        component={OfficeDetailRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/outgoing-payments'
        component={OutgoingPaymentsIndexRoute}
      />
      <AuthenticatedAdminRoute
        path='/invoices'
        component={InvoicesIndexRoute}
      />
      <AuthenticatedAdminRoute
        path='/teams'
        component={TeamSearchRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/teams/:id'
        component={TeamDetailRoute}
        exact
      />
      <AuthenticatedAdminRoute path='/mls' component={MLSSearchRoute} exact />
      <AuthenticatedAdminRoute
        path='/mls/:id'
        component={MLSDetailRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/boards'
        component={BoardSearchRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/boards/:id'
        component={BoardDetailRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/transactions'
        component={TransactionsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/transactions/code/:code'
        component={TransactionDetailsByCodeRoute}
      />
      <AuthenticatedAdminRoute
        path='/transactions/:id'
        component={TransactionDetailRoute}
      />
      <AuthenticatedAdminRoute
        path='/invoice/:id'
        component={InvoiceFormRoute}
        exact
      />
      <AuthenticatedAdminRoute path='/cda/:id' component={CDAFormRoute} exact />
      <AuthenticatedAdminRoute
        path='/applications'
        component={ApplicationFormIndex}
        exact
      />
      <AuthenticatedAdminRoute
        path='/applications/:id'
        component={ApplicationFormDetailRoute}
      />
      <AuthenticatedAdminRoute path='/secret/flags' component={FlagsIndex} />
      <AuthenticatedAdminRoute
        path='/events'
        component={MonitorEventsRoute}
        exact
      />
      <AuthenticatedAdminRoute path='/jobs' component={CronJobsRoute} exact />
      <AuthenticatedAdminRoute
        path='/jobs/:jobName/history'
        component={CronJobsHistoryRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/experimental'
        component={ExperimentalRoute}
      />
      <ErrorRoutes />
    </Switch>
  );
};

export default MainRoutes;
