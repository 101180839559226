import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AgentResponse, TeamResponseTypeEnum } from '../../openapi/yenta';
import {
  addDomesticTeamMember,
  addNormalTeamMember,
  addPlatinumTeamMember,
} from '../../slices/TeamSlice';
import { capitalizeEnum } from '../../utils/StringUtils';
import { searchForRegisteredAgents } from '../../utils/TableUtils';
import Button from '../Button';
import ControlledAsyncSelectInput from '../ControlledAsyncSelectInput';
import ControlledSelectInput from '../ControlledSelectInput';
import ControlledTextInput from '../ControlledTextInput';
import SidebarModal from '../SidebarModal';
import { teamRolesForType } from '../../utils/TeamHelper';
import { AsyncSelectOption } from '../../types';

interface AddTeamMemberProps {
  isOpen: boolean;
  onClose(): void;
  teamId: string;
  teamType: TeamResponseTypeEnum;
}

const AddTeamMember: React.FC<AddTeamMemberProps> = ({
  isOpen,
  onClose,
  teamType,
  teamId,
}) => {
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const dispatch = useDispatch();

  const onSubmit = async (data: any) => {
    if (teamType === TeamResponseTypeEnum.Domestic) {
      await dispatch(
        addDomesticTeamMember(teamId, { agentId: data.agentId.value }),
      );
    }
    if (teamType === TeamResponseTypeEnum.Normal) {
      await dispatch(
        addNormalTeamMember(teamId, {
          agentId: data.agentId.value,
          commissionSplit:
            data.commissionSplit > 0 ? data.commissionSplit : undefined,
        }),
      );
    }
    if (teamType === TeamResponseTypeEnum.Platinum) {
      await dispatch(
        addPlatinumTeamMember(teamId, {
          agentId: data.agentId.value,
          commissionSplit:
            data.commissionSplit > 0 ? data.commissionSplit : undefined,
        }),
      );
    }
    onClose();
  };

  return (
    <SidebarModal
      title='Add New Member'
      subtitle=''
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='create-team-form'
      >
        <div className='p-4'>
          <div>
            <ControlledAsyncSelectInput
              control={control}
              errors={errors}
              name='agentId'
              placeholder='Search Member'
              label='Name'
              fetchData={async (search, page) => {
                const searchResponse = await searchForRegisteredAgents<
                  Pick<AgentResponse, 'id' | 'firstName' | 'lastName'>
                >(
                  ['id', 'firstName', 'lastName'],
                  ['firstName', 'lastName'],
                  page,
                  search,
                );

                const options: AsyncSelectOption[] = searchResponse.map(
                  (resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName}`,
                  }),
                );

                return options;
              }}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledSelectInput
              name='type'
              control={control}
              errors={errors}
              label='Role'
              placeholder='Member Role'
              options={[
                {
                  label: `Select Role`,
                  value: '',
                  disabled: true,
                },
                ...teamRolesForType(teamType).map((type) => ({
                  value: type,
                  label: capitalizeEnum(type),
                })),
              ]}
              rules={{
                required: 'Required',
              }}
            />
          </div>
          {teamType !== TeamResponseTypeEnum.Domestic && (
            <div className='mt-5'>
              <ControlledTextInput
                control={control}
                type='number'
                label='Commission Split'
                name='commissionSplit'
                errors={errors}
                placeholder='Commission Split'
                rules={{
                  min: { value: 0, message: 'Cannot be less than 0' },
                  max: {
                    value: teamType === TeamResponseTypeEnum.Normal ? 75 : 100,
                    message:
                      teamType === TeamResponseTypeEnum.Normal
                        ? 'Cannot be greater than 75'
                        : 'Cannot be greater than 100',
                  },
                  valueAsNumber: true,
                }}
              />
            </div>
          )}
        </div>
        <div className='p-2 md:p-4 bg-white border-t border-gray-200  bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 px-3 absolute py-2  w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label='Create'
            size='lg'
          />
          <Button
            buttonType='button'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default AddTeamMember;
