import React from 'react';
import { FilterProps } from 'react-table';
import ControlledTextInput from '../../ControlledTextInput';
import { PROCESS_FILTER_COLUMN } from '../../../utils/TableUtils';
import { FilterColumnsToProcess } from '../../../types';

export interface TextStrictCaseColumnFilterProps<D extends object>
  extends FilterProps<D> {}

const TextStrictCaseColumnFilter = <D extends object>({
  column,
  errors,
  control,
}: TextStrictCaseColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}[${FilterColumnsToProcess.STRICT_CASE}][${column.id}]`;

  return (
    <ControlledTextInput
      control={control}
      name={prefixFieldName}
      placeholder={`Enter ${column.render('Header')}...`}
      errors={errors}
      rules={{
        required: `${column.render('Header')} is required`,
      }}
    />
  );
};

export default TextStrictCaseColumnFilter;
