import React from 'react';
import { useForm } from 'react-hook-form';
import { FaMagic } from 'react-icons/fa';
import { map, filter, keys } from 'lodash';
import { FEATURE_FLAG_CONFIG } from '../../utils/FeatureFlagUtils';
import ControlledSelectInput from '../../components/ControlledSelectInput';
import {
  FeatureFlagRow,
  FeatureFlagTypeEnum,
  ISelectOption,
  YesNoType,
} from '../../types';
import FeatureFlagService from '../../services/FeatureFlagService';

interface FlagsIndexProps {}

const FlagsIndex: React.FC<FlagsIndexProps> = () => {
  const options: ISelectOption[] = [
    { label: 'Enabled', value: YesNoType.YES },
    { label: 'Disabled', value: YesNoType.NO },
  ];
  const { handleSubmit, errors, control } = useForm();

  const onSubmit = (values: any) => {
    const flagsToEnable = filter(
      keys(values),
      (v) => values[v] === YesNoType.YES,
    ) as FeatureFlagTypeEnum[];
    FeatureFlagService.setAllFeatureFlags(flagsToEnable);
  };

  return (
    <div className='max-w-xl mx-auto'>
      <h1 className='font-primary-medium mt-10 text-xl mb-1 flex'>
        <FaMagic className='mr-2' />
        Feature Flags
      </h1>
      <div className='rounded border border-coolGray-200 shadow'>
        <form onChange={handleSubmit(onSubmit)}>
          {map(
            FEATURE_FLAG_CONFIG,
            (config: FeatureFlagRow, flag: FeatureFlagTypeEnum) => (
              <div
                key={flag}
                className='flex justify-between align-middle border-b border-coolGray-200 p-3'
              >
                <div>
                  <p className='text-base mb-1'>{config.name}</p>
                  <p className='text-xs text-gray-500'>{config.subtitle}</p>
                </div>
                <div className='w-28'>
                  <ControlledSelectInput
                    control={control}
                    errors={errors}
                    options={options}
                    name={flag}
                    defaultValue={
                      FeatureFlagService.isAvailable(flag)
                        ? YesNoType.YES
                        : YesNoType.NO
                    }
                  />
                </div>
              </div>
            ),
          )}
        </form>
      </div>
    </div>
  );
};

export default FlagsIndex;
