import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { get } from 'lodash';
import { FieldValues } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import FormErrorMessage from './FormErrorMessage';
import ToggleRow from './ToggleRow';

export interface ControlledToggleInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues> {
  errors: FieldErrors<TFieldValues>;
  title: string;
  tooltip?: string;
}

const ControlledToggleInput: React.FC<ControlledToggleInputProps> = ({
  errors,
  control,
  name,
  defaultValue,
  rules,
  title,
  tooltip,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      <Controller
        id={name}
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={({ value, onChange }) => (
          <ToggleRow
            value={value}
            onChange={onChange}
            tooltip={tooltip}
            title={title}
          />
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledToggleInput;
