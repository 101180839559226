// https://stackoverflow.com/a/2901298
const numberWithCommas = (num: string): string => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberToMoney = (
  amount: number,
  precision: number = 2,
): string => {
  return `$${numberWithCommas(amount.toFixed(precision))}`;
};
