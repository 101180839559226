import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SidebarModal from '../components/SidebarModal';
import ControlledTextInput from '../components/ControlledTextInput';
import Button from '../components/Button';
import ControlledDatePickerInput from '../components/ControlledDatePickerInput';
import ControlledFileUploadInput from '../components/ControlledFileUploadInput';
import ControlledRadioInput from '../components/ControlledRadioInput';
import ControlledAsyncSelectInput from '../components/ControlledAsyncSelectInput';
import {
  addReferralDispatch,
  updateReferralDispatch,
} from '../slices/AgentSlice';
import { AppDispatch, AsyncSelectOption, RootState } from '../types';
import { AgentResponse } from '../openapi/yenta';
import ControlledTextAreaInput from '../components/ControlledTextAreaInput';
import { searchForRegisteredAgents } from '../utils/TableUtils';
import { EMAIL_VALIDATIONS } from '../utils/Validations';
import {
  PaymentParticipantValueTypeEnum,
  ReferralResponse,
} from '../openapi/arrakis';
import { getFileNameFromUrl } from '../utils/StringUtils';

interface SaveReferralAgentFormSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  agent?: AgentResponse;
  referral?: ReferralResponse;
}

const SaveReferralAgentFormSidebarModal: React.FC<SaveReferralAgentFormSidebarModalProps> = ({
  isOpen,
  onClose,
  agent,
  referral,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { usersById } = useSelector((state: RootState) => state.userIds);
  const isEditReferral = !!referral;

  const {
    control,
    errors,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      type: PaymentParticipantValueTypeEnum.Agent,
    },
  });

  const type = watch('type');

  useEffect(() => {
    setValue(
      'type',
      referral?.receivingAgentUserYentaId
        ? PaymentParticipantValueTypeEnum.Agent
        : PaymentParticipantValueTypeEnum.ExternalEntity,
    );
  }, [referral?.receivingAgentUserYentaId, setValue]);

  const onSubmit = async (values: any) => {
    const finalData = {
      clientEmail: values.clientEmail,
      clientName: values.clientName,
      expectedCloseDate: values.expectedCloseDate,
      externalAgentBrokerage: values.externalAgentBrokerage,
      externalAgentEmail: values.externalAgentEmail,
      externalAgentName: values.externalAgentName,
      propertyAddress: values.propertyAddress,
      receivingAgentYentaId: values.receivingAgentYentaId?.value,
    };

    const success = isEditReferral
      ? await dispatch(
          updateReferralDispatch(
            referral?.id!,
            finalData,
            values.referralAgreement[0] || undefined,
          ),
        )
      : await dispatch(
          addReferralDispatch(
            agent?.id!,
            finalData,
            values.referralAgreement[0],
          ),
        );

    if (success) {
      onClose();
    }
  };

  return (
    <SidebarModal
      title={isEditReferral ? 'Edit Referral' : 'Add Referral'}
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <form
        className='flex flex-col flex-auto justify-between h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='add-referral-agent-form'
      >
        <div className='p-4'>
          <div className='mt-5'>
            <ControlledRadioInput
              errors={errors}
              label='Type of Referral'
              options={[
                {
                  label: 'Real Agent',
                  value: PaymentParticipantValueTypeEnum.Agent,
                },
                {
                  label: 'External Agent',
                  value: PaymentParticipantValueTypeEnum.ExternalEntity,
                },
              ]}
              name='type'
              control={control}
              rules={{
                required: 'Required',
              }}
              inline
            />
          </div>

          {PaymentParticipantValueTypeEnum.Agent === type ? (
            <div className='mt-5'>
              <ControlledAsyncSelectInput
                control={control}
                errors={errors}
                name='receivingAgentYentaId'
                placeholder='Search'
                label='Name of Receiving Agent*'
                fetchData={async (search, page) => {
                  const searchResponse = await searchForRegisteredAgents<
                    Pick<AgentResponse, 'id' | 'firstName' | 'lastName'>
                  >(
                    ['id', 'firstName', 'lastName'],
                    ['firstName', 'lastName'],
                    page,
                    search,
                  );

                  const options: AsyncSelectOption[] = searchResponse.map(
                    (resp) => ({
                      value: `${resp.id}`,
                      label: `${resp.firstName} ${resp.lastName}`,
                    }),
                  );

                  return options;
                }}
                defaultValue={
                  isEditReferral
                    ? {
                        label: `${
                          usersById[referral?.receivingAgentUserYentaId!]
                            ?.firstName
                        } ${
                          usersById[referral?.receivingAgentUserYentaId!]
                            ?.lastName
                        }`,
                        value: referral?.receivingAgentUserYentaId,
                      }
                    : undefined
                }
                rules={{
                  required: 'Agent is required',
                }}
              />
            </div>
          ) : (
            <div>
              <div className='mt-5'>
                <ControlledTextInput
                  control={control}
                  label='Name of Receiving Agent*'
                  name='externalAgentName'
                  placeholder='Receiving Agent'
                  errors={errors}
                  defaultValue={referral?.externalAgentName}
                  rules={{
                    required: 'External agent name is required',
                  }}
                />
              </div>
              <div className='mt-5'>
                <ControlledTextInput
                  control={control}
                  label='Email of Receiving Agent (Optional)'
                  name='externalAgentEmail'
                  placeholder='mail@example.com'
                  errors={errors}
                  defaultValue={referral?.externalAgentEmail}
                  rules={EMAIL_VALIDATIONS}
                />
              </div>
              <div className='mt-5'>
                <ControlledTextInput
                  control={control}
                  label='Brokerage of Receiving Agent'
                  name='externalAgentBrokerage'
                  placeholder='Brokerage Name'
                  errors={errors}
                  defaultValue={referral?.externalAgentBrokerageName}
                  rules={{
                    required: 'External brokerage name is required',
                  }}
                />
              </div>
            </div>
          )}
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Name of the Client*'
              name='clientName'
              placeholder='Name'
              errors={errors}
              defaultValue={referral?.clientName}
              rules={{
                required: 'Client name is required',
              }}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextInput
              control={control}
              label='Email of the Client (Optional)'
              name='clientEmail'
              placeholder='mail@example.com'
              errors={errors}
              defaultValue={referral?.clientEmail}
              rules={EMAIL_VALIDATIONS}
            />
          </div>
          <div className='mt-5'>
            <ControlledTextAreaInput
              control={control}
              label='Property Address'
              name='propertyAddress'
              errors={errors}
              rows={2}
              defaultValue={referral?.propertyAddress}
            />
          </div>
          <div className='mt-5'>
            <ControlledDatePickerInput
              name='expectedCloseDate'
              control={control}
              errors={errors}
              label=' Expected date of Closing*'
              placeholder='Date'
              defaultValue={referral?.expectedCloseDate}
              rules={{
                required: 'Expected date is required',
              }}
              datePickerConfig={{}}
            />
          </div>
          <div className='mt-5 pb-20'>
            <ControlledFileUploadInput
              name='referralAgreement'
              control={control}
              errors={errors}
              label='Signed referral agreement to be uploaded*'
              placeholder={
                referral?.docUrl
                  ? getFileNameFromUrl(referral.docUrl)
                  : 'PDF,Doc, Docx'
              }
              accept='.pdf,.doc,.docx'
              defaultValue={referral?.docUrl}
              rules={
                isEditReferral
                  ? {}
                  : {
                      required: 'Signed referral agreement is Required',
                    }
              }
            />
          </div>
        </div>
        <div className='p-4 bg-white absolute border-t border-gray-200 bottom-0 space-x-5 w-full'>
          <Button
            disabled={isSubmitting}
            buttonType='submit'
            label={isEditReferral ? 'Save' : 'Add'}
            size='lg'
          />
          <Button
            buttonType='reset'
            onClick={() => onClose()}
            label='Cancel'
            type='secondary'
            size='lg'
          />
        </div>
      </form>
    </SidebarModal>
  );
};

export default SaveReferralAgentFormSidebarModal;
