import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, AppThunk, ErrorState } from '../types';

export const initialState: ErrorState = {
  visible: false,
  errorData: undefined,
};

const ErrorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    showErrorModal(state, action: PayloadAction<ApiError>) {
      state.visible = true;
      state.errorData = action.payload;
    },
    closeErrorModal(state) {
      state.visible = false;
      state.errorData = undefined;
    },
  },
});

export const { closeErrorModal, showErrorModal } = ErrorSlice.actions;

export const showApiErrorModal = (error: any): AppThunk => async (dispatch) => {
  if (error.response) {
    const errorDetails: ApiError = error.response.data;
    if (errorDetails.status === 500) dispatch(showErrorModal(errorDetails));
  }
};

export const closeApiErrorModal = (): AppThunk => async (dispatch) => {
  dispatch(closeErrorModal());
};

export default ErrorSlice.reducer;
