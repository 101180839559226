import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthControllerApi, LoginRequest } from '../../openapi/keymaker';
import { getKeymakerConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { setAuthCookie } from '../../utils/AuthUtils';
import { fetchAuthUserDetail } from '../../slices/AuthSlice';
import { AppDispatch } from '../../types';
import { AgentResponse } from '../../openapi/yenta';
import ChangePasswordSection from '../ChangePassword/ChangePasswordSection';

interface RezenRouteProps extends RouteComponentProps {}

const RezenRoute: React.FC<RezenRouteProps> = ({ history }) => {
  const dispatch: AppDispatch = useDispatch();

  const onChangePasswordSuccess = async (values: LoginRequest) => {
    const api = await new AuthControllerApi(getKeymakerConfiguration());

    const { data } = await api.authenticateUserUsingPOST({
      usernameOrEmail: values.usernameOrEmail,
      password: values.password,
    });

    setAuthCookie(data.accessToken!);

    const userDetail: AgentResponse | undefined = await dispatch(
      fetchAuthUserDetail(false),
    );

    if (userDetail?.hasSignedICA) {
      history.push('/welcome-to-rezen/mobile-app');
    } else {
      history.push('/welcome-to-rezen/ica-signing');
    }
  };

  return (
    <ChangePasswordSection
      onChangePasswordSuccess={onChangePasswordSuccess}
      title='Welcome to Real'
      subtitle='Building your future, together'
      buttonText='Get Started'
    />
  );
};

export default RezenRoute;
