import _ from 'lodash';
import React from 'react';
import GridCardItem from './GridCardItem';

interface GridCardItems {
  name: string;
  value: React.ReactElement | string;
}

export interface GridCardProps {
  items: GridCardItems[];
  colCount?: number;
}

const GridCard: React.FC<GridCardProps> = ({ items, colCount = 2 }) => {
  return (
    <table className='border border-gray-600 border-collapse w-full'>
      <tbody>
        {_.chunk(items, colCount).map((item, index) => {
          return (
            <tr key={`${index + colCount}`}>
              {item.map((row) => {
                return (
                  <GridCardItem
                    key={row.name}
                    name={row.name}
                    value={row.value}
                  />
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default GridCard;
