import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { uniq } from 'lodash';
import { Column, Row } from 'react-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
// import { RootState } from '../types';
import ResourceIndexContainer from '../containers/ResourceIndexContainer';
import DateCell from '../components/table/Cells/DateCell';
import TransactionStateCell from '../components/table/Cells/TransactionStateCell';
import TransactionTypeCell from '../components/table/Cells/TransactionTypeCell';
// import TransactionHeaderSection from '../components/TransactionHeaderSection';
// import { getTransactionOverview } from '../slices/TransactionSlice';
// import ResourceContainer from '../components/ResourceContainer';
// import DetailPageLoader from '../components/DetailPageLoader';
import PageLayoutWithSearch from '../components/PageLayoutWithSearch';
import DateColumnFilter from '../components/table/Filters/DateColumnFilter';
import NumberColumnFilter from '../components/table/Filters/NumberColumnFilter';
import TransactionStatusSelectColumnFilter from '../components/table/Filters/TransactionStatusSelectColumnFilter';
import TransactionTypeSelectColumnFilter from '../components/table/Filters/TransactionTypeSelectColumnFilter';
import { resourceTableFetchData } from '../utils/TableUtils';
import {
  AddressStateEnum,
  SearchRequestTargetObjectEnum,
  TransactionResponse,
  TransactionLifecycleStateValueStateEnum,
} from '../openapi/arrakis';
import { capitalizeEnum, formatAddress } from '../utils/StringUtils';
import TextColumnFilter from '../components/table/Filters/TextColumnFilter';
import { numberToMoney } from '../utils/CurrencyUtils';
import IconButton from '../components/IconButton';
import AddressStateEnumSelectColumnFilter from '../components/table/Filters/AddressStateEnumSelectColumnFilter';
import TransactionVerifiedSwitchColumnFilter from '../components/table/Filters/TransactionVerifiedSwitchColumnFilter';

interface Match {
  id: string;
}

interface TransactionsTableContainerProps extends RouteComponentProps<Match> {}

export interface ModifiedTransactionResponse
  extends Omit<TransactionResponse, 'lifecycleState'> {
  lifecycleState: TransactionLifecycleStateValueStateEnum;
  number: string;
  address: {
    city: string;
    state: AddressStateEnum;
    street: string;
    street2: string;
    zip: string;
  };
}
export const columns: Array<Column<ModifiedTransactionResponse>> = [
  {
    Header: 'Transaction Code',
    accessor: 'code',
    Cell: ({ value, row: { original } }) => (
      <Link to={`/transactions/${original.id}`}>
        <IconButton
          leftIcon={<ExitToApp titleAccess='Link' fontSize='small' />}
          variant='outline'
          label={value}
        />
      </Link>
    ),
    cardColSize: 7,
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    Cell: ({ value }) => <TransactionTypeCell type={value!} />,
    Filter: TransactionTypeSelectColumnFilter,
    cardColSize: 5,
  },
  {
    Header: 'Address',
    accessor: 'address',
    id: 'street',
    Cell: ({ value }) => (
      <div className='w-56'>
        {formatAddress(
          value.street,
          value.street2,
          value.city,
          capitalizeEnum(value.state),
          value.zip,
        )}
      </div>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ value }) => numberToMoney(value?.amount!, 0),
    Filter: NumberColumnFilter,
    cardColSize: 6,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: 'lifecycleState',
    Cell: ({ value }) => <TransactionStateCell status={value!} />,
    Filter: TransactionStatusSelectColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Closing Date',
    accessor: 'closingDateActual',
    Cell: ({ value }) => <DateCell date={value!} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'Estimated Closing Date',
    accessor: 'closingDateEstimated',
    Cell: ({ value }) => <DateCell date={value!} />,
    Filter: DateColumnFilter,
    cardColSize: 6,
  },
  {
    Header: 'City',
    accessor: 'address',
    id: 'city',
    Cell: ({ value }) => value.city,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'State',
    accessor: 'address',
    id: 'state',
    Cell: ({ value }) => value.state,
    Filter: AddressStateEnumSelectColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Zip',
    accessor: 'address',
    id: 'zip',
    Cell: ({ value }) => value.zip,
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Verified',
    accessor: 'verified',
    Cell: ({ value }) =>
      value ? (
        <FaCheckCircle size={25} className='text-success' />
      ) : (
        <FaTimesCircle size={25} className='text-error' />
      ),
    Filter: TransactionVerifiedSwitchColumnFilter,
    disableSortBy: true,
  },
];

export const columnsWithAction: Array<Column<ModifiedTransactionResponse>> = [
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: Row<ModifiedTransactionResponse> }) => (
      <Link to={`/transactions/${row.original.id}`}>
        <IconButton leftIcon={<VisibilityIcon titleAccess='View' />} />
      </Link>
    ),
  },
  ...columns,
];

/* Todo: Doing this because there is an issue with the api that just adds
 *  address to the very end and not maintaining proper positions.
 * */
export const columnsToFetch = uniq([
  ...columns
    .map((col) => col.accessor as string)
    .filter((c) => c !== 'address'),
  'id',
  'address',
]);
const columnsForSearch = ['code', 'street', 'zip', 'street2', 'city'];

const TransactionsRoute: React.FC<TransactionsTableContainerProps> = () => {
  // const dispatch = useDispatch();
  const [search, setSearch] = useState<string>();
  // const agentId = parseInt(match?.params.id, 10);
  // const {
  //   transactionOverview,
  //   loadingTransactionOverview,
  //   fetchTransactionOverviewErrorCode,
  // } = useSelector((state: RootState) => state.transaction);

  // TODO: We may want to remove this agentId here. Right now we are treating
  // this page as an admin-only dashboard. When we want to search for a particular
  // agent's transactions, then we can integrate this.
  // useEffect(() => {
  //   dispatch(getTransactionOverview(agentId));
  // }, [dispatch, agentId]);

  return (
    // <ResourceContainer
    //   loading={loadingTransactionOverview}
    //   isEmpty={!transactionOverview}
    //   errorCode={fetchTransactionOverviewErrorCode}
    //   LoaderComponent={<DetailPageLoader />}
    //   resourceName='transaction overview'
    // >
    <PageLayoutWithSearch
      path={[
        { title: 'Home', url: '/' },
        { title: 'Transactions', url: '/transactions' },
      ]}
      search={search}
      onSearchChange={setSearch}
    >
      {/* {!!transactionOverview && (
          <TransactionHeaderSection transactionOverview={transactionOverview} />
        )} */}
      <div className='px-4 py-0 mt-5'>
        <ResourceIndexContainer<ModifiedTransactionResponse>
          header='Transactions'
          columns={columnsWithAction}
          search={search}
          resourceName='Transactions'
          hiddenColumns={['id', 'zip', 'city', 'state', 'street2', 'verified']}
          fetchData={(req) =>
            resourceTableFetchData(
              req,
              columnsToFetch,
              columnsForSearch,
              SearchRequestTargetObjectEnum.Transaction,
              'arrakis',
              {
                joinOn: ['address'],
              },
              {
                zip: 'address',
                street: 'address',
                street2: 'address',
                city: 'address',
                state: 'address',
              },
            )
          }
        />
      </div>
    </PageLayoutWithSearch>
    // </ResourceContainer>
  );
};

export default TransactionsRoute;
