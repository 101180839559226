import React from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SuccessIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { EnumMap } from '../types';
import { ModalVariantType } from './ConfirmationModal';
import Button from './Button';
import ControlledTextInput from './ControlledTextInput';

interface DoubleConfirmationModalProps {
  variant: ModalVariantType;
  title: string;
  isOpen: boolean;
  subtitle?: string;
  onClose(): void;
  isSubmitting: boolean;
  onConfirm(): void;
}

const CONFIRMATION_STRING_REQUIRED = 'CONFIRM';

const DoubleConfirmationModal: React.FC<DoubleConfirmationModalProps> = ({
  variant,
  title,
  isOpen,
  subtitle,
  onClose,
  isSubmitting,
  onConfirm,
}) => {
  const { errors, control, watch } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const classMapStyle: EnumMap<ModalVariantType, string> = {
    error: 'bg-error text-error',
    info: 'bg-primary text-primary',
    success: 'bg-success text-success',
    warning: 'bg-warning text-warning',
  };

  const IconComponent: EnumMap<ModalVariantType, React.ReactElement> = {
    error: <ErrorIcon fontSize='small' />,
    info: <InfoIcon fontSize='small' />,
    success: <SuccessIcon fontSize='small' />,
    warning: <WarningIcon fontSize='small' />,
  };

  if (!isOpen) {
    return null;
  }

  const watchConfirm = watch('confirm');
  const isDisabled = watchConfirm !== CONFIRMATION_STRING_REQUIRED;

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full-window z-50'>
      <div
        className='block absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-40 z-0'
        role='button'
        onClick={onClose}
      />
      <div className='w-full h-full flex justify-center items-center p-4 lg:p-0'>
        <div className='w-full md:w-1/2 lg:w-1/4 bg-white rounded-lg p-4 z-10'>
          <div className='flex items-start'>
            <div className='mr-3'>
              <div
                className={classNames(
                  'flex justify-center items-center w-10 h-10 bg-primary bg-opacity-20 rounded-full p-1',
                  classMapStyle[variant],
                )}
              >
                {IconComponent[variant]}
              </div>
            </div>
            <div>
              <p className='font-primary-regular text-dark text-lg'>{title}</p>
              {subtitle && (
                <p className='font-primary-regular text-gray-500 text-base'>
                  {subtitle}
                </p>
              )}
              <div className='mt-3'>
                <ControlledTextInput
                  errors={errors}
                  control={control}
                  name='confirm'
                  type='text'
                  placeholder={`Please type ${CONFIRMATION_STRING_REQUIRED} to continue.`}
                />
              </div>
              <div className='mt-3 space-x-3'>
                <Button
                  label={isSubmitting ? 'Submitting...' : 'Confirm'}
                  disabled={isDisabled}
                  type={isDisabled ? 'secondary' : 'primary'}
                  onClick={onConfirm}
                />
                <Button label='Cancel' type='secondary' onClick={onClose} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubleConfirmationModal;
