import React from 'react';
import { FilterProps } from 'react-table';
import { keys } from 'lodash';
import {
  DateFilterType,
  FilterColumnsToProcess,
  NumberFilterType,
  NumberFilterTypeLabel,
} from '../../../types';
import ControlledTextInput from '../../ControlledTextInput';
import ControlledSelectInput from '../../ControlledSelectInput';
import { PROCESS_FILTER_COLUMN } from '../../../utils/TableUtils';
import { NUMBER_REGEX } from '../../../utils/StringUtils';

const numberFilterOptions = keys(NumberFilterType) as NumberFilterType[];

export interface NumberColumnFilterProps<D extends object>
  extends FilterProps<D> {}

const NumberColumnFilter = <D extends object>({
  column,
  errors,
  watch,
  control,
}: NumberColumnFilterProps<D>): React.ReactElement => {
  const prefixFieldName = `${PROCESS_FILTER_COLUMN}[${FilterColumnsToProcess.NUMBER}][${column.id}]`;
  const selectedNumberFilter =
    (watch(`${prefixFieldName}[type]`) as NumberFilterType) ||
    DateFilterType.equals;
  const startNumber = watch(`${prefixFieldName}[start_number]`);
  const endNumber = watch(`${prefixFieldName}[end_number]`);

  return (
    <div className='space-y-1'>
      <ControlledSelectInput
        control={control}
        errors={errors}
        options={numberFilterOptions.map((numberFilterOption) => ({
          label: NumberFilterTypeLabel[numberFilterOption],
          value: numberFilterOption,
        }))}
        name={`${prefixFieldName}[type]`}
        rules={{
          required: 'Required',
        }}
        defaultValue={NumberFilterType.equals}
      />
      <div className='flex justify-between space-x-1'>
        <ControlledTextInput
          control={control}
          errors={errors}
          type='number'
          name={`${prefixFieldName}[start_number]`}
          rules={{
            required: 'Required',
            pattern: { value: NUMBER_REGEX, message: 'Please input number' },
            ...(endNumber
              ? {
                  max: {
                    value: endNumber,
                    message: `Cannot be greater than ${endNumber}`,
                  },
                }
              : {}),
          }}
        />
        {selectedNumberFilter === NumberFilterType.between && (
          <ControlledTextInput
            control={control}
            errors={errors}
            type='number'
            name={`${prefixFieldName}[end_number]`}
            rules={{
              required: 'Required',
              pattern: { value: NUMBER_REGEX, message: 'Please input number' },
              ...(startNumber
                ? {
                    min: {
                      value: startNumber,
                      message: `Cannot be less than ${startNumber}`,
                    },
                  }
                : {}),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NumberColumnFilter;
