import { Link } from 'react-router-dom';

interface CardProps {
  title: string;
  description: string;
  imgSrc: string;
  link: string;
}

const Card: React.FC<CardProps> = ({ title, imgSrc, description, link }) => {
  return (
    <Link to={link}>
      <div className='flex flex-col hover:shadow-xl p-10 cursor-pointer rounded-xl transition'>
        <img src={imgSrc} alt={title} />
        <div className='flex flex-col pt-10'>
          <span className='text-xl'>{title}</span>
          <span className='text-sm'>{description}</span>
        </div>
      </div>
    </Link>
  );
};

export default Card;
