import { InvoiceResponseStatusEnum } from '../../../openapi/arrakis';
import { SelectColumnFilterProps } from './Base/SelectColumnFilter';
import EnumSelectColumnGenerator from './EnumSelectColumnGenerator';

export interface InvoiceSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const InvoiceStatusSelectColumnFilter = <D extends object>(
  props: InvoiceSelectColumnFilterProps<D>,
) => {
  return EnumSelectColumnGenerator<D>(InvoiceResponseStatusEnum)(props);
};

export default InvoiceStatusSelectColumnFilter;
